<div class="timeline-container">

  <div class="hours" [style.top.rem]="baseHeightREM / 2">
    <div
      class="hour"
      *ngFor="let item of hours; let i = index"
      [class.first-hour]="i == 0"
      [style.min-height.rem]="baseHeightREM"
    >
      {{ item }}
    </div>


  </div>

  <div
  class="time-indicator left"
  [style.top.rem]="baseHeightREM / 2 +
  baseHeightREM * currentTimeMultiplier - 0.1
  "
  ></div>
<div
  class="time-indicator right"
  [style.top.rem]=" baseHeightREM / 2 +
  baseHeightREM * currentTimeMultiplier - 0.1
  "
></div>

</div>
