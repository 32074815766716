<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
   <defs>
    <path id="path-reset-v5" d="M12.8541145,2 C7.76104673,2 3.54341736,5.7234344 2.82754162,10.5505809 L1.60279035,8.53017852 C1.3598526,8.12921508 0.830852054,7.99461604 0.42116413,8.23547747 C0.0114762063,8.47633891 -0.122211432,8.99773307 0.122163821,9.40153018 L2.83041662,13.8744687 C2.96554176,14.0969113 3.195542,14.2470955 3.45572977,14.2810995 C3.49454231,14.2867668 3.53335485,14.2896005 3.57072989,14.2896005 C3.79210512,14.2896005 4.00629284,14.2045905 4.16873051,14.0515727 L7.77973425,10.6285067 C8.1232971,10.3026353 8.13335961,9.76423916 7.80417177,9.42561632 C7.47210893,9.08699348 6.92729586,9.07849249 6.58373301,9.40294701 L4.46341831,11.4134316 C4.77104363,7.11051289 8.4122349,3.70019836 12.8541145,3.70019836 C17.4972443,3.70019836 21.2749982,7.42363276 21.2749982,12 C21.2749982,16.5763672 17.4972443,20.2998016 12.8541145,20.2998016 C12.376864,20.2998016 11.9916136,20.6809294 11.9916136,21.1499008 C11.9916136,21.620289 12.376864,22 12.8541145,22 C18.4488703,22 23,17.51431 23,12 C23,6.48710683 18.4488703,2 12.8541145,2"></path>
</defs>
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <mask fill="white">
        <use xlink:href="#path-reset-v5"></use>
    </mask>
    <use fill="currentColor" xlink:href="#path-reset-v5"></use>
</g>
</svg>
