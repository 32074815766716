<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
   <defs>
    <path id="close-path" d="M13.333508,11.9996071 L17.7230044,7.61011067 C18.0923319,7.24078318 18.0923319,6.64514439 17.7230044,6.27581691 C17.3552485,5.90806103 16.7580381,5.90806103 16.3902822,6.27581691 L11.9992142,10.6653133 L7.60971777,6.27581691 C7.24196189,5.90806103 6.64475149,5.90806103 6.27699561,6.27581691 C5.90766813,6.64514439 5.90766813,7.24078318 6.27699561,7.61011067 L10.666492,11.9996071 L6.27699561,16.3891035 C5.90766813,16.758431 5.90766813,17.3540698 6.27699561,17.7233973 C6.46087355,17.9072752 6.70290092,18 6.94335669,18 C7.18381245,18 7.42583983,17.9072752 7.60971777,17.7233973 L11.9992142,13.3339009 L16.3902822,17.7233973 C16.5741602,17.9072752 16.8161875,18 17.0566433,18 C17.2970991,18 17.5391264,17.9072752 17.7230044,17.7233973 C18.0923319,17.3540698 18.0923319,16.758431 17.7230044,16.3891035 L13.333508,11.9996071 Z" ></path>
</defs>
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <mask fill="currentColor">
        <use xlink:href="#close-path"></use>
    </mask>
    <use fill="currentColor" xlink:href="#close-path"></use>
</g>
</svg>
