import { Component } from '@angular/core';

@Component({
  selector: 'tun-menu-home-icon-v5',
  templateUrl: './menu-home-icon-v5.component.html',
  styleUrls: ['./menu-home-icon-v5.component.scss']
})
export class MenuHomeIconV5Component {

}
