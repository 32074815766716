import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { AsyncStatus } from './vo/asyncStatus';
import { SongRequestApiService } from '@service/api/song-request-api.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

export class SongRequest {
  id: number;
  zoneId: number;
  title: string;
  groupname: string;
  remarks: string;
  status: ProcessStatus;
  creationDate: Date;
  lastModified: Date;
  feedback: string;
  hasViewed: boolean;
  isVisible: boolean;
}

export enum ProcessStatus {
  PENDING = 'Pending',
  FINISHED = 'Finished',
  REJECTED = 'Rejected',
  PROCESSED = 'Processed',
  UNAVAILABLE = 'Unavailable',
  ALREADY_IN_CATALOG = 'AlreadyInCatalog'
}

@Injectable({
  providedIn: 'root'
})
export class SongRequestService implements OnDestroy {

  constructor(
    private songRequestApiService: SongRequestApiService,
    private authenticationService: AuthenticationService
  ) { }

  private _destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this._destroyed$.next();
    this._destroyed$.complete();
    this._destroyed$ = null;
  }
  /**
   * Create a new songrequest for the current zone.
   *
   * @param songRequest SongRequest object with the title / group and optional remarks filled in
   */
  public createSongRequest(songRequest: SongRequest): Observable<AsyncStatus> {
    songRequest.id = 0;
    songRequest.zoneId = this.authenticationService.zoneId;

    const notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    let createSongRequestObservable = this.songRequestApiService.createSongRequest(songRequest);
    if (createSongRequestObservable){
      createSongRequestObservable
        .pipe(
          finalize(
            () => {
              //calendar.creating = false;
              notifier.complete();
            }
          ),
          takeUntil(this._destroyed$)
        )
        .subscribe(
          (data) => {
            //success -> parse result
            notifier.next(AsyncStatus.COMPLETED );
          },
          (error) => {
            notifier.next(AsyncStatus.ERROR);
          }
        );  
    } else {
      //we need a setTimeout of 0 so the creator can subscribe to the observable before it completes
      setTimeout(() => {
        notifier.next(AsyncStatus.RUNNING);
        notifier.complete();
      }, 0);
    }

    return notifier;
  }
}
