<div class="wrapper">

  <div class="contentWrapper">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="search-container">
        {{panelTitleForPlaylist(playlist)}}
      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- header -->
    <div class="searchBarContent">

      <div class="remainingSpace">

      </div>

      <div class="search-container">
        <tun-search-input
          class="search-field first-title-item"
          [canReceiveFocus]="canReceiveFocus"
          (searchfocus)="onSearchFieldFocus($event)"
          (search)="onSearch($event)"
          (autocomplete)="onAutocomplete($event)"
        >
      </tun-search-input>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- overlay wrapper for autocompletion -->
    <div class="overlay-wrapper">

      <div class="overlay-content {{showAutoComplete ? 'blurredContent' : ''}}">
        <!-- search result content -->

        <ng-container *ngIf="searchState != SearchState.none">

          <!-- header -->
          <div class="titleContent">

            <div class="remainingSpace">

            </div>

            <div class="titleContainer">

              <div class="title">
                {{ searchTitle$ | async }}
              </div>

            </div>

            <div class="remainingSpace"></div>

          </div>

          <!-- Special search states indicator -->

          <div
            *ngIf="searchState != SearchState.normal"
            [ngSwitch]="searchState"
            class="feedback-container"
          >
            <div
              *ngSwitchCase="SearchState.searchError"
              class="error-feedback-container"
            >
              <p>
                {{'search.status.failed' | translate }}
              </p>
              <button (click)="retryClick()" class="retryButton">
                <p>
                  {{'general.tryAgain' | translate }}
                </p>
              </button>
            </div>

            <ng-container *ngSwitchCase="SearchState.searching">
              <tun-loader-v5 class="loader"></tun-loader-v5>
            </ng-container>

          </div>

      <!-- normal content -->
      <cdk-virtual-scroll-viewport
        *ngIf="searchState == SearchState.normal"
        class="trackTable"
        [itemSize]="trackItemSize$ | async"
        cdkDropList
        [cdkDropListData]="trackTableItems"
        [cdkDropListConnectedTo]="['queueHeader', 'queue']"
        cdkDropListSortingDisabled="true"
        cdkDropListAutoScrollDisabled="true"
      >
        <!--
        ---- templateCacheSize: 0
        ---- We can not use a cache to support drag & drop through the angular cdk
        ---- Drag & drop caches items indexes, this cache is not re-calculated if an item moves from on-screen to the cache
        -->

        <ng-container>
            <tun-track-view
              *cdkVirtualFor="
              let trackTableItem of trackTableItems;
              let i = index;
              templateCacheSize: 0
              "
              class="trackItem"
              [track]="trackTableItem.track"
              [showAddButton]="true"
              (checkedChanged)="onCheckedChanged($event, trackTableItem.track)"
              [isSelected]="isTrackChecked(trackTableItem.track)"
              [showAudioFileProperty]="audioFileProperty$ | async"
              tunifyColor="TunifyColor.orange"
              (showOptions)="onShowOptions(trackTableItem.track)"
              [style.height.px]="trackItemSize$ | async"
              cdkDrag
              [cdkDragData]="trackTableItem"
            >
            </tun-track-view>

        </ng-container>

      </cdk-virtual-scroll-viewport>

    </ng-container>
      </div>

      <div
        *ngIf="showAutoComplete"
        class="overlay-content"
      >
        <div class="autocompletion-container">
          <tun-autocompletion
          [autocompleteValue]="lastAutocompleteValue"
          (selectAutocomplete)="onSelectAutocomplete($event)"
          class="autocompletion-field"
        >

        </tun-autocompletion>
        </div>

      </div>



    </div>





  </div>


</div>
