import { Component } from '@angular/core';

@Component({
  selector: 'tun-search-result-artist-icon-v5',
  templateUrl: './search-result-artist-icon-v5.component.html',
  styleUrls: ['./search-result-artist-icon-v5.component.scss']
})
export class SearchResultArtistIconV5Component {

}
