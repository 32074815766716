import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { LoggerService } from '@service/loggers/logger.service';
import { Config } from '@service/config';
import { CustomEncoder } from './customEncoder';

interface AuthenticationApi {
  authenticateUser(username: string, password: string, encrypted: boolean): Observable<AuthenticateUserResponse>;
}

export interface AuthenticateUserResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  userId: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationApiService implements AuthenticationApi {

  private LOGGER_CLASSNAME = 'AuthenticationApiService';

  constructor(
    private http: HttpClient,
    private logger: LoggerService) { }

  authenticateUser(username: any, password: any, encrypted: boolean): Observable<AuthenticateUserResponse> {
    let authenticateUserResponse: Observable<AuthenticateUserResponse>;

    if (environment.mockBackend) {
      this.logger.info(this.LOGGER_CLASSNAME, 'authenticateUser', 'about to mock authentication request');
      authenticateUserResponse = this.http.get<AuthenticateUserResponse>("api/authentication");
    } else {
      const headers = new HttpHeaders({"Authorization": "Basic " + btoa(environment.appId + ":" + environment.appPassword)});

      this.logger.info(this.LOGGER_CLASSNAME, 'authenticate', 'about to execute authentication request');

      /*
      const params = new HttpParams()
        .set('username', encodeURIComponent(username))
        .set('password', encodeURIComponent(password));
      */

      //we can't encode our parameters and we want to send them exactly as the user typed them
      const params = new FormData();
      params.append('username', username);
      params.set('password', password);


      var url = Config.api4Url_authenticator
      if (encrypted){
        url = Config.api4Url_encrypted_authenticator
      }

      authenticateUserResponse = this.http.
        post<AuthenticateUserResponse>(
          url,
          //"http://docker1-test.tunify.com:8090/oauth/token?grant_type=password",
          params,
          {
            headers: headers
          });
    }

    return authenticateUserResponse;
  }

  refreshAccessToken(refreshToken: string): Observable<AuthenticateUserResponse> {
    let refreshResponse: Observable<AuthenticateUserResponse>;

    if (environment.mockBackend) {
      this.logger.info(this.LOGGER_CLASSNAME, 'authenticateUser', 'about to mock refresh Access Token request');
      refreshResponse = this.http.get<AuthenticateUserResponse>("api/authentication");
    } else {
      const headers = new HttpHeaders({"Authorization": "Basic " + btoa(environment.appId + ":" + environment.appPassword)});

      this.logger.info(this.LOGGER_CLASSNAME, 'authenticate', 'about to execute refresh Access Token request');

      const params = new HttpParams()
        .set('refresh_token', refreshToken);

        refreshResponse = this.http.
        post<AuthenticateUserResponse>(
          Config.api4Url_authenticator_refresh,
          //"http://docker1-test.tunify.com:8090/oauth/token?grant_type=refresh_token",
          params,
          {
            headers: headers
          });
    }

    return refreshResponse;
  }



}
