import { Component } from '@angular/core';

@Component({
  selector: 'tun-reset-icon-v5',
  templateUrl: './reset-icon-v5.component.html',
  styleUrls: ['./reset-icon-v5.component.scss']
})
export class ResetIconV5Component {

}
