import { Injectable } from '@angular/core';
import { Config } from '@service/config';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../loggers/logger.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';


export class DTO_MessageScheduleRequest{
  timestamp: number;
  messageSchedule: DTO_MessageSchedule;
}

export class DTO_MessageSchedule{
  scheduleValidityDateTime: string;
  messageBlocks: DTO_MessageBlock[];
  messages: DTO_Message[];
}

export class DTO_MessageBlock {
  playDateTime: string;
  interruptMusic: Boolean;
  messageIds: number[];
}

export class DTO_Message {
  id: number;
  title: string;
  duration: number;
  url: string;
}


@Injectable({
  providedIn: 'root'
})
export class MessageApiService {

  private LOGGER_CLASSNAME = 'MessageApiService';

  constructor(
    private loggerService: LoggerService,
    private httpClient: HttpClient
  ) { }

  public loadMessageBlocks(startDate: Date, endDate: Date): Observable<DTO_MessageScheduleRequest>{

    //create the http get request
    let messageBlocksObservable:Observable<DTO_MessageScheduleRequest>;
    if (environment.mockBackend ){
      //todo
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadMessageBlocks', 'about to mock loadMessageBlocks request');
      /*
      queueObservable = this.httpClient.get<DTO_Queue>("api/" + queueType + "Queue");
      */
    }else{
      const url = Config.api4Url_messageSchedule();

      //the brands api expects dates without any timezone
      const startDateToSendString = moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS');
      const endDateToSendString = moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS');

      const params = new HttpParams()
                      .set('start', startDateToSendString)
                      .set('end', endDateToSendString);

      this.loggerService.debug(this.LOGGER_CLASSNAME, "loadMessageBlocks", "going to load messageBlocks from " + url);
      messageBlocksObservable = this.httpClient.get<DTO_MessageScheduleRequest>(url, {params: params});
    }

    return messageBlocksObservable;
  }
}
