import { Component } from '@angular/core';

@Component({
  selector: 'tun-play-track',
  templateUrl: './play-track.component.html',
  styleUrls: ['./play-track.component.scss']
})
export class PlayTrackComponent {

}
