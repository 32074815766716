import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Context } from '@model/context';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { MusicChannelService } from '@service/music-channel.service';
import { MusicCollectionService } from '@service/music-collection.service';
import { map } from 'rxjs/operators';


@Component({
  selector: 'tun-music-collection-view',
  templateUrl: './music-collection-view.component.html',
  styleUrls: ['./music-collection-view.component.scss']
})
export class MusicCollectionViewComponent {

  //@Input() musicChannel: MusicChannel;
  @Input() musicCollection: MusicCollection;
  @Input() recommendedMusicCollections: MusicCollection[];

  @Output() toggleMusicCollectionSelection = new EventEmitter<MusicCollection>();
  @Output() tweakMusicCollection = new EventEmitter<MusicCollection>();

  public playAnimationColors : MusicPlayAnimationColors

  public showAsPlaylist(musicCollection: MusicCollection){
    return musicCollection instanceof Playlist
  }

  public get isRecommendation(){
    if (this.musicCollection && this.recommendedMusicCollections){
      return this.recommendedMusicCollections.includes(this.musicCollection)
    }
    return false;
  }


  public get showPlayAnimation$(){
    return this.playingMusicSelectionService.playingMusicSelection$
      .pipe(
        map(playingMusicSelection => {
          return playingMusicSelection.origin == PlayingOrigin.musicChannel && playingMusicSelection.musicCollection == this.musicCollection
        })
      )
  }

  public get animatePlayAnimation$(){
    return this.playingItemService.isPlaying$
  }


  constructor(
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private playingItemService: PlayingItemService
  ) {
    this.playAnimationColors = new MusicPlayAnimationColors()
    .selectedColor('#FFFFFF')
    .unselectedColor('#FFFFFF');
  }

  public onToggleSelected(){
    this.toggleMusicCollectionSelection.emit(this.musicCollection);
    /*
    if (this.musicChannel && this.musicCollection) {
      this.musicCollection.selected = !this.musicCollection.selected;
      this.musicChannelService.saveMusicCollectionSelectionProperty(
        this.musicChannel,
        this.musicCollection
      );
    }
    */
  }

  public onTweak(event:Event){
    event.stopPropagation()
    this.tweakMusicCollection.emit(this.musicCollection);

  }



}
