import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { genericRetryStrategy } from './retryStrategy';
import { environment } from 'src/environments/environment';


export const BYPASS_RETRY = new HttpContextToken(() => false);


@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //do not handle request where we bypass
    if (request.context.get(BYPASS_RETRY) === true)
      return next.handle(request);

    if (environment.mockBackend
        || request.url.includes("blackbox2/services/")
        || request.url.includes("/oauth/token")){
          return next.handle(request)
                  .pipe(retryWhen(genericRetryStrategy(authenticationRequestParams)));
    }else{
      return next.handle(request)
              .pipe(retryWhen(genericRetryStrategy({})));
    }
  }
}

const authenticationRequestParams = {

  shouldRetry: (status) => {
    let should = status >= 500; //only retry general errors
    return  should;
  }
}
