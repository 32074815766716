import { ListRange } from '@angular/cdk/collections';
import { CdkDragDrop, CdkDragMove } from '@angular/cdk/drag-drop';
import { CdkVirtualForOf } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { AudioFile } from '@model/audioFile';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { subscriptionInfo } from '../../../services/data/mock/subscriptionInfo';
import { SubscriptionsService } from '@service/subscriptions.service';

export enum TrackTableItemType {
  WAITING_QUEUE_ITEM = "waitingQueueItem",
  RADIO_QUEUE_HEADER = "radioQueueHeader",
  RADIO_QUEUE_ITEM = "radioQueueItem",
  OTHER = "other"
}

export class TrackTableItem{
  type: TrackTableItemType
  track: AudioFile

  constructor(track?: AudioFile, type: TrackTableItemType = TrackTableItemType.OTHER){
    this.track = track
    this.type = type
  }
}


@Component({
  selector: 'tun-track-list-view',
  templateUrl: './track-list-view.component.html',
  styleUrls: ['./track-list-view.component.scss']
})
export class TrackListViewComponent implements OnInit, OnDestroy, OnChanges{

  public LOGGER_NAME = TrackListViewComponent.name;

  // === Inputs === //

  @Input() useSelectionBox: boolean = false;
  @Input() checkedTracks : AudioFile[] = [];

  @Input() tunifyColor: TunifyColor = TunifyColor.ORANGE

  _tracks: AudioFile[];

  @Input()
  set tracks(value: AudioFile[]) {
    this._tracks = value
    if (this.tracks){
      this.trackTableItems = this.tracks.map(track => new TrackTableItem(track))
    }else{
      this.trackTableItems = [];
    }
  }
  get tracks():AudioFile[]{
    return this._tracks;
  }

  @Output() checkedTracksChanged = new EventEmitter<AudioFile[]>();

  // === State === //

  public get trackItemSize$(){
    return this.appV5StateService.pixelsFor1Rem$
    .pipe(
      map(px => {
        return 3 * px
      })
    )
  }

  get audioFileProperty$(){
    return this.zoneConfigurationService.audioFileProperty$
  }

  public trackTableItems: TrackTableItem[];

  public get startTrackEnabled$(){
    return this.subscriptionsService.accessRights$
      .pipe(
        map(
          (accessRights => {
            return accessRights == null || accessRights.startTrack
          })
        )
      )
  }

  constructor(
    private appV5StateService: AppV5StateService,
    private zoneConfigurationService: ZoneConfigurationService,
    private trackOverlayService: TrackOverlayService,
    private musicManipulationService: MusicManipulationService,
    private loggerService: LoggerService,
    private subscriptionsService: SubscriptionsService
  ) {

  }

  public ngOnInit(): void {
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tracks']) {
      //wrap in objects

    }
  }


  /* Handlers for track item */
  public onPlay(track: AudioFile){
    this.musicManipulationService.playAudioFile(track);
  }

  public onShowOptions(track: AudioFile){
    this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = new TrackInfoOverlayData(track, new TrackInfoContext(TrackInfoContextMode.search));
  }

   /* Handler for selection header */


   get showSelectionBox(){
     return this.checkedTracks.length > 0
   }

   public onCheckedChanged(value:boolean, track: AudioFile){
    if (value){
      //add to checkedTracks
      if (this.checkedTracks.filter(t => t == track).length == 0){
        this.checkedTracks.push(track);
        this.checkedTracksChanged.emit(this.checkedTracks);
      }else{
        this.loggerService.error(this.LOGGER_NAME, "onCheckedChanged", "checked a track that was already checked");
      }
    }else{
      if (this.checkedTracks.filter(t => t == track).length > 0){
        this.checkedTracks = this.checkedTracks.filter(t => t != track);
        this.checkedTracksChanged.emit(this.checkedTracks);
      }else{
        this.loggerService.error(this.LOGGER_NAME, "onCheckedChanged", "un-checked a track that was already un-checked");
      }
    }
  }

  public isTrackChecked(track: AudioFile){
    return this.checkedTracks.filter(t => t == track).length > 0
  }


}
