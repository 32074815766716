export const searchMusicChannelGroups = {
  "timestamp": 1241,
  "musicChannelGroups":[
    {
        "id": 0,
        "name": "Tunify Lists",
        "type": "search",
        "musicChannels": [
            {
                "id": 188,
                "name": "Tunify Tips",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "TunifyTipList",
                        "id": 8713,
                        "title": "Tips Alternative",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 2,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8737,
                        "title": "Tips Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 2,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8708,
                        "title": "Tips Pop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 2,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8738,
                        "title": "Tips Pop Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 0,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8714,
                        "title": "Tips Pop Lite",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 0,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8707,
                        "title": "Tips Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 1,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8712,
                        "title": "Tips Soul",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 0,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8709,
                        "title": "Tips Urban",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 1,
                        "version": "740-1725"
                    }
                ]
            },
            {
                "id": 183,
                "name": "Hit Parades",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8619,
                        "title": "Pop",
                        "description": "01",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null
                    },
                    {
                        "@type": "Playlist",
                        "id": 8620,
                        "title": "Lite",
                        "description": "02",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null
                    },
                    {
                        "@type": "Context",
                        "id": 8757,
                        "title": "Years FR - Les 00's",
                        "description": "02",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": false,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Playlist",
                        "id": 8621,
                        "title": "Dance",
                        "description": "03",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null
                    },
                    {
                        "@type": "Playlist",
                        "id": 8622,
                        "title": "Alternative",
                        "description": "04",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null
                    },
                    {
                        "@type": "Context",
                        "id": 8793,
                        "title": "Other Countries",
                        "description": "10",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 194,
                "name": "Best Of",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8795,
                        "title": "Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8798,
                        "title": "Disco",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8794,
                        "title": "Pop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8799,
                        "title": "Pop Lite",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8796,
                        "title": "Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8797,
                        "title": "Soul",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8792,
                        "title": "Urban",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 128,
                "name": "Countries",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8716,
                        "title": "Africa",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8809,
                        "title": "Albania",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8301,
                        "title": "Arab",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8257,
                        "title": "Asia",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8291,
                        "title": "Austria",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8258,
                        "title": "Belgium",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8292,
                        "title": "Brazil",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8293,
                        "title": "Canada",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8743,
                        "title": "Caribbean",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8744,
                        "title": "Cuba",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8294,
                        "title": "Denmark",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8296,
                        "title": "Finland",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8297,
                        "title": "France",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8298,
                        "title": "Germany",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8299,
                        "title": "Greece",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8300,
                        "title": "Ireland",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8302,
                        "title": "Italy",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8303,
                        "title": "Jamaica",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8256,
                        "title": "Latin America",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8745,
                        "title": "Mexico",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8304,
                        "title": "Netherlands",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8305,
                        "title": "Norway",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8306,
                        "title": "Oceania",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8719,
                        "title": "Portugal",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8720,
                        "title": "Spain",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8721,
                        "title": "Sweden",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8681,
                        "title": "Switzerland",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8718,
                        "title": "Turkey",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8295,
                        "title": "UK",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8717,
                        "title": "USA",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 131,
                "name": "Dancing Styles",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8260,
                        "title": "Boogie",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8671,
                        "title": "Cha Cha",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8800,
                        "title": "Disco",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8259,
                        "title": "Discofox",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8662,
                        "title": "Folk Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8627,
                        "title": "Foxtrot",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8664,
                        "title": "Fun Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8682,
                        "title": "Latin",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8669,
                        "title": "Line Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8727,
                        "title": "Quickstep",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8261,
                        "title": "Rock & Roll",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8262,
                        "title": "Rumba & Bolero",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8674,
                        "title": "Salsa",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8678,
                        "title": "Samba",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8263,
                        "title": "Slow",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8701,
                        "title": "Slow Fox",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8672,
                        "title": "Swing",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8677,
                        "title": "Tango",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8661,
                        "title": "Waltz",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8680,
                        "title": "Zouk",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 186,
                "name": "Genres",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8723,
                        "title": "Alternative",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8632,
                        "title": "Blues",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8810,
                        "title": "Bossa Nova",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8639,
                        "title": "Britpop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8631,
                        "title": "Chanson",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8630,
                        "title": "Country",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8670,
                        "title": "Country Lite",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8634,
                        "title": "Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8640,
                        "title": "Dancehall",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8272,
                        "title": "Disco",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8722,
                        "title": "Drum & Bass",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8726,
                        "title": "Dubstep",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8633,
                        "title": "Easy Listening",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8629,
                        "title": "Electronic",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8641,
                        "title": "Flower Power",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8628,
                        "title": "Folk",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8679,
                        "title": "Funk",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8642,
                        "title": "Glamour",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8813,
                        "title": "Grunge",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8811,
                        "title": "Hard Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8635,
                        "title": "House",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8626,
                        "title": "Jazz",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8625,
                        "title": "Kleinkunst",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8624,
                        "title": "Latin",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8802,
                        "title": "Latin Pop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8289,
                        "title": "Lounge",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8282,
                        "title": "Metal",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8643,
                        "title": "New Beat",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8644,
                        "title": "New Romantics",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8645,
                        "title": "New Wave",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8274,
                        "title": "Pop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8803,
                        "title": "Pop Alternative",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8668,
                        "title": "Pop Lite",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8646,
                        "title": "Popcorn",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8814,
                        "title": "Progressive Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8812,
                        "title": "Punk",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8638,
                        "title": "R&B",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8276,
                        "title": "Reggae",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8673,
                        "title": "Reggaeton",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8275,
                        "title": "Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8804,
                        "title": "Rock Alternative",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8277,
                        "title": "Rock & Roll",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8801,
                        "title": "Soul",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8636,
                        "title": "Techno",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8279,
                        "title": "Traditional Music",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8637,
                        "title": "Trance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8280,
                        "title": "Urban",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8281,
                        "title": "World",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 144,
                "name": "Instruments",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8684,
                        "title": "Accordion",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8686,
                        "title": "Bagpipe",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8269,
                        "title": "Banjo",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8700,
                        "title": "Cello",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8687,
                        "title": "Contrabass",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8685,
                        "title": "Guitar Acoustic",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8688,
                        "title": "Guitar Electric",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8694,
                        "title": "Guitar Steel",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8689,
                        "title": "Hammond Organ",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8690,
                        "title": "Harmonica",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8691,
                        "title": "Harp",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8199,
                        "title": "Organ",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8693,
                        "title": "Percussion",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8253,
                        "title": "Piano",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8692,
                        "title": "Pipes",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8254,
                        "title": "Saxophone",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8695,
                        "title": "Strings",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8696,
                        "title": "Synthesizer",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8698,
                        "title": "Trombone",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8255,
                        "title": "Trumpet",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8699,
                        "title": "Vibraphone",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8697,
                        "title": "Violin",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 147,
                "name": "Languages",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8654,
                        "title": "African",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8655,
                        "title": "Arabic",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8653,
                        "title": "Celtic",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8656,
                        "title": "Creole",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8657,
                        "title": "Danish",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8290,
                        "title": "Dialect",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8264,
                        "title": "Dutch",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8267,
                        "title": "English",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8265,
                        "title": "French",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8266,
                        "title": "German",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8651,
                        "title": "Greek",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8268,
                        "title": "Italian",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8658,
                        "title": "Norwegian",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8652,
                        "title": "Portuguese",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8650,
                        "title": "Spanish",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8659,
                        "title": "Swedish",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8660,
                        "title": "Turkish",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 184,
                "name": "Themes",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8663,
                        "title": "Ambiance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8130,
                        "title": "Après Ski",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8683,
                        "title": "Birthday",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8284,
                        "title": "Carnival",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8285,
                        "title": "Christmas",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8270,
                        "title": "Football",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8287,
                        "title": "Halloween",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8271,
                        "title": "Kids Corner",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8764,
                        "title": "New Year",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8649,
                        "title": "Party Disco",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8288,
                        "title": "Party Oldies",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8286,
                        "title": "Saint Nicholas",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8647,
                        "title": "St. Patrick's Day",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8273,
                        "title": "Summer",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8676,
                        "title": "Teenager",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8648,
                        "title": "Valentine",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 185,
                "name": "Years",
                "musicChannelGroupId": 0,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8706,
                        "title": "10's",
                        "description": "01",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8705,
                        "title": "00's",
                        "description": "02",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8704,
                        "title": "90's",
                        "description": "03",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8470,
                        "title": "80's",
                        "description": "04",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8616,
                        "title": "70's",
                        "description": "05",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8615,
                        "title": "60's",
                        "description": "06",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8614,
                        "title": "50's",
                        "description": "07",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8703,
                        "title": "40's",
                        "description": "08",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8715,
                        "title": "30's",
                        "description": "09",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            }
        ]
    },
    {
        "id": 1,
        "name": "Search Lists",
        "type": "search",
        "musicChannels": [
            {
                "id": 188,
                "name": "Tunify Tips",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "TunifyTipList",
                        "id": 8713,
                        "title": "Tips Alternative",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 2,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8737,
                        "title": "Tips Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 2,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8708,
                        "title": "Tips Pop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 2,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8738,
                        "title": "Tips Pop Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 0,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8714,
                        "title": "Tips Pop Lite",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 0,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8707,
                        "title": "Tips Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 1,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8712,
                        "title": "Tips Soul",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 0,
                        "version": "740-1725"
                    },
                    {
                        "@type": "TunifyTipList",
                        "id": 8709,
                        "title": "Tips Urban",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null,
                        "nbTipsOfTheDay": 1,
                        "version": "740-1725"
                    }
                ]
            },
            {
                "id": 183,
                "name": "Hit Parades",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8619,
                        "title": "Pop",
                        "description": "01",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null
                    },
                    {
                        "@type": "Playlist",
                        "id": 8620,
                        "title": "Lite",
                        "description": "02",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null
                    },
                    {
                        "@type": "Context",
                        "id": 8757,
                        "title": "Years FR - Les 00's",
                        "description": "02",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": false,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Playlist",
                        "id": 8621,
                        "title": "Dance",
                        "description": "03",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null
                    },
                    {
                        "@type": "Playlist",
                        "id": 8622,
                        "title": "Alternative",
                        "description": "04",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "audioFiles": null
                    },
                    {
                        "@type": "Context",
                        "id": 8793,
                        "title": "Other Countries",
                        "description": "10",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 194,
                "name": "Best Of",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8795,
                        "title": "Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8798,
                        "title": "Disco",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8794,
                        "title": "Pop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8799,
                        "title": "Pop Lite",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8796,
                        "title": "Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8797,
                        "title": "Soul",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8792,
                        "title": "Urban",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 128,
                "name": "Countries",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8716,
                        "title": "Africa",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8809,
                        "title": "Albania",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8301,
                        "title": "Arab",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8257,
                        "title": "Asia",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8291,
                        "title": "Austria",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8258,
                        "title": "Belgium",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8292,
                        "title": "Brazil",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8293,
                        "title": "Canada",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8743,
                        "title": "Caribbean",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8744,
                        "title": "Cuba",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8294,
                        "title": "Denmark",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8296,
                        "title": "Finland",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8297,
                        "title": "France",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8298,
                        "title": "Germany",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8299,
                        "title": "Greece",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8300,
                        "title": "Ireland",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8302,
                        "title": "Italy",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8303,
                        "title": "Jamaica",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8256,
                        "title": "Latin America",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8745,
                        "title": "Mexico",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8304,
                        "title": "Netherlands",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8305,
                        "title": "Norway",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8306,
                        "title": "Oceania",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8719,
                        "title": "Portugal",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8720,
                        "title": "Spain",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8721,
                        "title": "Sweden",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8681,
                        "title": "Switzerland",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8718,
                        "title": "Turkey",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8295,
                        "title": "UK",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8717,
                        "title": "USA",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 131,
                "name": "Dancing Styles",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8260,
                        "title": "Boogie",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8671,
                        "title": "Cha Cha",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8800,
                        "title": "Disco",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8259,
                        "title": "Discofox",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8662,
                        "title": "Folk Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8627,
                        "title": "Foxtrot",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8664,
                        "title": "Fun Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8682,
                        "title": "Latin",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8669,
                        "title": "Line Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8727,
                        "title": "Quickstep",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8261,
                        "title": "Rock & Roll",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8262,
                        "title": "Rumba & Bolero",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8674,
                        "title": "Salsa",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8678,
                        "title": "Samba",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8263,
                        "title": "Slow",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8701,
                        "title": "Slow Fox",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8672,
                        "title": "Swing",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8677,
                        "title": "Tango",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8661,
                        "title": "Waltz",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8680,
                        "title": "Zouk",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 186,
                "name": "Genres",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8723,
                        "title": "Alternative",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8632,
                        "title": "Blues",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8810,
                        "title": "Bossa Nova",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8639,
                        "title": "Britpop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8631,
                        "title": "Chanson",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8630,
                        "title": "Country",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8670,
                        "title": "Country Lite",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8634,
                        "title": "Dance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8640,
                        "title": "Dancehall",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8272,
                        "title": "Disco",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8722,
                        "title": "Drum & Bass",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8726,
                        "title": "Dubstep",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8633,
                        "title": "Easy Listening",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8629,
                        "title": "Electronic",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8641,
                        "title": "Flower Power",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8628,
                        "title": "Folk",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8679,
                        "title": "Funk",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8642,
                        "title": "Glamour",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8813,
                        "title": "Grunge",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8811,
                        "title": "Hard Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8635,
                        "title": "House",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8626,
                        "title": "Jazz",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8625,
                        "title": "Kleinkunst",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8624,
                        "title": "Latin",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8802,
                        "title": "Latin Pop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8289,
                        "title": "Lounge",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8282,
                        "title": "Metal",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8643,
                        "title": "New Beat",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8644,
                        "title": "New Romantics",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8645,
                        "title": "New Wave",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8274,
                        "title": "Pop",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8803,
                        "title": "Pop Alternative",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8668,
                        "title": "Pop Lite",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8646,
                        "title": "Popcorn",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8814,
                        "title": "Progressive Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8812,
                        "title": "Punk",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8638,
                        "title": "R&B",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8276,
                        "title": "Reggae",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8673,
                        "title": "Reggaeton",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8275,
                        "title": "Rock",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8804,
                        "title": "Rock Alternative",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8277,
                        "title": "Rock & Roll",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8801,
                        "title": "Soul",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8636,
                        "title": "Techno",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8279,
                        "title": "Traditional Music",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8637,
                        "title": "Trance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8280,
                        "title": "Urban",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8281,
                        "title": "World",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 144,
                "name": "Instruments",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8684,
                        "title": "Accordion",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8686,
                        "title": "Bagpipe",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8269,
                        "title": "Banjo",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8700,
                        "title": "Cello",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8687,
                        "title": "Contrabass",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8685,
                        "title": "Guitar Acoustic",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8688,
                        "title": "Guitar Electric",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8694,
                        "title": "Guitar Steel",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8689,
                        "title": "Hammond Organ",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8690,
                        "title": "Harmonica",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8691,
                        "title": "Harp",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8199,
                        "title": "Organ",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8693,
                        "title": "Percussion",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8253,
                        "title": "Piano",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8692,
                        "title": "Pipes",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8254,
                        "title": "Saxophone",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8695,
                        "title": "Strings",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8696,
                        "title": "Synthesizer",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8698,
                        "title": "Trombone",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8255,
                        "title": "Trumpet",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8699,
                        "title": "Vibraphone",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8697,
                        "title": "Violin",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 147,
                "name": "Languages",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8654,
                        "title": "African",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8655,
                        "title": "Arabic",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8653,
                        "title": "Celtic",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8656,
                        "title": "Creole",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8657,
                        "title": "Danish",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8290,
                        "title": "Dialect",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8264,
                        "title": "Dutch",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8267,
                        "title": "English",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8265,
                        "title": "French",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8266,
                        "title": "German",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8651,
                        "title": "Greek",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8268,
                        "title": "Italian",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8658,
                        "title": "Norwegian",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8652,
                        "title": "Portuguese",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8650,
                        "title": "Spanish",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8659,
                        "title": "Swedish",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8660,
                        "title": "Turkish",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 184,
                "name": "Themes",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8663,
                        "title": "Ambiance",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8130,
                        "title": "Après Ski",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8683,
                        "title": "Birthday",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8284,
                        "title": "Carnival",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8285,
                        "title": "Christmas",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8270,
                        "title": "Football",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8287,
                        "title": "Halloween",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8271,
                        "title": "Kids Corner",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8764,
                        "title": "New Year",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8649,
                        "title": "Party Disco",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8288,
                        "title": "Party Oldies",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8286,
                        "title": "Saint Nicholas",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8647,
                        "title": "St. Patrick's Day",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8273,
                        "title": "Summer",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8676,
                        "title": "Teenager",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8648,
                        "title": "Valentine",
                        "description": "Zoeklijst",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            },
            {
                "id": 185,
                "name": "Years",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8706,
                        "title": "10's",
                        "description": "01",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8705,
                        "title": "00's",
                        "description": "02",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8704,
                        "title": "90's",
                        "description": "03",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8470,
                        "title": "80's",
                        "description": "04",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8616,
                        "title": "70's",
                        "description": "05",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8615,
                        "title": "60's",
                        "description": "06",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8614,
                        "title": "50's",
                        "description": "07",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8703,
                        "title": "40's",
                        "description": "08",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    },
                    {
                        "@type": "Context",
                        "id": 8715,
                        "title": "30's",
                        "description": "09",
                        "lastChanged": null,
                        "editable": false,
                        "visible": true,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null
                    }
                ]
            }
        ]
    }
]};
