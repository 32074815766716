<form class="search-field-container"
  [formGroup]="searchForm"
  (ngSubmit)="onSearch(searchInput.value)">

  <!--<div
    class="icon-container left-icon-container"
    (click)="onSearchIconClicked()"
  >
    <tun-menu-search-icon-v5  class="icon icon-scaled-svg-button"></tun-menu-search-icon-v5>
  </div>
  -->

  <div
    class="search-input-container"
  >
    <input #searchInput type="text" placeholder="{{'search.textField.placeholder' | translate}}"
		formControlName="searchTerm" (focus)="searchfocus.emit(true)" (blur)="searchfocus.emit(false)"
		(keydown.escape)="onEscapeSearch()">
  </div>

  <div
    *ngIf="searchInput.value"
    class="icon-container right-icon-container"
    (click)="onRemoveLastSearch()"
  >
    <tun-close-icon  class="icon icon-scaled-svg-button remove-search-icon"></tun-close-icon>
  </div>

  <button *ngIf="searchInput.value" class="search-button" (click)="onSearchIconClicked()">
    <tun-menu-search-icon-v5  class="icon icon-scaled-svg-button search-button-icon"></tun-menu-search-icon-v5>
    {{'search.tab.label' | translate}}
  </button>


</form>
