<div class="dropWrapper"
  cdkDropList
  id="queueHeader"
  #queueHeader="cdkDropList"
  [cdkDropListData]="newTracks"
  (cdkDropListDropped)="drop($event)"
  [cdkDropListConnectedTo]="['queue']"
>

  <div class="headerWrapper {{(amountTracks$ | async) == 0 ? 'empty' : 'hasItems'}}">

    <button class="icon-button" [disabled]="(amountTracks$ | async) == 0" (click)="onToggleOpen()">
      <tun-angle-up *ngIf="open" class="icon icon-scaled-svg-button icon-colored"></tun-angle-up>
      <tun-angle-down *ngIf="!open" class="icon icon-scaled-svg-button icon-colored"></tun-angle-down>
    </button>


    <tun-scrollable-text class="queueTitle" [text]="queueTitle$ | async"></tun-scrollable-text>


    <button class="icon-button" [disabled]="(amountTracks$ | async) == 0" (click)="onShuffle()">
      <tun-shuffle-icon-v5 class="icon icon-scaled-svg-button"></tun-shuffle-icon-v5>
    </button>

    <button class="icon-button" [disabled]="(amountTracks$ | async) == 0"
    (click)="onClear()">
      <tun-delete-icon-v5 class="icon icon-scaled-svg-button"></tun-delete-icon-v5>
    </button>

  </div>

</div>





