import { Component, OnDestroy } from '@angular/core';
import { AppV5StateService, TunifyV5Tab } from '@service/app-v5/app-v5-state.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'tun-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnDestroy {

  public TunifyV5Tab = TunifyV5Tab;

  public get selectedTab$(){
    return this.appV5StateService.selectedTab$;
  }

  constructor(
    private appV5StateService: AppV5StateService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

}
