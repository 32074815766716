<div
  #textfield
  class="text-field-text-container"
>
  <div
    #textfieldtext
    class="text-field-text"
    [style.margin-left]="textFieldMarginLeft"
    [style.-webkit-transition]="transition"
    [style.-moz-transition]="transition"
    [style.transition]="transition"
  >
    {{ text }}
  </div>
</div>
