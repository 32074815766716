import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { fadeAnimation, slideAnimation, viewPositionAnimation } from '@util/animations';
import { Subject } from 'rxjs';

@Component({
  selector: 'tun-home-panel',
  templateUrl: './home-panel.component.html',
  styleUrls: ['./home-panel.component.scss'],
  animations: [
    viewPositionAnimation,
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, { params: { duration: 10 } })
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        useAnimation(slideAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            startX: 0,
            endX: '100%'
          }
        })
      ])
    ])
  ]
})
export class HomePanelComponent implements OnDestroy {

  public mainViewPosition = "mid";

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

}
