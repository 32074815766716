import { Component, Input } from '@angular/core';

@Component({
  selector: 'tun-loader-v5',
  templateUrl: './loader-v5.component.html',
  styleUrls: ['./loader-v5.component.scss']
})
export class LoaderV5Component {

  // === Props === //
  @Input() text = 'general.status.loading';
}
