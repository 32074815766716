import { TweakStatus } from './enums/tweakStatus';
import { ChangeableParameter } from './changeableParameter';

export class TweakInfo {
    percentage: number;
    status: TweakStatus;
    amountTweakedTracks: number;
    amountTotalTracks: number;

    constructor(tweakInfo ?: TweakInfo ){
        if (tweakInfo){
            this.percentage = tweakInfo.percentage;
            this.status = tweakInfo.status;
            this.amountTweakedTracks = tweakInfo.amountTweakedTracks;
            this.amountTotalTracks = tweakInfo.amountTotalTracks;
        }
    }
}

export interface TweakInfoHolder {
    //we need these properties to load the tweakInfo
    contextId: number;
    changeableParameters: ChangeableParameter[];
    
    //the actual tweak info property
    tweakInfo: TweakInfo;

    //extra properties about the loading condition
    tweakInfoLoading: boolean;
    tweakInfoLoaded: boolean;
    tweakInfoLoadingError: string;
}
