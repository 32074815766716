import { Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '../loggers/logger.service';
import { MessageApiService, DTO_MessageBlock, DTO_MessageSchedule } from '../api/message-api.service';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BrandMessageQueueService } from './brand-message-queue.service';
import { PlayStateService } from '../audio/play-state.service';
import { MusicPlayerService } from '../audio/music-player.service';
import { PlayState } from '@model/enums/playState.enum';
import { BrandMessage } from '../../model/brandMessage';
import { BrandMessageBufferService } from '../audio/brand-message-buffer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Config } from '@service/config';

export class MessageSchedule{
  scheduleValidity: Date;
  messageBlocks: MessageBlock[];
  brandMessages: BrandMessage[];

  constructor(dtoMessageSchedule?: DTO_MessageSchedule){
    if (dtoMessageSchedule){
        const dateStringToConvert = dtoMessageSchedule.scheduleValidityDateTime;
        const offset = new Date().getTimezoneOffset();

        const localDateTime = dateStringToConvert != ""?new Date(dateStringToConvert):new Date();

        this.scheduleValidity = localDateTime;
        if (dtoMessageSchedule.messageBlocks){
          this.messageBlocks = dtoMessageSchedule.messageBlocks.map(dtoMessageBlock => new MessageBlock(dtoMessageBlock));
        }
        if (dtoMessageSchedule.messages){
          this.brandMessages = dtoMessageSchedule.messages.map(dtoMessage => new BrandMessage(dtoMessage));
        }
    }
  }

}

export class MessageBlock {
  playTime: Date;
  interruptMusic: Boolean;
  messageIds: number[];

  constructor(dtoMessageBlock?: DTO_MessageBlock){
    if (dtoMessageBlock){
        const dateStringToConvert = dtoMessageBlock.playDateTime;
        const offset = new Date().getTimezoneOffset();

        const localDateTime = dateStringToConvert != ""?new Date(dateStringToConvert):new Date();

        this.playTime = localDateTime;
        this.interruptMusic = dtoMessageBlock.interruptMusic;
        this.messageIds = dtoMessageBlock.messageIds;
    }
  }

}




@Injectable({
  providedIn: 'root'
})
export class MessageBlocksService implements OnDestroy {

  private LOGGER_CLASSNAME = 'MessageBlocksService';

  private readonly fetchMinutes = 60;
  private readonly simulateErrorPercentage = 0.0; // fill in percentage to test errors

  constructor(
    private loggerService: LoggerService,
    private messageApiService: MessageApiService,
    private zoneConnectionsService: ZoneConnectionsService,
    private brandMessageQueueService: BrandMessageQueueService,
    private playStateService: PlayStateService,
    private musicPlayerService: MusicPlayerService,
    private brandMessageBufferService: BrandMessageBufferService
  ) {
    this.zoneConnectionsService.activeZoneConnection$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      () => {
        this.unloadData();
        this.loadMessageBlocks();
      }
    )

    //look at the playState -> pause brandMessages when we are not playing (or starting to play)
    this.musicPlayerService.playerState$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (playState) => {
        this.pauzed = playState != PlayState.PLAYING && playState != PlayState.STARTING_TO_PLAY
      }
    )

  }

  /*
  private testMessage(){
    const message = new BrandMessage();
    message.id = -1;
    message.title = 'Test';
    message.duration = 7000;
    message.url = 'https://messages-cdn.tunify.com/mp3/mono/256/87/870dab5f628430669cfbad3e180b8ae1.mp3';
    this.brandMessageQueueService.addToQueue([message]);

  }
    */

  /*
  private testMessages(){

    let message = new BrandMessage();
    message.id = -1;
    message.title = 'Retail - Passen van kleding join stereo';
    message.duration = 7000;
    message.url = 'https://odoo-test.tunify.com/tunify/html/download/1.mp3';
    this.brandMessageQueueService.addToQueue([message]);

    message = new BrandMessage();
    message.id = -2;
    message.title = 'Retail - Passen van kleding mono';
    message.duration = 7000;
    message.url = 'https://odoo-test.tunify.com/tunify/html/download/2.mp3';
    this.brandMessageQueueService.addToQueue([message]);

    message = new BrandMessage();
    message.id = -3;
    message.title = 'Retail - Passen van kleding stereo';
    message.duration = 7000;
    message.url = 'https://odoo-test.tunify.com/tunify/html/download/3.mp3';
    this.brandMessageQueueService.addToQueue([message]);
  }
  */

  //Switch to pause the schedule. This is the idea:
  //- a fetched schedule keeps on running until it should play a block. When a block should be played and the schedule is on pause, the schedule is cleared (instead of playing the block).
  //- when validity of the current schedule ends: do not fetch a new schedule if we are paused
  //- when the schedule is unpaused -> check if we need to fetch a new one (cleared or invalid by now)
  private _pauzed = false
  private set pauzed(value: boolean){
    if (this._pauzed != value){
      this._pauzed = value
      if (!this._pauzed){
        if (!this.isCurrentScheduleValid){
          this.loadMessageBlocks();
        }
      }
    }
  }
  private get pauzed(): boolean{
    return this._pauzed
  }


  private currentValidityEndDate: Date = null;
  private adjustValidity(validityEndDate: Date){
    if (validityEndDate != null){
      this.currentValidityEndDate = validityEndDate;
      this.adjustLoadTimerForValidity();
      this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustValidity", "Going to adjust validity to " + validityEndDate.toString());
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "adjustValidity", "No validity end date");
    }

  }

  private get isCurrentScheduleValid(): boolean{
    return this.currentValidityEndDate != null && this.currentValidityEndDate > new Date()
  }

  private validityTimeout: NodeJS.Timeout;
  private adjustLoadTimerForValidity() {
    this.clearValidityTimer();

    const now = new Date();
    if (this.isCurrentScheduleValid){
      const validityUntilInvalid = this.currentValidityEndDate.getTime() - now.getTime();
      if (validityUntilInvalid != undefined) {
        this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustTimerForValidity", "going to start timer based on validity of " + validityUntilInvalid + " milliseconds");
        this.validityTimeout = setTimeout(() => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, "validityTimeout", "validityTimeout fired. Going to load messageSchedule");
          this.loadMessageBlocks();

        }, Math.max(validityUntilInvalid + 200, 1000));
      }
    }else{
      //not valid -> fetch, timeout depending on the amount of errors
      const delay = Math.min(Config.RETRY_MAX_INTERVAL_MS, Math.pow(2, this.errorsInARow - 1) * Config.RETRY_BASE_INTERVAL_MS);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "validityTimeout", "No validity or already invaliid. Going to wait " + delay + "milliseconds");
      this.validityTimeout = setTimeout(() => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, "validityTimeout", "validityTimeout fired. Going to load messageSchedule");
        this.loadMessageBlocks();
      }, delay);

    }

  }
  private clearValidityTimer() {
    if (this.validityTimeout) {
      clearTimeout(this.validityTimeout);
      this.validityTimeout = null;
    }
  }

  private destroy$ = new Subject<void>();
  // === Methods === //
  ngOnDestroy() {
    this.unloadData();

    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = null;

    this.cancelCurrentLoadMessagesRequest$.next;
    this.cancelCurrentLoadMessagesRequest$.complete();
    this.cancelCurrentLoadMessagesRequest$ = null;
  }


  private unloadData(){
    this.cancelCurrentLoadMessagesRequest$.next();

    this._loading = false;
    this._loadingError = null;

    this.currentBrandMessages = null;
    this.currentMessageBlocks = [];
    this.currentValidityEndDate = null;

    this.clearValidityTimer();
    this.clearNextBlockTimer();
  }


  /**
     * Loading state
     * @type {boolean}
     * @private
     */
  private get _loading(): boolean {
    return this._loadingSubject.value;
  }
  private set _loading(value: boolean) {
    if (this._loading !== value) {
      this._loadingSubject.next(value);
    }
  }
  get loading(): boolean {
    return this._loading;
  }
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loadingSubject.asObservable();



  /**
   * Error emitter for retrieving the messageBlocks
   * @type {string}
   * @private
   */
  private get _loadingError(): string {
    return this._loadingErrorSubject.value;
  }
  private set _loadingError(value: string) {
    if (this._loadingError !== value) {
      this._loadingErrorSubject.next(value);
    }
  }
  get loadingError(): string {
    return this._loadingError;
  }
  private _loadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public loadingError$: Observable<string> = this._loadingErrorSubject.asObservable();

  /**
   * Versioning
   */
 private messagesPlanningTimestamp: number = undefined;
 private timestampFromUpdateTrigger: number = undefined; //timestamp that we have received after a save or update event. This is used when the data is fetched to check if we have the most recent version
 private refetchedToAvoidVersionConflict = false; //boolean to avoid we fetch multiple times in a row (avoid endless loop when the server messes up the version numbers)

 public handleMessagesPlanningUpdateInfo(timestamp: number){
   //keep track of the highest seen timestamp
   if (timestamp != undefined){
     if (this.timestampFromUpdateTrigger == undefined || this.timestampFromUpdateTrigger < timestamp) {
       this.timestampFromUpdateTrigger = timestamp;
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleMessagesPlanningUpdateInfo', 'Going to check for newest version:  ' + timestamp);
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleMessagesPlanningUpdateInfo', 'Already going to check for an even newer version:  ' + this.timestampFromUpdateTrigger + '(we just received: ' + timestamp + ')');
     }
   }else{
     this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleMessagesPlanningUpdateInfo', 'No timestamp info');
   }

   this.checkReloadMessageBlocksNeeded();
 }

 private checkReloadMessageBlocksNeeded(){
   if (this.timestampFromUpdateTrigger != undefined){
     //if we are not loading the messageBlocks -> fetch
     if (!this.loading) {
       if (this.timestampFromUpdateTrigger > this.messagesPlanningTimestamp){
         this.loadMessageBlocks();
       }else{
         this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadCalendarGroupsNeeded', 'Already have a newer version (' + this.messagesPlanningTimestamp + '), not going to reload for update trigger timestamp ' + this.timestampFromUpdateTrigger);
       }
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadCalendarGroupsNeeded', 'Not going to load right now: loading: ' + (this.loading ? 'true': 'false') + '.');
     }
   }
 }


  private cancelCurrentLoadMessagesRequest$ = new Subject<void>();
  private errorsInARow = 0;
  private loadMessageBlocks(){
    if (this.zoneConnectionsService.activeZoneConnection != null){

      if (this.pauzed){
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadData', 'schedule is pauzed -> no need to fetch until we unpause');
      }else{
      this.cancelCurrentLoadMessagesRequest$

      const startDate = new Date();
      const endDate = new Date();
      endDate.setTime(startDate.getTime() + this.fetchMinutes * 60 * 1000); //add period to fetch to startDate

      const messageObservable = this.messageApiService.loadMessageBlocks(startDate, endDate);

      if (messageObservable != null){

        this._loading = true;

        messageObservable
        .pipe(
          takeUntil(
            merge(
              this.destroy$,
              this.cancelCurrentLoadMessagesRequest$
            )
          ),
          finalize(() => {
            this._loading = false;
            this.checkReloadMessageBlocksNeeded()
          })
        )
        .subscribe(
          data => {

            //for testing
            //this.testMessages();

            if (Math.random() < this.simulateErrorPercentage / 100){
              //simulate error
              this.errorsInARow++;
              this._loadingError = 'Simulation Error';

              //this wil reload the messageSchedule after a short waiting period
              this.adjustLoadTimerForValidity();
            }else{

              if (data != null){

                let useResponse = true;
                if (this.timestampFromUpdateTrigger != undefined && this.timestampFromUpdateTrigger > data.timestamp) {
                  //there is a newer resource version on the server -> refetch
                  if (!this.refetchedToAvoidVersionConflict) {
                    //we are only going to refetch once, to avoid endless loop of fetching data when there is something wrong with our version timestamps
                    this.refetchedToAvoidVersionConflict = true;
                    useResponse = false;
                  } else {
                    //ignore the newer version and start using this queue

                    this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadMessageBlocks', 'loadMessageBlocks receives an older version number but we already refetched before -> just start working with this version');
                  }
                }
                if (useResponse) {
                   //transform dto to usable object
                  const messageSchedule = new MessageSchedule(data.messageSchedule);

                  this.messagesPlanningTimestamp = data.timestamp;

                  if (messageSchedule.scheduleValidity != null && messageSchedule.scheduleValidity.getTime() > new Date().getTime()){
                    this.errorsInARow = 0;
                    this.adjustValidity(messageSchedule.scheduleValidity);

                    this.currentBrandMessages = messageSchedule.brandMessages;

                    this.addMessageBlocks(messageSchedule.messageBlocks);

                    //this.testMessage();

                    if (messageSchedule.brandMessages){
                      messageSchedule.brandMessages.forEach(brandMesssage => {
                        this.brandMessageBufferService.addBrandMessageToBuffer(brandMesssage);
                      })
                    }

                  }else{
                    this.loggerService.error(this.LOGGER_CLASSNAME, 'loadMessageBlocks', 'validity is not null or not in the future: ' + messageSchedule.scheduleValidity + ' -> error');
                    this.errorsInARow++;
                    this._loadingError = 'Validity Error';
                    this.adjustLoadTimerForValidity();
                  }
                }

              }else{
                this.loggerService.error(this.LOGGER_CLASSNAME, 'loadMessageBlocks', 'no data in resposne -> error');
                this.errorsInARow++;
                this._loadingError = 'No Data Error';
                this.adjustLoadTimerForValidity();
              }
            }
          },
          (error: unknown) => {
            let errMsg = '';
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';

            }else if (error instanceof Error){
              errMsg = error.message;
            }else{
              errMsg = 'unknown error type: ' + error;
            }

            this.errorsInARow++;
            this._loadingError = 'General Error';

            //this wil reload the messageSchedule after a short waiting period
            this.adjustLoadTimerForValidity();

            this.loggerService.error(this.LOGGER_CLASSNAME, "loadMessageBlocks", "Error occured: " + errMsg);
          });
      } else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "loadMessageBlocks", "No observable, so nothing will be fetched")
      }

    }



    }
  }

  private currentBrandMessages: BrandMessage[];

  private currentMessageBlocks: MessageBlock[] = [];
  private addMessageBlocks(messageBlocks: MessageBlock[]){
    //for now, just clear all current blocks
    this.currentMessageBlocks = [];

    //add all new blocks
    this.currentMessageBlocks = this.currentMessageBlocks.concat(messageBlocks);

    //sort by date
    this.currentMessageBlocks.sort(function(a,b){
      return a.playTime.getTime() - b.playTime.getTime();
    });

    this.adjustTimerForNextBlock();
  }


  //For testing purpose:
  private testFirstBlockFast = false;

  private nextBlockTimeout: NodeJS.Timeout;
  private adjustTimerForNextBlock() {
    this.clearNextBlockTimer();


    if (this.currentMessageBlocks != null && this.currentMessageBlocks.length > 0){
      let firstBlock = this.currentMessageBlocks[0];
      if (firstBlock.playTime != null){
        let now = new Date();
        let millisUntilNextBlock = firstBlock.playTime.getTime() - now.getTime();

        //for testing purpose:
        if (this.testFirstBlockFast){
          this.testFirstBlockFast = false;
          millisUntilNextBlock = 10000;
        }

        if (millisUntilNextBlock != undefined && millisUntilNextBlock > 100) {
          this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustTimerForNextBlock", "going to start timer of " + millisUntilNextBlock + " milliseconds");
          this.nextBlockTimeout = setTimeout(() => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, "nextBlockTimeout", "timer fired. Going to play first block");
            this.playFirstBlock();
          }, millisUntilNextBlock);
        }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustTimerForNextBlock", "block needs to start right away");
          this.playFirstBlock();
        }
      }else{
        let skippedBlock = this.currentMessageBlocks.shift();
        this.loggerService.error(this.LOGGER_CLASSNAME, "adjustTimerForNextBlock", "block has no playTime. Going to skip messages " + skippedBlock.messageIds);
        this.adjustTimerForNextBlock();
      }


    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustTimerForNextBlock", "No next block, nothing to do" );

    }

  }
  private clearNextBlockTimer() {
    if (this.nextBlockTimeout) {
      clearTimeout(this.nextBlockTimeout);
      this.nextBlockTimeout = null;
    }
  }


  private playFirstBlock(){

    if (this.pauzed){
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'playBlock', 'The schedule is on pause while we should start a block -> clear current schedule until we unpause');
      this.unloadData();
    }else{
      let blockToPlay = this.currentMessageBlocks.shift();
    this.loggerService.debug(this.LOGGER_CLASSNAME, 'playBlock', 'going to play block for startDate ' + blockToPlay.playTime + ' with messageIds ' + blockToPlay.messageIds);

    let brandMessagesToPlay = blockToPlay.messageIds.map(id => this.currentBrandMessages.find(message => message.id === id));

    if (brandMessagesToPlay.length > 0){
      //translate ids to messages
      this.brandMessageQueueService.addToQueue(brandMessagesToPlay);


      //todo -> save playstate somewhere so we can switch back to a pause state after all brandMessages have been played?

      if (blockToPlay.interruptMusic){
        //if we need to play right away -> skip to next track (this will be a brandMessage)
        this.playStateService.startNextAudioFile(brandMessagesToPlay[0], true);
      }else{
        //If we are not playing -> start message right away
        if (this.musicPlayerService.playState != PlayState.PLAYING){
          this.playStateService.startNextAudioFile(brandMessagesToPlay[0], true);
        }
      }

    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'playFirstBlock', '0 brandMessagesToPlay after converting to real messages. id: ' + blockToPlay.messageIds);
    }

    //start timer to new next block
    this.adjustTimerForNextBlock();
    }


  }
}
