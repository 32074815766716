export const calendar_12 = {
    "id": 12,
    "calendarId": 12,
    "name": "Eigentijds Café",
    "calendarGroupId": 196,
    "isPublished": true,
    "timestamp": 123456789,
    "calendarItems": [
        {
            "id": 3242834,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 16 ? * 3",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242835,
            "title": "Stimulating",
            "musicChannelId": 134,
            "musicCollectionID": 8127,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 4",
            "duration": 120,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242836,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 18 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242837,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 9 ? * 3",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242838,
            "title": "Dance",
            "musicChannelId": 134,
            "musicCollectionID": 8312,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 17 ? * 3",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242839,
            "title": "Soul",
            "musicChannelId": 150,
            "musicCollectionID": 8513,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 12 ? * 2",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242840,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 0 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242841,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 12 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242842,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 12 ? * 4",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242843,
            "title": "Pop Rock",
            "musicChannelId": 134,
            "musicCollectionID": 8133,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1985,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1985,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 13 ? * 4",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242844,
            "title": "Disco",
            "musicChannelId": 134,
            "musicCollectionID": 8143,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1971,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 19 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242845,
            "title": "Disco",
            "musicChannelId": 134,
            "musicCollectionID": 8143,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1971,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 18 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242846,
            "title": "Disco",
            "musicChannelId": 134,
            "musicCollectionID": 8143,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1971,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 9 ? * 4",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242847,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 10 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242848,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2011,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 4 ? * 4",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242849,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 7 ? * 3",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242850,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 11 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242851,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 13 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242852,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 16 ? * 4",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242853,
            "title": "Pop",
            "musicChannelId": 132,
            "musicCollectionID": 8165,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 17 ? * 4",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242854,
            "title": "Pop",
            "musicChannelId": 134,
            "musicCollectionID": 8202,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 8 ? * 4",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": true
        },
        {
            "id": 3242855,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 2 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242856,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 4 ? * 2",
            "duration": 120,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242857,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 7 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242858,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 5 ? * 3",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242859,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 9 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242860,
            "title": "Dance",
            "musicChannelId": 134,
            "musicCollectionID": 8312,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": false
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 6,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 17 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242861,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 14 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242862,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": false
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": false
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 6 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242863,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 11 ? * 3",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242864,
            "title": "Ragga",
            "musicChannelId": 164,
            "musicCollectionID": 8500,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 3 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242865,
            "title": "R&B",
            "musicChannelId": 164,
            "musicCollectionID": 8501,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Mixed",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2013,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Mixed",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 15 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242866,
            "title": "Dancehall",
            "musicChannelId": 164,
            "musicCollectionID": 8504,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1992,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 21 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242867,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 21 ? * 4",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242868,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 16 ? * 2",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242869,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 135,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 3",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242870,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 5 ? * 2",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242871,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 23 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242872,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 13 ? * 3",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242873,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 10 ? * 3",
            "duration": 90,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242874,
            "title": "Urban Pop",
            "musicChannelId": 164,
            "musicCollectionID": 8499,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 4 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242875,
            "title": "Best Of",
            "musicChannelId": 164,
            "musicCollectionID": 8490,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1979,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 3 ? * 3",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242876,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 8 ? * 2",
            "duration": 120,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242877,
            "title": "Disco",
            "musicChannelId": 134,
            "musicCollectionID": 8143,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1971,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 17 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242878,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 6 ? * 2",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242879,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 13 ? * 2",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242880,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 0 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242881,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 6 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242882,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 11 ? * 2",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242883,
            "title": "Stimulating",
            "musicChannelId": 134,
            "musicCollectionID": 8127,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 0 ? * 2",
            "duration": 90,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242884,
            "title": "Trance",
            "musicChannelId": 130,
            "musicCollectionID": 8117,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 1 ? * 2",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242885,
            "title": "House",
            "musicChannelId": 130,
            "musicCollectionID": 8118,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 1 ? * 2",
            "duration": 120,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242886,
            "title": "Hit Parade",
            "musicChannelId": 134,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 3 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242887,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 9 ? * 2",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242888,
            "title": "Dance",
            "musicChannelId": 134,
            "musicCollectionID": 8312,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 13 ? * 2",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242889,
            "title": "Hit Parade",
            "musicChannelId": 134,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 15 ? * 2",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242890,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 19 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242891,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 2 ? * 2",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242892,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 18 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242893,
            "title": "House",
            "musicChannelId": 130,
            "musicCollectionID": 8118,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 1 ? * 3",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242894,
            "title": "Trance",
            "musicChannelId": 130,
            "musicCollectionID": 8117,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 3 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242895,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 6 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242896,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 8 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242897,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 9 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242898,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 5 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242899,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 17 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242900,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 13 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242901,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 20 ? * 4",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242902,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 0 ? * 4",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242903,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 21 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242904,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 0 ? * 4",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242905,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 9 ? * 2",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242906,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 0 ? * 3",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242907,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 134,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 16 ? * 2",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242908,
            "title": "Stimulating",
            "musicChannelId": 134,
            "musicCollectionID": 8127,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 22 ? * 3",
            "duration": 90,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242909,
            "title": "Hit Parade",
            "musicChannelId": 134,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 15 ? * 3",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242910,
            "title": "Dance",
            "musicChannelId": 150,
            "musicCollectionID": 8557,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 0 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242911,
            "title": "Nu Urban",
            "musicChannelId": 146,
            "musicCollectionID": 8992,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 3 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242912,
            "title": "Indie Pop",
            "musicChannelId": 146,
            "musicCollectionID": 8182,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Folk",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Folk",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 4 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242913,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 7 ? * 2",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242914,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 8 ? * 2",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242915,
            "title": "House",
            "musicChannelId": 130,
            "musicCollectionID": 8118,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 10 ? * 2",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242916,
            "title": "Urban Reggae",
            "musicChannelId": 164,
            "musicCollectionID": 8495,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Ragga",
                            "selected": false
                        },
                        {
                            "value": "Rap",
                            "selected": false
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Ragga",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1992,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 14 ? * 2",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242917,
            "title": "Urban Dance",
            "musicChannelId": 164,
            "musicCollectionID": 8494,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1989,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1989,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 15 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242918,
            "title": "Best Of",
            "musicChannelId": 164,
            "musicCollectionID": 8490,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1979,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 17 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242919,
            "title": "Deep Beats",
            "musicChannelId": 192,
            "musicCollectionID": 8775,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1994,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 125,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1994,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1994,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 18 ? * 2",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242920,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 18 ? * 2",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242921,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 19 ? * 2",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242922,
            "title": "Dance Beats",
            "musicChannelId": 149,
            "musicCollectionID": 8339,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Eurodance",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Eurodance",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 22 ? * 2",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242923,
            "title": "Party Dance",
            "musicChannelId": 132,
            "musicCollectionID": 8166,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 23 ? * 2",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242924,
            "title": "Dance Grooves",
            "musicChannelId": 132,
            "musicCollectionID": 8160,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 114,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 114,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 114,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 114,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 21 ? * 2",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242925,
            "title": "Reggaeton",
            "musicChannelId": 164,
            "musicCollectionID": 8498,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 21 ? * 2",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242926,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 20 ? * 2",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242927,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 0 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242928,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 1 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242929,
            "title": "Progressive House",
            "musicChannelId": 130,
            "musicCollectionID": 8145,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 2 ? * 3",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242930,
            "title": "Soul R&B",
            "musicChannelId": 153,
            "musicCollectionID": 8206,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 1,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 4 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242931,
            "title": "Hitparade",
            "musicChannelId": 281,
            "musicCollectionID": 8855,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 5 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242932,
            "title": "Nu Urban",
            "musicChannelId": 146,
            "musicCollectionID": 8992,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 6 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242933,
            "title": "Bright",
            "musicChannelId": 146,
            "musicCollectionID": 8074,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Folk",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Folk",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 7 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242934,
            "title": "2014 - 2018",
            "musicChannelId": 132,
            "musicCollectionID": 8154,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 8 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242935,
            "title": "Best Of",
            "musicChannelId": 151,
            "musicCollectionID": 8344,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 9 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242936,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 12 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242937,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 13 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242938,
            "title": "Pop Rock",
            "musicChannelId": 151,
            "musicCollectionID": 8483,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 14 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242939,
            "title": "Dance",
            "musicChannelId": 150,
            "musicCollectionID": 8557,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 14 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242940,
            "title": "Urban Dance",
            "musicChannelId": 164,
            "musicCollectionID": 8494,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1989,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 15 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242941,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 19 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242942,
            "title": "Pop Rock",
            "musicChannelId": 151,
            "musicCollectionID": 8483,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 20 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242943,
            "title": "Disco",
            "musicChannelId": 150,
            "musicCollectionID": 8561,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1972,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1972,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1972,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242944,
            "title": "Soul",
            "musicChannelId": 150,
            "musicCollectionID": 8513,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 21 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242945,
            "title": "2014 - 2018  Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8665,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 20 ? * 3",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242946,
            "title": "2014 - 2018",
            "musicChannelId": 130,
            "musicCollectionID": 8065,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic-Dance",
                            "selected": true
                        },
                        {
                            "value": "Dubstep",
                            "selected": true
                        },
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Hardcore",
                            "selected": true
                        },
                        {
                            "value": "Hardstyle",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Tech House",
                            "selected": true
                        },
                        {
                            "value": "Techno",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic-Dance",
                            "selected": true
                        },
                        {
                            "value": "Dubstep",
                            "selected": true
                        },
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Hardcore",
                            "selected": true
                        },
                        {
                            "value": "Hardstyle",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Tech House",
                            "selected": true
                        },
                        {
                            "value": "Techno",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 19 ? * 3",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242947,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 16 ? * 6",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242948,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 9 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242949,
            "title": "Dance",
            "musicChannelId": 134,
            "musicCollectionID": 8312,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 17 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242950,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 0 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242951,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 12 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242952,
            "title": "Disco",
            "musicChannelId": 134,
            "musicCollectionID": 8143,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1971,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 18 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242953,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 7 ? * 6",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242954,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 5 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242955,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 11 ? * 6",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242956,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 135,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 6",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242957,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 23 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242958,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 13 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242959,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 10 ? * 6",
            "duration": 90,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242960,
            "title": "Urban Pop",
            "musicChannelId": 164,
            "musicCollectionID": 8499,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 4 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242961,
            "title": "Best Of",
            "musicChannelId": 164,
            "musicCollectionID": 8490,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1979,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 3 ? * 6",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242962,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 18 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242963,
            "title": "House",
            "musicChannelId": 130,
            "musicCollectionID": 8118,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 1 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242964,
            "title": "Trance",
            "musicChannelId": 130,
            "musicCollectionID": 8117,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 3 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242965,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 6 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242966,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 8 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242967,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 0 ? * 6",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242968,
            "title": "Stimulating",
            "musicChannelId": 134,
            "musicCollectionID": 8127,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 22 ? * 6",
            "duration": 90,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242969,
            "title": "Hit Parade",
            "musicChannelId": 134,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 15 ? * 6",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242970,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 0 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242971,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 1 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242972,
            "title": "Progressive House",
            "musicChannelId": 130,
            "musicCollectionID": 8145,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 2 ? * 6",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242973,
            "title": "Soul R&B",
            "musicChannelId": 153,
            "musicCollectionID": 8206,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 1,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 4 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242974,
            "title": "Hitparade",
            "musicChannelId": 281,
            "musicCollectionID": 8855,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 5 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242975,
            "title": "Nu Urban",
            "musicChannelId": 146,
            "musicCollectionID": 8992,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 6 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242976,
            "title": "Bright",
            "musicChannelId": 146,
            "musicCollectionID": 8074,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Folk",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Folk",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 7 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242977,
            "title": "2014 - 2018",
            "musicChannelId": 132,
            "musicCollectionID": 8154,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 8 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242978,
            "title": "Best Of",
            "musicChannelId": 151,
            "musicCollectionID": 8344,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 9 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242979,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 12 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242980,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 13 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242981,
            "title": "Pop Rock",
            "musicChannelId": 151,
            "musicCollectionID": 8483,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 14 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242982,
            "title": "Dance",
            "musicChannelId": 150,
            "musicCollectionID": 8557,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 14 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242983,
            "title": "Urban Dance",
            "musicChannelId": 164,
            "musicCollectionID": 8494,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1989,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 15 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242984,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 19 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242985,
            "title": "Pop Rock",
            "musicChannelId": 151,
            "musicCollectionID": 8483,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 20 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242986,
            "title": "Disco",
            "musicChannelId": 150,
            "musicCollectionID": 8561,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1972,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1972,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1972,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242987,
            "title": "Soul",
            "musicChannelId": 150,
            "musicCollectionID": 8513,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 21 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242988,
            "title": "2014 - 2018  Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8665,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 20 ? * 6",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242989,
            "title": "2014 - 2018",
            "musicChannelId": 130,
            "musicCollectionID": 8065,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic-Dance",
                            "selected": true
                        },
                        {
                            "value": "Dubstep",
                            "selected": true
                        },
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Hardcore",
                            "selected": true
                        },
                        {
                            "value": "Hardstyle",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Tech House",
                            "selected": true
                        },
                        {
                            "value": "Techno",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic-Dance",
                            "selected": true
                        },
                        {
                            "value": "Dubstep",
                            "selected": true
                        },
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Hardcore",
                            "selected": true
                        },
                        {
                            "value": "Hardstyle",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Tech House",
                            "selected": true
                        },
                        {
                            "value": "Techno",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 19 ? * 6",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242990,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 16 ? * 1",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242991,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 9 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242992,
            "title": "Dance",
            "musicChannelId": 134,
            "musicCollectionID": 8312,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 17 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242993,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 0 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242994,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 12 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242995,
            "title": "Disco",
            "musicChannelId": 134,
            "musicCollectionID": 8143,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1971,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 18 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242996,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 7 ? * 1",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242997,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 5 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3242998,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 11 ? * 1",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3242999,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 135,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 1",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243000,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 23 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243001,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 13 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243002,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 10 ? * 1",
            "duration": 90,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243003,
            "title": "Urban Pop",
            "musicChannelId": 164,
            "musicCollectionID": 8499,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 4 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243004,
            "title": "Best Of",
            "musicChannelId": 164,
            "musicCollectionID": 8490,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1979,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 3 ? * 1",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243005,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 18 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243006,
            "title": "House",
            "musicChannelId": 130,
            "musicCollectionID": 8118,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 1 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243007,
            "title": "Trance",
            "musicChannelId": 130,
            "musicCollectionID": 8117,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 3 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243008,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 6 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243009,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 8 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243010,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 0 ? * 1",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243011,
            "title": "Stimulating",
            "musicChannelId": 134,
            "musicCollectionID": 8127,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 22 ? * 1",
            "duration": 90,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243012,
            "title": "Hit Parade",
            "musicChannelId": 134,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 15 ? * 1",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243013,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 0 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243014,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 1 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243015,
            "title": "Progressive House",
            "musicChannelId": 130,
            "musicCollectionID": 8145,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 2 ? * 1",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243016,
            "title": "Soul R&B",
            "musicChannelId": 153,
            "musicCollectionID": 8206,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 1,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 4 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243017,
            "title": "Hitparade",
            "musicChannelId": 281,
            "musicCollectionID": 8855,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 5 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243018,
            "title": "Nu Urban",
            "musicChannelId": 146,
            "musicCollectionID": 8992,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 6 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243019,
            "title": "Bright",
            "musicChannelId": 146,
            "musicCollectionID": 8074,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Folk",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Folk",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 7 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243020,
            "title": "2014 - 2018",
            "musicChannelId": 132,
            "musicCollectionID": 8154,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 8 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243021,
            "title": "Best Of",
            "musicChannelId": 151,
            "musicCollectionID": 8344,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 9 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243022,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 12 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243023,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 13 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243024,
            "title": "Pop Rock",
            "musicChannelId": 151,
            "musicCollectionID": 8483,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 14 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243025,
            "title": "Dance",
            "musicChannelId": 150,
            "musicCollectionID": 8557,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 14 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243026,
            "title": "Urban Dance",
            "musicChannelId": 164,
            "musicCollectionID": 8494,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1989,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 15 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243027,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 19 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243028,
            "title": "Pop Rock",
            "musicChannelId": 151,
            "musicCollectionID": 8483,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 20 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243029,
            "title": "Disco",
            "musicChannelId": 150,
            "musicCollectionID": 8561,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1972,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1972,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1972,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243030,
            "title": "Soul",
            "musicChannelId": 150,
            "musicCollectionID": 8513,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 21 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243031,
            "title": "2014 - 2018  Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8665,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 20 ? * 1",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243032,
            "title": "2014 - 2018",
            "musicChannelId": 130,
            "musicCollectionID": 8065,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic-Dance",
                            "selected": true
                        },
                        {
                            "value": "Dubstep",
                            "selected": true
                        },
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Hardcore",
                            "selected": true
                        },
                        {
                            "value": "Hardstyle",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Tech House",
                            "selected": true
                        },
                        {
                            "value": "Techno",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic-Dance",
                            "selected": true
                        },
                        {
                            "value": "Dubstep",
                            "selected": true
                        },
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Hardcore",
                            "selected": true
                        },
                        {
                            "value": "Hardstyle",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Tech House",
                            "selected": true
                        },
                        {
                            "value": "Techno",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 19 ? * 1",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243033,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 2 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243034,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 10 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243035,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 11 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243036,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 3 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243037,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 6 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243038,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 7 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243039,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 0 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243040,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 5 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243041,
            "title": "Hit Parade Alternative",
            "musicChannelId": 142,
            "musicCollectionID": 8622,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 4 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243042,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 1 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243043,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 8 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243044,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 9 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243045,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 14 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243046,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 18 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243047,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 15 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243048,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 19 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243049,
            "title": "Hit Parade",
            "musicChannelId": 130,
            "musicCollectionID": 8729,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 22 ? * 4",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243050,
            "title": "R&B",
            "musicChannelId": 164,
            "musicCollectionID": 8501,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Mixed",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Mixed",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 12 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243051,
            "title": "Pop",
            "musicChannelId": 134,
            "musicCollectionID": 8202,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 13 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243052,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 16 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243053,
            "title": "2014 - 2018",
            "musicChannelId": 164,
            "musicCollectionID": 8493,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 17 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243054,
            "title": "House",
            "musicChannelId": 130,
            "musicCollectionID": 8118,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 20 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243055,
            "title": "Funky",
            "musicChannelId": 153,
            "musicCollectionID": 8205,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 21 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243056,
            "title": "Best Of",
            "musicChannelId": 130,
            "musicCollectionID": 8136,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Eurodance",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2016,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Eurodance",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 23 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243057,
            "title": "Stimulating",
            "musicChannelId": 134,
            "musicCollectionID": 8127,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 7",
            "duration": 120,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243058,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 18 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243059,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 12 ? * 7",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243060,
            "title": "Pop Rock",
            "musicChannelId": 134,
            "musicCollectionID": 8133,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1985,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1985,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 13 ? * 7",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243061,
            "title": "Disco",
            "musicChannelId": 134,
            "musicCollectionID": 8143,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1971,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 19 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243062,
            "title": "Disco",
            "musicChannelId": 134,
            "musicCollectionID": 8143,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1971,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 9 ? * 7",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243063,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 10 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243064,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": false
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2011,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 4 ? * 7",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243065,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 11 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243066,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 13 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243067,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 16 ? * 7",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243068,
            "title": "Pop",
            "musicChannelId": 132,
            "musicCollectionID": 8165,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 17 ? * 7",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243069,
            "title": "Pop",
            "musicChannelId": 134,
            "musicCollectionID": 8202,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 8 ? * 7",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": true
        },
        {
            "id": 3243070,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 2 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243071,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 7 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243072,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 9 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243073,
            "title": "Dance",
            "musicChannelId": 134,
            "musicCollectionID": 8312,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": false
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 6,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 17 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243074,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 14 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243075,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": false
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": false
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 6 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243076,
            "title": "Ragga",
            "musicChannelId": 164,
            "musicCollectionID": 8500,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 3 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243077,
            "title": "R&B",
            "musicChannelId": 164,
            "musicCollectionID": 8501,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Mixed",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2013,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Mixed",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 15 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243078,
            "title": "Dancehall",
            "musicChannelId": 164,
            "musicCollectionID": 8504,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1992,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 21 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243079,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 21 ? * 7",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243080,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 9 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243081,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 5 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243082,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 17 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243083,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 13 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243084,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 20 ? * 7",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243085,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 0 ? * 7",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243086,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 21 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243087,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 0 ? * 7",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243088,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 2 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243089,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 3 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243090,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 6 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243091,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 7 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243092,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 10 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243093,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 11 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243094,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 0 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243095,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 5 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243096,
            "title": "Hit Parade Alternative",
            "musicChannelId": 142,
            "musicCollectionID": 8622,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 4 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243097,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 1 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243098,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 8 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243099,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 9 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243100,
            "title": "Hitparade Urban",
            "musicChannelId": 142,
            "musicCollectionID": 8977,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 14 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243101,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 15 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243102,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 18 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243103,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 19 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243104,
            "title": "Hit Parade",
            "musicChannelId": 130,
            "musicCollectionID": 8729,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 22 ? * 7",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243105,
            "title": "R&B",
            "musicChannelId": 164,
            "musicCollectionID": 8501,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Mixed",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Mixed",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 12 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243106,
            "title": "Pop",
            "musicChannelId": 134,
            "musicCollectionID": 8202,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 13 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243107,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 16 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243108,
            "title": "2014 - 2018",
            "musicChannelId": 164,
            "musicCollectionID": 8493,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 17 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243109,
            "title": "House",
            "musicChannelId": 130,
            "musicCollectionID": 8118,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 20 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243110,
            "title": "Funky",
            "musicChannelId": 153,
            "musicCollectionID": 8205,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 21 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243111,
            "title": "Best Of",
            "musicChannelId": 130,
            "musicCollectionID": 8136,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Eurodance",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2016,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Electro",
                            "selected": true
                        },
                        {
                            "value": "Eurodance",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Progressive House",
                            "selected": true
                        },
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 23 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243112,
            "title": "Progressive House",
            "musicChannelId": 130,
            "musicCollectionID": 8145,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243113,
            "title": "Deep Beats",
            "musicChannelId": 192,
            "musicCollectionID": 8775,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1994,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 125,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1994,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1994,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 22 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243114,
            "title": "Happy Hour",
            "musicChannelId": 140,
            "musicCollectionID": 8330,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Rock'n'Roll",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Rock'n'Roll",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 23 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243115,
            "title": "Drum & Bass",
            "musicChannelId": 173,
            "musicCollectionID": 8537,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2016,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1992,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 23 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243116,
            "title": "Soul",
            "musicChannelId": 150,
            "musicCollectionID": 8513,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 12 ? * 5",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243117,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 7 ? * 5",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243118,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 4 ? * 5",
            "duration": 120,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243119,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 16 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243120,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 5 ? * 5",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243121,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 8 ? * 5",
            "duration": 120,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243122,
            "title": "Disco",
            "musicChannelId": 134,
            "musicCollectionID": 8143,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1971,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1971,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 17 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243123,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 6 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243124,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 13 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243125,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 0 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243126,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 6 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243127,
            "title": "Bright",
            "musicChannelId": 134,
            "musicCollectionID": 8147,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 11 ? * 5",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243128,
            "title": "Stimulating",
            "musicChannelId": 134,
            "musicCollectionID": 8127,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 0 ? * 5",
            "duration": 90,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243129,
            "title": "Trance",
            "musicChannelId": 130,
            "musicCollectionID": 8117,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Progressive Trance",
                            "selected": true
                        },
                        {
                            "value": "Psytrance",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 1 ? * 5",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243130,
            "title": "House",
            "musicChannelId": 130,
            "musicCollectionID": 8118,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 1 ? * 5",
            "duration": 120,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243131,
            "title": "Hit Parade",
            "musicChannelId": 134,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 3 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243132,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 9 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243133,
            "title": "Dance",
            "musicChannelId": 134,
            "musicCollectionID": 8312,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2012,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 13 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243134,
            "title": "Hit Parade",
            "musicChannelId": 134,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 15 ? * 5",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243135,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 0 19 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243136,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 2 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243137,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 9 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243138,
            "title": "2014 - 2018  Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8667,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 134,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 180,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 180,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 16 ? * 5",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243139,
            "title": "Dance",
            "musicChannelId": 150,
            "musicCollectionID": 8557,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 0 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243140,
            "title": "Nu Urban",
            "musicChannelId": 146,
            "musicCollectionID": 8992,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Reggae",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hip Hop",
                            "selected": true
                        },
                        {
                            "value": "R & B",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 3 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243141,
            "title": "Indie Pop",
            "musicChannelId": 146,
            "musicCollectionID": 8182,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Electronic",
                            "selected": false
                        },
                        {
                            "value": "Folk",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": false
                        },
                        {
                            "value": "Soul",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Folk",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 4 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243142,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 8 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243143,
            "title": "House",
            "musicChannelId": 130,
            "musicCollectionID": 8118,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 10 ? * 5",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243144,
            "title": "Urban Reggae",
            "musicChannelId": 164,
            "musicCollectionID": 8495,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Ragga",
                            "selected": false
                        },
                        {
                            "value": "Rap",
                            "selected": false
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Ragga",
                            "selected": true
                        },
                        {
                            "value": "Rap",
                            "selected": true
                        },
                        {
                            "value": "Slam",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1992,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 14 ? * 5",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243145,
            "title": "Urban Dance",
            "musicChannelId": 164,
            "musicCollectionID": 8494,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1989,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1989,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1989,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 15 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243146,
            "title": "Best Of",
            "musicChannelId": 164,
            "musicCollectionID": 8490,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1979,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1979,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 17 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243147,
            "title": "Deep Beats",
            "musicChannelId": 192,
            "musicCollectionID": 8775,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1994,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 125,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1994,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1994,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 18 ? * 5",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243148,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 18 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243149,
            "title": "Hit Parade Dance",
            "musicChannelId": 142,
            "musicCollectionID": 8621,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 12,
            "cronString": "0 30 19 ? * 5",
            "duration": 60,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243150,
            "title": "Dance Beats",
            "musicChannelId": 149,
            "musicCollectionID": 8339,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Eurodance",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Eurodance",
                            "selected": true
                        },
                        {
                            "value": "House",
                            "selected": true
                        },
                        {
                            "value": "Trance",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1975,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 22 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243151,
            "title": "Party Dance",
            "musicChannelId": 132,
            "musicCollectionID": 8166,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Danceability",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Danceability",
                    "minimum": 4,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 23 ? * 5",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243152,
            "title": "Dance Grooves",
            "musicChannelId": 132,
            "musicCollectionID": 8160,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 114,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 114,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 114,
                    "maximum": 140,
                    "step": 1,
                    "lowerSelection": 114,
                    "upperSelection": 140,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 21 ? * 5",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243153,
            "title": "Reggaeton",
            "musicChannelId": 164,
            "musicCollectionID": 8498,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2000,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2000,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 21 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3243154,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 20 ? * 5",
            "duration": 90,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243155,
            "title": "Progressive House",
            "musicChannelId": 130,
            "musicCollectionID": 8145,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 2,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 2,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 22 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243156,
            "title": "Deep Beats",
            "musicChannelId": 192,
            "musicCollectionID": 8775,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1994,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 125,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1994,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1994,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 115,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 115,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 22 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243157,
            "title": "Happy Hour",
            "musicChannelId": 140,
            "musicCollectionID": 8330,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Rock'n'Roll",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Rock'n'Roll",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Party Level",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Party Level",
                    "minimum": 3,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "4"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 12,
            "cronString": "0 30 23 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3243158,
            "title": "Drum & Bass",
            "musicChannelId": 173,
            "musicCollectionID": 8537,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2016,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1992,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1992,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 12,
            "cronString": "0 0 23 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        }
    ],
    "type": "Tunify",
    "isVisible": true
};