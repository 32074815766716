import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsModule } from '@components/icons/icons.module';
import { UtilsModule } from '@util/utils.module';
import { TooltipComponent } from './tooltip/tooltip.component';
import { PopupArrowComponent } from './popup-arrow/popup-arrow.component';
import { PopupContentDirective } from './popup-content.directive';
import { PopupHeaderComponent } from './popup-header/popup-header.component';
import { PopupOnClickDirective } from './popup-on-click.directive';
import { PopupOnHoverDirective } from './popup-on-hover.directive';
import { PopupComponent } from './popup/popup.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { CheckAccessDirective } from './access-checks/check-access.directive';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipOnHoverDirective } from './tooltip-on-hover/tooltip-on-hover.directive';
import { NoPlayerConnectPopupComponent } from './no-player-connect-popup/no-player-connect-popup.component';
import { TooManyTracksPopupComponent } from './too-many-tracks-popup/too-many-tracks-popup.component';
import { ResizeModule } from '@components/resize/resize.module';
import { RemotePopupV5Component } from './remote-popup-v5/remote-popup-v5.component';
import { DoubleAudioFilesPopupComponent } from './double-audio-files-popup/double-audio-files-popup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ComponentsV5Module } from '@components/components-v5/components-v5.module';


@NgModule({
    declarations: [
        PopupOnHoverDirective,
        TooltipComponent,
        PopupOnClickDirective,
        PopupArrowComponent,
        PopupComponent,
        PopupContentDirective,
        PopupHeaderComponent,
        ConfirmPopupComponent,
        CheckAccessDirective,
        TooltipOnHoverDirective,
        NoPlayerConnectPopupComponent,
        TooManyTracksPopupComponent,
        RemotePopupV5Component,
        DoubleAudioFilesPopupComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        ComponentsV5Module,
        IconsModule,
        BrowserAnimationsModule,
        UtilsModule,
        MatProgressSpinnerModule,
        ResizeModule
    ],
    exports: [
        TooltipComponent,
        PopupOnHoverDirective,
        PopupOnClickDirective,
        ConfirmPopupComponent,
        CheckAccessDirective,
        TooltipOnHoverDirective,
        TooManyTracksPopupComponent,
        RemotePopupV5Component
    ],
    providers: [DatePipe]
})
export class PopupsModule {}
