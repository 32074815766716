import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { DTO_lastUsed, DTO_lastUsedItem, DTO_lastUsedItemType, RecommendationsApiService } from '@service/api/recommendations-api.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { LoggerService } from '@service/loggers/logger.service';
import { BehaviorSubject, Observable, Subject, combineLatest, merge, timer } from 'rxjs';
import { filter, finalize, last, map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MusicChannelService } from './music-channel.service';
import { MusicChannel } from '@model/musicChannel';
import { Calendar } from '@model/calendar';
import { CalendarGroupService } from './calendar-group.service';
import { CalendarService } from './calendar.service';
import { CalendarType } from '@model/enums/calendarType';

/*
export class LastPlayedItems{
  lastPlayedItems: LastPlayedItem[];
}
  */

export class LastPlayedItem{
  matchedItem: MusicChannel | Calendar;

  lastUsedItem_DTO: DTO_lastUsedItem;
}

@Injectable({
  providedIn: 'root'
})
export class LastPlayedItemsService implements OnDestroy {

  private LOGGER_CLASSNAME = LastPlayedItemsService.name;

  private syncLastUsedEveryXMinutes: number = 15;
  private _keepLastUsedItemsUpToDate: boolean = false;
  public get keepLastUsedItemsUpToDate(): boolean {
    return this._keepLastUsedItemsUpToDate;
  }
  public set keepLastUsedItemsUpToDate(value: boolean) {
    this._keepLastUsedItemsUpToDate = value;
    this.checkLoadNeeded();
  }






  //helper observable that triggers when the active zone changes
  //this will cancel any running request
  private activeZoneChanged$ = new Subject<void>();


  constructor(
    private loggerService: LoggerService,
    private recommendationsApiService: RecommendationsApiService,
    private zoneConnectionsService: ZoneConnectionsService,
    private musicChannelService: MusicChannelService,
    private calendarGroupService: CalendarGroupService,
    private calendarService: CalendarService
  ) {

    this.zoneConnectionsService.activeZoneConnection$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (zoneConnection) => {
        this.activeZoneChanged$.next();
        this.clearData();
        if (zoneConnection != null) {
          this.loadLastUsedItems();
        }
      }
    )

    this.musicChannelService.musicChannelGroups$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.matchMusicChannelGroups()
      }
    )

    this.calendarGroupService.calendarGroups$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      ()=>{
        this.matchCalendarGroups();
      }
    )

    this.calendarService.customCalendars$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      ()=>{
        this.matchCustomCalendars();
      }
    )


  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private addTestData(value: DTO_lastUsed){

    //Add a musicChannel
    const lastUsedItemMC = new DTO_lastUsedItem();
    lastUsedItemMC.id = 142;
    lastUsedItemMC.type = DTO_lastUsedItemType.MUSIC_CHANNEL;
    value.lastUsedItems.push(lastUsedItemMC);

    //Add a calendar
    const lastUsedItemCA = new DTO_lastUsedItem();
    lastUsedItemCA.id = 142;
    lastUsedItemCA.type = DTO_lastUsedItemType.CALENDAR;
    lastUsedItemCA.calendarType = "Tunify";
    value.lastUsedItems.push(lastUsedItemCA);
  }

  private get _lastPlayedItems(): LastPlayedItem[] {
    return this._lastPlayedItemsSubject.value;
  }
  private set _lastPlayedItems(value: LastPlayedItem[]) {
    if (this._lastPlayedItems !== value) {
      this._lastPlayedItemsSubject.next(value);
      this.matchMusicChannelGroups();
      this.matchCalendarGroups();
      this.matchCustomCalendars();
    }
  }
  get lastPlayedItems(): LastPlayedItem[] {
    return this._lastPlayedItems;
  }
  private _lastPlayedItemsSubject = new BehaviorSubject<LastPlayedItem[]>(null);
  public lastPlayedItems$ = this._lastPlayedItemsSubject.asObservable();

  //Helper method to create the last used items
  private createLastUsedItems(){
    const lastPlayedItemsTemp: LastPlayedItem[] = [];
    if (this.rawLastUsed != null){
      this.rawLastUsed.lastUsedItems.forEach((lastUsedItem) => {
        const lastPlayedItem = new LastPlayedItem();
        lastPlayedItem.lastUsedItem_DTO = lastUsedItem;
        lastPlayedItemsTemp.push(lastPlayedItem);
      });
    }
    this._lastPlayedItems = lastPlayedItemsTemp;
  }

  /**
   * Loading state for the last used items
   * @type {boolean}
   */
  /*
  get loading(): boolean {
    return this._loadingRawData || this.musicChannelService.loading;
  }
  get loading$() {
    return combineLatest([this.musicChannelService.loading$, this.loadingRawData$])
    .pipe(
      map(([musicChannelLoading, loadingRawData]) => {
      return musicChannelLoading || loadingRawData;
      })
    )
  }
    */
  get loading(): boolean {
    return this._loadingRawData;
  }
  get loading$() {
    return this.loadingRawData$;
  }

  /**
   * Loading emitter for retrieving the last used items
   * @type {boolean}
   */
  private get _loadingRawData(): boolean {
    return this._loadingRawDataSubject.value;
  }
  private set _loadingRawData(value: boolean) {
    if (this._loadingRawData !== value) {
      this._loadingRawDataSubject.next(value);
    }
  }
  private _loadingRawDataSubject = new BehaviorSubject<boolean>(false);
  private loadingRawData$ = this._loadingRawDataSubject.asObservable();



  /**
   * Error emitter for retrieving the last used items
   * @type {boolean}
   */
  private get _loadingError(): boolean {
    return this._loadingErrorSubject.value;
  }
  private set _loadingError(value: boolean) {
    if (this._loadingError !== value) {
      this._loadingErrorSubject.next(value);
    }
  }
  get loadingError(): boolean {
    return this._loadingError;
  }
  private _loadingErrorSubject = new BehaviorSubject<boolean>(false);
  public loadingError$= this._loadingErrorSubject.asObservable();

   /**
   * the raw recommended musicChannels, as they are retrieved from the server
   * @type {DTO_RecommendedMusicChannels}
   * @private
   */
   private get _rawLastUsed(): DTO_lastUsed {
    return this._rawLastUsedSubject.value;
  }
  private set _rawLastUsed(value: DTO_lastUsed) {
    if (this._rawLastUsed !== value) {
      //this.addTestData(value);
      this._rawLastUsedSubject.next(value);
      this.createLastUsedItems();
    }
  }
  get rawLastUsed(): DTO_lastUsed {
    return this._rawLastUsed;
  }
  private _rawLastUsedSubject = new BehaviorSubject<DTO_lastUsed>(null);
  public rawLastUsed$ = this._rawLastUsedSubject.asObservable();

   //the subject to cancel any previous request
   private lastUsedWillStartLoading$: Subject<void> = new Subject<void>();
   public loadLastUsedItems(): Observable<DTO_lastUsed> {

    this.latestFetchTimestamp = Date.now();

     if (environment.enableRecommendations){
       const loadObservable: Observable<DTO_lastUsed> = this.recommendationsApiService.loadLastUsedItems();

       if (loadObservable != null){

         this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadLastUsedItems', 'Going to load last used items');
         this.lastUsedWillStartLoading$.next(null);

         this._loadingRawData = true;

         loadObservable
           .pipe(
             takeUntil(
               merge(
                 this.destroyed$,
                 this.activeZoneChanged$,
                 this.lastUsedWillStartLoading$
               )
             ),
             finalize(() => {
               this._loadingRawData = false;
             })
           )
           .subscribe(
             (data) => {
              this._rawLastUsed = data
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadLastUsedItems', 'loadLastUsedItems done');
             },
             (error : unknown) => {
               let errMsg = 'Error';
               if (error instanceof HttpErrorResponse){
                 errMsg = (error.message) ? error.message :
                 error.status ? `${error.status} - ${error.statusText}` : 'Server error';
               }
               this.loggerService.error(this.LOGGER_CLASSNAME, 'loadLastUsedItems error', errMsg);
               this._loadingError = true;
             });

       }else{
         this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels', 'Observable to load recommended musicChannels was not created');
       }
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels', 'Recommendations are disabled');
     }


     return this.rawLastUsed$;
   }

   /**
    * Keep last used items up to date
    */
   private get latestFetchTimestamp(): number {
    return this._latestFetchTimestampSubject.value;
   }
  private set latestFetchTimestamp(value: number) {
    this._latestFetchTimestampSubject.next(value);

    this.checkLoadNeeded();
  }

  private _latestFetchTimestampSubject = new BehaviorSubject<number>(0);
  private latestFetchTimestamp$= this._latestFetchTimestampSubject.asObservable();

   private checkLoadNeeded(){
    if (this.keepLastUsedItemsUpToDate && this.latestFetchTimestamp != 0){
      const now = Date.now();
      const diff = now - this.latestFetchTimestamp;
      if (diff > this.syncLastUsedEveryXMinutes * 60 * 1000){
        this.loadLastUsedItems();
      }else{
        this.startSyncTimer();
      }
    }
   }

   private startSyncTimer(){
    if (this.keepLastUsedItemsUpToDate && this.latestFetchTimestamp != 0){
      const passedTime = Date.now() - this.latestFetchTimestamp;
      const waitingTime = Math.max(2000, this.syncLastUsedEveryXMinutes * 60 * 1000 - passedTime);

      timer(waitingTime)
      .pipe(
        takeUntil(
          merge(
            this.destroyed$,
            this.activeZoneChanged$,
            this.latestFetchTimestamp$.pipe(filter((value) => value != this.latestFetchTimestamp))
          )
        )
      )
      .subscribe(
        () => {
          this.loadLastUsedItems();
        }
      );
    }
   }

   private clearData() {
     //cancel any loading requests
     this.lastUsedWillStartLoading$.next();

     this._loadingRawData = false;
     this._loadingError = null;
     this._rawLastUsed = null;
   }

   private matchMusicChannelGroups(){
    if (this.lastPlayedItems != null){
      for (const lastPlayedItem of this.lastPlayedItems) {
        if (lastPlayedItem.lastUsedItem_DTO.type == "MusicChannel") {
          lastPlayedItem.matchedItem = this.musicChannelService.findMusicChannelById(lastPlayedItem.lastUsedItem_DTO.id);
        }
      }
    }
  }

  private matchCalendarGroups(){
    if (this.lastPlayedItems != null){
      for (const lastPlayedItem of this.lastPlayedItems) {
        if (lastPlayedItem.lastUsedItem_DTO.type == "Calendar" && (lastPlayedItem.lastUsedItem_DTO.calendarType == null || lastPlayedItem.lastUsedItem_DTO.calendarType.toLowerCase() == CalendarType.TUNIFY.toLowerCase())) {
          lastPlayedItem.matchedItem = this.calendarGroupService.findCalendarById(lastPlayedItem.lastUsedItem_DTO.id);
        }
      }
    }
  }

  private matchCustomCalendars(){
    if (this.lastPlayedItems != null){
      for (const lastPlayedItem of this.lastPlayedItems) {
        if (lastPlayedItem.lastUsedItem_DTO.type == "Calendar" && lastPlayedItem.lastUsedItem_DTO.calendarType != null && lastPlayedItem.lastUsedItem_DTO.calendarType.toLowerCase() == CalendarType.CUSTOM.toLowerCase()) {
          lastPlayedItem.matchedItem = this.calendarService.findCalendarById(lastPlayedItem.lastUsedItem_DTO.id);
        }
      }
    }
  }


}
