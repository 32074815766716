import { TrackOrigin } from '@model/trackOrigin';
import { AudioFile } from '@model/audioFile';
import { Song } from '@model/song';

export class ListManipulationTrack{
    id: number;
    origin: TrackOrigin;

    static createFromAudioFile(audioFile:AudioFile): ListManipulationTrack{
        let listManipulationTrack = new ListManipulationTrack();
        listManipulationTrack.id = audioFile.id;

        if (audioFile instanceof Song){
            listManipulationTrack.origin = new TrackOrigin(audioFile.origin);
        }

        return listManipulationTrack;
    }
}