import { Component, OnDestroy } from '@angular/core';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { map, takeUntil } from 'rxjs/operators';
import { MusicChannel } from '@model/musicChannel';
import { AudioCommandsService, StartMusicSelectionFailedReason } from '@service/app-v5/audio-commands.service';
import { Subject, combineLatest, merge } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MusicCollection } from '@model/musicCollection';
import { MusicChannelService } from '@service/music-channel.service';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { StreamMode } from '@model/enums/streamMode';
import { LoggerService } from '@service/loggers/logger.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { slideUpAnimation } from '@util/animations';
import { Context } from '@model/context';
import { RecommendationsService } from '@service/recommendations.service';


@Component({
  selector: 'tun-music-channel-detail-content-view',
  templateUrl: './music-channel-detail-content-view.component.html',
  styleUrls: ['./music-channel-detail-content-view.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]
})
export class MusicChannelDetailContentViewComponent implements OnDestroy{
  private LOGGER_CLASSNAME = MusicChannelDetailContentViewComponent.name;
  private SNACKBAR_DURATION = 5000;

  public get showingPopup(){
    return this.showTweakPopup;
  }
  public get showTweakPopup(){
    return this.tweakMusiCollection != null
  }

  // Amount of music blocks in each group.
  public amountOfMusicBlocksPerGroup: Array<null> = new Array(4);

  // Amount of music block groups.
  public amountOfMusicBlockGroups: Array<null> = new Array(4);

  public get title$(){
    return this.appV5StateService.showDetailsForMusicChannel$
      .pipe(
        map(
          musicChannel => {
            if (musicChannel){
              return musicChannel.name
            }
            return ""
          }
        )
      )
  }

  public get musicCollections$(){
    return this.appV5StateService.showDetailsForMusicChannel$
    .pipe(
      map(
        musicChannel => {
          if (musicChannel){
            return musicChannel.musicCollections
          }
          return [];
        }
      )
    )
  }

  public get imageUrl$(){
    return this.appV5StateService.showDetailsForMusicChannel$
    .pipe(
      map(
        (musicChannel => {
          if (musicChannel){
            return musicChannel.imageInfo.urlWebSquare
          }
          return ""
        })
      )
    )
  }

  public get showingMusicChannel$(){
    return this.appV5StateService.showDetailsForMusicChannel$
  }

  public get recommendedMusicCollections$(){
    return combineLatest([this.appV5StateService.showDetailsForMusicChannel$, this.recommendationsService.recommendedMusicChannels$])
    .pipe(
      map(([musicChannel, recommendedMusicChannels]) => {
        if (musicChannel && recommendedMusicChannels){
          const recommendedMusicChannel = recommendedMusicChannels.find(mc => mc.musicChannel.id == musicChannel.id);
          if (recommendedMusicChannel){
            return recommendedMusicChannel.musicCollections
          }
        }
        return [];
      })
    )
  }


  constructor(
    private appV5StateService: AppV5StateService,
    private audioCommandsService: AudioCommandsService,
    private loggerService: LoggerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private musicChannelService: MusicChannelService,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private recommendationsService: RecommendationsService
    ) {

    }

    private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onBack(){
    this.appV5StateService.showMusicChannelInTunifyBlue(null);
  }

  //Start item feedback (from play button)
  onItemIsStarting(){
    this.watchMusicChannelToStart = this.appV5StateService.showDetailsForMusicChannel
  }

  private _watchMusicChannelToStart: MusicChannel;
  private musicChannelToStartChanged = new Subject<void>();
  private set watchMusicChannelToStart(musicChannel: MusicChannel){
    this.musicChannelToStartChanged.next();
    this._watchMusicChannelToStart = musicChannel;
    this.audioCommandsService.startMusicChannelFeedback$
    .pipe(
      takeUntil(
        merge(
          this.destroyed$,
          this.musicChannelToStartChanged
        )
      )
    )
    .subscribe(
      (startMusicChannelFeedback) => {
        if (this.watchMusicChannelToStart == startMusicChannelFeedback.musicChannel){
          if (startMusicChannelFeedback.success){
            const snackBarRef = this.snackBar.open(this.translateService.instant('musicChannel.start.succes').replace('{0}', musicChannel.name), null, {
              duration: this.SNACKBAR_DURATION,
              panelClass: ['tunify-snackbar']
            });
          }else{
            if (startMusicChannelFeedback.failedReason == StartMusicSelectionFailedReason.NothingSelected) {
              const snackBarRef = this.snackBar.open(this.translateService.instant('musicChannel.start.failed.noMusicCollection'), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });
            }else if (startMusicChannelFeedback.failedReason == StartMusicSelectionFailedReason.Timeout){
              const snackBarRef = this.snackBar.open(this.translateService.instant('musicChannel.start.failed.timeout').replace('{0}', musicChannel.name), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });
            }else{
              this.loggerService.error(this.LOGGER_CLASSNAME, "startMusicChannelFeedback", "reason not implemented");
            }

          }
          this.watchMusicChannelToStart = null;
        }else{
          this.loggerService.warn(this.LOGGER_CLASSNAME, "startMusicChannelFeedback", "feedback for other musicChannel than the one we are watching");
        }
      }
    )

  }

  private get watchMusicChannelToStart():MusicChannel{
    return this._watchMusicChannelToStart;
  }

  //Toggle selection
  public onToggleMusicCollectionSelection(musicCollection: MusicCollection){
    const changedMusicChannel = this.appV5StateService.showDetailsForMusicChannel
    if (changedMusicChannel && musicCollection){

      musicCollection.selected = !musicCollection.selected;
      this.musicChannelService.saveMusicCollectionSelectionProperty(
        changedMusicChannel,
        musicCollection
      );

      if (changedMusicChannel.musicCollections && this.activeMusicSelectionService.selectedStreamMode == StreamMode.MUSIC_CHANNEL && this.activeMusicSelectionService.selectedMusicChannel == changedMusicChannel){
        if (changedMusicChannel.musicCollections.filter(mc => mc.selected).length > 0){
          const snackBarRef = this.snackBar.open(this.translateService.instant('musicChannel.activeSelection.changed'), null, {
            duration: this.SNACKBAR_DURATION,
            panelClass: ['tunify-snackbar']
          });
        }else{
          const snackBarRef = this.snackBar.open(this.translateService.instant('musicChannel.start.failed.noMusicCollection'), null, {
            duration: this.SNACKBAR_DURATION,
            panelClass: ['tunify-snackbar']
          });
        }
      }

    }
  }

  /**
   * Recommendations
   */
  public onStartRecommendations(){
    const startMusicChannel = this.appV5StateService.showDetailsForMusicChannel;
    this.recommendationsService.recommendedMusicChannels
    if (startMusicChannel && this.recommendationsService.recommendedMusicChannels){
      const recommendedMusicChannel = this.recommendationsService.recommendedMusicChannels.find(mc => mc.musicChannel.id == startMusicChannel.id);
      if (recommendedMusicChannel && recommendedMusicChannel.musicCollections.length > 0){
        this.audioCommandsService.selectAndStartMusicCollections(startMusicChannel, recommendedMusicChannel.musicCollections);
      }
    }
  }

  public onCloseRecommendationInfo(){

  }

  /**
   * Tweak popup
   */

  public tweakMusiCollection: MusicCollection = null;
  public onTweakMusicCollection(musicCollection: MusicCollection){
    this.tweakMusiCollection = musicCollection
  }

  public onParametersChanged(context: Context){
    if (this.appV5StateService.showDetailsForMusicChannel){
      this.musicChannelService.saveContextParameters(
        this.appV5StateService.showDetailsForMusicChannel,
        context
      );
    }
  }

  public onCloseTweakPopup(){
    this.tweakMusiCollection = null;
  }

  onClickOutside(event: Event, item){
    if (event.target !== item)
      return;
      this.tweakMusiCollection = null;
  }


}
