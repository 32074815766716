import { AudioFile } from '@model/audioFile';
import { Song } from '@model/song';
import { Message } from '@model/message';

export function createClassObjectForAudioFile(audioFile:AudioFile) { 
    if (audioFile["@type"] == "Song"){
        return new Song(audioFile as Song);
    }else if (audioFile["@type"] == "Message"){
        return new Message(audioFile as Message);
    }else{
        console.error("AudioFile type not recognized");
        return new AudioFile(audioFile);
    }
 }