<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 36 36" enable-background="new 0 0 36 36" xml:space="preserve">
    <defs>
      <path id="arrow-back-path" d="M9.0005625,12.0005625 C8.46298938,12.0005625 7.92541626,12.2169677 7.51593673,12.6453617 L-1.12933101,21.7387968 C-1.62280633,22.2555195 -1.62280633,23.0946417 -1.12933101,23.6113644 C-0.637955574,24.1302952 0.160004528,24.1302952 0.651379959,23.6113644 L9.0005625,14.8337041 L17.349745,23.6113644 C17.8411205,24.1302952 18.6390806,24.1302952 19.130456,23.6113644 C19.6239313,23.0946417 19.6239313,22.2555195 19.130456,21.7387968 L10.4851883,12.6453617 C10.0757087,12.2169677 9.53813562,12.0005625 9.0005625,12.0005625"></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <mask fill="white">
        <use xlink:href="#arrow-back-path" transform="translate(9.0006, 18.0006) rotate(270) translate(-9.0006, -18.0006)"></use>
    </mask>
    <use fill="currentColor" transform="translate(9.0006, 18.0006) rotate(270) translate(-9.0006, -18.0006)" xlink:href="#arrow-back-path"></use>
    <line x1="4.5" y1="18" x2="30" y2="18" id="Line" stroke="currentColor" stroke-width="3" fill="currentColor" stroke-linecap="round"></line>
</g>
</svg>
