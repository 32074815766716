import { Component } from '@angular/core';

@Component({
  selector: 'tun-checkmark-icon-v5',
  templateUrl: './checkmark-icon-v5.component.html',
  styleUrls: ['./checkmark-icon-v5.component.scss']
})
export class CheckmarkIconV5Component {

}
