import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Calendar, isCalendarEditable } from '@model/calendar';
import { TranslateService } from '@ngx-translate/core';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { AudioCommandsService, StartMusicSelectionFailedReason } from '@service/app-v5/audio-commands.service';
import { CalendarGroupService } from '@service/calendar-group.service';
import { LoggerService } from '@service/loggers/logger.service';
import { BehaviorSubject, Subject, merge, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Day } from '@model/day';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { CalendarService } from '@service/calendar.service';
import { CalendarItem } from '@model/calendarItem';
import { transition, trigger, useAnimation } from '@angular/animations';
import { slideUpAnimation } from '@util/animations';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { MatDialog } from '@angular/material/dialog';
import { CopyCalendarPopupComponent } from '@components/popups-v5/copy-calendar-popup/copy-calendar-popup.component';
import { CreateCalendarItemData } from './calendar-content-view/create-calendar-item/create-calendar-item.component';
import { Playlist } from '@model/playlist';
import { MusicChannelData } from '@components/overlays-v5/overlay-select-music-channel/overlay-select-music-channel.component';

export const CALENDAR_CAROUSEL_ANIMATION_DURATION_MS = 700;

@Component({
  selector: 'tun-calendar-detail-view',
  templateUrl: './calendar-detail-view.component.html',
  styleUrls: ['./calendar-detail-view.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]
})
export class CalendarDetailViewComponent implements OnDestroy, AfterViewInit {

  private LOGGER_CLASSNAME = CalendarDetailViewComponent.name;
  private SNACKBAR_DURATION = 5000;

  public animationDirection: 'next' | 'prev';

  public get showingPopup(){
    return this.showTweakPopup;
  }
  public get showTweakPopup(){
    return this.tweakCalendarItem != null
  }

  get daysOfWeek(): Day[] {
    return Day.DAYS;
  }

  public get editMode$(){
    return this.appV5StateService.editingCurrentCalendar$;
  }

  public onToggleEdit(value: boolean){
    this.appV5StateService.editingCurrentCalendar = value;
  }

  public get title$(){
    return this.appV5StateService.showDetailsForCalendar$
      .pipe(
        map(
          calendar => {
            if (calendar){
              return calendar.name
            }
            return ""
          }
        )
      )
  }


  public get imageUrl$(){
    return this.appV5StateService.showDetailsForCalendar$
    .pipe(
      map(
        (calendar => {
          if (calendar){
            return calendar.imageInfo.urlWebSquare
          }
          return ""
        })
      )
    )
  }

  public get showingCalendar$(){
    return this.appV5StateService.showDetailsForCalendar$
  }

  public get isEditable(){
    return this.appV5StateService.showDetailsForCalendar && isCalendarEditable(this.appV5StateService.showDetailsForCalendar);
  }

  public get isCreating(){
    return this.appV5StateService.showDetailsForCalendar && this.appV5StateService.showDetailsForCalendar.creating;
  }


  constructor(
    private appV5StateService: AppV5StateService,
    private audioCommandsService: AudioCommandsService,
    private loggerService: LoggerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private calendarGroupService: CalendarGroupService,
    private calendarService: CalendarService,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private _cdRef: ChangeDetectorRef,
    private zoneConfigurationService: ZoneConfigurationService,
    private dialog: MatDialog
    ) {

      this.calculateTimeMultiplier();
      this.currentIndex = this.currentDayIndex;

      timer(0, 60000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.calculateTimeMultiplier();
      });

    }

  ngAfterViewInit(): void {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onBack(){
    this.appV5StateService.showCalendarInTunifyGreen(null);
  }

  //Start item feedback (from play button)
  onItemIsStarting(){
    this.watchCalendarToStart = this.appV5StateService.showDetailsForCalendar
  }

  private _watchCalendarToStart: Calendar;
  private calendarToStartChanged = new Subject<void>();
  private set watchCalendarToStart(calendar: Calendar){
    this.calendarToStartChanged.next();
    this._watchCalendarToStart = calendar;
    this.audioCommandsService.startCalendarFeedback$
    .pipe(
      takeUntil(
        merge(
          this.destroyed$,
          this.calendarToStartChanged
        )
      )
    )
    .subscribe(
      (startCalendarFeedback) => {
        if (this.watchCalendarToStart == startCalendarFeedback.calendar){
          if (startCalendarFeedback.success){
            const snackBarRef = this.snackBar.open(this.translateService.instant('calendar.start.succes').replace('{0}', calendar.name), null, {
              duration: this.SNACKBAR_DURATION,
              panelClass: ['tunify-snackbar']
            });
          }else{
            if (startCalendarFeedback.failedReason == StartMusicSelectionFailedReason.Timeout){
              const snackBarRef = this.snackBar.open(this.translateService.instant('musicChannel.start.failed.timeout').replace('{0}', calendar.name), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });
            }else if (startCalendarFeedback.failedReason == StartMusicSelectionFailedReason.Deleted){
              const snackBarRef = this.snackBar.open(this.translateService.instant('musicChannel.start.failed.timeout').replace('{0}', calendar.name), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });
            }else{
              this.loggerService.error(this.LOGGER_CLASSNAME, "startCalendarFeedback", "reason not implemented");
            }

          }
          this.watchCalendarToStart = null;
        }else{
          this.loggerService.warn(this.LOGGER_CLASSNAME, "startCalendarFeedback", "feedback for other calendar than the one we are watching");
        }
      }
    )

  }

  private get watchMusicChannelToStart():Calendar{
    return this._watchCalendarToStart;
  }

  //Scroll through days

  public onOpenCurrentTime(){
    this.calculateTimeMultiplier();
    //when auto scrolling -> always scroll in this week (monday to sunday = scroll to right)
    if (this.currentIndex < this.currentDayIndex){
      this.animationDirection = 'next';
    }else{
      this.animationDirection = 'prev';
    }
    this._cdRef.detectChanges();
    this.currentIndex = this.currentDayIndex;
    this.appV5StateService.scrollToCurrentTime$.next();
  }

  public onDayChange(days: number) {
    if (days > 0) {
      this.animationDirection = 'next';
      this._cdRef.detectChanges();
      this.currentIndex = (this.currentIndex + days) % this.daysOfWeek.length;
    } else if (days < 0) {
      this.animationDirection = 'prev';
      this._cdRef.detectChanges();
      this.currentIndex =
        (this.currentIndex + this.daysOfWeek.length + days) %
        this.daysOfWeek.length;
    }
  }

  currentIndex: number = null;
  animationIndex = 0;
  currentDayIndex = 0;
  currentHour = 0;
  currentMinutes = 0;
  currentTimeMultiplier = 0;

  calculateTimeMultiplier = () => {
    const now = this.zoneConfigurationService.currentDateTimeForZone;
    this.currentDayIndex = now.getDay() == 0 ? 6 : now.getDay() - 1;
    this.currentHour = now.getHours();
    this.currentMinutes = now.getMinutes();
    this.currentTimeMultiplier = this.currentHour + this.currentMinutes / 60;
  };

  onCopy(){

    const dialogRef = this.dialog.open(CopyCalendarPopupComponent, {
      width: CopyCalendarPopupComponent.widthForPopup,
      disableClose: true,
      data: {confirm: false}
    });

    dialogRef.afterClosed()
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(result => {
      //the calendar reference we need to copy
      const calendarToCopy = this.appV5StateService.showDetailsForCalendar;
      if (result.confirm && calendarToCopy){
        this.calendarService.copyCalendar(
          calendarToCopy
        ).pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (asyncStatusWithData) => {
            if (asyncStatusWithData){
              if  (asyncStatusWithData.asyncStatus == AsyncStatus.COMPLETED){
                if (asyncStatusWithData.data && asyncStatusWithData.data instanceof Calendar){
                  //when a calendar is created, we also select it
                  this.appV5StateService.showCalendarInTunifyGreen(asyncStatusWithData.data);
                }else{
                  this.loggerService.error(this.LOGGER_CLASSNAME, "copyCalendar", "Successfully created a copy of a calendar, but the created object could not be found -> not going to select in the menu");
                }
              }
            }
          }
        )
      }
    });




  }

  onRemove(){
    this.calendarService.deleteCalendar(this.appV5StateService.showDetailsForCalendar)
  }

  onEditTitle(){
    this.appV5StateService.showChangeNameForCalendar = this.appV5StateService.showDetailsForCalendar
  }


/* tweak popup */

  public tweakCalendarItem: CalendarItem = null;
  public onOpenTweakPanel(calendarItem: CalendarItem){
    this.tweakCalendarItem = calendarItem
  }

  public onCloseTweakPopup(){
    this.tweakCalendarItem = null;
  }


  /* select musicChannel popup */

  private createCalendarItemData: CreateCalendarItemData;
  private creatingItemForCalendar : Calendar = null;
  public showChooseMusicChannelOverlay = false;
  onCreateMusicChannelCalendarItem(createCalendarItemData: CreateCalendarItemData){
    this.createCalendarItemData = createCalendarItemData;
    this.creatingItemForCalendar = this.appV5StateService.showDetailsForCalendar;
    this.showChooseMusicChannelOverlay = true;
  }

  private changeingCalendarItem: CalendarItem = null;
  /* change musicChannel for existing calendarItem */
  onSelectMusicChannel(calendarItem: CalendarItem){
    this.changeingCalendarItem = calendarItem;
    this.creatingItemForCalendar = this.appV5StateService.showDetailsForCalendar;
    this.showChooseMusicChannelOverlay = true;
  }

  onCloseSelectMusicChannel(musicChannelData: MusicChannelData){
    if (this.createCalendarItemData && musicChannelData){
      this.calendarService.createCalendarItem(
        this.creatingItemForCalendar,
        musicChannelData.musicChannel,
        musicChannelData.musicCollection,
        this.daysOfWeek[this.currentIndex],
        Math.floor(this.createCalendarItemData.time),
        (this.createCalendarItemData.time % 1) * 60,
        30,
        this.createCalendarItemData.position
      )
    }else if (this.changeingCalendarItem && musicChannelData){
      this.calendarService.adjustMusicCollectionForCalendarItem(this.creatingItemForCalendar, this.changeingCalendarItem, musicChannelData.musicChannel, musicChannelData.musicCollection);
    }

    this.createCalendarItemData = null;
    this.changeingCalendarItem = null;
    this.creatingItemForCalendar = null;
    this.showChooseMusicChannelOverlay = false;
  }

  /* select playlist popup */

  public showChoosePlaylistOverlay = false;
  onCreatePlaylistCalendarItem(createCalendarItemData: CreateCalendarItemData){
    this.createCalendarItemData = createCalendarItemData;
    this.creatingItemForCalendar = this.appV5StateService.showDetailsForCalendar;
    this.showChoosePlaylistOverlay = true;
  }

   /* change playlist */
   onSelectPlaylist(calendarItem: CalendarItem){
    this.changeingCalendarItem = calendarItem;
    this.creatingItemForCalendar = this.appV5StateService.showDetailsForCalendar;
    this.showChoosePlaylistOverlay = true;
   }

  onCloseSelectPlaylist(playlist: Playlist){
    if (this.createCalendarItemData && playlist){
      this.calendarService.createCalendarItem(
        this.creatingItemForCalendar,
        null,
        playlist,
        this.daysOfWeek[this.currentIndex],
        Math.floor(this.createCalendarItemData.time),
        (this.createCalendarItemData.time % 1) * 60,
        30,
        this.createCalendarItemData.position
      )
    }else if (this.changeingCalendarItem && playlist){
      this.calendarService.adjustMusicCollectionForCalendarItem(this.creatingItemForCalendar, this.changeingCalendarItem, null, playlist);
    }
    this.createCalendarItemData = null;
    this.changeingCalendarItem = null;
    this.creatingItemForCalendar = null;
    this.showChoosePlaylistOverlay = false;
  }




  onClickOutside(event: Event, item){
    if (event.target !== item)
      return;

    this.tweakCalendarItem = null;
    this.createCalendarItemData = null;
    this.creatingItemForCalendar = null;
    this.showChoosePlaylistOverlay = false;
    this.showChooseMusicChannelOverlay = false;
  }
}
