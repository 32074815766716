<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
   <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(2.000000, 0.000000)" stroke="currentColor">
        <rect x="0.5" y="0.5" width="19" height="9" rx="4"></rect>
        <rect stroke-width="2" x="1" y="14" width="8" height="1" rx="0.5"></rect>
        <rect stroke-width="2" x="1" y="18" width="6" height="1" rx="0.5"></rect>
        <rect stroke-width="2" x="1" y="22" width="6" height="1" rx="0.5"></rect>
    </g>
    <circle stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" cx="18" cy="18.5" r="5"></circle>
    <rect stroke="currentColor" fill="currentColor" x="15.5" y="18.5" width="5" height="1" rx="0.5"></rect>
    <line x1="18" y1="21" x2="18" y2="16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></line>
</g>
</svg>
