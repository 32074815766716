export class MusicPlayAnimationColors {
  _selectedColor: string;
  _unselectedColor: string;

  selectedColor = (color: string): MusicPlayAnimationColors => {
    this._selectedColor = color;
    return this;
  }

  unselectedColor = (color: string): MusicPlayAnimationColors => {
    this._unselectedColor = color;
    return this;
  }
}
