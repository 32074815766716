import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Playlist } from '@model/playlist';
import { TunifyCheckBoxColor } from '../../../../components-v5/checkbox-v5/checkbox-v5.component';

@Component({
  selector: 'tun-overlay-playlist-button-view',
  templateUrl: './overlay-playlist-button-view.component.html',
  styleUrls: ['./overlay-playlist-button-view.component.scss']
})
export class OverlayPlaylistButtonViewComponent {

  @Input() playlist: Playlist;
  @Input() isChecked: boolean;

  @Output() selectPlaylist = new EventEmitter<Playlist>();

  public TunifyCheckBoxColor = TunifyCheckBoxColor

  constructor(

  ){

  }

  public onShowDetails(){
    this.selectPlaylist.emit(this.playlist);
  }
}
