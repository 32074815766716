import { Component } from '@angular/core';

@Component({
  selector: 'tun-play-icon-v5',
  templateUrl: './play-icon-v5.component.html',
  styleUrls: ['./play-icon-v5.component.scss']
})
export class PlayIconV5Component {

}
