import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackInfoContextMode, TrackInfoOverlayData } from '../overlay-song-info.component';
import { FavoriteBannedTrack, FavoriteBannedTrackService } from '@service/app-v5/favorite-banned-track.service';
import { AudioFile } from '@model/audioFile';
import { AudioFileProperty, formatTrackProperty, getPropertyLabel, isTrackPropertyAvailable, isTrackPropertySearchable, trackPropertyValue } from '@model/enums/audioFileProperty';
import { TranslateService } from '@ngx-translate/core';
import { TrackOverlayActionFeedbackType, TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { QueueService } from '@service/queue.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchType, SuggestionsType } from '@service/api/search-api.service';
import { SubscriptionsService } from '@service/subscriptions.service';

@Component({
  selector: 'tun-song-info-view',
  templateUrl: './song-info-view.component.html',
  styleUrls: ['./song-info-view.component.scss']
})
export class SongInfoViewComponent {

  _trackInfoOverlayData: TrackInfoOverlayData;
  @Input()
  set trackInfoOverlayData(value: TrackInfoOverlayData) {
    this._trackInfoOverlayData = value;
    if (this._trackInfoOverlayData){
      this.favBannedTrack = new FavoriteBannedTrack(this._trackInfoOverlayData.track);
    }
    this.adjustIsInQueue();
  }
  get trackInfoOverlayData():TrackInfoOverlayData{
    return this._trackInfoOverlayData;
  }

  @Output() selectPlaylist = new EventEmitter<void>();

  private _favBannedTrack : FavoriteBannedTrack = null
  public set favBannedTrack(value: FavoriteBannedTrack){
    if (this._favBannedTrack){
      this.favoriteBannedTrackService.cleanupFavoriteBannedTrack(this._favBannedTrack);
    }
    this._favBannedTrack = value;
    if (this._favBannedTrack){
      this.favoriteBannedTrackService.registerFavoriteBannedTrack(this._favBannedTrack);
    }
  }
  public get favBannedTrack(){
    return this._favBannedTrack;
  }

  public get showView(){
    return this.trackInfoOverlayData != null && this.trackInfoOverlayData.track != null;
  }

  //AudioFile properties
  public showProperty(track: AudioFile, audioFileProperty: AudioFileProperty){
    return isTrackPropertyAvailable(track, audioFileProperty);
  }

  public valueForProperty(track: AudioFile, audioFileProperty: AudioFileProperty){
    return formatTrackProperty(track, audioFileProperty);
  }

  public labelForProperty(audioFileProperty: AudioFileProperty){
    return getPropertyLabel(this.translateService, audioFileProperty);
  }

  public showPropertySearch(track: AudioFile, audioFileProperty: AudioFileProperty){
    return isTrackPropertySearchable(track, audioFileProperty);
  }

  public searchTextForProperty(track: AudioFile, audioFileProperty: AudioFileProperty){
    return this.translateService.instant(this.baseSearchTextForProperty(audioFileProperty)).replace("{0}", trackPropertyValue(track, audioFileProperty));
  }

  private baseSearchTextForProperty(audioFileProperty: AudioFileProperty){
    switch(audioFileProperty){
      case AudioFileProperty.YEAR:
        return"trackOptions.search.year"
      case AudioFileProperty.BPM:
        return"trackOptions.search.bpm"
      case AudioFileProperty.DANCING_STYLE:
        return"trackOptions.search.dancingStyle"
      case AudioFileProperty.MOOD:
        return"trackOptions.search.mood"
      case AudioFileProperty.MUSIC_STYLE:
        return"trackOptions.search.musicStyle"
      default:
        return "trackOptions.search.similar";
    }
  }


  //Actions
  get removeFromPlaylistActionAvailable(){
    return this.trackInfoOverlayData.context.trackInfoMode == TrackInfoContextMode.playlist;
  }

  get removeFromFavoriteActionAvailable(){
    return this.trackInfoOverlayData.context.trackInfoMode == TrackInfoContextMode.favorite;
  }

  get removeFromQueurAvailable(){
    return this.trackInfoOverlayData.context.trackInfoMode == TrackInfoContextMode.queue;
  }

  get toggleFavoriteActionAvailable(){
    return this.favBannedTrack != null && this.trackInfoOverlayData.context.trackInfoMode != TrackInfoContextMode.favorite;
  }

  get toggleBannedActionAvailable(){
    return this.favBannedTrack != null && this.trackInfoOverlayData.context.trackInfoMode != TrackInfoContextMode.playlist && this.trackInfoOverlayData.context.trackInfoMode != TrackInfoContextMode.favorite;
  }

  get addToPlaylistAvailable(){
    return true
  }

  get toggleQueueAvailable(){
    return  this.trackInfoOverlayData.context.trackInfoMode != TrackInfoContextMode.queue;
  }

  public audioFileProperties = [
    AudioFileProperty.DURATION,
    AudioFileProperty.YEAR,
    AudioFileProperty.BPM,
    AudioFileProperty.MUSIC_STYLE,
    AudioFileProperty.MOOD,
    AudioFileProperty.DANCING_STYLE
  ];

  public get addToQueueEnabled$(){
    return this.subscriptionsService.accessRights$
      .pipe(
        map(
          (accessRights) => {
            return accessRights == null || accessRights.addToQueue
          })
      )
  }

  public get searchEnabled$(){
    return this.subscriptionsService.accessRights$
      .pipe(
        map(
          (accessRights) => {
            return accessRights == null || accessRights.search
          })
      )
  }

  public get customPlaylistsEnabled$(){
    return this.subscriptionsService.accessRights$
      .pipe(
        map(
          (accessRights) => {
            return accessRights == null || accessRights.customPlaylists
          })
      )
  }

  constructor(
    private translateService: TranslateService,
    private trackOverlayService: TrackOverlayService,
    private favoriteBannedTrackService: FavoriteBannedTrackService,
    private queueService: QueueService,
    private subscriptionsService: SubscriptionsService
  ) {

  }

  ngOnInit(): void {
      this.queueService.queue$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        ()=> {
          this.adjustIsInQueue();
        }
      )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.favBannedTrack = null;

    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }


  //Track actions
  onRemoveFromPlaylist(){
    if (this.trackInfoOverlayData.track && this.trackInfoOverlayData.context.playlist){
      this.trackOverlayService.removeFromPlaylist(this.trackInfoOverlayData.track, this.trackInfoOverlayData.context.playlist);
    }
  }

  onRemoveFromQueue(){
    if (this.trackInfoOverlayData.track){
      this.trackOverlayService.removeFromQueue(this.trackInfoOverlayData.track);
    }
  }

  //When an action is toggled: force the state on the ui (view is closing)
  public get isFavorite(){
    return this.forceIsFavorite || (!this.forceIsNotFavorite && this.favBannedTrack.trackIsFavorite)
  }
  public forceIsFavorite = false;
  public forceIsNotFavorite = false;
  onToggleFavorite(){
    if (this.favBannedTrack){

      if (this.favBannedTrack.trackIsFavorite){
        this.forceIsFavorite = true;
      }else{
        this.forceIsNotFavorite = true;
      }

      this.favBannedTrack.toggleIsFavorite();
      if (this.favBannedTrack.trackIsFavorite){
        this.trackOverlayService.notifyAction(TrackOverlayActionFeedbackType.AddedToFavorites);
      }else{
        this.trackOverlayService.notifyAction(TrackOverlayActionFeedbackType.RemovedFromFavorites);
      }
    }
  }

  //When an action is toggled: force the state on the ui (view is closing)
  public get isBanned(){
    return this.forceIsBanned || (!this.forceIsNotBanned && this.favBannedTrack.trackIsBanned)
  }
  public forceIsBanned = false;
  public forceIsNotBanned = false;
  onToggleBanlist(){
    if (this.favBannedTrack){

      if (this.favBannedTrack.trackIsBanned){
        this.forceIsBanned = true;
      }else{
        this.forceIsNotBanned = true;
      }

      this.favBannedTrack.toggleIsBanned();
      if (this.favBannedTrack.trackIsBanned){
        this.trackOverlayService.notifyAction(TrackOverlayActionFeedbackType.AddedToBanlist);
      }else{
        this.trackOverlayService.notifyAction(TrackOverlayActionFeedbackType.RemovedFromBanlist);
      }
    }
  }

  onAddToPlaylist(){
    this.selectPlaylist.emit();
  }

  //Queue
  public trackIsInQueue = false
  private adjustIsInQueue(){
    if (this.trackInfoOverlayData && this.trackInfoOverlayData.track && this.queueService.queue){
      this.trackIsInQueue = this.queueService.queue.filter(track => track.id == this.trackInfoOverlayData.track.id).length > 0;
    }else{
      this.trackIsInQueue = false;
    }
  }

  onToggleQueue(){
    if (this.trackIsInQueue){
      this.trackOverlayService.removeFromQueue(this.trackInfoOverlayData.track);
    }else{
      this.trackOverlayService.addToQueue(this.trackInfoOverlayData.track);
    }
  }

  onSearchArtist(){
    if (this.trackInfoOverlayData && this.trackInfoOverlayData.track){
      this.trackOverlayService.search(this.trackInfoOverlayData.track, SearchType.SEARCH_ON_GROUP);
    }
  }

  onSearchTitle(){
    if (this.trackInfoOverlayData && this.trackInfoOverlayData.track){
      this.trackOverlayService.search(this.trackInfoOverlayData.track, SearchType.SEARCH_ON_TITLE);
    }
  }

  onSearchSimilar(){
    if (this.trackInfoOverlayData && this.trackInfoOverlayData.track){
      this.trackOverlayService.searchSuggestion(this.trackInfoOverlayData.track, SuggestionsType.SUGGEST_SIMILAR);
    }
  }

  onSearchProperty(audioFileProperty: AudioFileProperty){
    if (this.trackInfoOverlayData && this.trackInfoOverlayData.track){
      let suggestionType = SuggestionsType.SUGGEST_SIMILAR;
      switch (audioFileProperty){
        case AudioFileProperty.BPM:
          suggestionType = SuggestionsType.SUGGEST_ON_BPM;
          break;
        case AudioFileProperty.YEAR:
          suggestionType = SuggestionsType.SUGGEST_ON_YEAR;
          break;
        case AudioFileProperty.DANCING_STYLE:
          suggestionType = SuggestionsType.SUGGEST_ON_DANCING_STYLE;
          break;
        case AudioFileProperty.MOOD:
          suggestionType = SuggestionsType.SUGGEST_ON_MOOD;
          break;
        case AudioFileProperty.MUSIC_STYLE:
          suggestionType = SuggestionsType.SUGGEST_ON_STYLE;
          break;

      }
      this.trackOverlayService.searchSuggestion(this.trackInfoOverlayData.track, suggestionType);
    }
  }

}
