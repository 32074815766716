import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { LoggerService } from '../loggers/logger.service';
import { Subject, BehaviorSubject, Observable, merge, Subscription, timer } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { DTO_SubscriptionInfo, SubscriptionsApiService } from '@service/api/subscriptions-api.service';
import { ZoneConfigurationService } from './zone-configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { DTO_AccessRights } from '../api/subscriptions-api.service';
import { Config } from '@service/config';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService implements OnDestroy{

  private LOGGER_CLASSNAME = 'SubscriptionsService';

  //polling interval in seconds
  //every hour
  private readonly SUBSCRIPTIONS_POLLING_INTERVAL = 3600;
  //for testing -> every 30 seconds
  //private readonly SUBSCRIPTIONS_POLLING_INTERVAL = 30;

  private readonly VALID_DAYS_THRESHOLD = 8;
  //private VALID_DAYS_AUTORENEW_THRESHOLD = 2;

  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private loggerService: LoggerService,
    private subscriptionsApiService: SubscriptionsApiService,
    private translateService: TranslateService
  ) {
    this.zoneConnectionsService.activeZoneConnection$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (zoneConnection) => {

          this.cleanUpData();
          this.adjustSubscriptionInfoPolling();

          if (zoneConnection != null) {
            // zone just became active -> load info
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged in ... start loading subscriptions');
            this.loadSubscriptionData();
            this.loadAccessRights();
          } else {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged out ... unloading subscriptions');
          }
        }, err => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'error from subscriptions: ' + err);
        }
      );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    this.newMessageToTranslate$.next(); // cancel any subscriptions to translate a raw subscription message
    this.newMessageToTranslate$.complete();
    this.newMessageToTranslate$ = null;

    this.subscriptionInfoStarted.next();
    this.subscriptionInfoStarted.complete();
    this.subscriptionInfoStarted = null;

    this.loadAccessRightsStarted$.next();
    this.loadAccessRightsStarted$.complete();
    this.loadAccessRightsStarted$ = null;
  }



  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loadingSubject.asObservable();

  /**
   * Loading subscriptionInfo
   * @type {boolean}
   * @private
   */
  private get _loading(): boolean {
    return this._loadingSubject.value;
  }

  private set _loading(value: boolean) {
    if (this._loading !== value) {
      this._loadingSubject.next(value);
    }
  }

  get loading(): boolean {
    return this._loading;
  }


  private _loadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public loadingError$: Observable<string> = this._loadingErrorSubject.asObservable();

  /**
   * Error emitter for retrieving subscription Info
   * @type {string}
   * @private
   */
  private get _loadingError(): string {
    return this._loadingErrorSubject.value;
  }

  private set _loadingError(value: string) {
    if (this._loadingError !== value) {
      this._loadingErrorSubject.next(value);
    }
  }

  get loadingError(): string {
    return this._loadingError;
  }


  private _subscriptionInfoSubject: BehaviorSubject<DTO_SubscriptionInfo> = new BehaviorSubject<DTO_SubscriptionInfo>(null);
  public subscriptionInfo$: Observable<DTO_SubscriptionInfo> = this._subscriptionInfoSubject.asObservable();

  private get _subscriptionInfo(): DTO_SubscriptionInfo {
    return this._subscriptionInfoSubject.value;
  }

  private set _subscriptionInfo(value: DTO_SubscriptionInfo) {

    //before we change the subscriptionInfo -> check if some things have changed
    if (this._subscriptionInfo != null && value != null){
      if (value.subscriptionType != this._subscriptionInfo.subscriptionType){
        this.loadAccessRights();
      }
    }

    this._subscriptionInfoSubject.next(value);

    this.handleSubscriptionInfo();
  }

  get subscriptionInfo(): DTO_SubscriptionInfo {
    return this._subscriptionInfo;
  }

  /**
   * subscriptionInfo Polling
   */
  private pollingSubscription: Subscription;
  private adjustSubscriptionInfoPolling(){
    if (this.zoneConnectionsService.activeZoneConnection != null){
      if (this.pollingSubscription == null){
        //start timer
        this.pollingSubscription = timer(0, this.SUBSCRIPTIONS_POLLING_INTERVAL * 1000)
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(
            () => {
              this.loadSubscriptionData();
            }
          );
      }
    }else{
      if (this.pollingSubscription){
        this.pollingSubscription.unsubscribe();
        this.pollingSubscription = null;
      }
    }

  }

  private loadSubscriptionInfoFailedAttempts = 0;
  private retryLoadSubscriptionInfo() {
    const delay = Math.pow(2, this.loadSubscriptionInfoFailedAttempts - 1) * Config.RETRY_BASE_INTERVAL_MS;
    timer(Math.min(delay, Config.RETRY_MAX_INTERVAL_MS))
    .pipe(
      takeUntil(
        merge(
          this.subscriptionInfoStarted,
          this.destroyed$
        )
      )
    )
    .subscribe(
      ()=>{
         //if no reload happened, check if we need to re-save (or save new changes)
         if (this.zoneConnectionsService.activeZoneConnection){
          this.loadSubscriptionData();
         }
      }
    )
  }

  private subscriptionInfoStarted = new Subject<void>();
  private loadSubscriptionData(){

    //cancel any previous
    this.subscriptionInfoStarted.next(null);

    //adjust loading state after any previous loader has stopped
    this._loading = false;
    this._loadingError = null;

    const subscriptionInfoObservable = this.subscriptionsApiService.loadSubscriptionInfo();

    subscriptionInfoObservable
      .pipe(
        finalize(() => {
            this._loading = false;

            if (this._loadingError){
              this.retryLoadSubscriptionInfo();
            }

        }),
        takeUntil(
          merge(this.destroyed$, this.subscriptionInfoStarted)
        ))
      .subscribe(
            (data) => {
                this.loggerService.debug(this.LOGGER_CLASSNAME, "loadSubscriptionData", "data : " + data);

                this.loadSubscriptionInfoFailedAttempts = 0;

                this._subscriptionInfo = data;
            },
            (error : unknown) => {
              let errMsg = 'Server error: ' + error;
              if (error instanceof HttpErrorResponse){
                errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';
              }
              this.loggerService.error(this.LOGGER_CLASSNAME, "load subscriptionInfo", "error while loading: " + errMsg);

              this.loadSubscriptionInfoFailedAttempts++;

              this._loadingError = 'GeneralError';
            }
        );
  }

  private cleanUpData(){
    //cancel any running request
    this.subscriptionInfoStarted.next(null);
    this.loadAccessRightsStarted$.next(null);

    this._loadingError = null;
    this._loading = false;
    this._subscriptionMessage = null;
    this._subscriptionInfo = null;
    this._accessRights = null;
  }


  /**
   * SUBSCRIPTION MESSAGE
   */


  private _subscriptionMessageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public subscriptionMessage$: Observable<string> = this._subscriptionMessageSubject.asObservable();


  private get _subscriptionMessage(): string {
    return this._subscriptionMessageSubject.value;
  }

  private rawMessage:string = null;
  private newMessageToTranslate$ = new Subject<void>();
  private set _subscriptionMessage(value: string) {
    if (value != this.rawMessage){
      this.rawMessage = value;
      this.newMessageToTranslate$.next();
      if (value != null && value != ""){
        this.translateService.stream(value)
        .pipe(
          takeUntil(this.newMessageToTranslate$)
        )
        .subscribe(
          (translatedValue) => {
            if (this.subscriptionInfo){
              translatedValue = translatedValue.replace("{0}", ""+this.subscriptionInfo.remainingDays);
              translatedValue = translatedValue.replace("{1}", ""+this.subscriptionInfo.extendedPeriodEndsAt);
            }
            this._subscriptionMessageSubject.next(translatedValue);
          }
        )

      }else{
        this._subscriptionMessageSubject.next(this.rawMessage);
      }
    }
  }

  get subscriptionMessage(): string {
    return this._subscriptionMessage;
  }

  //marker to check if we should update the access Rigths
  private subscriptionEndDetected = false;

  private TEST_END_SUBSCRIPTION = false;

  //this method will inspect the subscription info and generate a message if needed
  private handleSubscriptionInfo(){

    if (this.subscriptionInfo){
      let newSubscriptionEndDetected = false;

      if (this.TEST_END_SUBSCRIPTION || (this.subscriptionInfo.daysFilledIn && this.subscriptionInfo.remainingDays == 0)){
				if (this.subscriptionInfo.inExtendedActivationPeriod){
					this._subscriptionMessage = 'messageCenter.subscriptions.extendedPeriod';
				}else{
          if (!environment.loginWithoutSubscription){
            this.zoneConnectionsService.deactivateCurrentZoneConnection(this.translateSubscriptionMessage('messageCenter.subscriptions.ended'), true, false);
          }else{
            this._subscriptionMessage = 'messageCenter.subscriptions.ended';
            if (!this.subscriptionEndDetected){
              //check access rights again -> we shouldn't be able to play any longer
					    this.loadAccessRights();
            }
            newSubscriptionEndDetected = true;
          }

				}
      }else{
        var validDaysThressholdForOnScreenMessage = this.VALID_DAYS_THRESHOLD;
        //Once we support auto-renew, we could lower the threshold for the auto renew zones
        /*
        if (this.subscriptionInfo.isAutomaticallyRenewed){
					validDaysThressholdForOnScreenMessage = VALID_DAYS_AUTORENEW_THRESHOLD;
        }
        */

        if (this.subscriptionInfo.daysFilledIn && this.subscriptionInfo.remainingDays <= validDaysThressholdForOnScreenMessage){
          if (this.subscriptionInfo.remainingDays == 1){
            this._subscriptionMessage = 'messageCenter.subscriptions.warningDay';
          }else{
            this._subscriptionMessage = 'messageCenter.subscriptions.warningDays';
          }
        }else{
          this._subscriptionMessage = "";
        }
      }


      this.subscriptionEndDetected = newSubscriptionEndDetected;
    }
  }


  //helper method to translate a message and fill in values from the subscriptionInfo
  private translateSubscriptionMessage(value: string): string{
    let translatedValue = value;
    //When the message is set, we will update the variables with the subscriptionInfo values
    if (value != null && value != ""){
      translatedValue = this.translateService.instant(value);
      if (this.subscriptionInfo){
        translatedValue = translatedValue.replace("{0}", ""+this.subscriptionInfo.remainingDays);
        translatedValue = translatedValue.replace("{1}", ""+this.subscriptionInfo.extendedPeriodEndsAt);
      }
    }
    return translatedValue;
  }



  /**
     * Access rights of the zone
     */
    //TODO -> polling to check changes
    //We now reload when the subscription type has changed
    private get _accessRights(): DTO_AccessRights {
      return this._accessRightsSubject.value;
  }
  private set _accessRights(value: DTO_AccessRights) {
      if (this._accessRights != value) {

        //fill missing properties - for now, all true to easily test
          if (value != null){
              if (!value.hasOwnProperty("addToQueue")){
                  value.addToQueue = true;
              }
              if (!value.hasOwnProperty("startTrack")){
                  value.startTrack = true;
              }
          }


          //testing purpose
          //value.accessBlue = false;
          //value.accessOrange = false;
          //value.customCalendars = false;
          //value.customPlaylists = false;
          //value.search = false;
          //value.addToQueue = false;
          //value.startTrack = false;

          this._accessRightsSubject.next(value);

      }
  }

  public get accessRights(): DTO_AccessRights{
      return this._accessRights;
  }
  private _accessRightsSubject: BehaviorSubject<DTO_AccessRights> = new BehaviorSubject<DTO_AccessRights>(null);
  public accessRights$: Observable<DTO_AccessRights> = this._accessRightsSubject.asObservable();


  private get _accessRightsLoadError(): boolean {
    return this._accessRightsLoadErrorSubject.value;
  }
  private set _accessRightsLoadError(value: boolean) {
    if (this._accessRightsLoadError !== value) {
        this._accessRightsLoadErrorSubject.next(value);
    }
  }

  public get accessRightsLoadError(): boolean{
    return this._accessRightsLoadError;
  }
  private _accessRightsLoadErrorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public accessRightsLoadError$: Observable<boolean> = this._accessRightsLoadErrorSubject.asObservable();



  private loadAccessRightsFailedAttempts = 0;
  private retryLoadAccessRights() {
    const delay = Math.pow(2, this.loadAccessRightsFailedAttempts - 1) * Config.RETRY_BASE_INTERVAL_MS;
    timer(Math.min(delay, Config.RETRY_MAX_INTERVAL_MS))
    .pipe(
      takeUntil(
        merge(
          this.loadAccessRightsStarted$,
          this.destroyed$
        )
      )
    )
    .subscribe(
      ()=>{
         //if no reload happened, check if we need to re-save (or save new changes)
         if (this.zoneConnectionsService.activeZoneConnection){
          this.loadAccessRights();
         }
      }
    )
  }


  private loadAccessRightsStarted$ = new Subject<void>();
  public loadAccessRights(){

    const accessRightsObservable = this.subscriptionsApiService.loadAccessRights();
    if (accessRightsObservable != null){
      this.loadAccessRightsStarted$.next();

      this._accessRightsLoadError = false;

      accessRightsObservable
        .pipe(
            takeUntil(
              merge(
                this.destroyed$,
                this.loadAccessRightsStarted$
                )
            ),
            finalize(()=>{
              //check if we need to re-save
              if (this.accessRightsLoadError){
                this.retryLoadAccessRights();
              }
            })
        )
        .subscribe(
            (data) => {
                this.loggerService.debug(this.LOGGER_CLASSNAME, "loadAccessRights", "data : " + data);

                this.loadAccessRightsFailedAttempts = 0;

                //testing
                //data.startTrack = false;
                //data.addToQueue = false;
                //data.search = false;
                //data.customPlaylists = false;
                //data.customCalendars = false;


                this._accessRights = data;
            },
            (error : unknown) => {
              let errMsg = 'Server error: ' + error;
              if (error instanceof HttpErrorResponse){
                errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';
              }
              this.loggerService.error(this.LOGGER_CLASSNAME, "load access rights", "error while loading access rights: " + errMsg);

              this.loadAccessRightsFailedAttempts++;

              this._accessRightsLoadError = true;
            }


        );
    }
  }
}
