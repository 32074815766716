<div style="width: 100%; height: 100%; display: flex;">
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 150 52">
      <style>.a{clip-path:url(#SVGID_2_);fill:#FFF;}</style>
      <defs>
          <rect x="-45" y="-19" width="100%" height="100%"/>
      </defs>
      <clipPath>
        <use xlink:href="#SVGID_1_"/>
      </clipPath>
      <path d="M19.6 34.4c0.3 0.2 0.4 0.6 0.4 0.9v4.4c0 0.3-0.1 0.6-0.4 0.8 -0.2 0.2-0.5 0.5-0.9 0.5 0 0 0.1 0 0 0h-3.5 -0.7 -0.2H14.1c-1.8-0.1-3.3-0.3-4.5-0.7 -1.2-0.4-2.1-1-2.9-1.7 -0.7-0.8-1-1.7-1.3-2.9C5.2 34.6 5 33.3 5 31.7v-13.7H1.3c-0.4 0-0.7-0.1-0.9-0.4C0.1 17.3 0 17 0 16.6v-0.3V12.4c0-0.4 0.1-0.7 0.4-1C0.6 11.1 0.9 11 1.3 11H5V1.3c0-0.4 0.1-0.7 0.4-0.9C5.7 0.1 6 0 6.4 0h5.2c0.4 0 0.7 0.1 1 0.4 0.3 0.2 0.4 0.6 0.4 0.9L13 11h5.7c0.3 0 0.6 0.1 0.9 0.4C19.9 11.7 20 12.1 20 12.5v4.1c0 0.4-0.1 0.7-0.4 1 -0.3 0.3-0.6 0.4-0.9 0.4h-5.7l0 12.5c0 0.5 0 1 0.1 1.4 0 0.4 0.2 0.8 0.4 1 0.2 0.3 0.5 0.5 0.8 0.7 0.4 0.2 0.9 0.3 1.5 0.4h2.8C19 34 19.3 34.1 19.6 34.4" class="a"/>
      <path d="M50.6 11.4C50.9 11.7 51 12 51 12.3v0.9 4.4 9.1c0 2.5-0.2 4.7-0.6 6.6 -0.4 1.8-1.1 3.3-2.2 4.5 -1 1.2-2.5 2-4.4 2.5C42 40.7 40 41 37.1 41c-2.8 0-5.2-0.3-7-0.8 -1.8-0.5-3.2-1.3-4.3-2.5 -1.1-1.2-1.8-2.6-2.2-4.5C23.2 31.4 23 29.2 23 26.7v-9.1 -3.3 -2c0-0.3 0.1-0.6 0.4-0.9C23.7 11.1 24 11 24.4 11h0.8 3.9 0.3c0.4 0 0.9 0 1.1 0.3 0.3 0.3 0.4 0.6 0.4 0.9L31 18.5c0 0 0-0.2 0 0l0 0v8.4c0 1 0 2 0.1 2.9 0.1 0.9 0.3 1.6 0.7 2.2 0.4 0.6 1 1.1 1.8 1.4C34.4 33.8 35.5 34 37 34c1.5 0 2.6-0.1 3.4-0.4 0.8-0.3 1.4-0.8 1.8-1.5 0.4-0.6 0.6-1.4 0.7-2.3C43 29 43 28.1 43 27v-9.5 -5.2c0-0.3 0.1-0.6 0.4-0.9 0.3-0.3 0.6-0.4 1-0.4h0.7 2.5 2C50 11 50.3 11.2 50.6 11.4" class="a"/>
      <path d="M71.5 11c2.2 0 4 0.3 5.4 0.9 1.4 0.6 2.4 1.4 3.2 2.5 0.8 1.1 1.3 2.4 1.6 3.9C81.9 19.8 82 21.4 82 23.3v11 5 0.5c0 0.3-0.1 0.6-0.4 0.9C81.3 40.9 81 41 80.6 41h-5.2c-0.4 0-0.7-0.1-1-0.4C74.1 40.4 74 40.1 74 39.7v-0.5V24.7c0-0.9-0.1-1.7-0.2-2.5 -0.1-0.8-0.3-1.4-0.7-2 -0.4-0.5-0.9-1-1.6-1.3 -0.7-0.3-1.7-0.5-2.9-0.5 -1.4 0-2.6 0.2-3.4 0.5 -0.9 0.3-1.6 0.8-2 1.4 -0.5 0.6-0.8 1.3-0.9 2.1C62.1 23.2 62 24.1 62 25v9.1 0.1 5.5c0 0.3-0.1 0.6-0.4 0.9 -0.3 0.3-0.6 0.4-1 0.4h-5.2c-0.4 0-0.7-0.1-1-0.4C54.1 40.4 54 40 54 39.7v-1.7 -3.8V17.7v-5.4 -0.2c0.1-0.3 0.2-0.6 0.5-0.8C54.8 11.1 55.1 11 55.4 11h5.2c0.4 0 0.7 0.1 1 0.4C61.9 11.6 62 11.9 62 12.3v1.8c0.4-0.4 0.7-0.8 1-1.2 0.3-0.4 0.8-0.7 1.4-1 0.6-0.3 1.5-0.5 2.5-0.7C68.1 11.1 69.6 11 71.6 11" class="a"/>
      <path d="M93 39.7c0 0.3-0.1 0.6-0.4 0.9C92.3 40.9 92 41 91.6 41h-5.1c-0.4 0-0.7-0.1-1-0.4C85.1 40.4 85 40.1 85 39.7V12.3c0-0.3 0.1-0.6 0.4-0.9 0.3-0.3 0.6-0.4 1-0.4h5.1c0.4 0 0.7 0.1 1 0.4C92.9 11.6 93 11.9 93 12.3V39.7z" class="a"/>
      <path d="M119.1 12.3c-0.1-0.3-0.1-0.7 0.1-0.9 0.2-0.3 0.5-0.4 0.9-0.4h0.2 5.6 0.4c0.4 0 0.8 0.1 1.1 0.4 0.4 0.3 0.6 0.6 0.8 0.9l2 5.5c0 0.1 0 0.1 0 0.1 0 0 0 0.1 0 0.1l4.5 11.8 6.4-17.6c0.1-0.3 0.4-0.6 0.8-0.9 0.4-0.3 0.7-0.4 1.1-0.4h0.7 4.5 0.6c0.4 0 0.7 0.1 0.9 0.4 0.2 0.3 0.2 0.6 0.1 0.9l-11.6 31.1c-0.5 1.1-1.1 2.6-1.6 3.6 -0.5 1-1.2 1.7-2 2.5 -0.8 0.8-1.9 1.4-3.2 1.8 -1.3 0.4-3 0.6-5.1 0.6h-1 -1.9c-0.4 0-0.7-0.1-1-0.4 -0.3-0.2-0.4-0.5-0.4-0.9v-4.7c0-0.4 0.1-0.7 0.4-0.9 0.3-0.2 0.6-0.4 1-0.4h2c0.8 0 1.4-0.1 1.9-0.2 0.5-0.1 0.9-0.5 1.3-0.7 0.3-0.2 0.6-0.5 0.8-0.9 0.2-0.4 0.4-0.6 0.6-1.1 0.2-0.6 0.3-1.5 0.3-1.5L119.1 12.4 119.1 12.3z" class="a"/>
      <path d="M93 2.7C93 3.4 92.4 4 91.7 4h-5.3C85.6 4 85 3.4 85 2.7V1.3C85 0.6 85.6 0 86.3 0h5.3C92.4 0 93 0.6 93 1.3V2.7z" style="clip-path:url(#SVGID_2_);fill:#7CBB59"/>
      <path d="M93 7.7C93 8.4 92.4 9 91.7 9h-5.3C85.6 9 85 8.4 85 7.7V6.3C85 5.6 85.6 5 86.3 5h5.3C92.4 5 93 5.6 93 6.3V7.7z" style="clip-path:url(#SVGID_2_);fill:#0EB4DF"/>
      <path d="M111.9 7.3c-0.6 0.3-0.8 0.7-1.1 1.2 -0.3 0.5-0.5 1-0.6 1.6 -0.1 0.6-0.2 0.9-0.2 0.9h5.6c0.4 0 0.7 0.1 1 0.4 0.3 0.2 0.4 0.5 0.4 0.9v4.5c0 0.4-0.1 0.7-0.4 0.9 -0.3 0.2-0.6 0.4-1 0.4h-0.3 -5.3v16.1 0.1 5.5c0 0.3-0.1 0.6-0.4 0.9 -0.3 0.3-0.9 0.4-1.3 0.4l-4.9 0c-0.4 0-0.7-0.1-1-0.4 -0.3-0.3-0.4-0.6-0.4-0.9v-0.2V18l-4.5 0c-0.4 0-0.7-0.1-1-0.4C96.1 17.4 96 17.1 96 16.7V12.2c0-0.3 0.1-0.6 0.4-0.9 0.3-0.2 0.6-0.4 1-0.4L102 11c0 0 0.2-1.9 0.6-3.3 0.4-1.4 1-2.7 1.9-3.8 0.9-1.1 1.9-2 3.4-2.7 1.5-0.7 2.4-1.1 4.6-1.1 0.5 0 0.9 0 1.2 0 0.3 0 0.5 0 0.7 0h0.5 0.6c0.4 0 0.7 0.1 1 0.3C116.9 0.6 117 0.9 117 1.2v4.3c0 0.3-0.1 0.6-0.4 0.9 -0.3 0.3-0.6 0.4-1 0.4h-1.2C113.5 6.9 112.5 7 111.9 7.3" class="a"/>
    </svg>
</div>