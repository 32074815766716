<!-- This wrapper shows the background without any animations on it-->
<div class="backGroundWrapper">

  <!--
  We need the fadeIn animation on the container
  to prevent the slideOutLeft animation from running at the initial render.
  -->
<div class="contentWrapper" @fadeIn>

  <!-- Keep the groups on screen to store the scrollPosition -->
  <tun-calendar-groups-view
    [@viewPosition]="mainViewPosition$ | async"
    class="fullContainer">
  </tun-calendar-groups-view>

  <tun-calendar-detail-view
    *ngIf="(calendarView$ | async) == CALENDAR_VIEW.CALENDAR_VIEW_DETAIL"
    [@carousel]="{
      value: animationDirection,
      params: {
        duration: CAROUSEL_DURATION
      }
    }"
    class="fullContainer"
  >

  </tun-calendar-detail-view>

  <tun-edit-title-for-calendar-view
    *ngIf="(calendarView$ | async) == CALENDAR_VIEW.CALENDAR_VIEW_CREATE || (calendarView$ | async) == CALENDAR_VIEW.CALENDAR_VIEW_EDIT_TITLE"
    [@carousel]="{
      value: animationDirection,
      params: {
        duration: CAROUSEL_DURATION
      }
    }"
    [calendar]="editTitleForCalendar$ | async"
    (back)="onBackFromEditTitleForCalendar()"
    (@carousel.start)="textViewAnimationStart($event)"
    (@carousel.done)="textViewAnimationDone($event)"
    [canReceiveFocus]="!textViewIsAnimating"
    class="fullContainer"
  >

  </tun-edit-title-for-calendar-view>

</div>


</div>
