<div class="wrapper">
  <div class="title">{{'musicChannel.label.title' | translate }}</div>
  <div *ngIf="(musicChannelsLoading$ | async)" class="loaderContainer">
    <mat-spinner class="loading-spinner"></mat-spinner>
    <span class="loadingText">{{'general.status.loading' | translate}}</span>
  </div>
  <div *ngIf="(musicChannelsLoadingError$ | async)" class="errorContainer">
    <p>{{'general.status.error.message' | translate}}</p>
    <button (click)="onRetry()" class="retryButton">
      <p>
        {{'general.status.error.retry' | translate }}
      </p>
    </button>
  </div>
  <div *ngIf="!(musicChannelsLoading$ | async) && !(musicChannelsLoadingError$ | async)" class="scrollContainer">
    <ng-container  *ngFor="let musicChannelGroup of (musicChannelGroups$ | async); index as i">
      <tun-music-channel-group-view
      class="musicChannelGroup {{ i == 0 ? 'first' : ''}}"
      [musicChannelGroup]="musicChannelGroup"
      (showDetails)="onShowDetails($event)"
    ></tun-music-channel-group-view>
    </ng-container>

  </div>
</div>
