import { Component } from '@angular/core';

@Component({
  selector: 'tun-arrow-back36px-icon-v5',
  templateUrl: './arrow-back36px-icon-v5.component.html',
  styleUrls: ['./arrow-back36px-icon-v5.component.scss']
})
export class ArrowBack36pxIconV5Component {

}
