import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil, finalize, map } from 'rxjs/operators';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { LoggerService } from '../loggers/logger.service';
import { MusicCollection } from '../../model/musicCollection';
import { Subject, Observable, BehaviorSubject, merge, combineLatest } from 'rxjs';
import { MusicChannelService } from './music-channel.service';
import { RecommendationsApiService, DTO_RecommendedMusicChannels } from '../api/recommendations-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MusicChannel } from '../../model/musicChannel';
import { environment } from 'src/environments/environment';

export class RecommendationsMusicChannel{
  musicChannel: MusicChannel;
  musicCollections: MusicCollection[];
}

@Injectable({
  providedIn: 'root'
})
export class RecommendationsService implements OnDestroy {

  private LOGGER_CLASSNAME = 'RecommendationsService';

  constructor(
    private loggerService: LoggerService,
    private zoneConnectionsService: ZoneConnectionsService,
    private musicChannelService: MusicChannelService,
    private recommendationsApiService: RecommendationsApiService
  ) {
    this.zoneConnectionsService.activeZoneConnection$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (zoneConnection) => {
        this.clearData();
        if (zoneConnection != null) {
          this.loadRecommendedMusicChannels();
        }
      }
    )

    musicChannelService.musicChannelGroups$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      () => {
        this.matchMusicChannelGroups()
      }
    )
  }

  private destroy$ = new Subject<void>();
  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = null;

    this.activeZoneChanged$.next();
    this.activeZoneChanged$.complete();
    this.activeZoneChanged$ = null;

    this.recommendedMusicChannelGroupsWillStartLoading$.complete();
    this.recommendedMusicChannelGroupsWillStartLoading$ = null;
  }

  // STATE

  //helper observable that triggers when the active zone changes
  //this will cancel any running request
  private activeZoneChanged$ = new Subject<void>();

  /**
   * loading
   * @type {boolean}
   * @private
   */
  /*
  private get _loading(): boolean {
    return this._loadingSubject.value;
  }
  private set _loading(value: boolean) {
    if (this._loading !== value) {
      this._loadingSubject.next(value);
    }
  }
  */
  get loading(): boolean {
    return this._loadingRawData || this.musicChannelService.loading;
  }
  get loading$() {
    return combineLatest([this.musicChannelService.loading$, this.loadingRawData$])
    .pipe(
      map(([musicChannelLoading, loadingRawData]) => {
      return musicChannelLoading || loadingRawData;
      })
    )
  }
  /*
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loadingSubject.asObservable();
  */

  private get _loadingRawData(): boolean {
    return this._loadingRawDataSubject.value;
  }
  private set _loadingRawData(value: boolean) {
    if (this._loadingRawData !== value) {
      this._loadingRawDataSubject.next(value);
    }
  }
  private _loadingRawDataSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingRawData$: Observable<boolean> = this._loadingRawDataSubject.asObservable();

  /**
   * Error emitter for retrieving the recommended musicChannels
   * @type {string}
   * @private
   */
  private get _loadingError(): string {
    return this._loadingErrorSubject.value;
  }
  private set _loadingError(value: string) {
    if (this._loadingError !== value) {
      this._loadingErrorSubject.next(value);
    }
  }
  get loadingError(): string {
    return this._loadingError;
  }
  private _loadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public loadingError$: Observable<string> = this._loadingErrorSubject.asObservable();

    /**
   * the raw recommended musicChannels, as they are retrieved from the server
   * @type {DTO_RecommendedMusicChannels}
   * @private
   */
  private get _rawRecommendedMusicChannels(): DTO_RecommendedMusicChannels {
    return this._rawRecommendedMusicChannelsSubject.value;
  }
  private set _rawRecommendedMusicChannels(value: DTO_RecommendedMusicChannels) {
    if (this._rawRecommendedMusicChannels !== value) {
      this._rawRecommendedMusicChannelsSubject.next(value);
      this.matchMusicChannelGroups();
    }
  }
  get rawRecommendedMusicChannels(): DTO_RecommendedMusicChannels {
    return this._rawRecommendedMusicChannels;
  }
  private _rawRecommendedMusicChannelsSubject: BehaviorSubject<DTO_RecommendedMusicChannels> = new BehaviorSubject<DTO_RecommendedMusicChannels>(null);
  public rawRecommendedMusicChannels$: Observable<DTO_RecommendedMusicChannels> = this._rawRecommendedMusicChannelsSubject.asObservable();

  /**
   * Versioning
   */
 private recommendedMusicChannelGroupsTimestamp: number = undefined;
 private timestampFromUpdateTrigger: number = undefined; //timestamp that we have received after a save or update event. This is used when the data is fetched to check if we have the most recent version
 private refetchedToAvoidVersionConflict = false; //boolean to avoid we fetch multiple times in a row (avoid endless loop when the server messes up the version numbers)

 public handleRecommendedMusicChannelGroupsUpdateInfo(timestamp: number){
   //keep track of the highest seen timestamp
   if (timestamp != undefined){
     if (this.timestampFromUpdateTrigger == undefined || this.timestampFromUpdateTrigger < timestamp) {
       this.timestampFromUpdateTrigger = timestamp;
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleRecommendedMusicChannelGroupsUpdateInfo', 'Going to check for newest version:  ' + timestamp);
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleRecommendedMusicChannelGroupsUpdateInfo', 'Already going to check for an even newer version:  ' + this.timestampFromUpdateTrigger + '(we just received: ' + timestamp + ')');
     }
   }else{
     this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleRecommendedMusicChannelGroupsUpdateInfo', 'No timestamp info');
   }

   this.checkReloadRecommendedMusicChannelGroupsNeeded();
 }

 private checkReloadRecommendedMusicChannelGroupsNeeded(){
   if (this.timestampFromUpdateTrigger != undefined){
     //if we are not loading the recommended musicChannels -> fetch
     if (!this.loading) {
       if (this.timestampFromUpdateTrigger > this.recommendedMusicChannelGroupsTimestamp){
         this.loadRecommendedMusicChannels();
       }else{
         this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadRecommendedMusicChannelGroupsNeeded', 'Already have a newer version (' + this.recommendedMusicChannelGroupsTimestamp + '), not going to reload for update trigger timestamp ' + this.timestampFromUpdateTrigger);
       }
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadRecommendedMusicChannelGroupsNeeded', 'Not going to load right now: loading: ' + (this.loading ? 'true': 'false') + '.');
     }
   }
 }

  //the subject to cancel any previous request
  private recommendedMusicChannelGroupsWillStartLoading$: Subject<void> = new Subject<void>();
  public loadRecommendedMusicChannels(): Observable<DTO_RecommendedMusicChannels> {

    if (environment.enableRecommendations){
      const loadObservable: Observable<DTO_RecommendedMusicChannels> = this.recommendationsApiService.loadRecommendedMusicChannels();

      if (loadObservable != null){

        this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels', 'Going to load recommended musicChannels');
        this.recommendedMusicChannelGroupsWillStartLoading$.next(null);

        this._loadingRawData = true;

        loadObservable
          .pipe(
            takeUntil(
              merge(
                this.destroy$,
                this.activeZoneChanged$,
                this.recommendedMusicChannelGroupsWillStartLoading$
              )
            ),
            finalize(() => {
              this._loadingRawData = false;
              this.checkReloadRecommendedMusicChannelGroupsNeeded()
            })
          )
          .subscribe(
            (data) => {

              let useResponse = true;

              //Uncomment once versioning is added


              if (this.timestampFromUpdateTrigger != undefined && this.timestampFromUpdateTrigger > data.timestamp) {
                //there is a newer resource version on the server -> refetch
                if (!this.refetchedToAvoidVersionConflict) {
                  //we are only going to refetch once, to avoid endless loop of fetching data when there is something wrong with our version timestamps
                  this.refetchedToAvoidVersionConflict = true;
                  useResponse = false;
                } else {
                  //ignore the newer version and start using this queue

                  this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadMusicChannelGroups', 'loadMusicChannelGroups receives an older version number but we already refetched before -> just start working with this version');
                }
              }

              if (useResponse) {

                //reset the conflict flag once we use the queue
                this.refetchedToAvoidVersionConflict = false;
                //reset the highest seen timestamp
                this.timestampFromUpdateTrigger = undefined;

                this._rawRecommendedMusicChannels = data

                this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels', 'loadMusicChannelGroups done');
                //this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels', 'loadMusicChannelGroups done (timestamp: ' + this.musicChannelGroupsTimestamp + '): ' + this.musicChannelGroups.length + ' musicChannelGroups.');
              } else {
                this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels', 'Not going to use result -> reload recommendedMusicChannels from the finalize block.');
              }
            },
            (error : unknown) => {
              let errMsg = 'Error';
              if (error instanceof HttpErrorResponse){
                errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';
              }
              this.loggerService.error(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels error', errMsg);
              this._loadingError = 'GeneralError';
            });

      }else{
        this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels', 'Observable to load recommended musicChannels was not created');
      }
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels', 'Recommendations are disabled');
    }


    return this.rawRecommendedMusicChannels$;
  }

  private clearData() {
    //cancel any loading requests
    this.activeZoneChanged$.next();

    this._loadingRawData = false;
    this._loadingError = null;
    this._rawRecommendedMusicChannels = null;
  }

  /**
  * Recommended musicChannels with their data
  */

    private get _recommendedMusicChannels(): RecommendationsMusicChannel[] {
      return this._recommendedMusicChannelsSubject.value;
    }
    private set _recommendedMusicChannels(value: RecommendationsMusicChannel[]) {
      if (this._recommendedMusicChannels !== value) {
        this._recommendedMusicChannelsSubject.next(value);
        this.adjustMusicCollectionData();
      }
    }
    get recommendedMusicChannels(): RecommendationsMusicChannel[] {
      return this._recommendedMusicChannels;
    }
    private _recommendedMusicChannelsSubject: BehaviorSubject<RecommendationsMusicChannel[]> = new BehaviorSubject<RecommendationsMusicChannel[]>([]);
    public recommendedMusicChannels$: Observable<RecommendationsMusicChannel[]> = this._recommendedMusicChannelsSubject.asObservable();

  private matchMusicChannelGroups(){

    if (this._rawRecommendedMusicChannels != null && this.musicChannelService.musicChannelGroups ) {

      const recommendedMCs : RecommendationsMusicChannel[] = []


      for (const recommendedMusicChannel of this._rawRecommendedMusicChannels.musicChannels) {
        const musicChannel = this.musicChannelService.findMusicChannelById(recommendedMusicChannel.musicChannelId)
        if (musicChannel != null && musicChannel.musicCollections != null) {
          const mcIds = recommendedMusicChannel.musicCollections.map(rMC => rMC.id);
          const musicCollections = musicChannel.musicCollections.filter(mc => { return mcIds.find(id => id == mc.id) != null} )
          if (musicCollections.length > 0) {
            const recommendationsMusicChannel = new RecommendationsMusicChannel();
            recommendationsMusicChannel.musicChannel = musicChannel;
            recommendationsMusicChannel.musicCollections = musicCollections;
            recommendedMCs.push(recommendationsMusicChannel);
          }
        }
        /*
        const musicChannelGroup = this.musicChannelService.musicChannelGroups.find(musicChannelGroup => {
          return musicChannelGroup.id == recommendedMusicChannelGroup.musicChannelGroupId;
        });
        if (musicChannelGroup != null && musicChannelGroup.musicChannels != null){
          musicChannelGroup.musicChannels.filter(musicCh)
        }*/

      }
      this._recommendedMusicChannels = recommendedMCs;
    }else{
      this._recommendedMusicChannels = null;
    }


  }

  //every time we match, we should reset the data on all the known musicCollections
  private adjustMusicCollectionData(){
    if ( this.musicChannelService.musicChannelGroups ){
      this.musicChannelService.musicChannelGroups.forEach(musicChannelGroup => {
        if (musicChannelGroup.musicChannels){
          musicChannelGroup.musicChannels.forEach(musicChannel => {
            if (musicChannel.musicCollections){
              const recommendedMusicChannel = this.recommendedMusicChannels.find(recommendedMusicChannel =>
                recommendedMusicChannel.musicChannel == musicChannel
              );
              musicChannel.musicCollections.forEach(musicCollection => {
                musicCollection.isRecommendation = recommendedMusicChannel != null && recommendedMusicChannel.musicCollections.find(mc => mc == musicCollection) != null
              })
            }
          })
        }
      });
    }
  }




}
