import { Injectable } from '@angular/core';
import { LoggerService } from '@service/loggers/logger.service';
import { PlayStateService } from './play-state.service';
import { QueueService } from '@service/queue.service';
import { BufferService } from './buffer.service';
import { AudioFile } from '@model/audioFile';
import { BehaviorSubject, Observable } from 'rxjs';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { MatDialog } from '@angular/material/dialog';
import { DoubleAudioFilesPopupComponent, DoubleAudioFilesPopupData } from '@components/popups/double-audio-files-popup/double-audio-files-popup.component';
import { MusicPlayerService } from './music-player.service';
import { AppService } from '@service/app.service';
import { Playlist } from '@model/playlist';
import { MusicCollectionService } from '@service/music-collection.service';
import { take, takeUntil } from 'rxjs/operators';
import { PlayableAudio } from '../../model/audioFile';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { ApplicationMode } from '@service/authentication/zone-connections.service';
import { RemoteActionsService } from './remote-actions.service';
import { TooManyTracksPopupComponent } from '../../components/popups/too-many-tracks-popup/too-many-tracks-popup.component';
import { SubscriptionsService } from '@service/subscriptions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SNACKBAR_DURATION } from '@view/player-v5/player-v5.component';

/**
 * This service is responsible for coordinating manual changes to the current playing item and the queues.
 *
 */

@Injectable({
  providedIn: 'root'
})
export class MusicManipulationService {

  private LOGGER_CLASSNAME = 'MusicManipulationService';


  constructor(private loggerService: LoggerService,
              private musicPlayerService: MusicPlayerService,
              private playStateService: PlayStateService,
              private queueService: QueueService,
              private bufferService: BufferService,
              private musicCollectionService: MusicCollectionService,
              private dialog: MatDialog,
              private appService: AppService,
              private zoneConnectionsService: ZoneConnectionsService,
              private remoteActionsService: RemoteActionsService,
              private subscriptionsService: SubscriptionsService,
              private snackBar: MatSnackBar,
              private translateService: TranslateService
  ) { }

  public next(){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.bufferService.reportManualChange();
      this.playStateService.startNextAudioFile();
    }else{
      this.remoteActionsService.remoteNext();
    }
  }

  public playAudioFile(audioFile: AudioFile, foceStartWithoutSubscriptionCheck = false){
    if (foceStartWithoutSubscriptionCheck || (this.subscriptionsService.accessRights == null || this.subscriptionsService.accessRights.startTrack)){

      if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
        this.bufferService.reportManualChange();
        this.playStateService.startNextAudioFile(audioFile, true);
      }else{
        this.remoteActionsService.remotePlayTrack(audioFile);
      }

      //remove the same track from the radio queue, to avoid playing doubles after a search (only remove 1, to avoid removing to many tracks in case we are playing from a context with only a few tracks)
      this.queueService.removeAudioFilesOnIdFromRadioQueue([audioFile]);

    }else{
      const snackBarRef = this.snackBar.open(this.translateService.instant('accessRight.snackbar.startTrack'), null, {
        duration: SNACKBAR_DURATION,
        panelClass: ['tunify-snackbar']
      });
    }

  }

  public prepareAsCurrentTrack(track: AudioFile){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.bufferService.reportManualChange();
      this.playStateService.startNextAudioFile(track, false);
    }else{
      this.remoteActionsService.remotePlayTrack(track);
    }
  }

  public prepareAsNextTrack(track: AudioFile){
    this.bufferService.reportManualChange();

    //remove from queue
    this.queueService.removeAudioFileFromQueue(track);
    this.queueService.removeAudioFilesFromRadioQueue([track]);

    this.queueService.addAudioFilesToQueue([track], 0);
  }




  public play(){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.playStateService.play();
    }else{
      this.remoteActionsService.remotePlay();
    }
  }

  public pause(){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.playStateService.pause();
    }else{
      this.remoteActionsService.remotePause();
    }
  }

  public seekToTime(time: number){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){
      this.playStateService.seekToTime(time);
    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, "seekToTime", "seekToTime is not supported in remote mode");
    }
  }

  public moveAudioFileInQueue(audioFile:AudioFile, index:number): Observable<AsyncStatus>{
    const statusObservable = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    if (this.queueService.queue != null){
      this.queueService.moveAudioFileInQueue(audioFile, index).subscribe(
        (asyncStatus) => {
          statusObservable.next(asyncStatus);
          if (asyncStatus == AsyncStatus.COMPLETED){
            this.bufferService.reportManualChange();
            statusObservable.complete();
          }
        }
      );
    }
    return statusObservable;

  }

  public addPlaylistToQueue(playlist: Playlist): Observable<AsyncStatus>{
    const statusObservable = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);
    if (!playlist.detailsLoaded){
      playlist.detailsloadingDone
      .pipe(
        take(1)
      )
      .subscribe( succes => {
        if (succes){
          return this.addAudioFilesToQueue(playlist.audioFiles, 0, statusObservable);
        }else{
          statusObservable.next(AsyncStatus.CANCELLED);
          statusObservable.complete();
        }
      });

      if (!playlist.detailsLoading){
        this.musicCollectionService.loadMusicCollectionDetails(playlist);
      }

    }else{
      return this.addAudioFilesToQueue(playlist.audioFiles, 0, statusObservable);
    }
    return statusObservable;
  }

  public addAudioFilesToQueue(audioFiles:AudioFile[], index?:number, statusObservableIn?:BehaviorSubject<AsyncStatus>): BehaviorSubject<AsyncStatus>{
    let statusObservable = statusObservableIn;
    if (statusObservable == null){
      statusObservable = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);
    }

    if ((this.subscriptionsService.accessRights == null || this.subscriptionsService.accessRights.addToQueue)){
      if (this.queueService.queue != null){
        if (audioFiles != null && audioFiles.length > 0){

          // check for amount: max 5000
          if (this.queueService.queue.length + audioFiles.length > 2500){
            //show popup
            const dialogRef = this.dialog.open(TooManyTracksPopupComponent, {
              width: TooManyTracksPopupComponent.widthForPopup,
              data: {playlist: null}
            });

            //cancel
            statusObservable.next(AsyncStatus.CANCELLED);
            setTimeout(() => {
              statusObservable.complete();
            }, 0);
          }else{


            //check for doubles
            let doubleAudioFiles = [];
            let checkedAudioFiles = [];

            audioFiles.forEach(
              (audioFileToAdd) => {
                if (this.appService.checkDoubles &&
                      ((this.musicPlayerService.currentActiveAudioFileWithPlayInfo != null && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile.id === audioFileToAdd.id)
                      ||  this.queueService.queue.some((audioFile) => audioFile.id === audioFileToAdd.id))){
                  doubleAudioFiles.push(audioFileToAdd);
                }else{
                  checkedAudioFiles.push(audioFileToAdd);
                }
              }
            )

            if (doubleAudioFiles.length > 0){
              //if there are doubles to check -> ask user, this can lead to a cancelled state
              let dialogRef = this.dialog.open(DoubleAudioFilesPopupComponent, {
                width: DoubleAudioFilesPopupComponent.widthForPopup,
                disableClose: true,
                data: {audioFilesToConfirm: doubleAudioFiles}
              });

              dialogRef.afterClosed().subscribe(result => {

                if (result.stopAsking){
                  this.appService.checkDoubles = false;
                }

                if (result.cancelled){
                  statusObservable.next(AsyncStatus.CANCELLED);
                  statusObservable.complete();
                }else{
                  let audioFilesToAddAfterCheck = checkedAudioFiles.concat(result.confirmedAudioFiles);
                  if (audioFilesToAddAfterCheck.length > 0){
                    this.performAddToQueue(audioFilesToAddAfterCheck, index, statusObservable);
                  }else{
                    statusObservable.next(AsyncStatus.CANCELLED);
                    statusObservable.complete();
                  }
                }

              });
            }else{
              this.performAddToQueue(audioFiles, index, statusObservable);
            }
          }
        }else{
            //empty list -> show invalid action
            statusObservable.next(AsyncStatus.INVALID_ACTION);
            setTimeout(() => {
              statusObservable.complete();
            }, 0);
          }
      }else{
          //no queue -> return invlid action
          statusObservable.next(AsyncStatus.INVALID_ACTION);
            setTimeout(() => {
              statusObservable.complete();
            }, 0);
      }


    }else{
      const snackBarRef = this.snackBar.open(this.translateService.instant('accessRight.snackbar.addToQueue'), null, {
        duration: SNACKBAR_DURATION,
        panelClass: ['tunify-snackbar']
      });

      statusObservable.next(AsyncStatus.CANCELLED);
      setTimeout(() => {
        statusObservable.complete();
      }, 0);
    }







    return statusObservable;
  }

    private performAddToQueue(audioFiles, index, statusObservable: BehaviorSubject<AsyncStatus>){
      this.queueService.addAudioFilesToQueue(audioFiles, index).subscribe(
        (asyncStatus) => {
          statusObservable.next(asyncStatus);
          if (asyncStatus == AsyncStatus.COMPLETED){
            this.bufferService.reportManualChange();
            //introduce 0 delay so we can subscribe to the observable before it completes
            setTimeout(() => {
              statusObservable.complete();
            }, 0);
          }
        }
      );
    }

    public removeAudioFileFromQueue(audioFile: AudioFile){
      this.queueService.removeAudioFileFromQueue(audioFile);
      this.bufferService.reportManualChange();
    }

    public removeAudioFileFromRadioQueue(audioFile:AudioFile){
      this.removeAudioFilesFromRadioQueue([audioFile]);
    }

    public removeAudioFilesFromRadioQueue(audioFiles:AudioFile[]){
      this.queueService.removeAudioFilesFromRadioQueue(audioFiles);
      this.bufferService.reportManualChange();
    }

    public refreshRadioQueue(){
      if (this.queueService.radioQueue != null){
        let audioFilesToRemove = [];
        let index = 0;
        while (index < 5 && index < this.queueService.radioQueue.length){
          audioFilesToRemove.push(this.queueService.radioQueue[index]);
          index++;
        }
        this.removeAudioFilesFromRadioQueue(audioFilesToRemove);
      }
    }

    public shuffleQueue(): Observable<AsyncStatus> {
      let resultObservable = this.queueService.shuffleQueue();
      this.bufferService.reportManualChange();
      return resultObservable;
    }

    public clearQueue(){
      this.queueService.clearQueue();
      this.bufferService.reportManualChange();
    }
}
