import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ZoneConnection } from '../../../model/zoneConnection';
import { BehaviorSubject, Subject } from 'rxjs';
import { ExternalApplicationInfo, ConnectionType } from '../../../services/data/vo/remote/remote-objects';
import { map, takeUntil } from 'rxjs/operators';
import { ApplicationMode, ZoneConnectionsService } from '../../../services/authentication/zone-connections.service';
import { ZoneConnectionService } from '../../../services/authentication/zone-connection.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface NoPlayerConnectPopupData {
  applicationMode: ApplicationMode;
  zoneConnection: ZoneConnection;
}


@Component({
  selector: 'tun-no-player-connect-popup',
  templateUrl: './no-player-connect-popup.component.html',
  styleUrls: ['./no-player-connect-popup.component.scss']
})
export class NoPlayerConnectPopupComponent implements OnInit, OnDestroy {

  public static widthForPopup = '35vw';

  public ApplicationMode = ApplicationMode;

  public applicationMode: ApplicationMode;
  public zoneConnection: ZoneConnection;


  private dummyApplicationInfoSubject = new BehaviorSubject<ExternalApplicationInfo>(null);

  //the current player that is remotely active
  public get zoneConnectionPlayerApplicationInfo$(){
    return this.zoneConnection != null ?
              this.zoneConnection.externalApplicationsInfo.externalApplicationsInfo$
                .pipe(
                  map(
                    (appInfos) => {
                      if (appInfos != null){
                        const playerAppInfos = appInfos.filter(appInfo => appInfo.connectionType == ConnectionType.player)
                        if (playerAppInfos.length > 0){
                          return playerAppInfos[0];
                        }
                      }
                      return null;
                    }
                  )
                )
              : this.dummyApplicationInfoSubject.asObservable();



  }

  //needed to know what info should be shown when using a dedicated player
  get enableConnect$(){
    return this.zoneConnection.externalApplicationsInfo.externalApplicationsInfo$
      .pipe(
        map(externalApplicationsInfos => externalApplicationsInfos != null ? externalApplicationsInfos.filter(externalApplicationsInfo => externalApplicationsInfo.connectionType == ConnectionType.player) : []),
        map(playerExternalApplicationInfos => playerExternalApplicationInfos.length > 0 && playerExternalApplicationInfos[0].connectEnabled)
      )
  }


  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    public dialogRef: MatDialogRef<NoPlayerConnectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NoPlayerConnectPopupData
  ) {
    this.applicationMode = data.applicationMode;
    this.zoneConnection = data.zoneConnection;
  }

  ngOnInit(): void {


    if (this.applicationMode == ApplicationMode.playerMode){
      //auto close when the connected player can be taken over (or not longer active)
      this.zoneConnectionPlayerApplicationInfo$
                .pipe(
                  takeUntil(this.destroyed$)
                )
                .subscribe(
                  appInfo => {
                    if (appInfo == null || !appInfo.dedicated){
                      this.onClose();
                    }
                  }
                );
    }else if (this.applicationMode == ApplicationMode.remoteMode){
      //auto close when the connected player supports Tunify Connect (or not longer active)
      this.zoneConnectionPlayerApplicationInfo$
                .pipe(
                  takeUntil(this.destroyed$)
                )
                .subscribe(
                  appInfo => {
                    if (appInfo == null || appInfo[0].connectEnabled){
                      this.onClose();
                    }
                  }
                );
    }

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onClose(){
    this.dialogRef.close();
  }

}
