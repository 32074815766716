import { CalendarItem } from "@model/calendarItem";
import {
  getStartHourFromCronString,
  getStartMinutesFromCronString,
  getDayFromCronString,
  getCronStringForDayAndTime
} from "./cronstringConverter";
import { MusicCollection } from "@model/musicCollection";
import { Day } from "@model/day";
import { MusicChannel } from "@model/musicChannel";
import { Playlist } from "@model/playlist";
import { Context } from "@model/context";
import { createClassObjectForChangeableParameter } from "./changeableParameterUtil";

export function parseAdditionalDataForCalendarItem(calendarItem: CalendarItem) {
  calendarItem.startHour = getStartHourFromCronString(calendarItem.cronString);
  calendarItem.startMinutes = getStartMinutesFromCronString(
    calendarItem.cronString
  );
  calendarItem.day = getDayFromCronString(calendarItem.cronString);
}

export function createSaveableCalendarItemObject(
  musicChannel: MusicChannel,
  musicCollection: MusicCollection,
  day: Day,
  startHour: number,
  startMinutes: number,
  position: number
): CalendarItem {
  let calendarItem = new CalendarItem();
  calendarItem.id = 0;
  calendarItem.title = musicCollection.title;
  calendarItem.cronString = getCronStringForDayAndTime(
    day,
    startHour,
    startMinutes
  );
  parseAdditionalDataForCalendarItem(calendarItem);
  calendarItem.duration = 30; //default 30 minutes
  calendarItem.position = position;

  if (musicChannel) {
    calendarItem.musicChannelId = musicChannel.id;
  } else {
    calendarItem.musicChannelId = 0;
  }

  if (musicCollection instanceof Playlist) {
    calendarItem.shufflePlaylist = true;
  } else if (musicCollection instanceof Context) {
    calendarItem.changeableParameter = [];
    if (musicCollection.changeableParameter) {
      musicCollection.changeableParameter.map(changeableParam =>
        calendarItem.changeableParameter.push(
          createClassObjectForChangeableParameter(changeableParam)
        )
      );
    }
    calendarItem.defaultChangeableParameter = [];
    if (musicCollection.defaultChangeableParameter) {
      musicCollection.defaultChangeableParameter.map(changeableParam =>
        calendarItem.defaultChangeableParameter.push(
          createClassObjectForChangeableParameter(changeableParam)
        )
      );
    }
  } else {
    console.log(
      "Error creating saveable calendarItem: musicCollection type not recognized for " +
        musicCollection
    );
  }

  return calendarItem;
}

export function calculateCalendarItemStartTime(
  calendarItem: CalendarItem
): number {
  return calendarItem.startHour + calendarItem.startMinutes / 60;
}
