<div class="wrapper">

  <button *ngIf="previousVersionAllowed" class="info-item link-button" (click)="onPreviousVersionClick()">
    <tun-reset-icon-v5 class="icon-link-button"></tun-reset-icon-v5> {{'menu.help.version.openPrevious' | translate}}
  </button>

  <button *ngIf="helpCenterAvailable$ | async" class="info-item link-button" (click)="onOpenHelpCenter()">
    <tun-help20px-icon-v5 class="icon-link-button"></tun-help20px-icon-v5> {{'menu.help.center' | translate}}
  </button>

  <div class="info-item">
    <tun-phone20px-icon-v5 class="icon-link-button"></tun-phone20px-icon-v5> {{phoneNumber$ | async}}
  </div>

  <div class="info-item">
    <tun-mail20px-icon-v5 class="icon-link-button"></tun-mail20px-icon-v5> {{email$ | async}}
  </div>

  <button class="info-item link-button" (click)="onOpenChat()">
    <tun-chat20px-icon-v5 class="icon-link-button"></tun-chat20px-icon-v5> {{'menu.help.chat' | translate}}
  </button>

</div>
