import { NgModule }     from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginGuardService } from './loginGuardService';
import { ZoneConnectionsComponent } from './zone-connections/zone-connections.component';
@NgModule({
    imports: [
        RouterModule.forChild([
            { path: 'login', component: ZoneConnectionsComponent, canActivate: [LoginGuardService]}
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class LoginRoutingModule { }
