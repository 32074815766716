import {
  ConnectedOverlayPositionChange,
  ConnectedPosition,
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayPositionBuilder,
} from '@angular/cdk/overlay';

import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { PopupArrowComponent } from '@components/popups/popup-arrow/popup-arrow.component';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import {
  PopupArrow,
  PopupConfig,
  PopupContent,
} from '@components/popups/popup/interfaces';
import { PopupComponent } from '@components/popups/popup/popup.component';
import {
  BOTTOM_CENTER,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  RIGHT_CENTER,
  RIGHT_TOP,
  TOP_CENTER,
  TOP_RIGHT,
} from '@components/popups/positions';
import { BehaviorSubject, EMPTY, Observable, of, race, Subject, Subscription } from 'rxjs';
import { delay, filter, map, take, tap, throttleTime, takeUntil } from 'rxjs/operators';
import { OverlayRef } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  // === Constants === //
  /** Used to attach instances of PopupComponent */
  private readonly COMPONENT_PORTAL = new ComponentPortal(PopupComponent);
  /** Used to attach instances of PopupArrowComponent */
  private readonly ARROW_COMPONENT_PORTAL = new ComponentPortal(
    PopupArrowComponent
  );
  private readonly VIEWPORT_MARGIN_PERCENTAGE = 0.005;

  private emptyPopupContentInstance = {
    connectedElementRef: null,
    elementRef: null,
  }

  // === Observables === //
  private _currentInstance$ = new BehaviorSubject<PopupContent>(this.emptyPopupContentInstance);
  /** Pass a PopupConfig to show a popup  */
  public showPopup$ = new Subject<PopupConfig>();
  /** Pass an ElementRef to hide a popup connected to said ElementRef */
  public hidePopup$ = new Subject<ElementRef>();
  /** When this emits all popups will be hidden */
  public hideAllPopups$ = new Subject();
  /** Emits the most recent popup */
  public currentInstance$ = this._currentInstance$.asObservable();

  constructor(
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _overlay: Overlay
  ) {
    this.showPopup$
      .pipe(map(config => this._getComponentInstance(config)))
      .subscribe(instance => this._currentInstance$.next(instance));
  }

  private _getComponentInstance({
    connector,
    componentType,
    showArrow,
    popupPosition,
    popupDirection,
    dynamicPosition,
    animationDuration,
    darkThemed
  }: PopupConfig): PopupContent {
    // Configure position- and scrollstrategy
    let offsetX = 0;
    let offsetY = 0;
    if (showArrow && !animationDuration) {
      if (popupDirection === PopupDirection.RIGHT) {
        offsetX =
          PopupArrowComponent.arrowSize - PopupArrowComponent.ARROW_OFFSET - 1;
      } else {
        offsetY =
          popupDirection === PopupDirection.UP
            ? (PopupArrowComponent.arrowSize -
                PopupArrowComponent.ARROW_OFFSET -
                1) *
              -1
            : PopupArrowComponent.arrowSize -
              PopupArrowComponent.ARROW_OFFSET -
              1;
      }
    }
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(connector)
      .withViewportMargin(Math.min(window.innerWidth * this.VIEWPORT_MARGIN_PERCENTAGE, 6))
      .withPositions(
        this._getConnectedPositions(popupPosition, offsetX, offsetY)
      );

    // Side effect: Create overlay reference and assign to local state
    const overlayRef = this._overlay.create({
      positionStrategy,
    });

    // Side effect: Attach portal to overlay ref (i.e. show the component)
    const popupComponent = overlayRef.attach(this.COMPONENT_PORTAL).instance;
    popupComponent.hasArrow = showArrow;
    popupComponent.popupDirection = popupDirection;

    const popupContent = popupComponent.createContentComponent(componentType);
    popupContent.connectedElementRef = connector;

    const popupShown$ = dynamicPosition
      ? (overlayRef.getConfig()
          .positionStrategy as FlexibleConnectedPositionStrategy).positionChanges.pipe(
          take(1)
        )
      : of(null);

    // listen to position changes to see which connection pair has been chosen
    popupShown$.subscribe(newPosition => {
      if (newPosition) {
        // determine popup direction based on chosen connection pair
        const newPopupDirection = this._getPopupDirectionFromPositionPair(
          newPosition.connectionPair
        );
        popupComponent.popupDirection = newPopupDirection;
      }

      const {
        overlayRef: popupArrowOverlayRef = null,
        popupArrowComponent = null,
      } = showArrow
        ? this._showArrow(
            connector,
            popupComponent.popupDirection,
            animationDuration,
            dynamicPosition
              ? (overlayRef.getConfig()
                  .positionStrategy as FlexibleConnectedPositionStrategy)
                  .positionChanges
              : EMPTY,
            darkThemed?darkThemed:false
          )
        : {};

      if (animationDuration) {
        // animate popup into view
        popupComponent.isOpen = true;
        popupComponent.animatePopup(animationDuration);

        // when hidepopup$ emits, animate hiding of popup from view
        race(
          this.hideAllPopups$,
          this.hidePopup$.pipe(
            filter(
              ({ nativeElement }) => nativeElement === connector.nativeElement
            )
          )
        )
          .pipe(
            take(1),
            tap(() => {
              popupComponent.isOpen = false;
              popupComponent.animatePopup(animationDuration);
              if (showArrow) {
                popupArrowComponent.isShown = false;
                popupArrowComponent.fadeArrowIn(animationDuration);
              }
            }),
            delay(animationDuration)
          )
          .subscribe(() => {
            this._hide(overlayRef, popupArrowOverlayRef);
            this._cleanupFromCurrent(connector);
          });
      } else {
        // when hidePopup$ emits, hide popup from view immediately
        race(
          this.hideAllPopups$,
          this.hidePopup$.pipe(
            filter(
              ({ nativeElement }) => nativeElement === connector.nativeElement
            )
          )
        )
          .pipe(take(1))
          .subscribe(() => {
            this._hide(overlayRef, popupArrowOverlayRef);
            this._cleanupFromCurrent(connector);
          });
      }
    });

    if (this.resizeSub){
      this.resizeSub.unsubscribe();
      this.resizeSub = null;
    }
    this.resizeSub = popupComponent.resize
    .subscribe(
      ()=>{
        overlayRef.updatePosition();
      }
    )

    return popupContent;
  }

  private resizeSub: Subscription;
  private _hide(
    overlayRef: OverlayRef,
    arrowOverlayRef: OverlayRef = null
  ) {

    if (this.resizeSub){
      this.resizeSub.unsubscribe();
      this.resizeSub = null;
    }

    overlayRef.dispose();
    overlayRef = null;
    if (arrowOverlayRef) {
      arrowOverlayRef.dispose();
    }
  }

  //once the popup is removed, clean it from the 'currentInstance' so it can be removed from memory
  private _cleanupFromCurrent(connector: ElementRef){
    if (this._currentInstance$.value != null && this._currentInstance$.value.connectedElementRef === connector){
      this._currentInstance$.next(this.emptyPopupContentInstance);
    }
  }

  /**
   * Show a popup arrow pointing to the connector
   * @param connector Arrow will be positioned based on the connector
   * @param direction Direction of the popup used to determine to pointing direction of the arrow
   * @param animationDuration Animation duration of the popup:
   *  Animated popups require the arrow to fade in so it doesn't interfere with the animating popup content
   */
  private _showArrow(
    connector: ElementRef,
    direction: PopupDirection,
    animationDuration: number,
    positionChanges: Observable<ConnectedOverlayPositionChange> = null,
    darkThemed: boolean = false
  ): PopupArrow {
    // Configure positionstrategy
    const popupArrowPositionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(connector)
      .withPositions(this._getArrowConnectedPositions(direction));

    // create overlay reference
    const arrowOverlayRef = this._overlay.create({
      positionStrategy: popupArrowPositionStrategy,
    });

    const arrowPopupComponent = arrowOverlayRef.attach(
      this.ARROW_COMPONENT_PORTAL
    ).instance;

    arrowPopupComponent.direction = this._getArrowDirectionForPopupDirection(
      direction
    );
    arrowPopupComponent.darkThemed = darkThemed;

    if (animationDuration) {
      arrowPopupComponent.isShown = true;
      arrowPopupComponent.fadeArrowIn(animationDuration);
    }

    // listen to position changes to reposition the arrow and change its direction if needed
    positionChanges
      .pipe(
        throttleTime(100),
        map(({ connectionPair }) =>
          this._getPopupDirectionFromPositionPair(connectionPair)
        ),
        tap(newDirection => {
          arrowPopupComponent.direction = this._getArrowDirectionForPopupDirection(
            newDirection
          );
          arrowPopupComponent.cdRef.markForCheck();
        }),
        map(newDirection =>
          this._overlayPositionBuilder
            .flexibleConnectedTo(connector)
            .withPositions(this._getArrowConnectedPositions(newDirection))
        )
      )
      .subscribe(newPositionStrategy => {
        arrowOverlayRef.updatePositionStrategy(newPositionStrategy);
      });

    return {
      overlayRef: arrowOverlayRef,
      popupArrowComponent: arrowPopupComponent,
    };
  }

  private _getPopupDirectionFromPositionPair(
    positionPair: ConnectionPositionPair
  ): PopupDirection {
    if (positionPair) {
      if (positionPair.originX === "end"){
        return PopupDirection.RIGHT
      }else if (positionPair.originY === 'top') {
        return PopupDirection.UP;
      }
      return PopupDirection.DOWN;
    }
  }

  private _getConnectedPositions(
    popupPosition: PopupPosition,
    offsetX: number = 0,
    offsetY: number = 0
  ): ConnectedPosition[] {
    switch (popupPosition) {
      case PopupPosition.TOP_CENTER:
        return [{ ...TOP_CENTER, offsetX, offsetY }];
      case PopupPosition.TOP_RIGHT:
        return [{ ...TOP_RIGHT, offsetX, offsetY }];
      case PopupPosition.BOTTOM_CENTER:
        return [{ ...BOTTOM_CENTER, offsetX, offsetY }];
      case PopupPosition.BOTTOM_RIGHT:
        return [{ ...BOTTOM_RIGHT, offsetX, offsetY }];
      case PopupPosition.BOTTOM_LEFT:
        return [{ ...BOTTOM_LEFT, offsetX, offsetY }];
      case PopupPosition.BOTTOM_CENTER_THEN_TOP:
        return [
          { ...BOTTOM_CENTER, offsetX, offsetY },
          { ...TOP_CENTER, offsetX, offsetY },
        ];
      case PopupPosition.RIGHT:
        return [{ ...RIGHT_TOP, offsetX, offsetY }];
      case PopupPosition.RIGHT_CENTER:
        return [{ ...RIGHT_CENTER, offsetX, offsetY }];
    }
  }

  private _getArrowConnectedPositions(popupDirection: PopupDirection) {
    switch (popupDirection) {
      case PopupDirection.UP:
        return [{ ...TOP_CENTER, offsetY: PopupArrowComponent.ARROW_OFFSET }];
      case PopupDirection.DOWN:
        return [
          { ...BOTTOM_CENTER, offsetY: PopupArrowComponent.ARROW_OFFSET * -1 },
        ];
      case PopupDirection.RIGHT:
        return [
          { ...RIGHT_CENTER, offsetX: PopupArrowComponent.ARROW_OFFSET * -1 },
        ];
    }
  }

  private _getArrowDirectionForPopupDirection(direction: PopupDirection) {
    switch (direction) {
      case PopupDirection.UP:
        return 'arrow-down';
      case PopupDirection.DOWN:
        return 'arrow-up';
      case PopupDirection.RIGHT:
        return 'arrow-left';
    }
  }
}
