<div class="wrapper">
  <div *ngIf="remoteRemoteActive$ | async" class="spacing-container">
    <button #remoteButton
      class="icon-button remote-active-button glow-animated {{showingPopup ? 'show-glow' : ''}}"
      tunPopupOnClick [closePopupAfterMouseLeave]="false"
      [closeOnPopupClick]="false"
      (togglepopup)="openRemoteInfo($event)"
    >
      <tun-remote-icon class="icon icon-scaled-svg-button remote-active-icon"></tun-remote-icon>
    </button>
  </div>

  <div *ngIf="isRemote$ | async" class="spacing-container">
    <button #remoteButton
      class="icon-button remote-button glow-animated {{showingPopup ? 'show-glow' : ''}}"
      tunPopupOnClick [closePopupAfterMouseLeave]="false"
      [closeOnPopupClick]="false"
      (togglepopup)="openRemoteInfo($event)"
    >
      <tun-remote-icon class="icon icon-scaled-svg-button remote-icon"></tun-remote-icon>
    </button>
  </div>
</div>
