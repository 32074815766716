import { animate, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { carouselAnimation, fadeAnimation, slideAnimation, viewPositionAnimation } from '@util/animations';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export enum SEARCH_VIEW {
  SEARCH_VIEW_MAIN = "searchViewMain",
  SEARCH_VIEW_MUSIC_CHANNEL = "searchViewMusicChannel",
  SEARCH_VIEW_RESULT_MUSIC_COLLECTION = "searchViewResultMusicCollection",
  SEARCH_VIEW_TEXT_RESULTS = "searchViewTextResults",
  SEARCH_VIEW_RESULTS = "searchViewResults",
}

export const SearchViewLevel = new Map<SEARCH_VIEW, number>([
  [SEARCH_VIEW.SEARCH_VIEW_MAIN, 0],
  [SEARCH_VIEW.SEARCH_VIEW_MUSIC_CHANNEL, 1],
  [SEARCH_VIEW.SEARCH_VIEW_RESULT_MUSIC_COLLECTION, 2],
  [SEARCH_VIEW.SEARCH_VIEW_TEXT_RESULTS, 1],
  [SEARCH_VIEW.SEARCH_VIEW_RESULTS, 3]
]);

@Component({
  selector: 'tun-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss'],
  animations: [
    carouselAnimation,
    viewPositionAnimation,
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, { params: { duration: 10 } })
      ])
    ]),
  ]
})
export class SearchPanelComponent implements OnInit, OnDestroy{

  public CAROUSEL_DURATION = 300;
  public animationDirection: 'next' | 'prev' = 'next';

  public get accessToSearch$(){
    return  this.subscriptionsService.accessRights$
      .pipe(
        map(
          accessRights => {
            return accessRights && accessRights.search
          }
        )
      )
  }

  public get mainViewPosition$(){
    return this.searchView$
    .pipe(
      map(
        (searchView => {
          if (searchView == SEARCH_VIEW.SEARCH_VIEW_MAIN){
            return 'mid';
          }
          return 'left';
        })
      )
    )
  }

  public SEARCH_VIEW = SEARCH_VIEW;
  private _searchView$ = new BehaviorSubject<SEARCH_VIEW>(SEARCH_VIEW.SEARCH_VIEW_MAIN);
  public set searchView(view: SEARCH_VIEW){
    if (this._searchView$.value != view){
      if (SearchViewLevel.get(this._searchView$.value) < SearchViewLevel.get(view)){
        this.animationDirection = 'next';
      }else{
        this.animationDirection = 'prev';
      }
      this.changeDetectorRef.detectChanges();
      this._searchView$.next(view)
    }
  }
  public get searchView$(){
    return this._searchView$;
  }


  public get showDetailsForSearchMusicChannel() {
    return this.appV5StateService.showDetailsForSearchMusicChannel;
  }

  public get showSearchResultView(){
    return this.appV5StateService.showDetailsForSearchMusicCollection
  }


  constructor(
    private appV5StateService: AppV5StateService,
    private changeDetectorRef: ChangeDetectorRef,
    private subscriptionsService: SubscriptionsService
  ) {

  }


  ngOnInit(): void {

    //this.changeDetectorRef.detach();

    combineLatest([
      this.appV5StateService.showSearchResults$,
      this.appV5StateService.showTextSearch$,
      this.appV5StateService.showDetailsForSearchMusicChannel$,
      this.appV5StateService.showDetailsForSearchMusicCollection$
    ])
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ([showSearchResult, showSearchOnText, showMusicChannel, showMusicCollection]) => {
        if (showSearchResult){
          this.searchView = SEARCH_VIEW.SEARCH_VIEW_RESULTS
        }else if (showSearchOnText){
          this.searchView = SEARCH_VIEW.SEARCH_VIEW_TEXT_RESULTS
        }else if (showMusicCollection!= null){
          this.searchView = SEARCH_VIEW.SEARCH_VIEW_RESULT_MUSIC_COLLECTION
        }else if (showMusicChannel != null){
          this.searchView = SEARCH_VIEW.SEARCH_VIEW_MUSIC_CHANNEL
        }else{
          this.searchView = SEARCH_VIEW.SEARCH_VIEW_MAIN
        }
      }
    )
    //this.changeDetectorRef.detectChanges();
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public textViewIsAnimating = false;
  public textViewAnimationStart(event: AnimationEvent){
    this.textViewIsAnimating = true;
  }
  public textViewAnimationDone(event: AnimationEvent){
    this.textViewIsAnimating = false;
  }

  onCloseSearchText(){
    this.appV5StateService.closeTextSearch();
  }

}
