import { Injectable } from '@angular/core';
import { UAParser } from '@applandstream/ua-parser-js';
import { LoggerService } from '@service/loggers/logger.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { checkIfCommServerRunning } from '@todesktop/client-comm-server';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { BehaviorSubject, combineLatest, filter, merge, Observable, Subject, takeUntil, timer } from 'rxjs';
import { ZoneConnection } from '@model/zoneConnection';
import { ZoneConnectionService } from '@service/authentication/zone-connection.service';
import { createBroadcastService } from '@todesktop/client-comm-server';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';


export enum DesktopDeviceType{
  MAC = "Mac",
  WINDOWS = "Windows",
  LINUX = "Linux",
  OTHER = "other"
}

interface DesktopApp_RequestData {
  zoneCode: string;
  externalZoneId: string;
  applicationFamilyId: number;
}

interface DesktopApp_ResponseData {
  success: boolean;
  message: string;
}


@Injectable({
  providedIn: 'root'
})
export class DesktopAppInstallerService {

  private ports = [22445, 39214];

  private broadcastService = createBroadcastService<DesktopApp_RequestData, DesktopApp_ResponseData>(this.ports);

  private LOGGER_CLASSNAME = 'DesktopAppInstallerService';

  public readonly desktopDeviceType: DesktopDeviceType;
  private arm64 = false;

  public isCommServerRunning(){
    return checkIfCommServerRunning(this.ports);
  }

  public bootService(){

  }

  constructor(
    private loggerService: LoggerService,
    private deviceDetectorService: DeviceDetectorService,
    private zoneConnectionService: ZoneConnectionService,
    private zoneConnectionsService: ZoneConnectionsService
  ) {

    var parser = new UAParser();
      var cpu = parser.getCPU().withClientHints()
      if (cpu instanceof Promise) {
        cpu.then((result) => {
          let arch = result.architecture;
          if (arch != null){
            if (arch.toLowerCase().includes("arm64")){
              this.arm64 = true;
            }
          }
          console.log('Resolved arch:', arch);
        });

      }

      this.desktopDeviceType = this.deviceDetectorOSStringToDesktopDeviceType(deviceDetectorService.os);


      if (isDesktopApp()){

       this.broadcastService.handleBroadcast((data: DesktopApp_RequestData) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleBroadcast', 'Received message: ' + data.zoneCode + ' ' + data.externalZoneId);

          let zoneConnection = this.zoneConnectionsService.findConnectionForExternalZoneId(data.externalZoneId, data.applicationFamilyId);

          if (zoneConnection != null && zoneConnection.valid){
            this.loggerService.warn(this.LOGGER_CLASSNAME, 'handleBroadcast', 'ZoneConnection already exists for externalZoneId: ' + data.externalZoneId + ' applicationFamilyId: ' + data.applicationFamilyId);
          }else{
            this.zoneConnectionsService.connectZoneForZoneCode(data.zoneCode, false, null);
          }

          return {success: true, message: "Received message"};
        });
      }else{
        /*
        checkIfCommServerRunning(this.ports).then((running) => {
          if (running){
            this.broadcastService.broadcast({ zoneCode: "123456", zoneId: 32 })
              .then(
                (res) => {
                this.loggerService.debug(this.LOGGER_CLASSNAME, 'shareToDesktopApp', 'ShareDesktopAppService broadcast response: ' + res);
                }, error => {
                  this.loggerService.error(this.LOGGER_CLASSNAME, 'shareToDesktopApp', 'ShareDesktopAppService broadcast error: ' + error);
                }
              );
            }
          });
          */
      }
  }

  private deviceDetectorOSStringToDesktopDeviceType(deviceOS: string){
    switch (deviceOS.toLowerCase()) {
      case 'mac':
        return DesktopDeviceType.MAC;
      case 'windows':
        return DesktopDeviceType.WINDOWS;
      case 'linux':
        return DesktopDeviceType.LINUX;
      default:
        return DesktopDeviceType.OTHER;
    }
  }

  public downloadDesktopApp(){
    if (this.desktopDeviceType == DesktopDeviceType.MAC){
      if (this.arm64){
        this.openSameWindow(environment.desktopAppMacarm64Location);
      }else{
        this.openSameWindow(environment.desktopAppMacx64Location);
      }
    }else if (this.desktopDeviceType == DesktopDeviceType.WINDOWS){
      this.openSameWindow(environment.desktopAppWindowsLocation);
    }else if (this.desktopDeviceType == DesktopDeviceType.LINUX){
      this.openSameWindow(environment.desktopAppLinuxLocation);
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "downloadDesktopApp", "Unsupported desktop device type: " + this.desktopDeviceType + ". deviceDetectorService.os: " + this.deviceDetectorService.os);
      this.openSameWindow(environment.desktopAppGeneralLocation);
    }
  }

  private openSameWindow(url: string){
    window.open(url, "_self");
  }

  /**
   * Share zoneConnection to desktop app
   */

  private set sharingZoneConnection(zoneConnection: ZoneConnection){
    this.sharingZoneConnectionSubject.next(zoneConnection);
  }
  private get sharingZoneConnection(){
    return this.sharingZoneConnectionSubject.value;
  }

  private sharingZoneConnectionSubject = new BehaviorSubject<ZoneConnection>(null);
  public sharingZoneConnection$ = this.sharingZoneConnectionSubject.asObservable();

  public startSharingZoneConnection(zoneConnection: ZoneConnection): Observable<boolean>{
    let resultObservable = new Subject<boolean>();
    if (this.sharingZoneConnection == null){

      this.sharingZoneConnection = zoneConnection;
      combineLatest([zoneConnection.valid$, zoneConnection.loadingZoneCodeError$, zoneConnection.zoneCode$])
      .pipe(
        takeUntil(this.sharingZoneConnection$.pipe(filter((zc) => zc != zoneConnection))),
      )
      .subscribe( ([valid, error, zoneCode]) => {
        if (zoneCode){
          this.shareToDesktopApp(zoneConnection, resultObservable);
        }
        if (!valid){
          timer(0).subscribe(() => {
            resultObservable.next(false);
            resultObservable.complete();
          });
          this.sharingZoneConnection = null;
        }
        if (error){
          timer(0).subscribe(() => {
            resultObservable.next(false);
            resultObservable.complete();
          });
          this.sharingZoneConnection = null;
        }
      });
      if (zoneConnection.zoneCode == null){
        this.zoneConnectionService.loadZoneCode(zoneConnection);
      }

    }else{
      timer(0).subscribe(() => {
        resultObservable.next(false);
        resultObservable.complete();
      });
    }

    return resultObservable;


  }

  private shareToDesktopApp(zoneConnection: ZoneConnection, resultObservable: Subject<boolean>): Observable<boolean>{
    if (isDesktopApp()){
      this.loggerService.error(this.LOGGER_CLASSNAME, 'shareToDesktopApp', 'Running in desktop app, not sending message');
      timer(0).subscribe(() => {
        resultObservable.next(false);
        resultObservable.complete();
      });
    }else{
      checkIfCommServerRunning(this.ports).then((running) => {

        if (running){

          this.broadcastService.broadcast({ zoneCode: zoneConnection.zoneCode, externalZoneId: zoneConnection.externalZoneId, applicationFamilyId: zoneConnection.appFamId })
            .then(
              (res) => {
                this.loggerService.debug(this.LOGGER_CLASSNAME, 'shareToDesktopApp', 'ShareDesktopAppService broadcast response: ' + res);

                if (res.success){
                  zoneConnection.sharedToDesktopApp = true;
                }

                this.sharingZoneConnection = null;

                resultObservable.next(res.success);
                resultObservable.complete();
              }, error => {
                this.sharingZoneConnection = null;
                this.loggerService.error(this.LOGGER_CLASSNAME, 'shareToDesktopApp', 'ShareDesktopAppService broadcast error: ' + error);
              }
          );

          }
        });
    }
    return resultObservable;
  }
}
