import {Injectable} from '@angular/core';
import {AudioFileWithPlayInfo} from './audioFileWithPlayInfo';
import {Subscription, BehaviorSubject, Observable, Subject} from 'rxjs';
import {AudioTagService} from "./audio.tag.service";
import { PlayState } from '@model/enums/playState.enum';
import { LoggerService } from '@service/loggers/logger.service';
import { Config } from '@service/config';
import { BufferService } from './buffer.service';
import { AudioFilePlayState } from '@service/vo/audioFilePlayState';
import { LogTrackActionService } from '@service/log-track-action.service';
import { DTO_TrackActivity_Action } from '@service/api/logging-api.service';
import { RealUserMonitorService } from '@service/real-user-monitor.service';
import { AppVersionService } from '@service/app-version.service';

/**
 * The musicPlayerService is responsible for audio playback, crossfading and keeps tracks of buffering/internet status
 */

@Injectable({
  providedIn: 'root'
})

export class MusicPlayerService {

  private LOGGER_CLASSNAME = 'MusicPlayerService';

  /**
   * This observable indicates if this service is waiting for an audioFile.
   * An external service (PlayStateService) will subscribe to this observable and feed a new audioFile if needed.
   */
  private _waitingForAudioFileSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public waitingForAudioFile$: Observable<boolean> = this._waitingForAudioFileSubject.asObservable();


  /**
   * A flag that indicates if the current track needs to start fading out as soon as it reaches its fade position
   *
   * This is set from the playStateService:
   * - if a next playable item is available, the current track should fade out to make a smooth transition.
   * - if no next playable item is available, the current track should play till the end
   *
   */
  public useFadeOutPosition = false;

  /**
   * The audioFile that has to be shown in the view.
   *
   * This is usually the same as the currentAudioFileWithPlayInfo. It can differ when a fade out is going on and the next audioFile is not yet started
   */
  private _currentActiveAudioFileWithPlayInfoSubject: BehaviorSubject<AudioFileWithPlayInfo> = new BehaviorSubject<AudioFileWithPlayInfo>(null);
  public currentActiveAudioFileWithPlayInfo$: Observable<AudioFileWithPlayInfo> = this._currentActiveAudioFileWithPlayInfoSubject.asObservable();
  private set _currentActiveAudioFileWithPlayInfo(value: AudioFileWithPlayInfo){
    this._currentActiveAudioFileWithPlayInfoSubject.next(value);
  }
  private get _currentActiveAudioFileWithPlayInfo():AudioFileWithPlayInfo{
    return this._currentActiveAudioFileWithPlayInfoSubject.getValue();
  }
  public get currentActiveAudioFileWithPlayInfo():AudioFileWithPlayInfo{
    return this._currentActiveAudioFileWithPlayInfo;
  }


  /**
   * The current audioFile that is played, all listeners (reachedFadeoutPosition, reachedEnd) are added to this audioFile.
   */
  private currentAudioFilePlayStateSubscription: Subscription = null;
  private _currentAudioFileWithPlayInfo = null;
  private set currentAudioFileWithPlayInfo (value: AudioFileWithPlayInfo) {
    if (this.currentAudioFilePlayStateSubscription != null) {
      this.currentAudioFilePlayStateSubscription.unsubscribe();
      this.currentAudioFilePlayStateSubscription = null;
    }

    if (this._currentAudioFileWithPlayInfo != null) {
      this._currentAudioFileWithPlayInfo.audioReachedFadeOutPosition.off(this.currentAudioFileReachedFadeOutPosition);
      this._currentAudioFileWithPlayInfo.audioReachedEnd.off(this.currentAudioFileReachedEnd);
      this._currentAudioFileWithPlayInfo.audioFileLoadingError.off(this.currentAudioFileLoadError);
    }

    //just before we change the current audiofile -> reset playstate
    this.playStateForLogging = PlayState.STOPPED;

    this._currentAudioFileWithPlayInfo = value;

    if (this._currentAudioFileWithPlayInfo != null) {

      this.currentAudioFilePlayStateSubscription = this._currentAudioFileWithPlayInfo.playerState$.subscribe(
        (playState) => {
          this.playStateForLogging = playState;
          this.adjustPlayStateToCurrentAudioFile();
          //as soon as the current player starts playing -> check if we need to fade to 1 volume
          if (playState == PlayState.PLAYING){
            this.startFadeInVolumeIfNeeded();
          }
        }
      );

      this._currentActiveAudioFileWithPlayInfo = this._currentAudioFileWithPlayInfo;

      this._currentAudioFileWithPlayInfo.audioReachedEnd.on(this.currentAudioFileReachedEnd);
      this._currentAudioFileWithPlayInfo.audioReachedFadeOutPosition.on(this.currentAudioFileReachedFadeOutPosition);
      this._currentAudioFileWithPlayInfo.audioFileLoadingError.on(this.currentAudioFileLoadError);
    }
  }
  private get currentAudioFileWithPlayInfo(): AudioFileWithPlayInfo {
    return this._currentAudioFileWithPlayInfo;
  }


  private previousPlaystateForLogging = PlayState.STOPPED;
  private set playStateForLogging(playState: PlayState){
    if (playState === PlayState.STARTING_TO_PLAY ){
      //ignore 'StartingToPlay' -> currentTime is not ready at this point
      return;
    }

    if (this.previousPlaystateForLogging !== playState){

      const playerId = 'Html ' + this.appVersionService.version;

      if (playState === PlayState.PLAYING){
        if (this.previousPlaystateForLogging === PlayState.PAUSED){
          this.logTrackActionService.logTrackAction(this.currentAudioFileWithPlayInfo.audioFile, DTO_TrackActivity_Action.RESUME, this.currentAudioFileWithPlayInfo.currentTime, playerId);
        }else{
          this.logTrackActionService.logTrackAction(this.currentAudioFileWithPlayInfo.audioFile, DTO_TrackActivity_Action.START, this.currentAudioFileWithPlayInfo.currentTime, playerId);
          if (this.currentActiveAudioFileWithPlayInfo && this.currentActiveAudioFileWithPlayInfo.audioFile) {
            this.realUserMonitorService.logEvent("playback", "start",
              {
                "track.id": ""+this.currentActiveAudioFileWithPlayInfo.audioFile.id,
                "track.title": ""+this.currentActiveAudioFileWithPlayInfo.audioFile.title,
                "track.group": ""+this.currentActiveAudioFileWithPlayInfo.audioFile.group
              }
            );
          }
        }
      }else if (playState === PlayState.PAUSED){
        this.logTrackActionService.logTrackAction(this.currentAudioFileWithPlayInfo.audioFile, DTO_TrackActivity_Action.PAUSE, this.currentAudioFileWithPlayInfo.currentTime, playerId);
      }else if (playState === PlayState.STOPPED){
        this.logTrackActionService.logTrackAction(this.currentAudioFileWithPlayInfo.audioFile, DTO_TrackActivity_Action.STOP, this.currentAudioFileWithPlayInfo.currentTime, playerId);
      }else{
        this.logger.error(this.LOGGER_CLASSNAME, "playStateForLogging", "state not recognized: " + playState);
      }
    }
    this.previousPlaystateForLogging = playState;
  }



  private adjustPlayStateToCurrentAudioFile() {
    // we only adjust the global playState when there actually is an item to play and it is somehow started
    if (this.currentAudioFileWithPlayInfo && this.currentAudioFileWithPlayInfo.playState !== PlayState.STOPPED) {
      this._playState = this.currentAudioFileWithPlayInfo.playState;
    }

  }

  private currentAudioFileReachedFadeOutPosition = () => {
    if (this.useFadeOutPosition){
      this.logger.debug(this.LOGGER_CLASSNAME, "currentAudioFileReachedFadeOutPosition", "going to fadeout");
      this.fadeOutCurrentAndAskForNext();
    }else{
      this.logger.debug(this.LOGGER_CLASSNAME, "currentAudioFileReachedFadeOutPosition", "no fadeout needed");
    }
  }

  private fadeOutCurrentAndAskForNext(){
    if (this.currentAudioFileWithPlayInfo){
      this.fadeOutCurrentPlayer();

      if (this.isFadingOut()){ //we only want to start the next audiofile once we have faded out a little bit
        this.startNextAudioFileDuringFadeOut = true;
      }else{
        if (!this._waitingForAudioFileSubject.value){
          this._waitingForAudioFileSubject.next(true);
        }
      }
    }else{
      this.logger.error(this.LOGGER_CLASSNAME, "fadeOutCurrentAndAskForNext", "no currentAudioFileWithPlayInfo -> listener not removed on time");
    }
  }

  private currentAudioFileReachedEnd = () => {
    if (this.currentAudioFileWithPlayInfo){

      if (this.currentActiveAudioFileWithPlayInfo.audioFile && !this.currentActiveAudioFileWithPlayInfo.audioFile.canCrossFade){
        this.logger.debug(this.LOGGER_CLASSNAME, "currentAudioFileReachedEnd", "audioFile with no crossFade reached end -> normal case" );
      }else{
        this.logger.error(this.LOGGER_CLASSNAME, "currentAudioFileReachedEnd", "we should not reach the end (no crossfade but the audioFile supports crossfades)");
      }

      this.bufferService.cleanupAudioFileWithPlayInfo(this.currentAudioFileWithPlayInfo);
      this.currentAudioFileWithPlayInfo = null;
      this._currentActiveAudioFileWithPlayInfo = null;

      if (!this._waitingForAudioFileSubject.value){
        this._waitingForAudioFileSubject.next(true);
      }

    }else{
      this.logger.error(this.LOGGER_CLASSNAME, "currentAudioFileReachedEnd", "no currentAudioFileWithPlayInfo -> listener not removed on time");
    }

  }

  private currentAudioFileLoadError = () => {
    this.logger.error(this.LOGGER_CLASSNAME, "currentAudioFileLoadError", "we are going to skip this audioFile");

    this.fadeOutCurrentAndAskForNext();
  }

  /**
   * Playstate of the music service
   * STOPPED - STARTING TO PLAY - PLAYING - PAUSED
   * @type {PlayState}
   * @private
   */
  private __playState: PlayState = null;
  private get _playState(): PlayState {
    return this.__playState;
  }
  private set _playState(value: PlayState) {
    if (this.__playState !== value) {
      this.__playState = value;
      this.logger.debug(this.LOGGER_CLASSNAME, "playStateChanged", 'musicPlayerService playState changed to ' + this.__playState);
      this._playStateSubject.next(this.__playState);
    }
  }
  public get playState(): PlayState {
    return this._playState;
  }
  private _playStateSubject: BehaviorSubject<PlayState> = new BehaviorSubject<PlayState>(null);
  public playerState$: Observable<PlayState> = this._playStateSubject.asObservable();


  constructor(private bufferService: BufferService,
              private audioTagService: AudioTagService,
              private logger: LoggerService,
              private logTrackActionService: LogTrackActionService,
              private appVersionService: AppVersionService,
              private realUserMonitorService: RealUserMonitorService) {

    this._playState = PlayState.STOPPED;

    /*
    this.currentAudioFileSubscription = this.playInfoService.currentAudioFile$.subscribe(
      (audioFile) =>{
        this.startAudioFile(audioFile);
      }
    );

    this.currentTimeSubscription = this.playInfoService.currentTime$.subscribe(
      (time) => {
        if (time != null && this.currentAudioFileWithPlayInfo != null){
          this.currentAudioFileWithPlayInfo.seekToTime(time);
        }
      }
    );

    this.currentStateSubscription = this.playInfoService.currentState$.subscribe(
      (playState) => {
        if (playState == PlayState.PLAYING) {
          this.tryToStartCurrentAudioFile();
        }else{
          if (this.currentAudioFileWithPlayInfo != null && (this.currentAudioFileWithPlayInfo.playState == PlayState.PLAYING || this.currentAudioFileWithPlayInfo.playState == PlayState.STARTING_TO_PLAY) ){
            this.currentAudioFileWithPlayInfo.pause();
          }
        }
      }
    )
    */
  }

  public generateUpToDateState(): AudioFilePlayState{
    let audioFilePlayState = new AudioFilePlayState();
    audioFilePlayState.playableAudio = this._currentAudioFileWithPlayInfo != null?this._currentAudioFileWithPlayInfo.audioFile:null;
    audioFilePlayState.position = this._currentAudioFileWithPlayInfo != null?this._currentAudioFileWithPlayInfo.currentTime:0;
    audioFilePlayState.playing = this._currentAudioFileWithPlayInfo != null?(this._currentAudioFileWithPlayInfo.playState === PlayState.STARTING_TO_PLAY || this._currentAudioFileWithPlayInfo.playState === PlayState.PLAYING):true;
    return audioFilePlayState;
  }

  public stopAll(){
    this.startNextAudioFileDuringFadeOut = false;
    this.endFadeOut();
    if (this.currentAudioFileWithPlayInfo){
      this.currentAudioFileWithPlayInfo.pause();
      this.bufferService.cleanupAudioFileWithPlayInfo(this.currentAudioFileWithPlayInfo);
      this.currentAudioFileWithPlayInfo = null;
      this._currentActiveAudioFileWithPlayInfo = null;
    }
  }


  public adjustToPlayState(audioFilePlayState: AudioFilePlayState){
    if (audioFilePlayState){

      //if needed, change the audioFile that is playing
      if (audioFilePlayState.playableAudio != null && (this.currentAudioFileWithPlayInfo == null || this.currentAudioFileWithPlayInfo.audioFile == null || this.currentAudioFileWithPlayInfo.audioFile != audioFilePlayState.playableAudio)){

        let startingVolume = 1;
        let autoStart = false;
        if (this._waitingForAudioFileSubject.value){
          this._waitingForAudioFileSubject.next(false);

          autoStart = true;

          //if it was an auto transition to a fadeable track -> slightly fade in
          if (audioFilePlayState.playableAudio.canCrossFade){
            startingVolume = 0.7;
          }

        }
        this.startNextAudioFileDuringFadeOut = false;

        const nextAudioFileWithPlayInfo = this.bufferService.retrievePlayableAudioWithPlayInfo(audioFilePlayState.playableAudio);

        //seek to the correct position before we start playing
        nextAudioFileWithPlayInfo.seekToTime(audioFilePlayState.position);

        //adjust starting volume if we need to
        if (startingVolume < 1 && nextAudioFileWithPlayInfo.audioTagWrapper){
          nextAudioFileWithPlayInfo.audioTagWrapper.adjustVolume(startingVolume);
        }


        if (audioFilePlayState.playing){
          nextAudioFileWithPlayInfo.play();
        }else {
          nextAudioFileWithPlayInfo.startPrepare();
        }
        this.fadeOutCurrentPlayer();
        this.currentAudioFileWithPlayInfo = nextAudioFileWithPlayInfo;
      }else{
        //the audio was already the current audioFile
        //seek to position
        this.currentAudioFileWithPlayInfo.seekToTime(audioFilePlayState.position);
        //adjust to playing
        if (audioFilePlayState.playing) {
          this.tryToStartCurrentAudioFile();
        }else{
          if (this.currentAudioFileWithPlayInfo != null && (this.currentAudioFileWithPlayInfo.playState === PlayState.PLAYING || this.currentAudioFileWithPlayInfo.playState === PlayState.STARTING_TO_PLAY) ){
            this.currentAudioFileWithPlayInfo.pause();
          }
        }
      }

    }
  }

  private tryToStartCurrentAudioFile(){
    if (this.currentAudioFileWithPlayInfo != null && this.currentAudioFileWithPlayInfo.playState != PlayState.PLAYING && this.currentAudioFileWithPlayInfo.playState != PlayState.STARTING_TO_PLAY){

      if (this.audioTagService.audioContext != null && this.audioTagService.audioContext.state == "suspended"){

          this.audioTagService.audioContext.resume().then(_ => {
            this.logger.debug( this.LOGGER_CLASSNAME, 'currentStateSubscription', 'audio context resumed => all ok');
            this.currentAudioFileWithPlayInfo.play();
          }, _ => {
            this.logger.error( this.LOGGER_CLASSNAME, 'currentStateSubscription', 'promise rejected');
            this._playState = PlayState.PAUSED;
            //this.playInfoService.pause();
          } ).catch(error => {
            this.logger.error( this.LOGGER_CLASSNAME, 'currentStateSubscription', 'error received: ' + error);
            this._playState = PlayState.PAUSED;
            //this.playInfoService.pause();
          });

      }else{
        this.currentAudioFileWithPlayInfo.play();
      }
    }
  }

  /**
   * FADEIN functions
   */

  private startFadeInVolumeIfNeeded(){
    if (this.currentAudioFileWithPlayInfo && this.currentAudioFileWithPlayInfo.audioTagWrapper && this.currentAudioFileWithPlayInfo.audioTagWrapper.volume < 1){
      this.logger.debug( this.LOGGER_CLASSNAME, 'startFadeInVolumeIfNeeded', this.currentAudioFileWithPlayInfo.audioFile.toString() + ': Going to start fadeIn. Volume is: ' + this.currentAudioFileWithPlayInfo.audioTagWrapper.volume);
      this.fadeInCallback();
    }else{
      this.logger.debug( this.LOGGER_CLASSNAME, 'startFadeInVolumeIfNeeded', this.currentAudioFileWithPlayInfo.audioFile.toString() + ': No need to fadeIn. Volume is: ' + this.currentAudioFileWithPlayInfo.audioTagWrapper.volume);
    }

  }

   private fadeInDelayedCallback;
   private fadeInCallback() {
    if (this.currentAudioFileWithPlayInfo && this.currentAudioFileWithPlayInfo.audioTagWrapper){
      if (this.currentAudioFileWithPlayInfo.audioTagWrapper.volume < 1){
        this.clearDelayedFadeInCallback();
        this.fadeInDelayedCallback = setTimeout(() => {
          this.fadeInCallback();
        }, 200);
      }else{
        this.logger.debug( this.LOGGER_CLASSNAME, 'fadeInCallback', this.currentAudioFileWithPlayInfo.audioFile.toString() + ': stopping to adjust fade in volume. Volume is: ' + this.currentAudioFileWithPlayInfo.audioTagWrapper.volume);
      }

      this.handleFadeInTimerTick();
    }
   }

   private clearDelayedFadeInCallback() {
     if (this.fadeInDelayedCallback) {
       clearTimeout(this.fadeInDelayedCallback);
       this.fadeInDelayedCallback = null;
     }
   }

   private handleFadeInTimerTick(){
    if (this.currentAudioFileWithPlayInfo && this.currentAudioFileWithPlayInfo.audioTagWrapper && this.currentAudioFileWithPlayInfo.audioTagWrapper.volume < 1){
      const increase = this.fadeOutAudioFileWithPlayInfo != null ? 0.02 : 0.04; //while we are fading out, take steps of 0.01
      const newVolume = Math.min(1.0, this.currentAudioFileWithPlayInfo.audioTagWrapper.volume + increase);
      this.currentAudioFileWithPlayInfo.audioTagWrapper.adjustVolume(newVolume);
      this.logger.info( this.LOGGER_CLASSNAME, 'handleFadeInTimerTick', 'adjusted volume to ' + newVolume);
    }
   }

  /**
   *
   * FADEOUT functions
   *
   */

  private fastFadeOutWhenOtherSongIsPlaying = true;
  private startNextAudioFileDuringFadeOut = false;
  private fadeOutAudioFileWithPlayInfo: AudioFileWithPlayInfo = null;

  private FADESTEPS = 36;

  private fadeOutSpeed = 1;
  private currentFadePercent = 0.0;
  private realFadeDuration = 0;
  private fadeInterval = 0;

  private fadeOutCurrentPlayer() {
    if (this.currentAudioFileWithPlayInfo) {
      this.endFadeOut();

      this.currentFadePercent = 0;
      this.fastFadeOutWhenOtherSongIsPlaying = true;
      this.fadeOutSpeed = 1;

      //if we need to fadeout a playableAudio that we should not crossFade -> fade shorter and faster
      if (!this.currentActiveAudioFileWithPlayInfo.audioFile.canCrossFade){
        this.logger.warn(this.LOGGER_CLASSNAME, "fadeOutCurrentPlayer", "Going to fadeout a playableAudio that does not support crossFades. Fading out faster and more before starting next track");
        this.fadeOutSpeed = 2;
      }

      this.fadeOutAudioFileWithPlayInfo = this.currentAudioFileWithPlayInfo;
      this.currentAudioFileWithPlayInfo = null;

      // only fadeout when the player is actually playing
      if (this.fadeOutAudioFileWithPlayInfo.playState === PlayState.PLAYING) {
        this.logger.debug(this.LOGGER_CLASSNAME, 'fadeOutCurrentPlayer', 'starting fadeout');

        this.realFadeDuration = Math.min((this.fadeOutAudioFileWithPlayInfo.endAudioSignal - this.fadeOutAudioFileWithPlayInfo.currentTime) * 1000, Config.MAX_FADEDURATION_MILLISECONDS);
        this.fadeInterval = Math.max(this.realFadeDuration / this.FADESTEPS, 20);

        this.logger.debug(this.LOGGER_CLASSNAME, 'fadeOutCurrentPlayer', 'starting fadeout of ' + this.realFadeDuration + ' milliseconds -> ' + this.fadeInterval + ' ms intervals');

        this.fadeOutCount = 0;
        this.fadeOutCallback();
      } else {
        this.logger.debug(this.LOGGER_CLASSNAME, 'fadeOutCurrentPlayer', 'fadeout not started because the item was not really playing. Going straight to endFadout..');
        this.endFadeOut();
      }
    }
  }

  private fadeOutDelayedCallback;
  private fadeOutCallback() {
    this.fadeOutDelayedCallback = setTimeout(() => {
      this.fadeOutCallback();
    }, this.fadeInterval);

    this.handleFadeOutTimerTick();
  }

  private clearDelayedCallback() {
    if (this.fadeOutDelayedCallback) {
      clearTimeout(this.fadeOutDelayedCallback);
      this.fadeOutDelayedCallback = null;
    }
  }

  private fastFadeOutStartedAtFadeCount = 0;
  private fadeOutCount = 0;
  private handleFadeOutTimerTick() {

    this.logger.info(this.LOGGER_CLASSNAME, 'handleFadeOutTimerTick', 'fading out step ' + this.fadeOutCount + '.');

    if (this.fastFadeOutWhenOtherSongIsPlaying && this.fadeOutAudioFileWithPlayInfo && this.currentAudioFileWithPlayInfo && this.currentAudioFileWithPlayInfo.playState === PlayState.PLAYING){

      //speed up on manual change or once under a certain theshold
      if (!this.fadeOutAudioFileWithPlayInfo.fadeOutPositionReached || this.currentFadePercent > 0.5) {

        this.fastFadeOutWhenOtherSongIsPlaying = false;
        this.fadeOutSpeed++;
        this.fastFadeOutStartedAtFadeCount = this.fadeOutCount;

        //go extra fast if we don't want to hear a crossfade
        if (!this.currentActiveAudioFileWithPlayInfo.audioFile.canCrossFade){
          this.fadeOutSpeed += 2;
        }

        this.logger.debug(this.LOGGER_CLASSNAME, 'handleFadeOutTimerTick', 'going to speed up fadeout at step ' + this.fastFadeOutStartedAtFadeCount + '. Speed is ' + this.fadeOutSpeed);
      }
    }

    this.fadeOutCount += this.fadeOutSpeed;

    // this.logger.info('handling fade step ' + this.fadeOutCount);

    this.setFadeVolume();
    if (this.fadeOutCount >= this.FADESTEPS ) {
      this.endFadeOut();
      this.logger.debug(this.LOGGER_CLASSNAME, 'handleFadeOutTimerTick', 'end of Fadeout');
    }

  }

  private setFadeVolume() {

    this.currentFadePercent = Math.max(Math.min(this.fadeOutCount / this.FADESTEPS, 1), 0);
    if (this.fadeOutAudioFileWithPlayInfo && this.fadeOutAudioFileWithPlayInfo.audioTagWrapper) {
      this.logger.info(this.LOGGER_CLASSNAME, 'setFadeVolume', 'about to set volume to ' + (1.0 - this.currentFadePercent));
      this.fadeOutAudioFileWithPlayInfo.audioTagWrapper.adjustVolume(1.0 - this.currentFadePercent);
    }

    this.checkStartNextAudioFileDuringFadeOut();
  }

  private endFadeOut() {
    this.logger.debug(this.LOGGER_CLASSNAME, 'endFadeOut',  'into endFadeOut');
    this.clearDelayedCallback();

    if (this.fadeOutAudioFileWithPlayInfo) {
      this.bufferService.cleanupAudioFileWithPlayInfo(this.fadeOutAudioFileWithPlayInfo);
      this.fadeOutAudioFileWithPlayInfo = null;
      if (this.currentAudioFileWithPlayInfo == null){
        this._currentActiveAudioFileWithPlayInfo = null;
      }
    }

    this.fastFadeOutWhenOtherSongIsPlaying = false;
    this.fadeOutSpeed = 1;
    this.checkStartNextAudioFileDuringFadeOut();
  }

  private checkStartNextAudioFileDuringFadeOut() {
    if (this.startNextAudioFileDuringFadeOut) {
      if (this.currentFadePercent > this.fadeOutAudioFileWithPlayInfo.fadePercentageBeforeNextOnAutoStart) {
        this.startNextAudioFileDuringFadeOut = false;
        if (!this._waitingForAudioFileSubject.value){
          this._waitingForAudioFileSubject.next(true);
        }
      }
    }
  }

  private isFadingOut(): boolean {
    return this.fadeOutAudioFileWithPlayInfo !== null;
  }


}
