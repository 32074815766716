import { Injectable, OnDestroy } from '@angular/core';
import { Calendar } from '@model/calendar';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { LoggerService } from '../loggers/logger.service';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { finalize, takeUntil, filter } from 'rxjs/operators';
import { CalendarItem } from '@model/calendarItem';
import { getCronStringForDayAndTime, getDayFromCronString, getStartHourFromCronString, getStartMinutesFromCronString } from './util/cronstringConverter';
import { AsyncStatus, AsyncStatusWithData } from './vo/asyncStatus';
import { parseAdditionalDataForCalendarItem } from './util/calendarItemUtil';
import { calculateMaximumDuration } from './util/calendarUtil';
import { CalendarApiService, createSaveableCalendar, createCreateableCalendar, CalendarItemManipulationInput, DTO_CalendarItemsAction } from '@service/api/calendar-api.service';
import { createClassObjectForChangeableParameter } from './util/changeableParameterUtil';
import { MusicCollectionService } from './music-collection.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '@components/popups/confirm-popup/confirm-popup.component';
import { Day } from '@model/day';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { VersionedResourceResponse } from '@service/api/dto/versionedResourceResponse';
import { SubscriptionsService } from './subscriptions.service';
import { ZoneConfigurationService } from './zone-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarService implements OnDestroy {


  constructor(
    private authenticationService: AuthenticationService,
    private zoneConfigurationService: ZoneConfigurationService,
    private subscriptionsService: SubscriptionsService,
    private loggerService: LoggerService,
    private calendarApiService: CalendarApiService,
    private musicCollectionService: MusicCollectionService,
    private dialog: MatDialog
  ) {
    this.authenticationService.loggedIn$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        value => {
          if (value) {
            //we can load the custom calendars when a zone is logged in
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged in ... starting to load custom calendars');
            this.loadCustomCalendarsIfNeeded();
          } else {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged out ... cleaning up playToken info');
            this.cleanUpData();
          }
        },
        err => {
          this.loggerService.error(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'error from loginInSubscription in CalendarService: ' + err);
        }
      );

    this.zoneConfigurationService.language$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        value => {
          if (value) {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'languageSubscription', 'language changed -> going to invalidate all calendarItems from all custom calendars');
            this.invalidateLoadedCalendarItems();
          } else {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'languageSubscription', 'no language was set, nothing to reload');
          }
        },
        err => {
          this.loggerService.error(this.LOGGER_CLASSNAME, 'languageSubscription', 'error from languageSubscription in CalendarService: ' + err);
        }
      );

    this.subscriptionsService.accessRights$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        value => {
          if (value) {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'accessRightsSubscription', 'language changed -> going to invalidate all calendarItems from all custom calendars');
            this.customCalendarsAvailable = value.customCalendars;
          } else {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'accessRightsSubscription', 'no language was set, nothing to reload');
          }
        },
        err => {
          this.loggerService.error(this.LOGGER_CLASSNAME, 'accessRightsSubscription', 'error from accessRightsSubscription in CalendarService: ' + err);
        }
      );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    this.cancelPreviousLoadCustomCalendarsRequest.next();
    this.cancelPreviousLoadCustomCalendarsRequest.complete();
    this.cancelPreviousLoadCustomCalendarsRequest = null;
  }

  private cleanUpData() {
    this.cancelPreviousLoadCustomCalendarsRequest.next(null);
    this.cancelPreviousLoadCalendarItemsRequest.next(0); //0 -> cancel all previous requests

    this._loading = false;
    this._loadingError = null;
    this._customCalendars = null;
  }

  public loadCustomCalendarsIfNeeded() {
    if (this.customCalendarsAvailable && this.authenticationService.loggedIn) {
      if (this.customCalendars == null && !this._loading) {
        this.loadCustomCalendars();
      }
    } else {
      this.cleanUpData();
    }
  }

  private invalidateLoadedCalendarItems() {
    this.cancelPreviousLoadCalendarItemsRequest.next(0); //0 -> cancel all previous load calencarItems requests
    if (this.customCalendars) {
      this.customCalendars.forEach((calendar) => {
        calendar.invalidateCalendarItems();
      });
    }

    //todo -> reload custom selected calendar

  }


  //this is set when access rights are loaded and they detect the zone has access to the custom calenders
  //we can't use a subscription because of circular dependencies
  public set customCalendarsAvailable(value: boolean) {
    if (this._customCalendarsAvailable !== value) {
      this._customCalendarsAvailable = value;
      this.loadCustomCalendarsIfNeeded();
    }
  }
  public get customCalendarsAvailable(): boolean {
    return this._customCalendarsAvailable;
  }

  /**
   * loading custom calendars
   */
  private get _loading(): boolean {
    return this._loadingSubject.value;
  }
  private set _loading(value: boolean) {
    if (this._loading !== value) {
      this._loadingSubject.next(value);
    }
  }
  get loading(): boolean {
    return this._loading;
  }

  /**
   * Error emitter for retrieving the custom Calendars
   */
  private get _loadingError(): string {
    return this._loadingErrorSubject.value;
  }
  private set _loadingError(value: string) {
    if (this._loadingError !== value) {
      this._loadingErrorSubject.next(value);
    }
  }
  get loadingError(): string {
    return this._loadingError;
  }

  /**
   * custom calendars
   */
  private get _customCalendars(): Calendar[] {
    return this._customCalendarsSubject.value;
  }
  private set _customCalendars(value: Calendar[]) {
    this._customCalendarsSubject.next(value);
  }
  get customCalendars(): Calendar[] {
    return this._customCalendars;
  }

  //helper sort function
  sortCalendars = (calendar1: Calendar, calendar2: Calendar) => {
    let name1 = calendar1.name ? calendar1.name.toLowerCase() : "";
    let name2 = calendar2.name ? calendar2.name.toLowerCase() : "";
    if (name1 < name2) { return -1; }
    if (name1 > name2) { return 1; }
    return 0;
  }

  private LOGGER_CLASSNAME = 'CalendarService';



  private loggedInSubscription: Subscription;
  private accessRightsSubscription: Subscription;

  private _customCalendarsAvailable: boolean = undefined;
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public loading$: Observable<boolean> = this._loadingSubject.asObservable();
  private _loadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<
    string
  >(null);
  public loadingError$: Observable<
    string
  > = this._loadingErrorSubject.asObservable();
  private _customCalendarsSubject: BehaviorSubject<
    Calendar[]
  > = new BehaviorSubject<Calendar[]>(null);
  public customCalendars$: Observable<
    Calendar[]
  > = this._customCalendarsSubject.asObservable();


  public findCalendarById(calendarId: number): Calendar {
    if (this.customCalendars) {
      return this.customCalendars.find(calendar => calendar.calendarId === calendarId);
    }
    return null;
  }


  //the subject to cancel any previous request
  private cancelPreviousLoadCustomCalendarsRequest = new Subject<void>();
  private loadCustomCalendars(): Observable<Calendar[]> {
    this.loggerService.debug(
      this.LOGGER_CLASSNAME,
      'loadCustomCalendars',
      'loading started'
    );


    this.cancelPreviousLoadCustomCalendarsRequest.next(null);
    this._loading = true;

    const calendarsObservable: Observable<
      Calendar[]
    > = this.calendarApiService.loadCustomCalendars();

    if (calendarsObservable) {
      calendarsObservable
        .pipe(
          finalize(() => {
            this._loading = false;
          }),
          takeUntil(
            this.cancelPreviousLoadCustomCalendarsRequest
          )
        )
        .subscribe(
          data => {
            // this.logger.debug(this.LOGGER_CLASSNAME, "loadMusicChannelGroups", "data : " + data);
            if (data instanceof Array) {
              // we should construct real objects with the correct type (so we can use instanceof to check the type)
              const realObjects = data.map(res => new Calendar(res)).sort(this.sortCalendars);

              this._customCalendars = realObjects;
            } else {
              this.loggerService.error(this.LOGGER_CLASSNAME, "loadCustomCalendars", "returned result is not an array -> going to set empty custom calendar list");
              this._customCalendars = [];
            }
          },
          error => {
            const errMsg = error.message
              ? error.message
              : error.status
                ? `${error.status} - ${error.statusText}`
                : 'Server error';
            console.error(errMsg);
            this._loadingError = 'GeneralError';
          }
        );
    }

    return this.customCalendars$;
  }

  public createCustomCalendar(calendar: Calendar): Observable<AsyncStatusWithData> {
    const notifier = new BehaviorSubject<AsyncStatusWithData>({ asyncStatus: AsyncStatus.RUNNING, data: null });

    let calendarCreate = createCreateableCalendar(calendar);
    let resultObservable = this.calendarApiService.createCalendar(calendarCreate);

    if (resultObservable) {

      calendar.creating = true;
      /*
      if (this.customCalendars){
        this._customCalendars.push(calendar);
      }
      */



      resultObservable
        .pipe(
          finalize(
            () => {
              calendar.creating = false;
              /*
              if (this.customCalendars){
                //remove the 'creating' state calendar
                let index = this.customCalendars.indexOf(calendar);
                this.customCalendars.splice(index, 1);
              }
              */

              notifier.complete();
            }
          ),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (data) => {
            //success -> use this calendar
            let realCalendar = new Calendar(data);
            this.parseAdditionalDataForAllCalendarItems(realCalendar);

            realCalendar.calendarItemsLoaded = true;
            if (realCalendar.calendarItems == null) {
              this.loggerService.error(this.LOGGER_CLASSNAME, "createCustomCalendar", "Created a custom calendar but the returned calendarItems is null -> going to initialize with an empty array");
              realCalendar.calendarItems = [];
            }

            this._customCalendars.push(realCalendar);
            this._customCalendars = this._customCalendars.sort(this.sortCalendars);

            notifier.next({ asyncStatus: AsyncStatus.COMPLETED, data: realCalendar });
          },
          (error) => {
            notifier.next({ asyncStatus: AsyncStatus.ERROR, data: null });
          }
        );
    } else {
      //we need a setTimeout of 0 so the creator can subscribe to the observable before it completes
      setTimeout(() => {
        notifier.next({ asyncStatus: AsyncStatus.RUNNING, data: null });
        notifier.complete();
      }, 0);
    }

    return notifier;
  }

  public copyCalendar(calendarToCopy: Calendar): Observable<AsyncStatusWithData> {

    const notifier = new BehaviorSubject<AsyncStatusWithData>({ asyncStatus: AsyncStatus.RUNNING, data: null });

    let resultObservable = this.calendarApiService.copyCalendar(calendarToCopy.calendarId);

    if (resultObservable) {

      calendarToCopy.creatingCopy = true;

      resultObservable
        .pipe(
          finalize(
            () => {
              calendarToCopy.creatingCopy = false;
              notifier.complete();
            }
          ),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (data) => {
            //success -> use this calendar
            let realCalendar = new Calendar(data);
            this.parseAdditionalDataForAllCalendarItems(realCalendar);

            realCalendar.calendarItemsLoaded = true;
            if (realCalendar.calendarItems == null) {
              this.loggerService.error(this.LOGGER_CLASSNAME, "copyCalendar", "Copied a calendar but the returned calendarItems is null -> going to initialize with an empty array");
              realCalendar.calendarItems = [];
            }

            this._customCalendars.push(realCalendar);
            this._customCalendars = this._customCalendars.sort(this.sortCalendars);

            notifier.next({ asyncStatus: AsyncStatus.COMPLETED, data: realCalendar });
          },
          (error) => {
            notifier.next({ asyncStatus: AsyncStatus.ERROR, data: null });
          }
        );
    } else {
      //we need a setTimeout of 0 so the creator can subscribe to the observable before it completes
      setTimeout(() => {
        notifier.next({ asyncStatus: AsyncStatus.INVALID_ACTION, data: null });
        notifier.complete();
      }, 0);
    }

    return notifier;
  }

  public saveCalendar(calendar: Calendar): Observable<AsyncStatus> {
    const notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    let calendar_Save = createSaveableCalendar(calendar);
    let resultObservable = this.calendarApiService.saveCalendar(calendar_Save);

    if (resultObservable) {

      calendar.saving = true;

      resultObservable
        .pipe(
          finalize(
            () => {
              calendar.saving = false;
              notifier.complete();
            }
          ),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (data) => {
            //success -> update timestamp
            calendar.timestamp = data.timestamp;

            if (data.clientUpdateAdvised) {
              //Reload loading the calendar
              this.loadCalendarItems(calendar);
            }

            //the name could be changed -> sort again
            this._customCalendars = this._customCalendars.sort(this.sortCalendars);

            notifier.next(AsyncStatus.COMPLETED);
          },
          (error) => {
            notifier.next(AsyncStatus.ERROR);
          }
        );
    } else {
      //we need a setTimeout of 0 so the creator can subscribe to the observable before it completes
      setTimeout(() => {
        notifier.next(AsyncStatus.INVALID_ACTION);
        notifier.complete();
      }, 0);
    }

    return notifier;
  }

  public deleteCalendar(calendar: Calendar): Observable<AsyncStatus> {

    let notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    calendar.deleting = true;

    //user must confirm with popup -> this can lead to a cancelled state
    let dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: ConfirmPopupComponent.widthForPopup,
      disableClose: true,
      data: { calendar: calendar }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirmed == true) {
        this.performDeleteCalendar(calendar, notifier);
      } else {
        calendar.deleting = false;
        setTimeout(() => {
          notifier.next(AsyncStatus.CANCELLED);
          notifier.complete();
        });
      }
    });

    return notifier;
  }

  private performDeleteCalendar(calendar: Calendar, notifier: BehaviorSubject<AsyncStatus>) {
    //delete playlist
    let deleteObservable = this.calendarApiService.deleteCalendar(calendar);

    if (deleteObservable) {
      deleteObservable.pipe(
        finalize(() => {
          calendar.deleting = false;
        })
      ).subscribe(
        data => {

          //update model (for detail view)
          calendar.deleted = true;

          //remove the playlist from the menu
          let index = this.customCalendars.indexOf(calendar);
          this.customCalendars.splice(index, 1);
          this._customCalendars = this.customCalendars;

          // this.logger.debug(this.LOGGER_CLASSNAME, "createPlaylist", "data : " + data);
          notifier.next(AsyncStatus.COMPLETED);
          notifier.complete();

        },
        error => {
          const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
          console.error(errMsg);


          notifier.next(AsyncStatus.ERROR);
          notifier.complete();
        }
      );
    } else {
      setTimeout(() => {
        notifier.next(AsyncStatus.INVALID_ACTION);
        notifier.complete();
      });
    }
  }

  public undoDelete(calendar: Calendar) {
    const notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    let resultObservable: Observable<VersionedResourceResponse> = null;

    if (calendar.deleted && !calendar.undoingDelete) {
      calendar.isVisible = true;

      let calendar_UndoDelete = createSaveableCalendar(calendar);
      resultObservable = this.calendarApiService.saveCalendar(calendar_UndoDelete);
    }

    if (resultObservable) {
      calendar.undoingDelete = true;

      resultObservable
        .pipe(
          finalize(
            () => {
              calendar.undoingDelete = false;
              notifier.complete();
            }
          ),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (data) => {
            //success -> update timestamp
            calendar.timestamp = data.timestamp;
            calendar.deleted = false;

            this.customCalendars.push(calendar);
            this._customCalendars = this._customCalendars.sort(this.sortCalendars);

            if (data.clientUpdateAdvised) {
              //Reload loading the calendar
              this.loadCalendarItems(calendar);
            }

            notifier.next(AsyncStatus.COMPLETED);
          },
          (error) => {
            notifier.next(AsyncStatus.ERROR);
          }
        );
    } else {
      //we need a setTimeout of 0 so the creator can subscribe to the observable before it completes
      setTimeout(() => {
        notifier.next(AsyncStatus.INVALID_ACTION);
        notifier.complete();
      }, 0);
    }

    return notifier;
  }



  //the subject to cancel the previous requests
  //id 0 -> cancel all previous requests
  //id specific value -> cancel the request for this calendar
  private cancelPreviousLoadCalendarItemsRequest = new Subject<number>();
  public loadCalendarItems(calendar: Calendar) {
    this.loggerService.debug(
      this.LOGGER_CLASSNAME,
      'loadCalendarDetails',
      'loading calendar details started for ' +
      calendar.name +
      '(' +
      calendar.calendarId +
      ')'
    );

    if (!calendar.calendarItemsLoading) {
      calendar.calendarItemsLoading = true;
      calendar.calendarItemsLoadingError = null;
      calendar.calendarItemsLoaded = false;

      // erase old data?

      const calendarObservable: Observable<Object> = this.calendarApiService.loadCalendarItems(calendar);

      calendarObservable
        .pipe(
          finalize(() => {
            calendar.calendarItemsLoading = false;
          }),
          takeUntil(
            this.cancelPreviousLoadCalendarItemsRequest
              .pipe(
                filter(val => (val == 0 || val == calendar.calendarId)) //listen to cancel all and cancel for this id
              )
          )
        )
        .subscribe(
          data => {

            this.loggerService.debug(
              this.LOGGER_CLASSNAME,
              'loadCalendarItems',
              'data : ' + data
            );

            let dataWithCalendar;

            if (data instanceof Array && data.length > 0) {
              dataWithCalendar = data[0];
            } else if (data) {
              dataWithCalendar = data;
            }

            // we should construct real objects with the correct type (so we can use instanceof to check the type)
            const realObject = new Calendar(dataWithCalendar);


            let useResponse = true;

            if (useResponse){
              this.parseAdditionalDataForAllCalendarItems(realObject);
              if (realObject && realObject.calendarItems) {
                calendar.calendarItems = realObject.calendarItems;
                calendar.calendarItemsLoaded = true;
                calendar.name = realObject.name;
                calendar.timestamp = realObject.timestamp;

                calendar.emitCalendarItemsLoadingDone(true);
              } else {
                calendar.calendarItemsLoadingError = 'Parsing Error';
                calendar.emitCalendarItemsLoadingDone(false);
              }
            }else{
              calendar.calendarItemsLoadingError = 'Not using response';
              calendar.emitCalendarItemsLoadingDone(false);
            }
          },
          (error: unknown) => {
            let errMsg = 'Server error: ' + error;
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
                  error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            }

            calendar.calendarItemsLoadingError = 'GeneralError';
            calendar.emitCalendarItemsLoadingDone(false);
            this.loggerService.error(this.LOGGER_CLASSNAME, "loadMusicSelection", "Error loading: " + errMsg);
          }
        );
    }
  }

  //helper method to parse all extra data from a freshly retrieved calendar
  private parseAdditionalDataForAllCalendarItems(calendar: Calendar) {
    if (calendar && calendar.calendarItems) {
      calendar.calendarItems.forEach(calendarItem => {
        parseAdditionalDataForCalendarItem(calendarItem);
        calendarItem.calendarId = calendar.calendarId;
        calendarItem.storedCronString = calendarItem.cronString;
        calendarItem.storedDuration = calendarItem.duration;
        calendarItem.storedPosition = calendarItem.position;
      });
    }
  }

  //the subject to cancel the previous requests
  //id 0 -> cancel all previous requests
  //id specific value -> cancel the request for this calendarItem
  private cancelPreviousLoadCalendarItemChangeableParametersRequest = new Subject<number>();
  public loadCalendarItemDetails(calendarItem: CalendarItem){
    this.loggerService.debug(
      this.LOGGER_CLASSNAME,
      'loadCalendarItemDetails',
      'loading calendarItem details started for ' +
      calendarItem.id +
      '(calendar id ' +
      calendarItem.calendarId +
      ')'
    );

    if (!calendarItem.changeableParametersLoaded && !calendarItem.changeableParametersLoading) {
      calendarItem.changeableParametersLoading = true;
      calendarItem.changeableParametersLoadingError = false;

      const calendarItemObservable = this.calendarApiService.loadCalendarItem(calendarItem.calendarId, calendarItem.id);

      calendarItemObservable
        .pipe(
          finalize(() => {
            calendarItem.changeableParametersLoading = false;
          }),
          takeUntil(
            this.cancelPreviousLoadCalendarItemChangeableParametersRequest
              .pipe(
                filter(val => (val == 0 || val == calendarItem.id)) //listen to cancel all and cancel for this id
              )
          )
        )
        .subscribe(
          data => {

            this.loggerService.debug(
              this.LOGGER_CLASSNAME,
              'loadCalendarItemDetails',
              'data : ' + data
            );

            // we should construct real objects with the correct type (so we can use instanceof to check the type)
            const realObject = new CalendarItem(data);

            const useResponse = true;

            if (useResponse){
              calendarItem.changeableParameter = realObject.changeableParameter
              calendarItem.defaultChangeableParameter = realObject.defaultChangeableParameter
              calendarItem.changeableParametersLoaded = true
            }else{
              calendarItem.changeableParametersLoadingError = true;
            }
          },
          (error: unknown) => {
            let errMsg = 'Server error: ' + error;
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
                  error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            }

            calendarItem.changeableParametersLoadingError = true;
            this.loggerService.error(this.LOGGER_CLASSNAME, "loadCalendarItemDetails", "Error loading: " + errMsg);
          }
        );
    }
  }


  public createCalendarItem(
    calendar: Calendar,
    musicChannel: MusicChannel,
    musicCollection: MusicCollection,
    day: Day,
    hours: number,
    minutes: number,
    duration: number,
    position: number
  ): Observable<AsyncStatus> {

    //create the calendarItem
    let calendarItem = new CalendarItem();
    calendarItem.calendarId = calendar.calendarId;

    calendarItem.musicChannelId = musicChannel ? musicChannel.id : 0;
    calendarItem.musicCollectionId = musicCollection.id;
    calendarItem.title = musicCollection.title;

    //changeable parameters are initialised server side
    if (musicCollection instanceof Playlist) {
      calendarItem.shufflePlaylist = true;
    }

    calendarItem.duration = duration;
    calendarItem.cronString = getCronStringForDayAndTime(
      day,
      hours,
      minutes
    );

    parseAdditionalDataForCalendarItem(calendarItem);
    calendarItem.position = position;

    calendarItem.creating = true;

    //post manipulation to server
    let manipulation = new CalendarItemManipulationInput();
    manipulation.action = DTO_CalendarItemsAction.Add;
    manipulation.calendarItem = calendarItem;

    return this.performManipulations(calendar, [manipulation]);
  }

  //called when an item is dragged to a new position
  public moveCalenderItem(
    calendar: Calendar,
    calendarItemToMove: CalendarItem,
    newStartHour: number,
    newStartMinutes: number,
    newPosition: number
  ) {
    // todo -> check if the startHour + startMinutes are free
    //this is done in the UI

    // adjust the calendarItem
    calendarItemToMove.cronString = getCronStringForDayAndTime(
      calendarItemToMove.day,
      newStartHour,
      newStartMinutes
    );
    parseAdditionalDataForCalendarItem(calendarItemToMove); // adjust startHour / startMinutes in our local model
    calendarItemToMove.position = newPosition;

    // check overlap for duration
    calendarItemToMove.duration = Math.min(
      calculateMaximumDuration(calendar, calendarItemToMove),
      calendarItemToMove.duration
    );

    // trigger changes by creating a new copy of the array of calendarItems -> should be done in a different way?
    //calendar.calendarItems = Object.assign([], calendar.calendarItems);

    this.saveCalendarItem(calendar, calendarItemToMove);
  }

  //called when an item is made smaller/larger -> the UI will adjust the variables day / startHour / startMinutes / position / duration
  //called when the musicChannel / musicCollection has changed
  public adjustCalendarItem(calendar: Calendar, calendarItem: CalendarItem): Observable<AsyncStatus> {

    let statusObservable = new BehaviorSubject<AsyncStatus>(
      AsyncStatus.RUNNING
    );

    //first check if it is actually changed
    if (
      calendarItem.day != getDayFromCronString(calendarItem.storedCronString) ||
      calendarItem.startHour != getStartHourFromCronString(calendarItem.storedCronString) ||
      calendarItem.startMinutes != getStartMinutesFromCronString(calendarItem.storedCronString) ||
      calendarItem.position != calendarItem.storedPosition ||
      calendarItem.duration != calendarItem.storedDuration
    ) {
      // adjust the cronstring to reflect the changes
      calendarItem.cronString = getCronStringForDayAndTime(
        calendarItem.day,
        calendarItem.startHour,
        calendarItem.startMinutes
      );

      return this.saveCalendarItem(calendar, calendarItem, statusObservable);
    } else {


      //nothing changed, complete directly
      setTimeout(() => {
        statusObservable.next(AsyncStatus.COMPLETED);
        statusObservable.complete();
      }, 0);

      return statusObservable;
    }
  }

public adjustMusicCollectionForCalendarItem(calendar: Calendar, calendarItem: CalendarItem, musicChannel: MusicChannel, musicCollection: MusicCollection): Observable<AsyncStatus>{
  let statusObservable = new BehaviorSubject<AsyncStatus>(
    AsyncStatus.RUNNING
  );

  calendarItem.musicChannelId = musicChannel != null ? musicChannel.id : 0;
  calendarItem.musicCollectionId = musicCollection.id;
  calendarItem.title = musicCollection.title;

  if (musicCollection instanceof Playlist) {
    calendarItem.shufflePlaylist = true;
  }

  return this.saveCalendarItem(calendar, calendarItem, statusObservable);
}

  public resetParameters(calendar: Calendar, calendarItem: CalendarItem): Observable<AsyncStatus> {
    const statusSubject = new BehaviorSubject<AsyncStatus>(
      AsyncStatus.RUNNING
    );

    //first check if the parameters are loaded
    if (calendarItem.changeableParameter != null && calendarItem.defaultChangeableParameter != null) {
      calendarItem.changeableParameter = [];
      calendarItem.defaultChangeableParameter.map(changeableParam => calendarItem.changeableParameter.push(createClassObjectForChangeableParameter(changeableParam)));
      let statusObservable = this.saveCalendarItem(calendar, calendarItem, statusSubject);

      //watch the observable and call tweak srength from here
      statusObservable
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (asyncStatus) => {
            if (asyncStatus === AsyncStatus.COMPLETED) {
              this.musicCollectionService.loadTweakInfo(calendarItem);
            }
          }
        );

      return statusObservable;


    } else {
      //nothing changed, complete directly
      setTimeout(() => {
        this.loggerService.error(this.LOGGER_CLASSNAME, "resetParameters", "trying to reset parameters but the calendarItem has no parameters");
        statusSubject.next(AsyncStatus.INVALID_ACTION);
        statusSubject.complete();
      }, 0);
    }

    return statusSubject;
  }

  public adjustParametersForCalendarItem(calendar: Calendar, calendarItem: CalendarItem) {
    let statusObservable = this.saveCalendarItem(calendar, calendarItem);

    //watch the observable and call tweak srength from here
    statusObservable
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (asyncStatus) => {
          if (asyncStatus === AsyncStatus.COMPLETED) {
            this.musicCollectionService.loadTweakInfo(calendarItem);
          }
        }
      );

    return statusObservable;
  }


  public saveCalendarItem(calendar: Calendar, calendarItem: CalendarItem, notifier: BehaviorSubject<AsyncStatus> = null): Observable<AsyncStatus> {
    let manipulation = new CalendarItemManipulationInput();
    manipulation.action = DTO_CalendarItemsAction.Update;
    manipulation.calendarItem = calendarItem;

    return this.performManipulations(calendar, [manipulation]);

  }

  public removeCalendarItemFromCalendar(calendar: Calendar, calendarItem: CalendarItem): Observable<AsyncStatus> {
    let manipulation = new CalendarItemManipulationInput();
    manipulation.action = DTO_CalendarItemsAction.Delete;
    manipulation.calendarItem = calendarItem;

    return this.performManipulations(calendar, [manipulation]);
  }


  public clearDay(calendar: Calendar, day: Day): Observable<AsyncStatus> {

    const statusSubject = new BehaviorSubject<AsyncStatus>(
      AsyncStatus.RUNNING
    );

    if (calendar.clearingDays && calendar.clearingDays.indexOf(day) < 0) {
      calendar.clearingDays.push(day);

      let manipulations: CalendarItemManipulationInput[] = [];
      if (calendar.calendarItemsLoaded) {
        calendar.calendarItems
          .filter(calendarItem => calendarItem.day === day)
          .forEach(
            calendarItem => {
              let manipulation = new CalendarItemManipulationInput();
              manipulation.action = DTO_CalendarItemsAction.Delete;
              manipulation.calendarItem = calendarItem;
              manipulations.push(manipulation);
            }
          )
      }

      statusSubject.subscribe((asyncStatus) => {
        if (asyncStatus != AsyncStatus.RUNNING) {
          let index = calendar.clearingDays.indexOf(day);
          calendar.clearingDays.splice(index, 1);
        }
      });

      return this.performManipulations(calendar, manipulations, statusSubject);
    } else {
      //already clearing this day, complete directly
      setTimeout(() => {
        statusSubject.next(AsyncStatus.INVALID_ACTION);
        statusSubject.complete();
      }, 0);
    }

    return statusSubject;
  }

  public copyDay(calendar: Calendar, sourceDay: Day, targetDays: Day[]): Observable<AsyncStatus> {

    const statusSubject = new BehaviorSubject<AsyncStatus>(
      AsyncStatus.RUNNING
    );

    let indexOfTargetDay = targetDays.indexOf(sourceDay);
    if (indexOfTargetDay >= 0) {
      //take a copy of the array of days to be sure we are not going to manipulate our 'ALL DAYS' array
      targetDays = Object.assign([], targetDays);
      targetDays.splice(indexOfTargetDay, 1);
    }

    if (targetDays.length > 0 && calendar.calendarItemsLoaded) {

      calendar.copyingDay = sourceDay;

      let manipulations: CalendarItemManipulationInput[] = [];

      //clean up all targetDays
      calendar.calendarItems
        .filter(calendarItem => targetDays.indexOf(calendarItem.day) >= 0)
        .forEach(
          calendarItem => {
            let manipulation = new CalendarItemManipulationInput();
            manipulation.action = DTO_CalendarItemsAction.Delete;
            manipulation.calendarItem = calendarItem;
            manipulations.push(manipulation);
          }
        )

      //copy all items of sourceDay into each day of targetDays
      calendar.calendarItems
        .filter(calendarItem => calendarItem.day == sourceDay)
        .forEach(
          calendarItem => {
            targetDays.forEach(targetDay => {
              let manipulation = new CalendarItemManipulationInput();
              manipulation.action = DTO_CalendarItemsAction.Add;
              manipulation.calendarItem = new CalendarItem(calendarItem);
              manipulation.calendarItem.id = 0;
              manipulation.calendarItem.day = targetDay;
              manipulation.calendarItem.cronString = getCronStringForDayAndTime(targetDay, manipulation.calendarItem.startHour, manipulation.calendarItem.startMinutes);
              manipulations.push(manipulation);
            });

          }
        )

      statusSubject.subscribe((asyncStatus) => {
        if (asyncStatus != AsyncStatus.RUNNING) {
          calendar.copyingDay = null;
        }
      });

      return this.performManipulations(calendar, manipulations, statusSubject);
    } else {
      //nothing changed, complete directly
      setTimeout(() => {
        statusSubject.next(AsyncStatus.INVALID_ACTION);
        statusSubject.complete();
      }, 0);
    }
  }


  private performManipulations(calendar: Calendar, manipulations: CalendarItemManipulationInput[], notifier?: BehaviorSubject<AsyncStatus>): Observable<AsyncStatus> {

    if (notifier == null) {
      notifier = new BehaviorSubject<AsyncStatus>(
        AsyncStatus.RUNNING
      );
    }

    let resultObservable = this.calendarApiService.manipulateCalendarItems(calendar, manipulations);

    if (resultObservable) {

      manipulations.forEach(manipulation => {
        this.adjustPerformingActionField(manipulation, true);

        //add the calendarItem right away in a 'creating' state
        if (manipulation.action == DTO_CalendarItemsAction.Add && calendar.calendarItemsLoaded) {
          calendar.calendarItems.push(manipulation.calendarItem);
        }

      });

      resultObservable
        .pipe(
          finalize(
            () => {
              manipulations.forEach(manipulation => {
                this.adjustPerformingActionField(manipulation, false);
              });
              notifier.complete();
            }
          ),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (data) => {
            if (data) {
              if (data.clientUpdateAdvised) {
                this.loggerService.debug(this.LOGGER_CLASSNAME, "performManipulations response", "clientUpdateAdvised: a newer calendar version is available server side -> going to reload");
                this.loadCalendarItems(calendar);
              } else {
                calendar.timestamp = data.timestamp;

                if (calendar.calendarItemsLoaded) {
                  //from our inputs
                  manipulations.forEach(manipulation => {
                    if (manipulation.action == DTO_CalendarItemsAction.Add || manipulation.action == DTO_CalendarItemsAction.Delete) {
                      //remove the 'creating' and 'removing' calendarItem
                      const index = calendar.calendarItems.indexOf(manipulation.calendarItem);
                      calendar.calendarItems.splice(index, 1);
                    } else if (manipulation.action == DTO_CalendarItemsAction.Update) {
                      //just keep the calendarItem, but updated the 'stored' values
                      manipulation.calendarItem.storedCronString = manipulation.calendarItem.cronString;
                      manipulation.calendarItem.storedDuration = manipulation.calendarItem.duration;
                      manipulation.calendarItem.storedPosition = manipulation.calendarItem.position;
                    }
                  });

                  //from our response -> add the created items from response.
                  //these objects have an id and changeable parameters if applicable
                  if (data.addedCalendarItems) {
                    data.addedCalendarItems.forEach(calendarItem => {
                      //create a real object (so instanceof is available)
                      let realCalendarItem = new CalendarItem(calendarItem);

                      parseAdditionalDataForCalendarItem(realCalendarItem);
                      realCalendarItem.calendarId = calendar.calendarId;
                      realCalendarItem.storedCronString = calendarItem.cronString;
                      realCalendarItem.storedDuration = calendarItem.duration;
                      realCalendarItem.storedPosition = calendarItem.position;

                      calendar.calendarItems.push(realCalendarItem);
                    });
                  }

                }
              }
            } else {
              //no data received?
              this.loggerService.error(this.LOGGER_CLASSNAME, "performManipulations response", "no data received.. going to reload");
              this.loadCalendarItems(calendar);
            }

            notifier.next(AsyncStatus.COMPLETED);
          },
          (error) => {
            if (calendar.calendarItemsLoaded) {
              manipulations.forEach(manipulation => {
                //remove the 'creating' calendarItems
                if (manipulation.action == DTO_CalendarItemsAction.Add) {
                  const index = calendar.calendarItems.indexOf(manipulation.calendarItem);
                  calendar.calendarItems.splice(index, 1);
                }
              });
            }
            notifier.next(AsyncStatus.ERROR);
          }
        );
    } else {
      //we need a setTimeout of 0 so the creator can subscribe to the observable before it completes
      setTimeout(() => {
        notifier.next(AsyncStatus.INVALID_ACTION);
        notifier.complete();
      }, 0);
    }

    return notifier;
  }

  //helper method to update the correct field
  private adjustPerformingActionField(manipulation: CalendarItemManipulationInput, value: boolean) {
    if (manipulation.action == DTO_CalendarItemsAction.Add) {
      manipulation.calendarItem.creating = value;
    } else if (manipulation.action == DTO_CalendarItemsAction.Update) {
      manipulation.calendarItem.saving = value;
    } else if (manipulation.action == DTO_CalendarItemsAction.Delete) {
      manipulation.calendarItem.removing = value;
    }
  }


  //VERSIONING

  public handleCalendarUpdateInfo(calendarId: number, timestamp: number){
    if (timestamp != undefined){

      if (this.customCalendars){
        let found = false;

        this.customCalendars.forEach(calendar => {
          if (calendar.calendarId == calendarId){
            found = true;
            if (calendar.timestampFromUpdateTrigger == undefined || calendar.timestampFromUpdateTrigger < timestamp){
              calendar.timestampFromUpdateTrigger = timestamp;
              this.checkReloadCalendarNeeded(calendar);
            }
          }
        });

       if (!found){
        this.loggerService.error(this.LOGGER_CLASSNAME, 'handleCalendarUpdateInfo', 'should handle updateInfo for calendar with id ' + calendarId + ', but calendar could not be found.');
       }
     }else{
       this.loggerService.error(this.LOGGER_CLASSNAME, 'handleCalendarUpdateInfo', 'should handle updateInfo for playlist with id ' + calendarId + ', but calendarGroups are not yet loaded.');
     }
   }else{
     this.loggerService.error(this.LOGGER_CLASSNAME, 'handleCalendarUpdateInfo', 'should handle updateInfo for playlist with id ' + calendarId + ', but no timestamp received.');
   }
 }

  private checkReloadCalendarNeeded(calendar: Calendar){
    if (calendar.refetchNeededForConflict){
     this.loadCalendarItems(calendar)
    }else if (calendar.timestampFromUpdateTrigger != undefined){
      if (calendar.timestampFromUpdateTrigger > calendar.timestamp){
        if (!calendar.saving && !calendar.calendarItemsLoading){
         this.loadCalendarItems(calendar)
        }
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, 'checkReloadCalendarNeeded', 'calendar ' + calendar.calendarId + ' has a newer version (' + calendar.timestamp + ') then seen in the updateInfo (' + calendar.timestampFromUpdateTrigger + ') -> going to ignore update info timestamp.');
      }
    }
  }

}
