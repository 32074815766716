import { ChangeableParameter } from '@model/changeableParameter';
import { DTO_ChangeableParameter } from '@service/api/dto/changeableParameter';
import { ChangeableIntervalParameter } from '@model/changeableIntervalParameter';
import { DTO_ChangeableIntervalParameter } from '@service/api/dto/changeableIntervalParameter';
import { ChangeableSelectionParameter } from '@model/changeableSelectionParameter';
import { DTO_ChangeableSelectionParameter } from '@service/api/dto/changeableSelectionParameter';
import { Value } from '@model/value';
import { DTO_Value } from '@service/api/dto/value';

export function convertChangeableParameterToDTO(changeableParameter: ChangeableParameter): DTO_ChangeableParameter{
    let dto_changeableParameter: DTO_ChangeableParameter = null;
    if (changeableParameter instanceof ChangeableIntervalParameter){
        dto_changeableParameter = convertChangeableIntervalParameterToDTO(changeableParameter);
    }else if (changeableParameter instanceof ChangeableSelectionParameter){
        dto_changeableParameter = convertChangeableSelectionParameterToDTO(changeableParameter);
    }else{
        dto_changeableParameter =  new DTO_ChangeableParameter();
        console.error("convertChangeableParameterToDTO - changeableParameter type not recognized");
    }

    dto_changeableParameter["@type"] = changeableParameter["@type"];
    dto_changeableParameter.metadataDisplayName = changeableParameter.metadataDisplayName;
    dto_changeableParameter.metadataDisplayType = changeableParameter.metadataDisplayType;
    dto_changeableParameter.name = changeableParameter.name;

    return dto_changeableParameter;
}

export function convertChangeableIntervalParameterToDTO(changeableIntervalParameter:ChangeableIntervalParameter): DTO_ChangeableIntervalParameter{
    let dto_changeableIntervalParameter: DTO_ChangeableIntervalParameter = new DTO_ChangeableIntervalParameter();

    dto_changeableIntervalParameter.minimum = changeableIntervalParameter.minimum;
    dto_changeableIntervalParameter.maximum = changeableIntervalParameter.maximum;
    dto_changeableIntervalParameter.step = changeableIntervalParameter.step;
    dto_changeableIntervalParameter.lowerSelection = changeableIntervalParameter.lowerSelection;
    dto_changeableIntervalParameter.upperSelection = changeableIntervalParameter.upperSelection;
    dto_changeableIntervalParameter.metadataDisplayMinimum = changeableIntervalParameter.metadataDisplayMinimum;
    dto_changeableIntervalParameter.metadataDisplayMaximum = changeableIntervalParameter.metadataDisplayMaximum;

    return dto_changeableIntervalParameter;
}

export function convertChangeableSelectionParameterToDTO(changeableSelectionParameter:ChangeableSelectionParameter): DTO_ChangeableSelectionParameter{
    let dto_changeableSelectionParameter: DTO_ChangeableSelectionParameter = new DTO_ChangeableSelectionParameter();

    if (changeableSelectionParameter.value){
        dto_changeableSelectionParameter.value = [];
        changeableSelectionParameter.value.forEach(value => {
            dto_changeableSelectionParameter.value.push(convertValueToDTO(value));
        });
    }

    return dto_changeableSelectionParameter;
}

export function convertValueToDTO(value:Value):DTO_Value{
    let dto_value = new DTO_Value();
    dto_value.value = value.value;
    dto_value.selected = value.selected;
    return dto_value;
}