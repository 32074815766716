import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TrackTableItem } from '@components/components-v5/track-list-view/track-list-view.component';
import { AudioFile } from '@model/audioFile';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { QueueService } from '@service/queue.service';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Playlist } from '@model/playlist';
import { TunifyCheckBoxColor } from '@components/components-v5/checkbox-v5/checkbox-v5.component';
import { PlaylistService } from '@service/playlist.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { MatSnackBar } from '@angular/material/snack-bar';
import { slideUpAnimation } from '@util/animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { SubscriptionsService } from '@service/subscriptions.service';

export enum SearchState{
  normal,
  searching,
  searchError,
  none
}

@Component({
  selector: 'tun-search-music-collection-result-content-view',
  templateUrl: './search-music-collection-result-content-view.component.html',
  styleUrls: ['./search-music-collection-result-content-view.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]
})
export class SearchMusicCollectionResultContentViewComponent implements OnInit, OnDestroy{
  public LOGGER_NAME = SearchMusicCollectionResultContentViewComponent.name;
  private SNACKBAR_DURATION = 5000;

  public TunifyColor = TunifyColor
  public TunifyCheckBoxColor = TunifyCheckBoxColor

  public get showingPopup(){
    return this.showChoosePlaylistOverlay;
  }
  public showChoosePlaylistOverlay = false

  public get showingMusicChannel$(){
    return this.appV5StateService.showDetailsForSearchMusicChannel$
  }

  public get musicCollection$(){
    return this.appV5StateService.showDetailsForSearchMusicCollection$
  }

  private trackTableItemsChanged$ = new Subject<void>();
  private _trackTableItems: TrackTableItem[];
  public set trackTableItems(value: TrackTableItem[]){
    this._trackTableItems = value;
    this.trackTableItemsChanged$.next();
  }
  public get trackTableItems(){
    return this._trackTableItems;
  }

  public get trackItemSize$(){
    return this.appV5StateService.pixelsFor1Rem$
    .pipe(
      map(px => {
        return 3 * px
      })
    )
  }

  public get title(){
    if (this.appV5StateService.showDetailsForSearchMusicCollection){
      return this.appV5StateService.showDetailsForSearchMusicCollection.title
    }
    return ""
  }

  //make the enum available in the html template
  public SearchState = SearchState;

  get searchState(): SearchState{
      if (this.appV5StateService.loadingTracksForMusicCollection){
        return SearchState.searching;
      }else if (this.appV5StateService.loadingTracksForMusicCollectionError){
        return SearchState.searchError;
      }else if (this.appV5StateService.tracksForMusicCollection){
        return SearchState.normal;
      }
      return SearchState.none;
  }

  get audioFileProperty$(){
    return this.zoneConfigurationService.audioFileProperty$
  }

  public get startTrackEnabled$(){
    return this.subscriptionsService.accessRights$
      .pipe(
        map(
          (accessRights => {
            return accessRights == null || accessRights.startTrack
          })
        )
      )
  }

  constructor(
    private zoneConfigurationService: ZoneConfigurationService,
    private appV5StateService: AppV5StateService,
    private translateService: TranslateService,
    private musicManipulationService: MusicManipulationService,
    private queueService: QueueService,
    private loggerService: LoggerService,
    private playlistService: PlaylistService,
    private snackBar: MatSnackBar,
    private trackOverlayService: TrackOverlayService,
    private subscriptionsService: SubscriptionsService
  ) {

  }

  ngOnInit(): void {
    this.appV5StateService.tracksForMusicCollection$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (tracks)=>{
        if (tracks){
          this.trackTableItems = tracks.map(track => new TrackTableItem(track))
        }else{
          this.trackTableItems = [];
        }
        this.checkedTracks = [];
        this.updateAllComplete();
      }
    )
  }


  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.trackTableItemsChanged$.complete();

    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;


  }

  onBack(){
    this.appV5StateService.showSearchMusicCollectionInTunifyOrange(null);
  }


  /* Handlers for header */

  public onPlayAll(){
    if (this.appV5StateService.tracksForMusicCollection){
      if (this.appV5StateService.tracksForMusicCollection.length > 0){
        const tracksToQueue: AudioFile[] = [];
        this.appV5StateService.tracksForMusicCollection.forEach(track => tracksToQueue.push(createClassObjectForAudioFile(track)));

        const trackToPlay = tracksToQueue.shift()
        this.musicManipulationService.playAudioFile(trackToPlay);

        if (tracksToQueue.length > 0){
          this.musicManipulationService.clearQueue();
          this.musicManipulationService.addAudioFilesToQueue(tracksToQueue);
        }
      }
    }
  }

  public onRefresh(){
    this.appV5StateService.loadTracksForCurrentMusicCollection();
  }

  /* Handler for selection header */
  public checkedTracks : AudioFile[] = [];

  get showSelectionBox(){
    return this.checkedTracks.length > 0
  }

  get selectTitle(){
    if (this.checkedTracks.length == 0){
      return this.translateService.instant('search.result.select.all')
    }else if (this.checkedTracks.length == 1){
      return this.translateService.instant('search.result.select.amount.one')
    }else{
      return this.translateService.instant('search.result.select.amount.many').replace("{0}", this.checkedTracks.length);
    }
  }

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.trackTableItems.every(trackTableItem => this.checkedTracks.includes(trackTableItem.track))
  }

  someComplete(): boolean {
    if (this.trackTableItems == null) {
      return false;
    }
    return !this.allComplete && this.checkedTracks.length > 0;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.allComplete){
      this.checkedTracks = this.trackTableItems.map(trackTableItem => trackTableItem.track);
    }else{
      this.checkedTracks = [];
    }
  }

  onAddSelectedToQueue(){
    this.musicManipulationService.addAudioFilesToQueue(this.checkedTracks)
    .pipe(
      takeUntil(
        merge(
          this.trackTableItemsChanged$,
          this.destroyed$
        )
      )
    ).subscribe(
      (asyncStatus) => {
        if (asyncStatus == AsyncStatus.COMPLETED){
            const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
              duration: this.SNACKBAR_DURATION,
              panelClass: ['tunify-snackbar']
            });

        }
      }
    );


  }

  /*
  overlayRef: OverlayRef;
  @ViewChild('overlayOrigin') overlayOrigin: CdkOverlayOrigin;
  @ViewChild('wrapper', {static: true}) wrapper:any;

  onAddSelectedToPlaylist(){

    const portal = new ComponentPortal(OverlaySelectPlaylistComponent);

    const config = new OverlayConfig();



         const positionStrategy = this.overlay.position().flexibleConnectedTo(
            this.overlayOrigin.elementRef)
            .withPositions([{
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'top',
            }]);
        this.overlayRef = this.overlay.create({positionStrategy});

        const viewRect = this.wrapper.nativeElement.getBoundingClientRect();

    if (viewRect.height > 0){
      let width = viewRect.width;
      let height = viewRect.height;
      this.overlayRef.updateSize({ width, height });
    }

        const popupComponent = this.overlayRef.attach(portal).instance;

  }
  */

  onAddSelectedToPlaylist(){
    this.showChoosePlaylistOverlay = true
  }

  /* Handlers for track item */
  public onPlay(track: AudioFile){
    this.musicManipulationService.playAudioFile(track);
  }

  public onShowOptions(track: AudioFile){
    this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = new TrackInfoOverlayData(track, new TrackInfoContext(TrackInfoContextMode.search));
  }

  public onCheckedChanged(value:boolean, track: AudioFile){
    if (value){
      //add to checkedTracks
      if (this.checkedTracks.filter(t => t == track).length == 0){
        this.checkedTracks.push(track);
        this.updateAllComplete()
      }else{
        this.loggerService.error(this.LOGGER_NAME, "onCheckedChanged", "checked a track that was already checked");
      }
    }else{
      if (this.checkedTracks.filter(t => t == track).length > 0){
        this.checkedTracks = this.checkedTracks.filter(t => t != track);
        this.updateAllComplete()
      }else{
        this.loggerService.error(this.LOGGER_NAME, "onCheckedChanged", "un-checked a track that was already un-checked");
      }
    }
  }

  public isTrackChecked(track: AudioFile){
    return this.checkedTracks.filter(t => t == track).length > 0
  }

  /* Select playlist popup */
  onCloseSelectPlaylist(playlist: Playlist){
    if (playlist && this.checkedTracks.length > 0){
      this.playlistService.addAudioFileToPlaylist(playlist, this.checkedTracks)
      .pipe(
        takeUntil(
          merge(
            this.trackTableItemsChanged$,
            this.destroyed$
          )
        )
      ).subscribe(
        (asyncStatus) => {
          if (asyncStatus == AsyncStatus.RUNNING){
            /*
            const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
              duration: this.SNACKBAR_DURATION,
              panelClass: ['tunify-snackbar']
            });
            */

        }
          if (asyncStatus == AsyncStatus.COMPLETED){

              const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });

          }
        }
      );
    }
    this.showChoosePlaylistOverlay = false;
  }

  //General popup
  onClickOutside(event: Event, item){
    if (event.target !== item)
      return;
    this.showChoosePlaylistOverlay = false;
    this.showTweakPopup = false;
  }


  //Tweak popup
  public showTweakPopup = false;
  public onTweak(){
    this.showTweakPopup = true
  }

  public onParametersChanged(){
    if (this.appV5StateService.showDetailsForSearchMusicCollection){
      this.appV5StateService.loadTracksForCurrentMusicCollection();
    }
  }

  public onCloseTweakPopup(){
    this.showTweakPopup = false;
  }




}
