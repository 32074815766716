import { Component, Input, OnDestroy } from '@angular/core';
import { PlayState } from '@model/enums/playState.enum';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { FavoriteBannedTrack, FavoriteBannedTrackService } from '../../../../../services/app-v5/favorite-banned-track.service';
import { AudioFile } from '@model/audioFile';
import { TrackForRemoteSharing } from '@service/vo/remote/remote-objects';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';

@Component({
  selector: 'tun-large-control-panel',
  templateUrl: './large-control-panel.component.html',
  styleUrls: ['./large-control-panel.component.scss']
})
export class LargeControlPanelComponent implements OnDestroy {


  @Input() canShowTooltips = false;


  private LOGGER_CLASSNAME = 'LargeControlPanelComponent';

  //Favorite observable
  private dummyFavorite$ = new BehaviorSubject<boolean>(false).asObservable();
  private favorite_source = new BehaviorSubject(this.dummyFavorite$);
  public get isFavorite$(){
    return this.favorite_source.pipe(switchMap(source => source));
  }

  //Banned observable
  private dummyBanned$ = new BehaviorSubject<boolean>(false).asObservable();
  private banned_source = new BehaviorSubject(this.dummyFavorite$);
  public get isBanned$(){
    return this.banned_source.pipe(switchMap(source => source));
  }

  private _favBannedTrack : FavoriteBannedTrack;
  private set favBannedTrack(value: FavoriteBannedTrack){
    this._favBannedTrack = value;
    if (this.favBannedTrack){
      this.favorite_source.next(this.favBannedTrack.trackIsFavorite$);
      this.banned_source.next(this.favBannedTrack.trackIsBanned$);
    }else{
      this.favorite_source.next(this.dummyFavorite$);
      this.banned_source.next(this.dummyBanned$);
    }

  }
  private get favBannedTrack(): FavoriteBannedTrack{
    return this._favBannedTrack;
  }

  public get playing$(){
    return this.playingItemService.playState$
      .pipe(
        map(state => state == PlayState.PLAYING || state == PlayState.STARTING_TO_PLAY)
      )
  }

  public get colorString$(){
    return this.playingMusicSelectionService.lastRealplayingMusicSelection$
      .pipe(
      map((playingMusicSelection) => {
        if (playingMusicSelection.origin == PlayingOrigin.musicChannel){
          return "blue"
        }else if (playingMusicSelection.origin == PlayingOrigin.calendar){
          return "green"
        }
        return "orange"
      })
    )
  }

  public get needClickToStartAudio$() {
    return this.playingItemService.needClickToPlay$
  }

  constructor(
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private musicManipulationService: MusicManipulationService,
    private playingItemService: PlayingItemService,
    private loggerService: LoggerService,
    private favoriteBannedTrackService: FavoriteBannedTrackService,
    private trackOverlayService: TrackOverlayService,


    ) {
      this.playingItemService.playingAudio$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (audio)=>{
          if (this.favBannedTrack){
            this.favoriteBannedTrackService.cleanupFavoriteBannedTrack(this.favBannedTrack);
            this.favBannedTrack = null;
          }
          if (audio){
            this.favBannedTrack = new FavoriteBannedTrack(audio);
            this.favoriteBannedTrackService.registerFavoriteBannedTrack(this.favBannedTrack);
          }
          this.showingAudio = audio;
        }
      )
    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;

      if (this.favBannedTrack != null){
        this.favoriteBannedTrackService.cleanupFavoriteBannedTrack(this.favBannedTrack);
        this.favBannedTrack = null;
      }
    }

  private showingAudio : AudioFile | TrackForRemoteSharing = null;
  public onTrackOptions(){
    if (this.showingAudio != null && this.showingAudio instanceof AudioFile){
      const trackInfoOverlayData = new TrackInfoOverlayData(this.showingAudio, new TrackInfoContext(TrackInfoContextMode.player));
      this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = trackInfoOverlayData;
    }
  }

  public onToggleFavorite(){
    if (this.favBannedTrack){
      this.favBannedTrack.toggleIsFavorite();
    }
  }

  public onToggleBanned(){
    if (this.favBannedTrack){
      this.favBannedTrack.toggleIsBanned();
    }
  }



  public onSkip(){
    this.musicManipulationService.next();
  }




}
