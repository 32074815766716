export const customPlaylists = {
"timestamp": 1111,
"customPlaylists":
 [
    {
        "@type": "Playlist",
        "id": 10000,
        "title": "Een lijst met een erg lange naam - test scrolling with this title",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10001,
        "title": "Dries 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10002,
        "title": "Mocked list",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10003,
        "title": "Another mocked",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10004,
        "title": "More lists",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10005,
        "title": "Mocked list 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10006,
        "title": "Another mocked 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10007,
        "title": "More lists 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10008,
        "title": "Mocked list 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10009,
        "title": "Another mocked 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10010,
        "title": "Test - More lists",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10011,
        "title": "Test - Mocked list 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10012,
        "title": "Test - Another mocked 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10013,
        "title": "Test - More lists 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10014,
        "title": "Test - Mocked list 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10015,
        "title": "Test - Another mocked 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10016,
        "title": "Test - More lists",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10017,
        "title": "Test - Mocked list 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10018,
        "title": "Test - Another mocked 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10019,
        "title": "Test - More lists 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10020,
        "title": "Test - Mocked list 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10021,
        "title": "Test - Another mocked 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },{
        "@type": "Playlist",
        "id": 10022,
        "title": "Test - Another mocked 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10023,
        "title": "Test - More lists 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10024,
        "title": "Test - Mocked list 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10025,
        "title": "Test - Another mocked 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10026,
        "title": "Test - More lists",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10027,
        "title": "Test - Mocked list 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10028,
        "title": "Test - Another mocked 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10029,
        "title": "Test - More lists 2",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10030,
        "title": "Test - Mocked list 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    },
    {
        "@type": "Playlist",
        "id": 10031,
        "title": "Test - Another mocked 3",
        "description": "",
        "lastChanged": null,
        "audioFiles": null,
        "visible": true,
        "editable": true,
        "favourite": false,
        "selected": false,
        "mostPlayed": false,
        "defaultSelected": false
    }
]};
