import { Component } from '@angular/core';

@Component({
  selector: 'tun-autocomplete-artist-icon-v5',
  templateUrl: './autocomplete-artist-icon-v5.component.html',
  styleUrls: ['./autocomplete-artist-icon-v5.component.scss']
})
export class AutocompleteArtistIconV5Component {

}
