import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoggerService } from '@service/loggers/logger.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '@service/config';
import { AuthenticationService } from '@service/authentication.service';

export class DTO_configValue{
  key: string;
  value: string;
  status: DTO_configStatus;
}

export enum DTO_configStatus{
  OK = "OK",
  NOT_FOUND = "NotFound",
  PROHIBITED = "Prohibited",
  INVALID = "invalid"
}

@Injectable({
  providedIn: 'root'
})
export class ConfigApiService {

  private LOGGER_CLASSNAME = 'ConfigApiService';

  constructor(
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService,
    private httpClient: HttpClient
  ) { }

  public loadPublicConfigs(keys: string[]): Observable<DTO_configValue[]>{
    let resultObservable: Observable<DTO_configValue[]>;

    if (environment.mockBackend){
      //when we mock, we just load all our known config values. The service will have to filter the correct ones out.
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadPublicConfigs', 'about to mock loadPublicConfigs request');
      resultObservable = this.httpClient.get<DTO_configValue[]>("api/publicConfigs");
    }else{
      let url = Config.api4Url_publicConfigs();

      const params = new HttpParams()
                      .set('keys', keys.join());
                      
      resultObservable = this.httpClient.get<DTO_configValue[]>(url, {params: params});
    }

    return resultObservable;
  }


  public loadZoneConfigs(keys: string[]): Observable<DTO_configValue[]>{
    let resultObservable: Observable<DTO_configValue[]>;

    if (environment.mockBackend){
      //when we mock, we just load all our known config values. The service will have to filter the correct ones out.
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadZoneConfigs', 'about to mock loadPublicConfigs request');
      resultObservable = this.httpClient.get<DTO_configValue[]>("api/publicConfigs");
    }else{
      let url = Config.api4Url_zoneConfigs(this.authenticationService.zoneId);

      const params = new HttpParams()
                      .set('keys', keys.join());
                      
      resultObservable = this.httpClient.get<DTO_configValue[]>(url, {params: params});
    }

    return resultObservable;
  }

  public saveZoneBooleanConfig(key: string, value: boolean): Observable<DTO_configValue>{
    let resultObservable: Observable<DTO_configValue>;

    if (environment.mockBackend){
      //when we mock, we just load all our known config values. The service will have to filter the correct ones out.
      //this.loggerService.info(this.LOGGER_CLASSNAME, 'loadZoneConfigs', 'about to mock loadPublicConfigs request');
      //resultObservable = this.httpClient.get<DTO_configValue[]>("api/publicConfigs");
      this.loggerService.error(this.LOGGER_CLASSNAME, "saveZoneBooleanConfig", "not implemented");
    }else{
      let url = Config.api4Url_zoneConfigs(this.authenticationService.zoneId) + key;
                      
      resultObservable = this.httpClient.put<DTO_configValue>(url, value);
    }

    return resultObservable;
  }

}
