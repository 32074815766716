<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 18 18"
  enable-background="new 0 0 18 18"
  xml:space="preserve"
>
  <polygon
    color="currentColor"
    fill="currentColor"
    points="13,3 10.737,3 4.737,9 10.738,15 13,15 7,9 "
  />
</svg>
