<div [class]="zoneConnection.valid?'container':'error-container'">
  <div class="infoContainer">
    <div class="location-container icon-container">
      <tun-location-icon></tun-location-icon>
    </div>
    <div *ngIf="!(loadingZoneInfo$ | async)" class="zone-container">
      <tun-scrollable-text class="name-label" [text]="nameForView"></tun-scrollable-text>
      <tun-scrollable-text *ngIf="zoneConnection.locationName != null" class="location-label" text="{{zoneConnection.locationName}}"></tun-scrollable-text>
    </div>
    <div *ngIf="loadingZoneInfo$ | async" class="status">
        <tun-loader text="general.loading"></tun-loader>
    </div>
  </div>




  <div class="button-container">

    <div *ngIf="sharingZoneConnection$ | async" class="button button-player-disabled">
      <tun-loader-v5 text="Delen met desktop app"></tun-loader-v5>
    </div>

    <div *ngIf="zoneConnectionShared" class="button button-player-disabled">
      Gedeeld met desktop app
    </div>

    <button *ngIf="!zoneConnectionShared && !(sharingZoneConnection$ | async)" type="submit"
      (click)="shareToDesktopApp()" class="button button-player" translate
    >
      Delen met desktop app
    </button>

  </div>

</div>
