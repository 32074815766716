import { NgModule }     from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConnectDesktopAppViewComponent } from './connect-desktop-app-view/connect-desktop-app-view.component';

@NgModule({
  imports: [
      RouterModule.forChild([
          { path: 'connect-desktop-app', component: ConnectDesktopAppViewComponent}
      ])
  ],
  exports: [
      RouterModule
  ]
})
export class ConnectDesktopAppRoutingModule { }
