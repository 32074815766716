import { Injectable } from '@angular/core';

import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '@service/authentication.service';
import { PlayerComponent } from './player.component';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { AppVersionService, MAJOR_VERSION } from '../../services/data/app-version.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerGuardService {

  constructor(
    private router: Router,
    private zoneConnectionsService: ZoneConnectionsService,
    private appVersionService: AppVersionService
  ) {

  }

  canActivate(): boolean {
    if (this.zoneConnectionsService.activeZoneConnection != null, this.appVersionService.isVersionAllowed(MAJOR_VERSION.V4)) {
      return true;
    }
    return false;
  }

  canDeactivate(component: PlayerComponent) {
    /*if (this.zoneConnectionsService.activeZoneConnection!= null) {
      var c = confirm('Are you sure you want to leave Tunify? Music will stop..');
      if (c == true){
        this.zoneConnectionsService.deactivateCurrentZoneConnection();
        return false;
      }
      return false;

    }*/
    return true;
  }

}
