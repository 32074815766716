import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitInputDirective } from './split-input.directive';
import { SplitInputComponent } from './split-input/split-input.component';



@NgModule({
  declarations: [
    SplitInputComponent,
    SplitInputDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SplitInputComponent,
    SplitInputDirective
  ]
})
export class SplitInputModule { }
