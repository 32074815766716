import { LogTarget, LOGLEVEL } from './logTarget';

export class BaseLogTarget implements LogTarget{

  protected enableAllLoggingForClassnames = [];

  protected logLevel = LOGLEVEL.DEBUG;
  public adjustLogLevel(logLevel: LOGLEVEL){
    this.logLevel = logLevel;
  }

  get currentLogLevel(): LOGLEVEL{
    return this.logLevel;
  }

  log(logLevel: LOGLEVEL, classname: string, method: string, message: string){
    if (this.shouldLog(logLevel, classname)){
      this.performLogging(logLevel, this.formatMessage(classname, method, message));
    }
  }

  flushBuffer(){
    //should be impletemented by targets that use a buffer and that need to flush the buffer
  }

  protected shouldLog(logLevel: LOGLEVEL, classname: string): boolean{
    return logLevel >= this.logLevel || (this.enableAllLoggingForClassnames.length > 0 && this.enableAllLoggingForClassnames.includes(classname) && logLevel >= LOGLEVEL.DEBUG);
  }

  protected formatMessage(classname: string, method: string, message: string): string {
    return classname + '.' + method + ' => ' + message;
  }

  protected performLogging(logLevel: LOGLEVEL, message: string){
    //to implement in each target that extends this base target
  }



}
