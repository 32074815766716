import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ZoneConnection } from '@model/zoneConnection';
import { DesktopAppInstallerService } from '@service/app-v5/desktop-app-installer.service';
import { ZoneConnectionService } from '@service/authentication/zone-connection.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'tun-connect-zone-connection-to-desktop-view',
  templateUrl: './connect-zone-connection-to-desktop-view.component.html',
  styleUrl: './connect-zone-connection-to-desktop-view.component.scss'
})
export class ConnectZoneConnectionToDesktopViewComponent implements OnInit, OnDestroy {

  get sharingZoneConnection$(){
    return this.desktopAppInstallerService.sharingZoneConnection$
      .pipe(map(zc => zc == this.zoneConnection));
  }

  get zoneConnectionShared(){
    return this._zoneConnection != null && this._zoneConnection.sharedToDesktopApp;
  }

  //only show a loading info state if we do not already have 'some' name
  get loadingZoneInfo$(){
    return this.zoneConnection.loadingZoneInfo$
      .pipe(map(loading => this.zoneConnection.name != null ? false : loading))
  }

  get nameForView(){
    if (this._zoneConnection.name){
      return this._zoneConnection.name;
    }
    return this._zoneConnection.zoneId;
  }

  _zoneConnection: ZoneConnection;
  @Input()
  set zoneConnection(value: ZoneConnection){
    this._zoneConnection = value;
  }
  get zoneConnection(){
    return this._zoneConnection;
  }

  constructor(
    private zoneConnectionService: ZoneConnectionService,
    private desktopAppInstallerService: DesktopAppInstallerService
  ) {

  }

  ngOnInit() {
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  public shareToDesktopApp(){
    this.desktopAppInstallerService.startSharingZoneConnection(this.zoneConnection)
    .pipe(
      takeUntil(
        this.destroy$
      )
    )
    .subscribe((result) => {

    });
  }

}
