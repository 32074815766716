import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TrackTableItem } from '@components/components-v5/track-list-view/track-list-view.component';
import { TranslateService } from '@ngx-translate/core';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { QueueService } from '@service/queue.service';
import { isFinal } from '@service/vo/asyncStatus';
import { Util } from '@util/util';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-waiting-queue-header',
  templateUrl: './waiting-queue-header.component.html',
  styleUrls: ['./waiting-queue-header.component.scss']
})
export class WaitingQueueHeaderComponent implements OnDestroy {

    @Input() open = false;

    @Output() toggleOpen = new EventEmitter();
    @Output() shuffle = new EventEmitter();
    @Output() clear = new EventEmitter();

    public newTracks = [];

    public get queueTitle$(){
      return combineLatest([
          this.amountTracks$,
          this.translateService.stream("queue.queueHeaderLabel.title"),
          this.translateService.stream("queue.queueHeaderLabel.empty"),
          this.translateService.stream("queue.queueHeaderLabel.one"),
          this.translateService.stream("queue.queueHeaderLabel.many"),
          this.durationString$
        ])
        .pipe(
          map(([amountTracks, queueTitle, amountEmptyString, amountOneString, amountManyString, durationString]) => {
            let title = queueTitle + " ";
            if (amountTracks == 0){
              title += amountEmptyString;
            }else {
              title += "(";
              if (amountTracks == 1){
                title += amountOneString;
              }else{
                title += this.replaceOccurences(amountManyString, ""+amountTracks);
              }
              title += ", " + durationString + ")";
            }
            return title;
          })
        );
    }

    public get amountTracks$(){
      return this.queueService.queue$
      .pipe(
        map(
          (queue) => {
            if (queue == null){
              return 0
            }
            return queue.length;
          }
        )
      )
    }

    public replaceOccurences(string: String, string2: string): string{
      return string.replace("{0}", string2)
    }

    public get durationString$(){
      return combineLatest([this.queueService.queue$, this.translateService.stream("playlist.time.hours.short"), this.translateService.stream("playlist.time.minutes.short"), this.translateService.stream("playlist.time.seconds.short")])
      .pipe(
        map(
          ([queue, hoursString, minutesString, secondsString]) => {
            if (queue == null || queue.length == 0){
              return ""
            }
            const totalDuration = queue.reduce((n, {duration}) => n + duration, 0) / 1000

            return Util.formatSeconds(totalDuration, hoursString, minutesString, secondsString);
          }
        )
      )
    }



    constructor(
      private queueService: QueueService,
      private translateService: TranslateService,
      private musicManipulationService: MusicManipulationService
    ) {

    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
    }

    public onToggleOpen(){
      this.toggleOpen.emit(!this.open)
    }

    public onShuffle(){
      this.shuffle.emit();
    }

    public onClear(){
      this.clear.emit();
    }

    public drop(event: CdkDragDrop<TrackTableItem[]>) {

      if (event.item.data instanceof TrackTableItem){
        const track = event.item.data.track;
        this.musicManipulationService.addAudioFilesToQueue(
          [track],
          -1
        );

      }

    }
}
