import { Injectable } from '@angular/core';
import { TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { AudioFile } from '@model/audioFile';
import { Playlist } from '@model/playlist';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PlaylistService } from '../data/playlist.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { SearchType, SuggestionsType } from '../api/search-api.service';
import { AppV5StateService } from './app-v5-state.service';
import { SearchV5Service } from './search-v5.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SNACKBAR_DURATION } from '@view/player-v5/player-v5.component';

export enum TrackOverlayActionFeedbackType {
  RemoveFromPlaylist = "RemoveFromPlaylist",
  RemovedFromFavorites = "RemovedFromFavorites",
  AddedToFavorites = "AddedToFavorites",
  RemovedFromBanlist = "RemovedFromBanlist",
  AddedToBanlist = "AddedToBanlist",
  RemovedFromQueue = "RemovedFromQueue",
  AddToQueue = "AddToQueue",
  AddToPlaylist = "AddToPlaylist"
}

export class TrackOverlayActionFeedback {
  type: TrackOverlayActionFeedbackType;

  observable: Observable<AsyncStatus>;

  constructor(type: TrackOverlayActionFeedbackType, observable?: Observable<AsyncStatus>){
    this.type = type
    this.observable = observable;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TrackOverlayService {

  public SearchType = SearchType;

  constructor(
    private playlistService: PlaylistService,
    private musicManipulationService: MusicManipulationService,
    private searchV5Service: SearchV5Service,
    private appV5StateService: AppV5StateService,
    private subscriptionsService: SubscriptionsService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) { }

  //showing details of a track (OVERLAY)

  public set showTrackOverlayForTrackInfoOverlayData(value: TrackInfoOverlayData) {
    if (this._showTrackOverlayForTrackInfoOverlayDataSubject.value !== value) {
      this._showTrackOverlayForTrackInfoOverlayDataSubject.next(value);
    }
  }
  public get showTrackOverlayForTrackInfoOverlayData(): TrackInfoOverlayData {
    return this._showTrackOverlayForTrackInfoOverlayDataSubject.value;
  }
  private _showTrackOverlayForTrackInfoOverlayDataSubject = new BehaviorSubject<TrackInfoOverlayData>(null);
  public showTrackOverlayForTrackInfoOverlayData$ = this._showTrackOverlayForTrackInfoOverlayDataSubject.asObservable();

  //Emits when an action is triggered from the overlay. A view can subscribe to this subject to give feedback about the status
  public trackOverlayActionFeedback$ = new Subject<TrackOverlayActionFeedback>();
  public notifyAction(type: TrackOverlayActionFeedbackType){
    this.trackOverlayActionFeedback$.next(new TrackOverlayActionFeedback(type))
    this.showTrackOverlayForTrackInfoOverlayData = null;
  }
  private notifyActionWithObservable(type: TrackOverlayActionFeedbackType, observable?: Observable<AsyncStatus>){
    this.trackOverlayActionFeedback$.next(new TrackOverlayActionFeedback(type, observable));
    this.showTrackOverlayForTrackInfoOverlayData = null;
  }


  //Action handlers
  public removeFromPlaylist(track: AudioFile, playlist: Playlist){
    const observable = this.playlistService.deleteAudioFileInPlaylist(playlist, [track])
    this.notifyActionWithObservable(TrackOverlayActionFeedbackType.RemoveFromPlaylist, observable);
  }

  public addToQueue(track: AudioFile){
    const observable =  this.musicManipulationService.addAudioFilesToQueue([track]);
    this.notifyActionWithObservable(TrackOverlayActionFeedbackType.AddToQueue, observable);
  }

  public addToPlaylist(track: AudioFile, playlist: Playlist){
    const observable =  this.playlistService.addAudioFileToPlaylist(playlist, [track]);
    this.notifyActionWithObservable(TrackOverlayActionFeedbackType.AddToPlaylist, observable);
  }

  public removeFromQueue(track: AudioFile){
    this.musicManipulationService.removeAudioFileFromQueue(track)
    this.notifyAction(TrackOverlayActionFeedbackType.RemovedFromQueue);
  }

  public search(track: AudioFile, searchType: SearchType){
    this.showTrackOverlayForTrackInfoOverlayData = null;
    if ((this.subscriptionsService.accessRights == null || this.subscriptionsService.accessRights.addToQueue)){
      this.searchV5Service.searchOnTrack(searchType, track);
      this.appV5StateService.openSearchResults();
    }else{
      const snackBarRef = this.snackBar.open(this.translateService.instant('accessRight.snackbar.search'), null, {
        duration: SNACKBAR_DURATION,
        panelClass: ['tunify-snackbar']
      });
    }

  }

  public searchSuggestion(track: AudioFile, suggestionsType: SuggestionsType){
    this.showTrackOverlayForTrackInfoOverlayData = null;
    if ((this.subscriptionsService.accessRights == null || this.subscriptionsService.accessRights.addToQueue)){
      this.searchV5Service.suggestionsForTrack(suggestionsType, track);
      this.appV5StateService.openSearchResults();
    }else{
      const snackBarRef = this.snackBar.open(this.translateService.instant('accessRight.snackbar.search'), null, {
        duration: SNACKBAR_DURATION,
        panelClass: ['tunify-snackbar']
      });
    }
  }


}
