export const musicCollection_8127 = {
    "@type": "Context",
    "id": 8127,
    "title": "Stimulerend",
    "description": "08",
    "lastChanged": null,
    "changeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Mood",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Mood",
            "value": [
                {
                    "value": "Bright",
                    "selected": true
                },
                {
                    "value": "Dynamic",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1970,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1970,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Party Level",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Party Level",
            "minimum": 3,
            "maximum": 4,
            "step": 1,
            "lowerSelection": 3,
            "upperSelection": 4,
            "metadataDisplayMinimum": "3",
            "metadataDisplayMaximum": "4"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 5,
            "maximum": 6,
            "step": 1,
            "lowerSelection": 5,
            "upperSelection": 6,
            "metadataDisplayMinimum": "5",
            "metadataDisplayMaximum": "6"
        }
    ],
    "defaultChangeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Mood",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Mood",
            "value": [
                {
                    "value": "Bright",
                    "selected": true
                },
                {
                    "value": "Dynamic",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1970,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1970,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Party Level",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Party Level",
            "minimum": 3,
            "maximum": 4,
            "step": 1,
            "lowerSelection": 3,
            "upperSelection": 4,
            "metadataDisplayMinimum": "3",
            "metadataDisplayMaximum": "4"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 5,
            "maximum": 6,
            "step": 1,
            "lowerSelection": 5,
            "upperSelection": 6,
            "metadataDisplayMinimum": "5",
            "metadataDisplayMaximum": "6"
        }
    ],
    "visible": true,
    "editable": false,
    "favourite": false,
    "selected": false,
    "mostPlayed": false,
    "defaultSelected": true
}