<div class="wrapper">

  <div class="contentWrapper">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <div class="title">
          {{(playlist == null ? 'playlist.create.title' : 'playlist.extraAction.changeName')  | translate }}
        </div>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <div class="subTitleContainer">
      <div class="subTitle">
        {{(playlist == null ? 'playlist.create.enterName.title' : 'playlist.edit.enterName.title') | translate }}
      </div>
    </div>

    <div  class="textInput">
      <input
        #nameInput
        type="text"
        [value]="currentName"
        (input)="onNameChange($event.target.value)"
      >
    </div>

    <div class="buttonsContainer">
      <button
        class="button cancel-button" (click)="onBack()"
      >
        {{'general.button.cancel' | translate}}
      </button>

      <button
        class="button create-button" [disabled]="currentName == null || currentName == ''" (click)="onCommit()"
      >
        {{(playlist == null ? 'playlist.create.enterName.button.submit' : 'playlist.edit.enterName.button.submit')  | translate}}
      </button>
    </div>

  </div>




</div>
