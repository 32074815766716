/**
 * TEST environement (https)
 *
 * run locally: ng serve --configuration="test"
 *
 * build for deployment: npm run build-for-test
 */

import { commonTESTEnv } from "./environment.test";

commonTESTEnv.use_redirect_location = true;
commonTESTEnv.build_version = "5";
commonTESTEnv.default_version = "5";
commonTESTEnv.default_v4_allowed = false;
commonTESTEnv.default_v5_allowed = true;

export const environment = commonTESTEnv;
