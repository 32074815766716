<div class="wrapper">
  <div class="trackInfoContainer">

    <tun-mini-track-info-view class="trackInfo">
    </tun-mini-track-info-view>

    <tun-remote-info-buttons-view
      clas="remoteInfoButtons"
      [canShowTooltips]="canShowTooltips"
    >
    </tun-remote-info-buttons-view>

    <div class="optionsButton">
      <button class="icon-button" (click)="onTrackOptions()">
        <tun-track-options class="icon icon-scaled-svg-button"></tun-track-options>
      </button>
    </div>
  </div>
  <div class="buttonsContainer">

    <div class="controlButton">
      <button class="icon-button" (click)="onToggleFavorite()">
        <tun-thumb-up-icon-v5 class="icon icon-scaled-svg-button {{(isFavorite$ | async) ? (colorString$ | async) : ''}}"></tun-thumb-up-icon-v5>
      </button>
    </div>

    <div class="controlButton">
      <button class="icon-button" (click)="onToggleBanned()">
        <tun-thumb-down-icon-v5 class="icon icon-scaled-svg-button {{(isBanned$ | async) ? (colorString$ | async) : ''}}"></tun-thumb-down-icon-v5>
      </button>
    </div>

    <div class="controlButton">

       <tun-toggle-play-button
        [canShowTooltips]="canShowTooltips"
        class="largePlayButton"
      >

       </tun-toggle-play-button>

    </div>

    <div class="controlButton">
      <button class="icon-button" (click)="onSkip()">
        <tun-skip-icon-v5 class="icon icon-scaled-svg-button {{colorString$ | async}}"></tun-skip-icon-v5>
      </button>
    </div>

    <div class="controlButton buttonPlaceHolder">

    </div>

  </div>

</div>
