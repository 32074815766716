import { Calendar } from './calendar';

export class CalendarGroup{
    id: number;
    name: string;
    calendars: Calendar[];

    constructor(calendarGroup?: CalendarGroup){
        if (calendarGroup){
            this.id = calendarGroup.id;
            this.name = calendarGroup.name;
            
            if (calendarGroup.calendars){
                this.calendars = [];
                calendarGroup.calendars.map(calendar => this.calendars.push(new Calendar(calendar)));
            }
        }
    }

}