import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeableIntervalParameter } from '@model/changeableIntervalParameter';
import { ChangeableParameter } from '@model/changeableParameter';
import { ChangeableSelectionParameter } from '@model/changeableSelectionParameter';
import { MetadataDisplayType } from '@model/enums/metadataDisplayType';
import { Value } from '@model/value';

export enum TunifyChangeableParameterColor{
  BLUE = "blue",
  GREEN = "green",
  ORANGE = "orange"
}

@Component({
  selector: 'tun-changeable-parameters-panel-v5',
  templateUrl: './changeable-parameters-panel-v5.component.html',
  styleUrls: ['./changeable-parameters-panel-v5.component.scss']
})
export class ChangeableParametersPanelV5Component {

  @Input() changeableParameters: ChangeableParameter[];
  @Input() changeableParametersColor = TunifyChangeableParameterColor.BLUE;

  @Output() parameterchange = new EventEmitter<ChangeableParameter[]>();

  onToggleValue(value: Value){
    value.selected = !value.selected
    this.parameterchange.emit(this.changeableParameters);
  }

  onCheckedChanged(paramValue: Value, newValue: boolean) {
    paramValue.selected = newValue;
    this.parameterchange.emit(this.changeableParameters);
  }

  adjustMinValue(param: ChangeableParameter, value: number) {
    if (param instanceof ChangeableIntervalParameter) {
      if (param.lowerSelection !== value) {
        param.lowerSelection = value;
        this.parameterchange.emit(this.changeableParameters);
      }
    } else if (param instanceof ChangeableSelectionParameter) {
      const selectUpTo = this.maxSelectedValue(param);
      let valueHasChanged = false;
      param.value.forEach((valueObject: Value, index: number) => {
        const shouldSelect = index >= value && index <= selectUpTo;
        if (valueObject.selected !== shouldSelect) {
          valueObject.selected = shouldSelect;
          valueHasChanged = true;
        }
      });
      if (valueHasChanged) {
        this.parameterchange.emit(this.changeableParameters);
      }
    }
  }

  adjustMaxValue(param: ChangeableParameter, value: number) {
    //console.log('max: ', value);
    if (param instanceof ChangeableIntervalParameter) {
      if (param.upperSelection !== value) {
        param.upperSelection = value;
        this.parameterchange.emit(this.changeableParameters);
      }
    } else if (param instanceof ChangeableSelectionParameter) {
      const selectFrom = this.minSelectedValue(param);
      let valueHasChanged = false;
      param.value.forEach((valueObject: Value, index: number) => {
        const shouldSelect = index >= selectFrom && index <= value;
        if (valueObject.selected !== shouldSelect) {
          valueObject.selected = shouldSelect;
          valueHasChanged = true;
        }
      });
      if (valueHasChanged) {
        this.parameterchange.emit(this.changeableParameters);
      }
    }
  }

  // return the index of the first selected value
  minSelectedValue(param: ChangeableParameter): number {
    let minIndex = 0;
    let found = false;
    if (param instanceof ChangeableSelectionParameter && param.value) {
      param.value.forEach((value, index) => {
        if (value.selected && !found) {
          minIndex = index;
          found = true;
        }
      });
    }
    return minIndex;
  }

  // return the index of the last selected value
  maxSelectedValue(param: ChangeableParameter): number {
    let maxIndex = 0;
    if (param instanceof ChangeableSelectionParameter && param.value) {
      param.value.forEach((value, index) => {
        if (value.selected) {
          maxIndex = index;
        }
      });
    }
    return maxIndex;
  }

  // === Helper functions === //
  isCheckboxSelectionParameter(changeableParameter: ChangeableParameter) {
    return (
      changeableParameter instanceof ChangeableSelectionParameter &&
      changeableParameter.metadataDisplayType ===
        MetadataDisplayType.TEXT_CHECKBOX_SELECTION
    );
  }

  isSliderRangeSelectionParameter(changeableParameter: ChangeableParameter) {
    return (
      changeableParameter instanceof ChangeableSelectionParameter &&
      changeableParameter.metadataDisplayType ===
        MetadataDisplayType.TEXT_SLIDER_RANGE_SELECTION
    );
  }

  isFlexibleSliderParameter(changeableParameter: ChangeableParameter) {
    return (
      changeableParameter instanceof ChangeableIntervalParameter &&
      changeableParameter.minimum > 7
    );
  }

  isSingleLabelTextSliderParameter(changeableParameter: ChangeableParameter) {
    return (
      changeableParameter instanceof ChangeableIntervalParameter &&
      changeableParameter.minimum <= 7
    );
  }


}
