import { Component } from '@angular/core';

@Component({
  selector: 'tun-angle-down',
  templateUrl: './angle-down.component.html',
  styleUrls: ['./angle-down.component.scss']
})
export class AngleDownComponent {

}
