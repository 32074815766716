<div #popupReference class="container">

  <button class="icon-button" (click)="onTogglePlay()">
    <tun-pause-icon-v5 *ngIf="reallyPlaying$ | async" class="icon {{colorString$ | async}}"></tun-pause-icon-v5>
    <tun-play-icon-v5 *ngIf="!(reallyPlaying$ | async)" class="icon {{colorString$ | async}}"></tun-play-icon-v5>
  </button>

  <tun-round-progress-view class="progress"></tun-round-progress-view>

</div>

