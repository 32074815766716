
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Playlist } from '@model/playlist';

@Component({
  selector: 'tun-playlist-detail-view',
  templateUrl: './playlist-detail-view.component.html',
  styleUrls: ['./playlist-detail-view.component.scss'],

})
export class PlaylistDetailViewComponent {

  @Input() showPlaylist: Playlist

  @Output() changeName = new EventEmitter<Playlist>();
  @Output() addSongs = new EventEmitter<Playlist>();

  onAddSongs(playlist: Playlist){
    this.addSongs.next(playlist);
  }

  onChangeName(playlist: Playlist){
    this.changeName.next(playlist);
  }
}
