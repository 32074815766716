<div *ngIf="calendarItem != null" class="content-wrapper">
  <div class="title-container">

    <tun-tweak-context-icon-v5 class="icon icon-scaled-svg-button"></tun-tweak-context-icon-v5>

    <div class="title">{{calendarItem.title}}</div>

    <tun-tweak-strength-bar-v5
      *ngIf="tweakInfoLoaded"
      [class.disabled]="tweakInfoLoading"
      [percentage]="tweakInfo?.percentage || 0"
      [status]="tweakInfo?.status"
    ></tun-tweak-strength-bar-v5>

    <!---->
    <button
      *ngIf="isContext"
      class="resetButton"
      (click)="onReset()"
    >
      Reset
    </button>

    <button class="icon-button" (click)="onClose()">
      <tun-close-v5 class="icon close-icon icon-large-scaled-svg-button"></tun-close-v5>
    </button>

  </div>

  <div class="seperator"></div>

  <div
    *ngIf="(loading$ | async)"
    class="feedback-container"
  >
    <tun-loader-v5 class="loader"></tun-loader-v5>
  </div>

  <div
      *ngIf="loadingError"
      class="error-feedback-container"
  >
    <p>
      {{'general.status.error.message' | translate }}
    </p>
    <button (click)="retryClick()" class="retryButton">
      <p>
        {{'general.status.error.retry' | translate }}
      </p>
    </button>
  </div>


  <ng-container
    *ngIf="!(loading$ | async) && !loadingError"
  >

    <tun-changeable-parameters-panel-v5
      *ngIf="isContext"
      [changeableParameters]="changeableParameters"
      (parameterchange)="onParameterChange()"
      [changeableParametersColor]="TunifyChangeableParameterColor.GREEN"
      class="content"
    ></tun-changeable-parameters-panel-v5>

    <div *ngIf="isPlaylist"
      class="content playlistContent">

      <mat-slide-toggle
          class="toggleSlider"
          [checked]="calendarItem.shufflePlaylist"
          [disabled]="disabled"
          (change)="onToggleShuffle($event)">
          {{'calendars.edit.playlist.popup.randomOrder' | translate}}
      </mat-slide-toggle>

    </div>

  </ng-container>



</div>
