import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { TunifyCheckBoxColor } from '@components/components-v5/checkbox-v5/checkbox-v5.component';
import { ZoneConfigurationService } from '@service/zone-configuration.service';

@Component({
  selector: 'tun-settings-language-panel',
  templateUrl: './settings-language-panel.component.html',
  styleUrls: ['./settings-language-panel.component.scss']
})
export class SettingsLanguagePanelComponent {

  public languages = this.zoneConfigurationService.ALLOWED_LANGUAGES;

  public TunifyCheckBoxColor = TunifyCheckBoxColor

  public translateableLanguageString(language: string){
    return "settings.language." + language
  }

  public isChecked(language: string){
    return this.zoneConfigurationService.language == language
  }

  @Output() back = new EventEmitter<void>();

  constructor(
    private zoneConfigurationService: ZoneConfigurationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

  }

  onBack(){
    this.back.emit();
  }

  checkedChanged(language: string, checked: boolean){
    if (checked){
      this.zoneConfigurationService.changeLanguage(language);
    }

  }
}
