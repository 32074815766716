import { Injectable } from '@angular/core';
import { Playlist } from '@model/playlist';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from '@service/config';
import { LoggerService } from '@service/loggers/logger.service';
import { HttpClient } from '@angular/common/http';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';

export class DTO_LoadPlaylistsResponse{
  timestamp: number;
  customPlaylists: Playlist[];
}

export class DTO_CreatePlaylistRequest{
  customPlaylistsTimestamp: number;
  playlist: DTO_SavePlaylist_Playlist;
}

export class DTO_CreatePlaylistResponse{
  customPlaylistsTimestamp: number;
  playlist: Playlist;
}

export class DTO_DeletePlaylistRequest{
  customPlaylistsTimestamp: number;
}

export class DTO_DeletePlaylistResponse{
  customPlaylistsTimestamp: number;
}

export class DTO_SavePlaylistResponse{
  timestamp: number;
}

export class DTO_SavePlaylist_Playlist{
  id: number;
  title: string;
  '@type': string = 'Playlist';
  timestamp: number;
  audioFiles: DTO_SavePlaylist_Track[];
}

export class DTO_SavePlaylist_Track{
  id: number;
  '@type': string;
}


@Injectable({
  providedIn: 'root'
})
export class PlaylistApiService {

  private LOGGER_CLASSNAME = 'PlaylistApiService';

  constructor(private httpClient: HttpClient,
    private zoneConnectionsService: ZoneConnectionsService,
    private loggerService: LoggerService) { }

  public loadPlaylists(): Observable<DTO_LoadPlaylistsResponse>{
    let resultObservable: Observable<DTO_LoadPlaylistsResponse>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadPlaylists', 'about to mock loadPlaylists request');
      resultObservable = this.httpClient.get<DTO_LoadPlaylistsResponse>('api/customPlaylists');
    }else{
      if (this.zoneConnectionsService.activeZoneConnection != null){
        const url = Config.api4Url_playlists(this.zoneConnectionsService.activeZoneConnection.zoneId);
        resultObservable = this.httpClient.get<DTO_LoadPlaylistsResponse>(url);
      }

    }

    return resultObservable;
  }

  public loadFavorites(): Observable<Playlist>{
    let resultObservable: Observable<Playlist>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadFavorites', 'about to mock loadFavorites request');
      resultObservable = this.httpClient.get<Playlist>('api/favorites');
    }else{
      if (this.zoneConnectionsService.activeZoneConnection != null){
        const url = Config.api4Url_favoritelist(this.zoneConnectionsService.activeZoneConnection.zoneId);
        resultObservable = this.httpClient.get<Playlist>(url);
      }

    }

    return resultObservable;
  }

  public loadBanlist(): Observable<Playlist>{
    let resultObservable: Observable<Playlist>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadBanlist', 'about to mock loadBanlist request');
      resultObservable = this.httpClient.get<Playlist>('api/banlist');
    }else{
      if (this.zoneConnectionsService.activeZoneConnection != null){
        const url = Config.api4Url_banlist(this.zoneConnectionsService.activeZoneConnection.zoneId);
        resultObservable = this.httpClient.get<Playlist>(url);
      }

    }

    return resultObservable;
  }

  public savePlaylist(playlist: Playlist, includeAudioFiles: boolean): Observable<DTO_SavePlaylistResponse>{
    let resultObservable: Observable<DTO_SavePlaylistResponse>;

    if (playlist){

      if (playlist.id == null || playlist.id == 0){
        //create playlist must return the playlist
        this.loggerService.error(this.LOGGER_CLASSNAME, 'savePlaylist', 'you can not save playlists without an id (or with id 0). Use createPlaylist.');
      }else{
        if (environment.mockBackend){
          this.loggerService.info(this.LOGGER_CLASSNAME, 'savePlaylist', 'about to mock savePlaylist request');

          const resultSubject = new Subject<DTO_SavePlaylistResponse>();
          resultObservable = resultSubject;
          // simulate 2 seconds request
          setTimeout(() => {
            if (Math.random() > 0.2) {
              const response = new DTO_SavePlaylistResponse();
              response.timestamp = playlist.timestamp + 1;
              resultSubject.next(response);
              resultSubject.complete();
            } else {
              // simulate 20% error chance
              resultSubject.error('Simulate error');
            }
          }, 2000);

        }else{
          if (this.zoneConnectionsService.activeZoneConnection){

            const saveable_playlist = this.createSaveablePlaylist(playlist, includeAudioFiles);

            const url = Config.api4Url_savePlaylist(this.zoneConnectionsService.activeZoneConnection.zoneId, playlist.id);
            resultObservable = this.httpClient.post<DTO_SavePlaylistResponse>(url, saveable_playlist);
          }
        }
      }

    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'savePlaylist', 'no playlist as input - nothing to save');
    }


    return resultObservable;
  }


  public createPlaylist(playlist: Playlist, customPlaylistsTimestamp: number): Observable<DTO_CreatePlaylistResponse>{
    let resultObservable: Observable<DTO_CreatePlaylistResponse>;

    if (playlist){

      if (playlist.id == null || playlist.id == 0){
        if (environment.mockBackend){
          this.loggerService.info(this.LOGGER_CLASSNAME, 'createPlaylist', 'about to mock createPlaylist request');

          const resultSubject = new Subject<DTO_CreatePlaylistResponse>();
          resultObservable = resultSubject;
          // simulate 2 seconds request
          setTimeout(() => {
            if (Math.random() > 0.2) {

              //simulate new id
              playlist.id = Math.floor(Math.random() * 10000);
              const response = new DTO_CreatePlaylistResponse();
              response.customPlaylistsTimestamp = customPlaylistsTimestamp + 1;
              response.playlist = playlist
              resultSubject.next(response);
              resultSubject.complete();
            } else {
              // simulate 20% error chance
              resultSubject.error('Simulate error');
            }
          }, 2000);

        }else{
          if (this.zoneConnectionsService.activeZoneConnection){

            const saveable_playlist = this.createSaveablePlaylist(playlist, true);
            const requestBody = new DTO_CreatePlaylistRequest();
            requestBody.customPlaylistsTimestamp = customPlaylistsTimestamp;
            requestBody.playlist = saveable_playlist;

            const url = Config.api4Url_playlists(this.zoneConnectionsService.activeZoneConnection.zoneId);
            resultObservable = this.httpClient.post<DTO_CreatePlaylistResponse>(url, requestBody);
          }
        }
      }else{
         //save existing playlist -> use different method
         this.loggerService.error(this.LOGGER_CLASSNAME, 'createPlaylist', 'you can not create an already existing playlist. Use savePlaylist.');
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'savePlaylist', 'no playlist as input - nothing to save');
    }


    return resultObservable;
  }

  public deletePlaylist(playlist: Playlist, customPlaylistsTimestamp: number): Observable<DTO_DeletePlaylistResponse>{
    let resultObservable: Observable<DTO_DeletePlaylistResponse>;

    if (playlist){

      if (playlist.id == null || playlist.id == 0){
        this.loggerService.error(this.LOGGER_CLASSNAME, 'deletePlaylist', 'you can not delete a playlist without id');


      }else{
        if (environment.mockBackend){
          this.loggerService.info(this.LOGGER_CLASSNAME, 'deletePlaylist', 'about to mock deletePlaylist request');

          const resultSubject = new Subject<DTO_DeletePlaylistResponse>();
          resultObservable = resultSubject;
          // simulate 2 seconds request
          setTimeout(() => {
            if (Math.random() > 0.2) {
              const response = new DTO_DeletePlaylistResponse();
              response.customPlaylistsTimestamp = customPlaylistsTimestamp + 1;
              resultSubject.next(response);
              resultSubject.complete();
            } else {
              // simulate 20% error chance
              resultSubject.error('Simulate error');
            }
          }, 2000);

        }else{
          if (this.zoneConnectionsService.activeZoneConnection){

            const requestBody = new DTO_DeletePlaylistRequest();
            requestBody.customPlaylistsTimestamp = customPlaylistsTimestamp;

            const url = Config.api4Url_deletePlaylist(this.zoneConnectionsService.activeZoneConnection.zoneId, playlist.id);
            resultObservable = this.httpClient.delete<DTO_DeletePlaylistResponse>(url, {body: requestBody});

          }
        }
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'deletePlaylist', 'no playlist as input - nothing to delete');
    }


    return resultObservable;
  }

  private createSaveablePlaylist(playlist: Playlist, includeAudioFiles: boolean): DTO_SavePlaylist_Playlist{
    //transform object
    const saveable_playlist = new DTO_SavePlaylist_Playlist();
    saveable_playlist.id = playlist.id;
    saveable_playlist.title = playlist.title;
    if (playlist.timestamp > 0){
      saveable_playlist.timestamp = playlist.timestamp;
    }
    if (playlist['@type'] != null){
      saveable_playlist['@type'] = playlist['@type'];
    }
    if (includeAudioFiles){
      saveable_playlist.audioFiles = [];
      if (playlist.audioFiles != null){
        playlist.audioFiles.forEach(audioFile=>{
          const saveable_track = new DTO_SavePlaylist_Track();
          saveable_track.id = audioFile.id;
          saveable_track['@type'] = audioFile['@type'];
          saveable_playlist.audioFiles.push(saveable_track);
        })
      }
    }

    return saveable_playlist;
  }

}
