<div class="wrapper">

  <div class="contentWrapper">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <div class="title">
          {{ 'settings.songrequest.title' | translate }}
        </div>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- normal content -->
    <div class="scrollContainer">
      <div class="real-content-container">

        <form [formGroup]="userForm" class="form" (ngSubmit)="submitForm()">

          <div class="form-group">
          <label class="form-label" for="title" translate>settings.songrequest.field.title</label>
          <input [disabled]="sendingSongRequest" type="text" class="text-input" id="title" formControlName="title">
          <div  class="text-danger">
            <ng-container *ngIf="userForm?.get('title')?.invalid && userForm?.get('title')?.touched">
              {{'settings.songrequest.field.error.required.general' | translate}}
            </ng-container>
          </div>
        </div>


        <div class="form-group">
          <label class="form-label" for="group" translate>settings.songrequest.field.group</label>
          <input [disabled]="sendingSongRequest" type="text" class="text-input" id="group" formControlName="group">
          <div  class="text-danger">
            <ng-container *ngIf="userForm?.get('group')?.invalid && userForm?.get('group')?.touched">
              {{'settings.songrequest.field.error.required.general' | translate}}
            </ng-container>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="remark" translate>settings.songrequest.field.info</label>
          <textarea [disabled]="sendingSongRequest" type="text" class="text-input text-area" id="remark" formControlName="remark"></textarea>
        </div>

        <div class="submit-group">
          <button type="submit" [disabled]="!this.userForm?.valid || sendingSongRequest" class="commit-button" translate>settings.songrequest.button.submit</button>
          <div *ngIf="sendingSongRequest" class="sending-container">
            <mat-spinner class="sending-spinner"></mat-spinner>
            <span class="sending-text">{{'settings.songrequest.status.sending' | translate}}</span>
          </div>
        </div>

      </form>

      </div>
    </div>


</div>
