import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { TweakStatus } from '@model/enums/tweakStatus';
import {  Subject } from 'rxjs';

interface TweakStrengthBarV5 {
  selected?: boolean;
}
@Component({
  selector: 'tun-tweak-strength-bar-v5',
  templateUrl: './tweak-strength-bar-v5.component.html',
  styleUrls: ['./tweak-strength-bar-v5.component.scss']
})
export class TweakStrengthBarV5Component implements AfterViewInit, OnDestroy {

  @Input() amountOfBars = 10;

  // === Props === //
  @Input() set percentage(value: number) {
    if (value > 0) {
      const selectedBars = Array<TweakStrengthBarV5>(
        Math.floor(this.amountOfBars * (value / 100))
      ).fill({ selected: true });
      this._tweakStrengthBars = selectedBars.concat(
        Array(this.amountOfBars - selectedBars.length).fill({})
      );
    } else {
      this._tweakStrengthBars = Array(this.amountOfBars).fill({});
    }
  }

  @Input() status = TweakStatus.NORMAL;

  // === State === //
  private _tweakStrengthBars: TweakStrengthBarV5[] = [];

  // === Observables === //
  private _destroy$ = new Subject<void>();

  // === Getters === //
  get tweakStrengthBars() {
    return this._tweakStrengthBars;
  }

  get statusClass(): string {
    switch (this.status) {
      case TweakStatus.WARNING:
        return 'warning';
      case TweakStatus.ERROR:
        return 'error';
      default:
        return 'normal';
    }
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }

}
