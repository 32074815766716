import {
  animate,
  style,
  transition,
  trigger,
  animation,
  state,
} from '@angular/animations';

const DEFAULT_ANIMATION_DURATION = 300;

const translateOffset = 100;
const base = 0;

export const viewPositionAnimation = trigger('viewPosition', [
  state('left', style({
    transform: `translateX(-100%)`
  })),
  state('mid', style({
    transform: `translateX(0%)`
  })),
  state('right', style({
    transform: `translateX(100%)`
  })),
  transition('mid <=> right', [
    animate(
      `300ms ease-in-out`
    ),
  ]),
  transition('left <=> mid', [
    animate(
      `300ms ease-in-out`
    ),
  ]),
])

export const carouselAnimation = trigger('carousel', [
  transition('void => prev', [
    style({ transform: `translateX(${translateOffset * -1}%)` }),
    animate(
      `${('{{ duration }}' || DEFAULT_ANIMATION_DURATION)}ms ease-in-out`,
      style({
        transform: `translateX(${base})`,
      })
    ),
  ]),
  transition('prev => void', [
    style({ transform: `translateX(${base})` }),
    animate(
      `${('{{ duration }}' || DEFAULT_ANIMATION_DURATION)}ms ease-in-out`,
      style({
        transform: `translateX(${translateOffset}%)`,
      })
    ),
  ]),
  transition('void => next', [
    style({ transform: `translateX(${translateOffset}%)` }),
    animate(
      `${('{{ duration }}' || DEFAULT_ANIMATION_DURATION)}ms ease-in-out`,
      style({
        transform: `translateX(${base})`,
      })
    ),
  ]),
  transition('next => void', [
    style({ transform: `translateX(${base})` }),
    animate(
      `${('{{ duration }}' || DEFAULT_ANIMATION_DURATION)}ms ease-in-out`,
      style({
        transform: `translateX(${translateOffset * -1}%)`,
      })
    ),
  ]),
]);


export const carouselYAnimation = trigger('carouselY', [
  transition('void => down', [
    style({ transform: `translateY(${translateOffset * -1}%)` }),
    animate(
      `${('{{ duration }}' || DEFAULT_ANIMATION_DURATION)}ms ease-in-out`,
      style({
        transform: `translateY(${base})`,
      })
    ),
  ]),
  transition('down => void', [
    style({ transform: `translateY(${base})` }),
    animate(
      `${('{{ duration }}' || DEFAULT_ANIMATION_DURATION)}ms ease-in-out`,
      style({
        transform: `translateY(${translateOffset}%)`,
      })
    ),
  ]),
  transition('void => up', [
    style({ transform: `translateY(${translateOffset}%)` }),
    animate(
      `${('{{ duration }}' || DEFAULT_ANIMATION_DURATION)}ms ease-in-out`,
      style({
        transform: `translateY(${base})`,
      })
    ),
  ]),
  transition('up => void', [
    style({ transform: `translateY(${base})` }),
    animate(
      `${('{{ duration }}' || DEFAULT_ANIMATION_DURATION)}ms ease-in-out`,
      style({
        transform: `translateY(${translateOffset * -1}%)`,
      })
    ),
  ]),
]);


// TODO: check the animations in the tweak panel (awaiting Sam's refactoring)
export const openCloseTweakPanel = trigger('slideInOut', [
  transition(':enter', [
    style({
      width: '{{ width }}px',
      height: '{{ height }}px',
      top: 'calc({{ top }}px + 1rem)',
      left: 'calc({{ left }}px + 1rem)',
    }),
    animate(
      '400ms ease-in',
      style({
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '400ms ease-in',
      style({
        width: '{{ width }}px',
        height: '{{ height }}px',
        top: 'calc({{ top }}px + 1rem)',
        left: 'calc({{ left }}px + 1rem)',
      })
    ),
  ]),
]);

export const openCloseCalendarItemTweakPanel = trigger(
  'slideInOutCalendarItem',
  [
    transition(':enter', [
      style({
        width: '{{ width }}px',
        height: '{{ height }}px',
        top: '{{ top }}px',
        left: '{{ left }}px',
      }),
      animate(
        '400ms ease-in',
        style({
          width: '100%',
          height: 'calc(100% - {{ heightPerItem }}px)',
          top: '0',
          left: '0',
        })
      ),
    ]),
    transition(':leave', [
      animate(
        '400ms ease-in',
        style({
          width: '{{ width }}px',
          height: '{{ height }}px',
          top: '{{ top }}px',
          left: '{{ left }}px',
        })
      ),
    ]),
  ]
);


/** Returns animation data to fade an element in or out (default is fade in).
 *
 * Optional parameters:
 * - duration: The duration of the animation in milliseconds, default is 300
 * - from: The opacity to start with, default is 0
 * - to: The opacity to end with, default is 1
 */
export const fadeAnimation = animation([
  style({ opacity: '{{ from }}'}),
  animate('{{ duration }}ms {{ delay }}ms ease-in-out', style({ opacity: '{{ to }}' }))
], {
  params: {
    from: 0,
    to: 1,
    duration: 300,
    delay: 0,
  }
});

/** Returns animation data to slide an element (default is slide in from right).
 *
 * Optional parameters:
 * - duration: The duration of the animation in milliseconds, default is 300.
 * - startX: The start value for translateX, default is 100%.
 * - endX: The end value for translateX, default is 0.
 */
export const slideAnimation = animation([
  style({ transform: 'translateX({{ startX }})' }),
  animate('{{ duration }}ms ease-in-out', style({ transform: 'translateX({{ endX }})' }))
], {
  params: {
    duration: 300,
    startX: '100%',
    endX: 0
  }
});

export const slideUpAnimation = animation([
  style({ transform: `translateY({{ startY }})` }),
  animate(
    `{{ duration }}ms ease-in-out`,
    style({
      transform: `translateY({{ endY }})`,
    })
  )
],{
  params: {
    duration: 300,
    startY: '100%',
    endY: '0'
  }
});
