import { Component, OnDestroy } from '@angular/core';
import { AppV5StateService, TunifyV5Tab } from '@service/app-v5/app-v5-state.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tun-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss']
})
export class TabMenuComponent implements OnDestroy {

  public TunifyV5Tab = TunifyV5Tab;

  public get selectedTab$(){
    return this.appV5StateService.selectedTab$;
  }

  public get enableHome(){
    return environment.enableHome;
  }

  constructor(
    private appV5StateService: AppV5StateService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }


  public onToggleTab(tab: TunifyV5Tab){
    this.appV5StateService.selectTunifyTab(tab);
  }
}
