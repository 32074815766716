<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 22 22" enable-background="new 0 0 22 22" xml:space="preserve">
   <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
    <g transform="translate(-432, -327)" stroke="currentColor" stroke-width="2">
        <g transform="translate(402, 178)">
            <g transform="translate(31, 150)">
                <path d="M20,15.6527273 L20,17.3918182 C19.9994983,18.8320693 18.8320693,19.9994983 17.3918182,20 L2.60818182,20 C1.16772278,20 0,18.8322772 0,17.3918182 L0,15.6527273"></path>
                <line x1="10" y1="15.5527273" x2="10" y2="0"></line>
                <polyline points="16.3636364 9.18909091 10 15.5527273 3.63636364 9.18909091"></polyline>
            </g>
        </g>
    </g>
</g>
</svg>
