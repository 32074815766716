import { Component } from '@angular/core';

@Component({
  selector: 'tun-pause-icon-v5',
  templateUrl: './pause-icon-v5.component.html',
  styleUrls: ['./pause-icon-v5.component.scss']
})
export class PauseIconV5Component {

}
