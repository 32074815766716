import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { TunifyColor } from '@model/enums/tunifyColor.enum';

export enum TunifyCheckBoxColor {
  GREEN = "Green",
  BLUE = "Blue",
  ORANGE = "Orange",
  WHITE = "White",
  BLACK = "black"
}

@Component({
  selector: 'tun-checkbox-v5',
  templateUrl: './checkbox-v5.component.html',
  styleUrls: ['./checkbox-v5.component.scss']
})
export class CheckboxV5Component {
  // === Props === //
  @Input() title: string;
  @Input() coloredTitleOnCheck = false;

  @Input() isChecked: boolean;
  @Input() indeterminate: boolean;

  @Input() disabled: boolean = false

  @Input() checkBoxColor: TunifyCheckBoxColor

  // === Emitters === //
  @Output() isCheckedChanged = new EventEmitter();

  get checkboxColorClass(): string{
    switch (this.checkBoxColor) {
      case TunifyCheckBoxColor.BLUE:
        return 'blue-checkbox';
      case TunifyCheckBoxColor.GREEN:
        return 'green-checkbox';
      case TunifyCheckBoxColor.ORANGE:
        return 'orange-checkbox';
      case TunifyCheckBoxColor.BLACK:
        return 'black-checkbox';
      default:
        return 'white-checkbox';
    }
  }

  constructor(
    public cdRef: ChangeDetectorRef,
    public ngZone: NgZone
  ) {}


  toggleChecked() {
    this.isChecked = !this.isChecked;
    this.isCheckedChanged.emit(this.isChecked);
  }

}
