import { Component } from '@angular/core';

@Component({
  selector: 'tun-menu-playlist-icon-v5',
  templateUrl: './menu-playlist-icon-v5.component.html',
  styleUrls: ['./menu-playlist-icon-v5.component.scss']
})
export class MenuPlaylistIconV5Component {

}
