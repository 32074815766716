import { Component, Input } from '@angular/core';

@Component({
  selector: 'tun-access-right-overlay-view',
  templateUrl: './access-right-overlay-view.component.html',
  styleUrls: ['./access-right-overlay-view.component.scss']
})
export class AccessRightOverlayViewComponent {

  @Input() message = "";

  @Input() smallMode = false;

}
