import { Injectable, OnDestroy } from '@angular/core';
import { TrackApiService } from '@service/api/track-api.service';
import { LoggerService } from '@service/loggers/logger.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { TrackCommand } from '@service/vo/remote/remote-objects';
import { TrackOrigin } from '../../model/trackOrigin';
import { ApplicationMode, ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { PlayStateService } from './play-state.service';


/*
export function stringToTrackCommand(commandString: string){
  if (commandString != null && commandString.toLowerCase() == TrackCommand.play.toLowerCase()){
    return TrackCommand.play;
  }else if (commandString != null && commandString.toLowerCase() == TrackCommand.addFirstInQueue.toLowerCase()){
    return TrackCommand.addFirstInQueue;
  }
  return null;
}
*/

@Injectable({
  providedIn: 'root'
})
export class TrackActionService implements OnDestroy{

  private LOGGER_CLASSNAME = 'TrackActionService';

  constructor(
    private trackApiService: TrackApiService,
    private loggerService: LoggerService,
    private zoneConnectionsService: ZoneConnectionsService,
    private playStateService: PlayStateService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public performTrackCommand(trackId: number, trackOrigin: TrackOrigin, command: TrackCommand){
    if (this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode){

      const trackObservable = this.trackApiService.searchTrack(trackId);
      if (trackObservable){
        trackObservable
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (data) => {
            const track = createClassObjectForAudioFile(data);
            track.origin = trackOrigin;
            if (command == TrackCommand.play){
              this.playStateService.startNextAudioFile(track);
            }else{
              this.loggerService.error(this.LOGGER_CLASSNAME, "performTrackCommand", "Command " + command + " not implemented");
            }
          }
        )
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "performTrackCommand", "Could not start search for trackId " + trackId);
      }
    }
  }

}
