export const calendar_35773 ={
	"id": 35773,
	"calendarId": 35773 ,
    "name": "Test 2",
    "calendarGroupId": 0,
    "isPublished": false,
    "timestamp": 123456789,
    "calendarItems": [
        {
            "id": 2954709,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 34611 ,
            "cronString": "0 30 1 ? * 5",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954720,
            "title": "Dream Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8779,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1987,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1987,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1987,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1987,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 8 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954784,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 12 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954785,
            "title": "Lite Cosy",
            "musicChannelId": 133,
            "musicCollectionID": 8125,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Classical",
                            "selected": false
                        },
                        {
                            "value": "Jazz",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Voice",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Voice",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": false
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Classical",
                            "selected": true
                        },
                        {
                            "value": "Jazz",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Voice",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Voice",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 7 ? * 2",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954786,
            "title": "Dream Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8779,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1987,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1987,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1987,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1987,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 8 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954787,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2016,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 6 ? * 2",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954788,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 0 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954789,
            "title": "Best Of The 90's",
            "musicChannelId": 142,
            "musicCollectionID": 8177,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 14 ? * 2",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954790,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 0 0 ? * 2",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": true
        },
        {
            "id": 2954792,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 9 ? * 2",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954793,
            "title": "Soulful",
            "musicChannelId": 153,
            "musicCollectionID": 8210,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 13 ? * 2",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954794,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 17 ? * 2",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954795,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 11 ? * 2",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954796,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 5 ? * 2",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954797,
            "title": "Hit Parade Pop",
            "musicChannelId": 142,
            "musicCollectionID": 8619,
            "changeableParameter": null,
            "defaultChangeableParameter": null,
            "calendarID": 34611 ,
            "cronString": "0 30 1 ? * 2",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954798,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 2 ? * 2",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954799,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 15 ? * 2",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954802,
            "title": "Down Beat",
            "musicChannelId": 124,
            "musicCollectionID": 8781,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 23 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954803,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 22 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954806,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 11 ? * 3",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954807,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 4 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        
        {
            "id": 2954823,
            "title": "Easy Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8040,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 18 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954825,
            "title": "Deep Instrumental",
            "musicChannelId": 192,
            "musicCollectionID": 8123,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 122,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 19 ? * 3",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954826,
            "title": "Down Beat",
            "musicChannelId": 124,
            "musicCollectionID": 8781,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 23 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954842,
            "title": "Dream Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8779,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1987,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1987,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1987,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1987,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 8 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954848,
            "title": "Soul R&B",
            "musicChannelId": 153,
            "musicCollectionID": 8206,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 1,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 20 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954850,
            "title": "Minimal House",
            "musicChannelId": 192,
            "musicCollectionID": 8019,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 23 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954851,
            "title": "Down Beat",
            "musicChannelId": 124,
            "musicCollectionID": 8781,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 21 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954852,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 22 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954853,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 13 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954854,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 9 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954855,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 4 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954857,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 5 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954858,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 11 ? * 4",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954859,
            "title": "Soulful",
            "musicChannelId": 153,
            "musicCollectionID": 8210,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 7 ? * 4",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954860,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 3 ? * 4",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954862,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 15 ? * 4",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": true
        },
        {
            "id": 2954863,
            "title": "Best Of The 90's",
            "musicChannelId": 142,
            "musicCollectionID": 8177,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 14 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954864,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 0 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954866,
            "title": "Dream Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8779,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1987,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1987,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1987,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1987,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 8 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954868,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 10 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954869,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 12 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954870,
            "title": "Soul Pop",
            "musicChannelId": 153,
            "musicCollectionID": 8211,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1965,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1965,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1965,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1965,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 16 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954871,
            "title": "Easy Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8040,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 18 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954872,
            "title": "Soul R&B",
            "musicChannelId": 153,
            "musicCollectionID": 8206,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 1,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 20 ? * 4",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954873,
            "title": "Deep Instrumental",
            "musicChannelId": 192,
            "musicCollectionID": 8123,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 123,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 17 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954877,
            "title": "Bossa Nova",
            "musicChannelId": 192,
            "musicCollectionID": 8033,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 19 ? * 4",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954749,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 15 ? * 6",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954705,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 19 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954706,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 3 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954824,
            "title": "Soul R&B",
            "musicChannelId": 153,
            "musicCollectionID": 8206,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 1,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 1,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "1",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 20 ? * 3",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954722,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 12 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954732,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 12 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954800,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 3 ? * 2",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        
        {
            "id": 2954856,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 0 0 ? * 4",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224538,
            "title": "Deep Instrumental",
            "musicChannelId": 192,
            "musicCollectionID": 8123,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 119,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 2 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224539,
            "title": "Deep Instrumental",
            "musicChannelId": 192,
            "musicCollectionID": 8123,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 119,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 2 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224540,
            "title": "Lounge Bubbles",
            "musicChannelId": 140,
            "musicCollectionID": 8326,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Jazz",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Jazz",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 1 ? * 4",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3224541,
            "title": "Lounge Bubbles",
            "musicChannelId": 140,
            "musicCollectionID": 8326,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Jazz",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Jazz",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 1 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3224542,
            "title": "Groovy",
            "musicChannelId": 281,
            "musicCollectionID": 8828,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 2 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224543,
            "title": "Groovy",
            "musicChannelId": 281,
            "musicCollectionID": 8828,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 2 ? * 3",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224544,
            "title": "Groovy",
            "musicChannelId": 281,
            "musicCollectionID": 8828,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 2 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224545,
            "title": "Groovy",
            "musicChannelId": 281,
            "musicCollectionID": 8828,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 4 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3224546,
            "title": "Groovy",
            "musicChannelId": 281,
            "musicCollectionID": 8828,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Lounge",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 4 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3224547,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 16 ? * 2",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3224548,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 16 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 3224549,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 3 ? * 1",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224550,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 3 ? * 3",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224551,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 3 ? * 6",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224552,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 6 ? * 4",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 3224553,
            "title": "Urban Grooves",
            "musicChannelId": 281,
            "musicCollectionID": 8859,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Rhythm",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Rhythm",
                    "value": [
                        {
                            "value": "Swingbeat",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 6 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954845,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 12 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954757,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 12 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954708,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 2 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954831,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 4 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954746,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 4 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954771,
            "title": "Cosy",
            "musicChannelId": 134,
            "musicCollectionID": 8005,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 4 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954776,
            "title": "Minimal House",
            "musicChannelId": 192,
            "musicCollectionID": 8019,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Version",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Version",
                    "value": [
                        {
                            "value": "Original",
                            "selected": true
                        },
                        {
                            "value": "Remix",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 23 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954752,
            "title": "Bossa Nova",
            "musicChannelId": 192,
            "musicCollectionID": 8033,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 19 ? * 7",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954876,
            "title": "Bossa Nova",
            "musicChannelId": 192,
            "musicCollectionID": 8033,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 21 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954875,
            "title": "Bossa Nova",
            "musicChannelId": 192,
            "musicCollectionID": 8033,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 21 ? * 3",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954727,
            "title": "Bossa Nova",
            "musicChannelId": 192,
            "musicCollectionID": 8033,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 21 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954874,
            "title": "Bossa Nova",
            "musicChannelId": 192,
            "musicCollectionID": 8033,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 22 ? * 2",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954726,
            "title": "Bossa Nova",
            "musicChannelId": 192,
            "musicCollectionID": 8033,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Vocal Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Vocal Style",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 22 ? * 5",
            "duration": 60,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954847,
            "title": "Easy Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8040,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 18 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954724,
            "title": "Easy Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8040,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 18 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954730,
            "title": "Easy Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8040,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 18 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954755,
            "title": "Easy Pop",
            "musicChannelId": 124,
            "musicCollectionID": 8040,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2005,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Emotion",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Emotion",
                    "value": [
                        {
                            "value": "Emotional",
                            "selected": true
                        },
                        {
                            "value": "Neutral",
                            "selected": true
                        },
                        {
                            "value": "Sentimental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 18 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954840,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 0 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954718,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 0 ? * 5",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954737,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 0 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954762,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 0 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954712,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 17 ? * 5",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954753,
            "title": "Deep Instrumental",
            "musicChannelId": 192,
            "musicCollectionID": 8123,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 123,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 17 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954849,
            "title": "Deep Instrumental",
            "musicChannelId": 192,
            "musicCollectionID": 8123,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 122,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 19 ? * 1",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954768,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 11 ? * 7",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954834,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 13 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954743,
            "title": "Best Of The 80's",
            "musicChannelId": 142,
            "musicCollectionID": 8108,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 13 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954728,
            "title": "Deep Instrumental",
            "musicChannelId": 192,
            "musicCollectionID": 8123,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 122,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1991,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1991,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 130,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 130,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 19 ? * 6",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954721,
            "title": "Lite Cosy",
            "musicChannelId": 133,
            "musicCollectionID": 8125,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Classical",
                            "selected": false
                        },
                        {
                            "value": "Jazz",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Voice",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Voice",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": false
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Feel",
                    "value": [
                        {
                            "value": "Classical",
                            "selected": true
                        },
                        {
                            "value": "Jazz",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Voice",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Voice",
                    "value": [
                        {
                            "value": "Instrumental",
                            "selected": true
                        },
                        {
                            "value": "Not Instrumental",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 7 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954716,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 0 0 ? * 5",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": true
        },
        {
            "id": 2954764,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 15 ? * 7",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": true
        },
        {
            "id": 2954838,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 17 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": true
        },
        {
            "id": 2954739,
            "title": "Best Of",
            "musicChannelId": 134,
            "musicCollectionID": 8149,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 5,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "5"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 17 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": true
        },
        {
            "id": 2954829,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 0 0 ? * 1",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954748,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 0 0 ? * 6",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954711,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 11 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954773,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 13 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954828,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 5,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 15 ? * 1",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954769,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 5 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954833,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 7 ? * 1",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954744,
            "title": "Best Of The 10's",
            "musicChannelId": 142,
            "musicCollectionID": 8176,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2010,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 2010,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 7 ? * 6",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954839,
            "title": "Best Of The 90's",
            "musicChannelId": 142,
            "musicCollectionID": 8177,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 14 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954717,
            "title": "Best Of The 90's",
            "musicChannelId": 142,
            "musicCollectionID": 8177,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 14 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954738,
            "title": "Best Of The 90's",
            "musicChannelId": 142,
            "musicCollectionID": 8177,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 14 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954763,
            "title": "Best Of The 90's",
            "musicChannelId": 142,
            "musicCollectionID": 8177,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": false
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "NA",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Latin",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 1999,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 1999,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 14 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954841,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2016,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 6 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954719,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2016,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 6 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954736,
            "title": "Soul & Funk",
            "musicChannelId": 134,
            "musicCollectionID": 8204,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1975,
                    "upperSelection": 2016,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 6 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954713,
            "title": "Soulful",
            "musicChannelId": 153,
            "musicCollectionID": 8210,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 13 ? * 5",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954767,
            "title": "Soulful",
            "musicChannelId": 153,
            "musicCollectionID": 8210,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 7 ? * 7",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954835,
            "title": "Soulful",
            "musicChannelId": 153,
            "musicCollectionID": 8210,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 9 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954811,
            "title": "Soulful",
            "musicChannelId": 153,
            "musicCollectionID": 8210,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 9 ? * 3",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954742,
            "title": "Soulful",
            "musicChannelId": 153,
            "musicCollectionID": 8210,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1964,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1964,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 9 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954846,
            "title": "Soul Pop",
            "musicChannelId": 153,
            "musicCollectionID": 8211,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1965,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1965,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1965,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1965,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 16 ? * 1",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954731,
            "title": "Soul Pop",
            "musicChannelId": 153,
            "musicCollectionID": 8211,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1965,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1965,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1965,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1965,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 16 ? * 6",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954756,
            "title": "Soul Pop",
            "musicChannelId": 153,
            "musicCollectionID": 8211,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1965,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1965,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1965,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1965,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 3,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "3",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 16 ? * 7",
            "duration": 30,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954766,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 3 ? * 7",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954836,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 5 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954741,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 5 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954714,
            "title": "R&B",
            "musicChannelId": 134,
            "musicCollectionID": 8311,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 4,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 9 ? * 5",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954844,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 10 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954777,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 10 ? * 5",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954733,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 10 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954758,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 10 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954827,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 22 ? * 1",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954750,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 22 ? * 6",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954774,
            "title": "2014 - 2018",
            "musicChannelId": 134,
            "musicCollectionID": 8399,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 22 ? * 7",
            "duration": 30,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954770,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 0 0 ? * 7",
            "duration": 120,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954832,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 1 ? * 1",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954745,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 1 ? * 6",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954830,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 11 ? * 1",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954747,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 11 ? * 6",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954707,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 15 ? * 5",
            "duration": 150,
            "position": 1,
            "shufflePlaylist": false
        },
        {
            "id": 2954710,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 5 ? * 5",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        },
        {
            "id": 2954772,
            "title": "Pop",
            "musicChannelId": 150,
            "musicCollectionID": 8556,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": false
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": false
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": false
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Language",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Language",
                    "value": [
                        {
                            "value": "Dutch",
                            "selected": true
                        },
                        {
                            "value": "English",
                            "selected": true
                        },
                        {
                            "value": "ALL_OTHERS",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1960,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1960,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": true
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "calendarID": 34611 ,
            "cronString": "0 30 9 ? * 7",
            "duration": 150,
            "position": 2,
            "shufflePlaylist": false
        }
    ],
    "type": "Tunify",
    "isVisible": true
}