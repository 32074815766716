import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-mini-track-origin-view',
  templateUrl: './mini-track-origin-view.component.html',
  styleUrls: ['./mini-track-origin-view.component.scss']
})
export class MiniTrackOriginViewComponent {

  public get originTitle$(){
    return combineLatest([
      this.playingMusicSelectionService.lastRealplayingMusicSelection$,
      this.translateService.stream("player.origin.queue"),
      this.translateService.stream("player.origin.musicChannel"),
      this.translateService.stream("player.origin.calendar"),
      this.translateService.stream("player.origin.message")
    ])
    .pipe(
      map(([playingMusicSelection, waitingQueueLabel, musicChannelLabel, calendarLabel, messageLabel]) => {
        if (playingMusicSelection.origin == PlayingOrigin.musicChannel){
          if (playingMusicSelection.musicChannel != null){
            return playingMusicSelection.musicChannel.name
          }
          return musicChannelLabel
        }else if (playingMusicSelection.origin == PlayingOrigin.calendar){
          if (playingMusicSelection.calendar != null){
            return playingMusicSelection.calendar.name
          }
          return calendarLabel
        }else if (playingMusicSelection.origin == PlayingOrigin.waitingQueue){
          return waitingQueueLabel
        }else if (playingMusicSelection.origin == PlayingOrigin.message){
          return messageLabel
        }
        return ""
      })
    )
  }

  constructor(
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private translateService: TranslateService
    ) {

    }
}
