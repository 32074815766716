import { Injectable } from '@angular/core';
import { AuthenticationService } from '@service/authentication.service';
import { LoggerService } from '@service/loggers/logger.service';
import { Observable, Subject } from 'rxjs';
import { Calendar } from '@model/calendar';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Config } from '@service/config';
import { InMemoryDataService } from '@service/in-memory-data.service';
import { CalendarItem } from '@model/calendarItem';
import { map } from 'rxjs/operators';
import { ChangeableParameter } from '@model/changeableParameter';
import { VersionedResourceResponse } from './dto/versionedResourceResponse';
import { CalendarType } from '@model/enums/calendarType';

export class DTO_Calendar_Save{
  id: number;
  name: string;
  isVisible: boolean;
  timestamp: number;
}

export function createSaveableCalendar(calendar: Calendar): DTO_Calendar_Save{
  let calendar_Save = new DTO_Calendar_Save();
  calendar_Save.id = calendar.calendarId;
  calendar_Save.name = calendar.name;
  calendar_Save.isVisible = calendar.isVisible;
  calendar_Save.timestamp = calendar.timestamp;
  return calendar_Save;
}


//this could also contain calendarItems, but not needed for now
export class DTO_Calendar_Create{
  name: string;
  isVisible: boolean;
  timestamp: number;
  calendarGroupId: number;
  type: CalendarType;
  isPublished: boolean;
}

export function createCreateableCalendar(calendar: Calendar): DTO_Calendar_Create{
  let calendar_Create = new DTO_Calendar_Create();
  calendar_Create.name = calendar.name;
  calendar_Create.isVisible = true;
  calendar_Create.calendarGroupId = 0;
  calendar_Create.type = CalendarType.CUSTOM;
  calendar_Create.isPublished = false;
  calendar_Create.timestamp = calendar.timestamp;
  return calendar_Create;
}


export enum DTO_CalendarItemsAction{
  Add = "Add",
  Update = "Update",
  Delete = "Delete"
}

//a helper class for our manipulate method input
export class CalendarItemManipulationInput{
  action: DTO_CalendarItemsAction;
  calendarItem: CalendarItem;
}

export class DTO_CalendarItemsManipulation{
  action: DTO_CalendarItemsAction;
  calendarItem: DTO_CalendarItem_Save;
}

export class DTO_CalendarItemsManipulations{
  timestamp: number;
  manipulations: DTO_CalendarItemsManipulation[];
}

export class DTO_CalendarItem_Save{
  id: number;
  title: string;
  musicChannelId: number;
  musicCollectionId: number;
  calendarId: number;
  cronString: string;
  duration: number;
  position: number;
  shufflePlaylist: boolean;
  changeableParameter: ChangeableParameter[];
}

export function createSaveableCalendarItem(calendarItem: CalendarItem): DTO_CalendarItem_Save{
  let calendarItem_Create = new DTO_CalendarItem_Save();
  calendarItem_Create.id = calendarItem.id;
  calendarItem_Create.calendarId = calendarItem.calendarId;
  calendarItem_Create.cronString = calendarItem.cronString;
  calendarItem_Create.duration = calendarItem.duration;
  calendarItem_Create.musicChannelId = calendarItem.musicChannelId;
  calendarItem_Create.musicCollectionId = calendarItem.musicCollectionId;
  calendarItem_Create.position = calendarItem.position;
  calendarItem_Create.shufflePlaylist = calendarItem.shufflePlaylist;
  calendarItem_Create.title = calendarItem.title;

  calendarItem_Create.changeableParameter = calendarItem.changeableParameter;

  return calendarItem_Create;
}


export interface DTO_CalendarItemsManipulationsResponse extends VersionedResourceResponse{
  addedCalendarItems: CalendarItem[];
  updatedCalendarItems: CalendarItem[];
  deletedCalendarItemIds: number[];
}


class DTO_CalendarItem_Response {
  calendarItem: CalendarItem;
}

@Injectable({
  providedIn: 'root'
})
export class CalendarApiService {

  private LOGGER_CLASSNAME = 'CalendarApiService';

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService) { }

  public loadCustomCalendars(): Observable<Calendar[]>{
    let resultObservable: Observable<Calendar[]>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadUserCalendars', 'about to mock loadUserCalendars request');
      resultObservable = this.httpClient.get<Calendar[]>("api/customCalendar");
    }else{
      let url = Config.api4Url_customCalendars(this.authenticationService.zoneId);
      resultObservable = this.httpClient.get<Calendar[]>(url);
    }

    return resultObservable;
  }

  public createCalendar(calendar: DTO_Calendar_Create): Observable<Calendar>{
    let resultObservable: Observable<Calendar>;

    if (calendar){

      if (environment.mockBackend){
        this.loggerService.info(this.LOGGER_CLASSNAME, 'createCalendar', 'about to mock createCalendar request');



        // simulate 20% error chance
        if (Math.random() < 0.2) {
          let resultSubject = new Subject<Calendar>();
          resultObservable = resultSubject;

          this.loggerService.error(this.LOGGER_CLASSNAME, 'createCalendar', 'simulating createCalendar error');

          // simulate 2 seconds request
          setTimeout(() => {
            resultSubject.error("Simulate error");
          }, 2000);

        }else{
          //we could improve by changing the result with a random id and use the name from the request
          resultObservable = this.httpClient.get<Calendar>("api/createCalendar");
        }

      }else{
        if (this.authenticationService.loggedIn){

          let url = Config.api4Url_createCalendar(this.authenticationService.zoneId);
          resultObservable = this.httpClient.post<Calendar>(url, calendar);
        }
      }
    }
    return resultObservable;
  }

  public copyCalendar(calendarId: number): Observable<Calendar>{
    let resultObservable: Observable<Calendar>;

    if (calendarId > 0){

      if (environment.mockBackend){
        this.loggerService.info(this.LOGGER_CLASSNAME, 'copyCalendar', 'about to mock copyCalendar request');

        // simulate 20% error chance
        if (Math.random() < 0.2) {
          let resultSubject = new Subject<Calendar>();
          resultObservable = resultSubject;

          this.loggerService.error(this.LOGGER_CLASSNAME, 'copyCalendar', 'simulating copyCalendar error');

          // simulate 2 seconds request
          setTimeout(() => {
            resultSubject.error("Simulate error");
          }, 2000);

        }else{
          //we could improve by changing the result with a random id and use the name from the request
          resultObservable = this.httpClient.get<Calendar>("api/createCalendar");
        }

      }else{
        if (this.authenticationService.loggedIn){

          let url = Config.api4Url_copyCalendar(this.authenticationService.zoneId, calendarId);
          resultObservable = this.httpClient.post<Calendar>(url, null);
        }
      }
    }
    return resultObservable;
  }

  public saveCalendar(calendar: DTO_Calendar_Save): Observable<VersionedResourceResponse>{
    let resultObservable: Observable<VersionedResourceResponse>;

    if (calendar){

      if (environment.mockBackend){
        this.loggerService.info(this.LOGGER_CLASSNAME, 'saveCalendar', 'about to mock saveCalendar request');

        let resultSubject = new Subject<VersionedResourceResponse>();
        resultObservable = resultSubject;

        // simulate 20% error chance
        if (Math.random() < 0.2) {
          this.loggerService.error(this.LOGGER_CLASSNAME, 'createCalendarItem', 'simulating saveCalendar error');

          // simulate 2 seconds request
          setTimeout(() => {
            resultSubject.error("Simulate error");
          }, 2000);

        }else{
          setTimeout(() => {
            resultSubject.next({ timestamp: 1234567890, clientUpdateAdvised: false });
            resultSubject.complete();
          }, 2000);
        }

      }else{
        if (this.authenticationService.loggedIn){

          let url = Config.api4Url_calendar(this.authenticationService.zoneId, calendar.id);
          resultObservable = this.httpClient.post<VersionedResourceResponse>(url, calendar);
        }
      }
    }
    return resultObservable;
  }

  public deleteCalendar(calendar: Calendar): Observable<null>{
    let resultObservable: Observable<null>;

    if (calendar){

      if (environment.mockBackend){
        this.loggerService.info(this.LOGGER_CLASSNAME, 'deleteCalendar', 'about to mock deleteCalendar request');

        let resultSubject = new Subject<null>();
        resultObservable = resultSubject;

        // simulate 20% error chance
        if (Math.random() < 0.2) {
          this.loggerService.error(this.LOGGER_CLASSNAME, 'deleteCalendar', 'simulating deleteCalendar error');

          // simulate 2 seconds request
          setTimeout(() => {
            resultSubject.error("Simulate error");
          }, 2000);
        }else{
          setTimeout(() => {
            resultSubject.next(null);
            resultSubject.complete();

          }, 2000);
        }

      }else{
        if (this.authenticationService.loggedIn){

          let url = Config.api4Url_calendar(this.authenticationService.zoneId, calendar.calendarId);
          resultObservable = this.httpClient.delete<null>(url)
        }
      }
    }
    return resultObservable;
  }


  public loadCalendarItems(calendar: Calendar): Observable<Calendar>{
    let resultObservable: Observable<Calendar>;

    if (environment.mockBackend) {
      let calendarIdForAPI = calendar.calendarId;
      // we only have mocked data of a few calendars.
      // check if this calendar has mocked data, if not, load the default mocked data
      if (
        InMemoryDataService.mockedCalendarIds.indexOf(calendarIdForAPI) < 0
      ) {
        calendarIdForAPI = InMemoryDataService.defaultMockedCalendarId;
      }

      this.loggerService.info(
        this.LOGGER_CLASSNAME,
        'loadCalendarDetails',
        'about to mock loadCalendarItems request for calendar with id ' +
          calendar.calendarId +
          ' with mocked id ' +
          calendarIdForAPI
      );
      resultObservable = this.httpClient.get<Calendar>(
        'api/calendar/' + calendarIdForAPI
      );
    } else {
      const url = Config.api4Url_calendar(this.authenticationService.zoneId, calendar.calendarId);
      resultObservable = this.httpClient.get<Calendar>(url);
    }

    return resultObservable;
  }

  public loadCalendarItem(calendarId: number, calendarItemId: number): Observable<CalendarItem>{
    let resultObservable: Observable<CalendarItem>;

    if (environment.mockBackend) {
      const resultSubject = new Subject<CalendarItem>();
      resultObservable = resultSubject;

      this.loggerService.error(this.LOGGER_CLASSNAME, 'loadCalendarItem', 'simulating loadCalendarItem error (not implemented)');

      // simulate 0.2 seconds request
      setTimeout(() => {
        resultSubject.error("Simulate error");
      }, 200);
    } else {
      const url = Config.api4Url_calendarItem(this.authenticationService.zoneId, calendarId, calendarItemId);
      resultObservable = this.httpClient.get<CalendarItem>(url);
    }

    return resultObservable;
  }




  public manipulateCalendarItems(calendar: Calendar, manipulationsInput: CalendarItemManipulationInput[]): Observable<DTO_CalendarItemsManipulationsResponse>{

    let manipulations: DTO_CalendarItemsManipulations = new DTO_CalendarItemsManipulations();
    manipulations.timestamp = calendar.timestamp;
    manipulations.manipulations = [];
    manipulationsInput.forEach(calendarItemManipulationInput => {
      let manipulation = new DTO_CalendarItemsManipulation();
      manipulation.action = calendarItemManipulationInput.action;
      //we could optimize -> only send what is interesting (only id for delete)
      manipulation.calendarItem = createSaveableCalendarItem(calendarItemManipulationInput.calendarItem);
      manipulations.manipulations.push(manipulation);
    });

    let resultObservable: Observable<DTO_CalendarItemsManipulationsResponse>;

    if (manipulations){

      if (environment.mockBackend){
        this.loggerService.info(this.LOGGER_CLASSNAME, 'manipulateCalendarItems', 'about to mock manipulateCalendarItems request');

        let resultSubject = new Subject<DTO_CalendarItemsManipulationsResponse>();
        resultObservable = resultSubject;

        // simulate 20% error chance
        if (Math.random() < 0.2) {
          this.loggerService.error(this.LOGGER_CLASSNAME, 'manipulateCalendarItems', 'simulating createCalendarItem error');

          // simulate 2 seconds request
          setTimeout(() => {
            resultSubject.error("Simulate error");
          }, 2000);
        }else{
          setTimeout(() => {
            let addedCalendarItems: CalendarItem[] = [];
            let updatedCalendarItems: CalendarItem[] = [];
            let deletedCalendarItemIds: number[] = [];
            manipulationsInput.forEach(calendarItemManipulationInput => {
              if (calendarItemManipulationInput.action == DTO_CalendarItemsAction.Add){
                calendarItemManipulationInput.calendarItem.id = Math.floor(Math.random() * 1000000000);
                addedCalendarItems.push(calendarItemManipulationInput.calendarItem);
              }else if (calendarItemManipulationInput.action == DTO_CalendarItemsAction.Update){
                updatedCalendarItems.push(calendarItemManipulationInput.calendarItem);
              }else if (calendarItemManipulationInput.action == DTO_CalendarItemsAction.Delete){
                deletedCalendarItemIds.push(calendarItemManipulationInput.calendarItem.id);
              }
            });

            let dTO_CalendarItemsManipulationsResponse: DTO_CalendarItemsManipulationsResponse = {
              timestamp: 1234567890,
              clientUpdateAdvised: false,
              addedCalendarItems: addedCalendarItems,
              updatedCalendarItems: updatedCalendarItems,
              deletedCalendarItemIds: deletedCalendarItemIds
            };


            resultSubject.next(dTO_CalendarItemsManipulationsResponse);
            resultSubject.complete();
          }, 2000);
        }

      }else{
        if (this.authenticationService.loggedIn){

          let url = Config.api4Url_calendar_manipulateCalendarItems(this.authenticationService.zoneId, calendar.calendarId);
          resultObservable = this.httpClient.post<DTO_CalendarItemsManipulationsResponse>(url, manipulations);
        }
      }
    }
    return resultObservable;
  }

}
