import {MetadataDisplayType} from './enums/metadataDisplayType'

export class ChangeableParameter {
    "@type": string;
    name: string;
    metadataDisplayType: MetadataDisplayType;
    metadataDisplayName: string;

    constructor(changeableParameter?: ChangeableParameter){
        if (changeableParameter){
            this.name = changeableParameter.name;
            this.metadataDisplayType = changeableParameter.metadataDisplayType;
            this.metadataDisplayName = changeableParameter.metadataDisplayName;
            this["@type"] = changeableParameter["@type"];
        }
    }
}