import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { LoggerService } from '../loggers/logger.service';
import { Subject, BehaviorSubject, Observable, Subscription, timer, merge } from 'rxjs';
import { AudioFile, QueueOrigin } from '@model/audioFile';
import { finalize, filter, takeUntil } from 'rxjs/operators';
import { createClassObjectForAudioFile } from './util/audioFileUtil';
import { AsyncStatus } from './vo/asyncStatus';
import { ListManipulationAction } from './vo/list-manipulation/listManipulationAction';
import { Config } from '@service/config';
import { QueueManipulation } from './vo/list-manipulation/queueManipulation';
import { LocalListManipulation } from './vo/list-manipulation/localListManipulation';
import { QueueApiService, DTO_Queue, DTO_QueueType, getQueueTypeForString } from '@service/api/queue-api.service';
import { VersionedResourceResponse } from '@service/api/dto/versionedResourceResponse';
import { ZoneConfigurationService } from './zone-configuration.service';
import { PlayableAudio } from '../../model/audioFile';
import { time } from 'console';


//internal representation of queue variables, there is one representation for each queue (waiting / radio / nextRadio)
class QueueProperties {

  private LOGGER_CLASSNAME = 'QueueProperties';

  //we need access to the loggerService from inside our queueProperties
  public loggerService: LoggerService;

  constructor(dto_queueType: DTO_QueueType) {
    this.dto_queueType = dto_queueType;
  }
  dto_queueType: DTO_QueueType;

  //the loader that is currently active -> this is an observable connected to the http request
  private _loader: Observable<DTO_Queue>;
  get loader(): Observable<DTO_Queue> {
    return this._loader;
  }
  set loader(value: Observable<DTO_Queue>) {
    this._loader = value;
    this._loading = this._loader !== null;
  }

  public loadAttempt = 0;

  private set _loading(value: boolean) {
    if (this.loading !== value) {
      this.loadingSubject.next(value);
    }
  }
  get loading(): boolean {
    return this.loadingSubject.value;
  }
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  get loadingError(): string {
    return this.loadingErrorSubject.value;
  }
  set loadingError(value: string) {
    if (this.loadingError !== value) {
      this.loadingErrorSubject.next(value);
    }
  }
  loadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  //event subject that is triggered when loading of the queue is done, with a boolean indicating a success (true = success, false = failure)
  loadingDoneSource: Subject<boolean> = new Subject<boolean>();
  emitLoadingDone(value: boolean) {
    this.loadingDoneSource.next(value);
  }

  get tracks(): AudioFile[] {
    return this.tracksSubject.value;
  }
  set tracks(value: AudioFile[]) {
    if (this.tracks != null && this.tracks === value) {
      value = Object.assign([], value); //when the array is the same but the setter is called -> take a copy to be sure change detection is triggered
    }
    this.tracksSubject.next(value);
  }
  tracksSubject: BehaviorSubject<AudioFile[]> = new BehaviorSubject<AudioFile[]>(null);

  emitTracksChanged() {
    this.tracks = this.tracks;
  }

  timestamp: number; //timestamp of the last fetched version
  timestampFromUpdateTrigger: number = undefined; //timestamp that we have received after a save or update event. This is used when the queue is fetched to check if we have the most recent version
  refetchedToAvoidVersionConflict = false; //boolean to avoid we fetch multiple times in a row (avoid endless loop when the server messes up the version numbers)


  //properties about the validity of the queue (currently only used for the radioQueue and nextRadioQueue)
  private _validitySeconds = undefined;
  public set validitySeconds(seconds: number) {
    this._validitySeconds = seconds;
    this.validityRefTimeStamp = new Date().getTime();
    this.adjustIsValid();
    this.adjustTimerForValidity();
  }
  public get validitySeconds(): number {
    if (this._validitySeconds != undefined && this.validityRefTimeStamp != undefined) {
      return this._validitySeconds - (new Date().getTime() - this.validityRefTimeStamp) / 1000;
    }
    return undefined;
  }

  //the timestamp the validity was set
  private validityRefTimeStamp = undefined;

  private validityTimeout: NodeJS.Timeout;
  private adjustTimerForValidity() {
    this.clearValidityTimer();
    //we just use a variable for logging/debugging purpose
    const validityUntilInvalid = this.validitySeconds;
    if (validityUntilInvalid != undefined) {
      this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustTimerForValidity", "going to start timer for " + this.dto_queueType + " queue based on validity of " + validityUntilInvalid + " seconds");
      this.validityTimeout = setTimeout(() => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, "validityTimeout", "validityTimeout fired. Going to adjust validity of " + this.dto_queueType);
        this.adjustTimerForValidity();
        this.adjustIsValid();
      }, Math.max((validityUntilInvalid + 1) * 1000, 1000));
    }
  }
  private clearValidityTimer() {
    if (this.validityTimeout) {
      clearTimeout(this.validityTimeout);
      this.validityTimeout = null;
    }
  }

  private adjustIsValid() {
    const newIsValidValue = this.validitySeconds != undefined && this.validitySeconds > 0;
    //just emit a new value each time -> we need to reload the queue when a new, invalid queue is received
    //we won't reload the queue if it is already reloading (check in loadQueue)
    this.isValidSubject.next(newIsValidValue);
  }

  private isValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isValid$: Observable<boolean> = this.isValidSubject.asObservable();



  //the local changes to the queue that still needs to be saved
  localChanges: LocalListManipulation[] = [];
  /**
   * About change objects:
   *
   * We need to keep the unsaved changes in a format with the original objects.
   * If the save fails because our local data is not up to date, we need to reapply the changes to the new data.
   * That is why we need a list of LocalListManipulations for the changes that are sent to the server, but not yet confirmed.
   */
  savingChanges: LocalListManipulation[];
  savingQueueManipulation: QueueManipulation; //object sent to the server
  saveFailed = false;
  saveFailedInARowAmount = 0; //how many times the save has failed in a row
  get saving(): boolean {
    return this.savingQueueManipulation != null;
  }

  //event subject that is triggered when saving changes of the queue are done, with a boolean indicating a success (true = success, false = failure)
  savingDoneSource: Subject<boolean> = new Subject<boolean>();
  emitSavingDone(value: boolean) {
    this.savingDoneSource.next(value);
  }

  //helper function to properly cleanup the manipulation objects
  private cleanupLocalListManipulations(localListManipulations: LocalListManipulation[]) {
    if (localListManipulations != null) {
      localListManipulations.forEach(
        localListManipulation => {
          if (localListManipulation.notifier) {
            localListManipulation.notifier.complete(); //complete all notifiers so subscriptions are automatically unsubscribed
          }
        }
      );
    }
  }

  //helper function to reset all data (when user logged out)
  cleanUpData() {
    this.loader = null;
    this.loadingError = null;
    this.tracks = null;
    this.timestamp = undefined;
    this.timestampFromUpdateTrigger = undefined;
    this.refetchedToAvoidVersionConflict = false;
    this.validitySeconds = undefined;

    this.cleanupLocalListManipulations(this.localChanges);
    this.localChanges = [];
    this.cleanupLocalListManipulations(this.savingChanges);
    this.savingChanges = null;

    this.savingQueueManipulation = null;
    this.saveFailed = false;
  }
}

@Injectable()
export class QueueService implements OnDestroy {

  private LOGGER_CLASSNAME = 'QueueService';

  private readonly BASE_RETRY_SAVE_CHANGES_INTERVAL_MILLISECONDS = 5000;
  private readonly MAX_RETRY_SAVE_CHANGES_INTERVAL_MILLISECONDS = 60000;

  constructor(private http: HttpClient,
    private queueApiService: QueueApiService,
    private zoneConfigurationService: ZoneConfigurationService,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService) {

    this.waitingQueueProperties.loggerService = this.loggerService;
    this.radioQueueProperties.loggerService = this.loggerService;
    this.nextRadioQueueProperties.loggerService = this.loggerService;

    //when one of the queues change -> check if the next audioFile has changed
    this.waitingQueueProperties.tracksSubject
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (tracks) => {
          this.recalcNextAudioFile();
        }
      );
    this.radioQueueProperties.tracksSubject
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (tracks) => {
          this.recalcNextAudioFile();
        }
      );

    this.radioQueueProperties.isValid$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          if (!value) {
            if (this.authenticationService.loggedIn && this.zoneConfigurationService.language != null) {
              this.loggerService.debug(this.LOGGER_CLASSNAME, "radioQueueValiditySubscription", "radioQueue becomes invalid, we need to reload");
              this.loadQueue(this.radioQueueProperties.dto_queueType);
            } else {
              this.loggerService.debug(this.LOGGER_CLASSNAME, "radioQueueValiditySubscription", "radioQueue becomes invalid, but no one is logged in -> nothing to do");
            }

          }
        }
      );

    this.nextRadioQueueProperties.isValid$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          if (!value) {
            if (this.authenticationService.loggedIn && this.zoneConfigurationService.language != null) {
              this.loggerService.debug(this.LOGGER_CLASSNAME, "nextRadioQueueValiditySubscription", "nextRadioQueue becomes invalid, we need to reload");
              this.loadQueue(this.nextRadioQueueProperties.dto_queueType);
            } else {
              this.loggerService.debug(this.LOGGER_CLASSNAME, "nextRadioQueueValiditySubscription", "nextRadioQueue becomes invalid, but no one is logged in -> nothing to do");
            }

          }
        }
      );




    this.authenticationService.loggedIn$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          if (value) {
            //we just logged in, start loading
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged in ... start loading queues');
            this.loadDataIfPossible();
          } else {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged out ... unloading queue data');
            this.cleanUpData();
          }
        },
        (err: unknown) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'error from loginInSubscription in RemoteService: ' + err);
        }
      );

    this.zoneConfigurationService.language$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.loadDataIfPossible();
        }
      );

      this.zoneConfigurationService.zoneConfigurationError$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (loadError) => {
          //When loading the zoneConfigration fails AND we did not load the queues: start loading without language
          if (loadError != null){
            const radioQueueProperties = this.queuePropertiesForQueueType(DTO_QueueType.Radio);
            if (!radioQueueProperties.loading && radioQueueProperties.tracks == null){
              this.loadDataIfPossible();
            }
          }
        }
      );


  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  //wait until all depending data is available (song properties are in the language of the user, wait for the langauge to be known)
  private loadDataIfPossible() {
    if (this.authenticationService.loggedIn && (this.zoneConfigurationService.language != null || this.zoneConfigurationService.zoneConfigurationError)) {
      this.loadQueue(DTO_QueueType.Waiting, true);
      this.loadQueue(DTO_QueueType.Radio, true);
    }
  }

  public cleanUpData() {
    this.waitingQueueProperties.cleanUpData();
    this.radioQueueProperties.cleanUpData();
    this.nextRadioQueueProperties.cleanUpData();
  }

  private set _nextAudioFile(value: AudioFile) {
    if (this._nextAudioFile !== value) {
      this._nextAudioFileSubject.next(value);
    }
  }
  private get _nextAudioFile(): AudioFile {
    return this._nextAudioFileSubject.value;
  }
  public get nextAudioFile(): AudioFile {
    return this._nextAudioFile;
  }
  private _nextAudioFileSubject: BehaviorSubject<AudioFile> = new BehaviorSubject<AudioFile>(null);
  public nextAudioFile$: Observable<AudioFile> = this._nextAudioFileSubject.asObservable();

  private recalcNextAudioFile() {
    this._nextAudioFile = this.getNextAudioFile(null, 0);
  }


  private waitingQueueProperties = new QueueProperties(DTO_QueueType.Waiting);
  private radioQueueProperties = new QueueProperties(DTO_QueueType.Radio);
  private nextRadioQueueProperties = new QueueProperties(DTO_QueueType.NextRadio);

  //Queue events when loading the queue is done, with a boolean indicating a success (true = success, false = failure)
  //private queueEventSource = new Subject<QueueEvent>();
  /*queueLoadingDone$ = this.queueLoadingDoneSource.asObservable();
  private emitQueueLoadingDone(value:boolean){
    this.queueLoadingDoneSource.next(value);
  }*/

  //events when loading the queue is done, with a boolean indicating a success (true = success, false = failure)
  queueLoadingDone$ = this.waitingQueueProperties.loadingDoneSource.asObservable();

  //events when saving queue changes are done, with a boolean indicating a success (true = success, false = failure)
  queueSavingDone$ = this.waitingQueueProperties.savingDoneSource.asObservable();

  //events when loading the radioQueue is done, with a boolean indicating a success (true = success, false = failure)
  radioQueueLoadingDone$ = this.radioQueueProperties.loadingDoneSource.asObservable();

  //events when saving the radio queue changes are done, with a boolean indicating a success (true = success, false = failure)
  radioQueueSavingDone$ = this.radioQueueProperties.savingDoneSource.asObservable();

  /**
    * Waiting Queue properties
    */

  /**
   * loading Queue
   */
  get loadingQueue(): boolean {
    return this.waitingQueueProperties.loading;
  }
  public loadingQueue$: Observable<boolean> = this.waitingQueueProperties.loading$;

  /**
   * Error emitter for retrieving the queue
   */
  get loadingQueueError(): string {
    return this.waitingQueueProperties.loadingError;
  }
  public loadingQueueError$: Observable<string> = this.waitingQueueProperties.loadingErrorSubject.asObservable();

  /**
   * Queued audioFiles
   */
  get queue(): AudioFile[] {
    return this.waitingQueueProperties.tracks;
  }

  public queue$: Observable<AudioFile[]> = this.waitingQueueProperties.tracksSubject.asObservable();


  /**
   * Radio Queue properties
   */

  get loadingRadioQueue(): boolean {
    return this.radioQueueProperties.loading;
  }
  public loadingRadioQueue$: Observable<boolean> = this.radioQueueProperties.loading$;

  /**
   * Error emitter for retrieving the radio queue
   */
  get loadingRadioQueueError(): string {
    return this.radioQueueProperties.loadingError;
  }
  public loadingRadioQueueError$: Observable<string> = this.radioQueueProperties.loadingErrorSubject.asObservable();

  /**
   * Radio Queued audioFiles
   */
  get radioQueue(): AudioFile[] {
    return this.radioQueueProperties.tracks;
  }
  public radioQueue$: Observable<AudioFile[]> = this.radioQueueProperties.tracksSubject.asObservable();

  /**
   * Next radio queue
   */
  public nextRadioQueue$: Observable<AudioFile[]> = this.nextRadioQueueProperties.tracksSubject.asObservable();










  //private nextRadioQueue:AudioFile[];
  //private nextRadioQueueVersion: number;


  private retryToLoadQueueAfterInterval(queueType: DTO_QueueType) {
    const queueProperties = this.queuePropertiesForQueueType(queueType);
    if (queueProperties != null){
      const queueWillLoadAgain$ = this.cancelLoadersForQueueTypeSubscriber.pipe(filter((queueTypeThatStartsAgain) => queueTypeThatStartsAgain === queueType));

       const delay = Math.pow(2, Math.max(0, queueProperties.loadAttempt - 1)) * Config.RETRY_BASE_INTERVAL_MS;
      timer(Math.min(delay, Config.RETRY_MAX_INTERVAL_MS))
      .pipe(
         takeUntil(
           merge(
             queueWillLoadAgain$,
             this.destroyed$
           )
         )
       )
       .subscribe(
         () => {
           this.loadQueue(queueType)
         }
       )
    }
  }


  //public wrapper functions to avoid having to use DTO_QueueType outside the services
  public loadWaitingQueue() {
    this.loadQueue(DTO_QueueType.Waiting);
  }
  public loadRadioQueue() {
    this.loadQueue(DTO_QueueType.Radio);
  }


  //we will use this observable to cancel any previous http request to load the same queueType that is still running
  private cancelLoadersForQueueTypeSubscriber: Subject<DTO_QueueType> = new Subject<DTO_QueueType>();

  //event subject that is triggered when a new save request will start
  private saveChangesForQueueTypeWillStart: Subject<DTO_QueueType> = new Subject<DTO_QueueType>();

  private queuePropertiesForQueueType(queueType: DTO_QueueType): QueueProperties {
    if (queueType === DTO_QueueType.Waiting) {
      return this.waitingQueueProperties;
    } else if (queueType === DTO_QueueType.Radio) {
      return this.radioQueueProperties;
    } else if (queueType === DTO_QueueType.NextRadio) {
      return this.nextRadioQueueProperties;
    }
    return null;
  }

  public handleUpdateInfo(queueType: string, timestamp: number) {
    if (queueType) {

      this.loggerService.debug(this.LOGGER_CLASSNAME, "handleUpdateInfo", "updateInfo received for " + queueType + ": timestamp " + timestamp);

      const queuePropertiesToCheck = this.queuePropertiesForQueueType(getQueueTypeForString(queueType));

      if (queuePropertiesToCheck) {
        if (queuePropertiesToCheck.timestamp == undefined || queuePropertiesToCheck.timestamp < timestamp) {
          this.loggerService.debug(this.LOGGER_CLASSNAME, "handleUpdateInfo", "We received an update " + queuePropertiesToCheck.dto_queueType + " event for timestamp " + timestamp + " -> Going to fetch newer version");
          this.handleNewerVersionSeen(queuePropertiesToCheck, timestamp);
        } else if (queuePropertiesToCheck.timestamp == timestamp) {
          this.loggerService.debug(this.LOGGER_CLASSNAME, "handleUpdateInfo", "We received an update " + queuePropertiesToCheck.dto_queueType + " event for timestamp " + timestamp + " -> already seen this timestamp");
        } else {
          this.loggerService.warn(this.LOGGER_CLASSNAME, "handleUpdateInfo", "We received an update " + queuePropertiesToCheck.dto_queueType + " event for timestamp " + timestamp + ", but we already have a newer version (" + queuePropertiesToCheck.timestamp + ")");
        }
      } else {
        this.loggerService.error(this.LOGGER_CLASSNAME, "handleUpdateInfo", "QueueType not recognized: " + queueType);
      }
    }

  }

  private handleNewerVersionSeen(queueProperties: QueueProperties, timestamp: number) {

    //keep track of the highest seen timestamp, after a fetch we should check if we have the latest version
    if (timestamp != undefined){
      if (queueProperties.timestampFromUpdateTrigger == undefined || queueProperties.timestampFromUpdateTrigger < timestamp) {
        queueProperties.timestampFromUpdateTrigger = timestamp;
        this.loggerService.debug(this.LOGGER_CLASSNAME, "handleNewerVersionSeen", "Going to check for newest version:  " + timestamp);
      }else{
        this.loggerService.debug(this.LOGGER_CLASSNAME, "handleNewerVersionSeen", "Already going to check for an even newer version:  " + queueProperties.timestampFromUpdateTrigger + '(we just received: ' + timestamp + ')');
      }
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, "handleNewerVersionSeen", "No timestamp info");
    }

    //new queue should be fetched if we are not already loading or saving
    if (!queueProperties.loading && !queueProperties.saving) {
      this.loadQueue(queueProperties.dto_queueType);
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, "handleNewerVersionSeen", 'Not going to load right now: loading: ' + (queueProperties.loading ? 'true': 'false') + 'saving: ' + (queueProperties.saving ? 'true': 'false'));
    }


  }

  public loadQueue(queueType: DTO_QueueType, forceLoad: boolean = false) {

    const queueProperties = this.queuePropertiesForQueueType(queueType);

    if (queueProperties) {
      this.loggerService.debug(this.LOGGER_CLASSNAME, "loadQueue", "loading started for " + queueType + "Queue");

      //only load the queue if we are not yet loading
      if (!queueProperties.loading || forceLoad) {

        //replace the previous loader if there is one (if a previous loader is still running, we will cancel it)
        queueProperties.loader = this.queueApiService.loadQueue(queueType);
        const loaderInThisRequest = queueProperties.loader;

        //this will trigger the finalize of any previous http request for the same queueType
        this.cancelLoadersForQueueTypeSubscriber.next(queueType);

        //after we have emitted our own start event, create a filter of our queueType on the startToLoadSubscriber and
        //add a takeUntil, this will cancel this request if a new request with the same queue type starts
        const queueWillLoadAgain = this.cancelLoadersForQueueTypeSubscriber.pipe(filter((queueTypeThatStartsAgain) => queueTypeThatStartsAgain === queueType));

        queueProperties.loadingError = null;
        queueProperties.loadAttempt++;

        queueProperties.loader.pipe(
          finalize(() => {
            if (loaderInThisRequest === queueProperties.loader) {
              this.loggerService.debug(this.LOGGER_CLASSNAME, "loadQueue", "loadQueue finished for " + queueType + "Queue");
              queueProperties.loader = null;
              this.saveQueueChanges(queueType);
            } else {
              this.loggerService.warn(this.LOGGER_CLASSNAME, "loadQueue", "loadQueue got cancelled because there is a newer request for the same queueType");
            }
          }),
          takeUntil(queueWillLoadAgain)
        ).subscribe(
          data => {
            let useQueueFromResponse = true;
            queueProperties.loadAttempt = 0;

            if (queueProperties.timestampFromUpdateTrigger != undefined && queueProperties.timestampFromUpdateTrigger > data.timestamp) {
              //there is a newer queue version on the server -> refetch
              if (!queueProperties.refetchedToAvoidVersionConflict) {
                //we are only going to refetch once, to avoid endless loop of fetching data when there is something wrong with our version timestamps
                queueProperties.refetchedToAvoidVersionConflict = true;
                useQueueFromResponse = false;
              } else {
                //ignore the newer version and start using this queue

                this.loggerService.warn(this.LOGGER_CLASSNAME, "loadQueue", "loadQueue receives an older version number but we already refetched before -> just start working with this queue");
              }
            }

            if (useQueueFromResponse) {

              //reset the conflict flag once we use the queue
              queueProperties.refetchedToAvoidVersionConflict = false;
              //reset the highest seen timestamp
              queueProperties.timestampFromUpdateTrigger = undefined;

              //we should construct real objects with the correct type (so we can use instanceof to check the type)
              const realQueue = new DTO_Queue(data);

              //apply local changes to the new queue
              queueProperties.localChanges = this.applyLocalChangesToTracks(realQueue.tracks, queueProperties.localChanges);

              if (queueType == DTO_QueueType.Radio || queueType == DTO_QueueType.NextRadio){
                realQueue.tracks.forEach(track => track.queueOrigin = QueueOrigin.radio);
              }else if (queueType == DTO_QueueType.Waiting){
                realQueue.tracks.forEach(track => track.queueOrigin = QueueOrigin.waiting);
              }

              queueProperties.tracks = realQueue.tracks;
              queueProperties.timestamp = realQueue.timestamp;
              queueProperties.validitySeconds = realQueue.validForSeconds;

              this.loggerService.debug(this.LOGGER_CLASSNAME, "loadQueue", "loadQueue done for " + queueProperties.dto_queueType + " (timestamp: " + queueProperties.timestamp + "): " + realQueue.tracks.length + " tracks - valid for " + realQueue.validForSeconds + " seconds");

              queueProperties.emitLoadingDone(true);
            } else {
              //we can't use this queue (tim)
              this.loadQueue(queueType, true);
            }
          },
          (error: unknown) => {
            if (error instanceof HttpErrorResponse){
              const errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';
              this.loggerService.error(this.LOGGER_CLASSNAME, 'loadQueue', 'Error loading ' + queueType + 'Queue: ' + errMsg)
            }
            queueProperties.loadingError = 'GeneralError';
            this.retryToLoadQueueAfterInterval(queueType);
            queueProperties.emitLoadingDone(false);

          }
        );
      } else {
        this.loggerService.debug(this.LOGGER_CLASSNAME, "loadQueue", "Already loading the queue -> skipping load");
      }
    } else {
      this.loggerService.error(this.LOGGER_CLASSNAME, "loadQueue", "no queueProperties for " + queueType + "Queue");
    }
  }





  private saveQueueChanges(queueType: DTO_QueueType) {

    const queueProperties = this.queuePropertiesForQueueType(queueType);

    if (!queueProperties.loading && queueProperties.loadingError == null && !queueProperties.saving && queueProperties.localChanges != null && queueProperties.localChanges.length > 0) {

      queueProperties.saveFailed = false;

      queueProperties.savingChanges = [];
      queueProperties.savingQueueManipulation = new QueueManipulation();
      queueProperties.savingQueueManipulation.timestamp = queueProperties.timestamp;

      //To test a wrong timestamp
      //queueProperties.savingQueueManipulation.timestamp = queueProperties.timestamp - 1000;


      queueProperties.savingQueueManipulation.manipulations = [];

      //todo -> move all shuffle action to the end just before we send (so our add / deletes work)

      //prepare object to send, order is important (shift + push = same order for new list)
      let localListManipulation = queueProperties.localChanges.shift();
      while (localListManipulation != null) {
        queueProperties.savingChanges.push(localListManipulation);
        localListManipulation.toListManipulations().forEach(listManipulation => {
          queueProperties.savingQueueManipulation.manipulations.push(listManipulation);
        });
        localListManipulation = queueProperties.localChanges.shift();
      }


      //perform save
      if (this.queueApiService.isQueueMocked(queueType)) {
        this.loggerService.info(this.LOGGER_CLASSNAME, 'saveQueueChanges', 'about to mock saveQueueChanges request for ' + queueType + 'Queue -> simulate response');

        //simulate a save error after 5 seconds
        setTimeout(() => {

          if (queueProperties.savingQueueManipulation != null) {
            this.handleQueueSaveDone(queueType, false);
          }
        }, 5000);

      } else {


        //let url = "https://docker1-test.tunify.com/zuul/t4-music-service/zone/331412/queue/waiting";
        const url = Config.api4Url_queue_tracks_manipulate(this.authenticationService.zoneId, queueType);
        this.loggerService.debug(this.LOGGER_CLASSNAME, "saveQueueChanges", "going to save queueManipulations to " + url);

        const saveQueueChangesObservable: Observable<VersionedResourceResponse> = this.http.post<VersionedResourceResponse>(
          url,
          queueProperties.savingQueueManipulation
        );

        this.saveChangesForQueueTypeWillStart.next(queueType);

        saveQueueChangesObservable
          .pipe(
            finalize(() => {

              const waitInterval = Math.min((this.BASE_RETRY_SAVE_CHANGES_INTERVAL_MILLISECONDS * queueProperties.saveFailedInARowAmount), this.MAX_RETRY_SAVE_CHANGES_INTERVAL_MILLISECONDS)

              //reload the queue if we know the server has a newer version
              if (queueProperties.timestampFromUpdateTrigger != undefined && queueProperties.timestampFromUpdateTrigger > queueProperties.timestamp) {
                this.loggerService.debug(this.LOGGER_CLASSNAME, "saveQueueChanges", "saveQueueChanges finished for " + queueType + "Queue. Server has a newer version (" + queueProperties.timestampFromUpdateTrigger + ") milliseconds before checking new save needed or not");
                this.loadQueue(queueType);
              }

              this.loggerService.debug(this.LOGGER_CLASSNAME, "saveQueueChanges", "saveQueueChanges finished for " + queueType + "Queue. Waiting " + waitInterval + " milliseconds before checking new save needed or not");

              //check if we still need to save changes, hold for a while if errors occured
              timer(waitInterval)
              .pipe(
                takeUntil(
                  merge(
                    this.destroyed$,
                    this.saveChangesForQueueTypeWillStart.pipe(filter((queueTypeInEvent) => queueTypeInEvent == queueType ))
                  )
                )
              )
              .subscribe(() => {
                this.loggerService.debug(this.LOGGER_CLASSNAME, "saveQueueChanges finalize", "Going to check if we need to save more changes for " + queueType + "Queue");
                this.saveQueueChanges(queueType);
              });

            }),
            takeUntil(this.authenticationService.loggedIn$.pipe(filter(val => val === false))) //cancel the request when the user is no longer logged in
          )
          .subscribe(
            (data) => {
              this.loggerService.debug(this.LOGGER_CLASSNAME, queueType + "Queue saveQueueChanges", "data : " + data);

              let reloadNeeded = false;
              if (data.timestamp == null) {
                // a reload should already been busy (from updateInfo interceptor), but for safety, we also check if we need to reload
                // reload will not trigger again if this queue is already reloading
                reloadNeeded = true;

              } else {

                if (queueProperties.timestampFromUpdateTrigger != undefined && queueProperties.timestampFromUpdateTrigger > data.timestamp) {
                  this.loggerService.warn(this.LOGGER_CLASSNAME, "Queue saveQueueChanges", queueType + 'Queue save receives a timestamp (' + data.timestamp + ') that is lower than the one we expected from updateInfos (' + queueProperties.timestampFromUpdateTrigger + ')');
                }else{
                  //our local version is correct -> update version label
                  queueProperties.timestamp = data.timestamp;
                  this.loggerService.debug(this.LOGGER_CLASSNAME, "Queue saveQueueChanges", queueType + "Queue save done, updating timestamp to " + queueProperties.timestamp);


                  if (queueProperties.timestampFromUpdateTrigger != undefined ){
                    this.loggerService.debug(this.LOGGER_CLASSNAME, "Queue saveQueueChanges", queueType + 'Queue receives a timestamp (' + data.timestamp + ') that is higher or equal than the one we expected from updateInfos (' + queueProperties.timestampFromUpdateTrigger + ') -> going to erase the updateInfo timestamp');
                    queueProperties.timestampFromUpdateTrigger = undefined;
                  }

                }
              }

              this.handleQueueSaveDone(queueType, true);
              if (reloadNeeded){
                this.handleNewerVersionSeen(queueProperties, undefined);
              }

            },
            (error: unknown) => {
              let status = 500;
              if (error instanceof HttpErrorResponse){
                status = error.status ? error.status : 500;
                const errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                this.loggerService.error(this.LOGGER_CLASSNAME, "saveQueueChanges", "Failed to save queue changes: " + errMsg);
              }else{
                this.loggerService.error(this.LOGGER_CLASSNAME, "saveQueueChanges", "Failed to save queue changes");
              }

              this.handleQueueSaveDone(queueType, false);

              //depending on the error -> reload queue on
              if (status == 409) { // - 409 conflict (timestamp conflict -> reload)
                this.loadQueue(queueProperties.dto_queueType);
              } else if (status == 400) { //When we receive a 400 error -> our message was badly formatted.
                this.loggerService.error(this.LOGGER_CLASSNAME, "saveQueueChanges", "We received status " + status + " when trying to save queue changes -> bad formmat?");
              } else {
                this.loggerService.error(this.LOGGER_CLASSNAME, "saveQueueChanges", "We received status " + status + " when trying to save queue changes");
              }

            });
      }
    }
  }

  //apply changes to an array of tracks and return a list of applied manipulations
  private applyLocalChangesToTracks(tracks: AudioFile[], localListManipulations: LocalListManipulation[]): LocalListManipulation[] {

    //we keep track of the manipulations that can still be applied
    const newLocalListManipulations: LocalListManipulation[] = [];

    if (tracks != null) {

      //loop over the manipulations, the order is important (don't run over the array backwards and remove unused manipulations)
      localListManipulations.forEach(
        localListManipulation => {

          if (localListManipulation.action === ListManipulationAction.REMOVE) {
            //remove in the correct order and only keep the onces that actually were removed
            let removedTracks: AudioFile[] = [];
            let removedAtPosition = undefined;

            localListManipulation.tracks.forEach(
              trackToRemove => {
                if (tracks.length > localListManipulation.position && tracks[localListManipulation.position].id === trackToRemove.id) {
                  tracks.splice(localListManipulation.position, 1);
                  removedTracks.push(trackToRemove);
                  removedAtPosition = localListManipulation.position;
                } else {
                  //if we didn't find the audioFile -> look for first entry with the same id
                  let index = 0;
                  while (index < tracks.length && tracks[index].id !== trackToRemove.id) {
                    index++;
                  }
                  if (index < tracks.length) {
                    //found the new index

                    if (removedAtPosition != undefined) {
                      //we already have removed items at an other position, split the action into multiple actions
                      const splittedLocalListManipulation = new LocalListManipulation();
                      splittedLocalListManipulation.action = ListManipulationAction.REMOVE;
                      splittedLocalListManipulation.position = removedAtPosition;
                      splittedLocalListManipulation.tracks = removedTracks;

                      //use the notifier, only on the first splitted manipulation (avoid double status update, double closing, ..)
                      splittedLocalListManipulation.notifier = localListManipulation.notifier;
                      localListManipulation.notifier = null;


                      newLocalListManipulations.push(splittedLocalListManipulation);
                      removedTracks = [];
                    }

                    //this is our first remove -> adjust position and apply
                    localListManipulation.position = index;
                    removedTracks.push(trackToRemove);
                    removedAtPosition = localListManipulation.position;

                    tracks.splice(localListManipulation.position, 1);
                  } else {
                    //track is no longer in the queue -> just ignore the remove for this track
                    this.loggerService.warn(this.LOGGER_CLASSNAME, "applyLocalChangesToTracks", "track is no longer in the array: " + trackToRemove);
                  }
                }
              }
            );

            if (removedTracks.length > 0) {
              localListManipulation.tracks = removedTracks;
              newLocalListManipulations.push(localListManipulation);
            } else {
              //the manipulation is no longer valid -> close the notifier if there is one
              if (localListManipulation.notifier) {
                localListManipulation.notifier.next(AsyncStatus.INVALID_ACTION);
                localListManipulation.notifier.complete();
              }
            }
          } else if (localListManipulation.action === ListManipulationAction.ADD) {
            if (localListManipulation.position < 0 || localListManipulation.position >= tracks.length) {
              //add to back
              localListManipulation.position = tracks.length;
              localListManipulation.tracks.forEach(
                (track) => {
                  tracks.push(track);
                }
              );
            } else {
              //insert at position and preserve the order (add them backwards on the same position)
              localListManipulation.tracks.slice().reverse().forEach(
                track => {
                  tracks.splice(localListManipulation.position, 0, track);
                }
              );
            }
            newLocalListManipulations.push(localListManipulation);
          } else if (localListManipulation.action === ListManipulationAction.SHUFFLE) {
            newLocalListManipulations.push(localListManipulation);
          } else if (localListManipulation.action === ListManipulationAction.CLEAR) {
            tracks.splice(0, tracks.length);
            newLocalListManipulations.push(localListManipulation);
          } else {
            this.loggerService.error(this.LOGGER_CLASSNAME, "applyLocalChangesToTracks", "manipulation not implemented: " + localListManipulation.action);
          }
        }
      )
    }
    return newLocalListManipulations;
  }

  private handleQueueSaveDone(queueType: DTO_QueueType, success: boolean) {

    const queueProperties = this.queuePropertiesForQueueType(queueType);

    if (success) {
      queueProperties.saveFailedInARowAmount = 0;

      queueProperties.savingChanges.forEach(localListManipulation => {
        if (localListManipulation.notifier) {
          localListManipulation.notifier.next(AsyncStatus.COMPLETED);
          localListManipulation.notifier.complete();
        }
      });
      queueProperties.savingChanges = null;
      queueProperties.savingQueueManipulation = null;
      queueProperties.emitSavingDone(true);
    } else {

      queueProperties.saveFailed = true;
      queueProperties.saveFailedInARowAmount++;

      //push the manipulations back on top and preserve the order
      let localListManipulation = queueProperties.savingChanges.pop();
      while (localListManipulation != null) {
        queueProperties.localChanges.unshift(localListManipulation);
        localListManipulation = queueProperties.savingChanges.pop();
      }

      queueProperties.savingChanges = null;
      queueProperties.savingQueueManipulation = null;

      queueProperties.emitSavingDone(false);
    }
  }



  public shuffleQueue(): Observable<AsyncStatus> {
    const statusObservable = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    let queueType = DTO_QueueType.Waiting;
    let queueProperties = this.waitingQueueProperties;

    if (this.queueApiService.isQueueMocked(queueType)) {
      if (this.queue != null && this.queue.length > 0) {
        this.queue.sort(() => Math.random() - 0.5);
        this.waitingQueueProperties.emitTracksChanged();

        statusObservable.next(AsyncStatus.COMPLETED);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);
      } else {
        statusObservable.next(AsyncStatus.INVALID_ACTION);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);
      }

    } else {
      if (this.queue != null && this.queue.length > 0) {
        let localListManipulation_shuffle = new LocalListManipulation();
        localListManipulation_shuffle.action = ListManipulationAction.SHUFFLE;
        localListManipulation_shuffle.notifier = statusObservable;
        queueProperties.localChanges.push(localListManipulation_shuffle);
        this.saveQueueChanges(queueType);
      } else {
        statusObservable.next(AsyncStatus.INVALID_ACTION);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);
      }
    }

    return statusObservable;
  }

  public clearQueue() {
    const statusObservable = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    if (this.queueApiService.isQueueMocked(DTO_QueueType.Waiting)) {
      if (this.queue != null && this.queue.length > 0) {
        this.queue.splice(0, this.queue.length);
        this.waitingQueueProperties.emitTracksChanged();

        statusObservable.next(AsyncStatus.COMPLETED);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);
      } else {
        statusObservable.next(AsyncStatus.INVALID_ACTION);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);
      }
    } else {
      if (this.queue != null && this.queue.length > 0) {
        let localListManipulation_clear = new LocalListManipulation();
        localListManipulation_clear.action = ListManipulationAction.CLEAR;

        //apply the manipulation to the queue and push it as a manipulation to save to the server
        this.applyLocalChangesToTracks(this.queue, [localListManipulation_clear]).forEach(
          (appliedLocalListManipulation) => {
            this.waitingQueueProperties.localChanges.push(appliedLocalListManipulation);
          }
        );

        //for queue changes we are not going to wait for the server response
        this.waitingQueueProperties.emitTracksChanged();

        statusObservable.next(AsyncStatus.COMPLETED);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);

        this.saveQueueChanges(DTO_QueueType.Waiting);
      } else {
        statusObservable.next(AsyncStatus.INVALID_ACTION);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);
      }
    }

    return statusObservable;
  }

  public moveAudioFileInQueue(audioFile: AudioFile, index: number): Observable<AsyncStatus> {

    const statusObservable = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    if (this.queue != null) {
      if (audioFile != null) {
        let oldPosition: number = this.queue.indexOf(audioFile);
        if (oldPosition >= 0) {
          if (oldPosition === index) {
            statusObservable.next(AsyncStatus.COMPLETED);
            setTimeout(() => {
              statusObservable.complete();
            }, 0);
            this.loggerService.debug(this.LOGGER_CLASSNAME, "moveAudioFileInQueue", "audioFile is moved to the same spot -> no action required");
          } else {
            let localListManipulation_remove = new LocalListManipulation();
            localListManipulation_remove.action = ListManipulationAction.REMOVE;
            localListManipulation_remove.position = oldPosition;
            localListManipulation_remove.tracks = [audioFile];

            let localListManipulation_add = new LocalListManipulation();
            localListManipulation_add.action = ListManipulationAction.ADD;
            localListManipulation_add.position = index;
            localListManipulation_add.tracks = [audioFile];

            //apply the manipulations to the queue and push them as manipulations to save to the server
            this.applyLocalChangesToTracks(this.queue, [localListManipulation_remove, localListManipulation_add]).forEach(
              (appliedLocalListManipulation) => {
                this.waitingQueueProperties.localChanges.push(appliedLocalListManipulation);
              }
            );

            //for queue changes we are not going to wait for the server response
            this.waitingQueueProperties.emitTracksChanged();

            statusObservable.next(AsyncStatus.COMPLETED);
            setTimeout(() => {
              statusObservable.complete();
            }, 0);

            this.saveQueueChanges(DTO_QueueType.Waiting);
          }
        } else {
          statusObservable.next(AsyncStatus.INVALID_ACTION);
          setTimeout(() => {
            statusObservable.complete();
          }, 0);
          this.loggerService.debug(this.LOGGER_CLASSNAME, "moveAudioFileInQueue", "audioFile to move is not from queue");
        }

      } else {
        statusObservable.next(AsyncStatus.INVALID_ACTION);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);
        this.loggerService.debug(this.LOGGER_CLASSNAME, "moveAudioFileInQueue", "no audioFile to move");
      }
    } else {
      statusObservable.next(AsyncStatus.INVALID_ACTION);
      setTimeout(() => {
        statusObservable.complete();
      }, 0);
      this.loggerService.error(this.LOGGER_CLASSNAME, "moveAudioFileInQueue", "trying to move an audioFile while the queue is not yet ready");
    }
    return statusObservable;
  }


  public addAudioFilesToQueue(audioFiles: AudioFile[], index?: number): Observable<AsyncStatus> {

    const statusObservable = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    if (this.queue != null) {
      if (audioFiles != null && audioFiles.length > 0) {

        let position: number;

        let clonedAudioFiles: AudioFile[] = [];
        audioFiles.forEach((audioFile) => {
          clonedAudioFiles.push(createClassObjectForAudioFile(audioFile));
        });

        if (index == undefined || index < 0 || index > this.queue.length) {
          position = this.queue.length;
        } else {
          position = index;
        }

        //create a localListManipulation for the added tracks
        let localListManipulation = new LocalListManipulation();
        localListManipulation.action = ListManipulationAction.ADD;
        localListManipulation.position = position;
        localListManipulation.tracks = clonedAudioFiles;

        //apply the manipulations to the queue and push them as manipulations to save to the server
        this.applyLocalChangesToTracks(this.queue, [localListManipulation]).forEach(
          (appliedLocalListManipulation) => {
            this.waitingQueueProperties.localChanges.push(appliedLocalListManipulation);
          }
        );

        //remove from our radio Queue
        //this.removeAudioFilesFromRadioQueue(audioFiles);
        this.removeAudioFilesOnIdFromRadioQueue(audioFiles);

        //for queue changes we are not going to wait for the server response
        this.waitingQueueProperties.emitTracksChanged();

        statusObservable.next(AsyncStatus.COMPLETED);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);

        this.saveQueueChanges(DTO_QueueType.Waiting);
      } else {
        statusObservable.next(AsyncStatus.INVALID_ACTION);
        setTimeout(() => {
          statusObservable.complete();
        }, 0);
        this.loggerService.debug(this.LOGGER_CLASSNAME, "addAudioFilesToQueue", "no audioFiles to add");
      }
    } else {
      statusObservable.next(AsyncStatus.INVALID_ACTION);
      setTimeout(() => {
        statusObservable.complete();
      }, 0);
      this.loggerService.error(this.LOGGER_CLASSNAME, "addAudioFilesToQueue", "trying to add audioFiles while the queue is not yet ready");
    }
    return statusObservable;
  }

  public removeAudioFileFromQueue(audioFile: AudioFile) {
    let trackToRemove = audioFile
    if (this.queue && this.queue.filter(track => track == audioFile).length == 0){
      //track object is not in the queue -> try to find the first match on id
      trackToRemove = this.queue.find(track => track.id == audioFile.id);
    }
    this.removeAudioFilesFromQueue(DTO_QueueType.Waiting, [trackToRemove]);
  }

  public removeAudioFilesOnIdFromRadioQueue(audioFiles: AudioFile[]){
    if (this.radioQueue != null){
      const tracksInRadioQueue = audioFiles.map((audioFile) =>this.radioQueue.find(radioQueuedTrack => radioQueuedTrack.id == audioFile.id)).filter(track => track != null);
      if (tracksInRadioQueue.length > 0){
        this.removeAudioFilesFromRadioQueue(tracksInRadioQueue);

      }
    }
  }

  public removeAudioFilesFromRadioQueue(audioFiles: AudioFile[]) {
    this.removeAudioFilesFromQueue(DTO_QueueType.Radio, audioFiles);
  }

  private removeAudioFilesFromQueue(queueType: DTO_QueueType, audioFiles: AudioFile[]) {

    let queueProperties = this.queuePropertiesForQueueType(queueType);

    if (queueProperties.tracks != null) {
      if (audioFiles && audioFiles.length > 0) {
        let removedAudioFiles = [];
        audioFiles.forEach(
          (audioFile) => {
            const index = queueProperties.tracks.indexOf(audioFile, 0);
            if (index > -1) {

              //because we need to save the position for each object, we create a localChange per object (not 1 for the total array)
              let localListManipulation = new LocalListManipulation();
              localListManipulation.tracks = [audioFile];
              localListManipulation.action = ListManipulationAction.REMOVE;
              localListManipulation.position = index;

              //apply the manipulation to the queue and push them as manipulations to save to the server
              this.applyLocalChangesToTracks(queueProperties.tracks, [localListManipulation]).forEach(
                (appliedLocalListManipulation) => {
                  queueProperties.localChanges.push(appliedLocalListManipulation);
                }
              );

              removedAudioFiles.push(audioFile);
            }
          }
        );

        if (removedAudioFiles.length > 0) {
          queueProperties.emitTracksChanged();
          this.saveQueueChanges(queueType);
        }
      } else {
        this.loggerService.warn(this.LOGGER_CLASSNAME, "removeAudioFilesFromQueue", "trying to remove an audioFiles in the " + queueType + "Queue , but the array is null or empty");
      }
    } else {
      this.loggerService.error(this.LOGGER_CLASSNAME, "removeAudioFilesFromQueue", "trying to remove an audioFiles while the " + queueType + "Queue is not yet ready");
    }
  }

  /*
  public isReadyForNextTrack():boolean{
    if (this.queue != null && this.queue.length > 0){ //queue is loaded and contains at least 1 track
      return true;
    } else if (this.queue != null){ //queue is loaded, or loading takes longer than expected
      if (this.radioQueue != null){
        return true;
      }
    }
    return false;
  }
  */

  public getNextAudioFile(ignoreAudioFiles?: AudioFile[], secondsBeforePlayWillOccur?: number, forceLoad?: boolean): AudioFile {
    let nextAudioFile = this.getNextAudioFileFromArray(this.queue, ignoreAudioFiles);

    if (nextAudioFile == null) {

      if ((!this.loadingQueue && this.queue != null) || forceLoad == true) {

        //determine what radioQueue we need to use
        let radioQueueWhenPlayWillOccur = this.radioQueue;
        if (secondsBeforePlayWillOccur != null) {
          let secondsBeforeNextRadioQueue = this.radioQueueProperties.validitySeconds;
          if (secondsBeforeNextRadioQueue != null) {
            //when the radioQueue is valid for a limited amount of time and the play will occur in the future -> check what radioQueue we need to use
            if (secondsBeforePlayWillOccur > secondsBeforeNextRadioQueue) {
              //we will only start to load the nextRadioQueue when we actually need it
              if (this.nextRadioQueueProperties.tracks === null) {
                this.loadQueue(DTO_QueueType.NextRadio);
              }
              radioQueueWhenPlayWillOccur = this.nextRadioQueueProperties.tracks;
            }
          }
        }
        nextAudioFile = this.getNextAudioFileFromArray(radioQueueWhenPlayWillOccur, ignoreAudioFiles);
      } else {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'getNextAudioFile', 'waiting for queue to be loaded')
      }
    }
    return nextAudioFile;
  }

  private getNextAudioFileFromArray(audioFilesArray: AudioFile[], ignoreAudioFiles?: AudioFile[]): AudioFile {
    if (audioFilesArray != null) {
      let index = 0;
      while (index < audioFilesArray.length) {
        if (ignoreAudioFiles == null || ignoreAudioFiles.indexOf(audioFilesArray[index]) < 0) {
          return audioFilesArray[index];
        }
        index++;
      }
    }
    return null;
  }

  //for buffering purpose -> check if the audioFile is in the first 'amountToConsider' tracks of the queues
  public audioFileLikelyToBePlayed(audioFile: AudioFile, amountToConsider: number): boolean {
    var elementsToCheck = amountToConsider;
    var totalDurationChecked = 0;
    if (this.queue != null && elementsToCheck > 0) {
      let tracksToCheck = this.queue.slice(0, elementsToCheck);
      let audioFileFound = this.queue.slice(0, elementsToCheck).some((audioFileInQueue) => audioFileInQueue.id == audioFile.id);
      if (audioFileFound) { //only return true
        return true;
      }

      tracksToCheck.forEach(track => totalDurationChecked += track.duration);
      elementsToCheck = elementsToCheck - tracksToCheck.length;
    }

    if (this.radioQueue != null && elementsToCheck > 0) {
      var trackIndexToCheck = 0;
      let validity = this.radioQueueProperties.validitySeconds;
      while ((validity == undefined || validity > totalDurationChecked / 1000) && trackIndexToCheck < this.radioQueue.length && elementsToCheck > 0){
        let trackToCheck = this.radioQueue[trackIndexToCheck];
        if (trackToCheck.id == audioFile.id){
          return true;
        }

        totalDurationChecked += trackToCheck.duration;
        trackIndexToCheck++;
        elementsToCheck--;
      }
    }

    if (this.nextRadioQueueProperties.tracks != null && elementsToCheck > 0) {
      let tracksToCheck = this.nextRadioQueueProperties.tracks.slice(0, elementsToCheck);
      let audioFileFound = tracksToCheck.some((audioFileInRadioQueue) => audioFileInRadioQueue.id == audioFile.id);
      if (audioFileFound) { //only return true
        return true;
      }
    }

    return false;
  }




}
