import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggerService } from '@service/loggers/logger.service';
import { AuthenticationService } from '@service/authentication.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from '@service/config';
import { VersionedResourceResponse } from './dto/versionedResourceResponse';

export enum DTO_ActiveMode{
  MusicChannel = "MusicChannel",
  Calendar = "Calendar",
  Free = "Free"
}

export class DTO_MusicSelection {
  active: DTO_ActiveMode;
  musicChannelId: number;
  calendarId: number;
  timestamp: number;
}



export class DTO_Region{
  id: number;
  code: string;
}

export class DTO_ZoneConfiguration {
  musicSelection: DTO_MusicSelection;
  sabamId: string;
  intercomUserHash: string;
  royaltyFree: boolean;
  region: DTO_Region;
  language: string;
  currentLocalDateTime: string; //we can't use Date -> we need to convert
  currentDateTime: string; //we can't use Date -> we need to convert
  clientLoggingLevel: string;
}

export class DTO_Language{
  language: string;
}




@Injectable({
  providedIn: 'root'
})
export class ZoneConfigurationApiService {

  private LOGGER_CLASSNAME = 'ZoneConfigurationApiService';

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService
  ) {

  }

  public loadZoneConfiguration(): Observable<DTO_ZoneConfiguration>{
    let zoneConfigurationObservable: Observable<DTO_ZoneConfiguration>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadZoneConfiguration', 'about to mock loadZoneConfiguration request');
      zoneConfigurationObservable = this.httpClient.get<DTO_ZoneConfiguration>("api/zoneConfiguration");
    }else{
      let url = Config.api4Url_zoneConfiguration(this.authenticationService.zoneId);
      zoneConfigurationObservable = this.httpClient.get<DTO_ZoneConfiguration>(url);
    }
    return zoneConfigurationObservable;
  }



  public loadMusicSelection(): Observable<DTO_MusicSelection>{

    let loadObservable: Observable<DTO_MusicSelection>;

    if (environment.mockBackend){
      this.loggerService.debug(this.LOGGER_CLASSNAME, "loadMusicSelection", "backend is mocked -> not going to save anything");
      loadObservable = this.httpClient.get<DTO_MusicSelection>("api/musicSelection");
    }else{
      const url = Config.api4Url_zoneConfiguration_musicSelection(this.authenticationService.zoneId);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "loadMusicSelection", "going to load musicSelection from " + url);

      //clear out
      loadObservable = this.httpClient.get<DTO_MusicSelection>(
        url
      );
    }

    return loadObservable;
  }

  public saveMusicSelection(musicSelection: DTO_MusicSelection): Observable<VersionedResourceResponse>{

    let saveMusicSelectionObservable: Observable<null>;

    if (environment.mockBackend){
      this.loggerService.debug(this.LOGGER_CLASSNAME, "saveMusicSelection", "backend is mocked -> not going to save anything");
    }else{
      let url = Config.api4Url_zoneConfiguration_musicSelection(this.authenticationService.zoneId);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "saveMusicSelection", "going to save musicSelection to " + url);

      //clear out

      saveMusicSelectionObservable = this.httpClient.post<null>(
        url,
        musicSelection
      );
    }

    return saveMusicSelectionObservable;
  }


  public saveLangauge(language: DTO_Language): Observable<null>{

    let saveLanguageObservable: Observable<null>;

    if (environment.mockBackend){
      this.loggerService.debug(this.LOGGER_CLASSNAME, "saveLangauge", "backend is mocked -> not going to save anything");
    }else{
      let url = Config.api4Url_zoneConfiguration_language(this.authenticationService.zoneId);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "saveLangauge", "going to save language to " + url);
      saveLanguageObservable = this.httpClient.post<null>(
        url,
        language
      );
    }

    return saveLanguageObservable;
  }
}
