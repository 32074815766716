<!-- This wrapper shows the background without any animations on it-->
<div class="backGroundWrapper">

    <!--
    We need the fadeIn animation on the container
    to prevent the slideOutLeft animation from running at the initial render.
    -->
  <div class="contentWrapper" @fadeIn>

    <!-- Keep the groups on screen to store the scrollPosition? -->
    <tun-music-channel-groups-view
      [@viewPosition]="mainViewPosition$ | async"
      class="fullContainer">
    </tun-music-channel-groups-view>

    <tun-music-channel-detail-view
      *ngIf="(showDetailsForMusicChannel$ | async) != null"
      @slideInRight
      class="fullContainer"
    >

    </tun-music-channel-detail-view>

    <tun-access-right-overlay-view
      *ngIf="!(accessToMusicChannels$ | async)"
      class="fullContainer"
      [message]="'accessRight.screen.musicChannels' | translate">
    </tun-access-right-overlay-view>

  </div>


</div>
