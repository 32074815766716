<!-- This wrapper shows the background without any animations on it-->
<div class="backGroundWrapper">

  <!--
  We need the fadeIn animation on the container
  to prevent the slideOutLeft animation from running at the initial render.
  -->
<div class="contentWrapper" @fadeIn>

  <!-- Keep the groups on screen to store the scrollPosition? -->

  <tun-search-music-channels-view
    [@viewPosition]="mainViewPosition$ | async"
    class="fullContainer">
  </tun-search-music-channels-view>

  <tun-search-result-view
    *ngIf="(searchView$ | async) == SEARCH_VIEW.SEARCH_VIEW_RESULTS"
    [@carousel]="{
      value: animationDirection,
      params: {
        duration: CAROUSEL_DURATION
      }
    }"
    class="fullContainer">

  </tun-search-result-view>


  <!--
  <tun-search-music-channels-view
  *ngIf="(searchView$ | async) == SEARCH_VIEW.SEARCH_VIEW_MAIN"
  [@carousel]="{
    value: animationDirection,
    params: {
      duration: CAROUSEL_DURATION
    }
  }"
    class="fullContainer">
  </tun-search-music-channels-view>
-->
  <tun-search-text-view
  *ngIf="(searchView$ | async) == SEARCH_VIEW.SEARCH_VIEW_TEXT_RESULTS"
  [@carousel]="{
    value: animationDirection,
    params: {
      duration: CAROUSEL_DURATION
    }
  }"
  (@carousel.start)="textViewAnimationStart($event)"
  (@carousel.done)="textViewAnimationDone($event)"
  [canReceiveFocus]="!textViewIsAnimating"
  (back)="onCloseSearchText()"
  class="fullContainer">

</tun-search-text-view>

  <tun-search-music-channel-view
    *ngIf="(searchView$ | async) == SEARCH_VIEW.SEARCH_VIEW_MUSIC_CHANNEL"
    [showSearchMusicChannel]="showDetailsForSearchMusicChannel"
    [@carousel]="{
      value: animationDirection,
      params: {
        duration: CAROUSEL_DURATION
      }
    }"
    class="fullContainer"
  >

  </tun-search-music-channel-view>

  <tun-search-music-collection-result-view
  *ngIf="(searchView$ | async) == SEARCH_VIEW.SEARCH_VIEW_RESULT_MUSIC_COLLECTION"
  [@carousel]="{
    value: animationDirection,
    params: {
      duration: CAROUSEL_DURATION
    }
  }"
  class="fullContainer"
>

</tun-search-music-collection-result-view>

  <tun-access-right-overlay-view
      *ngIf="!(accessToSearch$ | async)"
      class="fullContainer"
      [message]="'accessRight.screen.search' | translate">
    </tun-access-right-overlay-view>



</div>


</div>
