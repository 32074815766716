import { ChangeableParameter } from './changeableParameter';
import { Value } from './value';

export class ChangeableSelectionParameter extends ChangeableParameter{
    value: Value[];

    constructor(changeableSelectionParameter?: ChangeableSelectionParameter){
        super(changeableSelectionParameter);
        if (changeableSelectionParameter){
            if (changeableSelectionParameter.value){
                this.value = [];
            
                changeableSelectionParameter.value.forEach(val => {
                    this.value.push(new Value(val));
                });
            }
        }
    }
}