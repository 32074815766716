import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngleUpComponent } from './angle-up/angle-up.component';
import { AngleDownComponent } from './angle-down/angle-down.component';
import { PlayTrackComponent } from './play-track/play-track.component';
import { TrackOptionsComponent } from './track-options/track-options.component';
import { PlayIconV5Component } from './play-icon-v5/play-icon-v5.component';
import { PauseIconV5Component } from './pause-icon-v5/pause-icon-v5.component';
import { SkipIconV5Component } from './skip-icon-v5/skip-icon-v5.component';
import { QueueFullIconV5Component } from './queue-full-icon-v5/queue-full-icon-v5.component';
import { ShuffleIconV5Component } from './shuffle-icon-v5/shuffle-icon-v5.component';
import { DeleteIconV5Component } from './delete-icon-v5/delete-icon-v5.component';
import { RefreshIconV5Component } from './refresh-icon-v5/refresh-icon-v5.component';
import { ThumbUpIconV5Component } from './thumb-up-icon-v5/thumb-up-icon-v5.component';
import { ThumbDownIconV5Component } from './thumb-down-icon-v5/thumb-down-icon-v5.component';
import { MenuPlaylistIconV5Component } from './menu-playlist-icon-v5/menu-playlist-icon-v5.component';
import { MenuMusicChannelIconV5Component } from './menu-music-channel-icon-v5/menu-music-channel-icon-v5.component';
import { MenuCalendarIconV5Component } from './menu-calendar-icon-v5/menu-calendar-icon-v5.component';
import { MenuSearchIconV5Component } from './menu-search-icon-v5/menu-search-icon-v5.component';
import { MenuHomeIconV5Component } from './menu-home-icon-v5/menu-home-icon-v5.component';
import { MenuSettingsIconV5Component } from './menu-settings-icon-v5/menu-settings-icon-v5.component';
import { ArrowBack36pxIconV5Component } from './arrow-back36px-icon-v5/arrow-back36px-icon-v5.component';
import { TweakContextIconV5Component } from './tweak-context-icon-v5/tweak-context-icon-v5.component';
import { TweakPlaylistIconV5Component } from './tweak-playlist-icon-v5/tweak-playlist-icon-v5.component';
import { Chat20pxIconV5Component } from './chat20px-icon-v5/chat20px-icon-v5.component';
import { Help20pxIconV5Component } from './help20px-icon-v5/help20px-icon-v5.component';
import { Mail20pxIconV5Component } from './mail20px-icon-v5/mail20px-icon-v5.component';
import { Phone20pxIconV5Component } from './phone20px-icon-v5/phone20px-icon-v5.component';
import { AngleLeftIconV5Component } from './angle-left-icon-v5/angle-left-icon-v5.component';
import { AngleRightIconV5Component } from './angle-right-icon-v5/angle-right-icon-v5.component';
import { ClockIconV5Component } from './clock-icon-v5/clock-icon-v5.component';
import { AddToPlaylistV5Component } from './add-to-playlist-v5/add-to-playlist-v5.component';
import { AddToQueueV5Component } from './add-to-queue-v5/add-to-queue-v5.component';
import { CloseV5Component } from './close-v5/close-v5.component';
import { ZoneIconV5Component } from './zone-icon-v5/zone-icon-v5.component';
import { ExternalLinkIconV5Component } from './external-link-icon-v5/external-link-icon-v5.component';
import { ResetIconV5Component } from './reset-icon-v5/reset-icon-v5.component';
import { ThumbUpActiveIconV5Component } from './thumb-up-active-icon-v5/thumb-up-active-icon-v5.component';
import { ThumbDownActiveIconV5Component } from './thumb-down-active-icon-v5/thumb-down-active-icon-v5.component';
import { SearchResultArtistIconV5Component } from './search-result-artist-icon-v5/search-result-artist-icon-v5.component';
import { SearchResultSongIconV5Component } from './search-result-song-icon-v5/search-result-song-icon-v5.component';
import { AutocompleteArtistIconV5Component } from './autocomplete-artist-icon-v5/autocomplete-artist-icon-v5.component';
import { AutocompleteSongIconV5Component } from './autocomplete-song-icon-v5/autocomplete-song-icon-v5.component';
import { StarIconV5Component } from './star-icon-v5/star-icon-v5.component';
import { PlusIconV5Component } from './plus-icon-v5/plus-icon-v5.component';
import { PlusRoundIconV5Component } from './plus-round-icon-v5/plus-round-icon-v5.component';
import { CheckmarkIconV5Component } from './checkmark-icon-v5/checkmark-icon-v5.component';
import { EditIconV5Component } from './edit-icon-v5/edit-icon-v5.component';
import { LockLargeIconV5Component } from './lock-large-icon-v5/lock-large-icon-v5.component';
import { MusicnoteIconV5Component } from './musicnote-icon-v5/musicnote-icon-v5.component';
import { DuplicateFineIconV5Component } from './duplicate-fine-icon-v5/duplicate-fine-icon-v5.component';
import { DuplicateIconV5Component } from './duplicate-icon-v5/duplicate-icon-v5.component';
import { GrabberIconV5Component } from './grabber-icon-v5/grabber-icon-v5.component';
import { AiStarsV5Component } from './ai-stars-v5/ai-stars-v5.component';
import { DownloadIconV5Component } from './download-icon-v5/download-icon-v5.component';



@NgModule({
  declarations: [
    AngleUpComponent,
    AngleDownComponent,
    PlayTrackComponent,
    TrackOptionsComponent,
    PlayIconV5Component,
    PauseIconV5Component,
    SkipIconV5Component,
    QueueFullIconV5Component,
    ShuffleIconV5Component,
    DeleteIconV5Component,
    RefreshIconV5Component,
    ThumbUpIconV5Component,
    ThumbDownIconV5Component,
    MenuPlaylistIconV5Component,
    MenuMusicChannelIconV5Component,
    MenuCalendarIconV5Component,
    MenuSearchIconV5Component,
    MenuHomeIconV5Component,
    MenuSettingsIconV5Component,
    ArrowBack36pxIconV5Component,
    TweakContextIconV5Component,
    TweakPlaylistIconV5Component,
    Chat20pxIconV5Component,
    Help20pxIconV5Component,
    Mail20pxIconV5Component,
    Phone20pxIconV5Component,
    AngleLeftIconV5Component,
    AngleRightIconV5Component,
    ClockIconV5Component,
    AddToPlaylistV5Component,
    AddToQueueV5Component,
    CloseV5Component,
    ZoneIconV5Component,
    ExternalLinkIconV5Component,
    ResetIconV5Component,
    ThumbUpActiveIconV5Component,
    ThumbDownActiveIconV5Component,
    SearchResultArtistIconV5Component,
    SearchResultSongIconV5Component,
    AutocompleteArtistIconV5Component,
    AutocompleteSongIconV5Component,
    StarIconV5Component,
    PlusIconV5Component,
    PlusRoundIconV5Component,
    CheckmarkIconV5Component,
    EditIconV5Component,
    LockLargeIconV5Component,
    MusicnoteIconV5Component,
    DuplicateFineIconV5Component,
    DuplicateIconV5Component,
    GrabberIconV5Component,
    AiStarsV5Component,
    DownloadIconV5Component
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AngleUpComponent,
    AngleDownComponent,
    PlayTrackComponent,
    TrackOptionsComponent,
    PlayIconV5Component,
    PauseIconV5Component,
    SkipIconV5Component,
    QueueFullIconV5Component,
    ShuffleIconV5Component,
    DeleteIconV5Component,
    RefreshIconV5Component,
    ThumbUpIconV5Component,
    ThumbDownIconV5Component,
    MenuPlaylistIconV5Component,
    MenuMusicChannelIconV5Component,
    MenuCalendarIconV5Component,
    MenuSearchIconV5Component,
    MenuHomeIconV5Component,
    MenuSettingsIconV5Component,
    ArrowBack36pxIconV5Component,
    TweakContextIconV5Component,
    TweakPlaylistIconV5Component,
    Chat20pxIconV5Component,
    Help20pxIconV5Component,
    Mail20pxIconV5Component,
    Phone20pxIconV5Component,
    AngleLeftIconV5Component,
    AngleRightIconV5Component,
    ClockIconV5Component,
    AddToPlaylistV5Component,
    AddToQueueV5Component,
    CloseV5Component,
    ZoneIconV5Component,
    ExternalLinkIconV5Component,
    ResetIconV5Component,
    ThumbUpActiveIconV5Component,
    ThumbDownActiveIconV5Component,
    SearchResultArtistIconV5Component,
    SearchResultSongIconV5Component,
    AutocompleteArtistIconV5Component,
    AutocompleteSongIconV5Component,
    StarIconV5Component,
    PlusIconV5Component,
    PlusRoundIconV5Component,
    CheckmarkIconV5Component,
    EditIconV5Component,
    LockLargeIconV5Component,
    MusicnoteIconV5Component,
    DuplicateFineIconV5Component,
    DuplicateIconV5Component,
    GrabberIconV5Component,
    AiStarsV5Component,
    DownloadIconV5Component
  ]
})
export class IconsV5Module { }
