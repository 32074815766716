import { Component, OnDestroy } from '@angular/core';
import { MusicChannel } from '@model/musicChannel';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { MusicChannelService } from '@service/music-channel.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'tun-music-channel-groups-view',
  templateUrl: './music-channel-groups-view.component.html',
  styleUrls: ['./music-channel-groups-view.component.scss']
})
export class MusicChannelGroupsViewComponent implements OnDestroy{

  public get musicChannelsLoading$(){
    return this.musicChannelService.loading$
  }

  public get musicChannelsLoadingError$(){
    return this.musicChannelService.loadingError$
  }

  public get musicChannelGroups$(){
    return this.musicChannelService.musicChannelGroups$
  }

  constructor(
    private musicChannelService: MusicChannelService,
    private appV5StateService: AppV5StateService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onRetry(){
    this.musicChannelService.loadMusicChannelGroups();
  }

  public onShowDetails(musicChannel: MusicChannel){
    if (this.appV5StateService.showDetailsForMusicChannel == null){
      this.appV5StateService.showMusicChannelInTunifyBlue(musicChannel);
    }
  }
}
