import { Injectable } from '@angular/core';

import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '@service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService {

    constructor(private router: Router, private authService: AuthenticationService) { }

    canActivate(): boolean {
        if(this.authService.loggedIn){
            return false;
        }
        return true;
    }

}