<div class="wrapper">
  <div class="title">{{'calendars.label.title' | translate }}</div>

  <div *ngIf="(calendarGroupsLoading$ | async)" class="loaderContainer">
    <mat-spinner class="loading-spinner"></mat-spinner>
    <span class="loadingText">{{'general.status.loading' | translate}}</span>
  </div>

  <div *ngIf="(calendarGroupsLoadingError$ | async)" class="errorContainer">
    <p>{{'general.status.error.message' | translate}}</p>
    <button (click)="onRetry()" class="retryButton">
      <p>
        {{'general.status.error.retry' | translate }}
      </p>
    </button>
  </div>

  <div *ngIf="!(calendarGroupsLoading$ | async) && !(calendarGroupsLoadingError$ | async)" class="scrollContainer">
    <!-- custom calendars -->
    <tun-calendar-group-view
      class="calendarGroup first"
      [groupName]="'calendars.user.label' | translate"
      [calendars]="customCalendars$ | async"
      [showAddButton]="true"
      (addCalendar)="onAddCalendar()"
      [lockOverlayMessage]="(accessToCustomCalendars$ | async) ?  null : ('accessRight.content.customCalendars' | translate)"
    ></tun-calendar-group-view>

    <!-- calendarGroups -->
    <ng-container  *ngFor="let calendarGroup of (calendarGroups$ | async); index as i">
      <tun-calendar-group-view
      class="calendarGroup {{ (!(showCustomCalendars$ | async) && i == 0)  ? 'first' : ''}}"
      [groupName]="calendarGroup.name"
      [calendars]="calendarGroup.calendars"
    ></tun-calendar-group-view>
    </ng-container>

  </div>
</div>
