<div class="content-wrapper">

  <div class="title-container">
    <div class="title">{{'playlist.label.title' | translate }}</div>

    <button class="icon-button" (click)="onClose()">
      <tun-close-v5 class="icon icon-large-scaled-svg-button"></tun-close-v5>
    </button>

  </div>

  <tun-select-playlist-view
    class="contentContainer"
    (closeWithPlaylist)="onCloseWithPlaylist($event)"
    [confirmButtonTranslateableText]="confirmButtonTranslateableText"
  >

  </tun-select-playlist-view>






</div>
