<div #playerWrapper (tunResize)="onResize($event)" class="wrapper">

  <div
    *ngIf="!useTunifyV5"
    class="redirect-container playerBackground"
  >

    <div class="fade-borders">
      <div
        [style.height.px]="heightPerItem$ | async"
        [style.padding-top.px]="2 * (heightPerItem$ | async)"
      >
        <tun-tunify-icon></tun-tunify-icon>
      </div>

      <div class="spinner-container">
        <mat-spinner class="redirect-spinner"></mat-spinner>
      <span class="redirect-text">{{'general.redirecting' | translate}}</span>
      </div>
    </div>

  </div>


  <div *ngIf="useTunifyV5" class="contentWrapper {{(showingPopup$ | async) ? 'blurredContent' : ''}}">
    <tun-main-menu class="menuContainer"></tun-main-menu>
    <tun-main-content class="contentContainer"></tun-main-content>
    <tun-main-player class="playerContainer"></tun-main-player>
  </div>

  <!-- Popup: choose playlist-->
  <div #choosePlaylistPopupWrapper *ngIf="showTrackOverlay$ | async" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, choosePlaylistPopupWrapper)">
    <div class="menuContainer noEvents"></div>
    <div #choosePlaylistContentContainer class="contentContainer popupContent" (click)="onClickOutside($event, choosePlaylistContentContainer)">
      <tun-overlay-song-info
        class="select-playlist-overlay"
        [trackInfoOverlayData]="trackInfoOverlayData$ | async"
        (close)="onClosePopup($event)"
      >
      </tun-overlay-song-info>
    </div>
    <div class="playerContainer noEvents"></div>



  </div>
</div>
