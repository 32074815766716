import { Injectable } from '@angular/core';
import { LoggerService } from '../loggers/logger.service';
import { MusicCollection } from '@model/musicCollection';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { finalize, takeUntil, filter } from 'rxjs/operators';
import { createClassObjectForMusicCollection } from './util/musicCollectionUtil';
import { Context } from '@model/context';
import { Playlist } from '@model/playlist';
import { TweakInfo, TweakInfoHolder } from '@model/tweakInfo';
import { TweakStatus } from '@model/enums/tweakStatus';
import { MusicCollectionApiService } from '@service/api/music-collection-api.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MusicCollectionService {

  private LOGGER_CLASSNAME = 'MusicCollectionService';

  // we will use this observable to cancel any previous http request to load the tweakInfo that is still running
  private tweakInfoWillStartToLoadSubscriber: Subject<number> = new Subject<number>();

  // we will use this observable to cancel any previous http request to load the tweakInfo that is still running
  private tweakInfoForCalendarItemWillStartToLoadSubscriber: Subject<number> = new Subject<number>();

  constructor(
    private musicCollectionApiService: MusicCollectionApiService,
    private logger: LoggerService
    ) { }



    public loadMusicCollectionDetails(musicCollection: MusicCollection) {

      this.logger.debug(this.LOGGER_CLASSNAME, 'loadMusicCollectionDetails', 'loading musicCollection details started for ' + musicCollection.title + '(' + musicCollection.id + ')');

      if (!musicCollection.detailsLoading) {

        musicCollection.detailsLoading = true;
        musicCollection.detailsLoadingError = null;
        musicCollection.refetchNeededForConflict = false;
        //musicCollection.detailsLoaded = false;
        // erase old data?

        const musicCollectionObservable: Observable<MusicCollection> = this.musicCollectionApiService.loadMusicCollectionDetails(musicCollection);

        musicCollectionObservable.pipe(
          finalize(() => {
              musicCollection.detailsLoading = false;
          }))
          .subscribe(
          (data) => {
              this.logger.debug(this.LOGGER_CLASSNAME, 'loadMusicChannelGroups', 'data : ' + data);

              // we should construct real objects with the correct type (so we can use instanceof to check the type)
              const realObject = createClassObjectForMusicCollection(data);

              musicCollection.copyDetailsFrom(realObject);
              musicCollection.localChanges = false;

              if (musicCollection instanceof Context && realObject instanceof Context) {
                //by now, the details
                musicCollection.detailsLoaded = true;

                // we will also load the tweakInfo once the changeableParameters are loaded
                this.loadTweakInfo(musicCollection);
              } else if (musicCollection instanceof Playlist && realObject instanceof Playlist) {
                musicCollection.detailsLoaded = true;
              }else{
                musicCollection.detailsLoadingError = 'MusicCollection type not recognized';
              }

              musicCollection.detailsloadingDone.next(true);
          },
          (error: unknown) => {
            let errMsg = 'Error';
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';

            }

              console.error(errMsg);
              console.log(musicCollection);
              musicCollection.detailsLoadingError = 'GeneralError';
              musicCollection.detailsloadingDone.next(false);
          });
      }
    }

    public loadTweakInfo(tweakInfoHolder: TweakInfoHolder) {

      this.logger.debug(this.LOGGER_CLASSNAME, 'loadContextTweakInfo', 'loading context tweak info started for contextId ' + tweakInfoHolder.contextId);

      // this will trigger the finalize of any previous http request for the same context
      this.tweakInfoWillStartToLoadSubscriber.next(tweakInfoHolder.contextId);
      const contextTweakInfoWillLoadAgain = this.tweakInfoWillStartToLoadSubscriber.pipe(filter((musicCollectionId) => musicCollectionId == tweakInfoHolder.contextId));

      //tweakInfoHolder.tweakInfoLoaded = false;
      tweakInfoHolder.tweakInfoLoading = true;
      tweakInfoHolder.tweakInfoLoadingError = null;

      const contextObservable: Observable<TweakInfo> = this.musicCollectionApiService.loadTweakInfo(tweakInfoHolder.contextId, tweakInfoHolder.changeableParameters);

      if (contextObservable) {
        contextObservable.pipe(
          finalize(() => {
            this.logger.debug(this.LOGGER_CLASSNAME, 'loadContextTweakInfo', 'finalize for ' + tweakInfoHolder.contextId + ' loading: ' + tweakInfoHolder.tweakInfoLoading);
            if (!tweakInfoHolder.tweakInfoLoading) {
              this.logger.error(this.LOGGER_CLASSNAME, 'loadContextTweakInfo', 'finalize for ' + tweakInfoHolder.contextId + ' but we are not loading!');
            }
            tweakInfoHolder.tweakInfoLoading = false;
          }),
          takeUntil(contextTweakInfoWillLoadAgain)
        ).subscribe(
          (data) => {
            this.logger.debug(this.LOGGER_CLASSNAME, 'loadContextTweakInfo', 'data received for ' + tweakInfoHolder.contextId + ': ' + data);

            tweakInfoHolder.tweakInfo = new TweakInfo(data);
            this.alterTweakInfoForTesting(tweakInfoHolder.tweakInfo);

            tweakInfoHolder.tweakInfoLoaded = true;
          }
        ,
        error => {
          const errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';
          this.logger.error(this.LOGGER_CLASSNAME, 'loadContextTweakInfo', 'err: ' + errMsg);
          tweakInfoHolder.tweakInfoLoadingError = 'GeneralError';
        });
      }
    }



    private alterTweakInfoForTesting(tweakInfo: TweakInfo) {
      // only alter on mocked backend
      if (environment.mockBackend) {
        const percentage = Math.round(Math.random() * 100);
        tweakInfo.percentage = percentage;
        if (percentage < 20) {
          tweakInfo.status = TweakStatus.ERROR;
          if (percentage < 2) {
            tweakInfo.percentage = 100;
          }
        } else if (percentage < 40) {
          tweakInfo.status = TweakStatus.WARNING;
        }
      }
    }
}
