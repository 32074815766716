import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { calendarGroups } from './mock/calendars/calendar-groups';
import { calendar_12 } from './mock/calendars/calendar_12';
import { calendar_34595 } from './mock/calendars/calendar_34595';
import { calendar_34611 } from './mock/calendars/calendar_34611';
import { zoneConfiguration, musicSelection } from './mock/zoneConfiguration';
import { customPlaylists } from './mock/custom-playlists';
import { musicCollection_8005 } from './mock/musicCollections/8005';
import { musicCollection_8992 } from './mock/musicCollections/8992';
import { musicCollection_8070 } from './mock/musicCollections/8070';
import { musicCollection_8072 } from './mock/musicCollections/8072';
import { musicCollection_8073 } from './mock/musicCollections/8073';
import { musicCollection_8074 } from './mock/musicCollections/8074';
import { musicCollection_8077 } from './mock/musicCollections/8077';
import { musicCollection_8181 } from './mock/musicCollections/8181';
import { musicCollection_8182 } from './mock/musicCollections/8182';
import { musicCollection_8184 } from './mock/musicCollections/8184';
import { musicCollection_8185 } from './mock/musicCollections/8185';
import { musicCollection_8200 } from './mock/musicCollections/8200';
import { musicCollection_8201 } from './mock/musicCollections/8201';
import { musicCollection_8218 } from './mock/musicCollections/8218';
import { musicCollection_8219 } from './mock/musicCollections/8219';
import { musicCollection_8622 } from './mock/musicCollections/8622';
import { musicCollection_8991 } from './mock/musicCollections/8991';
import { musicCollection_8127 } from './mock/musicCollections/8127';
import { musicCollection_8133 } from './mock/musicCollections/8133';
import { musicCollection_8143 } from './mock/musicCollections/8143';
import { musicCollection_8147 } from './mock/musicCollections/8147';
import { musicCollection_8149 } from './mock/musicCollections/8149';
import { musicCollection_8134 } from './mock/musicCollections/8134';
import { musicCollection_8619 } from './mock/musicCollections/8619';
import { musicChannelGroups_limit } from './mock/musicChannelGroups_limit';
import { waitingQueue } from './mock/waitingQueue';
import { radioQueue } from './mock/radioQueue';
import { musicCollection_8223 } from './mock/musicCollections/8223';
import { search1 } from './mock/search/search1';
import { search2 } from './mock/search/search2';
import { custom_calendars } from './mock/calendars/customCalendars';
import { calendar_35922 } from './mock/calendars/calendar_35922';
import { calendar_35773 } from './mock/calendars/calendar_35773';
import { favorites } from './mock/musicCollections/favorites';
import { banlist } from './mock/musicCollections/banlist';
import { searchMusicChannelGroups } from './mock/searchMusicChannelGroups';
import { musicCollection_8321 } from './mock/musicCollections/8321';
import { subscriptionInfo} from './mock/subscriptionInfo';
import { zoneInfo } from './mock/zoneInfo';
import { createCalendar } from './mock/calendars/create-calendar';
import { createCalendarItem } from './mock/calendars/createCalendarItem';
import { musicCollection_8551 } from './mock/musicCollections/8551';
import { publicConfigs } from './mock/publicConfig';
import { searchTrack } from './mock/track';
import { recommenderCollection } from './mock/recommender-collection';

@Injectable({
  providedIn: 'root'
})
export class InMemoryDataService implements InMemoryDbService{

    static mockedMusicCollectionIds = [8005, 8619, 8399, 8149, 8134, 8310, 8147, 8127,8312,8204,8311,8143,8202,8133,8252,8315,8321,8622,8181,8070,8182,8200,8073,8074,8201,8218,8992,8185,8077,8991,8219,8072,8184,8223,8551];
    static defaultMockedPlaylistId = 8619;
    static defaultMockedContextId = 8321;
    static defaultMockedContextId2 = 8551;
    static mockedTweakInfoMusicCollectionIds = [8005];
    static defaultMockedTweakInfoMusicCollectionId = 8005;
    static mockedCalendarIds = [12, 34595, 34611, 35773, 35922];
    static defaultMockedCalendarId = 12;

  createDb(){
    const authentication = {
        "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJEcmllc0MiLCJzY29wZSI6WyJyZWFkIl0sImFwcElkIjo0NCwiZXhwIjoxNTU3OTExMjg4LCJ1c2VySWQiOjMzMTQxMiwiYXV0aG9yaXRpZXMiOlsiUkVBRF9QUklWSUxFR0UiXSwianRpIjoiYjUwYWI0ZGEtZGJkMC00NGQwLTllMjgtNWY1OWM2MzE2OWJiIiwiY2xpZW50X2lkIjoiNDQiLCJ1c2VybmFtZSI6IkRyaWVzQyJ9.DDKFnpWucdbSdI-SNXJIDq8kb-GFRdrceCgXcZVNyRs",
        "token_type": "bearer",
        "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJEcmllc0MiLCJzY29wZSI6WyJyZWFkIl0sImFwcElkIjoiNjAiLCJhdGkiOiI2OGMzODVlYi00ZDYzLTQyN2UtOGNmNC0wYmQ3MTYwNDNhYjciLCJleHAiOjE1NjA4NDQyOTQsInVzZXJJZCI6IjMzMTQxMiIsImF1dGhvcml0aWVzIjpbIlJFQURfUFJJVklMRUdFIl0sImp0aSI6ImJiMDZiZGYzLTM5NDItNDYwNi1iOWYzLTBjNTMwNTEwMDg2MiIsImNsaWVudF9pZCI6IjYwIiwidXNlcm5hbWUiOiJEcmllc0MifQ.OIF3S7Ba794w-zolPkbGvFaTNKX3Tz6s9uMYMa-BnJs",
        "expires_in": 5183999,
        "userId": 331412
    };

    const refresh_token = {
      "id": 1,
      "zoneId": 331412,
      "refreshToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9"
    }

    const access_token = {
      "accessToken": "eyJ1c2VyX25hbWUiOiJEcmllc0MiLCJzY29wZSI6WyJyZ"
    }

    const zone_connection_info = {
      "name": "Zone 1",
      "location": "Martien van Zuijlen",
      "appFamilyId": 1,
      "externalZoneId": "5935"
  }

    const playInfo = {
        "currentAudioFile": {
            "@type": "Song",
            "id": "411638",
            "duration": 234571,
            "group": "Hayden James & Running Touch",
            "location": "2-38/000411638d6ad46.mp3",
            "title": "Better Together",
            "origin": {                 "type": "musicCollection",                 "data": "8619"             },

            "composer": null,
            "bpm": 116,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 75,
            "endAudioSignal": 234089,
            "fadeBeginPosition": 233089,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
            },
        "currentTime": 10,
        "currentState": "Playing",
        "currentAudioFileVersion": "1",
        "queue": [{
                    "@type": "Song",
                    "id": "411560",
                    "duration": 209945,
                    "group": "Karol G & Maluma",
                    "location": "2-60/000411560d289ab.mp3",
                    "title": "Créeme",
                    "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                    "composer": null,
                    "bpm": 97,
                    "musicFeel": "Reggae",
                    "localAvailable": null,
                    "version": "Original",
                    "year": "2018",
                    "mood": "Bright",
                    "dancingStyle": "Reggaeton",
                    "startAudioSignal": 0,
                    "endAudioSignal": 208833,
                    "fadeBeginPosition": 197833,
                    "musicStyle": "Pop",
                    "subStyle": "Basic",
                    "genreDescription": "Pop Reggae",
                    "rhythmDescription": "Reggaeton",
                    "tunifyTipValue": "Tunify Tip"
                },
                {
                    "@type": "Song",
                    "id": "409261",
                    "duration": 233787,
                    "group": "Oscar & The Wolf",
                    "location": "2-61/000409261dc1f74.mp3",
                    "title": "On Fire",
                    "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                    "composer": null,
                    "bpm": 117,
                    "musicFeel": "Pop",
                    "localAvailable": null,
                    "version": "Original",
                    "year": "2018",
                    "mood": "Bright",
                    "dancingStyle": "Free",
                    "startAudioSignal": 50,
                    "endAudioSignal": 231342,
                    "fadeBeginPosition": 214787,
                    "musicStyle": "Pop",
                    "subStyle": "Basic",
                    "genreDescription": "Pop",
                    "rhythmDescription": "Free",
                    "tunifyTipValue": null
                }
            ],
        "queueVersion": "1",
        "radioQueue": [{
            "@type": "Song",
            "id": "409746",
            "duration": 275968,
            "group": "Jessie Ware",
            "location": "2-46/00040974683e29e.mp3",
            "title": "Overtime",
            "origin": {                 "type": "musicCollection",                 "data": "8619"            },

            "composer": null,
            "bpm": 115,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 275782,
            "fadeBeginPosition": 266708,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": "412971",
            "duration": 223368,
            "group": "Loïc Nottet",
            "location": "2-71/000412971a6a182.mp3",
            "title": "On Fire",
            "origin": {                 "type": "musicCollection",                 "data": "8619"            },

            "composer": null,
            "bpm": 70,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 1,
            "endAudioSignal": 221923,
            "fadeBeginPosition": 213923,
            "musicStyle": "Electronic",
            "subStyle": "Basic",
            "genreDescription": "Electronic Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": "411629",
            "duration": 204833,
            "group": "Alle Farben & ILIRA",
            "location": "2-29/00041162931a1ce.mp3",
            "title": "Fading",
            "origin": {                 "type": "musicCollection",                 "data": "8619"            },

            "composer": null,
            "bpm": 122,
            "musicFeel": "Dance",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 3,
            "endAudioSignal": 203721,
            "fadeBeginPosition": 194684,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Dance",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": "410616",
            "duration": 278330,
            "group": "Friendly Fires",
            "location": "2-16/0004106166be0b8.mp3",
            "title": "Heaven Let Me In",
            "origin": {                 "type": "musicCollection",                 "data": "8619"            },

            "composer": null,
            "bpm": 126,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 276329,
            "fadeBeginPosition": 259885,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": "412453",
            "duration": 164234,
            "group": "Sultan & Ned Shepard",
            "location": "2-53/000412453c6ba18.mp3",
            "title": "Where Are You?",
            "origin": {                 "type": "musicCollection",                 "data": "8619"            },

            "composer": null,
            "bpm": 124,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 163122,
            "fadeBeginPosition": 162122,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": "410235",
            "duration": 227187,
            "group": "Little Mix ft Nicki Minaj",
            "location": "2-35/000410235f2caaf.mp3",
            "title": "Woman Like Me",
            "origin": {                 "type": "musicCollection",                 "data": "8619"            },

            "composer": null,
            "bpm": 75,
            "musicFeel": "Reggae",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 9,
            "endAudioSignal": 226409,
            "fadeBeginPosition": 212075,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Reggae",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        }],
        "radioQueueVersion": "1",
        "radioQueueValiditySeconds": 300,
        "nextRadioQueue": [
            {
                "@type": "Song",
                "id": "410919",
                "duration": 213235,
                "group": "Sheridan Smith",
                "location": "2-19/000410919da3e98.mp3",
                "title": "Priceless",
                "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                "composer": null,
                "bpm": 142,
                "musicFeel": "Soul",
                "localAvailable": null,
                "version": "Original",
                "year": "2018",
                "mood": "Bright",
                "dancingStyle": "Free",
                "startAudioSignal": 96,
                "endAudioSignal": 210457,
                "fadeBeginPosition": 196123,
                "musicStyle": "Pop",
                "subStyle": "Basic",
                "genreDescription": "Pop Soul",
                "rhythmDescription": "Free",
                "tunifyTipValue": "Tunify Tip"
            },
            {
                "@type": "Song",
                "id": "403090",
                "duration": 219957,
                "group": "Clean Bandit ft Demi Lovato",
                "location": "2-90/000403090e90486.mp3",
                "title": "Solo",
                "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                "composer": null,
                "bpm": 105,
                "musicFeel": "Electronic",
                "localAvailable": null,
                "version": "Original",
                "year": "2018",
                "mood": "Bright",
                "dancingStyle": "Free",
                "startAudioSignal": 1,
                "endAudioSignal": 219697,
                "fadeBeginPosition": 209845,
                "musicStyle": "Pop",
                "subStyle": "Basic",
                "genreDescription": "Pop Electronic",
                "rhythmDescription": "Free",
                "tunifyTipValue": "Tunify Tip"
            },
            {
                "@type": "Song",
                "id": "411238",
                "duration": 198578,
                "group": "Milow & Matt Simons",
                "location": "2-38/00041123883f3ff.mp3",
                "title": "Lay Your Worry Down",
                "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                "composer": null,
                "bpm": 76,
                "musicFeel": "Pop",
                "localAvailable": null,
                "version": "Original",
                "year": "2018",
                "mood": "Social",
                "dancingStyle": "Free",
                "startAudioSignal": 102,
                "endAudioSignal": 198133,
                "fadeBeginPosition": 192466,
                "musicStyle": "Pop",
                "subStyle": "Basic",
                "genreDescription": "Pop",
                "rhythmDescription": "Free",
                "tunifyTipValue": "Tunify Tip"
            },
            {
                "@type": "Song",
                "id": "409228",
                "duration": 194222,
                "group": "Shawn Mendes & Zedd",
                "location": "2-28/00040922849a1ee.mp3",
                "title": "Lost In Japan (Remix)",
                "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                "composer": null,
                "bpm": 107,
                "musicFeel": "Electronic",
                "localAvailable": null,
                "version": "Remix",
                "year": "2018",
                "mood": "Bright",
                "dancingStyle": "Free",
                "startAudioSignal": 209,
                "endAudioSignal": 191110,
                "fadeBeginPosition": 180777,
                "musicStyle": "Pop",
                "subStyle": "Basic",
                "genreDescription": "Pop Electronic",
                "rhythmDescription": "Free Dance",
                "tunifyTipValue": "Tunify Tip"
            },
            {
                "@type": "Song",
                "id": "413880",
                "duration": 277673,
                "group": "Rudimental",
                "location": "2-80/00041388004974c.mp3",
                "title": "They Don't Care About Us",
                "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                "composer": null,
                "bpm": 120,
                "musicFeel": "Electronic",
                "localAvailable": null,
                "version": "Original",
                "year": "2018",
                "mood": "Bright",
                "dancingStyle": "Free",
                "startAudioSignal": 0,
                "endAudioSignal": 267450,
                "fadeBeginPosition": 261673,
                "musicStyle": "Pop",
                "subStyle": "Basic",
                "genreDescription": "Pop Electronic",
                "rhythmDescription": "Free Dance",
                "tunifyTipValue": null
            },
            {
                "@type": "Song",
                "id": "413741",
                "duration": 175052,
                "group": "Netsky, Toulouse & StarGate",
                "location": "2-41/00041374194acf3.mp3",
                "title": "Voodoo Magic",
                "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                "composer": null,
                "bpm": 170,
                "musicFeel": "Pop",
                "localAvailable": null,
                "version": "Original",
                "year": "2018",
                "mood": "Bright",
                "dancingStyle": "Free",
                "startAudioSignal": 0,
                "endAudioSignal": 169274,
                "fadeBeginPosition": 168940,
                "musicStyle": "Electronic",
                "subStyle": "Basic",
                "genreDescription": "Electronic Pop",
                "rhythmDescription": "Free",
                "tunifyTipValue": "Tunify Tip"
            },
            {
                "@type": "Song",
                "id": "409335",
                "duration": 162412,
                "group": "Olly Murs ft Snoop Dogg",
                "location": "2-35/0004093350cea3d.mp3",
                "title": "Moves",
                "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                "composer": null,
                "bpm": 101,
                "musicFeel": "Pop",
                "localAvailable": null,
                "version": "Original",
                "year": "2018",
                "mood": "Bright",
                "dancingStyle": "Free",
                "startAudioSignal": 0,
                "endAudioSignal": 161634,
                "fadeBeginPosition": 161300,
                "musicStyle": "Pop",
                "subStyle": "Basic",
                "genreDescription": "Pop",
                "rhythmDescription": "Free",
                "tunifyTipValue": null
            },
            {
                "@type": "Song",
                "id": "412894",
                "duration": 231558,
                "group": "Clean Bandit, KYLE & Big Boi",
                "location": "2-94/000412894230c31.mp3",
                "title": "Out At Night",
                "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                "composer": null,
                "bpm": 105,
                "musicFeel": "Electronic",
                "localAvailable": null,
                "version": "Original",
                "year": "2018",
                "mood": "Bright",
                "dancingStyle": "Free",
                "startAudioSignal": 0,
                "endAudioSignal": 228780,
                "fadeBeginPosition": 228076,
                "musicStyle": "Pop",
                "subStyle": "Basic",
                "genreDescription": "Pop Electronic",
                "rhythmDescription": "Free",
                "tunifyTipValue": "Tunify Tip"
            },
            {
                "@type": "Song",
                "id": "412699",
                "duration": 178256,
                "group": "Rita Ora",
                "location": "2-99/0004126994a3d10.mp3",
                "title": "Only Want You",
                "origin": {                 "type": "musicCollection",                 "data": "8619"            },

                "composer": null,
                "bpm": 82,
                "musicFeel": "Electronic",
                "localAvailable": null,
                "version": "Original",
                "year": "2018",
                "mood": "Social",
                "dancingStyle": "Free",
                "startAudioSignal": 0,
                "endAudioSignal": 176107,
                "fadeBeginPosition": 163107,
                "musicStyle": "Pop",
                "subStyle": "Basic",
                "genreDescription": "Pop Electronic",
                "rhythmDescription": "Free",
                "tunifyTipValue": "Tunify Tip"
            }
        ],
        "nextRadioQueueVersion": "n-1"
    };

    const tweakInfo = [
        {
            "id": 8005,
            "percentage": 80,
            "status": "Normal",
            "numberTweakedTracks": 1800,
            "numberTotalTracks": 2000
        }
    ];

    const musicCollections = [
        musicCollection_8070,
        musicCollection_8072,
        musicCollection_8073,
        musicCollection_8074,
        musicCollection_8077,
        musicCollection_8181,
        musicCollection_8182,
        musicCollection_8184,
        musicCollection_8185,
        musicCollection_8200,
        musicCollection_8201,
        musicCollection_8218,
        musicCollection_8219,
        musicCollection_8223,
        musicCollection_8622,
        musicCollection_8991,
        musicCollection_8992,
        musicCollection_8005,
        musicCollection_8127,
        musicCollection_8133,
        musicCollection_8134,
        musicCollection_8143,
        musicCollection_8147,
        musicCollection_8149,
        musicCollection_8321,
        musicCollection_8551,
        {
            "@type": "Context",
            "id": 8202,
            "title": "Pop",
            "description": "13",
            "lastChanged": null,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "visible": true,
            "editable": false,
            "favourite": false,
            "selected": false,
            "mostPlayed": false,
            "defaultSelected": false
        },
        {
            "@type": "Context",
            "id": 8204,
            "title": "Soul & Funk",
            "description": "10",
            "lastChanged": null,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1985,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 6,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Funk",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1970,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1970,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "visible": true,
            "editable": false,
            "favourite": false,
            "selected": false,
            "mostPlayed": false,
            "defaultSelected": false
        },
        {
            "@type": "Context",
            "id": 8252,
            "title": "Oldies Pop",
            "description": "15",
            "lastChanged": null,
            "changeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1961,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1961,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1961,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1961,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "visible": true,
            "editable": false,
            "favourite": false,
            "selected": false,
            "mostPlayed": false,
            "defaultSelected": false
        },
        {
            "@type": "Context",
            "id": 8310,
            "title": "Rustig",
            "description": "05",
            "lastChanged": null,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Blues",
                            "selected": true
                        },
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1988,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 2,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "4"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Blues",
                            "selected": true
                        },
                        {
                            "value": "Country",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1980,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1980,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Energy",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Energy",
                    "minimum": 2,
                    "maximum": 4,
                    "step": 1,
                    "lowerSelection": 3,
                    "upperSelection": 4,
                    "metadataDisplayMinimum": "2",
                    "metadataDisplayMaximum": "4"
                }
            ],
            "visible": true,
            "editable": false,
            "favourite": false,
            "selected": false,
            "mostPlayed": false,
            "defaultSelected": false
        },
        {
            "@type": "Context",
            "id": 8311,
            "title": "R&B",
            "description": "11",
            "lastChanged": null,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 6,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Peaceful",
                            "selected": false
                        },
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        }
                    ]
                }
            ],
            "visible": true,
            "editable": false,
            "favourite": false,
            "selected": false,
            "mostPlayed": false,
            "defaultSelected": false
        },
        {
            "@type": "Context",
            "id": 8312,
            "title": "Dance",
            "description": "09",
            "lastChanged": null,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": false
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 6,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Style",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Style",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1990,
                    "maximum": 2018,
                    "step": 1,
                    "lowerSelection": 1990,
                    "upperSelection": 2018,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "BPM",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "BPM",
                    "minimum": 110,
                    "maximum": 150,
                    "step": 1,
                    "lowerSelection": 110,
                    "upperSelection": 150,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                }
            ],
            "visible": true,
            "editable": false,
            "favourite": false,
            "selected": false,
            "mostPlayed": false,
            "defaultSelected": false
        },
        {
            "@type": "Context",
            "id": 8315,
            "title": "Oldies Rock",
            "description": "16",
            "lastChanged": null,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hard Rock",
                            "selected": false
                        },
                        {
                            "value": "Prog Rock",
                            "selected": true
                        },
                        {
                            "value": "Psychedelic",
                            "selected": false
                        },
                        {
                            "value": "Punk",
                            "selected": false
                        },
                        {
                            "value": "Soft Rock",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1963,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1963,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Substyle",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Substyle",
                    "value": [
                        {
                            "value": "Basic",
                            "selected": true
                        },
                        {
                            "value": "Hard Rock",
                            "selected": false
                        },
                        {
                            "value": "Prog Rock",
                            "selected": true
                        },
                        {
                            "value": "Psychedelic",
                            "selected": false
                        },
                        {
                            "value": "Punk",
                            "selected": false
                        },
                        {
                            "value": "Soft Rock",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 1963,
                    "maximum": 1989,
                    "step": 1,
                    "lowerSelection": 1963,
                    "upperSelection": 1989,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 4,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "4",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": false
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": true
                        }
                    ]
                }
            ],
            "visible": true,
            "editable": false,
            "favourite": false,
            "selected": false,
            "mostPlayed": false,
            "defaultSelected": false
        },
        {
            "@type": "Context",
            "id": 8399,
            "title": "2014 - 2018",
            "description": "02",
            "lastChanged": null,
            "changeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "defaultChangeableParameter": [
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Music Tweak Style Feel",
                    "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
                    "metadataDisplayName": "Music Tweak Style Feel",
                    "value": [
                        {
                            "value": "Dance",
                            "selected": true
                        },
                        {
                            "value": "Electronic",
                            "selected": true
                        },
                        {
                            "value": "Pop",
                            "selected": true
                        },
                        {
                            "value": "Rock",
                            "selected": true
                        },
                        {
                            "value": "Soul",
                            "selected": true
                        },
                        {
                            "value": "Urban",
                            "selected": true
                        }
                    ]
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "year",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "year",
                    "minimum": 2014,
                    "maximum": 2019,
                    "step": 1,
                    "lowerSelection": 2014,
                    "upperSelection": 2019,
                    "metadataDisplayMinimum": "-",
                    "metadataDisplayMaximum": "+"
                },
                {
                    "@type": "ChangeableIntervalParameter",
                    "name": "Region Popularity",
                    "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Region Popularity",
                    "minimum": 5,
                    "maximum": 6,
                    "step": 1,
                    "lowerSelection": 5,
                    "upperSelection": 6,
                    "metadataDisplayMinimum": "5",
                    "metadataDisplayMaximum": "6"
                },
                {
                    "@type": "ChangeableSelectionParameter",
                    "name": "Mood",
                    "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
                    "metadataDisplayName": "Mood",
                    "value": [
                        {
                            "value": "Social",
                            "selected": true
                        },
                        {
                            "value": "Bright",
                            "selected": true
                        },
                        {
                            "value": "Dynamic",
                            "selected": false
                        }
                    ]
                }
            ],
            "visible": true,
            "editable": false,
            "favourite": false,
            "selected": false,
            "mostPlayed": false,
            "defaultSelected": false
        },
        musicCollection_8619
    ];


    const calendars = [calendar_12, calendar_34595, calendar_34611, calendar_35773, calendar_35922];



    return {    authentication: authentication,
                radio_musicChannelGroup: musicChannelGroups_limit,
                search_musicChannelGroup: searchMusicChannelGroups,
                musicCollection: musicCollections,
                "tweak-info": tweakInfo,
                playInfo: playInfo,
                customPlaylists: customPlaylists,
                waitingQueue: waitingQueue,
                radioQueue: radioQueue,
                favorites: favorites,
                banlist: banlist,
                calendarGroup: calendarGroups,
                calendar: calendars,
                zoneConfiguration: zoneConfiguration,
                search1: search1,
                search2: search2,
                customCalendar: custom_calendars,
                subscriptionInfo: subscriptionInfo,
                zoneInfo: zoneInfo,
                createCalendar: createCalendar,
                createCalendarItem: createCalendarItem,
                publicConfigs: publicConfigs,
                searchTrack: searchTrack,
                refreshTokenb: refresh_token,
                accessToken: access_token,
                zoneConnectionInfo: zone_connection_info,
                musicSelection: musicSelection,
                "recommender-colection": recommenderCollection
            };
  }

  constructor() { }
}
