import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { MusicChannel } from '@model/musicChannel';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { fadeAnimation, slideAnimation, viewPositionAnimation } from '@util/animations';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-music-channels-panel',
  templateUrl: './music-channels-panel.component.html',
  styleUrls: ['./music-channels-panel.component.scss'],
  animations: [
    viewPositionAnimation,
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, { params: { duration: 10 } })
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        useAnimation(slideAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            startX: 0,
            endX: '100%'
          }
        })
      ])
    ])
  ]
})
export class MusicChannelsPanelComponent implements OnDestroy {

  public get accessToMusicChannels$(){
    return  this.subscriptionsService.accessRights$
      .pipe(
        map(
          accessRights => {
            return accessRights == null || accessRights.accessBlue
          }
        )
      )
  }

  public get mainViewPosition$(){
    return this.appV5StateService.showDetailsForMusicChannel$
    .pipe(
      map(
        musicChannel => {
          if (musicChannel){
            return 'left';
          }
          return 'mid';
        }
      )
    )
  }

  public get showDetailsForMusicChannel$() {
    return this.appV5StateService.showDetailsForMusicChannel$;
  }

  constructor(
    private appV5StateService: AppV5StateService,
    private subscriptionsService: SubscriptionsService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }
}
