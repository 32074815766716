import { Calendar } from "@model/calendar";
import { CalendarItem } from "@model/calendarItem";
import { calculateCalendarItemStartTime } from "./calendarItemUtil";

// Check whether there is an overlap between
// an existing calendarItem and a modified calendarItem
export function calculateMaximumDuration(
  calendar: Calendar,
  calendarItemToCheck: CalendarItem
): number {
  const calendarItemStartTime: number = calculateCalendarItemStartTime(
    calendarItemToCheck
  );

  const calendarItemsThatStartLater: Array<
    CalendarItem
  > = calendar.calendarItems
    .filter(
      c =>
        c.day === calendarItemToCheck.day &&
        c.position === calendarItemToCheck.position &&
        calculateCalendarItemStartTime(c) >= calendarItemStartTime &&
        c.id !== calendarItemToCheck.id
    )
    .sort((a, b) => {
      return calculateCalendarItemStartTime(a) ===
        calculateCalendarItemStartTime(b)
        ? 0
        : calculateCalendarItemStartTime(a) < calculateCalendarItemStartTime(b)
        ? -1
        : 1;
    });

  let calendarItemToCheckShouldEndBefore = 24; //we can't go past midnight
  if (calendarItemsThatStartLater.length > 0) {
    const firstCalendarItemThatStartsLater: CalendarItem =
      calendarItemsThatStartLater[0];
    calendarItemToCheckShouldEndBefore = calculateCalendarItemStartTime(
      firstCalendarItemThatStartsLater
    );
  }

  return (calendarItemToCheckShouldEndBefore - calendarItemStartTime) * 60;
}

export function calculateMaxStartHour(
  calendar: Calendar,
  calendarItemToCheck: CalendarItem
): number {
  const calendarItemStartTime: number = calculateCalendarItemStartTime(
    calendarItemToCheck
  );

  const calendarItemsThatStartEarlier: Array<
    CalendarItem
  > = calendar.calendarItems
    .filter(
      c =>
        c.day === calendarItemToCheck.day &&
        c.position === calendarItemToCheck.position &&
        calculateCalendarItemStartTime(c) < calendarItemStartTime &&
        c.id !== calendarItemToCheck.id
    )
    .sort((a, b) => {
      return calculateCalendarItemStartTime(a) ===
        calculateCalendarItemStartTime(b)
        ? 0
        : calculateCalendarItemStartTime(a) < calculateCalendarItemStartTime(b)
        ? -1
        : 1;
    });

  let calendarItemToCheckShouldStartAfter = 0;
  if (calendarItemsThatStartEarlier.length > 0) {
    const lastCalendarItemThatStartsEarlier: CalendarItem =
      calendarItemsThatStartEarlier[calendarItemsThatStartEarlier.length - 1];
    calendarItemToCheckShouldStartAfter =
      calculateCalendarItemStartTime(lastCalendarItemThatStartsEarlier) * 60 +
      lastCalendarItemThatStartsEarlier.duration;
  }

  return calendarItemToCheckShouldStartAfter / 60;
}
