import { Component } from '@angular/core';

@Component({
  selector: 'tun-clock-icon-v5',
  templateUrl: './clock-icon-v5.component.html',
  styleUrls: ['./clock-icon-v5.component.scss']
})
export class ClockIconV5Component {

}
