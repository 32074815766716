import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Playlist } from '../../../model/playlist';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface TooManyTracksPopupData{
  playlist: Playlist;

}

@Component({
  selector: 'tun-too-many-tracks-popup',
  templateUrl: './too-many-tracks-popup.component.html',
  styleUrls: ['./too-many-tracks-popup.component.scss']
})
export class TooManyTracksPopupComponent implements OnInit, OnDestroy {

  public text = "";

  private playlist: Playlist = null;

  public static widthForPopup = '30vw';

  constructor(public dialogRef: MatDialogRef<TooManyTracksPopupComponent>,
              private translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: TooManyTracksPopupData)
  {

  }

  ngOnInit() {
    if (this.data && this.data.playlist != null){
      this.playlist = this.data.playlist;
      this.translateService.get("popup.tooManyTracks.playlist")
      .pipe(
        takeUntil(this.destroyed$))
      .subscribe(
        value => {
          if (value){
            this.text = value.replace("{0}", this.playlist.title);
          }
        }
      );
    }else{
      //waiting list
      this.translateService.get("popup.tooManyTracks.queue")
      .pipe(
        takeUntil(this.destroyed$))
      .subscribe(
        value => {
          if (value){
            this.text = value;
          }
        }
      );
    }
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
  }

  public onCancelClick(){
    this.dialogRef.close();
  }

}
