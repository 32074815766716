import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../data/authentication.service';
import { LoggerService } from '../loggers/logger.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Config } from '../config';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';

/**
 * Recommended music channels DTOs
 */
export class DTO_RecommendedMusicChannels {
  timestamp: number;
  musicChannels: DTO_RecommendedMusicChannel[];
}
export class DTO_RecommendedMusicChannel {
  musicChannelId: number;
  musicCollections: DTO_RecommendedMusicCollection[];
}
export class DTO_RecommendedMusicCollection {
  id: number;
}

/**
 * Last used DTOs
 */
export class DTO_lastUsed{
  timestamp: number;
  lastUsedItems: DTO_lastUsedItem[];

}

export class DTO_lastUsedItem{
  type: DTO_lastUsedItemType;
  calendarType?: string;
  id: number;
}

export enum DTO_lastUsedItemType{
  MUSIC_CHANNEL = 'MusicChannel',
  CALENDAR = 'Calendar'

}

@Injectable({
  providedIn: 'root'
})
export class RecommendationsApiService {

  private LOGGER_CLASSNAME = 'RecommendationsApiService';

  constructor(
    private httpClient: HttpClient,
    private loggerService: LoggerService) { }

  public loadRecommendedMusicChannels(): Observable<DTO_RecommendedMusicChannels>{
    let resultObservable: Observable<DTO_RecommendedMusicChannels>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadRecommendedMusicChannels', 'about to mock loadMusicChannelGroups request');
      resultObservable = this.httpClient.get<DTO_RecommendedMusicChannels>("api/recommender-collection");
    }else{
      const url = Config.api4Url_musicChannelRecommendations();
      resultObservable = this.httpClient.get<DTO_RecommendedMusicChannels>(url);
    }

    return resultObservable;
  }


  public loadLastUsedItems(): Observable<DTO_lastUsed>{
    let resultObservable: Observable<DTO_lastUsed>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadLastUsedItems', 'not supported in mock backend');
      return null;
    }else{
      const url = Config.api4Url_lastPlayed();
      resultObservable = this.httpClient.get<DTO_lastUsed>(url);
    }

    return resultObservable;
  }
}
