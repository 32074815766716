import { Injectable, OnDestroy } from "@angular/core";

import { Observable, BehaviorSubject, Subject } from "rxjs";
import { finalize, takeUntil } from 'rxjs/operators';

import { LoggerService } from '@service/loggers/logger.service';
import { environment } from 'src/environments/environment';
import { AuthenticationApiService, AuthenticateUserResponse } from '@service/api/authentication-api.service';
import { LocalStorageService } from '@service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';

@Injectable()
export class AuthenticationService implements OnDestroy{

    private LOGGER_CLASSNAME = AuthenticationService.name;

    constructor(
      private logger: LoggerService,
      private zoneConnectionsService: ZoneConnectionsService,
      private localStorage: LocalStorageService,
        private translateService: TranslateService
    ) {
      this.zoneConnectionsService.activeZoneConnection$
      .pipe(
        takeUntil(
          this.destroyed$
        )
      )
      .subscribe(
        (data) => {

          if (data != null){
            this._zoneIdSubject.next(data.zoneId);
          }else{
            this._zoneIdSubject.next(null);
          }

          //we are logged in as soon as we have an active zoneConnection
          this._loggedIn = data != null;


        }
      )
    }

    private destroyed$ = new Subject<void>();
  ngOnDestroy(){
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
  }

  get zoneId(): number{
    return this._zoneIdSubject.value;
  }
  private _zoneIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public zoneId$: Observable<number> = this._zoneIdSubject.asObservable();



  /*
    public forceUsername(username: string){
        if (this.localStorage.username && this.localStorage.username.toLowerCase() != username.toLowerCase()){
            this.localStorage.clearTokens();
        }
        this.localStorage.username = username;
    }


    //indiates that an access token is present, but we are currently not using it
    private set _reloginPossible(value: boolean) {
        if (this.reloginPossible !== value) {
            this._reloginPossibleSubject.next(value);
        }
    }
    get reloginPossible(): boolean {
        return this._reloginPossibleSubject.value;
    }
    private _reloginPossibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public reloginPossible$: Observable<boolean> = this._reloginPossibleSubject.asObservable();

    public relogin(){
        this.initFromLocalStorage();
    }
    */

    /*
    public initFromLocalStorage() {

        this._username = this.localStorage.username;
        this._password = this.localStorage.password;
        this._accessToken = this.localStorage.accessToken;
        this.refreshToken = this.localStorage.refreshToken;
        this._userId = this.localStorage.zoneId;

        this._loggedIn = this.accessToken && this.refreshToken && this.userId ? true : false;
    }

    private set _loggingIn(value: boolean) {
        if (this.loggingIn !== value) {
            this._loggingInSubject.next(value);
        }
    }
    get loggingIn(): boolean {
        return this._loggingInSubject.value;
    }
    private _loggingInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public logginIn$: Observable<boolean> = this._loggingInSubject.asObservable();
    */


    private set _loggedIn(value: boolean) {
        if (this.loggedIn !== value) {
            this._loggedInSubject.next(value);
        }
    }
    get loggedIn(): boolean {
        return this._loggedInSubject.value;
    }
    private _loggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loggedIn$: Observable<boolean> = this._loggedInSubject.asObservable();


    /*
    private set _loginError(value: string) {
        if (this.loginError !== value) {
            this._loginErrorSubject.next(value);
        }
    }
    get loginError(): string {
        return this._loginErrorSubject.value;
    }
    private _loginErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public loginError$: Observable<string> = this._loginErrorSubject.asObservable();

    //helper flag to indicate we need to show a buy subscription link with the error
    private _showSubscriptionLink = false;
    public get showSubscriptionLink(){
        return this._showSubscriptionLink;
    }

    private set _accessToken(value: string) {
        if (this.accessToken !== value) {
            this._accessTokenSubject.next(value);
        }
    }
    get accessToken(): string {
        return this._accessTokenSubject.value;
    }
    private _accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public accessToken$: Observable<string> = this._accessTokenSubject.asObservable();
    */

    /*
    private refreshToken: string = null;


    private set _userId (value: number){
        this._zoneIdSubject.next(value);
    }
    private get _userId(): number{
        return this._zoneIdSubject.value;
    }
    public get userId(): number{
        return this._userId;
    }

    get zoneId(): number{
        return this._zoneIdSubject.value;
    }
    private _zoneIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public zoneId$: Observable<number> = this._zoneIdSubject.asObservable();


    private set _username(value: string) {
        if (this.username !== value) {
            this._usernameSubject.next(value);
        }
    }
    get username(): string {
        return this._usernameSubject.value;
    }
    private _usernameSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public username$: Observable<string> = this._usernameSubject.asObservable();


    private set _password(value: string) {
        if (this.password !== value) {
            this._passwordSubject.next(value);
        }
    }
    get password(): string {
        return this._passwordSubject.value;
    }
    private _passwordSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public password$: Observable<string> = this._passwordSubject.asObservable();
    */

    /**
     * This method will authenticate the user and retrieve an access token
     */
    /*
    public authenticate(username, password): Observable<String> {

        this._accessToken = null;
        this.refreshToken = null;
        this._loggedIn = false;
        this._loggingIn = true;
        this._loginError = null;
        this._showSubscriptionLink = false;

        this._username = username;
        this._password = password;
        this.localStorage.username = username;

        let authenticationObservable = this.authenticationApi.authenticateUser(username, password);

        authenticationObservable.pipe(
            finalize(() => {
                this._loggingIn = false;
            }))
            .subscribe(
                (data) => {
                    this.logger.debug(this.LOGGER_CLASSNAME, "authenticate", "data : " + data);

                    //we need to store the password because we use logAudioFileActionLogs from old SOAP webservice
                    this.localStorage.password = password;

                    this.handleAuthenticationResponse(data);

                    this._loggedIn = true;
                },
                error => {

                    if (error.status === 400){
                        this._loginError = this.translateService.instant('login.error.credentials');
                    }else{
                        const shortErrInfo = error.status ? `(code ${error.status})` : "";
                        this._loginError = this.translateService.instant('login.error.general') + shortErrInfo;

                        this.logger.error(this.LOGGER_CLASSNAME, "authenticate", "failed to login " + shortErrInfo + error.statusText ? ` statusText: ${error.statusText}`: "" + error.message ? ` message: ${error.message}` : "" );
                    }

                }
            );

        return this.accessToken$;
    }


    private get _refreshingAccessToken(): boolean{
        return this._refreshingAccessTokenSubject.value;
    }
    private set _refreshingAccessToken(value: boolean){
        this._refreshingAccessTokenSubject.next(value);
    }
    public get refreshingAccessToken(): boolean{
        return this._refreshingAccessToken;
    }
    private _refreshingAccessTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public refreshingAccessToken$: Observable<boolean> = this._refreshingAccessTokenSubject.asObservable();

    public refreshAccessToken(){
        if (!this.refreshingAccessToken){
            this._refreshingAccessToken = true;

            let authenticationObservable = this.authenticationApi.refreshAccessToken(this.refreshToken);

            authenticationObservable.pipe(
                finalize(() => {
                    this._refreshingAccessToken = false;
                }))
                .subscribe(
                    (data) => {
                        this.logger.debug(this.LOGGER_CLASSNAME, "authenticate", "data : " + data);
                        this.handleAuthenticationResponse(data);
                    },
                    error => {
                        const errMsg = (error.message) ? error.message :
                            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                        this.logger.error(this.LOGGER_CLASSNAME, "refreshAccessToken", errMsg);
                        this.logout(true, this.translateService.instant("login.refreshToken.error"));
                    }
            );
        }
    }

    private handleAuthenticationResponse(data: AuthenticateUserResponse){
        try {
            const accessToken = data.access_token;
            const refreshToken = data.refresh_token;
            const userId = data.userId;

            this.refreshToken = refreshToken;
            this._userId = userId;
            this._accessToken = accessToken;

            this.localStorage.zoneId = userId;
            this.localStorage.accessToken = accessToken;
            this.localStorage.refreshToken = refreshToken;
        } catch (e) {
            this.logger.error(this.LOGGER_CLASSNAME, 'handleAuthenticationResponse', 'authenticate failed with error ' + e);
            this._loginError = "Exception";
        }
    }

    public logout(clearTokens: boolean, reason: string, showSubscriptionLink = false) {

        //flush all logging just before we logout
        this.logger.flushBuffer();

        this._accessToken = null;
        this.refreshToken = null;
        this._loggingIn = false;
        this._loginError = null;
        this._userId = null;

        if (clearTokens){
            this._password = "";
            this.localStorage.clearTokens();
        }else{
            this._reloginPossible = true;
        }

        if (reason != null){
            this._showSubscriptionLink = showSubscriptionLink;
            this._loginError = reason;
        }

        this._loggedIn = false;
    }
    */
}
