import { NgModule }     from '@angular/core';
import { RouterModule } from '@angular/router';
import {PlayerComponent} from "./player.component";
import { PlayerGuardService } from './playerGuardService';
@NgModule({
    imports: [
        RouterModule.forChild([
            { path: 'player', component: PlayerComponent, canDeactivate: [PlayerGuardService], canActivate: [PlayerGuardService]}
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class PlayerRoutingModule { }
