import { Injectable, OnDestroy } from '@angular/core';
import { ZoneConfigurationApiService, DTO_MusicSelection } from '../api/zone-configuration-api.service';
import { BehaviorSubject, merge, Observable, Subject, timer } from 'rxjs';
import { LoggerService } from '../loggers/logger.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Config } from '@service/config';

@Injectable({
  providedIn: 'root'
})
export class MusicSelectionService implements OnDestroy{

  private LOGGER_CLASSNAME = 'MusicSelectionService';

  constructor(
    private zoneConfigurationApiService: ZoneConfigurationApiService,
    private zoneConnectionsService: ZoneConnectionsService,
    private loggerService: LoggerService
  ) {
    this.zoneConnectionsService.activeZoneConnection$
        .pipe(
            takeUntil(this.destroyed$)
        )
        .subscribe(
            (zoneConnection) => {
              this.clearData();

              if (zoneConnection != null){
                this.loadMusicSelection();
              }
            }
        );
  }

  private clearData(){
    this._musicSelectionSubject.next(null);
    this._musicSelectionLoadError = false;

    this.musicSelectionTimestamp = undefined;
    this.timestampFromUpdateTrigger = undefined;
    this.refetchedToAvoidVersionConflict = false;
    this.refetchNeededForConflict = false;

    this.cancelPrevousLoadMusicSelectionRequests$.next();
    this.cancelPreviousSaveMusicSelectionSubject$.next();
  }

  /**
     * Music selection -> combination of properties that determine the current active music:
     * - streamMode (MusicChannel / Calendar)
     * - selected musicChannel
     * - selected Calendar
     */
   private get _musicSelection(): DTO_MusicSelection {
    return this._musicSelectionSubject.value;
}
private set _musicSelection(value: DTO_MusicSelection) {
    if (this._musicSelection !== value) {
        this._musicSelectionSubject.next(value);
    }
}

public get musicSelection(): DTO_MusicSelection{
    return this._musicSelection;
}
private _musicSelectionSubject: BehaviorSubject<DTO_MusicSelection> = new BehaviorSubject<DTO_MusicSelection>(null);
public musicSelection$: Observable<DTO_MusicSelection> = this._musicSelectionSubject.asObservable();



private get _musicSelectionLoading(): boolean {
  return this._musicSelectionLoadingSubject.value;
}
private set _musicSelectionLoading(value: boolean) {
  if (this._musicSelectionLoading !== value) {
      this._musicSelectionLoadingSubject.next(value);
  }
}

public get musicSelectionLoading(): boolean{
  return this._musicSelectionLoading;
}
private _musicSelectionLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
public musicSelectionLoading$: Observable<boolean> = this._musicSelectionLoadingSubject.asObservable();

//keep track when the zoneConfiguration is loaded once for this logged in zone
private get _musicSelectionLoaded(): boolean {
  return this._musicSelectionLoadedSubject.value;
}
private set _musicSelectionLoaded(value: boolean) {
  if (this._musicSelectionLoaded !== value) {
      this._musicSelectionLoadedSubject.next(value);
  }
}

public get musicSelectionLoaded(): boolean{
  return this._musicSelectionLoaded;
}
private _musicSelectionLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
public musicSelectionLoaded$: Observable<boolean> = this._musicSelectionLoadedSubject.asObservable();



private get _musicSelectionLoadError(): boolean {
  return this._musicSelectionLoadErrorSubject.value;
}
private set _musicSelectionLoadError(value: boolean) {
  if (this._musicSelectionLoadError !== value) {
      this._musicSelectionLoadErrorSubject.next(value);
  }
}

public get musicSelectionLoadError(): boolean{
  return this._musicSelectionLoadError;
}
private _musicSelectionLoadErrorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
public musicSelectionLoadError$: Observable<boolean> = this._musicSelectionLoadErrorSubject.asObservable();


/**
   * Versioning
   */
 private musicSelectionTimestamp: number = undefined;
 private timestampFromUpdateTrigger: number = undefined; //timestamp that we have received after a save or update event. This is used when the queue is fetched to check if we have the most recent version
 private refetchedToAvoidVersionConflict = false; //boolean to avoid we fetch multiple times in a row (avoid endless loop when the server messes up the version numbers)
  private refetchNeededForConflict = false; //boolean to indicate we received a conflict when saving: refetch
 public handleMusicSelectionUpdateInfo(timestamp: number){
   //keep track of the highest seen timestamp
   if (timestamp != undefined){
     if (this.timestampFromUpdateTrigger == undefined || this.timestampFromUpdateTrigger < timestamp) {
       this.timestampFromUpdateTrigger = timestamp;
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleMusicSelectionUpdateInfo', 'Going to check for newest version:  ' + timestamp);
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleMusicSelectionUpdateInfo', 'Already going to check for an even newer version:  ' + this.timestampFromUpdateTrigger + '(we just received: ' + timestamp + ')');
     }
   }else{
     this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleMusicSelectionUpdateInfo', 'No timestamp info');
   }

   this.checkReloadMusicSelectionNeeded();
 }

 private checkReloadMusicSelectionNeeded(){
  if (this.refetchNeededForConflict) {
    this.loadMusicSelection();
  }else{
    if (this.timestampFromUpdateTrigger != undefined && this.musicSelectionTimestamp != undefined){
      //if we are not loading and modifying the music selection -> fetch if needed
      if (!this.musicSelectionLoading && !this.musicSelectionSaving) {
        if (this.timestampFromUpdateTrigger > this.musicSelectionTimestamp){
          this.loadMusicSelection();
        }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadCustomPlaylistsNeeded', 'Already have a newer version (' + this.musicSelectionTimestamp + '), not going to reload for update trigger timestamp ' + this.timestampFromUpdateTrigger);
        }
      }else{
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadCustomPlaylistsNeeded', 'Not going to load right now: loading: ' + (this.musicSelectionLoading ? 'true': 'false') + 'saving: ' + (this.musicSelectionSaving ? 'true': 'false'));
      }
    }
  }

 }

 private errorsInARow_load = 0;
 private retryLoadMusicSelection() {
  const delay = Math.pow(2, this.errorsInARow_load - 1) * Config.RETRY_BASE_INTERVAL_MS;

  timer(Math.min(delay, Config.RETRY_MAX_INTERVAL_MS))
  .pipe(
    takeUntil(
      merge(
        this.cancelPrevousLoadMusicSelectionRequests$,
        this.destroyed$
      )
    )
  )
  .subscribe(
    ()=>{
       //if not loaded in the meanwhile, start to load
       if (this.zoneConnectionsService.activeZoneConnection && !this.musicSelectionLoading && !this.musicSelectionLoaded){
        this.loadMusicSelection();
      }

    }
  )
}

private cancelPrevousLoadMusicSelectionRequests$ = new Subject<void>();
    public loadMusicSelection(): void {

        this.cancelPrevousLoadMusicSelectionRequests$.next();

        this._musicSelectionLoading = true;
        this._musicSelectionLoadError = false;
        this.refetchNeededForConflict = false;

        const zoneConfigurationObservable = this.zoneConfigurationApiService.loadMusicSelection();

        zoneConfigurationObservable
            .pipe(
                finalize(() => {
                    this._musicSelectionLoading = false;

                    this.checkReloadMusicSelectionNeeded();

                    if (this._musicSelectionLoadError != null){
                      this.retryLoadMusicSelection();
                    }

               }),
               takeUntil(
                   merge(
                    this.destroyed$,
                    this.cancelPrevousLoadMusicSelectionRequests$
                   )
                )
            )
            .subscribe(
                (data) => {

                  this.errorsInARow_load = 0;

                  let useResponse = true;
                  if (this.timestampFromUpdateTrigger != undefined && this.timestampFromUpdateTrigger > data.timestamp) {
                    //there is a newer resource version on the server -> refetch
                    if (!this.refetchedToAvoidVersionConflict) {
                      //we are only going to refetch once, to avoid endless loop of fetching data when there is something wrong with our version timestamps
                      this.refetchedToAvoidVersionConflict = true;
                      useResponse = false;
                    } else {
                      //after loading 2x: ignore the newer seen version and start using this musicSelection
                      this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadMusicSelection', 'loadMusicSelection receives an older version number but we already refetched before -> just start working with this version');
                    }
                  }

                  if (useResponse) {

                    //reset the conflict flag once we use the queue
                    this.refetchedToAvoidVersionConflict = false;
                    //reset the highest seen timestamp
                    this.timestampFromUpdateTrigger = undefined;

                    this.serverSideMusicSelection = data;
                    this._musicSelection = data;
                    this._musicSelectionLoaded = true;
                    this.musicSelectionTimestamp = data.timestamp;

                    this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadMusicSelection', 'loadMusicSelection done (timestamp: ' + this.musicSelectionTimestamp + ')');
                  } else {
                    this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadMusicSelection', 'Not going to use result -> reload musicSelection from the finalize block.');
                  }
                },
                (error: unknown) => {
                  let errMsg = 'Server error: ' + error;
                  if (error instanceof HttpErrorResponse){
                    errMsg = (error.message) ? error.message :
                        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                  }

                  this.errorsInARow_load++;

                  this._musicSelectionLoadError = true;
                  this.loggerService.error(this.LOGGER_CLASSNAME, "loadMusicSelection", "Error loading: " + errMsg);
                }
            );
    }


    private get _musicSelectionSaving(): boolean {
      return this._musicSelectionSavingSubject.value;
    }
    private set _musicSelectionSaving(value: boolean) {
      if (this._musicSelectionSaving !== value) {
          this._musicSelectionSavingSubject.next(value);
      }
    }

    public get musicSelectionSaving(): boolean{
      return this._musicSelectionSaving;
    }
    private _musicSelectionSavingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public musicSelectionSaving$: Observable<boolean> = this._musicSelectionSavingSubject.asObservable();


//we keep track of the last successfully saved musicSelection (or loaded)
//we can revert to this when our save fails
private serverSideMusicSelection: DTO_MusicSelection;
//we will use this observable to cancel any previous http request
private cancelPreviousSaveMusicSelectionSubject$: Subject<void> = new Subject<void>();
public saveMusicSelection(musicSelection: DTO_MusicSelection){


    //we already silently update our local settings
    this._musicSelectionSubject.next(musicSelection);

    if (!this.musicSelectionSaving){
      //perform save
      this.loggerService.info(this.LOGGER_CLASSNAME, 'saveMusicSelection', 'about to send saveMusicSelection request');

      //indicate a save will start, this will cancel all previous saves
      this.cancelPreviousSaveMusicSelectionSubject$.next();

      this._musicSelectionSaving = true;

      //fill in timestamp right before we save
      musicSelection.timestamp = this.musicSelectionTimestamp;

      //call api
      const saveMusicCollectionObservable = this.zoneConfigurationApiService.saveMusicSelection(musicSelection);

      if (saveMusicCollectionObservable){
        saveMusicCollectionObservable.pipe(
            takeUntil(
                merge(
                    this.cancelPreviousSaveMusicSelectionSubject$, //stop as soon as a newer save starts
                    this.destroyed$
                    )
                ),
                finalize(()=>{
                  this._musicSelectionSaving = false;

                  this.checkReloadMusicSelectionNeeded();

                  //check if we need to re-save
                  if (this.musicSelectionNeedsSave()){
                    this.saveMusicSelection(this.musicSelection);
                  }
                })
            )
            .subscribe(
                (data) => {
                    this.loggerService.debug(this.LOGGER_CLASSNAME, "saveMusicSelection", "data : " + data);

                    //our local modal was already adjusted, we keep track what gets successfully stored on the server
                    this.serverSideMusicSelection = musicSelection;
                    if (data.timestamp != null){
                      this.musicSelectionTimestamp = data.timestamp;
                    }
                },
                (error : unknown) => {
                  let errMsg = 'Server error: ' + error;
                  if (error instanceof HttpErrorResponse){
                    errMsg = (error.message) ? error.message :
                    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                    if (error.status == 409){ //conflict -> reload
                      this.refetchNeededForConflict = true;
                    }
                  }
                  this.loggerService.error(this.LOGGER_CLASSNAME, "saveMusicSelection error", "error while sending request : " + errMsg);

                  //revert to the last successfull save
                  this._musicSelection = this.serverSideMusicSelection;
                }
            );
      }
    }else{
      //wait for other save to complete
      this.loggerService.info(this.LOGGER_CLASSNAME, 'saveMusicSelection', 'waiting until other save completes');
    }
}

  private destroyed$ = new Subject<void>();
    ngOnDestroy(){
        this.destroyed$.next();
        this.destroyed$.complete();
        this.destroyed$ = null;
    }

  private musicSelectionNeedsSave():boolean{
    if (this.musicSelection != null && this.serverSideMusicSelection != null){
      return this.musicSelection.active != this.serverSideMusicSelection.active
        || this.musicSelection.musicChannelId != this.serverSideMusicSelection.musicChannelId
        || this.musicSelection.calendarId != this.serverSideMusicSelection.calendarId;
    }
    return false;
  }
}
