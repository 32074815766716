import { Component } from '@angular/core';



@Component({
  selector: 'tun-search-music-collection-result-view',
  templateUrl: './search-music-collection-result-view.component.html',
  styleUrls: ['./search-music-collection-result-view.component.scss']
})
export class SearchMusicCollectionResultViewComponent {



}
