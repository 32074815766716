<div class="wrapper">

  <div class="contentWrapper">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <div class="title">
          {{ 'settings.desktopapp.title' | translate }}
        </div>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- normal content -->
    <div class="scrollContainer">

      <div class="real-content-container">

        <!-- general info -->
         <div class="small-title-container">
          <span class="small-info" translate>settings.desktopapp.general.info</span>
         </div>

         <div class="panel-container">

          <!-- install app -->


          <div class="small-title-container">
            <div class="step-container">
              {{'settings.desktopapp.label.step' | translate}}&nbsp;<b>1</b>&nbsp; / 2
            </div>
            <span class="small-title" translate>
              settings.desktopapp.download.title
            </span>
          </div>
          <div class="small-info topspacer bottomspacer" translate>
            settings.desktopapp.download.info
          </div>

          <div>
            <div class="download-button" (click)="onDownload()">
              <tun-download-icon-v5 class="download-icon icon icon-scaled-svg-button"></tun-download-icon-v5>
              <span class="button-title">{{'settings.desktopapp.download.button' | translate}} {{'settings.desktopapp.for' | translate}} {{desktopDeviceType}}</span>
            </div>
          </div>

          <div *ngIf="desktopDeviceType === DesktopDeviceType.MAC" class="small-info topspacer">
            1. {{'settings.desktopapp.download.mac.info1' | translate}}
          </div>
          <div *ngIf="desktopDeviceType === DesktopDeviceType.MAC" class="small-info" translate>
            2. {{'settings.desktopapp.download.mac.info2' | translate}}
          </div>

          <div *ngIf="desktopDeviceType === DesktopDeviceType.WINDOWS" class="small-info topspacer">
            {{'settings.desktopapp.download.windows.info1' | translate}}
          </div>

          <!-- seperator -->
           <div class="seperator"></div>


          <!-- start app -->
          <div class="small-title-container">
            <div class="step-container">
              {{'settings.desktopapp.label.step' | translate}}&nbsp;<b>2</b>&nbsp; / 2
            </div>
            <span class="small-title" translate>
              settings.desktopapp.open.title
            </span>
          </div>
          <div class="small-info" translate>
            settings.desktopapp.open.info
          </div>

          <div>
            <div class="play-button" (click)="onStartDesktop()">
              <div class="play-icon-container">
                <tun-play-icon-v5 class="play-icon icon"></tun-play-icon-v5>
              </div>
              <span class="button-title">{{'settings.desktopapp.open.button' | translate}} {{'settings.desktopapp.for' | translate}} {{desktopDeviceType}}</span>
            </div>
          </div>

          <div *ngIf="playerNotFound" class="error-info" translate>
            settings.desktopapp.open.error
          </div>
         </div>

      </div>
    </div>


</div>
