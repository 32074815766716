<div class="wrapper">
  <div class="zone-name-container">
    <tun-zone-icon-v5 class="icon icon-scaled-svg-button"></tun-zone-icon-v5>
    <tun-scrollable-text class="location-name" [text]="locationName"></tun-scrollable-text>
    <button class="icon-button external-link-button" (click)="onOpenZone()">
      <tun-external-link-icon-v5 class="icon icon-scaled-svg-button"></tun-external-link-icon-v5>
    </button>
  </div>

  <div class="subscription-container">
    <div class="subscription-label">{{'settings.subscription.title' | translate}}</div>
    <tun-scrollable-text class="subscription-info-label" [text]="subscriptionName$ | async"></tun-scrollable-text>

    <ng-container *ngIf="lastValidDate$ | async">
      <div class="subscription-label">{{'settings.subscriptions.validity.until' | translate}}</div>
      <tun-scrollable-text class="subscription-info-label" [text]="lastValidDate$ | async"></tun-scrollable-text>
    </ng-container>

    <ng-container *ngIf="autoRenewalDate$ | async">
      <div class="subscription-label">{{'settings.subscriptions.validity.renewOn' | translate}}</div>
      <tun-scrollable-text class="subscription-info-label" [text]="autoRenewalDate$ | async"></tun-scrollable-text>
    </ng-container>

    <ng-container *ngIf="(lastValidDate$ | async) == null && (autoRenewalDate$ | async) == null">
      <div class="subscription-label">{{'settings.subscriptions.validity.until' | translate}}</div>
      <tun-scrollable-text class="subscription-info-label" [text]="'settings.subscriptions.validity.unknown' | translate"></tun-scrollable-text>
    </ng-container>

    <div>
      <button class="logout-button" (click)="onLogout()">
        <ng-container *ngIf="!(remoteMode$ | async)">
          {{'settings.subscriptions.logout' | translate}}
        </ng-container>
        <ng-container *ngIf="remoteMode$ | async">
          {{'connect.settings.button.stop' | translate}}
        </ng-container>


      </button>
    </div>


  </div>
</div>
