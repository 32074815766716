import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { Context } from '@model/context';

export function createClassObjectForMusicCollection(musicCollection:MusicCollection) {
    if (musicCollection['@type'] == 'Playlist' || musicCollection['@type'] == 'TunifyTipList'){
        return new Playlist(musicCollection as Playlist);
    }else if (musicCollection['@type'] == 'Context'){
        return new Context(musicCollection as Context);
    }else{
        console.error('MusicCollection type not recognized: ' + musicCollection['@type']);
        return new MusicCollection(musicCollection);
    }
 }
