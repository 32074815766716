import { Injectable } from '@angular/core';
import { AuthenticationService } from '@service/authentication.service';
import { LoggerService } from '@service/loggers/logger.service';
import { AudioFile } from '@model/audioFile';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AutocompletionObject, AutocompletionType } from '@service/autocompletion.service';
import { Config } from '@service/config';

export enum SearchType{
  SEARCH_ON_TITLE = "searchOnTitle",
  SEARCH_ON_GROUP = "searchOnGroup",
  AUTOCOMPLETION_SEARCH_ON_GROUP = "autocompletionOnGroup",
  AUTOCOMPLETION_SEARCH_ON_TITLE = "autocompletionOnSong",
  SEARCH_BY_VALUE = "searchByValue",
  SEARCH_SUGGESTIONS = "searchSuggestions"
}

export enum SuggestionsType{
  SUGGEST_SIMILAR = "similar",
  SUGGEST_ON_DANCING_STYLE = "dancingstyle",
  SUGGEST_ON_MOOD = "mood",
  SUGGEST_ON_STYLE = "style",
  SUGGEST_ON_YEAR = "year",
  SUGGEST_ON_BPM = "bpm",
}

@Injectable({
  providedIn: 'root'
})
export class SearchApiService {

  private LOGGER_CLASSNAME = 'SearchApiService';

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService) { }
  
  /**
   * track based search.
   * 
   * @param searchType Possible values: SEARCH_ON_TITLE OR SEARCH_ON_GROUP
   * @param track Track with the property
   */
  public searchOnTrack(searchType: SearchType, track: AudioFile): Observable<AudioFile[]>{
    let resultObservable: Observable<AudioFile[]>;

    if (environment.mockSearch){
      let url = "api/search1";
      if (searchType === SearchType.SEARCH_ON_GROUP){
        url = "api/search2";
      }
      this.loggerService.info(this.LOGGER_CLASSNAME, 'searchOnTrack', 'about to mock search ' + searchType + ' on track ' + track + ' going to use ' + url);
      resultObservable = this.httpClient.get<AudioFile[]>(url);
    }else{
      
      const params = new HttpParams()
                      .set('type', "" + searchType)
                      .set('trackId', "" + track.id);

      let url = Config.api4Url_search(this.authenticationService.zoneId);
      resultObservable = this.httpClient.get<AudioFile[]>(url, {params: params});
      
    }
    return resultObservable;
  }


  /**
   * Textual search
   * @param text Textual input from the user,
   */
  public searchOnText(text: string){
    let resultObservable: Observable<AudioFile[]>;

    if (environment.mockSearch){
      let url = "api/search1";
      this.loggerService.info(this.LOGGER_CLASSNAME, 'searchOnText', 'about to mock searchOnText for text:' + text + ' going to use ' + url);
      resultObservable = this.httpClient.get<AudioFile[]>(url);
    }else{
      const params = new HttpParams()
                      .set('type', "" + SearchType.SEARCH_BY_VALUE)
                      .set('searchTerm', text);

      let url = Config.api4Url_search(this.authenticationService.zoneId);
      resultObservable = this.httpClient.get<AudioFile[]>(url, {params: params});
    }
    return resultObservable;
  }

  /**
   * Autocompletion search
   * @param autocompletionObject the autocompletion object to perform the search on
   */
  public searchOnAutocompletion(autocompletionObject: AutocompletionObject){
    
    let resultObservable: Observable<AudioFile[]>;

    if (autocompletionObject){
      let searchType = autocompletionObject.type === AutocompletionType.Song?SearchType.AUTOCOMPLETION_SEARCH_ON_TITLE:SearchType.AUTOCOMPLETION_SEARCH_ON_GROUP;

      if (environment.mockSearch){
        let url = "api/search1";
        if (searchType === SearchType.AUTOCOMPLETION_SEARCH_ON_GROUP){
          url = "api/search2";
        }
        this.loggerService.info(this.LOGGER_CLASSNAME, 'searchOnAutocompletion', 'about to mock search ' + searchType + ' for text:' + autocompletionObject.autocompletionText + ' going to use ' + url);
        resultObservable = this.httpClient.get<AudioFile[]>(url);
      }else{
     
        const params = new HttpParams()
                            .set('type', "" + searchType)
                            .set('searchTerm', autocompletionObject.text)
                            .set('autocompletion', autocompletionObject.autocompletionText)
                            .set('autocompletionRank', "" + autocompletionObject.rank);

        let url = Config.api4Url_search(this.authenticationService.zoneId);
        resultObservable = this.httpClient.get<AudioFile[]>(url, {params: params});
      }
    }
    
    return resultObservable;
  }


  /**
   * 
   * 
   * @param suggestionsType The suggestion type that determines the properties that have to be similar
   * @param track Track to find suggestions on
   * @param similarity value between 1 and 7. 1 is stricter search (less results, results are more similar) and 7 is wider search (more results, results are less similar)
   */
  public searchSuggestions(suggestionsType: SuggestionsType, track: AudioFile, similarity: number): Observable<AudioFile[]>{
    let resultObservable: Observable<AudioFile[]>;

    if (environment.mockSearch){
      let url = "api/search1";
      //just to get some different search results
      if (suggestionsType === SuggestionsType.SUGGEST_SIMILAR || suggestionsType === SuggestionsType.SUGGEST_ON_DANCING_STYLE){
        url = "api/search2";
      }
      this.loggerService.info(this.LOGGER_CLASSNAME, 'searchSuggestions', 'about to mock search suggestions ' + suggestionsType + ' on track ' + track.toString() + ' going to use ' + url);
      resultObservable = this.httpClient.get<AudioFile[]>(url);
    }else{
      const params = new HttpParams()
                            .set('type', "" + suggestionsType)
                            .set('similarity', "" + similarity)
                            .set('trackId', "" + track.id);

      let url = Config.api4Url_suggest(this.authenticationService.zoneId);
      resultObservable = this.httpClient.get<AudioFile[]>(url, {params: params});
    }
    return resultObservable;
  }
}
