import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from './local-storage.service';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private _forcedDevice : string;
  public set forcedDevice(value: string){
    this._forcedDevice = value;
    this.localStorageService.forcedDevice = this._forcedDevice;
  }
  public get forcedDevice():string{
    return this._forcedDevice;
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private localStorageService: LocalStorageService
  ) {
    this._forcedDevice = this.localStorageService.forcedDevice
  }

  public userAgent(): string{
    return this.deviceDetectorService.userAgent;
  }

  //used to show to the user in a readable format
  public deviceShortReadableInfo(): string{
    return this.realDevice() + " (" + (this.forcedDevice ? this.forcedDevice : (isDesktopApp() ? 'Tunify-Desktop' : this.deviceDetectorService.browser + " browser")) + ")";
  }

  //used to show to the user in a readable format
  public deviceExtraReadableInfo(): string{
    return this.deviceDetectorService.os_version + " (" + this.deviceDetectorService.browser + " " + this.deviceDetectorService.browser_version + ")";
  }

  public realDevice(): string{
    if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()){
      return this.deviceDetectorService.device;
    }else{
      return this.deviceDetectorService.os;
    }
  }


}
