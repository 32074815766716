<div class="wrapper">

  <div class="contentWrapper {{showingPopup ? 'blurredContent' : ''}}">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <button [disabled]="checkedTracks.length > 0" class="icon-button first-title-item" (click)="onPlayAll()">
          <tun-play-icon-v5 class="icon colored-icon"></tun-play-icon-v5>
        </button>

        <div class="title">
          {{title}}
        </div>

        <button [disabled]="checkedTracks.length > 0" class="icon-button" (click)="onRefresh()">
          <tun-refresh-icon-v5 class="icon"></tun-refresh-icon-v5>
        </button>

        <button [disabled]="checkedTracks.length > 0" class="icon-button" (click)="onTweak()">
          <tun-menu-music-channel-icon-v5 class="icon"></tun-menu-music-channel-icon-v5>
        </button>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <ng-container *ngIf="musicCollection$ | async">



      <!-- Special playlist states indicator -->

      <div
        *ngIf="searchState != SearchState.normal"
        [ngSwitch]="searchState"
        class="feedback-container"
      >
        <div
          *ngSwitchCase="SearchState.searchError"
          class="error-feedback-container"
        >
          <p>
            {{'search.status.failed' | translate }}
          </p>
          <button (click)="retryClick()" class="retryButton">
            <p>
              {{'general.tryAgain' | translate }}
            </p>
          </button>
        </div>

        <ng-container *ngSwitchCase="SearchState.searching">
          <tun-loader-v5 class="loader"></tun-loader-v5>
        </ng-container>

      </div>


      <div
        *ngIf="searchState == SearchState.normal"
        class="select-title"
      >
        <div class="select-front">
          <tun-checkbox-v5
          class="select-all-checkbox"
          [title]="selectTitle"
          coloredTitleOnCheck="true"
          [checkBoxColor]="TunifyCheckBoxColor.ORANGE"
          [isChecked]="allComplete"
          [indeterminate]="someComplete()"
          (isCheckedChanged)="setAll($event)"
        >
        </tun-checkbox-v5>

        <button *ngIf="checkedTracks.length != 0" class="unselect-link-button" (click)="setAll(false)">
          <p translate>
            search.result.deselect.all
          </p>
        </button>

        </div>


        <button class="icon-button colored-action-button" [disabled]="checkedTracks.length == 0" (click)="onAddSelectedToQueue()">
          <tun-add-to-queue-v5 class="icon"></tun-add-to-queue-v5>
        </button>

        <button class="icon-button colored-action-button" [disabled]="checkedTracks.length == 0" (click)="onAddSelectedToPlaylist()">
          <tun-add-to-playlist-v5 class="icon"></tun-add-to-playlist-v5>
        </button>
      </div>

      <!-- normal content -->
      <cdk-virtual-scroll-viewport
        *ngIf="searchState == SearchState.normal"
        class="trackTable"
        [itemSize]="trackItemSize$ | async"
        cdkDropList
        [cdkDropListData]="trackTableItems"
        [cdkDropListConnectedTo]="['queueHeader', 'queue']"
        cdkDropListSortingDisabled="true"
        cdkDropListAutoScrollDisabled="true"
      >
        <!--
        ---- templateCacheSize: 0
        ---- We can not use a cache to support drag & drop through the angular cdk
        ---- Drag & drop caches items indexes, this cache is not re-calculated if an item moves from on-screen to the cache
        -->

        <ng-container>
            <tun-track-view
              *cdkVirtualFor="
              let trackTableItem of trackTableItems;
              let i = index;
              templateCacheSize: 0
              "
              class="trackItem"
              [track]="trackTableItem.track"
              [useSelectionBox]="true"
              [showSelectionBox]="showSelectionBox"
              [isSelected]="isTrackChecked(trackTableItem.track)"
              (checkedChanged)="onCheckedChanged($event, trackTableItem.track)"
              [showAudioFileProperty]="audioFileProperty$ | async"
              [tunifyColor]="TunifyColor.ORANGE"
              (play)="onPlay(trackTableItem.track)"
              [playEnabled]="startTrackEnabled$ | async"
              (showOptions)="onShowOptions(trackTableItem.track)"
              [style.height.px]="trackItemSize$ | async"
              cdkDrag
              [cdkDragData]="trackTableItem"
            >
            </tun-track-view>

        </ng-container>

      </cdk-virtual-scroll-viewport>

    </ng-container>


  </div>


  <!-- Popup: choose playlist-->
  <div #choosePlaylistPopupWrapper *ngIf="showChoosePlaylistOverlay" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, choosePlaylistPopupWrapper)">
    <tun-overlay-select-playlist
      class="overlay-panel"
      confirmButtonTranslateableText="playlist.addTrack.popup.button.commit"
      (closeWithPlaylist)="onCloseSelectPlaylist($event)"
    >
    </tun-overlay-select-playlist>
  </div>

  <!-- Popup: tweak musicCollection -->
  <div #tweakPopupWrapper *ngIf="showTweakPopup" class="popupWrapper" @slideUpAnimation (mousedown)="onClickOutside($event, tweakPopupWrapper)">
    <tun-overlay-tweak-music-collection
      class="overlay-panel"
      [musicChannel]="showingMusicChannel$ | async"
      [musicCollection]="musicCollection$ | async"
      (parametersChanged)="onParametersChanged($event)"
      (close)="onCloseTweakPopup()"
    >
    </tun-overlay-tweak-music-collection>
  </div>



</div>

