import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@service/authentication.service';
import { Config } from '@service/config';
import { BYPASS_ACCESSTOKEN } from '@service/interceptors/authentication-interceptor';
import { BYPASS_RETRY } from '@service/interceptors/retryInterceptor';
import { LoggerService } from '@service/loggers/logger.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class DTO_ZoneCode {
  code: string;
  validForSeconds: number;
}

@Injectable({
  providedIn: 'root'
})
export class ZoneCodeApiService {

  private LOGGER_CLASSNAME = ZoneCodeApiService.name;

  constructor(
    private httpClient: HttpClient,
    private loggerService: LoggerService
  ) {

  }



  public loadZoneCode(): Observable<DTO_ZoneCode>{
    return this.loadZoneCodeForZoneId(null, null);
  }

  public loadZoneCodeForZoneId(zoneId: number, accessToken: string): Observable<DTO_ZoneCode>{
    let zoneCodeObservable: Observable<DTO_ZoneCode>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadZoneConfiguration', 'not supported in mock backend');
      return null;
    }else{

      const params = new HttpParams()
                      .set('type', "link");
      if (zoneId && accessToken){
        const url = Config.api4Url_zoneCode_zoneId(zoneId);
        const headers = new HttpHeaders({'Authorization': "Bearer " + accessToken});
        zoneCodeObservable = this.httpClient.post<DTO_ZoneCode>(url, null, {headers: headers, params: params, context: new HttpContext().set(BYPASS_ACCESSTOKEN, true)});
      }else{
        const url = Config.api4Url_zoneCode();
        zoneCodeObservable = this.httpClient.post<DTO_ZoneCode>(url, null, {params: params});
      }
    }
    return zoneCodeObservable;
  }
}
