import { ItemAbstractReviver } from '@rebolon/json-reviver';
import { ZoneConnection } from '../../model/zoneConnection';

export class ZoneConnectionReviver extends ItemAbstractReviver{

  //see https://github.com/Rebolon/json-reviver

  //add sub-revivers here if needed
  constructor(){
    super();
  }

   // The name of the node in the json string/object
   getNodeName(): string {
    return 'zoneConnection';
}

// The entity for which the reviver works
getNewEntity(): Object {
    return new ZoneConnection()
}

// List of props (int, string, date, bool) of the entity
public getEzPropsName()
{
    return ZoneConnection.persistentProperties;
}

// List of props that links to other entities (relations Many To Many)
// And configuration of how to restore them
public getManyRelPropsName(): Object
{
  return {} 
  /*return {
        'editors': {
            'reviver': this.editorsReviver,
            'setter': 'addEdition',
            'cb': function (relation, entity) {
                Accessor('book', relation, entity)
            },
        },
    }
    */
}

// List of props that links to other entities (relations Many To One)
public getOneRelPropsName(): Object
{
  return {};
  /*  
  return {
        'serie': {
            'reviver': this.serieReviver,
            'registryKey': 'serie',
        },
    }
    */
}





}