<div
  class="wrapper"
  [style.height.rem]="baseREM / 2 - 0.2"
  [style.top.rem]="
    baseREM / 2 +
    baseREM * time
  "
>

  <button
    class="icon-button"
    [disabled]="isMouseDown$ | async"
    [matMenuTriggerFor]="menu"
    #t="matMenuTrigger"
    [class.open]="t.menuOpen">
    <div class="timeLabel">
      {{
        timeInMilliseconds | date: "HH:mm":"UTC"
      }}
    </div>
    <tun-plus-icon-v5 class="icon button-icon icon-scaled-svg-button"></tun-plus-icon-v5>
  </button>


  <mat-menu #menu="matMenu">
    <button class="option-button" (click)="onChooseMusicChannel()">
      <tun-musicnote-icon-v5 class="icon option-button-icon"></tun-musicnote-icon-v5>
      <span translate>calendars.edit.item.popup.musicChannel</span>
    </button>
    <button class="option-button" (click)="onChoosePlaylist()">
      <tun-menu-playlist-icon-v5 class="icon option-button-icon"></tun-menu-playlist-icon-v5>
      <span translate>calendars.edit.item.popup.playlist</span>
    </button>
  </mat-menu>

</div>
