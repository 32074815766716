import { DTO_Message } from '@service/api/message-api.service';
import { RemoteAudioType } from '@service/vo/remote/remote-objects';
import { PlayableAudio } from './audioFile';
import { TrackOrigin } from './trackOrigin';

export class BrandMessage implements PlayableAudio{
  id: number;
  title: string;
  group = "";
  duration: number;
  url: string;

  canCrossFade = false;
  canLoadMediaUrls = false;
  canPerformTrackActions = false;

  type = RemoteAudioType.brandMessage;

  public get publicUrl():string {
    return this.url;
  }

  //dummy null getter to implement the interface
  public get origin(): TrackOrigin{
    return null;
  }

  constructor(dtoMessage?: DTO_Message){
    if (dtoMessage){
      this.id = dtoMessage.id;
      this.title = dtoMessage.title;
      this.duration = dtoMessage.duration;
      this.url = dtoMessage.url;
    }
  }

  public toString(): string {
    return 'BrandMessage ' + this.id + ': ' + this.title;
  }
}
