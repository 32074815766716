import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarDayNavigatorComponent } from '../calendar-day-navigator/calendar-day-navigator.component';
import { Calendar } from '@model/calendar';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { map, takeUntil } from 'rxjs/operators';
import { Day } from '@model/day';
import { CALENDAR_CAROUSEL_ANIMATION_DURATION_MS } from '../calendar-detail-view.component';
import { carouselAnimation, slideUpAnimation } from '@util/animations';
import { CalendarItem } from '@model/calendarItem';
import { ActiveCalendarItemsService } from '@service/app-v5/active-calendar-items.service';
import { Subject } from 'rxjs';
import { DragDropService } from '@service/drag-drop.service';
import { CalendarService } from '@service/calendar.service';
import { calculateMaxStartHour, calculateMaximumDuration } from '@service/util/calendarUtil';
import { Playlist } from '@model/playlist';
import { transition, trigger, useAnimation } from '@angular/animations';
import { CALENDAR_ITEM_BASE_HEIGTH_REM, CreateCalendarItemData } from './create-calendar-item/create-calendar-item.component';

export enum CalendarState{
  normal,
  creating,
  creatingCopy,
  deleting,
  deleted,
  undoingDelete,
  clearingCurrentDay,
  copyingDay,
  loading,
  loadError,
  none
}

@Component({
  selector: 'tun-calendar-content-view',
  templateUrl: './calendar-content-view.component.html',
  styleUrls: ['./calendar-content-view.component.scss'],
  animations: [
    carouselAnimation
  ]
})
export class CalendarContentViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('calendarPanel')
  private calendarPanel: ElementRef;

  _timelineView: ElementRef<HTMLElement>;
  @ViewChild('timeLineView', { static: false })
  set timeLineView(element: ElementRef<HTMLElement>) {
    //only auto scroll when the timeline first appears
    const scrollingAllowed = this._timelineView == null;
    this._timelineView = element;
    if(element && scrollingAllowed) {
      this.scrollToCurrentTime()
    }
 }

  // === Constants === //
  readonly MIN_DURATION = 30;
  readonly CAROUSEL_DURATION = CALENDAR_CAROUSEL_ANIMATION_DURATION_MS;

  public CALENDAR_ITEM_BASE_HEIGTH_REM = CALENDAR_ITEM_BASE_HEIGTH_REM;
  private LOGGER_CLASSNAME = 'CalendarContentViewComponent';

  @Input() currentIndex = 0;
  @Input() animationDirection: 'next' | 'prev';

  //The time for the green line
  @Input() currentTimeMultiplier: number

  @Output() openTweakPanel = new EventEmitter<CalendarItem>();
  @Output() createMusicChannelCalendarItem = new EventEmitter<CreateCalendarItemData>();
  @Output() createPlaylistCalendarItem = new EventEmitter<CreateCalendarItemData>();
  @Output() selectMusicChannelForCalendarItem = new EventEmitter<CalendarItem>();
  @Output() selectPlaylistForCalendarItem = new EventEmitter<CalendarItem>();

  // === State === //
  public get showingCalendar$(){
    return this.appV5StateService.showDetailsForCalendar$
  }



  get daysOfWeek(): Day[] {
    return Day.DAYS;
  }

  get currentCalendarItems$(){
    return this.activeCalendarItemsService.currentCalendarItems$
  }

  public get editMode$(){
    return this.appV5StateService.editingCurrentCalendar$;
  }

  public get isMouseDown$(){
    return this._dragDropService.isMouseDown$;
  }

  public isActive(calendarItem: CalendarItem){
    const isctive = this.activeCalendarItemsService.currentCalendarItems.filter(e => e.id == calendarItem.id).length > 0
    return isctive;
  }

  //make the enum available in the html template
  public CalendarState = CalendarState;

  get calendarState(): CalendarState{
    if (this.showingCalendar){
      if (this.showingCalendar.creating){
        return CalendarState.creating;
      }else if (this.showingCalendar.creatingCopy){
        return CalendarState.creatingCopy;
      }else if (this.showingCalendar.deleting){
        return CalendarState.deleting;
      }else if (this.showingCalendar.undoingDelete){ //must be before deleted
        return CalendarState.undoingDelete;
      }else if (this.showingCalendar.deleted){
        return CalendarState.deleted;
      }else if (this.clearingCurrentDay){
        return CalendarState.clearingCurrentDay;
      }else if (this.showingCalendar.copyingDay != null){
        return CalendarState.copyingDay;
      }else if (this.showingCalendar.calendarItemsLoading){
        return CalendarState.loading;
      }else if (this.showingCalendar.calendarItemsLoadingError != null){
        return CalendarState.loadError;
      }else if (this.showingCalendar.calendarItems == null){
        return CalendarState.none;
      }
      return CalendarState.normal;
    }
    return CalendarState.none;
  }

  get clearingCurrentDay():boolean{
    return this.showingCalendar && this.showingCalendar.clearingDay(Day.DAYS[this.currentIndex]);
  }


  hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];

  constructor(
    private appV5StateService: AppV5StateService,
    private activeCalendarItemsService: ActiveCalendarItemsService,
    private _dragDropService: DragDropService,
    private _calendarService: CalendarService,
    private _cdRef: ChangeDetectorRef,
    private _ngZone: NgZone
  ){

  }

  public hoursForDropdown = []
  ngOnInit(): void {
    const hoursWithout23 = this.hours
      .slice(0, this.hours.length - 1)
      .map(hourString => parseInt(hourString, 10));
    this.hoursForDropdown = [
      parseInt(this.hours[this.hours.length - 1], 10),
      ...hoursWithout23
    ];

    this.appV5StateService.scrollToCurrentTime$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ()=>{
        this.scrollToCurrentTime();
      }
    )

    this.appV5StateService.showDetailsForCalendar$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      calendar => {
        this.showingCalendar = calendar;
      }
    )



  }

  private _showingCalendar: Calendar;
  private showingCalendarChanged$ = new Subject<void>();
  private get showingCalendar(){
    return this._showingCalendar
  }
  private set showingCalendar(value: Calendar){
    this._showingCalendar = value;
    this.showingCalendarChanged$.next()
    if (this.showingCalendar){
      //watch if needed
    }
  }


  ngAfterViewInit(): void {
    this.scrollToCurrentTime();

    this._ngZone.runOutsideAngular(() => {
      this.calendarPanel.nativeElement.addEventListener(
        'mouseup',
        this.onMouseUp
      );
      this.calendarPanel.nativeElement.addEventListener(
        'mousemove',
        this.onMouseMove
      );
      document.body.addEventListener('mouseleave', this.onMouseUp);
    });
  }

  private destroyed$ = new Subject<void>()
  ngOnDestroy(): void {
      this.destroyed$.next()
      this.destroyed$.complete()

      this._ngZone.runOutsideAngular(() => {
        this.calendarPanel.nativeElement.removeEventListener(
          'mouseup',
          this.onMouseUp
        );
        this.calendarPanel.nativeElement.removeEventListener(
          'mousemove',
          this.onMouseMove
        );
        document.body.removeEventListener('mouseleave', this.onMouseUp);
      });
  }

  scrollToCurrentTime = () => {
    if (this.calendarPanel != null && this.currentTimeMultiplier && this._timelineView != null){
      this.calendarPanel.nativeElement.scrollTop =
      this.currentTimeMultiplier * this.heightPerHour -
      this.heightPerHour / 2 - 0.25 * this.calendarPanel.nativeElement.clientHeight;
    }
  };

  private get heightPerHour(){
    return this.appV5StateService.pixelsFor1Rem * CALENDAR_ITEM_BASE_HEIGTH_REM
  }

  onBack(){
    this.appV5StateService.showCalendarInTunifyGreen(null);
  }

  onOpenTweakPanel(calendarItem: CalendarItem){
    this.openTweakPanel.emit(calendarItem);
  }

  onSelectMusicChannel(calendarItem: CalendarItem){
    this.selectMusicChannelForCalendarItem.emit(calendarItem);
  }

  onSelectPlaylist(calendarItem: CalendarItem){
    this.selectPlaylistForCalendarItem.emit(calendarItem);
  }

  onDeleteItem(calendarItem: CalendarItem){
    this._calendarService.removeCalendarItemFromCalendar(this.showingCalendar, calendarItem);
  }

  //Resizing calendarItem
  onMouseUp = () => {


    //clean up view states before triggering the service (avoid hanging UI on errors)
    this._ngZone.run(() => {
      const wasMouseDown = this._dragDropService.isMouseDown;
      const calendarItemToUpdate =  this._dragDropService.resizingCalendarItem;

      this._dragDropService.isMouseDown = false;
      this._dragDropService.resizingCalendarItem = null;
      this._dragDropService.resizingCalendarItemYcoordinate = 0;
      this._dragDropService.resizingCalendarItemDuration = 0;
      this._dragDropService.resizingCalendarItemStartTime = 0;
      this._dragDropService.resizeFromTop = false;

      //trigger save if we just resized a calendarItem
      if (wasMouseDown && calendarItemToUpdate){
        this._calendarService.adjustCalendarItem(this.showingCalendar, calendarItemToUpdate);
      }
    });





  };

  onMouseMove = () => {
    if (!this._dragDropService.isMouseDown) {
      return;
    }

    const elementYcoord = this._dragDropService.resizingCalendarItemYcoordinate;
    const eventYcoord = (event as MouseEvent).y;

    const heightPerItem = CALENDAR_ITEM_BASE_HEIGTH_REM * this.appV5StateService.pixelsFor1Rem;

    if (this._dragDropService.resizeFromTop) {
      const MAX_START_HOUR = calculateMaxStartHour(
        this.showingCalendar,
        this._dragDropService.resizingCalendarItem
      );

      let preferredStartTime = 0;

      if (eventYcoord <= elementYcoord) {
        preferredStartTime =
          this._dragDropService.resizingCalendarItemStartTime -
          Math.round((elementYcoord - eventYcoord) / (heightPerItem / 2)) *
            0.5;
      } else {
        preferredStartTime =
          this._dragDropService.resizingCalendarItemStartTime +
          Math.round((eventYcoord - elementYcoord) / (heightPerItem / 2)) *
            0.5;
      }

      const realStartTime = Math.min(
        Math.max(preferredStartTime, MAX_START_HOUR),
        this._dragDropService.resizingCalendarItemStartTime +
          this._dragDropService.resizingCalendarItemDuration / 60 -
          0.5
      );

      const startHour = Math.floor(realStartTime);
      const startMinutes = Number.isInteger(realStartTime) ? 0 : 30;
      const duration =
        (this._dragDropService.resizingCalendarItemStartTime - realStartTime) *
          60 +
        this._dragDropService.resizingCalendarItemDuration;

      this._dragDropService.resizingCalendarItem.startHour = startHour;
      this._dragDropService.resizingCalendarItem.startMinutes = startMinutes;
      this._dragDropService.resizingCalendarItem.duration = duration;

      this._cdRef.detectChanges();
    } else {
      const MAX_DURATION = calculateMaximumDuration(
        this.showingCalendar,
        this._dragDropService.resizingCalendarItem
      );

      let preferredDuration = 0;

      if (eventYcoord >= elementYcoord) {
        preferredDuration =
          this._dragDropService.resizingCalendarItemDuration +
          Math.round((eventYcoord - elementYcoord) / (heightPerItem / 2)) *
            30;
      } else {
        preferredDuration =
          this._dragDropService.resizingCalendarItemDuration -
          Math.round((elementYcoord - eventYcoord) / (heightPerItem / 2)) *
            30;
      }

      const realDuration = Math.min(
        Math.max(preferredDuration, this.MIN_DURATION),
        MAX_DURATION
      );

      this._dragDropService.resizingCalendarItem.duration = realDuration;

      this._cdRef.detectChanges();
    }
  };



  onCreateMusicChannelCalendarItem(createCalendarItemData: CreateCalendarItemData){
    this.createMusicChannelCalendarItem.emit(createCalendarItemData);
  }

  onCreatePlaylistCalendarItem(createCalendarItemData: CreateCalendarItemData){
    this.createPlaylistCalendarItem.emit(createCalendarItemData);
  }

}
