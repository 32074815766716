import { Injectable, ElementRef } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { BehaviorSubject, Observable } from 'rxjs';
import { CalendarItem } from '@model/calendarItem';

@Injectable()
export class DragDropService {
  public isUserDraggingLong = false;
  public dragStartedFromQueue = false;
  public draggingAudioFile: AudioFile = null;
  public draggingCalendarItem: CalendarItem = null;

  public resizingCalendarItem: CalendarItem = null;
  public resizingCalendarItemYcoordinate = 0;
  public resizingCalendarItemDuration = 0;
  public resizingCalendarItemStartTime = 0;
  public resizeFromTop = false;

  /**
   * The user isUserDragging boolean should be an observable so we can easily bind to changes in our views
   */
  public get isUserDragging(): boolean {
    return this.isUserDraggingSubject.value;
  }
  public set isUserDragging(value: boolean) {
    if (this.isUserDragging !== value) {
      this.isUserDraggingSubject.next(value);
    }
  }
  private isUserDraggingSubject: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public isUserDragging$: Observable<
    boolean
  > = this.isUserDraggingSubject.asObservable();

  public get isMouseDown(): boolean {
    return this.isMouseDownSubject.value;
  }
  public set isMouseDown(value: boolean) {
    if (this.isMouseDown !== value) {
      document.body.style.cursor = value ? 'ns-resize' : 'default';
      this.isMouseDownSubject.next(value);
    }
  }
  private isMouseDownSubject: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public isMouseDown$: Observable<
    boolean
  > = this.isMouseDownSubject.asObservable();
}
