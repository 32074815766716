import { Component } from '@angular/core';

@Component({
  selector: 'tun-download-icon-v5',
  templateUrl: './download-icon-v5.component.html',
  styleUrl: './download-icon-v5.component.scss'
})
export class DownloadIconV5Component {

}
