<div class="wrapper" (click)="onShowDetails()">

  <div class="image-wrapper">
    <tun-image-on-screen-loader class="image {{(isActive$ | async) ? 'active' : ''}}" [src]="calendar.imageInfo.urlWebSquare"></tun-image-on-screen-loader>

    <div *ngIf="showTogglePlayButton" class="playButtonContainer">
      <tun-play-music-selection-button
        class="play-button"
        [itemToStart]="calendar"
        (itemIsStarting)="onItemIsStarting()"
      >

      </tun-play-music-selection-button>
    </div>

  </div>



  <div class="titleContainer">
    <tun-music-play-animation
      *ngIf="showPlayAnimation$ | async"
      [playing]="animatePlayAnimation$ | async"
      [musicPlayAnimationColors]="playAnimationColors"
    >
    </tun-music-play-animation>
    <tun-scrollable-text class="title" [text]="calendar.name"></tun-scrollable-text>
  </div>
</div>
