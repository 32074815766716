import { RemoteAudioType } from '@service/vo/remote/remote-objects';
import { AudioFile } from './audioFile';

export class Message extends AudioFile{

  canCrossFade = false;

  type = RemoteAudioType.message;

  constructor(message:Message){
        super(message);
  }
}
