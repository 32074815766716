
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FitSizeDirective } from "./fit-size-directive/fit-size.directive";

@NgModule({
    declarations: [
        FitSizeDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        FitSizeDirective,
    ],
    providers: [
    ]
})
export class AppComponentsModule {}
