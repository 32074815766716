import { Injectable, OnDestroy } from '@angular/core';
import { RemotePlayStateService } from './remote-play-state.service';
import { LoggerService } from '@service/loggers/logger.service';
import { PlayModeForRemoteSharing, TrackAction } from '@service/vo/remote/remote-objects';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { DataUpdateService } from '../realTimeCommunication/data-update.service';
import { PlayerAction, PlayerActionEnum, canPerformTrackActionsOnRemoteAudioType, TrackCommand } from '../data/vo/remote/remote-objects';
import { TrackFavoriteBannedService } from '../app/track-favorite-banned-info.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject, BehaviorSubject, merge, Observable, pipe } from 'rxjs';
import { AudioFile } from '../../model/audioFile';
import { AsyncStatus, isFinal } from '../data/vo/asyncStatus';

@Injectable({
  providedIn: 'root'
})
export class RemoteActionsService implements OnDestroy{

  private LOGGER_CLASSNAME = 'RemoteActionsService';

  private startingTrackSubject = new BehaviorSubject<AudioFile>(null);
  public startingTrack$ = this.startingTrackSubject.asObservable();

  constructor(
    private remotePlayStateService:RemotePlayStateService,
    private dataUpdateService: DataUpdateService,
    private zoneConnectionsService: ZoneConnectionsService,
    private loggerService: LoggerService,
    private trackFavoriteBannedService: TrackFavoriteBannedService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public remotePlayTrack(track: AudioFile):Observable<AsyncStatus>{
    this.loggerService.debug(this.LOGGER_CLASSNAME, 'remotePlayTrack', 'sending remote play track');

    this.startingTrackSubject.next(track);

    const trackAction = new TrackAction();
    trackAction.trackId = track.id;
    trackAction.trackOrigin = track.origin;
    trackAction.command = TrackCommand.play;
    const notifier = this.dataUpdateService.sendTrackAction(trackAction);

    notifier.pipe(
      takeUntil(
        merge(
          this.startingTrack$.pipe(filter(audioFile => audioFile != track)),
          this.destroyed$
        )
      )
    )
    .subscribe(
      (status) => {
        if (isFinal(status)){
          this.startingTrackSubject.next(null);
        }
        if (status == AsyncStatus.ERROR || status == AsyncStatus.CANCELLED || status == AsyncStatus.INVALID_ACTION){
          this.dataUpdateService.sendSharePlayerState();
        }
      }
    )

    return notifier;
}

public remotePlay(){
    if (this.zoneConnectionsService.activeZoneConnection){
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'remotePlay', 'sending remote play action');
        const playerAction = new PlayerAction();
        playerAction.action = PlayerActionEnum.play;
        this.dataUpdateService.sendPlayerAction(playerAction);
        //immediatly adjust our local player state
        this.remotePlayStateService.remotePlayModeReceived(PlayModeForRemoteSharing.startingPlay);
    }
}

public remotePause(){
  if (this.zoneConnectionsService.activeZoneConnection){
    this.loggerService.debug(this.LOGGER_CLASSNAME, 'remotePause', 'sending remote play action');
    const playerAction = new PlayerAction();
    playerAction.action = PlayerActionEnum.pause;
    this.dataUpdateService.sendPlayerAction(playerAction);
    //immediatly adjust our local player state
    this.remotePlayStateService.remotePlayModeReceived(PlayModeForRemoteSharing.pause);
  }
}

public remoteNext(){
  if (this.zoneConnectionsService.currentRemotePlayerApplicationInfo != null){
    if (this.zoneConnectionsService.currentRemotePlayerApplicationInfo.listensToRemoteCommands){

      this.loggerService.debug(this.LOGGER_CLASSNAME, 'remotePause', 'sending remote play action');
      const playerAction = new PlayerAction();
      playerAction.action = PlayerActionEnum.skip;

      //immediatly adjust our local player state
      this.remotePlayStateService.currentTrackIsSkipped();

      this.dataUpdateService.sendPlayerAction(playerAction);

    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "remoteNext", "remote player does not listens to commands -> not sending next");
    }
  }else{
    this.loggerService.error(this.LOGGER_CLASSNAME, "remoteNext", "no remote player -> not sending next");
  }
}

/*
public takeOverPlaymode(){
  if (this.remotePlayStateService.currentTrack != null && this.remotePlayStateService.currentTrack.type != null && canPerformTrackActionsOnRemoteAudioType(this.remotePlayStateService.currentTrack.type)){
    const trackId = this.remotePlayStateService.currentTrack.id;
    this.remotePlayStateService.lookupTrackIfNeeded();

    this.remotePlayStateService.remoteTrackObject$
    .pipe(
      filter(track => track != null),
      takeUntil(
        merge(
          this.remotePlayStateService.currentTrack$.pipe(filter(track => track == null || track.id != trackId)),
          this.destroyed$
        )
      )
    )
    .subscribe(
      (trackObject)=>{
        this.musicManipulationService.startAudioAtPosition(trackObject, this.remotePlayStateService.progress);
        this.playTokenService.changeToPlayerMode();
      }
    );
  }else{
    this.loggerService.error(this.LOGGER_CLASSNAME, "takeOverPlaymode", "No known track (or not allowed to do trackActions on the track) -> going to just start");
    this.playTokenService.changeToPlayerMode();
  }
}
*/

public remoteAdjustFavorite(value:boolean){
  if (this.remotePlayStateService.currentTrack != null){
    const trackId = this.remotePlayStateService.currentTrack.id;

    if (this.trackFavoriteBannedService.currentRemoteTrackIsFavorite != value){
      this.remotePlayStateService.lookupTrackIfNeeded();

      this.remotePlayStateService.remoteTrackObject$
      .pipe(
        filter(track => track != null),
        takeUntil(
          merge(
            this.remotePlayStateService.currentTrack$.pipe(filter(track => track == null || track.id != trackId)),
            this.destroyed$
          )
        )
      )
      .subscribe(
        (trackObject)=>{
          this.trackFavoriteBannedService.adjustIsFavorite(trackObject, value);
        }
      );
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "remoteAdjustFavorite", "Track " + this.remotePlayStateService.currentTrack.id + " has already correct state (favorite: " + (value ? "true": "false") + ") -> nothing to do");
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "remoteAdjustFavorite", "No known track");
    }
}

public remoteAdjustBanned(value:boolean){
  if (this.remotePlayStateService.currentTrack != null){
    const trackId = this.remotePlayStateService.currentTrack.id;

    if (this.trackFavoriteBannedService.currentRemoteTrackIsBanned != value){
      this.remotePlayStateService.lookupTrackIfNeeded();

      this.remotePlayStateService.remoteTrackObject$
      .pipe(
        filter(track => track != null),
        takeUntil(
          merge(
            this.remotePlayStateService.currentTrack$.pipe(filter(track => track == null || track.id != trackId)),
            this.destroyed$
          )
        )
      )
      .subscribe(
        (trackObject)=>{
          this.trackFavoriteBannedService.adjustIsBanned(trackObject, value);
        }
      );
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "remoteAdjustBanned", "Track " + this.remotePlayStateService.currentTrack.id + " has already correct state (banned: " + (value ? "true": "false") + ") -> nothing to do");
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "remoteAdjustBanned", "No known track");

    }
}

}
