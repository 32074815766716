export enum AudioFileOriginType {
    CALENDAR_ITEM = "calendarItem",
    MUSICCOLLECTION = "musicCollection",
    SEARCH_BY_VALUE = "searchByValue",
    SEARCH_AUTOCOMPLETION_ON_GROUP = "autocompletionOnGroup",
    SEARCH_AUTOCOMPLETION_ON_SONG = "autocompletionOnSong",
    SEARCH_ON_GROUP = "searchOnGroup",
    SEARCH_ON_TITLE = "searchOnTitle",
    SUGGESTION_SIMILAR = "suggestionSimilar",
    SUGGESTION_BPM = "suggestionBPM",
    SUGGESTION_DANCING_STYLE = "suggestionDancingStyle",
    SUGGESTION_MOOD = "suggestionMood",
    SUGGESTION_STYLE = "suggestionStyle",
    SUGGESTION_YEAR = "suggestionYear",
    BRAND_AUDIO_MESSAGE = "brandAudioMessage"
}
