<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 20" enable-background="new 0 0 20 20" xml:space="preserve">
   <defs>
    <path id="path-mail-def" d="M11.52,11.859 L1.6,11.859 L1.6,7.859 C1.6,7.559 1.716,7.278 1.928,7.067 C2.14,6.855 2.42,6.738 2.72,6.738 L7.68,6.738 L7.68,7.859 C7.68,8.3 8.038,8.658 8.48,8.658 C8.922,8.658 9.28,8.3 9.28,7.859 L9.28,6.738 L11.52,6.738 L11.52,11.859 Z M6.276,2.25 L6.23,1.599 L7.68,1.599 L7.68,2.25 L6.276,2.25 Z M12.32,5.139 L9.28,5.139 L9.28,3.049 L9.28,0.799 C9.28,0.358 8.922,8.8817842e-16 8.48,8.8817842e-16 L6.23,8.8817842e-16 C5.817,8.8817842e-16 5.428,0.16 5.133,0.453 C4.841,0.746 4.68,1.135 4.68,1.549 L4.68,2.299 C4.68,2.713 4.841,3.103 5.134,3.397 C5.428,3.689 5.817,3.849 6.23,3.849 L7.68,3.849 L7.68,5.139 L2.72,5.139 C1.993,5.139 1.31,5.422 0.797,5.935 C0.283,6.448 -8.8817842e-16,7.131 -8.8817842e-16,7.859 L-8.8817842e-16,12.179 C-8.8817842e-16,12.521 0.133,12.842 0.374,13.084 C0.617,13.326 0.938,13.459 1.28,13.459 L5.363,13.459 L5.363,15.199 C5.363,15.641 5.721,15.999 6.163,15.999 C6.604,15.999 6.962,15.641 6.962,15.199 L6.962,13.459 L12.32,13.459 C12.762,13.459 13.12,13.101 13.12,12.659 L13.12,5.939 C13.12,5.497 12.762,5.139 12.32,5.139 L12.32,5.139 Z"></path>
</defs>
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect x="0" y="0" width="20" height="20"></rect>
    <g transform="translate(3, 2)">
        <mask id="mask-mail-def" fill="white">
            <use xlink:href="#path-mail-def"></use>
        </mask>
        <use fill="currentColor" xlink:href="#path-mail-def"></use>
        <rect fill="currentColor" mask="url(#mask-mail-def)" x="-1" y="-1.15463195e-14" width="16" height="16"></rect>
    </g>
</g>
</svg>
