import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SongRequest, SongRequestService } from '@service/song-request.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isFinal } from '@service/vo/asyncStatus';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SNACKBAR_DURATION } from '@view/player-v5/player-v5.component';

@Component({
  selector: 'tun-settings-song-request-panel',
  templateUrl: './settings-song-request-panel.component.html',
  styleUrls: ['./settings-song-request-panel.component.scss']
})
export class SettingsSongRequestPanelComponent implements OnInit, OnDestroy{

  userForm: any;

  constructor(
    private formBuilder: FormBuilder,
    private songRequestService: SongRequestService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      title: ['', Validators.required],
      group: ['', [Validators.required]],
      remark: [''],
    });
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = null;
  }

  public sendingSongRequest = false;
  submitForm(): void {
    if (this.userForm?.valid) {
      this.sendingSongRequest = true;

      const songRequest = new SongRequest();
      songRequest.title = this.userForm.value.title;
      songRequest.groupname = this.userForm.value.group;
      songRequest.remarks = this.userForm.value.remark;
      this.songRequestService.createSongRequest(songRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (isFinal(status)){
          this.sendingSongRequest = false;
        }
        if (status === 'completed'){
          this.userForm.reset();
          const snackBarRef = this.snackBar.open(this.translateService.instant('settings.songrequest.status.done'), null, {
            duration: SNACKBAR_DURATION,
            panelClass: ['tunify-snackbar']
            });
        }else if (status === 'error'){
          const snackBarRef = this.snackBar.open(this.translateService.instant('general.status.error.message'), null, {
            duration: SNACKBAR_DURATION,
            panelClass: ['tunify-snackbar']
            });

        }
      })
    }
  }

  @Output() back = new EventEmitter<void>();

  onBack(){
    this.back.emit();
  }

}
