import { LogTarget, LOGLEVEL } from './logTarget';
import { BaseLogTarget } from './baseLogTarget';

export class ConsoleLogTarget extends BaseLogTarget{

  protected performLogging(logLevel: LOGLEVEL, message: string){
    const messageWithTimeStamp = new Date() + ": "+ message;
    if (logLevel === LOGLEVEL.INFO){
      console.info(messageWithTimeStamp);
    }else if (logLevel === LOGLEVEL.DEBUG){
      console.debug(messageWithTimeStamp);
    }else if (logLevel === LOGLEVEL.WARN){
      console.warn(messageWithTimeStamp);
    }else if (logLevel === LOGLEVEL.ERROR){
      console.error(messageWithTimeStamp);
    }
  }


}
