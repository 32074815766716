import { Component } from '@angular/core';

@Component({
  selector: 'tun-menu-calendar-icon-v5',
  templateUrl: './menu-calendar-icon-v5.component.html',
  styleUrls: ['./menu-calendar-icon-v5.component.scss']
})
export class MenuCalendarIconV5Component {

}
