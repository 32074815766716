<div class="content-wrapper">

  <ng-container *ngIf="(playlistsLoading$ | async)">
    <tun-loader-v5 class="loader"></tun-loader-v5>
  </ng-container>

  <div *ngIf="(playlistsLoadingError$ | async)" class="errorContainer">
    <p>{{'general.status.error.message' | translate}}</p>
    <button (click)="onRetry()" class="retryButton">
      <p>
        {{'general.status.error.retry' | translate }}
      </p>
    </button>
  </div>

  <ng-container *ngIf="!(playlistsLoading$ | async) && !(playlistsLoadingError$ | async)">

    <div class="scrollContainer">
      <tun-overlay-playlist-button-view
        class="playlist first"
        *ngIf="favoriteList$ | async"
        [playlist]="favoriteList$ | async"

        [isChecked]="(favoriteList$ | async) == selectedPlaylist"
        (selectPlaylist)="onSelectPlaylist($event)"
      >
      </tun-overlay-playlist-button-view>

      <ng-container *ngFor="let playlist of (playlists$ | async); index as i">
        <tun-overlay-playlist-button-view
          class="playlist {{ (i == 0 && (favoriteList$ | async) == null) ? 'first' : ''}}"
          [playlist]="playlist"
          [isChecked]="playlist == selectedPlaylist"
          (selectPlaylist)="onSelectPlaylist($event)"
        >
        </tun-overlay-playlist-button-view>
      </ng-container>

    </div>

    <button *ngIf="confirmButtonTranslateableText != null" [disabled]="selectedPlaylist == null" class="commit-button" (click)="onCommit()">
      <div class="commit-icon-container">
        <tun-plus-icon-v5 class="icon icon-commit-button"></tun-plus-icon-v5>
      </div>
       {{confirmButtonTranslateableText | translate}}
    </button>

    <div class="spacer" *ngIf="confirmButtonTranslateableText == null"></div>

  </ng-container>
</div>
