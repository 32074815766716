import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ResizedEvent } from '@components/resize/resize.directive';
import { MusicChannel } from '@model/musicChannel';
import { MusicChannelGroup } from '@model/musicChannelGroup';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { LoggerService } from '@service/loggers/logger.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'tun-music-channel-group-view',
  templateUrl: './music-channel-group-view.component.html',
  styleUrls: ['./music-channel-group-view.component.scss']
})
export class MusicChannelGroupViewComponent implements OnDestroy, AfterViewInit{

  private LOGGER_CLASSNAME = MusicChannelGroupViewComponent.name;

  @ViewChild('container', { static: true }) container: ElementRef;

  @Input() musicChannelGroup: MusicChannelGroup

  @Output() showDetails = new EventEmitter<MusicChannel>();

  public gridClassName = '';

  constructor(
    private loggerService: LoggerService,
    private appV5StateService: AppV5StateService
  ) {

  }

  ngAfterViewInit(): void {
      //this.adjustToSize();
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onShowDetails(musicChannel: MusicChannel){
    this.showDetails.emit(musicChannel);
  }

  public onResizeContainer(event: ResizedEvent){
    this.adjustToSize();
  }

  public adjustToSize(){
    if (this.container){
      const viewRect = this.container.nativeElement.getBoundingClientRect();

      if (viewRect.width > 0){
        const width = viewRect.width;
        const minWidthPerItem = 12.5 * this.appV5StateService.pixelsFor1Rem;
        if (width > 8 * minWidthPerItem){
          this.gridClassName = "grid-8row";
        }else  if (width > 7 * minWidthPerItem){
          this.gridClassName = "grid-7row";
        }else  if (width > 6 * minWidthPerItem){
          this.gridClassName = "grid-6row";
        }else  if (width > 5 * minWidthPerItem){
          this.gridClassName = "grid-5row";
        }else  if (width > 4 * minWidthPerItem){
          this.gridClassName = "grid-4row";
        }else  if (width > 2.75 * minWidthPerItem){
          this.gridClassName = "grid-3row";
        }else if (width > 1.5 * minWidthPerItem){
          this.gridClassName = "grid-2row";
        }
        //this.loggerService.debug(this.LOGGER_CLASSNAME, "resize", "adjust to " + width + " width");

      }
    }
  }




}
