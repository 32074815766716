import { Injectable } from '@angular/core';
import { CalendarGroup } from '../../model/calendarGroup';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { LoggerService } from '../loggers/logger.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from '../config';



export class DTO_LoadCalendarGroupsResponse{
  timestamp: number;
  calendarGroups: [CalendarGroup];
}

@Injectable({
  providedIn: 'root'
})
export class CalendarGroupApiService {

  private LOGGER_CLASSNAME = 'CalendarGroupApiService';

  constructor(private httpClient: HttpClient,
    private zoneConnectionsService: ZoneConnectionsService,
    private loggerService: LoggerService) { }

  public loadCalendarGroups(): Observable<DTO_LoadCalendarGroupsResponse>{
    let resultObservable: Observable<DTO_LoadCalendarGroupsResponse>;

    if (environment.mockBackend) {
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadCalendarGroups', 'about to mock loadCalendarGroups request');
      resultObservable = this.httpClient.get<DTO_LoadCalendarGroupsResponse>("api/calendarGroup");
    } else {
      const url = Config.api4Url_calendarGroups(this.zoneConnectionsService.activeZoneConnection.zoneId);
      resultObservable = this.httpClient.get<DTO_LoadCalendarGroupsResponse>(url);
    }
    return resultObservable;
  }
}
