import { environment } from 'src/environments/environment';

export class Config {

  /**
   * SERVICE RETRY LOGIC -> this is on top of request retry logic
   * SERVICES THAT REALLY NEED TO SAVE OR REQUEST DATA CAN RETRY WITH AN EXPONENTIAL BACKOF
   */

  static RETRY_BASE_INTERVAL_MS = 10000; //10sec (10s - 20s - 40s - 80s - ..)
  static RETRY_MAX_INTERVAL_MS = 600000; //10min

   /**
   * AUTHENTICATION SERVICE
   */

  static api4Url_authenticator = environment.api_authenticator + "/oauth/token?grant_type=password";
  static api4Url_encrypted_authenticator = environment.api_md5_authenticator + "/oauth/token?grant_type=password";
  static api4Url_authenticator_refresh = environment.api_authenticator + "/oauth/token?grant_type=refresh_token";

   /**
   * MUSIC SERVICE
   */

  //MUSIC SERVICE -> playlist endpoints
  static api4Url_playlists = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/custom-playlist-v2`;
  static api4Url_deletePlaylist = (zoneId, playlistId) => `${environment.api_music_service}/zone/${zoneId}/custom-playlist/${playlistId}`;
  static api4Url_savePlaylist = (zoneId, playlistId) => `${environment.api_music_service}/zone/${zoneId}/custom-playlist/${playlistId}`;
  static api4Url_favoritelist = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/custom-playlist/special/favourite`;
  static api4Url_banlist = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/custom-playlist/special/banlist`;

  //MUSIC SERVICE -> zone configuration endpoint-s
  static api4Url_zoneConfiguration = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/zone-configuration`;
  static api4Url_zoneConfiguration_musicSelection = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/zone-configuration/music-selection`;
  static api4Url_zoneConfiguration_language = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/zone-configuration/language`;

  //MUSIC SERVICE -> queue endpoints
  static api4Url_queue = (zoneId, queueType) => `${environment.api_music_service}/zone/${zoneId}/queue/${queueType}`;
  static api4Url_queue_tracks_manipulate = (zoneId, queueType) => `${environment.api_music_service}/zone/${zoneId}/queue/${queueType}/tracks/manipulate`;
  static api4Url_waiting_queue = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/queue/waiting`;
  static api4Url_waiting_queue_tracks_manipulate = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/queue/waiting/tracks/manipulate`;
  static api4Url_radio_queue = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/queue/radio`;
  static api4Url_radio_queue_tracks_manipulate = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/queue/radio/tracks/manipulate`;

  //MUSIC SERVICE -> musicChannel endpoints
  static api4Url_musicChannelGroups = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/v2/music-channel-group`;
  static api4Url_musicCollection = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/music-collection`;
  static api4Url_musicCollection_tweakInfo = (zoneId, musicCollectionId) => `${environment.api_music_service}/zone/${zoneId}/music-collection/${musicCollectionId}/tweak-info`;
  static api4Url_musicChannel_musicCollection = (zoneId, musicChannelId, musicCollectionId) =>  `${environment.api_music_service}/zone/${zoneId}/music-channel/${musicChannelId}/music-collection/${musicCollectionId}/`;

  //MUSIC SERVICE -> search
  static api4Url_search = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/search/find`;
  static api4Url_suggest = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/search/suggest`;

  //MUSIC SERVICE -> search track
  static api4Url_searchTrack = (zoneId, trackId) => `${environment.api_music_service}/zone/${zoneId}/track/${trackId}`;

  //MUSIC SERVICE -> generate playable list of audioFiles endpoint
  static api4Url_audioFile_playableList = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/audiofile`;

  //MUSIC SERVICE -> calendar endpoints
  static api4Url_calendarGroups = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/v2/calendar-group`;
  static api4Url_customCalendars = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/search/calendar?type=Custom`;
  static api4Url_createCalendar = (zoneId) => `${environment.api_music_service}/zone/${zoneId}/calendar/`;
  static api4Url_calendar = (zoneId, calendarId) => `${environment.api_music_service}/zone/${zoneId}/calendar/${calendarId}?tweakLevel=Nothing`;
  static api4Url_calendarItem = (zoneId, calendarId, calendarItemId) => `${environment.api_music_service}/zone/${zoneId}/calendar/${calendarId}/calendarItem/${calendarItemId}`;
  static api4Url_copyCalendar = (zoneId, calendarId) => `${environment.api_music_service}/zone/${zoneId}/calendar/${calendarId}/copy`;
  static api4Url_calendar_manipulateCalendarItems = (zoneId, calendarId) => `${environment.api_music_service}/zone/${zoneId}/calendar/${calendarId}/calendar-items/manipulate/`;

  static api4Url_createCalendarItem = (zoneId, calendarId) => `${environment.api_music_service}/zone/${zoneId}/calendar/${calendarId}/calendarItems/`;
  static api4Url_saveCalendarItem = (zoneId, calendarId, calendarItemId) => `${environment.api_music_service}/zone/${zoneId}/calendar/${calendarId}/calendarItems/${calendarItemId}`;
  static api4Url_deleteCalendarItem = (zoneId, calendarId, calendarItemId) => `${environment.api_music_service}/zone/${zoneId}/calendar/${calendarId}/calendarItems/${calendarItemId}`;

  //MUSIC SERVICE -> config endpoints
  static api4Url_publicConfigs = () => `${environment.api_music_service}/configuration/public/`;
  static api4Url_zoneConfigs = (zoneId) => `${environment.api_music_service}/configuration/zone/${zoneId}/`;

  //LOGGING SERVICE -> activity log endpoints
  static api4Url_trackActivityLog = (zoneId) => `${environment.api_logging_service}/zone/${zoneId}/trackActivity/`;
  static api4Url_clientActivityLog = () => `${environment.api_logging_service}/clientActivity/`;

  //SO?G REQUESTS SERVICE -> song requests endpoints
  static api4Url_songRequests = (zoneId) => `${environment.api_song_requests_service}/zone/${zoneId}/song-request/`;

  //RECOMMENDER SERVICE -> recommendations
  static api4Url_musicChannelRecommendations = () => `${environment.api_recommender_service}/zone/{zoneId}/recommender-collection/`;
  static api4Url_lastPlayed = () => `${environment.api_recommender_service}/zone/{zoneId}/recommendations/lastused/`;


  /**
   * MEDIA SERVICE
   */

  //MEDIA SERVICE -> ENDPOINTS
  static api4Url_playToken = (zoneId) => `${environment.api_media_service}/zone/${zoneId}/play-token`;
  static api4Url_mediaUrl = (zoneId, trackId) => `${environment.api_media_service}/zone/${zoneId}/track/${trackId}/media-url/`;

  //deprecated:
  //static api4Url_audioFileUrl = (zoneId, audioFileId) => `${environment.api_media_service}/zone/${zoneId}/audiofile/${audioFileId}/url`;


  /**
   * SUBSCRIPTIONS  SERVICE
   */

  //SUBSCRIPTIONS SERVICE -> ENDPOINTS
  static api4Url_subscriptionInfo = () => `${environment.api_subscriptions_service}/zone/{zoneId}/subscription-info/`;
  static api4Url_accessRights = () => `${environment.api_subscriptions_service}/zone/{zoneId}/access-rights/`;
  static api4Url_zoneInfo = (zoneId) => `${environment.api_subscriptions_service}/zone/${zoneId}/zone-info/`;

  /**
   * ZONE  SERVICE
   */

  static api4Url_refreshToken = (zoneId) => `${environment.api_zone_service}/zone/${zoneId}/refresh-token/`;
  static api4Url_refreshTokenForZoneCode = (zoneCode) => `${environment.api_zone_service}/zone-code/${zoneCode}/refresh-token/`;
  static api4Url_accessToken = (zoneId) => `${environment.api_zone_service}/zone/${zoneId}/access-token/`;
  static api4Url_zoneConnectionInfo = (zoneId) => `${environment.api_zone_service}/zone/${zoneId}/info/`;

  static api4Url_zoneCode = () => `${environment.api_zone_service}/zone/{zoneId}/zone-code`;
  static api4Url_zoneCode_zoneId = (zoneId) => `${environment.api_zone_service}/zone/${zoneId}/zone-code`;

  /**
   * BRANDS  SERVICE
   */
  static api4Url_messageSchedule = () => `${environment.api_brands_service}/zone/{zoneId}/messageSchedule/v2`;

  static MAX_FADEDURATION_MILLISECONDS = 6000;
  static MIN_FADEDURATION_MILLISECONDS = 3000;

  static MILLISECONDS_PREPARE_TO_PLAY_BEFORE_USE_PUBLIC_URL = 3000;
  static PUBLIC_URL_PREFIX = 'https://rescue-cdn.tunify.com/mp3/stereo/128/';

}
