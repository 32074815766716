<div class="wrapper">

    <!-- header -->
    <div
      class="header-container">

      <div class="header-label">
        {{'calendar.copy.title' | translate }}
      </div>

    </div>

    <div mat-dialog-content class="content-container">
      <div class="text-container">
        {{'calendar.copy.info' | translate }}
      </div>


    </div>
    <div mat-dialog-actions>
      <div class="buttons-container">
          <button class="confirm-button" (click)="onNoClick()" cdkFocusInitial>{{'general.cancel' | translate | uppercase }}</button>
      </div>
      <div class="buttons-right-side">
        <button class="confirm-button" (click)="onYesClick()">{{'calendar.copy.button.commit' | translate | uppercase}}</button>
      </div>
    </div>


  </div>

