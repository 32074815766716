import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpContextToken } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ZoneConnectionsService } from '../../authentication/zone-connections.service';

/**
 * This interceptor is responsible for adding the access token the requests that need it
 *
 */

export const BYPASS_ACCESSTOKEN = new HttpContextToken(() => false);

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private zoneConnectionsService: ZoneConnectionsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    //replace the zoneId
    if (req.url.includes("{zoneId}")){
      if (this.zoneConnectionsService.activeZoneConnection == null){
        throw new HttpErrorResponse({
          error: 'No active zoneConnection',
          headers: req.headers,
          status: 501,
          statusText: 'Warning',
          url: req.url
        });
      }

      req = req.clone({
        url: req.url.replace("{zoneId}", this.zoneConnectionsService.activeZoneConnection.zoneId + "")
      });
    }

    //do not handle request where we bypass
    if (req.context.get(BYPASS_ACCESSTOKEN) === true)
      return next.handle(req);

    //add authorization header (not in all cases)
    if (
      !environment.mockBackend &&
      !req.headers.has('Authorization') && // authentication request, refresh access token request -> already present
      !req.url.includes("blackbox2/services/") && //old webservice
      !req.url.includes("assets") //assets loaded over http (translations)
    ) {

      if (
        this.zoneConnectionsService.activeZoneConnection == null
      ){
        throw new HttpErrorResponse({
          error: 'AuthenticationInterceptor - No active zoneConnection',
          headers: req.headers,
          status: 501,
          statusText: 'Warning',
          url: req.url
        });
      }

      req = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + this.zoneConnectionsService.activeZoneConnection.accessToken)
      });
    }

    return next.handle(req);
  }
}
