import { Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '../loggers/logger.service';
import { BehaviorSubject, Observable, Subject, timer, merge } from 'rxjs';
import { Playlist } from '@model/playlist';
import { finalize, takeUntil, take } from 'rxjs/operators';
import { AudioFile } from '@model/audioFile';
import { createClassObjectForAudioFile } from './util/audioFileUtil';
import { MusicCollectionService } from './music-collection.service';
import { AsyncStatus } from './vo/asyncStatus';
import { MatDialog } from '@angular/material/dialog';
import { DoubleAudioFilesPopupComponent } from '@components/popups/double-audio-files-popup/double-audio-files-popup.component';
import { AppService } from '@service/app.service';
import { PlaylistApiService } from '@service/api/playlist-api.service';
import { ConfirmPopupComponent } from '@components/popups/confirm-popup/confirm-popup.component';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { DTO_CreatePlaylistResponse, DTO_SavePlaylistResponse, DTO_LoadPlaylistsResponse } from '../api/playlist-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TooManyTracksPopupComponent } from '../../components/popups/too-many-tracks-popup/too-many-tracks-popup.component';
import { DeletePlaylistConfirmPopupComponent } from '@components/popups-v5/delete-playlist-confirm-popup/delete-playlist-confirm-popup.component';
import { Config } from '@service/config';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService implements OnDestroy {

  private LOGGER_CLASSNAME = 'PlaylistService';

  //helper observable that triggers when the active zone changes
  //this will cancel any running request
  private activeZoneChanged$ = new Subject<void>();

  constructor(
    private appService: AppService,
    private playlistApiService: PlaylistApiService,
    private zoneConnectionsService: ZoneConnectionsService,
    private musicCollectionService: MusicCollectionService,
    private loggerService: LoggerService,
    private dialog: MatDialog
  ) {
    this.zoneConnectionsService.activeZoneConnection$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (zoneConnection) => {

          //zoneConnection changed -> unload
          this.cleanUpData();

          if (zoneConnection != null) {
            // we just logged in, start loading
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'activeZoneConnectionSubscription', 'logged in ... start loading playlists');
            this.loadPlaylists();
            this.loadFavorites();
            this.loadBanlist();
          } else {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'activeZoneConnectionSubscription', 'logged out ... playlists was unloaded');

          }
        },
        (err: unknown) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'activeZoneConnectionSubscription', 'error from activeZoneConnectionSubscription: ' + err);
        }
      );
  }

  // === Observables === //
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loadingSubject.asObservable();

  /**
   * Loading playlists (favorites & banlist are seperate playlists with their own loading / error flags)
   * @type {boolean}
   * @private
   */
  private get _loading(): boolean {
    return this._loadingSubject.value;
  }

  private set _loading(value: boolean) {
    if (this._loading !== value) {
      this._loadingSubject.next(value);
    }
  }

  get loading(): boolean {
    return this._loading;
  }


  private _loadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public loadingError$: Observable<string> = this._loadingErrorSubject.asObservable();

  /**
   * Error emitter for retrieving the playlists
   * @type {string}
   * @private
   */
  private get _loadingError(): string {
    return this._loadingErrorSubject.value;
  }

  private set _loadingError(value: string) {
    if (this._loadingError !== value) {
      this._loadingErrorSubject.next(value);
    }
  }

  get loadingError(): string {
    return this._loadingError;
  }





  private _playlistsSubject: BehaviorSubject<Playlist[]> = new BehaviorSubject<Playlist[]>(null);
  public playlists$: Observable<Playlist[]> = this._playlistsSubject.asObservable();

  /**
   * playlists
   * @type {Playlist[]}
   * @private
   */
  private get _playlists(): Playlist[] {
    return this._playlistsSubject.value;
  }

  private set _playlists(value: Playlist[]) {
    // if (this._playlists !== value) {
    this._playlistsSubject.next(value);
    // }
  }

  get playlists(): Playlist[] {
    return this._playlists;
  }

  /**
   * Versioning
   */
  private playlistsTimestamp: number = undefined;
  private timestampFromUpdateTrigger: number = undefined; //timestamp that we have received after a save or update event. This is used when the queue is fetched to check if we have the most recent version
  private refetchedToAvoidVersionConflict = false; //boolean to avoid we fetch multiple times in a row (avoid endless loop when the server messes up the version numbers)

  public handleCustomPlaylistsUpdateInfo(timestamp: number){
    //keep track of the highest seen timestamp
    if (timestamp != undefined){
      if (this.timestampFromUpdateTrigger == undefined || this.timestampFromUpdateTrigger < timestamp) {
        this.timestampFromUpdateTrigger = timestamp;
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleCustomPlaylistsUpdateInfo', 'Going to check for newest version:  ' + timestamp);
      }else{
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleCustomPlaylistsUpdateInfo', 'Already going to check for an even newer version:  ' + this.timestampFromUpdateTrigger + '(we just received: ' + timestamp + ')');
      }
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleCustomPlaylistsUpdateInfo', 'No timestamp info');
    }

    this.checkReloadCustomPlaylistsNeeded();
  }

  private checkReloadCustomPlaylistsNeeded(){
    if (this.timestampFromUpdateTrigger != undefined){
      //if we are not modifying the customPlaylists -> fetch
      if (!this.loading && !this.creatingPlaylist && !this.deletingPlaylist) {
        if (this.timestampFromUpdateTrigger > this.playlistsTimestamp){
          this.loadPlaylists();
        }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadCustomPlaylistsNeeded', 'Already have a newer version (' + this.playlistsTimestamp + '), not going to reload for update trigger timestamp ' + this.timestampFromUpdateTrigger);
        }
      }else{
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadCustomPlaylistsNeeded', 'Not going to load right now: loading: ' + (this.loading ? 'true': 'false') + 'creating: ' + (this.creatingPlaylist ? 'true': 'false') + + 'deleting: ' + (this.deletingPlaylist ? 'true': 'false'));
      }
    }

  }

  public handlePlaylistUpdateInfo(playlistId: number, timestamp: number){
    if (timestamp != undefined){
      if (this.favorites && this.favorites.id == playlistId){
        if (this.favorites.timestampFromUpdateTrigger == undefined || this.favorites.timestampFromUpdateTrigger < timestamp){
          this.favorites.timestampFromUpdateTrigger = timestamp;
          this.checkReloadPlaylistNeeded(this.favorites);
        }
      }else if (this.banlist && this.banlist.id == playlistId){
        if (this.banlist.timestampFromUpdateTrigger == undefined || this.banlist.timestampFromUpdateTrigger < timestamp){
          this.banlist.timestampFromUpdateTrigger = timestamp;
          this.checkReloadPlaylistNeeded(this.banlist);
        }
      }else if (this.playlists){
        const playlistWithId = this.playlists.filter(playlist => playlist.id == playlistId);
        if (playlistWithId.length > 0){
          playlistWithId.forEach(playlist => {
            if (playlist.timestampFromUpdateTrigger == undefined || playlist.timestampFromUpdateTrigger < timestamp){
              playlist.timestampFromUpdateTrigger = timestamp;
              this.checkReloadPlaylistNeeded(playlist);
            }
          });
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, 'handlePlaylistUpdateInfo', 'should handle updateInfo for playlist with id ' + playlistId + ', but playlist could not be found.');
        }
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, 'handlePlaylistUpdateInfo', 'should handle updateInfo for playlist with id ' + playlistId + ', but playlists are not yet loaded.');
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'handlePlaylistUpdateInfo', 'should handle updateInfo for playlist with id ' + playlistId + ', but no timestamp received.');
    }

  }

  private checkReloadPlaylistNeeded(playlist: Playlist){
    if (playlist.refetchNeededForConflict){
      this.musicCollectionService.loadMusicCollectionDetails(playlist);
    }else if (playlist.timestampFromUpdateTrigger != undefined){
      if (playlist.timestampFromUpdateTrigger > playlist.timestamp){
        if (!playlist.saving && !playlist.detailsLoading){
          this.musicCollectionService.loadMusicCollectionDetails(playlist);
        }
      }else{
        if (playlist.timestampFromUpdateTrigger == playlist.timestamp){
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadPlaylistNeeded', 'playlist ' + playlist.id + ' already has this version (' + playlist.timestamp + ')');
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, 'checkReloadPlaylistNeeded', 'playlist ' + playlist.id + ' has a newer version (' + playlist.timestamp + ') then seen in the updateInfo (' + playlist.timestampFromUpdateTrigger + ') -> going to ignore update info timestamp.');
        }

      }
    }
  }

  private get creatingPlaylist(): boolean{
    return this.playlists != null && this.playlists.filter(playlist => playlist.creatingPlaylist).length > 0;
  }

  private get deletingPlaylist(): boolean{
    return this.playlists != null && this.playlists.filter(playlist => playlist.deletingPlaylist).length > 0;
  }

  /**
   * show a playlist -> show the tracks of the selected playlist (e.g. from playlist popup)
   */
  public showPlaylist(playlist: Playlist){
    if (playlist != null && !playlist.detailsLoaded && !playlist.detailsLoading){
      this.musicCollectionService.loadMusicCollectionDetails(playlist);
    }
    this._showPlaylistSubject.next(playlist);
  }

  private _showPlaylistSubject: Subject<Playlist> = new Subject<Playlist>();
  public showPlaylist$: Observable<Playlist> = this._showPlaylistSubject.asObservable();


  private _favoritesLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public favoritesLoading$: Observable<boolean> = this._favoritesLoadingSubject.asObservable();

  /**
   * Loading the special favorites playlist
   */
  private get _favoritesLoading(): boolean {
    return this._favoritesLoadingSubject.value;
  }

  private set _favoritesLoading(value: boolean) {
    if (this._favoritesLoading !== value) {
      this._favoritesLoadingSubject.next(value);
    }
  }

  get favoritesLoading(): boolean {
    return this._favoritesLoading;
  }


  private _favoritesLoadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public favoritesLoadingError$: Observable<string> = this._favoritesLoadingErrorSubject.asObservable();

  /**
   * Error emitter for retrieving the special favorites playlists
   */
  private get _favoritesLoadingError(): string {
    return this._favoritesLoadingErrorSubject.value;
  }

  private set _favoritesLoadingError(value: string) {
    if (this._favoritesLoadingError !== value) {
      this._favoritesLoadingErrorSubject.next(value);
    }
  }

  get favoritesLoadingError(): string {
    return this._favoritesLoadingError;
  }


  private _favoritesSubject: BehaviorSubject<Playlist> = new BehaviorSubject<Playlist>(null);
  public favorites$: Observable<Playlist> = this._favoritesSubject.asObservable();

  /**
   * favorites
   */
  private get _favorites(): Playlist {
    return this._favoritesSubject.value;
  }

  private set _favorites(value: Playlist) {
    if (this._favorites !== value) {
      this._favoritesSubject.next(value);
    }
  }

  get favorites(): Playlist {
    return this._favorites;
  }


  private _banlistLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public banlistLoading$: Observable<boolean> = this._banlistLoadingSubject.asObservable();

  /**
   * loading the special banlist
   */
  private get _banlistLoading(): boolean {
    return this._banlistLoadingSubject.value;
  }

  private set _banlistLoading(value: boolean) {
    if (this._banlistLoading !== value) {
      this._banlistLoadingSubject.next(value);
    }
  }

  get banlistLoading(): boolean {
    return this._banlistLoading;
  }


  private _banlistLoadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public banlistLoadingError$: Observable<string> = this._banlistLoadingErrorSubject.asObservable();

  /**
   * Error emitter for retrieving the special favorites playlists
   */
  private get _banlistLoadingError(): string {
    return this._banlistLoadingErrorSubject.value;
  }

  private set _banlistLoadingError(value: string) {
    if (this._banlistLoadingError !== value) {
      this._banlistLoadingErrorSubject.next(value);
    }
  }

  get banlistLoadingError(): string {
    return this._banlistLoadingError;
  }


  private _banlistSubject: BehaviorSubject<Playlist> = new BehaviorSubject<Playlist>(null);
  public banlist$: Observable<Playlist> = this._banlistSubject.asObservable();

  /**
   * Banlist
   */
  private get _banlist(): Playlist {
    return this._banlistSubject.value;
  }

  private set _banlist(value: Playlist) {
    if (this._banlist !== value) {
      this._banlistSubject.next(value);
    }
  }

  get banlist(): Playlist {
    return this._banlist;
  }

  private destroy$ = new Subject<void>();
  // === Methods === //
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = null;

    this.activeZoneChanged$.next();
    this.activeZoneChanged$.complete();
    this.activeZoneChanged$ = null;
  }


  private playlistsWillStartLoading$: Subject<void> = new Subject<void>();
  public loadPlaylists(): Observable<Playlist[]> {

    const playlistsObservable: Observable<DTO_LoadPlaylistsResponse> = this.playlistApiService.loadPlaylists();
    if (playlistsObservable != null){

      this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadPlaylists', 'Going to load playlists');
      this.playlistsWillStartLoading$.next(null);

      this._loading = true;

      playlistsObservable
        .pipe(
          takeUntil(
            merge(
              this.destroy$,
              this.activeZoneChanged$,
              this.playlistsWillStartLoading$
            )
          ),
          finalize(() => {
            this._loading = false;
            this.checkReloadCustomPlaylistsNeeded();
          })
        )
        .subscribe(
          (data) => {

            let useResponse = true;
            if (this.timestampFromUpdateTrigger != undefined && this.timestampFromUpdateTrigger > data.timestamp) {
              //there is a newer resource version on the server -> refetch
              if (!this.refetchedToAvoidVersionConflict) {
                //we are only going to refetch once, to avoid endless loop of fetching data when there is something wrong with our version timestamps
                this.refetchedToAvoidVersionConflict = true;
                useResponse = false;
              } else {
                //ignore the newer version and start using this queue

                this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadPlaylists', 'loadPlaylists receives an older version number but we already refetched before -> just start working with this version');
              }
            }

            if (useResponse) {

              //reset the conflict flag once we use the queue
              this.refetchedToAvoidVersionConflict = false;
              //reset the highest seen timestamp
              this.timestampFromUpdateTrigger = undefined;

              if (data.customPlaylists.length > 0){
                const realObjects = data.customPlaylists.filter(playlist => playlist['@type'] == 'Playlist').map(res => new Playlist(res));
                const customPlaylists = realObjects.sort(this.sortPlaylistFunction);
                this.mergeUpToDatePlaylistsDetails(customPlaylists);
                this._playlists = customPlaylists;
              }else{
                this._playlists = [];
              }

              this.playlistsTimestamp = data.timestamp;

              this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadPlaylists', 'loadPlaylists done (timestamp: ' + this.playlistsTimestamp + '): ' + this.playlists.length + ' playlists.');
            } else {
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadPlaylists', 'Not going to use result -> reload playlists from the finalize block.');
            }
          },
          (error : unknown) => {
            let errMsg = 'Error';
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            }
            this.loggerService.error(this.LOGGER_CLASSNAME, 'loadPlaylists error', errMsg);
            this._loadingError = 'GeneralError';
          });

    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadPlaylists', 'Observable to load playlists was not created');
    }

    return this.playlists$;
  }

  //helper function that will merge all up-to-date details of our current customPlaylists into the new array.
  //This will avoid having to reload the tracks of unchanged playlists
  private mergeUpToDatePlaylistsDetails(customPlaylists: Playlist[]){
    if (customPlaylists != null && this.playlists != null){
      customPlaylists.forEach(customPlaylist => {
        const filteredPlaylists = this.playlists.filter(playlist => playlist.id == customPlaylist.id && playlist.timestamp >= customPlaylist.timestamp);
        if (filteredPlaylists.length > 0){
          customPlaylist.tranferUpToDateDetails(filteredPlaylists[0]);
        }
      });
    }
  }

  public loadFavorites(): Observable<Playlist> {
    const playlistObservable: Observable<Playlist> = this.playlistApiService.loadFavorites();

    if (playlistObservable != null){

      this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadFavorites', 'Going to start loading favorites');

      this._favoritesLoading = true;
      this._favoritesLoadingError = null;

      playlistObservable
        .pipe(
          takeUntil(
            merge(
              this.destroy$,
              this.activeZoneChanged$
            )
          ),
          finalize(() => {
            this._favoritesLoading = false;
          })
        )
        .subscribe(
          data => {
            // this.logger.debug(this.LOGGER_CLASSNAME, "loadFavorites", "data : " + data);
            // we should construct real objects with the correct type (so we can use instanceof to check the type)
            const realObject = new Playlist(data);

            // favorites are loaded right away
            realObject.detailsLoaded = true;

            this._favorites = realObject;
          },
          (error: unknown) => {
            let errMsg = 'Error';
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            }
            this.loggerService.error(this.LOGGER_CLASSNAME, 'loadFavorites error', errMsg);
            this._favoritesLoadingError = 'GeneralError';
          }
        );
    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadFavorites', 'Observable to load favorites was not created');
    }

    return this.favorites$;
  }

  public loadBanlist(): Observable<Playlist> {

    const playlistObservable: Observable<Playlist> = this.playlistApiService.loadBanlist();

    if (playlistObservable != null){

      this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadBanlist', 'loading banlist');

      this._banlistLoading = true;
      this._banlistLoadingError = null;

      playlistObservable
      .pipe(
        takeUntil(
          merge(
            this.destroy$,
            this.activeZoneChanged$
          )
        ),
        finalize(() => {
          this._banlistLoading = false;
        })
      )
      .subscribe(
        data => {
          // this.logger.debug(this.LOGGER_CLASSNAME, "loadFavorites", "data : " + data);
          // we should construct real objects with the correct type (so we can use instanceof to check the type)
          const realObject = new Playlist(data);

          // favorites are loaded right away
          realObject.detailsLoaded = true;

          this._banlist = realObject;
        },
        (error: unknown) => {
          let errMsg = 'Error';
          if (error instanceof HttpErrorResponse){
            errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
          }
          this.loggerService.error(this.LOGGER_CLASSNAME, 'loadBanlist error', errMsg);
          this._banlistLoadingError = 'GeneralError';
        }
      );
    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadBanlist', 'Observable to load banlist was not created');
    }

    return this.favorites$;
  }

  public createPlaylist(playlist: Playlist): Observable<AsyncStatus> {
    playlist.id = 0;
    if (playlist.description == null || playlist.description === '') {
      playlist.description = playlist.title;
    }

    // we create a copy of all the audioFiles
    let clonedAudioFile;
    const audioFilesToAdd = [];
    if (playlist.audioFiles && playlist.audioFiles.length > 0) {
      playlist.audioFiles.forEach((audioFile) => {
        clonedAudioFile = createClassObjectForAudioFile(audioFile);
        audioFilesToAdd.push(clonedAudioFile);
      });
    }
    playlist.audioFiles = audioFilesToAdd;

    playlist.detailsLoaded = true;
    playlist.creatingPlaylist = true;

    // add the playlist from start
    this.playlists.push(playlist);
    this.playlists.sort(this.sortPlaylistFunction);
    this._playlists = this.playlists;

    const notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    const createPlaylistObservable: Observable<DTO_CreatePlaylistResponse> = this.playlistApiService.createPlaylist(playlist, this.playlistsTimestamp);

    if (createPlaylistObservable != null){
      createPlaylistObservable
        .pipe(
          takeUntil(
            merge(
              this.destroy$,
              this.activeZoneChanged$
            )
          ),
          finalize(() => {
            playlist.creatingPlaylist = false;
            this.checkReloadCustomPlaylistsNeeded();
          })
        )
        .subscribe(
          data => {
            // this.logger.debug(this.LOGGER_CLASSNAME, "createPlaylist", "data : " + data);
            if (data.customPlaylistsTimestamp != undefined){
              this.playlistsTimestamp = data.customPlaylistsTimestamp;
            }
            const playlistFromResponse = data.playlist;
            playlist.id = playlistFromResponse.id;
            playlist.audioFilesChanged = false;
            notifier.next(AsyncStatus.COMPLETED);
            notifier.complete();
          },
          (error: unknown) => {
            let errMsg = 'Error';
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            }
            this.loggerService.error(this.LOGGER_CLASSNAME, 'createPlaylist error', 'Error creating playlist: ' + errMsg);

            this.playlistNotCreated(playlist, notifier);
          }
        );
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'createPlaylist', 'Observable to create a playlist was not created');
      timer(0)
      .subscribe(
        () => {
          this.playlistNotCreated(playlist, notifier);
          this.checkReloadCustomPlaylistsNeeded();
        }
      );
    }

    return notifier;
  }

  private playlistNotCreated(playlist: Playlist, notifier: BehaviorSubject<AsyncStatus> ){
     //adjust marker, view must show feedback based on this field
     playlist.createPlaylistFailed = true;

     //remove the playlist from the menu
     const index = this.playlists.indexOf(playlist);
     this.playlists.splice(index, 1);
     this._playlists = this.playlists;

     notifier.next(AsyncStatus.ERROR);
     notifier.complete();
  }



  public deletePlaylist(playlist: Playlist): Observable<AsyncStatus> {

    const notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    playlist.deletingPlaylist = true;

    //user must confirm with popup -> this can lead to a cancelled state
    const dialogRef = this.dialog.open(DeletePlaylistConfirmPopupComponent, {
      width: DeletePlaylistConfirmPopupComponent.widthForPopup,
      disableClose: true,
      data: {playlist: playlist, confirm: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm == true){
        this.performDeletePlaylist(playlist, notifier);
      }else{
        playlist.deletingPlaylist = false;
        setTimeout(() => {
          notifier.next(AsyncStatus.CANCELLED);
          notifier.complete();
        });
      }
    });


    /*
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: ConfirmPopupComponent.widthForPopup,
      disableClose: true,
      data: {playlist: playlist}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirmed == true){
        this.performDeletePlaylist(playlist, notifier);
      }else{
        playlist.deletingPlaylist = false;
        setTimeout(() => {
          notifier.next(AsyncStatus.CANCELLED);
          notifier.complete();
        });
      }
    });

    */

    return notifier;

  }



  private performDeletePlaylist(playlist: Playlist, notifier: BehaviorSubject<AsyncStatus>){
     //delete playlist
     const deletePlaylistObservable = this.playlistApiService.deletePlaylist(playlist, this.playlistsTimestamp);

     if (deletePlaylistObservable){
       deletePlaylistObservable
       .pipe(
        takeUntil(
          merge(
            this.destroy$,
            this.activeZoneChanged$
          )
        ),
        finalize(() => {
          playlist.deletingPlaylist = false;
          this.checkReloadCustomPlaylistsNeeded();
        })
      )
      .subscribe(
         data => {

          if (data.customPlaylistsTimestamp != undefined){
            this.playlistsTimestamp = data.customPlaylistsTimestamp;
          }

           //update model (for detail view)
           playlist.deleted = true;

           //remove the playlist from the menu
           const index = this.playlists.indexOf(playlist);
           this.playlists.splice(index, 1);
           this._playlists = this.playlists;

           // this.logger.debug(this.LOGGER_CLASSNAME, "createPlaylist", "data : " + data);
           notifier.next(AsyncStatus.COMPLETED);
           notifier.complete();

         },
         (error: unknown) => {
           let errMsg = 'Error';
           if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';
           }
           this.loggerService.error(this.LOGGER_CLASSNAME, 'performDeletePlaylist error', errMsg);

           notifier.next(AsyncStatus.ERROR);
           notifier.complete();
         }
       );
     }else{
       playlist.deletingPlaylist = false;
       this.checkReloadCustomPlaylistsNeeded();
       setTimeout(() => {
          notifier.next(AsyncStatus.INVALID_ACTION);
          notifier.complete();
       });
     }
  }

  public addAudioFileToPlaylist(playlist: Playlist, audioFiles: AudioFile[], index?: number, notifier?: BehaviorSubject<AsyncStatus>)
    : Observable<AsyncStatus> {

      if (notifier == null) {
      notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);
    }

    if (audioFiles && audioFiles.length > 0) {
      if (playlist.detailsLoaded) {

        // todo -> keep track of previous version (for undo actions)
        // saveAudioFilesBeforeLastActionOnPlaylist(playlist);

        // check for amount: max 2500
        if (playlist.audioFiles.length + audioFiles.length > 2500){
          //show popup
          const dialogRef = this.dialog.open(TooManyTracksPopupComponent, {
            width: TooManyTracksPopupComponent.widthForPopup,
            data: {playlist: playlist}
          });

          //cancel
          notifier.next(AsyncStatus.CANCELLED);
          setTimeout(() => {
            notifier.complete();
          }, 0);
        }else{
          // check for doubles
          const doubleAudioFiles = [];
          const checkedAudioFiles = [];
          audioFiles.forEach(
            (audioFileToAdd) => {
              if (this.appService.checkDoubles && playlist.audioFiles.some((audioFile) => audioFile.id === audioFileToAdd.id)) {
                doubleAudioFiles.push(audioFileToAdd);
              } else {
                checkedAudioFiles.push(audioFileToAdd);
              }
            }
          );

          if (doubleAudioFiles.length > 0) {
            // if there are doubles -> ask user, this can lead to a cancelled state
            const dialogRef = this.dialog.open(DoubleAudioFilesPopupComponent, {
              width: DoubleAudioFilesPopupComponent.widthForPopup,
              disableClose: true,
              data: { audioFilesToConfirm: doubleAudioFiles }
            });

            dialogRef.afterClosed().subscribe(result => {

              if (result.stopAsking) {
                this.appService.checkDoubles = false;
              }

              if (result.cancelled) {
                notifier.next(AsyncStatus.CANCELLED);
                notifier.complete();
              } else {
                const audioFilesToAddAfterCheck = checkedAudioFiles.concat(result.confirmedAudioFiles);
                if (audioFilesToAddAfterCheck.length > 0) {
                  this.performAddToPlaylist(playlist, audioFilesToAddAfterCheck, index, notifier);
                } else {
                  notifier.next(AsyncStatus.CANCELLED);
                  notifier.complete();
                }
              }
            });
          } else {
            this.performAddToPlaylist(playlist, audioFiles, index, notifier);
          }
        }
      } else {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'addAudioFileToPlaylist',
          'details not yet loaded when trying to add audioFiles to a playlist -> going to load audioFiles first');

        if (playlist.audioFilesToAddAfterLoadSucces == null) {
          playlist.audioFilesToAddAfterLoadSucces = [];
        }
        audioFiles.forEach((audioFile) => {
          playlist.audioFilesToAddAfterLoadSucces.push(audioFile);
        });

        this.loadAudioFilesFromPlaylist(playlist, notifier);
      }
    } else {
      notifier.next(AsyncStatus.INVALID_ACTION);
      setTimeout(() => {
        notifier.complete();
      }, 0);
      this.loggerService.error(this.LOGGER_CLASSNAME, 'addAudioFileToPlaylist',
        'trying to add audioFiles but there are not audioFiles to add');
    }
    return notifier;
  }

  public moveAudioFileInPlaylist(playlist: Playlist, audioFiles: AudioFile[], index: number): Observable<AsyncStatus> {
    const notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    if (audioFiles && audioFiles.length > 0) {
      if (playlist.detailsLoaded) {

        // we are going to perform the actions on a cloned array
        const audioFilesCopy = Object.assign([], playlist.audioFiles);
        audioFiles.forEach(
          (audioFile) => {
            const audioFileIndex = audioFilesCopy.indexOf(audioFile);
            if (audioFileIndex > -1) {
              audioFilesCopy.splice(audioFileIndex, 1);
              if (audioFileIndex < index) {
                // move index to front -> item before index was removed
                //index--;
              }
              audioFilesCopy.splice(index, 0, audioFile);
            } else {
              // the audioFile was not in de array -> ignore it
            }
          }
        );

        playlist.audioFiles = audioFilesCopy;

        playlist.audioFilesHaveChanged.next(true);
        playlist.audioFilesChanged = true;
        playlist.localChanges = true;
        playlist.saveNotifiers.push(notifier);
        this.savePlaylist(playlist);
      } else {
        // we can't move audioFiles from a playlist if the audioFiles are not yet loaded
        notifier.next(AsyncStatus.INVALID_ACTION);
        setTimeout(() => {
          notifier.complete();
        }, 0);
      }
    } else {
      // there are no audioFiles to move
      notifier.next(AsyncStatus.INVALID_ACTION);
      setTimeout(() => {
        notifier.complete();
      }, 0);
    }

    return notifier;
  }

  public deleteAudioFileInPlaylist(playlist: Playlist, audioFiles: AudioFile[]): Observable<AsyncStatus> {
    const notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);

    if (audioFiles && audioFiles.length > 0) {
      if (playlist.detailsLoaded) {

        // we are going to perform the actions on a cloned array
        const audioFilesCopy = Object.assign([], playlist.audioFiles);
        audioFiles.forEach(
          (audioFile) => {
            const audioFileIndex = audioFilesCopy.indexOf(audioFile);
            if (audioFileIndex > -1) {
              audioFilesCopy.splice(audioFileIndex, 1);
            } else {
              // the audioFile was not in de array -> ignore it
            }
          }
        );

        playlist.audioFiles = audioFilesCopy;

        playlist.audioFilesHaveChanged.next(true);
        playlist.audioFilesChanged = true;
        playlist.localChanges = true;
        playlist.saveNotifiers.push(notifier);
        this.savePlaylist(playlist);
      } else {
        // we can't delete audioFiles from a playlist if the audioFiles are not yet loaded
        notifier.next(AsyncStatus.INVALID_ACTION);
        setTimeout(() => {
          notifier.complete();
        }, 0);
      }
    } else {
      // there are no audioFiles to delete
      notifier.next(AsyncStatus.INVALID_ACTION);
      setTimeout(() => {
        notifier.complete();
      }, 0);
    }

    return notifier;
  }

  // === Private methods === //
  private cleanUpData() {
    this.activeZoneChanged$.next();

    this._playlists = null;
    this._loading = false;
    this._loadingError = null;

    this._favorites = null;
    this._favoritesLoading = false;
    this._favoritesLoadingError = null;

    this._banlist = null;
    this._banlistLoading = false;
    this._banlistLoadingError = null;
  }

  /**
   * The sort function for the playlist, they are alphabetic according to title
   */
  private sortPlaylistFunction(playlist1: Playlist, playlist2: Playlist): number {
    if (playlist1.title.toLowerCase() < playlist2.title.toLowerCase()) {
      return -1;
    } else if (playlist1.title.toLowerCase() > playlist2.title.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  private performAddToPlaylist(playlist: Playlist, audioFiles: AudioFile[], index?: number, notifier?: BehaviorSubject<AsyncStatus>) {
    let clonedAudioFile: AudioFile;
    const addedAudioFiles = [];
    if (index == null || index >= playlist.audioFiles.length) {
      audioFiles.forEach((audioFile) => {
        clonedAudioFile = createClassObjectForAudioFile(audioFile);
        addedAudioFiles.push(clonedAudioFile);
        playlist.audioFiles = playlist.audioFiles.concat([clonedAudioFile]);
      });
    } else {
      audioFiles.slice().reverse().forEach((audioFile) => {
        clonedAudioFile = createClassObjectForAudioFile(audioFile);
        addedAudioFiles.push(clonedAudioFile);
        playlist.audioFiles.splice(index, 0, clonedAudioFile);
        playlist.audioFiles = [...playlist.audioFiles];
      });
    }

    playlist.audioFilesHaveChanged.next(true);
    playlist.audioFilesChanged = true;
    playlist.localChanges = true;
    playlist.saveNotifiers.push(notifier);
    this.savePlaylist(playlist);
  }

  private loadAudioFilesFromPlaylist(playlist: Playlist, notifier: BehaviorSubject<AsyncStatus>) {
    playlist.detailsloadingDone
    .pipe(
      take(1),
      takeUntil(
        merge(
          this.destroy$,
          this.activeZoneChanged$
        )
      )
    )
    .subscribe(
      (success) => {
        if (success) {
          const audioFilesToAdd = playlist.audioFilesToAddAfterLoadSucces;
          playlist.audioFilesToAddAfterLoadSucces = null;
          this.addAudioFileToPlaylist(playlist, audioFilesToAdd, null, notifier);
        } else {
          // we failed to load the audioFiles -> cancel the add audioFiles and give an error feedback
          playlist.audioFilesToAddAfterLoadSucces = null;

          notifier.next(AsyncStatus.ERROR);
          notifier.complete();
        }
      }
    );
    if (!playlist.detailsLoaded && !playlist.detailsLoading) {
      this.musicCollectionService.loadMusicCollectionDetails(playlist);
    }
  }



  private retrySavePlaylist(playlist: Playlist){
    const delay = Math.pow(2, playlist.saveFailedAttemps - 1) * Config.RETRY_BASE_INTERVAL_MS;
    timer(Math.min(delay, Config.RETRY_MAX_INTERVAL_MS))
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      ()=>{
        if (!playlist.deleted && !playlist.deletingPlaylist){
          if (playlist.localChanges && !playlist.detailsLoading){
            this.savePlaylist(playlist);
          }
        }
      }
    )
  }

  private savePlaylist(playlist: Playlist) {
    if (!playlist.saving){

      const savePlaylistObservable: Observable<DTO_SavePlaylistResponse> = this.playlistApiService.savePlaylist(playlist, playlist.audioFilesChanged);
      const currentNotifiers = playlist.saveNotifiers;
      playlist.saveNotifiers = [];

    if (savePlaylistObservable != null){

      playlist.saving = true;

      const audioFilesChangedBeforeSave = playlist.audioFilesChanged;
      playlist.audioFilesChanged = false;
      playlist.localChanges = false;

      savePlaylistObservable
      .pipe(
        takeUntil(
          merge(
            this.destroy$,
            this.activeZoneChanged$
          )
        ),
        finalize(() => {
          playlist.saving = false;

          //before we re-save or do a second save -> check if we need to reload
          this.checkReloadPlaylistNeeded(playlist);

          if (playlist.localChanges && !playlist.detailsLoading){
            this.retrySavePlaylist(playlist);
          }
        })
      ).subscribe(
        data => {
          if (data.timestamp != undefined){
            playlist.timestamp = data.timestamp;
          }
          playlist.saveFailedAttemps = 0;
          currentNotifiers.forEach(notifier => {
            notifier.next(AsyncStatus.COMPLETED);
            notifier.complete();
          });

        },
        (error: unknown) => {
          if (error instanceof HttpErrorResponse){
            const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';

            playlist.saveFailedAttemps++;

            if (error.status == 409){
              this.loggerService.error(this.LOGGER_CLASSNAME, "savePlaylist", "error saving playlist " + playlist.title + " (" + playlist.id + ") -> going to refetch. error: " + errMsg);
              playlist.refetchNeededForConflict = true;
            }else if (error.status == 406){
              this.loggerService.error(this.LOGGER_CLASSNAME, "savePlaylist", "error saving playlist " + playlist.title + " (" + playlist.id + ") -> going to refetch. error: " + errMsg);
              playlist.refetchNeededForConflict = true;
            }else if (error.status == 400){
              //BAD REQUEST: invalid playlist?
              this.loggerService.error(this.LOGGER_CLASSNAME, "savePlaylist", "error saving playlist " + playlist.title + " (" + playlist.id + ") -> going to refetch. error: " + errMsg);
              playlist.refetchNeededForConflict = true;
            }else{
              this.loggerService.error(this.LOGGER_CLASSNAME, "savePlaylist", "error saving playlist " + playlist.title + " (" + playlist.id + ") -> going to retry. error: " + errMsg);
            }
          }else{
            this.loggerService.error(this.LOGGER_CLASSNAME, "savePlaylist", "error saving playlist " + playlist.title + " (" + playlist.id + ") -> going to retry. unknown error type: " + error);
          }

          //save failed -> we have local changes
          playlist.localChanges = true;
          playlist.audioFilesChanged = playlist.audioFilesChanged || audioFilesChangedBeforeSave;

          currentNotifiers.forEach(notifier => {
            notifier.next(AsyncStatus.ERROR);
            notifier.complete();
          });

        }
      );

    }else{
      timer(0)
      .subscribe(() => {
        currentNotifiers.forEach(notifier => {
          notifier.next(AsyncStatus.ERROR);
          notifier.complete();
        });
      });
      this.loggerService.error(this.LOGGER_CLASSNAME, 'savePlaylist', 'Observable to save a playlist could not be created');
    }
    }
  }


  public savePlaylistTitle(playlist: Playlist): Observable<AsyncStatus> {
    let notifier = new BehaviorSubject<AsyncStatus>(AsyncStatus.RUNNING);
    playlist.saveNotifiers.push(notifier);
    playlist.localChanges = true;
    this.savePlaylist(playlist);
    return notifier;
  }


}
