<!-- This component is shown with an animation. We can not add another animation to this component (angular bug), so we create an extra sub-component as a solution -->

<tun-playlist-detail-content-view
  [showPlaylist]="showPlaylist"
  (addSongs)="onAddSongs($event)"
  (changeName)="onChangeName($event)"
  class="wrapper"
>

</tun-playlist-detail-content-view>
