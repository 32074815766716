import { Injectable } from '@angular/core';
import { TrackOrigin } from '@model/trackOrigin';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from '@service/config';
import { BYPASS_ACCESSTOKEN } from '../data/interceptors/authentication-interceptor';
import { BYPASS_RETRY } from '../data/interceptors/retryInterceptor';

/**
 * Track activity logs
 */

export enum DTO_TrackActivity_Action {
  START = "start",
  PAUSE = "pause",
  RESUME = "resume",
  STOP = "stop",
}

export class DTO_TrackActivityLogs {
  timestamp: number;
  trackActivities: DTO_TrackActivity[];
}

export class DTO_TrackActivity {
  track: DTO_TrackActivity_Track;
  timestamp: number;
  positionMillis: number;
  regionId: number;
  playToken: string;
  playerType: string;
  action: DTO_TrackActivity_Action;
}

export class DTO_TrackActivity_Track {
  id: number;
  origin: TrackOrigin;
}

/**
 * Client activity logs
 */

 export class DTO_ClientActivity {
  guid: string;
  zoneId: string = "";
  playerToken: string;
  date: string;
  level: string;
  message: string;
}


@Injectable({
  providedIn: 'root'
})
export class LoggingApiService {

  constructor(
    private httpClient: HttpClient
    ) { }

  public sendLogs(lastSeenZoneId: number, trackActivityLogs: DTO_TrackActivityLogs): Observable<any> {
    let logsObservable: Observable<any> = null;
    if (environment.mockBackend) {
      //just ignore - don't send anything
    } else {
      const url = Config.api4Url_trackActivityLog(lastSeenZoneId);
      logsObservable = this.httpClient.post<any>(url, trackActivityLogs);
    }
    return logsObservable;
  }

  public sendClientActivityLogs(clientActivityLogs: DTO_ClientActivity[]): Observable<any> {
    let logsObservable: Observable<any> = null;
    if (environment.mockBackend) {
      //just ignore - don't send anything
    } else {
      const url = Config.api4Url_clientActivityLog();
      logsObservable = this.httpClient.post<any>(url, clientActivityLogs, { context: new HttpContext().set(BYPASS_ACCESSTOKEN, true).set(BYPASS_RETRY, true) });
    }
    return logsObservable;
  }
}
