<!--<login-form fxFlex (close)="onClose()"></login-form>-->

<div class="container playerBackground">

    <div class="content-container fade-borders">

      <div
          [style.height.px]="heightPerItem$ | async"
          [style.padding-top.px]="2 * (heightPerItem$ | async)"
        >
          <tun-tunify-icon></tun-tunify-icon>
        </div>

        <div class="status"
          [style.height.px]="heightPerItem$ | async"
          [style.padding-top.px]="heightPerItem$ | async"
        >
          <label *ngIf="zoneCodeConnectionsState == ZoneCodeConnectionsState.createError" translate>{{creationError$ | async}}</label>
          <tun-loader *ngIf="zoneCodeConnectionsState == ZoneCodeConnectionsState.creatingConnection" text="connectZone.busy"></tun-loader>
          <tun-loader *ngIf="zoneCodeConnectionsState == ZoneCodeConnectionsState.connecting" text="login.busy"></tun-loader>
        </div>

        <div
          *ngIf="backEnabled$ | async"
          class="button-container"
        >
          <button
            [style.width.px]="itemWidth$ | async"
            [style.min-height.px]="(heightPerItem$ | async)"
            (click)="onClose()"
            class="button-light"
            translate>
              <fa-icon
                class="button-item-icon"
                size="lg"
                [icon]="faAngleLeft"
              ></fa-icon>
              zoneConnection.back
          </button>
        </div>

        <div *ngIf="showConnect" class="button-container">
          <button class="link-button zone-code-info-container" (click)="onConnectThroughBrowser()">
            <p translate>
              Koppel via de browser
            </p>
          </button>
        </div>

        <div class="small-info-container" translate>
          connectZone.code.info
        </div>



        <div class="code-input-container">

          <tun-split-input class="zone-code-input-container" (completed)="handleZoneCodeCompleted($event)">
            <input #input1 (input)="clearWhenEmpty(input1.value)" inputmode="numeric"  pattern="[0-9]*" digitOnly #firstInput class="zone-code-input-field" [disabled]="!codeInputEnabled" tunSplitInput type="text" maxlength="1">
            <input #input2 (input)="clearWhenEmpty(input2.value)" inputmode="numeric" pattern="[0-9]*" digitOnly class="zone-code-input-field" [disabled]="!codeInputEnabled" tunSplitInput type="text" maxlength="1">
            <span class="code-spacer">-</span>
            <input #input3 (input)="clearWhenEmpty(input3.value)" inputmode="numeric" pattern="[0-9]*" digitOnly class="zone-code-input-field" [disabled]="!codeInputEnabled" tunSplitInput type="text" maxlength="1">
            <input #input4 (input)="clearWhenEmpty(input4.value)" inputmode="numeric" pattern="[0-9]*" digitOnly class="zone-code-input-field" [disabled]="!codeInputEnabled" tunSplitInput type="text" maxlength="1">
            <span inputmode="numeric" pattern="[0-9]*" class="code-spacer">-</span>
            <input #input5 (input)="clearWhenEmpty(input5.value)" inputmode="numeric" pattern="[0-9]*" digitOnly class="zone-code-input-field" [disabled]="!codeInputEnabled" tunSplitInput type="text" maxlength="1">
            <input #input6 (input)="clearWhenEmpty(input6.value)" inputmode="numeric" pattern="[0-9]*" digitOnly class="zone-code-input-field" [disabled]="!codeInputEnabled" tunSplitInput type="text" maxlength="1">
          </tun-split-input>

        </div>

        <div class="button-container">
          <button
            [disabled]="!(connectEnabled$ | async)"
            type="submit"
            [style.width.px]="itemWidth$ | async"
            [style.min-height.px]="(heightPerItem$ | async)"
            (click)="connect()"
            class="start-button"
            translate>zoneConnection.connect
          </button>
        </div>

        <div class="button-container">
          <button class="link-button zone-code-info-container" (click)="onHelpClick()">
            <p translate>
              connectZone.info
            </p>
          </button>
        </div>


        <div class="spacer"></div>

        <div class="info-container connect-info-container" translate>
          connectZone.register.info
        </div>
        <div class="connect-new-container">
          <button class="connect-button" [style.width.px]="itemWidth$ | async" [style.min-height.px]="1.5 * (heightPerItem$ | async)" (click)="onRegisterClick()" translate>connectZone.register.link</button>
        </div>

        <div class="info-container" translate>
          connectZone.info.contact
        </div>

    </div>
</div>
