<div class="content-wrapper">

  <div class="title-container">

    <button class="icon-button" (click)="onBack()">
      <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
    </button>

    <div class="title">{{'playlist.label.title' | translate }}</div>

  </div>

  <tun-select-playlist-view
    class="contentContainer"
    (closeWithPlaylist)="onCloseWithPlaylist($event)"
  >

  </tun-select-playlist-view>






</div>
