import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoggerService } from '@service/loggers/logger.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@service/authentication.service';
import { Config } from '@service/config';



export enum DTO_SubscriptionType{
   NONE = "none",
   UNDEFINED = "undefined",
   GREEN = "green",
   BLUE = "blue",
   ORANGE = "orange"
}

export class DTO_SubscriptionInfo{
  //These properties are used to show a message about the current subscription (message in message center)
  daysFilledIn: boolean;
  remainingDays: number;
  extendedPeriodEndsAt: number;
  inExtendedActivationPeriod: boolean;

  //Used as a trigger to reload the accessRights
  subscriptionType: DTO_SubscriptionType;

  name: string;
  lastValidDate: string;
  autoRenewalDate: string;

  //royaltyFree: boolean;
}

export class DTO_AccessRights {
  playMusic: boolean;
  accessBlue: boolean;
  accessOrange: boolean;
  prelisten: boolean;
  search: boolean;
  customCalendars: boolean;
  customPlaylists: boolean;
  addToQueue: boolean;
  startTrack: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsApiService {

  private LOGGER_CLASSNAME = 'SubscriptionsApiService';

  constructor(
    private loggerService: LoggerService,
    private httpClient: HttpClient,
  ) {

  }

  public loadSubscriptionInfo(): Observable<DTO_SubscriptionInfo>{
    let subscriptionObservable: Observable<DTO_SubscriptionInfo>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadZoneConfiguration', 'about to mock loadZoneConfiguration request');
      subscriptionObservable = this.httpClient.get<DTO_SubscriptionInfo>("api/subscriptionInfo");
    }else{
      let url = Config.api4Url_subscriptionInfo();
      subscriptionObservable = this.httpClient.get<DTO_SubscriptionInfo>(url);
    }
    return subscriptionObservable;
  }


  public loadAccessRights(): Observable<DTO_AccessRights>{
    let resultObservable: Observable<DTO_AccessRights>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadAccessRights', 'about to mock loadAccessRights request');
      resultObservable = this.httpClient.get<DTO_AccessRights>("api/accessRights");
    }else{
      let url = Config.api4Url_accessRights();
      resultObservable = this.httpClient.get<DTO_AccessRights>(url);
    }
    return resultObservable;
  }

}
