import { Injectable } from '@angular/core';
import { LoggerService } from '@service/loggers/logger.service';
import { LiteEvent } from '@util/lite.event';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private LOGGER_CLASSNAME = 'AppService';



  constructor(
    private loggerService: LoggerService,

    ) { }



  //HEIGHT PER ITEM height -> with observables
  private get _heightPerItem():number{
    return this._heightPerItemSubject.value;
  }
  private set _heightPerItem(value: number) {
    if (this._heightPerItem !== value) {
        this._heightPerItemSubject.next(value);
    }
  }
  get heightPerItem(): number {
    return this._heightPerItem;
  }
  private _heightPerItemSubject: BehaviorSubject<number> = new BehaviorSubject<number>(40);
  public heightPerItem$: Observable<number> = this._heightPerItemSubject.asObservable();

  //ICON height -> with observables
  private get _heightForIcon():number{
    return this._heightForIconSubject.value;
  }
  private set _heightForIcon(value: number) {
    if (this._heightForIcon !== value) {
        this._heightForIconSubject.next(value);
    }
  }
  get heightForIcon(): number {
    return this._heightForIcon;
  }
  private _heightForIconSubject: BehaviorSubject<number> = new BehaviorSubject<number>(18);
  public heightForIcon$: Observable<number> = this._heightForIconSubject.asObservable();


  //TOP BAR height -> with observables
  private get _heightForTopBar():number{
    return this._heightForTopBarSubject.value;
  }
  private set _heightForTopBar(value: number) {
    if (this._heightForTopBar !== value) {
        this._heightForTopBarSubject.next(value);
    }
  }
  get heightForTopBar(): number {
    return this._heightForTopBar;
  }
  private _heightForTopBarSubject: BehaviorSubject<number> = new BehaviorSubject<number>(40);
  public heightForTopBar$: Observable<number> = this._heightForTopBarSubject.asObservable();


  //BOTTOM BAR height -> with observables
  private get _heightForBottomBar():number{
    return this._heightForBottomBarSubject.value;
  }
  private set _heightForBottomBar(value: number) {
    if (this._heightForBottomBar !== value) {
        this._heightForBottomBarSubject.next(value);
    }
  }
  get heightForBottomBar(): number {
    return this._heightForBottomBar;
  }
  private _heightForBottomBarSubject: BehaviorSubject<number> = new BehaviorSubject<number>(40);
  public heightForBottomBar$: Observable<number> = this._heightForBottomBarSubject.asObservable();


  //HEADER height -> with observables
  private get _heightForHeader():number{
    return this._heightForHeaderSubject.value;
  }
  private set _heightForHeader(value: number) {
    if (this._heightForHeader !== value) {
        this._heightForHeaderSubject.next(value);
    }
  }
  get heightForHeader(): number {
    return this._heightForHeader;
  }
  private _heightForHeaderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(100);
  public heightForHeader$: Observable<number> = this._heightForHeaderSubject.asObservable();


  //WIDTH FOR MENU PANEL -> with observables
  private get _widthForMenuPanel():number{
    return this._widthForMenuPanelSubject.value;
  }
  private set _widthForMenuPanel(value: number) {
    if (this._widthForMenuPanel !== value) {
        this._widthForMenuPanelSubject.next(value);
    }
  }
  get widthForMenuPanel(): number {
    return this._widthForMenuPanel;
  }
  private _widthForMenuPanelSubject: BehaviorSubject<number> = new BehaviorSubject<number>(100);
  public widthForMenuPanel$: Observable<number> = this._widthForMenuPanelSubject.asObservable();



  //WIDTH FOR CONTENT PANELS -> with observables
  private get _widthForContentPanel():number{
    return this._widthForContentPanelSubject.value;
  }
  private set _widthForContentPanel(value: number) {
    if (this._widthForContentPanel !== value) {
        this._widthForContentPanelSubject.next(value);
    }
  }
  get widthForContentPanel(): number {
    return this._widthForContentPanel;
  }
  private _widthForContentPanelSubject: BehaviorSubject<number> = new BehaviorSubject<number>(400);
  public widthForContentPanel$: Observable<number> = this._widthForContentPanelSubject.asObservable();


  public widthForQueuePanel = 400;

  //available height -> with observables
  private get _availableHeightForPlayer():number{
    return this._availableHeightForPlayerSubject.value;
  }
  private set _availableHeightForPlayer(value: number) {
    if (this._availableHeightForPlayer !== value) {
        this._availableHeightForPlayerSubject.next(value);
    }
  }
  get availableHeightForPlayer(): number {
    return this._availableHeightForPlayer;
  }
  private _availableHeightForPlayerSubject: BehaviorSubject<number> = new BehaviorSubject<number>(18);
  public availableHeightForPlayer$: Observable<number> = this._availableHeightForPlayerSubject.asObservable();

  public adjustPlayerSizeToHeight(totalHeightForPlayer: number){
    if (this.availableHeightForPlayer != totalHeightForPlayer){
      this._heightForTopBar = this._heightForBottomBar = Math.max(30, Math.floor(totalHeightForPlayer * 0.04));
      const heightForContent = totalHeightForPlayer - (this.heightForTopBar + this.heightForBottomBar);

      const wantedHeightForHeaders = Math.max(95, Math.floor(totalHeightForPlayer * 0.14));
      const restHeight = heightForContent - wantedHeightForHeaders;

      this._heightPerItem = Math.max(30, Math.floor(restHeight / 16));
      this._heightForHeader = Math.max(80, heightForContent - (16 * this.heightPerItem));

      let heightForIconTemp = Math.floor((this.heightPerItem - 2) / 4) * 2;
      if (heightForIconTemp < 23){
        //when we are close to 18, keep it at the pixel perfect 18 size
        heightForIconTemp = 18;
      }
      this._heightForIcon = heightForIconTemp;


      this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustPlayerSize', 'adjusted to ' + totalHeightForPlayer + ': heightForTopBar = ' + this.heightForTopBar);

      this._availableHeightForPlayer = totalHeightForPlayer;
    }
  }

  //available height -> with observables
  private get _availableWidthForPlayer():number{
    return this._availableWidthForPlayerSubject.value;
  }
  private set _availableWidthForPlayer(value: number) {
    if (this._availableWidthForPlayer !== value) {
        this._availableWidthForPlayerSubject.next(value);
    }
  }
  get availableWidthForPlayer(): number {
    return this._availableWidthForPlayer;
  }
  private _availableWidthForPlayerSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public availableWidthForPlayer$: Observable<number> = this._availableWidthForPlayerSubject.asObservable();
  public adjustPlayerSizeToWidth(totalWidthForPlayer: number){
    if (this.availableWidthForPlayer != totalWidthForPlayer){
      //we draw 2 lines of 1 pixel between the panels
      let restWidth = totalWidthForPlayer - 2;

      //width for menu must be devidable by 3 (3 buttons at the top), and each button must be devidable by 2 (make icons pixel perfect):
      this._widthForMenuPanel = Math.round((restWidth * 0.175) / 6) * 6 + 2;
      restWidth = restWidth - this.widthForMenuPanel;
      this._widthForContentPanel = Math.floor(restWidth / 2);
      this.widthForQueuePanel = restWidth - this.widthForContentPanel;

      this._availableWidthForPlayer = totalWidthForPlayer;
    }


  }





  //a flag to indicate if doubles needs to be checked.
  //one flag is used for adding doubles to the queue and any playlist.
  public checkDoubles:boolean = true;


}
