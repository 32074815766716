<div #wrapper class="wrapper">

  <div class="contentWrapper">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <div class="title">
          {{ 'settings.blockedSongs.title' | translate }}
        </div>

        <button class="icon-button" [disabled]="((banlist$ | async).detailsLoading$ | async) || (banlist$ | async).loadError != null || (banlist$ | async).audioFiles.length == 0" (click)="onResetAll()">
          <tun-reset-icon-v5 class="icon icon-scaled-svg-button"></tun-reset-icon-v5>
        </button>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- normal content -->

        <ng-container *ngIf="banlist$ | async">

          <!-- Special banlist states indicator -->

          <div
          *ngIf="!((banlist$ | async).detailsLoading$ | async) && (banlist$ | async).loadError == null && (banlist$ | async).audioFiles.length == 0"
            [ngSwitch]="playlistState"
            class="empty-container"
          >
            <div class="feedback-hard">{{'settings.blockedSongs.empty' | translate }}</div>
            <div class="feedback-soft">{{'settings.blockedSongs.empty.message' | translate}}</div>
            <div class="feedback-soft">{{'settings.blockedSongs.empty.add.message' | translate}}</div>
          </div>


          <div
            *ngIf="(banlist$ | async).detailsLoading$ | async"
            [ngSwitch]="playlistState"
            class="feedback-container"
          >
            <tun-loader-v5 class="loader"></tun-loader-v5>
          </div>

          <div
            *ngIf="(banlist$ | async).loadError != null"
            [ngSwitch]="playlistState"
            class="feedback-container"
          >
            <div
              class="error-feedback-container"
            >
              <p>
                {{'calendar.contentPanel.load.error' | translate }}
              </p>
              <button (click)="retryClick()" class="retryButton">
                <p>
                  {{'general.tryAgain' | translate }}
                </p>
              </button>
            </div>

          </div>



          <!-- normal content -->
          <cdk-virtual-scroll-viewport
            *ngIf="!((banlist$ | async).detailsLoading$ | async) && (banlist$ | async).loadError == null && (banlist$ | async).audioFiles.length > 0"
            #scrollViewport
            id="banlistTrackList"
            class="trackTable"
            [itemSize]="virtualScrollingItemSize$ | async"
          >

            <ng-container
              *cdkVirtualFor="
              let track of (banlist$ | async).audioFiles;
              let i = index;
              "
            >
                <tun-settings-banned-track
                  class="trackItem"
                  [track]="track"
                  (reset)="onReset($event)"
                  [style.height.px]="trackItemSize$ | async"
                >
                </tun-settings-banned-track>

            </ng-container>



          </cdk-virtual-scroll-viewport>



        </ng-container>

</div>
