import { Component } from '@angular/core';

@Component({
  selector: 'tun-menu-music-channel-icon-v5',
  templateUrl: './menu-music-channel-icon-v5.component.html',
  styleUrls: ['./menu-music-channel-icon-v5.component.scss']
})
export class MenuMusicChannelIconV5Component {

}
