import { Component } from '@angular/core';

@Component({
  selector: 'tun-ai-stars-v5',
  templateUrl: './ai-stars-v5.component.html',
  styleUrls: ['./ai-stars-v5.component.scss']
})
export class AiStarsV5Component {

}
