<div #progressWrapper (tunResize)="onResize($event)" class="wrapper">

  <circle-progress
  maxPercent="100"
  [percent]="progressPercentage$ | async"
  [radius]="radius"
  [innerStrokeWidth]="strokeWidth"
  innerStrokeColor="#5E656E"
  [space]="-strokeWidth"
  outerStrokeGradient="true"
  [outerStrokeColor]="gradientColor1$ | async"
  [outerStrokeGradientStopColor]="gradientColor2$ | async"
  [outerStrokeWidth]="strokeWidth"

  renderOnClick="false"
  [animation]="false"
  animationDuration="0"
  [showTitle]="false"
  [showSubtitle]="false"
  [showUnits]="false"
></circle-progress>

</div>
