export const favorites = {
    "@type": "Playlist",
    "id": 10010,
    "title": "Favorieten",
    "description": "",
    "lastChanged": null,
    "audioFiles": [
        {
            "@type": "Song",
            "id": "410629",
            "duration": 245492,
            "group": "Satin Jackets",
            "location": "2-29/0004106292777a5.mp3",
            "title": "Athena",
            "origin": {                 "type": "musicCollection",                 "data": "10010"            },
            
            "composer": null,
            "bpm": 114,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 245047,
            "fadeBeginPosition": 243343,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": "411238",
            "duration": 198578,
            "group": "Milow & Matt Simons",
            "location": "2-38/00041123883f3ff.mp3",
            "title": "Lay Your Worry Down",
            "origin": {                 "type": "musicCollection",                 "data": "10010"            },
            
            "composer": null,
            "bpm": 76,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 102,
            "endAudioSignal": 198133,
            "fadeBeginPosition": 192466,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": "412972",
            "duration": 187904,
            "group": "John Gibbons & Nina Nesbitt",
            "location": "2-72/000412972b69877.mp3",
            "title": "A Spaceman Came Travelling",
            "origin": {                 "type": "musicCollection",                 "data": "10010"            },
            
            "composer": null,
            "bpm": 100,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 12,
            "endAudioSignal": 186459,
            "fadeBeginPosition": 177126,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        }
    ],
    "visible": true,
    "editable": true
}