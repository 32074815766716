export const musicCollection_8074 = {
    "@type": "Context",
    "id": 8074,
    "title": "Opgewekt",
    "description": "07",
    "lastChanged": null,
    "changeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Music Style",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Music Style",
            "value": [
                {
                    "value": "Country",
                    "selected": false
                },
                {
                    "value": "Electronic",
                    "selected": false
                },
                {
                    "value": "Folk",
                    "selected": false
                },
                {
                    "value": "Pop",
                    "selected": true
                },
                {
                    "value": "Rock",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1980,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1980,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 4,
            "maximum": 6,
            "step": 1,
            "lowerSelection": 5,
            "upperSelection": 6,
            "metadataDisplayMinimum": "4",
            "metadataDisplayMaximum": "6"
        }
    ],
    "defaultChangeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Music Style",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Music Style",
            "value": [
                {
                    "value": "Country",
                    "selected": false
                },
                {
                    "value": "Electronic",
                    "selected": false
                },
                {
                    "value": "Folk",
                    "selected": false
                },
                {
                    "value": "Pop",
                    "selected": true
                },
                {
                    "value": "Rock",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1980,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1980,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 4,
            "maximum": 6,
            "step": 1,
            "lowerSelection": 5,
            "upperSelection": 6,
            "metadataDisplayMinimum": "4",
            "metadataDisplayMaximum": "6"
        }
    ],
    "visible": true,
    "editable": false,
    "favourite": false,
    "selected": false,
    "mostPlayed": false,
    "defaultSelected": false
}