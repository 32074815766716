import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TunifyCheckBoxColor } from '@components/components-v5/checkbox-v5/checkbox-v5.component';
import { AudioFile } from '@model/audioFile';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { CheckBoxColor } from '@model/fieldModels/checkboxColor';

export interface DoubleAudioFilesPopupData{
  audioFilesToConfirm: AudioFile[];
  confirmedAudioFiles: AudioFile[];
  cancelledAudioFiles: AudioFile[];
  cancelled: boolean;
  stopAsking: boolean;
}

@Component({
  selector: 'tun-double-audio-files-popup',
  templateUrl: './double-audio-files-popup.component.html',
  styleUrls: ['./double-audio-files-popup.component.scss']
})
export class DoubleAudioFilesPopupComponent implements OnInit {

  public checkBoxColor = TunifyCheckBoxColor.BLACK;
  public static widthForPopup = "50vw";

  public audioFileToConfirm: AudioFile;
  public amountOfAudioFilesToConfirm: number = 0;

  constructor(public dialogRef: MatDialogRef<DoubleAudioFilesPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DoubleAudioFilesPopupData) {}

  private index = 0;
  ngOnInit() {
    if (this.data && this.data.audioFilesToConfirm != null){
      this.data.confirmedAudioFiles = [];
      this.data.cancelledAudioFiles = [];
      this.data.cancelled = false;
      this.data.stopAsking = false;
      this.loadAudioFileToConfirm();
    }else{
      this.dialogRef.close();
    }
  }



  public onYesClick(){
    this.data.confirmedAudioFiles.push(this.audioFileToConfirm);
    this.index++;
    this.loadAudioFileToConfirm();
  }

  public onNoClick(){
    this.data.cancelledAudioFiles.push(this.audioFileToConfirm);
    this.index++;
    this.loadAudioFileToConfirm();
  }

  public onYesToAllClick(){
    for (let i = this.index; i < this.data.audioFilesToConfirm.length; i++ ){
      this.data.confirmedAudioFiles.push(this.data.audioFilesToConfirm[i]);
    }
    this.dialogRef.close(this.data);
  }

  public onNoToAllClick(){
    for (let i = this.index; i < this.data.audioFilesToConfirm.length; i++ ){
      this.data.cancelledAudioFiles.push(this.data.audioFilesToConfirm[i]);
    }
    this.dialogRef.close(this.data);
  }

  private loadAudioFileToConfirm(){
    if (this.data.audioFilesToConfirm.length > this.index){
      this.audioFileToConfirm = this.data.audioFilesToConfirm[this.index];
      this.amountOfAudioFilesToConfirm = this.data.audioFilesToConfirm.length - this.index;
    }else{
      this.dialogRef.close(this.data);
    }
  }

  public onCancelClick(){
    this.data.cancelled = true;
    this.dialogRef.close(this.data);
  }

  public toggleStopAsking(){
    this.data.stopAsking = !this.data.stopAsking
  }





}
