import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { AudioFileProperty, formatTrackProperty, getPropertyLabel, isTrackPropertyAvailable, isTrackPropertySearchable, trackPropertyValue } from '@model/enums/audioFileProperty';
import { Playlist } from '@model/playlist';
import { TranslateService } from '@ngx-translate/core';
import { FavoriteBannedTrack, FavoriteBannedTrackService } from '@service/app-v5/favorite-banned-track.service';
import { TrackOverlayActionFeedbackType, TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { QueueService } from '@service/queue.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { waitingQueue } from '../../../services/data/mock/waitingQueue';
import { takeUntil } from 'rxjs/operators';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { SearchType, SuggestionsType } from '@service/api/search-api.service';
import { DTO_AccessToken } from '../../../services/api/zone-connection-api.service';
import { carouselAnimation, fadeAnimation, slideAnimation } from '@util/animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { SubscriptionsService } from '@service/subscriptions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_DURATION } from '@view/player-v5/player-v5.component';

export enum SONG_INFO_OVERLAY_VIEW {
  SONG_INFO_OVERLAY_VIEW_MAIN = "songInfoOverlayViewMain",
  SONG_INFO_OVERLAY_VIEW_PLAYLISTS = "songInfoOverlayViewPlaylists",
}

export const SongInfoOverlayViewLevel = new Map<SONG_INFO_OVERLAY_VIEW, number>([
  [SONG_INFO_OVERLAY_VIEW.SONG_INFO_OVERLAY_VIEW_MAIN, 0],
  [SONG_INFO_OVERLAY_VIEW.SONG_INFO_OVERLAY_VIEW_PLAYLISTS, 1],
]);

export enum TrackInfoContextMode{
  none = "none",
  favorite = "favorite",
  playlist = "playlist",
  queue = "queue",
  radioQueue = "radioQueue",
  player = "player",
  search = "search"
}

export class TrackInfoContext{
  trackInfoMode : TrackInfoContextMode
  playlist: Playlist

  constructor(trackInfoMode: TrackInfoContextMode, playlist?: Playlist){
    this.trackInfoMode = trackInfoMode;
    this.playlist = playlist;
  }
}

export class TrackInfoOverlayData{
  track: AudioFile
  context: TrackInfoContext

  constructor(track: AudioFile, context: TrackInfoContext){
    this.track = track;
    this.context = context;
  }
}



@Component({
  selector: 'tun-overlay-song-info',
  templateUrl: './overlay-song-info.component.html',
  styleUrls: ['./overlay-song-info.component.scss'],
  animations: [
    carouselAnimation,
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, { params: { duration: 10 } })
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        useAnimation(slideAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            startX: 0,
            endX: '100%'
          }
        })
      ])
    ]),
    trigger('slideOutLeft', [
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            startX: 0,
            endX: '-100%'
          }
        })
      ]),
      transition(':enter', [
        useAnimation(slideAnimation, {
          params: {
            startX: '-100%',
            endX: 0
          }
        })
      ])
    ])
  ]
})
export class OverlaySongInfoComponent{

  public CAROUSEL_DURATION = 300;
  public animationDirection: 'next' | 'prev' = 'next';

  @Input() trackInfoOverlayData: TrackInfoOverlayData;

  @Output() close = new EventEmitter<void>();

  public get showView(){
    return this.trackInfoOverlayData != null && this.trackInfoOverlayData.track != null;
  }

  public SONG_INFO_OVERLAY_VIEW = SONG_INFO_OVERLAY_VIEW;
  private _songInfoOverlayView$ = new BehaviorSubject<SONG_INFO_OVERLAY_VIEW>(SONG_INFO_OVERLAY_VIEW.SONG_INFO_OVERLAY_VIEW_MAIN);
  public set songInfoOverlayView(view: SONG_INFO_OVERLAY_VIEW){
    if (this._songInfoOverlayView$.value != view){
      if (SongInfoOverlayViewLevel.get(this._songInfoOverlayView$.value) < SongInfoOverlayViewLevel.get(view)){
        this.animationDirection = 'next';
      }else{
        this.animationDirection = 'prev';
      }
      this.changeDetectorRef.detectChanges();
      this._songInfoOverlayView$.next(view)
    }
  }
  public get songInfoOverlayView$(){
    return this._songInfoOverlayView$;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private subscriptionsService: SubscriptionsService,
    private snackBar: MatSnackBar,
    private translateService:TranslateService
  ) {

  }
  public onSelectPlaylist(){
    if (this.subscriptionsService.accessRights == null || this.subscriptionsService.accessRights.customPlaylists){
      this.songInfoOverlayView = SONG_INFO_OVERLAY_VIEW.SONG_INFO_OVERLAY_VIEW_PLAYLISTS
    }else{
      const snackBarRef = this.snackBar.open(this.translateService.instant("accessRight.snackbar.customPlaylists"), null, {
        duration: SNACKBAR_DURATION,
        panelClass: ['tunify-snackbar']
      });
      this.close.emit();
    }

  }

  public onBack(){
    this.songInfoOverlayView = SONG_INFO_OVERLAY_VIEW.SONG_INFO_OVERLAY_VIEW_MAIN
  }

  public onClose(){
    this.close.emit();
  }

}
