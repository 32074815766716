import { AudioFile } from '@model/audioFile';
import { AudioFileProperty } from '@model/enums/audioFileProperty';

export enum TrackAction {
    PLAY = "play",
    ADD_TO_QUEUE = "addToQueue",
    ADD_TO_EDITING_PLAYLIST = "addToEditingPlaylist",
    REMOVE = "remove",
    SEARCH_ON_TITLE = "searchOnTitle",
    SEARCH_ON_GROUP = "searchOnGroup",
    SEARCH_ON_BPM = "searchOnBpm",
    SEARCH_ON_DANCING_STYLE = "searchOnDancingStyle",
    SEARCH_ON_MOOD = "searchOnMood",
    SEARCH_ON_MUSIC_STYLE = "searchOnMusicStyle",
    SEARCH_ON_YEAR = "searchOnYear",
    SEARCH_SIMILAR = "searchSimilar"
}

export function isSearchAction(trackAction: TrackAction){
    return trackAction === TrackAction.SEARCH_ON_TITLE 
                || trackAction === TrackAction.SEARCH_ON_GROUP
                || trackAction === TrackAction.SEARCH_ON_BPM
                || trackAction === TrackAction.SEARCH_ON_DANCING_STYLE
                || trackAction === TrackAction.SEARCH_ON_MOOD
                || trackAction === TrackAction.SEARCH_ON_MUSIC_STYLE
                || trackAction === TrackAction.SEARCH_ON_YEAR
                || trackAction === TrackAction.SEARCH_SIMILAR; 
}

export function searchActionForTrackProperty(trackProperty:AudioFileProperty): TrackAction{
    if (trackProperty === AudioFileProperty.BPM){
        return TrackAction.SEARCH_ON_BPM;
    }else if (trackProperty === AudioFileProperty.DANCING_STYLE){
        return TrackAction.SEARCH_ON_DANCING_STYLE;
    }else if (trackProperty === AudioFileProperty.MOOD){
        return TrackAction.SEARCH_ON_MOOD;
    }else if (trackProperty === AudioFileProperty.MUSIC_STYLE){
        return TrackAction.SEARCH_ON_MUSIC_STYLE;
    }else if (trackProperty === AudioFileProperty.YEAR){
        return TrackAction.SEARCH_ON_YEAR;
    }
    return null;
}

export class TrackEvent {
    track: AudioFile;
    action: TrackAction;
    
    constructor(action: TrackAction, track: AudioFile){
        this.action = action;
        this.track = track;
    }

    public toString(): string {
        return 'perform ' + this.action + ' for ' + this.track;
      }
}   