import { Component } from '@angular/core';

@Component({
  selector: 'tun-thumb-up-active-icon-v5',
  templateUrl: './thumb-up-active-icon-v5.component.html',
  styleUrls: ['./thumb-up-active-icon-v5.component.scss']
})
export class ThumbUpActiveIconV5Component {

}
