<!-- This wrapper shows the background without any animations on it-->
<div class="backGroundWrapper">

  <!--
  We need the fadeIn animation on the container
  to prevent the slideOutLeft animation from running at the initial render.
  -->
<div class="contentWrapper" @fadeIn>

  <!-- Keep the groups on screen to store the scrollPosition? -->
  <tun-settings-home-panel
    *ngIf="(settingsView$ | async) == SETTINGS_VIEW.SETTINGS_VIEW_HOME"
    [@carousel]="{
      value: animationDirection,
      params: {
        duration: CAROUSEL_DURATION
      }
    }"
    class="fullContainer"
    (openSettingsView)="onOpenSettingsView($event)">
  </tun-settings-home-panel>

  <tun-settings-language-panel
  *ngIf="(settingsView$ | async) == SETTINGS_VIEW.SETTINGS_VIEW_LANGUAGE"
  [@carousel]="{
    value: animationDirection,
    params: {
      duration: CAROUSEL_DURATION
    }
  }"
  class="fullContainer"
  (back)="onBack()">

  </tun-settings-language-panel>

  <tun-settings-banlist-panel
  *ngIf="(settingsView$ | async) == SETTINGS_VIEW.SETTINGS_VIEW_BANLIST"
  [@carousel]="{
    value: animationDirection,
    params: {
      duration: CAROUSEL_DURATION
    }
  }"
  class="fullContainer"
  (back)="onBack()">

  </tun-settings-banlist-panel>

  <tun-settings-music-rights-panel
  *ngIf="(settingsView$ | async) == SETTINGS_VIEW.SETTINGS_VIEW_MUSICRIGHTS"
  [@carousel]="{
    value: animationDirection,
    params: {
      duration: CAROUSEL_DURATION
    }
  }"
  class="fullContainer"
  (back)="onBack()">

  </tun-settings-music-rights-panel>

  <tun-settings-song-request-panel
  *ngIf="(settingsView$ | async) == SETTINGS_VIEW.SETTINGS_VIEW_SONGREQUEST"
  [@carousel]="{
    value: animationDirection,
    params: {
      duration: CAROUSEL_DURATION
    }
  }"
  class="fullContainer"
  (back)="onBack()">

  </tun-settings-song-request-panel>

  <tun-settings-desktop-app-panel
  *ngIf="(settingsView$ | async) == SETTINGS_VIEW.SETTINGS_VIEW_DESKTOP_APP"
  [@carousel]="{
    value: animationDirection,
    params: {
      duration: CAROUSEL_DURATION
    }
  }"
  class="fullContainer"
  (back)="onBack()">

  </tun-settings-desktop-app-panel>

</div>


</div>
