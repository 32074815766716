export class AudioTagWrapper {

  audioTagWrapperId = 0;

  // audio Element when we play with an audio tag
  audioElement: HTMLAudioElement;
  audioNode: MediaElementAudioSourceNode;

  // audio buffer when we buffer ourselfs
  // audioBufferSourceNode: AudioBufferSourceNode;

  audioGainNode: GainNode;

  public adjustVolume(volume) {
    if (this.audioGainNode) {
      this.audioGainNode.gain.value = volume;
    }else{
      this.audioElement.volume = volume;
    }
  }

  public get volume():number {
    if (this.audioGainNode) {
      return this.audioGainNode.gain.value;
    }

    return this.audioElement.volume;
  }

  //for logging purpose
  public toString(): string {
    return 'AudioTagWrapper ' + this.audioTagWrapperId;
  }


}
