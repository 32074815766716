import { Injectable, OnDestroy } from '@angular/core';
import { ZoneConnection } from '@model/zoneConnection';
import { ZoneConnectionApiService } from '@service/api/zone-connection-api.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { LoggerService } from '../loggers/logger.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DTO_ZoneCode, ZoneCodeApiService } from '@service/api/zone-code-api.service';

@Injectable({
  providedIn: 'root'
})
export class ZoneConnectionService implements OnDestroy {

  private LOGGER_CLASSNAME = 'ZoneConnectionService';

  //events when zone data (zone info or an access token) for a specific zone is changed
  private zoneDataChangedSource = new Subject<ZoneConnection>();
  zoneDataChanged$ = this.zoneDataChangedSource.asObservable();
  private emitZoneDataChanged(zoneConnection: ZoneConnection) {
    this.zoneDataChangedSource.next(zoneConnection);
  }

  //events when a zone is no longer valid -> so the zoneConnectionsService can remive the connection
  private zoneConnectionBecomesInvalidSource = new Subject<ZoneConnection>();
  zoneConnectionBecomesInvalid$ = this.zoneConnectionBecomesInvalidSource.asObservable();
  private emitZoneConnectionBecomesInvalid(zoneConnection: ZoneConnection) {
    this.zoneConnectionBecomesInvalidSource.next(zoneConnection);
  }

  constructor(
    private zoneConnectionApiService: ZoneConnectionApiService,
    private loggerService: LoggerService,
    private zoneCodeApiService: ZoneCodeApiService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
  }

  public loadAccessToken(zoneConnection: ZoneConnection){
    if (!zoneConnection.loadingAccessToken){
      zoneConnection.loadingAccessToken = true;
      this.zoneConnectionApiService.loadAccessToken(zoneConnection.zoneId, zoneConnection.refreshToken)
      .pipe(
        takeUntil(
          this.destroyed$
        ),
        finalize( () => {
          zoneConnection.loadingAccessToken = false;
          this.emitZoneDataChanged(zoneConnection);

          if (zoneConnection.needToReloadZoneInfo){
            this.loadZoneInfo(zoneConnection);
          }
          if (zoneConnection.needToReloadZoneCode){
            this.loadZoneCode(zoneConnection);
          }
        })
      )
      .subscribe(
        (data) => {
          zoneConnection.valid = true;
          zoneConnection.accessTokenRefreshFails = 0;
          zoneConnection.accessToken = data.accessToken;
          //We know the refreshtoken is valid when a new accessToken could be generated
          zoneConnection.validCheckedDate = new Date();
          zoneConnection.emitLoadAccessTokenDone(true);
        },
        (error : unknown) => {
          zoneConnection.accessTokenRefreshFails++;
          zoneConnection.emitLoadAccessTokenDone(false);
          if (error instanceof HttpErrorResponse && error.status === 403){
            //The refreshToken is not valid for this zoneId -> forget this connection
            zoneConnection.valid = false;
            this.emitZoneConnectionBecomesInvalid(zoneConnection);
          }
          this.loggerService.error(this.LOGGER_CLASSNAME, "loadAccessToken error", "error " + error);
        }
      );
    }
  }

  public loadZoneInfo(zoneConnection: ZoneConnection){
    if (!zoneConnection.loadingZoneInfo){
      if (zoneConnection.accessToken){
        zoneConnection.loadingZoneInfo = true;
      this.zoneConnectionApiService.loadZoneInfo(zoneConnection.zoneId, zoneConnection.accessToken)
      .pipe(
        takeUntil(
          this.destroyed$
        ),
        finalize( () => {
          zoneConnection.loadingZoneInfo = false;
          this.emitZoneDataChanged(zoneConnection);
        })
      )
      .subscribe(
        (data) => {
          zoneConnection.name = data.name;
          zoneConnection.locationName = data.location;
          zoneConnection.appFamId = data.appFamilyId;
          zoneConnection.externalZoneId = data.externalZoneId;
          zoneConnection.emitLoadZoneInfoDone(true);
        },
        (error : unknown) => {
          //todo -> keep track of error?
          zoneConnection.emitLoadZoneInfoDone(false);
          this.loggerService.error(this.LOGGER_CLASSNAME, "loadZoneInfo error", "error " + error);
        }
      );
      }else{
        zoneConnection.needToReloadZoneInfo = true;
      }

    }
  }

  public loadZoneCode(zoneConnection: ZoneConnection){

    if (!zoneConnection.loadingZoneCode){
      if (zoneConnection.accessToken){
        zoneConnection.loadingZoneCode = true;
        zoneConnection.loadingZoneCodeError = false;

        this.zoneCodeApiService.loadZoneCodeForZoneId(zoneConnection.zoneId, zoneConnection.accessToken)
        .pipe(
          takeUntil(
            this.destroyed$
          ),
          finalize( () => {
            zoneConnection.loadingZoneCode = false;
          })
        )
        .subscribe(
          (data) => {
            if (data.code){
              zoneConnection.zoneCode = data.code;
            }else{
              this.loggerService.error(this.LOGGER_CLASSNAME, "loadZoneCode error", "no code in response");
              zoneConnection.loadingZoneCodeError = true;
            }
          },
          (error : unknown) => {
            //todo -> keep track of error?
            this.loggerService.error(this.LOGGER_CLASSNAME, "loadZoneCode error", "error " + error);
            zoneConnection.loadingZoneCodeError= true;
          }
        );
      }else{
        zoneConnection.needToReloadZoneCode = true;
        this.loadAccessToken(zoneConnection);
      }
    }
  }

}
