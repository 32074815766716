import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ResizedEvent } from '@components/resize/resize.directive';
import { Calendar } from '@model/calendar';
import { MusicChannel } from '@model/musicChannel';
import { AppV5StateService, TunifyV5Tab } from '@service/app-v5/app-v5-state.service';
import { PlayingMusicSelectionService } from '@service/app-v5/playing-music-selection.service';
import { LastPlayedItemsService } from '@service/last-played-items.service';
import { RecommendationsService } from '@service/recommendations.service';
import { Subject, combineLatest, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MusicSelectionState } from '../../../../../../services/app-v5/playing-music-selection.service';

@Component({
  selector: 'tun-recommendations-view',
  templateUrl: './recommendations-view.component.html',
  styleUrls: ['./recommendations-view.component.scss']
})
export class RecommendationsViewComponent {

  @ViewChild('container', { static: true }) container: ElementRef;

  /**
   * Background image properties
   */
  public get imageUrl$(){
    return this.playingMusicSelectionService.lastRealplayingMusicSelection$
    .pipe(
      map(
        (musicSelection => {
          if (musicSelection && musicSelection.imageUrl != MusicSelectionState.PLAYINFO_DEFAULT){
            return musicSelection.imageUrl;
          }
          return null;
        })
      )
    )
  }


  /**
   * Last played items properties
   */
  public get lastPlayedItemsLoading$(){
    return combineLatest([this.lastPlayedItemsService.loading$, this.lastPlayedItems$])
    .pipe(
      map(([loading, lastPlayedItems]) => {
        return loading && lastPlayedItems == null;
      }
      )
    )
  }
  public get lastPlayedItemsLoadingError$(){
    return combineLatest([this.lastPlayedItemsService.loadingError$, this.lastPlayedItems$])
    .pipe(
      map(([error, lastPlayedItems]) => {
        return error && lastPlayedItems == null;
      }
      )
    )
  }


  public get showNoLastPlayedItems$(){
    return this.lastPlayedItems$
    .pipe(
      map((lastPlayedItems) => {
        return lastPlayedItems != null && lastPlayedItems.length == 0;
      }
      )
    )
  }

  public get showLastPlayedItems$(){
    return this.lastPlayedItems$
    .pipe(
      map((lastPlayedItems) => {
        return lastPlayedItems != null && lastPlayedItems.length > 0;
      }
      )
    )
  }

  public get lastPlayedItems$(){
    return this.lastPlayedItemsService.lastPlayedItems$;
  }

  public isMusicChannel(lastPlayedItem){
    return lastPlayedItem.matchedItem instanceof MusicChannel
  }

  public isCalendar(lastPlayedItem){
    return lastPlayedItem.matchedItem instanceof Calendar;
  }


  /**
   * Recommendations properties
   */
  public get recommendationsLoading$(){
    return this.recommendationsService.loading$;
  }
  public get recommendationsLoadingError$(){
    return this.recommendationsService.loadingError$;
  }

  public get showNoRecommendations$(){
    return combineLatest([this.recommendationsLoading$, this.recommendationsLoadingError$, this.recommendedMusicChannels$])
    .pipe(
      map(([loading, error, items]) => {
        return !loading && !error && items != null && items.length == 0;
      }
      )
    )
  }

  public get showRecommendations$(){
    return combineLatest([this.recommendationsLoading$, this.recommendationsLoadingError$, this.recommendedMusicChannels$])
    .pipe(
      map(([loading, error, items]) => {
        return !loading && !error && items != null && items.length > 0;
      }
      )
    )
  }

  public get recommendedMusicChannels$(){
    return this.recommendationsService.recommendedMusicChannels$;
  }


  public gridClassName = '';

  constructor(
    private lastPlayedItemsService: LastPlayedItemsService,
    private recommendationsService: RecommendationsService,
    private appV5StateService: AppV5StateService,
    private playingMusicSelectionService: PlayingMusicSelectionService
  ) {

  }

  private destroyed$ = new Subject<void>();
  OnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onRetryLastPlayed(){
    this.lastPlayedItemsService.loadLastUsedItems();
  }

  onRetryRecommendations(){
    this.recommendationsService.loadRecommendedMusicChannels();
  }


  public onResizeContainer(event: ResizedEvent){
    this.adjustToSize();
  }

  public adjustToSize(){
    if (this.container){
      const viewRect = this.container.nativeElement.getBoundingClientRect();

      if (viewRect.width > 0){
        const width = viewRect.width;
        const minWidthPerItem = 12.5 * this.appV5StateService.pixelsFor1Rem;
        if (width > 8 * minWidthPerItem){
          this.gridClassName = "grid-8row";
        }else  if (width > 7 * minWidthPerItem){
          this.gridClassName = "grid-7row";
        }else  if (width > 6 * minWidthPerItem){
          this.gridClassName = "grid-6row";
        }else  if (width > 5 * minWidthPerItem){
          this.gridClassName = "grid-5row";
        }else  if (width > 4 * minWidthPerItem){
          this.gridClassName = "grid-4row";
        }else  if (width > 2.75 * minWidthPerItem){
          this.gridClassName = "grid-3row";
        }else if (width > 1.5 * minWidthPerItem){
          this.gridClassName = "grid-2row";
        }

      }
    }
  }

  public onShowDetailsForMusicChannel(musicChannel: MusicChannel){
    this.appV5StateService.showMusicChannelInTunifyBlue(musicChannel);

    /*
    this.appV5StateService.selectTunifyTab(TunifyV5Tab.MUSIC_CHANNEL);
    //select the music channel in blue
    timer(0).subscribe(() => {
      this.appV5StateService.showMusicChannelInTunifyBlue(musicChannel);
    });
    */

  }

  public onShowDetailsForCalendar(calendar: Calendar){
    this.appV5StateService.showCalendarInTunifyGreen(calendar);
  }
}
