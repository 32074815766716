import { Component } from '@angular/core';

@Component({
  selector: 'tun-search-result-view',
  templateUrl: './search-result-view.component.html',
  styleUrls: ['./search-result-view.component.scss']
})
export class SearchResultViewComponent {

}
