import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@service/authentication.service';
import { LoggerService } from '@service/loggers/logger.service';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { Config } from '@service/config';
import { AudioFile } from '@model/audioFile';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { filter, takeUntil, finalize, map } from 'rxjs/operators';

export enum DTO_QueueType{
  Waiting = "waiting",
  Radio = "radio",
  NextRadio = "nextRadio"
}

export function getQueueTypeForString(type: string): DTO_QueueType{
  if (DTO_QueueType.Waiting.toLowerCase() == type.toLowerCase()){
    return DTO_QueueType.Waiting;
  }else if (DTO_QueueType.Radio.toLowerCase() == type.toLowerCase()){
    return DTO_QueueType.Radio;
  }else if (DTO_QueueType.NextRadio.toLowerCase() == type.toLowerCase()){
    return DTO_QueueType.NextRadio;
  }
  return null;
}

export class DTO_Queue {
  timestamp: number;
  id: number;
  zoneId: number;
  queueType: DTO_QueueType;
  tracks: AudioFile[];
  validForSeconds: number;

  constructor(queue?: DTO_Queue){
      if (queue){
          this.timestamp = queue.timestamp;
          this.id = queue.timestamp;
          this.zoneId = queue.zoneId;
          this.validForSeconds = queue.validForSeconds;
          if (queue.tracks){
              this.tracks = [];
              queue.tracks.forEach(audioFile => this.tracks.push(createClassObjectForAudioFile(audioFile)));
          }
      }
  }
}

class DTO_Queue_Response {
  queue: DTO_Queue;
}

@Injectable({
  providedIn: 'root'
})
export class QueueApiService {

  private LOGGER_CLASSNAME = 'QueueApiService';

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService) { }

  //Helper functions - also used from the service
  public isQueueMocked(queueType: DTO_QueueType){
    if (queueType === DTO_QueueType.Waiting){
      return environment.mockQueue;
    }else if (queueType === DTO_QueueType.Radio || queueType === DTO_QueueType.NextRadio){
      return environment.mockRadioQueue;
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "isQueueMocked", "queueType not recognized: " + queueType);
      return true;
    }

  }

  public loadQueue(queueType: DTO_QueueType): Observable<DTO_Queue>{

    //create the http get request
    let queueObservable:Observable<DTO_Queue>;
    if (this.isQueueMocked(queueType) ){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadQueue', 'about to mock loadQueue request for type ' + queueType);
      queueObservable = this.httpClient.get<DTO_Queue>("api/" + queueType + "Queue");
    }else{
      let url = Config.api4Url_queue(this.authenticationService.zoneId, queueType);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "loadQueue", "going to load queue from " + url);
      queueObservable = this.httpClient.get<DTO_Queue_Response>(url).pipe(
        map(data => data.queue),
        takeUntil(this.authenticationService.loggedIn$.pipe(filter(val => val === false))) //cancel the request when the user is no longer logged in
      );
    }

    return queueObservable;
  }




}
