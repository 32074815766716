import { Component } from '@angular/core';

@Component({
  selector: 'tun-add-to-queue-v5',
  templateUrl: './add-to-queue-v5.component.html',
  styleUrls: ['./add-to-queue-v5.component.scss']
})
export class AddToQueueV5Component {

}
