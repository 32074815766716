<div class="wrapper">
  <div class="title">{{ groupName }}</div>
  <hr class="horizontalLine"/>

  <div class="contentContainer">

    <div class="calendarContainer">
      <ng-container *ngIf="calendars != null">
        <ng-container *ngFor="let calendar of calendars">
          <tun-calendar-view
            class="calendar"
            [calendar]="calendar"
            (showDetails)="onShowDetails($event)"
            >
          </tun-calendar-view>
        </ng-container>
      </ng-container>

      <div *ngIf="showAddButton" class="calendar">
        <div class="addButtonContainer">
          <button
            class="addButton "
            (click)="onAdd()"
            >
              <div class="addButtonIconContainer">
                <tun-plus-icon-v5 class="icon icon-scaled-svg-button addIcon"></tun-plus-icon-v5>
              </div>

          </button>
        </div>

      </div>

    </div>

    <tun-access-right-overlay-view
      *ngIf="lockOverlayMessage != null"
      class="overlayContainer"
      [message]="lockOverlayMessage"
      [smallMode]="true"
    >

    </tun-access-right-overlay-view>

  </div>

</div>
