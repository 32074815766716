import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Playlist } from '@model/playlist';
import { Subject } from 'rxjs';

@Component({
  selector: 'tun-overlay-select-playlist',
  templateUrl: './overlay-select-playlist.component.html',
  styleUrls: ['./overlay-select-playlist.component.scss']
})
export class OverlaySelectPlaylistComponent {

  @Input() confirmButtonTranslateableText : string = null;

  @Output() closeWithPlaylist = new EventEmitter<Playlist>();

  constructor(
  ) {
  }

  public onClose(){
    this.closeWithPlaylist.emit();
  }

  public onCloseWithPlaylist(playlist: Playlist){
    this.closeWithPlaylist.emit(playlist);
  }

}
