export const musicCollection_8005 = {
    "@type": "Context",
    "id": 8005,
    "title": "Gezellig",
    "description": "06",
    "lastChanged": null,
    "changeableParameter": [
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1980,
            "maximum": 2018,
            "step": 1,
            "lowerSelection": 1980,
            "upperSelection": 2017,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Energy",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Energy",
            "minimum": 3,
            "maximum": 5,
            "step": 1,
            "lowerSelection": 3,
            "upperSelection": 4,
            "metadataDisplayMinimum": "3",
            "metadataDisplayMaximum": "5"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 5,
            "maximum": 6,
            "step": 1,
            "lowerSelection": 5,
            "upperSelection": 6,
            "metadataDisplayMinimum": "5",
            "metadataDisplayMaximum": "6"
        }
    ],
    "defaultChangeableParameter": [
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1980,
            "maximum": 2018,
            "step": 1,
            "lowerSelection": 1980,
            "upperSelection": 2018,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Energy",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Energy",
            "minimum": 3,
            "maximum": 5,
            "step": 1,
            "lowerSelection": 3,
            "upperSelection": 4,
            "metadataDisplayMinimum": "3",
            "metadataDisplayMaximum": "5"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 5,
            "maximum": 6,
            "step": 1,
            "lowerSelection": 5,
            "upperSelection": 6,
            "metadataDisplayMinimum": "5",
            "metadataDisplayMaximum": "6"
        }
    ],
    "visible": true,
    "editable": false,
    "favourite": false,
    "selected": true,
    "mostPlayed": false,
    "defaultSelected": false
};