export const musicCollection_8551 = {
	"@type": "Context",
	"id": 8551,
	"title": "Folk",
	"description": "05",
	"lastChanged": null,
	"editable": false,
	"visible": true,
	"favourite": false,
	"selected": false,
	"mostPlayed": false,
	"defaultSelected": true,
	"changeableParameter": [{
		"@type": "ChangeableSelectionParameter",
		"name": "Muziekstijl",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Muziekstijl",
		"value": [{
			"value": "Country",
			"selected": true
		}, {
			"value": "Folk",
			"selected": true
		}, {
			"value": "Pop",
			"selected": true
		}, {
			"value": "Rock",
			"selected": true
		}]
	}, {
		"@type": "ChangeableSelectionParameter",
		"name": "Taal",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Taal",
		"value": [{
			"value": "Engels",
			"selected": true
		}, {
			"value": "Instrumentaal",
			"selected": true
		}, {
			"value": "Nederlands",
			"selected": true
		}, {
			"value": "Andere",
			"selected": false
		}]
	}, {
		"@type": "ChangeableIntervalParameter",
		"name": "Jaar",
		"metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
		"metadataDisplayName": "",
		"minimum": 1963.0,
		"maximum": 2018.0,
		"step": 1.0,
		"lowerSelection": 1963.0,
		"upperSelection": 2018.0,
		"metadataDisplayMinimum": "",
		"metadataDisplayMaximum": ""
	}, {
		"@type": "ChangeableIntervalParameter",
		"name": "Bekendheid",
		"metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
		"metadataDisplayName": "Bekend",
		"minimum": 3.0,
		"maximum": 6.0,
		"step": 1.0,
		"lowerSelection": 5.0,
		"upperSelection": 6.0,
		"metadataDisplayMinimum": "3",
		"metadataDisplayMaximum": "6"
	}, {
		"@type": "ChangeableSelectionParameter",
		"name": "Sfeer",
		"metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
		"metadataDisplayName": "Sfeer",
		"value": [{
			"value": "Rustig",
			"selected": false
		}, {
			"value": "Gezellig",
			"selected": true
		}, {
			"value": "Opgewekt",
			"selected": true
		}]
	}],
	"defaultChangeableParameter": [{
		"@type": "ChangeableSelectionParameter",
		"name": "Muziekstijl",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Muziekstijl",
		"value": [{
			"value": "Country",
			"selected": true
		}, {
			"value": "Folk",
			"selected": true
		}, {
			"value": "Pop",
			"selected": true
		}, {
			"value": "Rock",
			"selected": true
		}]
	}, {
		"@type": "ChangeableSelectionParameter",
		"name": "Taal",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Taal",
		"value": [{
			"value": "Engels",
			"selected": true
		}, {
			"value": "Instrumentaal",
			"selected": true
		}, {
			"value": "Nederlands",
			"selected": true
		}, {
			"value": "Andere",
			"selected": true
		}]
	}, {
		"@type": "ChangeableIntervalParameter",
		"name": "Jaar",
		"metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
		"metadataDisplayName": "",
		"minimum": 1963.0,
		"maximum": 2018.0,
		"step": 1.0,
		"lowerSelection": 1963.0,
		"upperSelection": 2018.0,
		"metadataDisplayMinimum": "",
		"metadataDisplayMaximum": ""
	}, {
		"@type": "ChangeableIntervalParameter",
		"name": "Bekendheid",
		"metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
		"metadataDisplayName": "Bekend",
		"minimum": 3.0,
		"maximum": 6.0,
		"step": 1.0,
		"lowerSelection": 3.0,
		"upperSelection": 6.0,
		"metadataDisplayMinimum": "3",
		"metadataDisplayMaximum": "6"
	}, {
		"@type": "ChangeableSelectionParameter",
		"name": "Sfeer",
		"metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
		"metadataDisplayName": "Sfeer",
		"value": [{
			"value": "Rustig",
			"selected": true
		}, {
			"value": "Gezellig",
			"selected": true
		}, {
			"value": "Opgewekt",
			"selected": true
		}]
	}]
}