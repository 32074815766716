import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './loader/loader.component';
import { ScrollOverflowDirective } from './scroll-overflow.directive';
import { SongSortOnClickDirective } from './song-sort-on-click.directive';
import { ScrollBarVisibleDirective } from './scroll-bar-visible.directive';
import { ThemedDirective } from './themed.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ScrollOverflowDirective,
    SongSortOnClickDirective,
    LoaderComponent,
    ThemedDirective,
    ScrollBarVisibleDirective
  ],
  imports: [CommonModule, MatProgressSpinnerModule, TranslateModule],
  exports: [
    ScrollOverflowDirective,
    SongSortOnClickDirective,
    LoaderComponent,
    ThemedDirective,
    ScrollBarVisibleDirective,
  ],
})
export class UtilsModule {}
