export class DTO_MusicCollection {
    id: number;
    title: string | null;
    description: string | null;
    '@type': string;
    timestamp: number;

    selected: boolean | null;
    //lastChanged: Date;
  }
