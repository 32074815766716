import { Injectable, Renderer2, NgZone, OnDestroy } from '@angular/core';
import { AudioFileWithPlayInfo } from './audioFileWithPlayInfo';
import { AudiofileUrlService } from '@service/audiofile-url.service';
import { AudioTagService } from './audio.tag.service';
import { LoggerService } from '@service/loggers/logger.service';
import { AudioFile } from '@model/audioFile';
import { Subject } from 'rxjs';
import { QueueService } from '@service/queue.service';
import { PlayTokenService,  } from '@service/play-token.service';
import { PlayableAudio } from '../../model/audioFile';
import { BrandMessage } from '../../model/brandMessage';
import { BrandMessageBufferService } from './brand-message-buffer.service';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { takeUntil } from 'rxjs/operators';
import { ApplicationMode } from '@service/authentication/zone-connections.service';

@Injectable({
  providedIn: 'root'
})

/**
 * This service is responsible for buffering audioFiles.
 *
 * The service is only active when our application playmode is player
 *
 * While a user is making changes to what is playing, the amount of audioFiles that will be buffered is kept low.
 * Once the user stops making changes we will increase the amount of buffered audioFiles.
 * This prevents that we will load many audioFiles that will not be played.
 *
 * AudioFiles are buffered in the order that they will be played.
 * Buffering of an audioFile can only start once all audioFiles that have to be played before that audioFile are fully loaded.
 * This way, the bandwidth is optimally used to load audioFiles that have to be played first.
 *
 * Once an audioFile is buffered, we keep it in memory as long as it is probable that it will be played in the near future.
 *
 */

export class BufferService implements OnDestroy{

  private LOGGER_CLASSNAME = 'BufferService';

  //this array defines how many audioFiles we will before after the amount of idle seconds. idle = no manual changes to what should be playing.
  // - item in array = amount of idle seconds
  // - position of the item in the array = amount of aufioFilesToBuffer
  private static readonly IDLE_PERIOD_LENGTH_BEFORE_BUFFER = [10, 60, 180, 240, 300];

  constructor(private loggerService: LoggerService,
              private playTokenService: PlayTokenService,
              private queueService:QueueService,
              private zoneConnectionsService: ZoneConnectionsService,
              private audioFileUrlService: AudiofileUrlService,
              private audioTagService: AudioTagService,
              private _ngZone: NgZone,
              private brandMessageBufferService: BrandMessageBufferService) {

    this.zoneConnectionsService.activeZoneConnection$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (value) =>{
        if (value){
          //when a user logs in, reset the buffering timestamps
          this.reportManualChange();
        }else{
          this.syncAmountAudioFilesToBuffer();
        }
      }
    );

    this.queueService.queue$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (queue) => {
        this.invalidateBuffer();
      }
    );

    this.queueService.radioQueue$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (radioQueue) => {
        this.invalidateBuffer();
      }
    );

    this.queueService.nextRadioQueue$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (nextRadioQueue) => {
        this.invalidateBuffer();
      }
    );

    this.zoneConnectionsService.applicationMode$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      applicationMode => {
        this.reportManualChange();
      }
    );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  // we need a renderer to add AUDIO tags to the DOM and to register the event listeners
  private renderer: Renderer2;
  bootstrapRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }


  //we keep track of the last requested audioFileWithPlayInfo to make predictions. We should know
  // - if the audio is fully loaded
  // - how long this audio will play (to predict changes on the radioQueue)
  private lastAudioFileWithPlayInfo:AudioFileWithPlayInfo = null;

  public retrievePlayableAudioWithPlayInfo(playableAudio: PlayableAudio): AudioFileWithPlayInfo{

    let audioFileWithPlayInfo = null;

    //if it is an BrandsMessage -> look into seperate buffer
    if (playableAudio instanceof BrandMessage){
      audioFileWithPlayInfo = this.brandMessageBufferService.retrievePlayableAudioWithPlayInfo(playableAudio);
    }else if (playableAudio instanceof AudioFile){
      let index = 0;

      //look into buffer
      while (audioFileWithPlayInfo === null && index < this.bufferedAudioFilesWithPlayInfos.length){
        if (this.bufferedAudioFilesWithPlayInfos[index].audioFile.id === playableAudio.id){
          audioFileWithPlayInfo = this.bufferedAudioFilesWithPlayInfos[index];
          audioFileWithPlayInfo.audioFile = playableAudio;
        }else{
          index++;
        }
      }

      //remove from buffer
      if (index < this.bufferedAudioFilesWithPlayInfos.length){
        this.bufferedAudioFilesWithPlayInfos.splice(index, 1);
      }

      //look into reverved buffer
      if (audioFileWithPlayInfo === null){
        index = 0;
        while (audioFileWithPlayInfo === null && index < this.reserveBufferedAudioFilesWithPlayInfos.length){
          if (this.reserveBufferedAudioFilesWithPlayInfos[index].audioFile.id === playableAudio.id){
            audioFileWithPlayInfo = this.reserveBufferedAudioFilesWithPlayInfos[index];
            audioFileWithPlayInfo.audioFile = playableAudio;
          }else{
            index++;
          }
        }

        //remove from reserve buffer
        if (index < this.reserveBufferedAudioFilesWithPlayInfos.length){
          this.reserveBufferedAudioFilesWithPlayInfos.splice(index, 1);
        }
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "retrievePlayableAudioWithPlayInfo", "this type of playableAudio is not implemented: " + playableAudio);
    }

    if (audioFileWithPlayInfo === null){
      audioFileWithPlayInfo = this.createAudioFileWithPlayInfo();
      audioFileWithPlayInfo.audioFile = playableAudio;
    }

    this.lastAudioFileWithPlayInfo = audioFileWithPlayInfo;
    return audioFileWithPlayInfo;
  }

  private createAudioFileWithPlayInfo(): AudioFileWithPlayInfo {
    const audioFileWithPlayInfo = new AudioFileWithPlayInfo(this.audioFileUrlService, this.renderer, this.audioTagService, this.loggerService, this._ngZone);
    audioFileWithPlayInfo.audioFullyLoaded.on(this.audioFileFullyLoaded);
    return audioFileWithPlayInfo;
  }

  public cleanupAudioFileWithPlayInfo(audioFileWithPlayInfo: AudioFileWithPlayInfo) {
    if (this.lastAudioFileWithPlayInfo === audioFileWithPlayInfo){
      this.lastAudioFileWithPlayInfo = null;
    }
    if (audioFileWithPlayInfo.audioFile instanceof BrandMessage){
      this.brandMessageBufferService.doneUsingPlayableAudioWithPlayInfo(audioFileWithPlayInfo);
    }else{
      audioFileWithPlayInfo.cleanUp();
      audioFileWithPlayInfo.audioFullyLoaded.off(this.audioFileFullyLoaded);
    }

  }

  private audioFileFullyLoaded = () => {
    this.loggerService.debug(this.LOGGER_CLASSNAME, "audioFileFullyLoaded", "audioFile fully loaded -> checking buffer");
    this.bufferAudioFiles();
  }


  /**
   * last change timestamp
   */
  private manualChangeTimeStamp = new Date().getTime();
  public reportManualChange(){
    this.manualChangeTimeStamp = new Date().getTime();
    this.syncAmountAudioFilesToBuffer();
  }

  /**
   * Determine the amount of songs to buffer
   *
   * Only buffer when a user is logged in
   */
  private delayedCallToSyncAmountAudioFilesToBuffer = undefined;
  private syncAmountAudioFilesToBuffer():void{

    if (this.delayedCallToSyncAmountAudioFilesToBuffer != undefined){
      clearTimeout(this.delayedCallToSyncAmountAudioFilesToBuffer);
      this.delayedCallToSyncAmountAudioFilesToBuffer = undefined;
    }

    var amountToBuffer = 0;

    if (this.zoneConnectionsService.activeZoneConnection != null && this.zoneConnectionsService.applicationMode === ApplicationMode.playerMode){

      //we could change the used array for specific users if they want less buffering
      let idlePeriodArray = BufferService.IDLE_PERIOD_LENGTH_BEFORE_BUFFER;
      let idlePeriodInSeconds = this.idlePeriodInSeconds;

      while (amountToBuffer < idlePeriodArray.length && idlePeriodArray[amountToBuffer] <= idlePeriodInSeconds){
        amountToBuffer++;
      }


      if (amountToBuffer < idlePeriodArray.length){
        var timeForNextChange:number = (idlePeriodArray[amountToBuffer] - idlePeriodInSeconds) + 0.1;
        this.loggerService.debug(this.LOGGER_CLASSNAME, "syncAmountAudioFilesToBuffer", "timeForNextChange: " + timeForNextChange);
        this.delayedCallToSyncAmountAudioFilesToBuffer = setTimeout(this.callToSyncAmountAudioFilesToBuffer, timeForNextChange * 1000);
      }
    }

    this.amountAudioFilesToBuffer = amountToBuffer;
  }

  private callToSyncAmountAudioFilesToBuffer = () => {
    this.syncAmountAudioFilesToBuffer();
  }

  private get idlePeriodInSeconds():number{
    return  (new Date().getTime() - this.manualChangeTimeStamp) / 1000;
  }

  private _amountAudioFilesToBuffer = 0;
	private set amountAudioFilesToBuffer(value: number){
		if (this._amountAudioFilesToBuffer != value){
      this._amountAudioFilesToBuffer = value;
      this.loggerService.debug(this.LOGGER_CLASSNAME, "amountAudioFilesToBuffer", "amount of audioFiles to buffer changed to " + this.amountAudioFilesToBuffer);
		  this.invalidateBuffer();
		}
	}
	private get amountAudioFilesToBuffer():number{
		return this._amountAudioFilesToBuffer;
  }



  /**
   * Buffering
   *
   * bufferedAudioFilesWithPlayInfos -> the buffered audioFiles, in the order we expect them to be played
   * reserveBufferedAudioFilesWithPlayInfos -> the audioFiles that were buffered but for some reason were removed from the buffered list
   */

  private bufferIsValid = false;

  private invalidateBuffer(){
    this.bufferIsValid = false;
    this.cancelDelayedCall();
    this.delayedCallToBufferAudioFiles = setTimeout(this.bufferAudioFiles, 200);
  }

  private delayedCallToBufferAudioFiles = undefined;
  private cancelDelayedCall(){
    if (this.delayedCallToBufferAudioFiles != undefined){
      clearTimeout(this.delayedCallToBufferAudioFiles);
      this.delayedCallToBufferAudioFiles = undefined;
    }
  }

  private bufferedAudioFilesWithPlayInfos: AudioFileWithPlayInfo[] = [];
  private reserveBufferedAudioFilesWithPlayInfos: AudioFileWithPlayInfo[] = [];
  private bufferAudioFiles = () => {
    this.cancelDelayedCall();
    this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'into bufferQueuedAudioFiles. Amount to buffer: ' + this.amountAudioFilesToBuffer);

    let alreadyLoadedAudioFiles: AudioFile[] = [];
    let alreadyBufferedSeconds = 0;
    let previousBufferedAudioFilesFullyLoaded = false;

    //check if there is an audioFile loaded into the player. Only buffer if it is fully loaded
    if (this.lastAudioFileWithPlayInfo != null) {
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'lastAudioFileWithPlayInfo loaded: ' + this.lastAudioFileWithPlayInfo.isFullyLoaded);
      if (this.lastAudioFileWithPlayInfo.audioFile instanceof AudioFile){
        //only keep track of already loaded audioFiles
        alreadyLoadedAudioFiles.push(this.lastAudioFileWithPlayInfo.audioFile);
      }else{
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'lastAudioFileWithPlayInfo is not an audioFile: ' + this.lastAudioFileWithPlayInfo.audioFile);
      }
      previousBufferedAudioFilesFullyLoaded = this.lastAudioFileWithPlayInfo.isFullyLoaded;
      alreadyBufferedSeconds = this.lastAudioFileWithPlayInfo.remainingSeconds();
    } else {
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'no lastAudioFileWithPlayInfo -> do not buffer (wait until something is loading into the player)');
    }

    let audioFileToBuffer: AudioFile = null;
    let newBufferedAudioFilesWithPlayInfos: AudioFileWithPlayInfo[] = [];
    let nextAudioFileAvailable = true;
    let addingAudioFilesToBuffer = true;

    while (nextAudioFileAvailable && addingAudioFilesToBuffer && previousBufferedAudioFilesFullyLoaded) {
      audioFileToBuffer = this.queueService.getNextAudioFile(alreadyLoadedAudioFiles, alreadyBufferedSeconds);
      if (audioFileToBuffer) {
        // find match in previous bufferedQueue, but the download should be ably to complete in time
        let index = 0;
        while (index < this.bufferedAudioFilesWithPlayInfos.length
                && audioFileToBuffer.id != this.bufferedAudioFilesWithPlayInfos[index].audioFile.id) {
          index++;
        }
        if (index < this.bufferedAudioFilesWithPlayInfos.length) {

          const bufferedAudioFileWithPlayInfo = this.bufferedAudioFilesWithPlayInfos[index];
          //update the audioFile object
          bufferedAudioFileWithPlayInfo.audioFile = audioFileToBuffer;
          newBufferedAudioFilesWithPlayInfos.push(bufferedAudioFileWithPlayInfo);
          previousBufferedAudioFilesFullyLoaded = bufferedAudioFileWithPlayInfo.isFullyLoaded;
          alreadyBufferedSeconds += bufferedAudioFileWithPlayInfo.remainingSeconds();
          this.bufferedAudioFilesWithPlayInfos.splice(index, 1);

          this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'match found in bufferedAudioFilesWithPlayInfos: ' + bufferedAudioFileWithPlayInfo.audioFile + ' -> fully loaded: ' + bufferedAudioFileWithPlayInfo.isFullyLoaded);
        } else {

          index = 0;
          while (index < this.reserveBufferedAudioFilesWithPlayInfos.length
                && audioFileToBuffer.id != this.reserveBufferedAudioFilesWithPlayInfos[index].audioFile.id) {
            index++;
          }
          if (index < this.reserveBufferedAudioFilesWithPlayInfos.length) {

            const bufferedAudioFileWithPlayInfo = this.reserveBufferedAudioFilesWithPlayInfos[index];

            this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'match found in reserveBufferedAudioFilesWithPlayInfos: ' + bufferedAudioFileWithPlayInfo.audioFile + ' -> fully loaded: ' + bufferedAudioFileWithPlayInfo.isFullyLoaded);

            //update the audioFile object
            bufferedAudioFileWithPlayInfo.audioFile = audioFileToBuffer;
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'values after changing the audioFile: ' + bufferedAudioFileWithPlayInfo.audioFile + ' -> fully loaded: ' + bufferedAudioFileWithPlayInfo.isFullyLoaded);

            newBufferedAudioFilesWithPlayInfos.push(bufferedAudioFileWithPlayInfo);
            previousBufferedAudioFilesFullyLoaded = bufferedAudioFileWithPlayInfo.isFullyLoaded;
            alreadyBufferedSeconds += bufferedAudioFileWithPlayInfo.remainingSeconds();
            this.reserveBufferedAudioFilesWithPlayInfos.splice(index, 1);
          }else{
            if (newBufferedAudioFilesWithPlayInfos.length < this.amountAudioFilesToBuffer) {
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'adding audioFile to buffer: ' + audioFileToBuffer);
              const audioFileWithPlayInfoToBuffer = this.createAudioFileWithPlayInfo();
              audioFileWithPlayInfoToBuffer.audioFile = audioFileToBuffer;
              newBufferedAudioFilesWithPlayInfos.push(audioFileWithPlayInfoToBuffer);
              previousBufferedAudioFilesFullyLoaded = false;
              audioFileWithPlayInfoToBuffer.startPrepare();
            } else {
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'addingAudioFilesToBuffer is false');
              addingAudioFilesToBuffer = false;
            }
          }
        }

        alreadyLoadedAudioFiles.push(audioFileToBuffer);


      } else {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'nextAudioFileAvailable not available');
        nextAudioFileAvailable = false;
      }

    }

    this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'After adding to buffer. nextAudioFileAvailable: ' + nextAudioFileAvailable + ' addingAudioFilesToBuffer: ' + addingAudioFilesToBuffer + ' previousBufferedAudioFilesFullyLoaded: ' + previousBufferedAudioFilesFullyLoaded);


    // clean up old reserveBuffered audioFiles that are no longer likely to be played from the queue
    let newReserveBufferedAudioFilesWithPlayInfos = [];
    this.reserveBufferedAudioFilesWithPlayInfos.forEach((audioFileWithPlayInfo) => {
      //we are only going to keep suspended downoads if it is very close to beeing played
      let trackHasToBePlayedInAmount = 20;
      if (audioFileWithPlayInfo.suspended){
        trackHasToBePlayedInAmount = 5
      }
      if (
        audioFileWithPlayInfo.audioFile instanceof AudioFile
        && this.queueService.audioFileLikelyToBePlayed(audioFileWithPlayInfo.audioFile, trackHasToBePlayedInAmount)
      ){
        newReserveBufferedAudioFilesWithPlayInfos.push(audioFileWithPlayInfo);
      }else{
        if (!(audioFileWithPlayInfo.audioFile instanceof AudioFile)){
          this.loggerService.error(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'reserveBufferedAudioFilesWithPlayInfos contains a playableAudio that is not an AudioFile: ' + audioFileWithPlayInfo.audioFile );
        }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'throwing away buffered audioFile from reserveBufferedAudioFilesWithPlayInfos : ' + audioFileWithPlayInfo.audioFile );
        }
        //clean it up, references will be removed
        this.cleanupAudioFileWithPlayInfo(audioFileWithPlayInfo);
      }
    });

    this.reserveBufferedAudioFilesWithPlayInfos = newReserveBufferedAudioFilesWithPlayInfos;

    const oldBufferedAudioFileWithPlayInfos = this.bufferedAudioFilesWithPlayInfos;
    this.bufferedAudioFilesWithPlayInfos = newBufferedAudioFilesWithPlayInfos;

    this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'amount buffered: ' + this.bufferedAudioFilesWithPlayInfos.length);

    // keep reference to buffered audioFiles if they are somewhere in the queue or radio queue (and are fully loaded)
    oldBufferedAudioFileWithPlayInfos.forEach((audioFileWithPlayInfo) => {
      //we are only going to keep suspended downoads if it is very close to beeing played
      let trackHasToBePlayedInAmount = 20;
      if (audioFileWithPlayInfo.suspended){
        trackHasToBePlayedInAmount = 5
      }
      if (audioFileWithPlayInfo.audioFile instanceof AudioFile && this.queueService.audioFileLikelyToBePlayed(audioFileWithPlayInfo.audioFile, trackHasToBePlayedInAmount)){
        this.reserveBufferedAudioFilesWithPlayInfos.push(audioFileWithPlayInfo);
      }else{

        if (!(audioFileWithPlayInfo.audioFile instanceof AudioFile)){
          this.loggerService.error(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'oldBufferedAudioFileWithPlayInfos contains a playableAudio that is not an AudioFile: ' + audioFileWithPlayInfo.audioFile );
        }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'throwing away buffered audioFile from oldBufferedAudioFileWithPlayInfos : ' + audioFileWithPlayInfo.audioFile );
        }
        this.cleanupAudioFileWithPlayInfo(audioFileWithPlayInfo);
      }
    });

    this.loggerService.debug(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'amount reserve buffered: ' + this.reserveBufferedAudioFilesWithPlayInfos.length);

    if (this.reserveBufferedAudioFilesWithPlayInfos.length > 5){
      this.loggerService.error(this.LOGGER_CLASSNAME, 'bufferQueuedAudioFiles', 'amount reverve buffered is very high: ' + this.reserveBufferedAudioFilesWithPlayInfos.length);
    }

    this.bufferIsValid = true;
  }

}
