<div class="wrapper">

  <div class="contentWrapper">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <div class="title">
          {{ 'settings.musicRights.title' | translate }}
        </div>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- normal content -->
    <div class="scrollContainer">
      <div class="real-content-container">
        <div class="music-rights-info">
          {{'settings.musicRights.info' | translate}}
        </div>

        <div class="sabam-id">
          {{sabamId$ | async}}
        </div>
      </div>
    </div>


</div>
