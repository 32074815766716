import { Component } from '@angular/core';

@Component({
  selector: 'tun-mail20px-icon-v5',
  templateUrl: './mail20px-icon-v5.component.html',
  styleUrls: ['./mail20px-icon-v5.component.scss']
})
export class Mail20pxIconV5Component {

}
