import { Day } from '@model/day';

export function getStartMinutesFromCronString(cronString:String):number{
    var cronsStringSplit:string[] = cronString.split(" ");
    if (cronsStringSplit.length >= 2){
        return +cronsStringSplit[1];
    }
    return 0;
}

export function getStartHourFromCronString(cronString:String):number{
    var cronsStringSplit:string[] = cronString.split(" ");
    if (cronsStringSplit.length >= 3){
        return +cronsStringSplit[2];
    }
    return 0;
}

export function getDayFromCronString(cronString:String):Day{
    var cronsStringSplit:string[] = cronString.split(" ");
    if (cronsStringSplit.length >= 6){
        return Day.getDayForDayCronString(cronsStringSplit[5]);
    }
    return null;
}


export function getCronStringForDayAndTime(day:Day, startHour:Number, startMinutes:Number):string{
    var dayCronString:string = day.cronstring;
    
    //replace hour & mintues
    var cronsStringSplit:string[] = dayCronString.split(" ");
    cronsStringSplit[2] = startHour.toString();
    cronsStringSplit[1] = startMinutes.toString();
    
    return cronsStringSplit.join(" ");
} 