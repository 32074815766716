import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AudioFile } from '@model/audioFile';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@service/authentication.service';
import { LoggerService } from '@service/loggers/logger.service';
import { Config } from '@service/config';

@Injectable({
  providedIn: 'root'
})
export class TrackApiService {

  private LOGGER_CLASSNAME = 'TrackApiService';

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService
  ){ 

  }

  public searchTrack(trackId: number): Observable<AudioFile>{
    let resultObservable: Observable<AudioFile>;

    if (environment.mockSearch){
      let url = "api/search-track";
      this.loggerService.info(this.LOGGER_CLASSNAME, 'searchTrack', 'about to mock searchTrack ' + trackId + ' going to use ' + url);
      resultObservable = this.httpClient.get<AudioFile>(url);
    }else{
      let url = Config.api4Url_searchTrack(this.authenticationService.zoneId, trackId);
      resultObservable = this.httpClient.get<AudioFile>(url);
      
    }
    return resultObservable;
  }
}
