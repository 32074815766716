import { ChangeableParameter } from '@model/changeableParameter';
import { ChangeableIntervalParameter } from '@model/changeableIntervalParameter';
import { ChangeableSelectionParameter } from '@model/changeableSelectionParameter';

export function createClassObjectForChangeableParameter(changeableParameter:ChangeableParameter) { 
    if (changeableParameter["@type"] == "ChangeableIntervalParameter"){
        return new ChangeableIntervalParameter(changeableParameter as ChangeableIntervalParameter);
    }else if (changeableParameter["@type"] == "ChangeableSelectionParameter"){
        return new ChangeableSelectionParameter(changeableParameter as ChangeableSelectionParameter);
    }else{
        console.error("ChangeableParameter type not recognized");
        return new ChangeableParameter(changeableParameter);
    }
 }