export const search1 =  [{
    "@type": "Song",
    "id": 413208,
    "duration": 202373,
    "group": "Satin Jackets ft Niya Wells",
    "location": "2-8/0004132089e027a.mp3",
    "title": "Lost In Japan",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 105,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 201261,
    "fadeBeginPosition": 198373,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 405333,
    "duration": 193762,
    "group": "Dean Lewis",
    "location": "2-33/0004053337511e1.mp3",
    "title": "Be Alright",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 63,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Free",
    "startAudioSignal": 881,
    "endAudioSignal": 192317,
    "fadeBeginPosition": 190650,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412699,
    "duration": 178256,
    "group": "Rita Ora",
    "location": "2-99/0004126994a3d10.mp3",
    "title": "Only Want You",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 82,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 176107,
    "fadeBeginPosition": 163107,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 411309,
    "duration": 202308,
    "group": "Clean Bandit, Marina & Luis Fonsi",
    "location": "2-9/000411309720aba.mp3",
    "title": "Baby",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 117,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 201863,
    "fadeBeginPosition": 200196,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412119,
    "duration": 180197,
    "group": "Lewis Capaldi",
    "location": "2-19/0004121195d910f.mp3",
    "title": "Someone You Loved",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 110,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 6,
    "endAudioSignal": 176085,
    "fadeBeginPosition": 172752,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 413600,
    "duration": 268280,
    "group": "Jack Savoretti",
    "location": "2-0/000413600c27f79.mp3",
    "title": "Candlelight",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 82,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 266279,
    "fadeBeginPosition": 255835,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412963,
    "duration": 214647,
    "group": "Miley Cyrus & Mark Ronson",
    "location": "2-63/000412963e135de.mp3",
    "title": "Nothing Breaks Like A Heart",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 114,
    "musicFeel": "Dance",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 3,
    "endAudioSignal": 214202,
    "fadeBeginPosition": 209535,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Dance",
    "rhythmDescription": "Free Dance",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 408856,
    "duration": 186869,
    "group": "Rita Ora",
    "location": "2-56/00040885690cc6b.mp3",
    "title": "Let You Love Me",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 96,
    "musicFeel": "Soul",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 185646,
    "fadeBeginPosition": 183091,
    "musicStyle": "Urban",
    "subStyle": "R & B",
    "genreDescription": "R & B",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412971,
    "duration": 223368,
    "group": "Loïc Nottet",
    "location": "2-71/000412971a6a182.mp3",
    "title": "On Fire",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 70,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Free",
    "startAudioSignal": 1,
    "endAudioSignal": 221923,
    "fadeBeginPosition": 213923,
    "musicStyle": "Electronic",
    "subStyle": "Basic",
    "genreDescription": "Electronic Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 410675,
    "duration": 186412,
    "group": "Zara Larsson",
    "location": "2-75/000410675e85281.mp3",
    "title": "Ruin My Life",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 125,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 7,
    "endAudioSignal": 184300,
    "fadeBeginPosition": 183967,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 413592,
    "duration": 185870,
    "group": "Avril Lavigne",
    "location": "2-92/0004135923b5f90.mp3",
    "title": "Tell Me It's Over",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 92,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Slow",
    "startAudioSignal": 11,
    "endAudioSignal": 181092,
    "fadeBeginPosition": 178425,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Slow",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 411314,
    "duration": 191957,
    "group": "Martin Garrix",
    "location": "2-14/000411314b564e3.mp3",
    "title": "Dreamer",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 105,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 189845,
    "fadeBeginPosition": 173734,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 403782,
    "duration": 147775,
    "group": "Rosalía",
    "location": "2-82/00040378271af75.mp3",
    "title": "Malamente",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 98,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 146663,
    "fadeBeginPosition": 144663,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412453,
    "duration": 164234,
    "group": "Sultan & Ned Shepard",
    "location": "2-53/000412453c6ba18.mp3",
    "title": "Where Are You?",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 124,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 163122,
    "fadeBeginPosition": 162122,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free Dance",
    "tunifyTipValue": "Tunify Tip"
}
]