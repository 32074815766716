<div class="header">
  <div class="header-left-content">
    <button
      *ngIf="showBackButton"
      class="back-icon popup-button"
      (click)="onBackClick()"
    >
      <tun-back-arrow-icon></tun-back-arrow-icon>
    </button>

    <ng-content></ng-content>
  </div>

  <div class="header-right-content">
    <!-- Use the mat-dialog-close directive if we are in a dialog, else a close button which emits the event -->
    <button mat-dialog-close class="mat-close-icon popup-button" *ngIf="isInMatDialog; else normaleCloseBtn">
      <tun-close-icon></tun-close-icon>
    </button>
  </div>
</div>

<ng-template #normaleCloseBtn>
  <button class="close-icon popup-button" (click)="onCloseClick()">
    <tun-close-icon></tun-close-icon>
  </button>
</ng-template>

