import { Injectable } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { SearchType, SearchApiService, SuggestionsType } from '@service/api/search-api.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { LoggerService } from '../loggers/logger.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { AutocompletionObject, AutocompletionType } from './autocompletion.service';
import { TrackAction, TrackEvent } from '@model/events/trackEvent';
import { ZoneConfigurationService } from './zone-configuration.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AudioFileProperty, formatTrackProperty } from '@model/enums/audioFileProperty';
import { createClassObjectForAudioFile } from './util/audioFileUtil';
import { SubscriptionsService } from './subscriptions.service';

export class SearchData {
  searchType: SearchType; // will always be filled in

  // depending on the search type, some will be filled in
  track: AudioFile;
  text: string;
  autocompletion: AutocompletionObject;
  suggestionsTrack: AudioFile;
  suggestionsType: SuggestionsType;

  toString(): string {
    if (this.searchType != null) {
      return this.searchType + ' for ' + this.track;
    } else if (this.suggestionsType != null) {
      return this.suggestionsType + ' for ' + this.suggestionsTrack.toString();
    }
    return 'No search';
  }
}

export function translatedDescriptionForSearchData(translateService: TranslateService, searchData: SearchData): string{
  let title = "";
  if (searchData != null){
    switch (searchData.searchType) {
      case SearchType.AUTOCOMPLETION_SEARCH_ON_GROUP:
      case SearchType.AUTOCOMPLETION_SEARCH_ON_TITLE:
        title = `${translateService.instant('search.feedback.title.searchFor')} ${searchData.autocompletion?searchData.autocompletion.autocompletionText:""}`;
        break;
      case SearchType.SEARCH_BY_VALUE:
        title = `${translateService.instant('search.feedback.title.searchFor')} ${searchData.text}`;
        break;
      case SearchType.SEARCH_ON_TITLE:
        title = `${translateService.instant('search.feedback.title.searchFor')} ${searchData.track?searchData.track.title:""}`;
        break;
      case SearchType.SEARCH_ON_GROUP:
        title = `${translateService.instant('search.feedback.title.searchFor')} ${searchData.track?searchData.track.group:""}`;
        break;
      case SearchType.SEARCH_SUGGESTIONS:
        // take the first suggestion track (we currently only use 1 track)
        const track = searchData.suggestionsTrack;
        let property: AudioFileProperty;
        switch (searchData.suggestionsType) {
          case SuggestionsType.SUGGEST_ON_BPM:
            property = AudioFileProperty.BPM;
            break;
          case SuggestionsType.SUGGEST_ON_DANCING_STYLE:
            property = AudioFileProperty.DANCING_STYLE;
            break;
          case SuggestionsType.SUGGEST_ON_MOOD:
            property = AudioFileProperty.MOOD;
            break;
          case SuggestionsType.SUGGEST_ON_STYLE:
            property = AudioFileProperty.MUSIC_STYLE;
            break;
          case SuggestionsType.SUGGEST_ON_YEAR:
            property = AudioFileProperty.YEAR;
            break;
          case SuggestionsType.SUGGEST_SIMILAR:
            property = null;
            break;
        }
        if (property != null) {
          title = `${translateService.instant('search.feedback.title.searchFor')} ${track?formatTrackProperty(track, property):""}`;
        } else {
          title = `${translateService.instant('search.feedback.title.similarTo')} ${track?track.title:""}`;
        }

        break;
    }
    return title;
  }


}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private LOGGER_CLASSNAME = 'SearchService';

  /**
   * Searching property
   */
  private get _searching(): boolean {
    return this._searchingSubject.value;
  }
  private set _searching(value: boolean) {
    if (this._searching !== value) {
        this._searchingSubject.next(value);
    }
  }
  get searching(): boolean {
    return this._searching;
  }
  private _searchingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public searching$: Observable<boolean> = this._searchingSubject.asObservable();


  /**
   * Search error
   */
  private get _searchError(): string {
    return this._searchErrorSubject.value;
  }
  private set _searchError(value: string) {
    if (this._searchError !== value) {
        this._searchErrorSubject.next(value);
    }
  }
  get searchError(): string {
    return this._searchError;
  }
  private _searchErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public searchError$: Observable<string> = this._searchErrorSubject.asObservable();


  /**
   * Search result
   */
  private get _searchResult(): AudioFile[] {
    return this._searchResultSubject.value;
  }
  private set _searchResult(value: AudioFile[]) {
    if (this._searchResult !== value) {
        this._searchResultSubject.next(value);
    }
  }
  get searchResult(): AudioFile[] {
    return this._searchResult;
  }
  private _searchResultSubject: BehaviorSubject<AudioFile[]> = new BehaviorSubject<AudioFile[]>(null);
  public searchResult$: Observable<AudioFile[]> = this._searchResultSubject.asObservable();

  /**
   * Last search input
   */

  private get _lastSearch(): SearchData {
    return this._lastSearchSubject.value;
  }
  private set _lastSearch(value: SearchData) {
    if (this._lastSearch !== value) {
        this._lastSearchSubject.next(value);
    }
  }
  get lastSearch(): SearchData {
    return this._lastSearch;
  }
  private _lastSearchSubject: BehaviorSubject<SearchData> = new BehaviorSubject<SearchData>(null);
  public lastSearch$: Observable<SearchData> = this._lastSearchSubject.asObservable();




  // event subject that is triggered when a search starts (usefull for opening the view)
  // we internally use this observable to cancel any previous http request for a search
  private searchStartsSource = new Subject<SearchData>();
  public searchStarts$: Observable<SearchData> = this.searchStartsSource.asObservable();
  emitSearchStarts(searchData: SearchData) {
    this.searchStartsSource.next(searchData);
  }

  //event emitter to close the search view - this can be triggered from anywhere in our app
  private closeSearchSource = new Subject<null>();
  public closeSearch$: Observable<SearchData> = this.closeSearchSource.asObservable();
  closeSearch() {
    this.closeSearchSource.next(null);
  }








  constructor(
    private loggerService: LoggerService,
    private searchApiService: SearchApiService,
    private subscriptionsService: SubscriptionsService
  ) {

  }


  public handleSearchOnTrack(trackEvent: TrackEvent) {

    if (this.subscriptionsService.accessRights != null && this.subscriptionsService.accessRights.search){
      if (environment.enableSearch){
        if (trackEvent.action === TrackAction.SEARCH_ON_TITLE) {
          this.searchOnTrack(SearchType.SEARCH_ON_TITLE, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_GROUP) {
          this.searchOnTrack(SearchType.SEARCH_ON_GROUP, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_BPM) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_BPM, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_DANCING_STYLE) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_DANCING_STYLE, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_MUSIC_STYLE) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_STYLE, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_MOOD) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_MOOD, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_YEAR) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_YEAR, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_SIMILAR) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_SIMILAR, trackEvent.track);
        }
      }else{
        this.loggerService.debug(this.LOGGER_CLASSNAME, "handleSearchOnTrack", "App has no access to search (" + trackEvent.action + ")");
      }
    }else{
      //zone has no access to search -> no feedback?
      this.loggerService.debug(this.LOGGER_CLASSNAME, "handleSearchOnTrack", "Zone has no access to search (" + trackEvent.action + ")");
    }


  }

  public searchOnTrack(searchType: SearchType, track: AudioFile) {
    this.clearLastSearch();

    const searchData = new SearchData();
    searchData.track = track;
    searchData.searchType = searchType;

    const searchObservable: Observable<AudioFile[]> = this.searchApiService.searchOnTrack(searchType, track);

    this.handleSearchObservable(searchData, searchObservable);
  }

  public searchOnText(text: string) {
    this.clearLastSearch();

    const searchData = new SearchData();
    searchData.text = text;
    searchData.searchType = SearchType.SEARCH_BY_VALUE;


    const searchObservable: Observable<AudioFile[]> = this.searchApiService.searchOnText(text);

    this.handleSearchObservable(searchData, searchObservable);
    }

    public searchOnAutocompletion(autocompletionObject: AutocompletionObject) {
      this.clearLastSearch();

      const searchData = new SearchData();
      searchData.autocompletion = autocompletionObject;
      if (autocompletionObject.type === AutocompletionType.Song) {
        searchData.searchType = SearchType.AUTOCOMPLETION_SEARCH_ON_TITLE;
      } else {
        searchData.searchType = SearchType.AUTOCOMPLETION_SEARCH_ON_GROUP;
      }


      const searchObservable: Observable<AudioFile[]> = this.searchApiService.searchOnAutocompletion(autocompletionObject);

      this.handleSearchObservable(searchData, searchObservable);
      }

    // When the user closes the search view and a search is still running -> we can just cancel it
    /*public cancelCurrentSearch(){

    }*/

    public suggestionsForTrack(suggestionsType: SuggestionsType, tracks: AudioFile) {
      this.clearLastSearch();

      const searchData = new SearchData();
      searchData.searchType = SearchType.SEARCH_SUGGESTIONS;
      searchData.suggestionsTrack = tracks;
      searchData.suggestionsType = suggestionsType;

      // fixed similarity of 5
      const searchObservable: Observable<AudioFile[]> = this.searchApiService.searchSuggestions(suggestionsType, tracks, 5);

      this.handleSearchObservable(searchData, searchObservable);

    }

    private handleSearchObservable(searchData: SearchData, searchObservable: Observable<AudioFile[]>) {
      if (searchObservable) {

        this._lastSearch = searchData;
        this.emitSearchStarts(searchData);

        //set loading after emitting search starts -> finalize will be triggered and we need to keep the searching state for the latest request
        this._searching = true;

        searchObservable.pipe(
          finalize(() => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleSearchObservable', 'finalize for ' + searchData.toString() + ' loading: ' + this.searching);
            if (!this.searching) {
              this.loggerService.error(this.LOGGER_CLASSNAME, 'handleSearchObservable', 'finalize for ' + searchData.toString()  + ' but we are not loading!');
            }
            this._searching = false;
          }),
          takeUntil(this.searchStarts$)
        ).subscribe(
            (data) => {
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleSearchObservable', 'data received for ' + searchData.toString() + ': ' + data);

              const realObjects = data.map(res => createClassObjectForAudioFile(res));
              this._searchResult = realObjects;
            }
          ,
          error => {
            const errMsg = (error.message) ? error.message :
                  error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            this.loggerService.error(this.LOGGER_CLASSNAME, 'handleSearchObservable', 'err: ' + errMsg);
            this._searchError = 'GeneralError';
          });
        }
    }


  public clearLastSearch() {
    this._lastSearch = null;
    this._searchResult = null;
    this._searching = false;
    this._searchError = null;
  }
}
