import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface ClearBanlistPopupComponentData{
  confirm: boolean;
}

@Component({
  selector: 'tun-clear-banlist-popup',
  templateUrl: './clear-banlist-popup.component.html',
  styleUrls: ['./clear-banlist-popup.component.scss']
})
export class ClearBanlistPopupComponent {

  public static widthForPopup = "min(20rem,25vw)";

  constructor(
    public dialogRef: MatDialogRef<ClearBanlistPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClearBanlistPopupComponentData,
    private trans: TranslateService
  ) {

  }

  public onYesClick(){
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }

  public onNoClick(){
    this.data.confirm = false;
    this.dialogRef.close(this.data);
  }
}
