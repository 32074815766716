export const musicCollection_8143 = {
    "@type": "Context",
    "id": 8143,
    "title": "Disco",
    "description": "12",
    "lastChanged": null,
    "changeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Music Style",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Music Style",
            "value": [
                {
                    "value": "Pop",
                    "selected": true
                },
                {
                    "value": "Soul",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1971,
            "maximum": 2018,
            "step": 1,
            "lowerSelection": 1971,
            "upperSelection": 2018,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Danceability",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Danceability",
            "minimum": 3,
            "maximum": 5,
            "step": 1,
            "lowerSelection": 3,
            "upperSelection": 5,
            "metadataDisplayMinimum": "3",
            "metadataDisplayMaximum": "5"
        }
    ],
    "defaultChangeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Music Style",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Music Style",
            "value": [
                {
                    "value": "Pop",
                    "selected": true
                },
                {
                    "value": "Soul",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1971,
            "maximum": 2018,
            "step": 1,
            "lowerSelection": 1971,
            "upperSelection": 2018,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Danceability",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Danceability",
            "minimum": 3,
            "maximum": 5,
            "step": 1,
            "lowerSelection": 3,
            "upperSelection": 5,
            "metadataDisplayMinimum": "3",
            "metadataDisplayMaximum": "5"
        }
    ],
    "visible": true,
    "editable": false,
    "favourite": false,
    "selected": false,
    "mostPlayed": false,
    "defaultSelected": false
}