import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { fadeAnimation, slideAnimation, viewPositionAnimation } from '@util/animations';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export class MusicChannelData{
  musicChannel: MusicChannel;
  musicCollection: MusicCollection;
}

@Component({
  selector: 'tun-overlay-select-music-channel',
  templateUrl: './overlay-select-music-channel.component.html',
  styleUrls: ['./overlay-select-music-channel.component.scss'],
  animations: [
    viewPositionAnimation,
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, { params: { duration: 10 } })
      ])
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        useAnimation(slideAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideAnimation, {
          params: {
            startX: 0,
            endX: '100%'
          }
        })
      ])
    ])
  ]
})
export class OverlaySelectMusicChannelComponent implements OnDestroy {


  @Output() closeWithMusicChannelData = new EventEmitter<MusicChannelData>();

  private showDetailsForMusicChannelSubject = new BehaviorSubject<MusicChannel>(null);
  public showDetailsForMusicChannel$ = this.showDetailsForMusicChannelSubject.asObservable();

  public get mainViewPosition$(){
    return this.showDetailsForMusicChannel$
    .pipe(
      map(
        musicChannel => {
          if (musicChannel){
            return 'left';
          }
          return 'mid';
        }
      )
    )
  }

  constructor(

  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onSelectMusicChannel(musicChannel: MusicChannel){
    this.showDetailsForMusicChannelSubject.next(musicChannel);
  }

  public onBack(){
    this.showDetailsForMusicChannelSubject.next(null);
  }

  public onClose(){
    this.closeWithMusicChannelData.emit();
  }

  public onSelectMusicCollection(musicCollection: MusicCollection){
    const musicChannelData = new MusicChannelData();
    musicChannelData.musicChannel = this.showDetailsForMusicChannelSubject.value;
    musicChannelData.musicCollection = musicCollection;
    this.closeWithMusicChannelData.emit(musicChannelData);
  }
}
