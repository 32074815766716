import { ElementRef } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter, map, tap, debounceTime } from 'rxjs/operators';

export class Util {

  static formatSeconds(secondsToFormat: number, hourString: string = 'h', minuteString: string = 'm', secondsString: string = 's'): string{
    /*
    let dateObj = new Date(secondsToFormat * 1000);

    let hours = dateObj.getUTCHours();
    let minutes = dateObj.getUTCMinutes();
    let seconds = dateObj.getSeconds();
    */

    //let days = Math. floor(secondsToFormat / (3600*24));
    //let hours = Math. floor(secondsToFormat % (3600*24) / 3600);
    let hours = Math. floor(secondsToFormat / 3600);
    let minutes = Math. floor(secondsToFormat % 3600 / 60);
    let seconds = Math. floor(secondsToFormat % 60);

    if (hours > 0){
      let timeString =
        hours.toString() + hourString + ' ' +
        minutes.toString().padStart(2, '0') + minuteString;
        //seconds.toString().padStart(2, '0');
      return timeString
    }else{
      let timeString =
          minutes.toString() + minuteString + ' ' +
          seconds.toString().padStart(2, '0') + secondsString;
      return timeString
    }
  }

  static formatStringArray(arrayToFormat: string[]): string {
    if (arrayToFormat) {
      return `[${arrayToFormat.join(', ')}]`;
    }
    return 'nil';
  }

  static formatObjectArray(arrayToFormat: any[]): string {
    if (arrayToFormat) {
      return `[${arrayToFormat.map(x => x.toString()).join(', ')}]`; // `[${arrayToFormat.join(', ')}]`;
    }
    return 'nil';
  }

  static escapeCharactersForSOAP(stringToEscape): string {
    if (stringToEscape) {
      stringToEscape = this.replaceAll(stringToEscape, '<', '&lt;');
      // stringToEscape = this.replaceAll(stringToEscape, '>', '&gt;');
      stringToEscape = this.replaceAll(stringToEscape, '&', '&amp;');
      stringToEscape = this.replaceAll(stringToEscape, '[', '&#91;');
      stringToEscape = this.replaceAll(stringToEscape, ']', '&#93;');
      // stringToEscape = this.replaceAll(stringToEscape, '"', '&quot;');
      // stringToEscape = this.replaceAll(stringToEscape, "'", ' &#39;');
    }
    return stringToEscape;
  }

  static replaceAll(stringToEdit, search, replacement): string {
    if (stringToEdit) {
      return stringToEdit.replace(
        new RegExp(this.escapeRegExp(search), 'g'),
        replacement
      );
    }
    return stringToEdit;
  }

  static escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }

  /**
   * Converts rem to pixels based on given ElementRef
   * @param elementRef
   * @param rem
   */
  static convertRemToPixels(
    rem: number,
    elementRef: ElementRef = null
  ): number {
    return (
      rem *
      parseFloat(getComputedStyle(document.querySelector('html')).fontSize)
    );
  }

  /**
   * Returns an observables which emits when a click happens outside of an element
   * @param window The current window
   * @param elementRef Reference to the element that is used to determine whether the click was outside of it
   * @returns An observable which emits when a click happens outside of the element, emits only once
   */
  static createOutsideClickObservable(
    window: Window,
    elementRef: ElementRef
  ): Observable<boolean> {
    return fromEvent(window, 'click').pipe(
      map(({ target }) => elementRef.nativeElement.contains(target)),
      filter(clickedInside => !clickedInside)
    );
  }

  /**
   * Checks if an element is overflown and returns the overflown amount
   * @param elementRef The reference holding the element for which to check the overflow
   * @returns True is the element is overflown, else false
   */
  static getOverflow(elementRef: ElementRef): number {
    return (
      (elementRef &&
        elementRef.nativeElement.scrollWidth -
          elementRef.nativeElement.clientWidth) ||
      0
    );
  }
}
