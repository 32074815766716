import { AudioFile } from '@model/audioFile';
import { PlayableAudio } from '../../../model/audioFile';

export class AudioFilePlayState{
    playableAudio: PlayableAudio;
    position: number;
    playing: boolean;

    public toString():string{
        return (this.playing?"":"Not ") + "Playing at position " + this.position + " for " + this.playableAudio;
    }
}