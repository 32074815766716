<!-- This wrapper shows the background without any animations on it-->
<div class="backGroundWrapper">

  <!--
  We need the fadeIn animation on the container
  to prevent the slideOutLeft animation from running at the initial render.
  -->
<div class="contentWrapper" @fadeIn>

  <!-- Keep the groups on screen to store the scrollPosition? -->
  <tun-playlists-view
    [@viewPosition]="mainViewPosition$ | async"
    (createPlaylist)="onCreatePlaylist()"
    class="fullContainer">
  </tun-playlists-view>

  <tun-edit-title-for-playlist-view
    *ngIf="(playlistView$ | async) == PLAYLIST_VIEW.PLAYLIST_VIEW_CREATE || (playlistView$ | async) == PLAYLIST_VIEW.PLAYLIST_VIEW_EDIT_TITLE"
    [@carousel]="{
      value: animationDirection,
      params: {
        duration: CAROUSEL_DURATION
      }
    }"
    [playlist]="editTitleForPlaylist$ | async"
    (back)="onBackFromEditTitleForPlaylist()"
    (@carousel.start)="textViewAnimationStart($event)"
    (@carousel.done)="textViewAnimationDone($event)"
    [canReceiveFocus]="!textViewIsAnimating"
    class="fullContainer"
  >

  </tun-edit-title-for-playlist-view>

  <tun-playlist-detail-view
    *ngIf="(playlistView$ | async) == PLAYLIST_VIEW.PLAYLIST_VIEW_DETAIL"
    [showPlaylist]="showDetailsForPlaylist$ | async"
    [@carousel]="{
      value: animationDirection,
      params: {
        duration: CAROUSEL_DURATION
      }
    }"
    (addSongs)="onAddSongs($event)"
    (changeName)="onChangeName($event)"
    class="fullContainer"
  >

  </tun-playlist-detail-view>

  <tun-search-to-add-view
    *ngIf="(playlistView$ | async) == PLAYLIST_VIEW.PLAYLIST_VIEW_SEARCH_TO_ADD"
    [@carousel]="{
      value: animationDirection,
      params: {
        duration: CAROUSEL_DURATION
      }
    }"
    (@carousel.start)="textViewAnimationStart($event)"
    (@carousel.done)="textViewAnimationDone($event)"
    [canReceiveFocus]="!textViewIsAnimating"
    [playlist]="addSongsToPlaylist$ | async"
    (back)="onCloseSearch()"
    class="fullContainer">

  </tun-search-to-add-view>

  <tun-access-right-overlay-view
      *ngIf="!(accessToPlaylists$ | async)"
      class="fullContainer"
      [message]="'accessRight.screen.customPlaylists' | translate">
    </tun-access-right-overlay-view>

</div>


</div>
