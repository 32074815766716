import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@service/authentication.service';
import { LoggerService } from '@service/loggers/logger.service';
import { environment } from 'src/environments/environment';
import { MusicCollection } from '@model/musicCollection';
import { Observable, Subject } from 'rxjs';
import { InMemoryDataService } from '@service/in-memory-data.service';
import { Context } from '@model/context';
import { Playlist } from '@model/playlist';
import { Config } from '@service/config';
import { TweakInfo } from '@model/tweakInfo';
import { ChangeableParameter } from '@model/changeableParameter';
import { AudioFile } from '@model/audioFile';

export class MusicCollectionPackage{
  mcId: number;
  changeableParameters: ChangeableParameter[];
  weight: number;
  musicChannelId: number;
}

export enum PlaylistSizeUnit{
  MINUTES = "MINUTES",
  AUDIOFILES = "AUDIOFILES"
}

export class AudioFilesForMusicCollectionRequestParameters{
  musicCollectionPackages: MusicCollectionPackage[];
  seedAudioFileIDs: number[];
  avoidSongIDs: number[];
  size: number;
  unit: PlaylistSizeUnit;
  saveChangeableParameters: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MusicCollectionApiService {

  private LOGGER_CLASSNAME = 'MusicCollectionApiService';

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService) { }




  public loadMusicCollectionDetails(musicCollection: MusicCollection): Observable<MusicCollection>{
    let resultObservable: Observable<MusicCollection>;

    if (environment.mockBackend){
      //we only have mocked data of a few musicCollections.
      //check if this musicCollection has mocked data, if not, load the default mocked data
      let musicCollectionIdForAPI = musicCollection.id;
      if (InMemoryDataService.mockedMusicCollectionIds.indexOf(musicCollectionIdForAPI) < 0){
        if (musicCollection instanceof Context){
          if (Math.random() < 0.5){
            musicCollectionIdForAPI = InMemoryDataService.defaultMockedContextId;
          }else{
            musicCollectionIdForAPI = InMemoryDataService.defaultMockedContextId2;
          }
        }else if (musicCollection instanceof Playlist){
          musicCollectionIdForAPI = InMemoryDataService.defaultMockedPlaylistId;
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, "loadMusicCollectionDetails", "musicCollection type not recognized: " + musicCollection);
        }
      }

      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadMusicCollectionDetails', 'about to mock loadMusicCollectionDetails request for musicCollection with id ' + musicCollection.id + " with mocked id " + musicCollectionIdForAPI);
      resultObservable = this.httpClient.get<MusicCollection>("api/musicCollection/" + musicCollectionIdForAPI);
    }else{
      const params = new HttpParams()
                      .set('id', "" + musicCollection.id);

      let url = Config.api4Url_musicCollection(this.authenticationService.zoneId);
      resultObservable = this.httpClient.get<MusicCollection>(url, {params: params});
    }
    return resultObservable;
  }



  public loadTweakInfo(contextId: number, changeableParameters: ChangeableParameter[]): Observable<TweakInfo>{
    let resultObservable: Observable<TweakInfo>;

    if (environment.mockBackend){
      let contextIdForAPI = contextId;
      if (InMemoryDataService.mockedTweakInfoMusicCollectionIds.indexOf(contextIdForAPI) < 0){
        contextIdForAPI = InMemoryDataService.defaultMockedTweakInfoMusicCollectionId;
      }

      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadContextTweakInfo', 'about to mock loadContextTweakInfo request for context with id ' + contextId + " with mocked id " + contextIdForAPI);
      resultObservable = this.httpClient.get<TweakInfo>("api/tweak-info/" + contextIdForAPI);
    }else{
      let url = Config.api4Url_musicCollection_tweakInfo(this.authenticationService.zoneId, contextId);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "loadContextTweakInfo", "going to load tweakInfo for context " + contextId + " from " + url);

      resultObservable = this.httpClient.post<null>(url, changeableParameters);
    }
    return resultObservable;
  }

  public loadTracksForMusicCollectionPackages( musicCollectionPackages: MusicCollectionPackage[]): Observable<AudioFile[]>{
    let resultObservable: Observable<AudioFile[]>;

    if (environment.mockBackend){

      if (Math.random() > 0.2) {
        let url = "api/search2";
        this.loggerService.info(this.LOGGER_CLASSNAME, 'loadTracksForMusicCollections', 'about to mock loadTracksForMusicCollections.  going to use ' + url);
        resultObservable = this.httpClient.get<AudioFile[]>(url);
      }else{
        // simulate 20% error chance
        let resultSubject = new Subject<null>();
        resultObservable = resultSubject;
         // simulate 2 seconds request
        setTimeout(() => {
          resultSubject.error("Simulate error");
        }, 2000);
      }
    }else{

      let audioFilesForMusicCollectionRequestParameters = new AudioFilesForMusicCollectionRequestParameters();
      audioFilesForMusicCollectionRequestParameters.musicCollectionPackages = musicCollectionPackages;
      audioFilesForMusicCollectionRequestParameters.avoidSongIDs = [];
      audioFilesForMusicCollectionRequestParameters.saveChangeableParameters = false;
      audioFilesForMusicCollectionRequestParameters.seedAudioFileIDs = [];
      audioFilesForMusicCollectionRequestParameters.unit = PlaylistSizeUnit.MINUTES;
      audioFilesForMusicCollectionRequestParameters.size = 240;

      let url = Config.api4Url_audioFile_playableList(this.authenticationService.zoneId);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "loadTracksForMusicCollections", "going to load playable list");

      const headers = new HttpHeaders({"generator": "playable-list", "input": "musicCollections"});

      resultObservable = this.httpClient.post<null>(url, audioFilesForMusicCollectionRequestParameters, {headers: headers});
    }
    return resultObservable;
  }
}
