import { Injectable } from '@angular/core';
import { Calendar } from '@model/calendar';
import { AudioFileOriginType } from '@model/enums/audioFileOriginType';
import { StreamMode } from '@model/enums/streamMode';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { MusicChannelService } from '@service/music-channel.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { QueueService } from '@service/queue.service';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

export enum StartMusicSelectionFailedReason{
  NothingSelected, Timeout, Deleted
}

export class StartMusicChannelFeedback{
  musicChannel: MusicChannel
  success: boolean
  failedReason: StartMusicSelectionFailedReason

  constructor(musicChannel: MusicChannel, success: boolean, failedReason: StartMusicSelectionFailedReason = null){
    this.musicChannel = musicChannel
    this.success = success
    this.failedReason = failedReason
  }
}

export class StartCalendarFeedback{
  calendar: Calendar
  success: boolean
  failedReason: StartMusicSelectionFailedReason

  constructor(calendar: Calendar, success: boolean, failedReason: StartMusicSelectionFailedReason = null){
    this.calendar = calendar
    this.success = success
    this.failedReason = failedReason
  }
}

@Injectable({
  providedIn: 'root'
})
export class AudioCommandsService {

  //musicChannel to start
  private get _musicChannelToStart(): MusicChannel {
    return this._musicChannelToStartSubject.value;
  }
  private set _musicChannelToStart(value: MusicChannel) {
    if (this._musicChannelToStart != value) {
      this._musicChannelToStartSubject.next(value);

      this.adjustWatchRadioQueue();

      if (this.musicChannelToStart){
        this._calendarToStart = null;

        this.checkStartPossible();
        this.restartErrorDelay();
      }
    }
  }
  get musicChannelToStart(): MusicChannel {
    return this._musicChannelToStart;
  }
  private _musicChannelToStartSubject = new BehaviorSubject<MusicChannel>(null);
  public musicChannelToStart$ = this._musicChannelToStartSubject.asObservable();

  //feedback event
  private _startMusicChannelFeedbackSubject = new Subject<StartMusicChannelFeedback>();
  public startMusicChannelFeedback$ = this._startMusicChannelFeedbackSubject.asObservable();

   //calendar to start
   private get _calendarToStart(): Calendar {
    return this._calendarToStartSubject.value;
  }
  private set _calendarToStart(value: Calendar) {
    if (this._calendarToStart != value) {

      this._calendarToStartSubject.next(value);

      this.adjustWatchRadioQueue();

      if (this.calendarToStart){
        this._musicChannelToStart = null;


        this._calendarToStart.calendarItemsLoadingDone$
          .pipe(
            takeUntil(
              this.calendarToStart$
            )
          )
          .subscribe(
            ()=>{
              this.checkStartPossible();
            }
          )

        this.checkStartPossible();
        this.restartErrorDelay();
      }
    }
  }
  get calendarToStart(): Calendar {
    return this._calendarToStart;
  }
  private _calendarToStartSubject = new BehaviorSubject<Calendar>(null);
  public calendarToStart$ = this._calendarToStartSubject.asObservable();

  //feedback event
  private _startCalendarFeedbackSubject = new Subject<StartCalendarFeedback>();
  public startCalendarFeedback$ = this._startCalendarFeedbackSubject.asObservable();

  constructor(
    private musicChannelService: MusicChannelService,
    private queueService: QueueService,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private musicManipulationService: MusicManipulationService
  ) { }

  public selectAndStartMusicCollections(musicChannel: MusicChannel, musicCollections: MusicCollection[]){
    musicChannel.musicCollections.forEach(mc => {
      if (musicCollections.includes(mc)){
        //should be selected
        if (mc.selected == false){
          mc.selected = true;
          this.musicChannelService.saveMusicCollectionSelectionProperty(
            musicChannel,
            mc
          );
        }
      }else{
        //should not be selected
        if (mc.selected){
          mc.selected = false;
          this.musicChannelService.saveMusicCollectionSelectionProperty(
            musicChannel,
            mc
          );
        }
      }
    });

    if (this.activeMusicSelectionService.selectedStreamMode != StreamMode.MUSIC_CHANNEL || this.activeMusicSelectionService.selectedMusicChannel != musicChannel){
      this.startMusicChannel(musicChannel);
    }else{
      this._startMusicChannelFeedbackSubject.next(new StartMusicChannelFeedback(musicChannel, true))
    }

  }

  public startMusicChannel(musicChannel: MusicChannel){
    let canStart = true;
    if (musicChannel.musicCollections){
      const selectedMusicCollections = musicChannel.musicCollections.filter(mc => mc.selected);
      canStart = selectedMusicCollections.length > 0;
    }

    if (canStart){
      this.queueService.clearQueue();
      this._musicChannelToStart = musicChannel;
      this.activeMusicSelectionService.changeActiveMusicChannel(musicChannel);
      this.activeMusicSelectionService.selectedPlayerView = TunifyColor.BLUE;
    }else{
      this._startMusicChannelFeedbackSubject.next(new StartMusicChannelFeedback(musicChannel, false, StartMusicSelectionFailedReason.NothingSelected))
    }
  }

  public startCalendar(calendar: Calendar){
    let canStart = true;
    let failedReason = StartMusicSelectionFailedReason.Timeout;
    if (calendar.deleted){
      canStart = false;
      failedReason = StartMusicSelectionFailedReason.Deleted
    }

    if (canStart){
      this.queueService.clearQueue();
      this._calendarToStart = calendar
      this.activeMusicSelectionService.changeActiveCalendar(calendar);
      this.activeMusicSelectionService.selectedPlayerView = TunifyColor.GREEN;
    }else{
      this._startCalendarFeedbackSubject.next(new StartCalendarFeedback(calendar, false, failedReason))
    }

  }


  //clear autoStart after delay (error)
  private stopErrorTimer = new Subject<void>();
  private stopErrorDelay(){
    this.stopErrorTimer.next();
  }
  private restartErrorDelay(){
    this.stopErrorDelay();

    if (this.musicChannelToStart || this.calendarToStart){
      timer(10000)
      .pipe(
        takeUntil(
          this.stopErrorTimer
        )
      )
      .subscribe(
        () => {
          if (this.musicChannelToStart){
            this._startMusicChannelFeedbackSubject.next(new StartMusicChannelFeedback(this.musicChannelToStart, false, StartMusicSelectionFailedReason.Timeout))
            this._musicChannelToStart = null;
          }
          if (this.calendarToStart) {
            this._startCalendarFeedbackSubject.next(new StartCalendarFeedback(this.calendarToStart, false, StartMusicSelectionFailedReason.Timeout))
            this._calendarToStart = null;
          }
        }
      );
    }
  }

  //Watch radio queue
  private adjustWatchRadioQueue(){
    this.watchRadioQueue = this.musicChannelToStart != null || this.calendarToStart != null;
  }

  private _watchRadioQueue = false;
  private stopWatchingRadioQueue = new Subject<void>();
  private set watchRadioQueue(value: boolean){
    if (this._watchRadioQueue != value){
      this._watchRadioQueue = value;
      this.stopWatchingRadioQueue.next();
      if (this.watchRadioQueue){
        this.queueService.radioQueue$
        .pipe(
          takeUntil(
            this.stopWatchingRadioQueue
          )
        )
        .subscribe(
          ()=>{
            this.checkStartPossible();
          }
        );
      }
    }
  }
  private get watchRadioQueue(){
    return this._watchRadioQueue;
  }

  //Check if we can start: first item in radioQueue is from new selection
  private checkStartPossible(){
    if (this.musicChannelToStart && this.musicChannelToStart.musicCollections){
      if (this.queueService.radioQueue, this.queueService.radioQueue.length > 0){
        const firstTrack = this.queueService.radioQueue[0];
        if (firstTrack.origin && firstTrack.origin.type == AudioFileOriginType.MUSICCOLLECTION){
          const idValue = parseInt(firstTrack.origin.data, 10);
          if ( !isNaN(idValue)) {
            const filtered = this.musicChannelToStart.musicCollections.filter(mc => mc.id == idValue)
            if (filtered.length > 0){
              const startedMusicChannel = this._musicChannelToStart;
              this._musicChannelToStart = null;

              this.musicManipulationService.playAudioFile(firstTrack, true);

              this._startMusicChannelFeedbackSubject.next(new StartMusicChannelFeedback(startedMusicChannel, true))
            }
          }
        }
      }
    }
    if (this.calendarToStart && this.calendarToStart.calendarItems){
      if (this.queueService.radioQueue, this.queueService.radioQueue.length > 0){
        const firstTrack = this.queueService.radioQueue[0];
        if (firstTrack.origin && firstTrack.origin.type == AudioFileOriginType.CALENDAR_ITEM){
          const idValue = parseInt(firstTrack.origin.data, 10);
          if ( !isNaN(idValue)) {
            const filtered = this.calendarToStart.calendarItems.filter(ci => ci.id == idValue)
            if (filtered.length > 0){
              const startedCalendar = this._calendarToStart;
              this._calendarToStart = null;

              this.musicManipulationService.playAudioFile(firstTrack, true);

              this._startCalendarFeedbackSubject.next(new StartCalendarFeedback(startedCalendar, true))
            }
          }
        }
      }
    }
  }
}
