import { Overlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopupDirection, PopupPosition } from '@components/popups/popup/enums';
import { PopupContent, TooltipPopupContent } from '@components/popups/popup/interfaces';
import { TooltipAlignment, TooltipComponent } from '@components/popups/tooltip/tooltip.component';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { AudioTagService } from '@service/audio.tag.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { ExternalLinksService } from '@service/external-links.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { MusicPlayerService } from '@service/music-player.service';
import { PopupService } from '@service/popup.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, combineLatest, merge, timer } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { ApplicationMode } from '../../../../../services/authentication/zone-connections.service';

@Component({
  selector: 'tun-toggle-play-button',
  templateUrl: './toggle-play-button.component.html',
  styleUrls: ['./toggle-play-button.component.scss']
})
export class TogglePlayButtonComponent implements OnInit, OnDestroy, AfterViewInit {

  //@ViewChild('popupReference', {static: true}) popupReference:any;
  @ViewChild('popupReference') popupReference: ElementRef;

  private _canShowTooltips = false;
  @Input()
  public set canShowTooltips(value: boolean){
    this._canShowTooltips = value;
    this.showOrHideNeedClickTooltip();
  }
  public get canShowTooltips(){
    return this._canShowTooltips;
  }

  private LOGGER_CLASSNAME = 'TogglePlayButtonComponent';

  public get reallyPlaying$(){
    return combineLatest([this.playing$, this.needClickToStartAudio$])
    .pipe(
      map(([playing, needClickToStartAudio]) => {
        return !needClickToStartAudio && playing
      })
    )
  }

  public get playing$(){
    return this.playingItemService.isPlaying$
  }

  public get colorString$(){
    return this.playingMusicSelectionService.lastRealplayingMusicSelection$
      .pipe(
      map((playingMusicSelection) => {
        if (playingMusicSelection.origin == PlayingOrigin.musicChannel){
          return "blue"
        }else if (playingMusicSelection.origin == PlayingOrigin.calendar){
          return "green"
        }
        return "orange"
      })
    )
  }

  public get needClickToStartAudio$() {
    return this.playingItemService.needClickToPlay$
  }

  constructor(
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private musicManipulationService: MusicManipulationService,
    private playingItemService: PlayingItemService,
    private audioTagService: AudioTagService,
    private musicPlayerService: MusicPlayerService,
    private loggerService: LoggerService,
    private overlay: Overlay,
    private ngZone: NgZone,
    private popupService: PopupService,
    private deviceDetectorService: DeviceDetectorService,
    private externalLinksService: ExternalLinksService,
    private changeDetectorRef: ChangeDetectorRef,
    private zoneConnectionsService: ZoneConnectionsService
    ) {

    }

    ngOnInit() {

      this.audioTagService.needClickToStartAudio$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (needClick) => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, "needClickToStartAudio subscription", "value: " + needClick);
            //this.changeDetectorRef.detectChanges();

            this.ngZone.run(() => {
              this.showOrHideNeedClickTooltip();
            });
          }
        );

    }

    private initDoneForTooltips = false;
    ngAfterViewInit() {
      //wait a bit before showing any tooltips
      timer(200)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.initDoneForTooltips = true;
          this.showOrHideNeedClickTooltip();
        });
    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy(): void {
      this.hideNeedClickTooltip();

        this.destroyed$.next();
        this.destroyed$.complete();
    }

  public onTogglePlay(){
    if (this.playingItemService.needClickToPlay) {

      this.audioTagService.enableAudioAfterClick();
      if (this.musicPlayerService.currentActiveAudioFileWithPlayInfo){
        this.musicPlayerService.currentActiveAudioFileWithPlayInfo.startForAudioContextResume();
      }

      this.loggerService.debug(this.LOGGER_CLASSNAME, "onTogglePlay", "starting audio -> " + (NgZone.isInAngularZone() ? "" : "NOT ") + "in angular zone");

      if (!this.playingItemService.isPlaying) {
        this.musicManipulationService.play()
      }
    } else if (this.musicPlayerService.currentActiveAudioFileWithPlayInfo && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.needClickToUseAudioTag) {
      //firefox -> resume?
      this.musicPlayerService.currentActiveAudioFileWithPlayInfo.startForAudioContextResume();
    } else {
      if (this.playingItemService.isPlaying) {
        this.musicManipulationService.pause()
      }else{
        this.musicManipulationService.play()
      }
    }
  }


  /** TOOLTIP -  Need a click to start */

  public get needClickToStartAudio() {
    return  this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode && (this.audioTagService.needClickToStartAudio || (this.musicPlayerService.currentActiveAudioFileWithPlayInfo && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.needClickToUseAudioTag));
  }

  private tooltipContent: TooltipPopupContent;
  private showOrHideNeedClickTooltip() {
    if (this.needClickToStartAudio && this.canShowTooltips && this.initDoneForTooltips) {
      if (!this.tooltipContent) {
        this.showNeedClickTooltip();
      } else {
        this.showNeedClickText();
      }
    } else if (!this.needClickToStartAudio && this.tooltipContent) {
      //we don't need a click any more
      this.tooltipContent.text = "player.autostart.confirm";
      this.tooltipContent.tooltipAction = null;
      this.changeDetectorRef.detectChanges();
      timer(2000)
        .pipe(
          takeUntil(
            merge(
              this.destroyed$,
              this.audioTagService.needClickToStartAudio$.pipe(
                filter(value => value == true),
                takeUntil(this.destroyed$)
              )
            )
          )
        )
        .subscribe(() => {
          this.ngZone.run(() => {
            this.hideNeedClickTooltip();
          });
        });
    } else if (!this.canShowTooltips && this.tooltipContent) {
      //we are not in the playing state -> just remove the we need a click tooltip
      this.hideNeedClickTooltip();
    }
  }

  private showNeedClickTooltip() {
    this.popupService.showPopup$.next({
      connector: this.popupReference,
      componentType: TooltipComponent,
      popupDirection: PopupDirection.DOWN,
      popupPosition: PopupPosition.BOTTOM_CENTER,
      dynamicPosition: true,
      animationDuration: 200,
      showArrow: true,
    });

    this.popupService.currentInstance$
      .pipe(
        filter(
          (instance: PopupContent) =>
            instance &&
            instance.connectedElementRef === this.popupReference
        ),
        take(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((instance: TooltipPopupContent) => {
        this.tooltipContent = instance;
        this.showNeedClickText();
      });

    //listen when the popup will be closed
    merge(
      this.popupService.hideAllPopups$,
      this.popupService.hidePopup$.pipe(
        filter(
          ({ nativeElement }) =>
            nativeElement === this.popupReference.nativeElement

        ),
        takeUntil(this.destroyed$)
      )
    )
      .pipe(
        take(1),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.tooltipContent = null;
      });
  }

  private showNeedClickText() {
    const browser = this.deviceDetectorService.browser;
    const isFirefox = browser != null && browser.toLowerCase() == "firefox";
    //special message for firefox
    if (isFirefox) {
      this.tooltipContent.text = "player.autostart.firefox.needClick";
      this.tooltipContent.alignment = TooltipAlignment.LEFT;
      this.tooltipContent.tooltipAction = { action: this.openFirefoxLink, text: "player.autostart.firefox.clickAction" }
    } else {
      this.tooltipContent.text = "player.autostart.needClick";
      this.tooltipContent.alignment = TooltipAlignment.CENTER;
      this.tooltipContent.tooltipAction = { action: this.play, text: "player.autostart.clickAction" }
    }

  }

  private hideNeedClickTooltip() {
    if (this.tooltipContent) {
      this.popupService.hidePopup$.next(this.popupReference);
    }

  }

  private play = () => {
    if (this.audioTagService.needClickToStartAudio) {
      this.audioTagService.enableAudioAfterClick();

      this.musicPlayerService.currentActiveAudioFileWithPlayInfo.startForAudioContextResume();

      if (!this.playingItemService.isPlaying) {
        this.musicManipulationService.play()
      }

    }
  }

  private openFirefoxLink = () => {
    this.externalLinksService.openFirefoxAutoPlayHelp();
  }

  /*
  public showNeedClickPopup = false;

  displayOverlay() {
    const overlayRef = this.overlay.create({
      hasBackdrop: false,
      backdropClass: "cdk-overlay-transparent-backdrop",
      panelClass: "mat-elevation-z8",
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.popupReference)
        .withPositions([
          {
            originX: "center",
            originY: "bottom",
            overlayX: "center",
            overlayY: "top"
          }
        ])
    });
    const component = new ComponentPortal(ClickToStartPopupComponent);
    const componentRef = overlayRef.attach(component);
    //overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }
  */
}
