import { Component } from '@angular/core';

@Component({
  selector: 'tun-plus-icon-v5',
  templateUrl: './plus-icon-v5.component.html',
  styleUrls: ['./plus-icon-v5.component.scss']
})
export class PlusIconV5Component {

}
