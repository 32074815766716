import { MusicChannel } from './musicChannel';
import { MusicChannelGroupType } from './enums/musicChannelGroupType';

export class MusicChannelGroup{
    id: number;
    name: string;
    type: MusicChannelGroupType;

    musicChannels: MusicChannel[];

    constructor(musicChannelGroup?: MusicChannelGroup){
        //you can pass in an object that conforms the MusicChannelGroup type and construct a new object that actually is of the MusicChannelGroup type 
        //This allows us to call instanceof on the returned object.
        if (musicChannelGroup){
            this.id = musicChannelGroup.id;
            this.name = musicChannelGroup.name;
            this.type = musicChannelGroup.type;
    
            this.musicChannels = [];
            if (musicChannelGroup.musicChannels){
                musicChannelGroup.musicChannels.forEach(musicChannel => {
                    this.musicChannels.push(new MusicChannel(musicChannel))
                });
            }
        }
        
    }


}