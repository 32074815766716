export const publicConfigs = [
    {
        "key": "AUTOCOMPLETION_CRED_PASSWORD",
        "value": "ne7nq905nc6hjzla6r",
        "status": "OK"
    },
    {
        "key": "AUTOCOMPLETION_CRED_USERNAME",
        "value": "autocompletion",
        "status": "OK"
    },
    {
        "key": "AUTOCOMPLETION_URL",
        "value": "https://2db0bbbf5ff0413b802bdb9fe9416bc6.eu-west-1.aws.found.io:9243/autocompletion/_search",
        "status": "OK"
    }
]