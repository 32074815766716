import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';


@Component({
  selector: 'tun-overlay-music-collection-button-view',
  templateUrl: './overlay-music-collection-button-view.component.html',
  styleUrls: ['./overlay-music-collection-button-view.component.scss']
})
export class OverlayMusicCollectionButtonViewComponent {

  @Input() musicCollection: MusicCollection;

  @Output() selectMusicCollection = new EventEmitter<MusicCollection>();

  get isPlaylist(){
    return this.musicCollection != null && this.musicCollection instanceof Playlist;
  }

  constructor(

  ){

  }

  public onSelect(){
    this.selectMusicCollection.emit(this.musicCollection);
  }
}
