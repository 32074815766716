import { Component, Input } from '@angular/core';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { ApplicationMode, ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-track-origin-image-view',
  templateUrl: './track-origin-image-view.component.html',
  styleUrls: ['./track-origin-image-view.component.scss']
})
export class TrackOriginImageViewComponent {

  @Input() canShowRemoteOverlay = false;

  public get showQueue$(){
    return this.playingMusicSelectionService.lastRealplayingMusicSelection$
    .pipe(
      map((playingMusicSelection) => {
        if (playingMusicSelection != null){
          return playingMusicSelection.origin == PlayingOrigin.waitingQueue
        }
        return false
      })
    )
  }

  public get originImageUrl$(){
    return this.playingMusicSelectionService.lastRealplayingMusicSelection$
    .pipe(
      map((playingMusicSelection) => {
        if (playingMusicSelection != null){
          return playingMusicSelection.imageUrl
        }
        return ""
      })
    )
  }

  get showOverlay$(){
    return combineLatest([this.remoteRemoteActive$, this.isRemote$])
    .pipe(map(([remoteRemoteActive, isRemote]) => remoteRemoteActive || isRemote))
  }

  get remoteRemoteActive$(){
    return combineLatest([this.zoneConnectionsService.applicationMode$, this.zoneConnectionsService.currentRemoteApplicationInfos$])
    .pipe(
      map(([applictionMode, remoteApplicationInfos]) => applictionMode == ApplicationMode.playerMode && remoteApplicationInfos != null && remoteApplicationInfos.length > 0)

    )
  }

  get isRemote$(){
    return this.zoneConnectionsService.applicationMode$
    .pipe(map(applicationMode => applicationMode == ApplicationMode.remoteMode));
  }

  constructor(
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private zoneConnectionsService: ZoneConnectionsService
    ) {

    }
}
