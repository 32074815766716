import { AudioFile } from '@model/audioFile';
import { ListManipulationAction } from './listManipulationAction';
import { ListManipulation } from './listManipulation';
import { ListManipulationTrack } from './listManipulationTrack';
import { AsyncStatus } from '../asyncStatus';
import { BehaviorSubject } from 'rxjs';

export class LocalListManipulation{
    tracks: AudioFile[];
    position: number;
    action: ListManipulationAction;

    notifier: BehaviorSubject<AsyncStatus>;

    toListManipulations():ListManipulation[]{
        let listManipulations:ListManipulation[] = [];
        if (this.tracks){
            let tempPosition = this.position;
            
            this.tracks.forEach(
                (audioFile) => {
                    let listManipulation:ListManipulation = new ListManipulation();
                    listManipulation.position = tempPosition;
                    listManipulation.action = this.action;
                    listManipulation.track = ListManipulationTrack.createFromAudioFile(audioFile);
                    if (this.action === ListManipulationAction.ADD){
                        tempPosition++; //when we add objects, we need to increase the index for each object we add to preserve the order
                    }
                    listManipulations.push(listManipulation);
                }
            )    
        }else{
            let listManipulation:ListManipulation = new ListManipulation();
            listManipulation.position = this.position;
            listManipulation.action = this.action;
            listManipulations.push(listManipulation);
        }
        return listManipulations;
    }
}