import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Playlist } from '@model/playlist';

@Component({
  selector: 'tun-search-to-add-view',
  templateUrl: './search-to-add-view.component.html',
  styleUrls: ['./search-to-add-view.component.scss']
})
export class SearchToAddViewComponent {

  @Input() playlist: Playlist;

  @Input() canReceiveFocus = false;

  @Output() back = new EventEmitter<void>();

  onBack(){
    this.back.emit();
  }
}
