<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
   <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">

    <rect stroke="currentColor" fill="currentColor" x="4.5" y="7.5" width="15" height="1" rx="0.5"></rect>
    <rect stroke="currentColor" fill="currentColor" x="4.5" y="12.5" width="7" height="1" rx="0.5"></rect>
    <rect stroke="currentColor" fill="currentColor" x="4.5" y="17.5" width="5" height="1" rx="0.5"></rect>
    <rect stroke="currentColor" fill="currentColor" x="0.5" y="2.5" width="1" height="1" rx="0.5"></rect>
    <rect  stroke="currentColor" fill="currentColor" x="4.5" y="2.5" width="15" height="1" rx="0.5"></rect>
    <circle stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" cx="18" cy="17.5" r="5"></circle>
    <line x1="15.5" y1="17.5" x2="20.5" y2="17.5" stroke="currentColor" stroke-linecap="round"></line>
    <line x1="18" y1="20" x2="18" y2="15" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></line>
    <rect stroke="currentColor" fill="currentColor" x="0.5" y="7.5" width="1" height="1" rx="0.5"></rect>
    <rect stroke="currentColor" fill="currentColor" x="0.5" y="12.5" width="1" height="1" rx="0.5"></rect>
    <rect stroke="currentColor" fill="currentColor" x="0.5" y="17.5" width="1" height="1" rx="0.5"></rect>
</g>
</svg>
