import { Component, EventEmitter, Output } from '@angular/core';
import { SETTINGS_VIEW } from '../settings-panel.component';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { map } from 'rxjs/operators';
import { AppVersionService } from '@service/app-version.service';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { environment } from 'src/environments/environment';
import { DesktopAppInstallerService, DesktopDeviceType } from '@service/app-v5/desktop-app-installer.service';
import { UpdateService } from '@service/update.service';

@Component({
  selector: 'tun-settings-home-panel',
  templateUrl: './settings-home-panel.component.html',
  styleUrls: ['./settings-home-panel.component.scss']
})
export class SettingsHomePanelComponent {

  @Output() openSettingsView = new EventEmitter<SETTINGS_VIEW>();

  public SETTINGS_VIEW = SETTINGS_VIEW;

  public get updateAvailable$(){
    return this.updateService.updateAvailable$;
  }

  public get isDesktopApp(){
    return isDesktopApp();
  }

  get enableDownloadApp(){
    return environment.enableDownloadApp && this.desktopAppInstallerService.desktopDeviceType != DesktopDeviceType.OTHER;
  }

  public get language$(){
    return this.zoneConfigurationService.language$
    .pipe(
      (map(lang => {
        return "settings.language." + lang;
      }))
    )
  }

  public get version$(){
    return this.appVersionService.version$;
  }

  constructor(
    private zoneConfigurationService: ZoneConfigurationService,
    private appVersionService: AppVersionService,
    private desktopAppInstallerService: DesktopAppInstallerService,
    private updateService: UpdateService
  ) {

  }

  public onOpen(settingsView: SETTINGS_VIEW){
    this.openSettingsView.emit(settingsView);
  }

  public forceUpdate(){
    this.updateService.forceUpdate();
  }
}
