import { Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '../loggers/logger.service';
import { BehaviorSubject, Observable, Subscription, Subject } from 'rxjs';
import { MusicChannelGroup } from '@model/musicChannelGroup';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

import { MusicChannelApiService } from '@service/api/music-channel-api.service';

import { MusicChannelGroupType } from '@model/enums/musicChannelGroupType';
import { ZoneConfigurationService } from './zone-configuration.service';
import { DTO_LoadMusicChannelGroupsResponse } from '../api/music-channel-api.service';

/**
 * This service loads the music channels that are used in the menu of Tunify Orange
 */

@Injectable({
  providedIn: 'root'
})
export class SearchMusicChannelService implements OnDestroy {

  private LOGGER_CLASSNAME = 'SearchMusicChannelService';

  constructor(
    private musicChannelApiService: MusicChannelApiService,
    private loggerService: LoggerService,
    private authenticationService: AuthenticationService,
    private zoneConfigurationService: ZoneConfigurationService
  ) {

    this.authenticationService.loggedIn$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          //start loading once the language is known
          if (value) {
            this.loadDataIfPossible();
          } else {
            this.clearData();
          }
        }
      );

    this.zoneConfigurationService.language$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          //start loading once the language is known. This will also reload the data when the language is changed.
          if (value != null) {
            this.loadDataIfPossible();
          }
        }
      );
  }

  private destroyed$ = new Subject<void>();
  public ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
    this.destroyed$ = null;

    this.cancelPreviousLoadMusicChannelGroupsRequest.next(null);
    this.cancelPreviousLoadMusicChannelGroupsRequest.complete();
    this.cancelPreviousLoadMusicChannelGroupsRequest = null;
  }

  //load the data once all depending data is available
  private loadDataIfPossible() {
    if (this.authenticationService.loggedIn && this.zoneConfigurationService.language != null) {
      this.loadMusicChannelGroups();
    }
  }

  private clearData() {
    //cancel any loading requests
    this.cancelPreviousLoadMusicChannelGroupsRequest.next(null);

    this._loading = false;
    this._loadingError = null;
    this._musicChannelGroups = null;
  }

  /**
   * loading
   * @type {boolean}
   * @private
   */
  private get _loading(): boolean {
    return this._loadingSubject.value;
  }
  private set _loading(value: boolean) {
    if (this._loading !== value) {
      this._loadingSubject.next(value);
    }
  }
  get loading(): boolean {
    return this._loading;
  }
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loadingSubject.asObservable();

  /**
   * Error emitter for retrieving the musicChannelGroups
   * @type {string}
   * @private
   */
  private get _loadingError(): string {
    return this._loadingErrorSubject.value;
  }
  private set _loadingError(value: string) {
    if (this._loadingError !== value) {
      this._loadingErrorSubject.next(value);
    }
  }
  get loadingError(): string {
    return this._loadingError;
  }
  private _loadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public loadingError$: Observable<string> = this._loadingErrorSubject.asObservable();

  /**
   * musicChannelGroups
   * @type {MusicChannelGroup[]}
   * @private
   */
  private get _musicChannelGroups(): MusicChannelGroup[] {
    return this._musicChannelGroupsSubject.value;
  }
  private set _musicChannelGroups(value: MusicChannelGroup[]) {
    if (this._musicChannelGroups !== value) {
      this._musicChannelGroupsSubject.next(value);
    }
  }
  get musicChannelGroups(): MusicChannelGroup[] {
    return this._musicChannelGroups;
  }
  private _musicChannelGroupsSubject: BehaviorSubject<MusicChannelGroup[]> = new BehaviorSubject<MusicChannelGroup[]>(null);
  public musicChannelGroups$: Observable<MusicChannelGroup[]> = this._musicChannelGroupsSubject.asObservable();

  //the subject to cancel any previous request
  private cancelPreviousLoadMusicChannelGroupsRequest = new Subject<void>();
  public loadMusicChannelGroups(): Observable<MusicChannelGroup[]> {

    this.loggerService.debug(this.LOGGER_CLASSNAME, "loadMusicChannelGroups", "loading started");

    this.cancelPreviousLoadMusicChannelGroupsRequest.next(null);

    this._loading = true;
    this._loadingError = null;

    const musicChannelGroupObservable: Observable<DTO_LoadMusicChannelGroupsResponse> = this.musicChannelApiService.loadMusicChannelGroups(MusicChannelGroupType.SEARCH);

    musicChannelGroupObservable
      .pipe(
        finalize(() => {
          this._loading = false;
        }),
        takeUntil(
          this.cancelPreviousLoadMusicChannelGroupsRequest
        )
      )
      .subscribe(
        (data) => {

          if (data.musicChannelGroups instanceof Array) {

            let realObjects = data.musicChannelGroups.map(res => new MusicChannelGroup(res));
            this._musicChannelGroups = realObjects;

          } else {
            this._loadingError = "Something went wrong (bad format error)";
            this.loggerService.error(this.LOGGER_CLASSNAME, "loadMusicChannelGroups", "result is not an array");
          }
        },
        error => {
          const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
          this.loggerService.error(this.LOGGER_CLASSNAME, "loadMusicChannelGroups", "error: " + errMsg);
          this._loadingError = 'General Error';
        });

    return this.musicChannelGroups$;
  }

}
