import { Component, OnInit, OnDestroy, ChangeDetectorRef, NgZone, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ZoneConnectionsService } from '../../../services/authentication/zone-connections.service';
import { Subject, combineLatest, Observable, merge, BehaviorSubject, EMPTY } from 'rxjs';
import { AppService } from '../../../services/app/app.service';
import { map, takeUntil, mergeAll, filter, mapTo, mergeMap, switchMap, first, delay } from 'rxjs/operators';
import { ZoneConnection } from '../../../model/zoneConnection';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ExternalApplicationInfo, ConnectionType } from '../../../services/data/vo/remote/remote-objects';
import { ExternalLinksService } from '@service/external-links.service';
import { ResizedEvent } from '@components/resize/resize.directive';

export enum ZoneConnectionsState {
  none,
  disconnected,
  connecting,
  creatingConnection
}

@Component({
  selector: 'tun-zone-connections',
  templateUrl: './zone-connections.component.html',
  styleUrls: ['./zone-connections.component.scss'],
  host: {
    'class': 'router-flex darkPanelBackground'
  }
})
export class ZoneConnectionsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('zoneConnectionsView', {static: true}) zoneConnectionsView:any;
  @ViewChild('scrollableZoneContainer', {static: true}) scrollableZoneContainer:ElementRef;

  ZoneConnectionsState = ZoneConnectionsState;

  public numbers = Array(5).fill(0).map((x,i)=>i);
  public faAngleLeft = faAngleLeft;
  public faAngleRight = faAngleRight;

  public generalError$ = new BehaviorSubject<string>(null);

  //we keep track of the errors and state of the first zoneConnection
  private _focussedZoneConnectionSubject = new BehaviorSubject<ZoneConnection>(null);
  private focussedZoneConnection$ = this._focussedZoneConnectionSubject.asObservable();
  private set focussedZoneConnection(zoneConnection: ZoneConnection){
    if (this._focussedZoneConnectionSubject.value != zoneConnection){

      if (this.focussedZoneConnection != null && this.canShowLoggedOutStatus$.value){
        //only show logged out status for first zoneConnection in view. Once we scroll to another zone -> do not show it again
        this.canShowLoggedOutStatus$.next(false);
      }

      this._focussedZoneConnectionSubject.next(zoneConnection);

      const currentFocussedZoneConnection = this.focussedZoneConnection;
      if (currentFocussedZoneConnection){

        currentFocussedZoneConnection.errorMessage$
        .pipe(
          takeUntil(
            merge(
              this.destroyed$,
              this.focussedZoneConnection$.pipe(filter(zoneConnection => zoneConnection != currentFocussedZoneConnection))
            )
          )
        )
        .subscribe(
          (error) => {
            this.generalError$.next(error);
          }
        )
      }
    }
  }
  private get focussedZoneConnection(){
    return this._focussedZoneConnectionSubject.value;
  }

  private dummyApplicationInfoSubject = new BehaviorSubject<ExternalApplicationInfo>(null);
  public get externalApplicationInfo$(){
    return this.focussedZoneConnection$
      .pipe(
        switchMap(
          (zoneConnection) => {
            return zoneConnection != null ?
              zoneConnection.externalApplicationsInfo.externalApplicationsInfo$.pipe(
                map(
                  (appInfos) => {
                    if (appInfos != null){
                      return appInfos.find(appInfo => appInfo.connectionType == ConnectionType.player)
                    }
                    return null;
                  }
                )

              )
              : this.dummyApplicationInfoSubject.asObservable();
          }
        )
      )
  }

  private dummyShowBuySubscriptionSubject = new BehaviorSubject<Boolean>(false);
  public get showBuySubscription$(){
    return this.focussedZoneConnection$
      .pipe(
        switchMap(
          (zoneConnection) => {
            return zoneConnection != null ?
              zoneConnection.showSubscriptionLink$
              : this.dummyShowBuySubscriptionSubject.asObservable();
          }
        )
      )
  }


  private get currentFocussedExternalApplicationInfo$(){
    return this.focussedZoneConnection$.pipe(map(zoneConnection => zoneConnection != null ? zoneConnection.externalApplicationsInfo.externalApplicationsInfo$: null));
  }

  public zoneConnectionState$ = new BehaviorSubject<ZoneConnectionsState>(ZoneConnectionsState.none);
  private set zoneConnectionState(zoneConnectionState: ZoneConnectionsState){
    if (zoneConnectionState != this.zoneConnectionState$.value){
      this.zoneConnectionState$.next(zoneConnectionState);
    }
  }

  private canShowLoggedOutStatus$ = new BehaviorSubject<boolean>(true);

  public get connecting$(){
    return this.zoneConnectionService.connectingZoneConnection$
      .pipe(map(zoneConnection => zoneConnection != null))
  }

  public get creatingZoneConnection$(){
    return combineLatest([this.connecting$, this.zoneConnectionService.creatingZoneConnection$])
    .pipe(map(([connecting, creating]) => !connecting && creating));
  }

  public get disconnected$(){
    return combineLatest([this.connecting$, this.zoneConnectionService.creatingZoneConnection$, this.zoneConnectionService.lastActivePlayerZoneConnection$, this.canShowLoggedOutStatus$])
    .pipe(map(([connecting, creating, lastActivePlayerZoneConnection, canShowLoggedOutStatus]) => !connecting && !creating && canShowLoggedOutStatus && lastActivePlayerZoneConnection != null));
  }




  private _connectNewZone = false;
  get connectNewZone(){
    return this._connectNewZone;
  }

  get zoneConnections$(){
    return this.zoneConnectionService.zoneConnections$;
  }



  get heightPerItem$(){
    return this.appService.heightPerItem$;
  }



  get gridWidth$(): Observable<number>{
    return combineLatest([this.amountOfGridColumns$, this.itemWidth$])
      .pipe(
        map(([amountOfColumns, itemWidth]) => {
          //console.log("Going to change: itemWidth: " + itemWidth + " , amountOfItems: " + amountOfItems + " -> " + itemWidth * amountOfItems);
          return itemWidth * amountOfColumns
        })
      );
    /*
    this.itemWidth$
    .pipe(
      merge(this.zoneConnectionsAmount$),
      map(([itemWidth, amountOfItems]) => itemWidth * amountOfItems)
    );
    */
  }

  public get amountOfGridColumns$():Observable<number>{
    return combineLatest([this.zoneConnectionsAmount$, this.maxColumns$])
    .pipe(
      map(([amount, maxColumns]) => Math.max(1, Math.min(maxColumns, amount)))
    )
  }

  /*
  private get maxColumns$(): Observable<number>{
    return this.appService.availableWidthForPlayer$
    .pipe(
      map(width => Math.max(3, Math.floor(width / 300)))
    )
  }
  */

  private get zoneConnectionsAmount$(): Observable<number>{
    return this.zoneConnectionService.zoneConnections$
      .pipe(
        map(zoneConnections => zoneConnections.length)
      );
  }

  get itemWidth$(): Observable<number>{
    return this.appService.widthForMenuPanel$
            .pipe(
              map(width => Math.max(width, 200))
            );
  }

  constructor(
    private zoneConnectionService: ZoneConnectionsService,
    private appService: AppService,
    private externalLinksService: ExternalLinksService
  ){
    if (this.zoneConnectionService.zoneConnections.length == 0){
      this._connectNewZone = true;
    }

    this.zoneConnectionService.zoneConnectionAdded$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ()=>{
        this._connectNewZone = false;
      }
    );

    this.zoneConnectionService.creatingZoneConnectionError$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (error) => {
        this.generalError$.next(error);
      }
    );

    this.zoneConnectionService.zoneConnections$
    .pipe(
      delay(100),
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.calcCurrentVisibleElement();
        this.determineFocussedZoneConnection();
      }
    );
  }

  private determineFocussedZoneConnection(){
    if (this.zoneConnectionService.zoneConnections != null && this.zoneConnectionService.zoneConnections.length > 0 && this.visibleZoneConnectionIndex >= 0 && this.visibleZoneConnectionIndex < this.zoneConnectionService.zoneConnections.length){
      this.focussedZoneConnection = this.zoneConnectionService.zoneConnections[this.visibleZoneConnectionIndex];
    }else{
      this.focussedZoneConnection = null;
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.startScrollDetection();
    this.calcCurrentVisibleElement();
  }

  private maxColumns$ = new BehaviorSubject<number>(1);

  public onResize(event:ResizedEvent){
    const viewRect = this.zoneConnectionsView.nativeElement.getBoundingClientRect();

    if (viewRect.height > 0){
      this.appService.adjustPlayerSizeToHeight(viewRect.height);
      this.appService.adjustPlayerSizeToWidth(viewRect.width);
    }
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    this._focussedZoneConnectionSubject.complete();
  }

  public connectNew(){
    this._connectNewZone = true;
  }

  public onCloseConnectNew(){
    this._connectNewZone = false;
    if (this.focussedZoneConnection != null){
      this.generalError$.next(this.focussedZoneConnection.errorMessage);
    }

  }

  /**
   * Scrolling detection
   */

  //Timer, used to detect whether horizontal scrolling is over
  private timer = null;
  //Scrolling event start
  private startScrollDetection(){
    this.scrollableZoneContainer.nativeElement.addEventListener('scroll', () => {
      clearTimeout(this.timer);
      //Renew timer
      this.timer = setTimeout(() => {
        this.scrollingDidEnd();
      }, 100);
    });
  }

  private scrollingDidEnd() {
    this.calcCurrentVisibleElement();
  }

  private visibleZoneConnectionIndex = -1;
  private calcCurrentVisibleElement() {
    //mid of scrollContainer:
    var mid = this.scrollableZoneContainer.nativeElement.getBoundingClientRect().width / 2;

    this.visibleZoneConnectionIndex = -1;
    [].slice.call(this.scrollableZoneContainer.nativeElement.children).forEach((ele, index) => {

      if (ele.getBoundingClientRect().left < mid &&ele.getBoundingClientRect().right > mid){
        //console.log("Element " + index + " is showing");
        this.visibleZoneConnectionIndex = index;
        this.determineFocussedZoneConnection();
      }
    });
  }

  public scrollLeft(){
    if (this.scrollableZoneContainer.nativeElement.children != null && this.scrollableZoneContainer.nativeElement.children.length > 0){
      let element = this.scrollableZoneContainer.nativeElement.children[0];
      let withMinusLargestPadding = this.getScrollingWidthForElement(element);
      this.scrollableZoneContainer.nativeElement.scroll({left: this.scrollableZoneContainer.nativeElement.scrollLeft - withMinusLargestPadding, behavior: 'smooth'});
    }
  }

  public scrollRight(){
    if (this.scrollableZoneContainer.nativeElement.children != null && this.scrollableZoneContainer.nativeElement.children.length > 0){
      let element = this.scrollableZoneContainer.nativeElement.children[0];
      let withMinusLargestPadding = this.getScrollingWidthForElement(element);
      this.scrollableZoneContainer.nativeElement.scroll({left: this.scrollableZoneContainer.nativeElement.scrollLeft + withMinusLargestPadding, behavior: 'smooth'});
    }
  }

  private getScrollingWidthForElement(element: any): number{

      let compytedStyle = getComputedStyle(element);
      let elementWidth = element.clientWidth;
      //only allow the minimum padding to be calculated in the scroll distance
      let withMinusLargestPadding = elementWidth - Math.max(parseFloat(compytedStyle.paddingLeft), parseFloat(compytedStyle.paddingRight));
      return withMinusLargestPadding;
  }


  public onBuyClick(){
    this.externalLinksService.openZoneBuyPage();
  }

}
