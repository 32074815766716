import { Component } from '@angular/core';

@Component({
  selector: 'tun-refresh-icon-v5',
  templateUrl: './refresh-icon-v5.component.html',
  styleUrls: ['./refresh-icon-v5.component.scss']
})
export class RefreshIconV5Component {

}
