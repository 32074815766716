import { Injectable, OnDestroy } from '@angular/core';
import { setUserId, Analytics, logEvent } from '@angular/fire/analytics';
import { datadogRum } from '@datadog/browser-rum';
import { AppVersionService, MAJOR_VERSION } from '@service/app-version.service';
import { ApplicationMode, ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { LoggerService } from '@service/loggers/logger.service';
import { PlayTokenService } from '@service/play-token.service';
import { RecommendationsService } from '@service/recommendations.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { version } from 'process';

@Injectable({
  providedIn: 'root'
})
export class RealUserMonitorService implements OnDestroy  {

  private LOGGER_CLASSNAME = RealUserMonitorService.name;

  constructor(
    private analytics: Analytics,
    private zoneConnectionsService: ZoneConnectionsService,
    private recommendationsService: RecommendationsService,
    private appVersionService: AppVersionService,
    private loggerService: LoggerService,
  ) {

    datadogRum.init({
      applicationId: '675a70da-749f-4796-aae6-4e92709b5392',
      clientToken: 'pub485257ae70bb665fe38970a5ba127c54',
      site: 'datadoghq.eu',
      service:'tunify-html-player',
      env: environment.name,
      version: environment.VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'allow'
  });


  datadogRum.startSessionReplayRecording();

    this.zoneConnectionsService.activeZoneConnection$
    .pipe(
      takeUntil(
        this.destroy$
      )
    )
    .subscribe(
      (zoneConnection) => {

        if (zoneConnection != null){
          this.loggerService.debug(this.LOGGER_CLASSNAME, "zoneConnectionChanged", "Setting userId to " + zoneConnection.zoneId);
          //firebase analytics
          setUserId(this.analytics, ""+zoneConnection.zoneId);
          //datadog
          datadogRum.setUser({"id": ""+zoneConnection.zoneId})
        }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, "zoneConnectionChanged", "No zoneConnection: Keeping last id");
        }
      }
    );

    combineLatest([this.zoneConnectionsService.activeZoneConnection$, this.appVersionService.appVersionToUse$])
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      ([activeZoneConnection, appVersionToUse]) => {
        if (activeZoneConnection != null && appVersionToUse != MAJOR_VERSION.STARTING){
          datadogRum.setUserProperty('playerVersion', this.appVersionService.version);
        }
      }
    )

    /*
    this.appVersionService.version$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (value) => {
          datadogRum.setUserProperty('PlayerVersion', value);
        }
      );
        */

    //watch the recommendations to set the user properties
    recommendationsService.recommendedMusicChannels$
    .pipe(
      takeUntil(
        this.destroy$
      )
    )
    .subscribe(
      (recommendedMusicChannels) => {
        //set data when a zoneConnection is active
        if (zoneConnectionsService.activeZoneConnection != null){
          datadogRum.setUserProperty('recommendedMusicChannels', recommendedMusicChannels.length);
        }
      }
    );

    zoneConnectionsService.applicationMode$
    .pipe(
      takeUntil(
        this.destroy$
      )
    )
    .subscribe(
      (applicationMode) => {
        //set data when a zoneConnection is active
        if (applicationMode == ApplicationMode.playerMode){
          datadogRum.setUserProperty('applicationMode', 'player');
        }else if (applicationMode == ApplicationMode.remoteMode){
          datadogRum.setUserProperty('applicationMode', 'remote');
        }
      }
    );

  }


  public logEvent(eventGroup: String, eventName: String, eventParams?: {[key: string]: string;}){
    //firebase analytics
    logEvent(this.analytics, eventGroup + "_" + eventName, eventParams);

    //datadog
    datadogRum.addAction(eventGroup + "_" + eventName, eventParams);

    //own logging
    let logString = "eventGroup: " + eventGroup + " -- eventName: " + eventName + " -- ";
    let spacer = "";
    for (const key in eventParams) {
      const value = eventParams[key];
      logString = logString + spacer + key + ": " + value;
      spacer = ", ";
  }
    this.loggerService.debug(this.LOGGER_CLASSNAME, "logEvent", logString);
  }



  /**
   * Angular needs a reference to the service, we do this with a simple call
   */
  public bootService(){

  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(){
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = null;
  }

}
