import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ZoneConnectionsService } from '../../authentication/zone-connections.service';
import { BYPASS_ACCESSTOKEN } from './authentication-interceptor';

/**
 * This interceptor is responsible for detecting when an response indicates that an access token is no longer valid
 */
@Injectable()
export class InvalidAccessTokenInterceptor implements HttpInterceptor {

  constructor(
    private zoneConnectionsService: ZoneConnectionsService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    //do not handle request where we bypass the accessToken mechanism
    if (req.context.get(BYPASS_ACCESSTOKEN) === true)
      return next.handle(req);

    //we don't want to catch any invalid access token request for
    // - our old SOAP webservices
    // - authentication request & refresh access token request
    // - when we mock our backend
    // - when the request does not use an authorization header
    if (environment.mockBackend ||
        req.url.includes("blackbox2/services/")
        || req.url.includes("/oauth/token")
        || !req.headers.has("Authorization")
        ){
            return next.handle(req);
    }else{
        //read the accessToken from the Authorization headers request and invalidate only this one
        let accessToken = req.headers.get("Authorization").replace("Bearer ", "");
        return next.handle(req).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    //console.log('interceptor fired!')
                },
                err => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {

                          this.zoneConnectionsService.invalidateAccessToken(accessToken);
                          //console.log('401 detected');
                        }
                    }
                }
            )
        );
    }

  }
}
