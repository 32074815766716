<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 20 20" enable-background="new 0 0 20 20" xml:space="preserve">
   <defs>
    <path id="path-chat-def" d="M4.53751817,11.21 C4.68451817,11.21 4.83051817,11.251 4.95951817,11.331 C5.64751817,11.758 6.43851817,12.024 7.24551817,12.101 C8.05351817,12.175 8.87851817,12.063 9.63351817,11.77 C10.3895182,11.479 11.0785182,11.008 11.6245182,10.409 C12.1715182,9.81 12.5775182,9.082 12.7985182,8.302 C13.0205182,7.522 13.0585182,6.689 12.9105182,5.893 C12.7605182,5.096 12.4235182,4.334 11.9345182,3.687 C11.4455182,3.041 10.8035182,2.509 10.0775182,2.149 C9.35351817,1.79 8.54451817,1.6 7.73651817,1.6 L7.72151817,1.6 C6.78551817,1.6 5.86551817,1.85 5.05951817,2.323 C4.25151817,2.797 3.58451817,3.48 3.12851817,4.299 C2.67351817,5.118 2.44451817,6.045 2.46751817,6.981 C2.49051817,7.918 2.76451817,8.834 3.25951817,9.628 C3.40351817,9.86 3.41951817,10.149 3.30251817,10.395 L2.49151817,12.098 L4.19351817,11.288 C4.30251817,11.235 4.42051817,11.21 4.53751817,11.21 M0.799518168,14.589 C0.592518168,14.589 0.387518168,14.509 0.233518168,14.356 C-0.00548183168,14.116 -0.0674818317,13.752 0.0775181683,13.446 L1.67751817,10.087 C1.17251817,9.145 0.894518168,8.093 0.867518168,7.021 C0.837518168,5.799 1.13651817,4.589 1.73051817,3.521 C2.32551817,2.453 3.19651817,1.562 4.24951817,0.943 C5.30051817,0.326 6.50051817,0 7.71951817,0 C7.72551817,0 7.73251817,0 7.73951817,0 C8.79051817,0 9.84551817,0.247 10.7885182,0.716 C11.7345182,1.185 12.5725182,1.879 13.2105182,2.722 C13.8485182,3.565 14.2875182,4.56 14.4825182,5.598 C14.6775182,6.637 14.6265182,7.723 14.3375182,8.74 C14.0485182,9.756 13.5185182,10.707 12.8065182,11.487 C12.0935182,12.269 11.1955182,12.882 10.2105182,13.263 C9.22451817,13.644 8.14551817,13.794 7.09551817,13.693 C6.19151817,13.608 5.30351817,13.34 4.50251817,12.913 L1.14351817,14.512 C1.03351817,14.564 0.916518168,14.589 0.799518168,14.589"></path>
</defs>
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect x="0" y="0" width="20" height="20"></rect>
    <g transform="translate(3, 3)">
        <mask id="mask-chat" fill="white">
            <use xlink:href="#path-chat-def"></use>
        </mask>
        <use fill="currentColor" xlink:href="#path-chat-def"></use>
        <rect id="Icon-color" fill="currentColor" mask="url(#mask-chat)" x="-1" y="-1" width="16" height="16"></rect>
    </g>
</g>
</svg>
