import { ChangeableParameter } from './changeableParameter';
import { createClassObjectForChangeableParameter } from '@service/util/changeableParameterUtil';
import { Day } from './day';
import { TweakInfo, TweakInfoHolder } from './tweakInfo';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

export class CalendarItem implements TweakInfoHolder{
    id: number;
    title: string;
    musicChannelId: number;
    musicCollectionId: number;
    changeableParameter: ChangeableParameter[];
    defaultChangeableParameter: ChangeableParameter[];
    calendarId: number;
    cronString: string;
    position: number;
    shufflePlaylist: boolean;

    //changeable parameters loaded
    private changeableParametersLoadedSubject = new BehaviorSubject<boolean>(false);
    public changeableParametersLoaded$ = this.changeableParametersLoadedSubject.asObservable();
    public set changeableParametersLoaded(value: boolean){
        if (this.changeableParametersLoaded != value){
            this.changeableParametersLoadedSubject.next(value);
        }
    }
    public get changeableParametersLoaded():boolean{
        return this.changeableParametersLoadedSubject.value;
    }

    //changeable parameters loading
    private changeableParametersLoadingSubject = new BehaviorSubject<boolean>(false);
    public changeableParametersLoading$ = this.changeableParametersLoadingSubject.asObservable();
    public set changeableParametersLoading(value: boolean){
        if (this.changeableParametersLoading != value){
            this.changeableParametersLoadingSubject.next(value);
        }
    }
    public get changeableParametersLoading():boolean{
        return this.changeableParametersLoadingSubject.value;
    }

    //changeable parameters loading error
    private changeableParametersLoadingErrorSubject = new BehaviorSubject<boolean>(false);
    public changeableParametersLoadingError$ = this.changeableParametersLoadingErrorSubject.asObservable();
    public set changeableParametersLoadingError(value: boolean){
        if (this.changeableParametersLoadingError != value){
            this.changeableParametersLoadingErrorSubject.next(value);
        }
    }
    public get changeableParametersLoadingError():boolean{
        return this.changeableParametersLoadingErrorSubject.value;
    }

    //changing this property should trigger change detection in our views
    private _duration: number;
    public set duration(value: number){
        if (this._duration != value){
            this._duration = value;
            this.timingForItemChangedSubject.next(true);
        }
    }
    public get duration():number{
        return this._duration;
    }

    //additional properties (not stored server side, used in to represent the data in our views)
    day: Day;
    private _startHour: number;
    public set startHour(value: number){
        if (this._startHour != value){
            this._startHour = value;
            this.timingForItemChangedSubject.next(true);
        }
    }
    public get startHour():number{
        return this._startHour;
    }
    private _startMinutes: number;
    public set startMinutes(value: number){
        if (this._startMinutes != value){
            this._startMinutes = value;
            this.timingForItemChangedSubject.next(true);
        }
    }
    public get startMinutes():number{
        return this._startMinutes;
    }

    //an observable that fires whenever the timing (startHour, startMinutes or duration) changes for this calendarItem
    private timingForItemChangedSubject: Subject<boolean> = new Subject<boolean>();
    public timingForItemChanged$: Observable<boolean> = this.timingForItemChangedSubject.asObservable();


    private _creating: boolean;
    public set creating(value: boolean){
        this._creating = value;
        this.performingActionChangedSubject.next(true);
    }
    public get creating():boolean{
        return this._creating;
    }

    private _saving: boolean;
    public set saving(value: boolean){
        this._saving = value;
        this.performingActionChangedSubject.next(true);
    }
    public get saving():boolean{
        return this._saving;
    }

    private _removing: boolean;
    public set removing(value: boolean){
        this._removing = value;
        this.performingActionChangedSubject.next(true);
    }
    public get removing():boolean{
        return this._removing;
    }

    //an observable that fires whenever some action states change (creating - saving - removing)
    private performingActionChangedSubject: Subject<boolean> = new Subject<boolean>();
    public performingActionChanged$: Observable<boolean> = this.performingActionChangedSubject.asObservable();

    //saved properties (needed to turn back to what is stored when a save request fails)
    storedCronString: string;
    storedDuration: number;
    storedPosition: number;

    //tweakInfoHolder properties
    get contextId():number{
        return this.musicCollectionId;
    }
    get changeableParameters(): ChangeableParameter[]{
        return this.changeableParameter;
    }

    //tweak info properties
    tweakInfo: TweakInfo;

    tweakInfoLoaded;

    private _tweakInfoLoadingSubject = new BehaviorSubject<boolean>(false);
    tweakInfoLoading$ = this._tweakInfoLoadingSubject.asObservable();
    set tweakInfoLoading(value: boolean){
        this._tweakInfoLoadingSubject.next(value);
    }
    get tweakInfoLoading():boolean{
        return this._tweakInfoLoadingSubject.value;
    }

    tweakInfoLoadingError;


    constructor(calendarItem?: CalendarItem){
        if (calendarItem){
            this.id = calendarItem.id;
            this.title = calendarItem.title;
            this.musicChannelId = calendarItem.musicChannelId;
            this.musicCollectionId = calendarItem.musicCollectionId;

            if (calendarItem.changeableParameter){
                this.changeableParameter = [];
                calendarItem.changeableParameter.map(changeableParam => this.changeableParameter.push(createClassObjectForChangeableParameter(changeableParam)));
                this.changeableParametersLoaded = true
            }else{
              this.changeableParametersLoaded = false
            }
            if (calendarItem.defaultChangeableParameter){
                this.defaultChangeableParameter = [];
                calendarItem.defaultChangeableParameter.map(changeableParam => this.defaultChangeableParameter.push(createClassObjectForChangeableParameter(changeableParam)));
            }

            this.calendarId = calendarItem.calendarId;
            this.cronString = calendarItem.cronString;
            this.duration = calendarItem.duration;
            this.position = calendarItem.position;
            this.shufflePlaylist = calendarItem.shufflePlaylist;

            this.day = calendarItem.day;
            this.startHour = calendarItem.startHour;
            this.startMinutes = calendarItem.startMinutes;

            this.tweakInfo = calendarItem.tweakInfo;
            this.tweakInfoLoaded = calendarItem.tweakInfoLoaded;
            //tweakInfo: don't take over the loading or loadingError

            //creating - saving - deleting -> don't take over this state


        }
    }
}
