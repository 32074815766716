import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicChannel } from 'src/app/model/musicChannel';

@Injectable({
  providedIn: 'root'
})
export class ActiveStreamServiceService {

  private LOGGER_CLASSNAME = 'ActiveStreamServiceService';

  constructor(private loggerService: LoggerService) { }

  
}
