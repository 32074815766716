import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PlayerV5Component } from './player-v5.component';
import { PlayerV5Guard } from './player-v5.guard';

@NgModule({
  imports: [
      RouterModule.forChild([
          { path: 'player-v5', component: PlayerV5Component, canDeactivate: [PlayerV5Guard], canActivate: [PlayerV5Guard]}
      ])
  ],
  exports: [
      RouterModule
  ]
})
export class PlayerV5RoutingModule { }
