import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
  keyframes,
} from '@angular/animations';
import { PopupDirection } from './popup/enums';
import { PopupArrowComponent } from './popup-arrow/popup-arrow.component';

export function getSlideAnimation(
  popupDirection: PopupDirection,
  open: boolean,
  duration: number,
  showPopupArrow: boolean
) {
  const animationTransition = `${duration}ms ease-out`;
  let arrowOffset = showPopupArrow
    ? PopupArrowComponent.arrowSize - PopupArrowComponent.ARROW_OFFSET - 1
    : 0;
  let translateOffset = 100;

  if (popupDirection === PopupDirection.UP) {
    arrowOffset *= -1;
  } else {
    translateOffset *= -1;
  }

  return open
    ? [
        style({}),
        animate(
          animationTransition,
          keyframes([
            style({
              transform: `translateY(${translateOffset}%)`,
              offset: 0,
            }),
            style({
              transform: `translateY(0px)`,
              offset: 0.9,
            }),
            style({
              transform: `translateY(${arrowOffset}px)`,
              offset: 1,
            }),
          ])
        ),
      ]
    : [
        style({}),
        animate(
          animationTransition,
          keyframes([
            style({
              transform: `translateY(${arrowOffset}px)`,
              offset: 0,
            }),
            style({
              transform: `translateY(0px)`,
              offset: 0.1,
            }),
            style({
              transform: `translateY(${translateOffset}%)`,
              offset: 1,
            }),
          ])
        ),
      ];
}

/** Returns animation data to slide an element in to the right when it enters the DOM
 * @param duration The duration of the animation in milliseconds, default is 300
 */
export function slideInRight(duration = 300): AnimationTriggerMetadata {
  return trigger('slideInRight', [
    state('void', style({ transform: 'translateX(100%)' })),
    state('*', style({ transform: 'translateX(0)' })),
    transition(':enter, :leave', animate(duration)),
  ]);
}

/** Returns animation data to slide an element in to the left when it enters the DOM
 * @param duration The duration of the animation in milliseconds, default is 300
 */
export function slideInLeft(duration = 300): AnimationTriggerMetadata {
  return trigger('slideInLeft', [
    state('void', style({ transform: 'translateX(-100%)' })),
    state('*', style({ transform: 'translateX(0)' })),
    transition(':enter, :leave', animate(duration)),
  ]);
}
