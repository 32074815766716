<div #progressContainer class="fullContainer {{(adjustable$ | async)?'showCursor':''}}" (click)="onClick($event)">

  <div class="lines {{colorString$ | async}}" data-dd-action-name="trackProgressBar">

    <div
      class="line unselected-line"
    ></div>
    <div
      class="line selected-line"
      [style.right.%]="100 - (progressPercentage$ | async)"
    ></div>
</div>

<div class="timeLabels">
  <div id="timeLabel1" class="timeLabel leftLabel {{colorString$ | async}}">
    <span>{{time$ | async}}</span>
  </div>


  <div id="timeLabel2" class="timeLabel rightLabel">
    {{timeLeft$ | async}}
  </div>
</div>


</div>
