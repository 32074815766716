<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
   <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(7.000000, 1.000000)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <circle cx="17" cy="29" r="2"></circle>
        <line x1="17" y1="31" x2="17" y2="37"></line>
        <rect x="0" y="18" width="34" height="28" rx="2"></rect>
        <path d="M6,11 C6,4.92486775 10.9248678,0 17,0 C23.0751322,0 28,4.92486775 28,11 L28,18 L6,18 L6,11 Z"></path>
      </g>
  </g>
</svg>
