import { Component, Input } from '@angular/core';

@Component({
  selector: 'tun-close-icon',
  templateUrl: './close-icon.component.html',
  styleUrls: ['./close-icon.component.scss']
})
export class CloseIconComponent {

  @Input() color: string;

  constructor() { }

}
