<div  #zoneConnectionsView (tunResize)="onResize($event)" class="main-container playerBackground">



  <div
    #loginContainer
    *ngIf="connectNewZone"
    class="login-container"
  >
    <tun-connect-zone-view
      class="connect-zone-view"
      (close)="onCloseConnectNew()"
    >
    </tun-connect-zone-view>
  </div>

  <div
    #zoneConnectionsContainer
    class="zone-connections-container"
  >

    <div class="scrollContainer">

        <div
          class="fade-borders"
          [style.height.px]="(heightPerItem$ | async)"
          [style.padding-top.px]="2 * (heightPerItem$ | async)"
        >
          <tun-tunify-icon></tun-tunify-icon>
        </div>

        <div class="status fade-borders">
          <label *ngIf="(disconnected$ | async)" translate>zoneConnections.status.loggedOut</label>
          <tun-loader *ngIf="(creatingZoneConnection$ | async)" text="connectZone.busy"></tun-loader>
          <tun-loader *ngIf="(connecting$ | async)" text="login.busy"></tun-loader>
        </div>

        <div class="errorFeedback fade-borders"
          *ngIf="(generalError$ | async) != null"
          fxLayout="column"
        >
          <label *ngIf="(generalError$ | async) != null" translate>{{generalError$ | async}}</label>
          <button *ngIf="(showBuySubscription$ | async)" class="link-button" (click)="onBuyClick()" translate>
              settings.option.buy
          </button>
        </div>

        <div
          class="errorFeedback fade-borders"
        >
          <label *ngIf="(externalApplicationInfo$ | async) != null" translate>zoneConnections.inUse</label>
          <label class="errorFeedbackColoredPart" *ngIf="(externalApplicationInfo$ | async) != null">{{(externalApplicationInfo$ | async).deviceInfo}}</label>
        </div>

        <div class="flex-column">

          <div class="layerContainer zone-connections-layer-container">


            <div #scrollableZoneContainer class="layer scroll-container">



              <div *ngFor="let zoneConnection of zoneConnections$ | async; let i = index;"
                class="zone-connection-view {{i == 0 ? 'first':''}} {{i == (zoneConnections$ | async).length -1 ? 'last':''}}">

              <tun-zone-connection-view
                [zoneConnection]="zoneConnection"
              >
              </tun-zone-connection-view>

            </div>

          </div>


          <div *ngIf="(zoneConnections$ | async).length > 1" class="layer noPointer fade-borders">
              <div style="float: left;">
                <button
                (click)="scrollLeft()"
                class="button-transparent {{visibleZoneConnectionIndex > 0 ? '' : 'button-scroll-disabled'}}">
                <fa-icon
                  class="button-item-icon"
                  size="lg"
                  [icon]="faAngleLeft"
                ></fa-icon>
                </button>
              </div>
              <div style="float: right;">
                <button
                (click)="scrollRight()"
                class="button-transparent {{visibleZoneConnectionIndex < (zoneConnections$ | async).length - 1 ? '' : 'button-scroll-disabled'}}">
                <fa-icon
                  class="button-item-icon"
                  size="lg"
                  [icon]="faAngleRight"
                ></fa-icon>
              </button>
              </div>


          </div>
        </div>


        </div>

        <div class="connect-new-container fade-borders">
          <button class="connect-button" [style.min-height.px]="1.5 * (heightPerItem$ | async)" (click)="connectNew()" translate>zoneConnections.connectNew</button>
        </div>

        <div class="flex-column fade-borders"></div>

        <div class="info-container fade-borders" translate>
          connectZone.info.contact
        </div>

      </div>
    </div>
</div>



