import { Component } from '@angular/core';

@Component({
  selector: 'tun-menu-search-icon-v5',
  templateUrl: './menu-search-icon-v5.component.html',
  styleUrls: ['./menu-search-icon-v5.component.scss']
})
export class MenuSearchIconV5Component {

}
