import { Component } from '@angular/core';

@Component({
  selector: 'tun-zone-icon-v5',
  templateUrl: './zone-icon-v5.component.html',
  styleUrls: ['./zone-icon-v5.component.scss']
})
export class ZoneIconV5Component {

}
