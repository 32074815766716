import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@service/authentication.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject, Subscription, timer, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { LoggerService } from '../loggers/logger.service';
import { AppVersionService } from '@service/app-version.service';
import { Intercom } from '@supy-io/ngx-intercom';

@Injectable({
  providedIn: 'root'
})
export class IntercomService implements OnDestroy {

  //for testing -> fast ping
  //private readonly INTERCOM_PING_INTERVAL_MS = 60000; //every 60 seconds
  private readonly INTERCOM_PING_INTERVAL_MS = 300000; //every 5 minutes

  private LOGGER_CLASSNAME = IntercomService.name;

  private unreadMessagesSubject = new BehaviorSubject<number>(0);
  public unreadMessages$ = this.unreadMessagesSubject.asObservable();


  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private zoneConfigurationService:ZoneConfigurationService,
    private intercom: Intercom,
    private appService: AppService,
    private loggerService: LoggerService,
    private appVersionService: AppVersionService
  ) {
    if (environment.enableIntercom){

      this.zoneConnectionsService.activeZoneConnection$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.adjustUseZoneForIntercom();
          this.adjustIntercom();
        }
      );

      this.zoneConfigurationService.intercomUserHash$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.adjustUseZoneForIntercom();
        }
      );

      //there is no unhook method for this. That is why we pass the unread count first to our own observable.
      this.intercom.onUnreadCountChange(count => {
        if (this.destroyed$ != null){ //update our value for as long as we are not destroyed
          this.unreadMessagesSubject.next(count);
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, 'unreadCountChanged', 'but the service is already destroyed. We should somehow try to unhook the function with a reference to this');
        }
      });


      this.appVersionService.version$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          this.intercom.update(
            {
              'Player Version': 'Html V' + value
            }
          );
        }
      );
    }
  }

  public bootService(){
    /**
     * we need to use this service somewere before it gets loaded by angular
     * Injection in another service/view is enough, but we provide a method call so it will not be cleaned up by accident (without a call it looks like an unnecessary import/injection)
    */
     this.adjustSubscriptionInfoPolling();
  }


  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next(null);
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private adjustUseZoneForIntercom(){
    this.useZoneForIntercomConnection = this.zoneConnectionsService.activeZoneConnection != null && this.zoneConfigurationService.intercomUserHash != null;
  }

  private _useZoneForIntercomConnection = false;
  private set useZoneForIntercomConnection(value: boolean){
    if (this._useZoneForIntercomConnection != value){
      this._useZoneForIntercomConnection = value;
      this.adjustIntercom()
    }
  }
  private get useZoneForIntercomConnection():boolean{
    return this._useZoneForIntercomConnection;
  }

  private registeredZoneForIntercom : number = null;
  private adjustIntercom(){
    if (environment.enableIntercom){
      if (this.useZoneForIntercomConnection){

        //log out if the wrong zone is connected
        if (this.registeredZoneForIntercom != null && this.registeredZoneForIntercom != this.zoneConnectionsService.activeZoneConnection.zoneId){
          this.loggerService.warn(this.LOGGER_CLASSNAME, 'adjustIntercom', 'We have the wrong zoneConnection (' + this.registeredZoneForIntercom + ' instead of ' + this.zoneConnectionsService.activeZoneConnection.zoneId + ') -> logging out from intercom before logging in');
          this.intercom.shutdown();
          this.registeredZoneForIntercom = null;
        }

        //connect zone if none is connected by now
        if (this.registeredZoneForIntercom == null){

          this.intercom.boot({
            app_id: environment.intercomId,
            custom_launcher_selector:'#messagesButton',

            alignment: 'right',      //top does not work, only left and right
            horizontal_padding: 20,
            vertical_padding: this.appService.heightForBottomBar + 10 ,

            user_id: '' + this.zoneConnectionsService.activeZoneConnection.zoneId,
            user_hash: this.zoneConfigurationService.intercomUserHash,

            // Supports all optional configuration.
            /*
            widget: {
              "activator": "#intercom"
            }
            */
          });

          this.intercom.update(
            "{'Player Version': 'Html V' + " + this.appVersionService.version + "}"
          )



          this.registeredZoneForIntercom = this.zoneConnectionsService.activeZoneConnection.zoneId;
        }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustIntercom', 'zoneConnection ' + this.registeredZoneForIntercom + ' already used for intercom connection');
        }
      }else{
        //log out if the wrong zone is connected
        if (this.registeredZoneForIntercom != null){
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustIntercom', 'Going to disconnect ' + this.registeredZoneForIntercom + '.');
          this.intercom.shutdown();
          this.registeredZoneForIntercom = null;

          //start an anonymous visitor mode intercom session
          this.intercom.boot({
            app_id: environment.intercomId,
            custom_launcher_selector:'#messagesButton',

            alignment: 'right',      //top does not work, only left and right
            horizontal_padding: 20,
            vertical_padding: this.appService.heightForBottomBar + 10 ,
          });

          this.intercom.update(
            "{'Player Version': 'Html V' + " + this.appVersionService.version + "}"
          )

        }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustIntercom', 'No zone connected to intercom -> nothing to do.');
        }
      }
    }
  }

  private pollingSubscription: Subscription;
  private adjustSubscriptionInfoPolling(){
    if (environment.enableIntercom){
      if (this.pollingSubscription == null){
        //start timer
        this.pollingSubscription = timer(0, this.INTERCOM_PING_INTERVAL_MS)
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(
            () => {
              this.pingIntercom();
            }
          );
      }
    }else{
      if (this.pollingSubscription){
        this.pollingSubscription.unsubscribe();
        this.pollingSubscription = null;
      }
    }

  }

  private pingIntercom(){
    this.intercom.update();
  }
}
