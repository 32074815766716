<div class="container {{open ? 'open': 'closed'}} {{(remoteMode$ | async) ? 'remote':'' }}">

  <div class="largePlayerContainer {{open ? 'open': 'closed'}}">
    <tun-large-control-panel
        class="largeControlPanel"
        [canShowTooltips]="showOpenContent && !isAnimating"
        [style.opacity]="showOpenContent?1:0"
        [style.visibility]="showOpenContent ? 'visible' : 'hidden'"
      >
      </tun-large-control-panel>
  </div>





  <div class="headerOriginInfo"
      [style.opacity]="showOpenContent?1:0">

      <tun-mini-track-origin-view class="originInfoContainer">
      </tun-mini-track-origin-view>

  </div>

  <div class="headerToggleButtonContainer">
    <button class="icon-button" (click)="onToggleOpen()">
      <tun-angle-up *ngIf="open" class="icon icon-scaled-svg-button"></tun-angle-up>
      <tun-angle-down *ngIf="!open" class="icon icon-scaled-svg-button"></tun-angle-down>
    </button>
  </div>

  <div class="largeImageContainer {{open ? 'open': 'closed'}}">
    <tun-track-origin-image-view
      class="imageContainer"
      [canShowRemoteOverlay]="showClosedContent"
    >

    </tun-track-origin-image-view>
  </div>

  <tun-progress-view class="progressContainer {{open ? 'open': 'closed'}}"
      [style.opacity]="showOpenContent?1:0"
  >

  </tun-progress-view>

  <div class="headerTrackInfo"
    [style.opacity]="showClosedContent?1:0"
    [style.visibility]="showClosedContent ? 'visible' : 'hidden'">

    <tun-mini-track-info-view class="trackInfoContainer">

    </tun-mini-track-info-view>


    <tun-toggle-play-button
      [canShowTooltips]="showClosedContent && !isAnimating"
      class="headerPlayButton"
    >
    </tun-toggle-play-button>


  </div>






</div>

