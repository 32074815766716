import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SearchType } from '@service/api/search-api.service';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { SearchTextV5Service } from '@service/app-v5/search-text-v5.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnDestroy, OnInit, AfterViewInit{

  private _canReceiveFocus = false;

  @Input()
  public set canReceiveFocus(value: boolean){
    this._canReceiveFocus = value;
    this.focusIfPossible();
  }
  public get canReceiveFocus(){
    return this._canReceiveFocus;
  }

  private componentDestroyed$ = new Subject<boolean>();

  get hasLastSearch$(){
    return this.searchTextV5Service.lastSearch$
      .pipe(
        map(
          lastSearch => {
            return lastSearch != null;
          }
        )
      )
  }

  // === ViewChildren === //
  @ViewChild('searchInput')
  searchInput: ElementRef;

  // === State === //
  private _searchTermBeforePreview: string;
  searchForm: UntypedFormGroup;

  // === Props === //
  private _previewValue?: string;

  get previewValue(): string {
    return this._previewValue;
  }

  @Input()
  set previewValue(value: string) {
    this._previewValue = value;

    // Backup the old value
    if (!this._searchTermBeforePreview && this.searchForm) {
      this._searchTermBeforePreview = this.searchForm.get('searchTerm').value;
    }

    // Set the new one without emitting the change
    if (this.searchForm) {
      // if there is a preview value, show it
      // otherwise reset the previous search term
      this.searchForm.patchValue(
        {
          searchTerm: value || this._searchTermBeforePreview
        },
        {
          emitEvent: false
        }
      );

      this._searchTermBeforePreview = value
        ? this._searchTermBeforePreview
        : null;
    }
  }

  // === Emitters === //
  // will emit when the user hits Enter.
  @Output()
  search = new EventEmitter<string>();

  // will emit every time the user hits a key different from Enter.
  @Output()
  autocomplete = new EventEmitter<string>();

  @Output() searchfocus = new EventEmitter<boolean>();
  @Output() searchescape = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private subscriptionsService: SubscriptionsService,
    private searchTextV5Service: SearchTextV5Service,
    private appV5StateService: AppV5StateService
  ) {}

  private get searchTerm() {
    return this.searchForm.get('searchTerm').value;
  }

  ngOnInit() {
    if (this.searchTextV5Service.lastSearch){
      this._searchTermBeforePreview = this.searchTextV5Service.lastSearch.text
    }

    this.buildForm();

    //special case with access rights -> also enable/disable the textInput
    this.subscriptionsService.accessRights$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(accessRights => {
        if (accessRights && accessRights.search) {
          this.searchForm.get('searchTerm').enable();
        } else {
          this.searchForm.get('searchTerm').disable();
        }
      });

    this.searchTextV5Service.searchStarts$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((searchData) => {

        /*
        if (searchData.searchType == SearchType.AUTOCOMPLETION_SEARCH_ON_GROUP || searchData.searchType == SearchType.AUTOCOMPLETION_SEARCH_ON_TITLE){
          this.previewValue = searchData.autocompletion.autocompletionText
        }
        */

        if (this.searchInput){
          this.searchInput.nativeElement.blur();
        }
      });


  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$ = null;
  }

  private _initDone = false;
  private set initDone(value: boolean){
    this._initDone = value;
    this.focusIfPossible();

  }
  private get initDone(){
    return this._initDone;
  }
  private focusIfPossible(){
    if (this.initDone && this.canReceiveFocus){
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    }
  }

  ngAfterViewInit(): void {

    //we need to set the focus after the views animation has finished
    timer(100)
    .pipe(
      takeUntil(
        this.componentDestroyed$
      )
    )
    .subscribe(
      () => {
        this.initDone = true;
      }
    )


  }

  onSearchIconClicked() {
    // Request focus on input field when the icon is clicked.
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }

    // if we have a previous search term, emit it.
    if (this.searchTerm && this.searchTerm.length) {
      this.onSearch(this.searchTerm);
    }
  }

  onSearch(value: string) {
    const trimmed = value.trim();
    if (trimmed) {
      this.search.next(trimmed);
      this.searchInput.nativeElement.blur();
    }
  }

  onEscapeSearch() {
    this.searchInput.nativeElement.blur();
    this.searchescape.emit();
  }

  private buildForm() {
    this.searchForm = this.fb.group({
      searchTerm: [this._previewValue || this._searchTermBeforePreview || '']
    });

    // listen to value changes of the search term and emit an autocomplete event.
    this.searchForm
      .get('searchTerm')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        const trimmed = value.trim();

        this.autocomplete.emit(trimmed);


        // close the preview
        this._previewValue = null;
        this._searchTermBeforePreview = null;
      });
  }

  public onRemoveLastSearch(){

    if (this.searchForm) {
      // if there is a preview value, show it
      // otherwise reset the previous search term
      this.searchForm.patchValue(
        {
          searchTerm: ''
        },
        {
          emitEvent: true
        }
      );
    }

    this._searchTermBeforePreview = null;

    this.searchTextV5Service.clearLastSearch();

    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }
}
