<div class="wrapper">

  <div class="contentWrapper {{showingPopup ? 'blurredContent' : ''}}">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <button class="icon-button first-title-item" (click)="onPlayAll()">
          <tun-play-icon-v5 class="icon colored-icon"></tun-play-icon-v5>
        </button>

        <div class="title">
          {{ searchTitle$ | async }}
        </div>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- search result content -->


      <!-- Special search states indicator -->

      <div
        *ngIf="searchState != SearchState.normal"
        [ngSwitch]="searchState"
        class="feedback-container"
      >
        <div
          *ngSwitchCase="SearchState.searchError"
          class="error-feedback-container"
        >
          <p>
            {{'search.status.failed' | translate }}
          </p>
          <button (click)="retryClick()" class="retryButton">
            <p>
              {{'general.tryAgain' | translate }}
            </p>
          </button>
        </div>

        <ng-container *ngSwitchCase="SearchState.searching">
          <tun-loader-v5 class="loader"></tun-loader-v5>
        </ng-container>

      </div>

      <div
        *ngIf="searchState == SearchState.normal"
        class="select-title"
      >
        <div class="select-front">
          <tun-checkbox-v5
          class="select-all-checkbox"
          [title]="selectTitle"
          coloredTitleOnCheck="true"
          [checkBoxColor]="TunifyCheckBoxColor.ORANGE"
          [isChecked]="allComplete"
          [indeterminate]="someComplete()"
          (isCheckedChanged)="setAll($event)"
        >
        </tun-checkbox-v5>

        <button *ngIf="checkedTracks.length != 0" class="unselect-link-button" (click)="setAll(false)">
          <p translate>
            search.result.deselect.all
          </p>
        </button>

        </div>


        <button class="icon-button colored-action-button" [disabled]="checkedTracks.length == 0" (click)="onAddSelectedToQueue()">
          <tun-add-to-queue-v5 class="icon"></tun-add-to-queue-v5>
        </button>

        <button class="icon-button colored-action-button" [disabled]="checkedTracks.length == 0" (click)="onAddSelectedToPlaylist()">
          <tun-add-to-playlist-v5 class="icon"></tun-add-to-playlist-v5>
        </button>
      </div>

      <!-- normal content -->
      <tun-track-list-view
        *ngIf="searchState == SearchState.normal"
        [tracks]="searchResult$ | async"
        [useSelectionBox]="true"
        [checkedTracks]="checkedTracks"
        (checkedTracksChanged)="onCheckedTracksChanged($event)"
        class="trackTable"
      >

      </tun-track-list-view>

  </div>

   <!-- Popup: choose playlist-->
   <div #choosePlaylistPopupWrapper *ngIf="showChoosePlaylistOverlay" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, choosePlaylistPopupWrapper)">
    <tun-overlay-select-playlist
      class="select-playlist-overlay"
      confirmButtonTranslateableText="playlist.addTrack.popup.button.commit"
      (closeWithPlaylist)="onCloseSelectPlaylist($event)"
    >
    </tun-overlay-select-playlist>
  </div>

</div>
