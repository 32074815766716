import { Component } from '@angular/core';
import { ZoneConfigurationService } from '@service/zone-configuration.service';

@Component({
  selector: 'tun-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {

  get royaltyFree$(){
    return this.zoneConfigurationService.royaltyFree$;
  }

  constructor(
    private zoneConfigurationService: ZoneConfigurationService,
  ) {

  }

}
