import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tun-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {

  // === Props === //
  @Input() text = 'general.loading';
  @Input() textColor = '#bcbcbc';

  @Input() spinnerSize = 30;

  private _zIndex: number;

  get zIndex(): number {
    return this._zIndex;
  }

  // Optional z-index to make the loader floating
  @Input()
  set zIndex(value: number) {
    this._zIndex = value;
    this.position = value ? 'absolute' : undefined;
  }

  // === State === //
  position: string;
}
