export const musicCollection_8622 = {
    "@type": "Playlist",
    "id": 8622,
    "title": "Hitparade Alternatief",
    "description": "06",
    "lastChanged": null,
    "audioFiles": [],
    "visible": true,
    "editable": false,
    "favourite": false,
    "selected": true,
    "mostPlayed": false,
    "defaultSelected": false
}