import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@service/authentication.service';
import { LoggerService } from '@service/loggers/logger.service';
import { SongRequest } from '@service/song-request.service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from '@service/config';

@Injectable({
  providedIn: 'root'
})
export class SongRequestApiService {

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService
  ){ 

  }
  
  public createSongRequest(songRequest: SongRequest): Observable<SongRequest>{
    let resultObservable: Observable<SongRequest>;

    if (environment.mockBackend){

      const notifier = new Subject<SongRequest>();


     // simulate 2 seconds request
      setTimeout(() => {
      if (Math.random() > 0.2) {
        // todo -> update id from server
        songRequest.id = Math.floor(Math.random() * 10000);

        notifier.next(songRequest);
        notifier.complete();
      } else {
        // simulate 20% error chance
        notifier.error("Simulating creation error");
      }

      notifier.complete();
    }, 2000);

    }else{
      
      let url = Config.api4Url_songRequests(this.authenticationService.zoneId);
        resultObservable = this.httpClient.post<SongRequest>(url, songRequest);
      
    }
    return resultObservable;
  }
    
}
