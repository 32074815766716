<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
   <g transform="translate(1.000000, 4.000000)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
    <path d="M9,7 C9,7 7.65,3 4.95,3 L0,3"></path>
    <path d="M22,3 L16.7391304,3 C11.9565217,3 10.0434783,14 5.26086957,14 L0,14"></path>
    <path d="M12,11 C12,11 13.9047619,14 16.7619048,14 L22,14"></path>
    <polyline points="20 0 22 3 20 6"></polyline>
    <polyline points="20 11 22 14 20 17"></polyline>
</g>
</svg>
