import {Injectable, Renderer2, NgZone} from '@angular/core';
import {AudioTagWrapper} from "./audioTagWrapper";
import { LoggerService } from '@service/loggers/logger.service';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * This service is responsible for
 *  - managing the audio Context (web audio api)
 *  - create audiotags that can be used to play media
 *  - manage a pool of audioTags for re-use
 */

@Injectable()
export class AudioTagService {

  private LOGGER_CLASSNAME = 'AudioTagService';

  private __needClickToStartAudio = false;
  private get _needClickToStartAudio(): boolean {
    return this.__needClickToStartAudio;
  }
  private set _needClickToStartAudio(value: boolean) {
    if (this.__needClickToStartAudio !== value) {
        this.__needClickToStartAudio = value;
        this.ngZone.run( () => {
          this._needClickToStartAudioSubject.next(this.__needClickToStartAudio);
        });

    }
  }
  get needClickToStartAudio(): boolean {
    return this._needClickToStartAudio;
  }
  private _needClickToStartAudioSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public needClickToStartAudio$: Observable<boolean> = this._needClickToStartAudioSubject.asObservable();

  private _audioContextStateSubject: BehaviorSubject<AudioContextState> = new BehaviorSubject<AudioContextState>(null);
  public audioContextState$: Observable<AudioContextState> = this._audioContextStateSubject.asObservable();

  constructor(private logger: LoggerService,
              private ngZone: NgZone) {
    this.startAudioContext();
  }

  public audioContext: AudioContext;
  private iOSSafari = false;
  private startAudioContext(){

    if (this.audioContextAvailable()) {
        this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioContext', 'AudioContext is available');


        //Detect iOS safari to bugfix: https://bugs.webkit.org/show_bug.cgi?id=211394
        let ua = window.navigator.userAgent;
        let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        let webkit = !!ua.match(/WebKit/i);
        this.iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (this.iOSSafari){
          this.logger.warn(this.LOGGER_CLASSNAME, "startAudioContext", "Starting on iOS with safari -> do not use createMediaElementSource (bug https://bugs.webkit.org/show_bug.cgi?id=211394)");
        }

        if (!NgZone.isInAngularZone){
          this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioContext', 'We are not in angular zone');
        }

        this.audioContext = new AudioContext();
        this.logger.debug(this.LOGGER_CLASSNAME, "startAudioContext", "audioContext state started with " + this.audioContext.state);

        this._audioContextStateSubject.next(this.audioContext.state);
        this._needClickToStartAudio = this.audioContext.state != "running";

        this.audioContext.onstatechange = () => {

          if (!NgZone.isInAngularZone){
            this.logger.debug( this.LOGGER_CLASSNAME, 'audioContext.onstatechange', 'We are not in angular zone');
          }

          this._audioContextStateSubject.next(this.audioContext.state);
          this._needClickToStartAudio = this.audioContext.state != "running" ;
          this.logger.debug(this.LOGGER_CLASSNAME, "startAudioContext", "audioContext state changed to " + this.audioContext.state);
        }
    } else {
      this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioContext', 'AudioContext is NOT available. Playback will go through audio-tags');
    }
  }



  private audioContextAvailable():boolean{
    //to test without web audio api:
    //return false;
    try{
      this.logger.debug( this.LOGGER_CLASSNAME, 'audioContextAvailable', 'checking AudioContext..');
      if (AudioContext) {
        this.logger.debug( this.LOGGER_CLASSNAME, 'audioContextAvailable', 'AudioContext is available');
        return true;
      }else{
        this.logger.warn(this.LOGGER_CLASSNAME, 'audioContextAvailable', 'AudioContext NOT available -> only use audio tags');
      }
    }catch(e){
      this.logger.error(this.LOGGER_CLASSNAME, 'audioContextAvailable', 'AudioContext NOT available. Error while checking: ' + e);
    }

    return false;

  }

  public enableAudioAfterClick(){

    if (this.audioContextAvailable()){
      if (this.audioContext != null){
        if (this.audioContext.state == "closed"){
          this.logger.error(this.LOGGER_CLASSNAME, 'enableAudioAfterClick', 'audioContext object was closed -> going to create a new one');
          //todo -> check if we need to destroy all previous created audio objects
          this.startAudioContext();
        }else if (this.audioContext.state != "running"){
          this.logger.warn(this.LOGGER_CLASSNAME, 'enableAudioAfterClick', 'audioContext resumed after state ' + this.audioContext.state);
          this.audioContext.resume();
        }else{
          this.logger.debug(this.LOGGER_CLASSNAME, 'enableAudioAfterClick', 'audioContext normal state: ' + this.audioContext.state);
        }
      }else{
        this.logger.error(this.LOGGER_CLASSNAME, 'enableAudioAfterClick', 'AudioContext is available but no audioContext object was present -> going to create one');
        this.startAudioContext();
      }
    }else{
      this.logger.debug( this.LOGGER_CLASSNAME, 'enableAudioAfterClick', 'AudioContext is NOT available. Playback will go through audio-tags, no need to enable anything on our first click');
    }
  }

  public checkStartAudioContextBeforeStart():Promise<void>{
    this.logger.debug( this.LOGGER_CLASSNAME, 'checkStartAudioContextBeforeStart', 'checking AudioContext..');
    if (AudioContext){
      if (this.audioContext.state == "suspended"){
        return this.audioContext.resume();
      }
    }
    return null;
  }

  private audioTagPool: AudioTagWrapper[] = [];
  private amountAudioTagWrappers = 0;

  public getAudioTagFromPool(): AudioTagWrapper {
    if (this.audioTagPool.length > 0) {
      this.logger.debug( this.LOGGER_CLASSNAME, 'getAudioTagFromPool', 'Reusing audioTag');
      const audioTagWrapper = this.audioTagPool.shift();
      audioTagWrapper.adjustVolume(1);
      return audioTagWrapper;
    }



    const audioTagWrapper = new AudioTagWrapper();

    this.amountAudioTagWrappers++;

    audioTagWrapper.audioTagWrapperId = this.amountAudioTagWrappers;

    this.logger.debug( this.LOGGER_CLASSNAME, 'getAudioTagFromPool', 'Creating new audioTag with id ' + audioTagWrapper.audioTagWrapperId + ' -> ' + (NgZone.isInAngularZone?'':'NOT ') + 'in angular zone');

    audioTagWrapper.audioElement = new Audio();
    audioTagWrapper.audioElement.crossOrigin = 'anonymous';

    audioTagWrapper.audioElement.controls = false;
    audioTagWrapper.audioElement.autoplay = false;
    audioTagWrapper.audioElement.preload = 'auto';

    document.body.appendChild(audioTagWrapper.audioElement);

    if (this.audioContext) {

      if (!this.iOSSafari){
        audioTagWrapper.audioNode = this.audioContext.createMediaElementSource(audioTagWrapper.audioElement);
        audioTagWrapper.audioGainNode = this.audioContext.createGain();

        audioTagWrapper.audioNode.connect(audioTagWrapper.audioGainNode);
        audioTagWrapper.audioGainNode.connect(this.audioContext.destination);
      }else{
        this.logger.warn(this.LOGGER_CLASSNAME, "getAudioTagFromPool", "creating new audioTagWrapper without using createMediaElementSource (still bugged: https://bugs.webkit.org/show_bug.cgi?id=211394)) ");
      }

    }

    return audioTagWrapper;
  }

  public freeAudioTagForPool(audioTagWrapper: AudioTagWrapper) {
    this.logger.debug(this.LOGGER_CLASSNAME, "freeAudioTagForPool", "cleaning up " + audioTagWrapper.audioTagWrapperId);
    // clear audioTag

    if (audioTagWrapper.audioElement) {
      if (audioTagWrapper.audioElement.src) {
        audioTagWrapper.audioElement.pause();
        audioTagWrapper.audioElement.currentTime = 0;
        //audioTagWrapper.audioElement.removeAttribute('src');
      }

      //audioTagWrapper.adjustVolume(1);
    }

    this.audioTagPool.push(audioTagWrapper);

  }

}
