<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
   <defs>
    <path id="path-star-icon-v5" d="M2,9.66220391 L6.59821033,14.3583934 C6.84982723,14.6167962 6.96677594,14.991293 6.90652964,15.3582998 L5.82032427,22 L11.5135997,18.8560996 C11.821919,18.6875761 12.1904846,18.6875761 12.4988039,18.8560996 L18.1796757,21.9925101 L17.0934704,15.3489374 C17.0332241,14.9819305 17.1501728,14.6093062 17.4017897,14.349031 L22,9.65284149 L15.6475591,8.68102238 C15.3002569,8.62672034 15.0007974,8.39827731 14.8466377,8.06497519 L12.0079738,2 L9.15513423,8.07621009 C8.99920262,8.40763973 8.70151502,8.63608276 8.35421281,8.68851231 L2,9.66220391 Z M4.63735975,23.6299401 C4.07502513,23.1988024 3.7972867,22.4838323 3.91386826,21.7616766 L4.95110132,15.4652695 L0.555290488,11.0137725 C0.0581043959,10.5107784 -0.125340128,9.73832335 0.0872497875,9.04850299 C0.299839703,8.35868263 0.879318665,7.84670659 1.56337815,7.74431138 L7.64447839,6.82095808 L10.3670009,1.07065868 C10.6704558,0.418562874 11.3150833,0 12.007715,0 C12.7020611,0 13.3466885,0.420359281 13.6501435,1.0760479 L16.3589505,6.81197605 L22.4366218,7.73532934 C23.1309679,7.83952096 23.6967314,8.34071856 23.9127502,9.03952096 C24.1253401,9.72934132 23.9418956,10.5017964 23.4447095,11.0047904 L19.0488987,15.4580838 L20.0861317,21.7491018 C20.2027133,22.4748503 19.9266893,23.1898204 19.3626403,23.6209581 C18.7985912,24.0538922 18.0648131,24.1095808 17.4441877,23.7754491 L12.0060005,20.7988024 L6.55924116,23.7808383 C6.2900749,23.9281437 6.00033542,24 5.71231037,24 C5.33342028,24 4.95795906,23.8760479 4.63735975,23.6299401 Z"></path>
</defs>
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <mask fill="white">
        <use xlink:href="#path-star-icon-v5"></use>
    </mask>
    <use fill="currentColor" xlink:href="#path-star-icon-v5"></use>
</g>
</svg>
