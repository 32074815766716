import { Component} from '@angular/core';

@Component({
  selector: 'tun-music-channel-detail-view',
  templateUrl: './music-channel-detail-view.component.html',
  styleUrls: ['./music-channel-detail-view.component.scss']
})
export class MusicChannelDetailViewComponent {


}
