<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <circle stroke="currentColor" cx="2" cy="3" r="1.5"></circle>
    <circle stroke="currentColor" cx="2" cy="9" r="1.5"></circle>
    <circle stroke="currentColor" cx="2" cy="15" r="1.5"></circle>
    <circle stroke="currentColor" cx="2" cy="21" r="1.5"></circle>
    <line x1="6" y1="3" x2="23" y2="3" id="Line" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></line>
    <line x1="6" y1="21" x2="23" y2="21" id="Line" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></line>
    <line x1="6" y1="15" x2="23" y2="15" id="Line" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></line>
    <line x1="6" y1="9" x2="23" y2="9" id="Line" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></line>
  </g>
</svg>
