export const musicCollection_8223 = {
    "@type": "Context",
    "id": 8223,
    "title": "Party",
    "description": "09",
    "lastChanged": null,
    "editable": false,
    "visible": true,
    "favourite": false,
    "selected": false,
    "mostPlayed": false,
    "defaultSelected": true,
    "changeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Dancing Style",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Dancing Style",
            "value": [
                {
                    "value": "Free Dances",
                    "selected": true
                },
                {
                    "value": "March",
                    "selected": true
                },
                {
                    "value": "Polka",
                    "selected": true
                },
                {
                    "value": "Polonaise",
                    "selected": true
                },
                {
                    "value": "Tarantella",
                    "selected": true
                },
                {
                    "value": "Waltz",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Occasion",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Occasion",
            "value": [
                {
                    "value": "Ambiance",
                    "selected": true
                },
                {
                    "value": "Carnival",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Origin",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Origin",
            "value": [
                {
                    "value": "Belgium",
                    "selected": true
                },
                {
                    "value": "Germany",
                    "selected": true
                },
                {
                    "value": "Netherlands",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1960,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1960,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Party Level",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Party Level",
            "minimum": 2,
            "maximum": 4,
            "step": 1,
            "lowerSelection": 2,
            "upperSelection": 4,
            "metadataDisplayMinimum": "2",
            "metadataDisplayMaximum": "4"
        }
    ],
    "defaultChangeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Dancing Style",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Dancing Style",
            "value": [
                {
                    "value": "Free Dances",
                    "selected": true
                },
                {
                    "value": "March",
                    "selected": true
                },
                {
                    "value": "Polka",
                    "selected": true
                },
                {
                    "value": "Polonaise",
                    "selected": true
                },
                {
                    "value": "Tarantella",
                    "selected": true
                },
                {
                    "value": "Waltz",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Occasion",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Occasion",
            "value": [
                {
                    "value": "Ambiance",
                    "selected": true
                },
                {
                    "value": "Carnival",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Origin",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Origin",
            "value": [
                {
                    "value": "Belgium",
                    "selected": true
                },
                {
                    "value": "Germany",
                    "selected": true
                },
                {
                    "value": "Netherlands",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1960,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1960,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Party Level",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Party Level",
            "minimum": 2,
            "maximum": 4,
            "step": 1,
            "lowerSelection": 2,
            "upperSelection": 4,
            "metadataDisplayMinimum": "2",
            "metadataDisplayMaximum": "4"
        }
    ]
};