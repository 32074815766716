import { Component, Input } from '@angular/core';
import { MusicChannel } from '@model/musicChannel';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';

@Component({
  selector: 'tun-search-music-channel-view',
  templateUrl: './search-music-channel-view.component.html',
  styleUrls: ['./search-music-channel-view.component.scss']
})
export class SearchMusicChannelViewComponent {

  private _showSearchMusicChannel: MusicChannel;
  @Input()
  set showSearchMusicChannel(value: MusicChannel) {
    if (value != null){
      this._showSearchMusicChannel = value
    }
  }
  get showSearchMusicChannel():MusicChannel{
    return this._showSearchMusicChannel;
  }

  constructor(
    private appV5StateService: AppV5StateService,
  ) {

  }

  onBack(){
    this.appV5StateService.showSearchMusicChannelInTunifyOrange(null);
  }
}
