import { Component, ElementRef, Input } from '@angular/core';
import { ApplicationMode, ZoneConnectionsService } from '../../../services/authentication/zone-connections.service';
import { map } from 'rxjs/operators';
import { PopupService } from '../../../services/app/popup.service';

@Component({
  selector: 'tun-remote-popup-v5',
  templateUrl: './remote-popup-v5.component.html',
  styleUrls: ['./remote-popup-v5.component.scss']
})
export class RemotePopupV5Component {

  @Input() public connectedElementRef: ElementRef;

  get showRemotePlayer$(){
    return this.zoneConnectionsService.applicationMode$
      .pipe(map(applicationMode => applicationMode == ApplicationMode.remoteMode))
  }

  get remotePlayerActive$(){
    return this.zoneConnectionsService.currentPlayerApplicationInfo$
    .pipe(
      map(remoteApplicationInfos => remoteApplicationInfos != null)
    )
  }


  public get remotePlayerApplicationInfo$(){
    return this.zoneConnectionsService.currentPlayerApplicationInfo$
  }

  public get lastPlayerApplicationInfo$(){
    return this.zoneConnectionsService.lastPlayerApplicationInfo$;
  }

  public get remoteRemoteApplicationInfos$(){
    return this.zoneConnectionsService.currentRemoteApplicationInfos$;
  }

  constructor(
    public elementRef: ElementRef,
    private zoneConnectionsService: ZoneConnectionsService,
    private popupService: PopupService
  ) { }

  ngOnInit(): void {
  }

  public stopConnect(){
    this.zoneConnectionsService.deactivateCurrentZoneConnection();
  }

  public close(){
    this.popupService.hidePopup$.next(this.connectedElementRef);
  }
}
