<div class="tooltip {{theme}}">
  <p [style.text-align]="textAlignment" *ngFor="let line of translatedTextLines">
    {{ line }}
  </p>
  <button *ngIf="tooltipAction" (click)="onActionClick()">
    <p translate>
      {{ tooltipAction.text }}
    </p>
  </button>
</div>
