import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { LoggerService } from '../loggers/logger.service';
import { BehaviorSubject, Subject, Observable, merge, timer } from 'rxjs';
import { AudioFileProperty } from '@model/enums/audioFileProperty';
import { ZoneConfigurationApiService, DTO_MusicSelection, DTO_Language, DTO_Region} from '@service/api/zone-configuration-api.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ConfigApiService } from '@service/api/config-api.service';
import { stringToLogLevel } from '@service/loggers/logTarget';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { LocalStorageService } from '../app/local-storage.service';
import { MusicSelectionService } from './music-selection.service';
import { Config } from '@service/config';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})

export class ZoneConfigurationService implements OnDestroy {

    public readonly ALLOWED_LANGUAGES = ["en", "nl", "fr", "de"];

    private readonly simulateLoadErrorPercentage = 0.0; // fill in percentage to test errors

    private LOGGER_CLASSNAME = 'ZoneConfigurationService';

    constructor(
            private zoneConnectionsService: ZoneConnectionsService,
            private zoneConfigurationApiService: ZoneConfigurationApiService,
            private loggerService: LoggerService,
            private translateService:TranslateService,
            private configApiService: ConfigApiService,
            private localStorageService: LocalStorageService,
            private musicSelectionService: MusicSelectionService
    ) {

        this.zoneConnectionsService.activeZoneConnection$
        .pipe(
            takeUntil(this.destroyed$)
        )
        .subscribe(
            (zoneConnection) => {
              this.clearData();
              if (zoneConnection != null){
                this.loadData();
              }
            }
        );
    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy(){
        this.destroyed$.next();
        this.destroyed$.complete();
        this.destroyed$ = null;
    }

    private loadData(){
        this.loadZoneConfiguration();
    }

    private clearData(){
        //cancel all loading requests
        this.cancelPrevousZoneConfigurationRequests$.next();
        this._zoneConfigurationError = null;
        this._zoneConfigurationLoaded = false;

        //reset all properties
        this._audioFilePropertySubject.next(AudioFileProperty.YEAR);
        this._sabamIdSubject.next(null);
        this._regionSubject.next(null);

        this._royaltyFree = false;
    }

     /**
     * selected AudioFileProperty
     * @type {AudioFileProperty}
     * @private
     */
    private get _audioFileProperty(): AudioFileProperty {
        return this._audioFilePropertySubject.value;
    }
    private set _audioFileProperty(value: AudioFileProperty) {
        if (this._audioFileProperty !== value) {
            this._audioFilePropertySubject.next(value);
        }
    }

    public get audioFileProperty(): AudioFileProperty{
        return this._audioFileProperty;
    }
    private _audioFilePropertySubject: BehaviorSubject<AudioFileProperty> = new BehaviorSubject<AudioFileProperty>(AudioFileProperty.YEAR);
    public audioFileProperty$: Observable<AudioFileProperty> = this._audioFilePropertySubject.asObservable();

    //public method to change the visible audioFileProperty, can be set from a UI component
    //this method triggers a save to the servers if needed
    public changeAudioFileProperty(audioFileProperty: AudioFileProperty){
        this._audioFileProperty = audioFileProperty;
        this.saveAudioFilePropertyToServer();
    }

    private saveAudioFilePropertyToServer(){
        //todo: implement save to our server
    }


    /**
     * royalty free flag
     */
    private get _royaltyFree(): boolean {
        return this._royaltyFreeSubject.value;
    }
    private set _royaltyFree(value: boolean) {
        if (this._royaltyFree !== value) {
            this._royaltyFreeSubject.next(value);
        }
    }

    public get royaltyFree(): boolean{
        return this._royaltyFree;
    }
    private _royaltyFreeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public royaltyFree$: Observable<boolean> = this._royaltyFreeSubject.asObservable();






    /**
     * Sabam id
     */
    private get _sabamId(): string {
        return this._sabamIdSubject.value;
    }
    private set _sabamId(value: string) {
        if (this._sabamId !== value) {
            this._sabamIdSubject.next(value);
        }
    }

    public get sabamId(): string{
        return this._sabamId;
    }
    private _sabamIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public sabamId$: Observable<string> = this._sabamIdSubject.asObservable();

    /**
     * Region code
     */
    private get _region(): DTO_Region {
        return this._regionSubject.value;
    }
    private set _region(value: DTO_Region) {
        if (this._region !== value) {
            this._regionSubject.next(value);
        }
    }

    public get region(): DTO_Region{
        return this._region;
    }
    private _regionSubject: BehaviorSubject<DTO_Region> = new BehaviorSubject<DTO_Region>(null);
    public region$: Observable<DTO_Region> = this._regionSubject.asObservable();


    /**
     * Language
     */

    public forcedLanguage: string = null;

    //the used language of the client application.
    //this language is checked against the array of ALLOWED_LANGUAGES
    //this language does not change when a user logs out
    public get usedLanguage(): string{
      return this.forcedLanguage
              ? this.forcedLanguage
              : this.language
                ?  this.language
                : (this.ALLOWED_LANGUAGES.indexOf(this.translateService.getBrowserLang()) >= 0)
                    ? this.translateService.getBrowserLang()
                    : "en"; //default language when no other language is possible
    }

    /**
     * The language stored in the zone configuration
     */

    private get _language(): string {
        return this._languageSubject.value;
    }
    private set _language(value: string) {
        if (this._language !== value) {
            //check language
            if (this.ALLOWED_LANGUAGES.indexOf(value) >= 0){
                this.forcedLanguage = null;
                this.translateService.use(value);
                this._languageSubject.next(value);
                this.saveLanguage(); //when a new valid language is selected -> trigger a save
            }else{
                this._languageSubject.next(this.usedLanguage);
            }
        }
    }

    public get language(): string{
        return this._language;
    }
    private _languageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public language$: Observable<string> = this._languageSubject.asObservable();

    public changeLanguage(lang:string){
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'changeLanguage', 'about to send chagne the language to ' + lang);
      this._language = lang;
    }

     //keep track of the stored language
     private serverSideLanguage: DTO_Language;

    //we will use this observable to cancel any previous http request
    private saveLanguageWillStartSubject: Subject<boolean> = new Subject<boolean>();
    private saveLanguage(){
        //perform save
        this.loggerService.info(this.LOGGER_CLASSNAME, 'saveLanguage', 'about to send saveLanguage request');

        //create a new language DTO
        const languageDTO = new DTO_Language();
        languageDTO.language = this.language;

        //we just update our local model and don't wait for on ok or error.
        this.serverSideLanguage = languageDTO;

        //indicate a save will start, this will cancel all previous saves
        this.saveLanguageWillStartSubject.next(true);

        //we already silently update our local settings
        //this._musicSelectionSubject.next(musicSelection);

        //call api
        const saveLanguageObservable = this.zoneConfigurationApiService.saveLangauge(languageDTO);

        if (saveLanguageObservable){
            saveLanguageObservable.pipe(
                takeUntil(
                    merge(
                        this.saveLanguageWillStartSubject, //stop as soon as a newer save starts
                        this.destroyed$
                        )
                    )
                ).subscribe(
                    (data) => {
                        this.loggerService.debug(this.LOGGER_CLASSNAME, "saveLanguage", "data : " + data);
                    },
                    error => {
                        const errMsg = (error.message) ? error.message :
                            error.status ? `${error.status} - ${error.statusText}` : 'Server error';

                        this.loggerService.debug(this.LOGGER_CLASSNAME, "saveLanguage", "error while sending request : " + errMsg);
                    }
                );
        }
    }





    /**
     * Date time of the zone
     *
     * Dates in javascript are always in the Timezone of the local system.
     * This date is the current time of the time zone that is configurad for the zone.
     *
     * If the configured timeZone on the server is -2UTC and the local system has +1UTC, then this date will be 3hours earlier than the current time of the local system
     *
     */
    //marker when the time was set so we can adjust
    private currentDateTimeFetchTimeStamp = null;
    private __currentDateTimeForZone: Date = null;
    private set _currentDateTimeForZone(dateTime: Date){
        this.__currentDateTimeForZone = dateTime;
        this.loggerService.debug(this.LOGGER_CLASSNAME, "set _currentDateTimeForZone", "date: " + dateTime.getHours() + " utc: " + dateTime.getUTCHours());
        this.currentDateTimeFetchTimeStamp = new Date().getTime();
    }
    private get _currentDateTimeForZone(): Date{
      if (this.__currentDateTimeForZone != null){
        const adjustment = new Date().getTime() - this.currentDateTimeFetchTimeStamp;
        return new Date(this.__currentDateTimeForZone.getTime() + adjustment);
      }else{
        this.loggerService.warn(this.LOGGER_CLASSNAME, "get _currentDateTimeForZone", "date not yet set.. still loading the configuration?");
        return new Date();
      }

    }
    public get currentDateTimeForZone():Date{
        return this._currentDateTimeForZone;
    }



    /**
     * Enable onscreen keyboard flag
     */
  public get enableOnScreenKeyboard(): boolean {
      return this._enableOnScreenKeyboardSubject.value;
  }
  public set enableOnScreenKeyboard(value: boolean) {
      if (this.enableOnScreenKeyboard !== value) {
          this._enableOnScreenKeyboardSubject.next(value);

          //save in localStorage
          this.localStorageService.onscreenKeyboardActive = this.enableOnScreenKeyboard;
      }
  }

  private _enableOnScreenKeyboardSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.localStorageService.onscreenKeyboardActive);
  public enableOnScreenKeyboard$: Observable<boolean> = this._enableOnScreenKeyboardSubject.asObservable();


    private get _intercomUserHash(): string {
        return this._intercomUserHashSubject.value;
    }
    private set _intercomUserHash(value: string) {
        if (this._intercomUserHash !== value) {
            this._intercomUserHashSubject.next(value);
        }
    }

    public get intercomUserHash(): string{
        return this._intercomUserHash;
    }
    private _intercomUserHashSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public intercomUserHash$: Observable<string> = this._intercomUserHashSubject.asObservable();



  private get _clientLoggingLevel(): string {
    return this._clientLoggingLevelSubject.value;
  }
  private set _clientLoggingLevel(value: string) {
      if (this._clientLoggingLevel !== value) {
          this._clientLoggingLevelSubject.next(value);
          const logLevel = stringToLogLevel(value);
          if (logLevel){
            //push the value to the logging service
          this.loggerService.webserviceLogLevel = logLevel;
          }else{
            this.loggerService.error(this.LOGGER_CLASSNAME, "_clientLogggingLevel setter", "could not parse value " + value + " to logging level");
          }

      }
  }

  public get clientLoggingLevel(): string{
      return this._clientLoggingLevel;
  }
  private _clientLoggingLevelSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public clientLoggingLevel$: Observable<string> = this._clientLoggingLevelSubject.asObservable();




    private get _zoneConfigurationLoading(): boolean {
        return this._zoneConfigurationLoadingSubject.value;
    }
    private set _zoneConfigurationLoading(value: boolean) {
        if (this._zoneConfigurationLoading !== value) {
            this._zoneConfigurationLoadingSubject.next(value);
        }
    }

    public get zoneConfigurationLoading(): boolean{
        return this._zoneConfigurationLoading;
    }
    private _zoneConfigurationLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public zoneConfigurationLoading$: Observable<boolean> = this._zoneConfigurationLoadingSubject.asObservable();

    //keep track when the zoneConfiguration is loaded once for this logged in zone
    private get _zoneConfigurationLoaded(): boolean {
        return this._zoneConfigurationLoadedSubject.value;
    }
    private set _zoneConfigurationLoaded(value: boolean) {
        if (this._zoneConfigurationLoaded !== value) {
            this._zoneConfigurationLoadedSubject.next(value);
        }
    }

    public get zoneConfigurationLoaded(): boolean{
        return this._zoneConfigurationLoaded;
    }
    private _zoneConfigurationLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public zoneConfigurationLoaded$: Observable<boolean> = this._zoneConfigurationLoadedSubject.asObservable();



    private get _zoneConfigurationError(): string {
        return this._zoneConfigurationErrorSubject.value;
    }
    private set _zoneConfigurationError(value: string) {
        if (this._zoneConfigurationError !== value) {
            this._zoneConfigurationErrorSubject.next(value);
        }
    }

    public get zoneConfigurationError(): string{
        return this._zoneConfigurationError;
    }
    private _zoneConfigurationErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public zoneConfigurationError$: Observable<string> = this._zoneConfigurationErrorSubject.asObservable();


    private errorsInARow_load = 0;
    private retryLoadZoneConfiguration() {
     const delay = Math.pow(2, this.errorsInARow_load - 1) * Config.RETRY_BASE_INTERVAL_MS;

     timer(Math.min(delay, Config.RETRY_MAX_INTERVAL_MS))
     .pipe(
       takeUntil(
         merge(
           this.cancelPrevousZoneConfigurationRequests$,
           this.destroyed$
         )
       )
     )
     .subscribe(
       ()=>{
          //if not loaded in the meanwhile, start to load
          if (this.zoneConnectionsService.activeZoneConnection && !this.zoneConfigurationLoading && !this.zoneConfigurationLoaded){
           this.loadZoneConfiguration();
         }

       }
     )
   }

    private cancelPrevousZoneConfigurationRequests$ = new Subject<void>();
    public loadZoneConfiguration(): void {

        this.cancelPrevousZoneConfigurationRequests$.next();

        this._zoneConfigurationLoading = true;
        this._zoneConfigurationError = null;

        const zoneConfigurationObservable = this.zoneConfigurationApiService.loadZoneConfiguration();

        zoneConfigurationObservable
            .pipe(
                finalize(() => {
                    this._zoneConfigurationLoading = false;

                    if (this.zoneConnectionsService.activeZoneConnection && !this.zoneConfigurationLoading && !this.zoneConfigurationLoaded){
                      this.retryLoadZoneConfiguration();
                    }

               }),
               takeUntil(
                   merge(
                    this.destroyed$,
                    this.cancelPrevousZoneConfigurationRequests$
                   )
                )
            )
            .subscribe(
                (data) => {
                  if (Math.random() < this.simulateLoadErrorPercentage / 100){
                    //simulate error

                    this.errorsInARow_load++;
                    this._zoneConfigurationError = 'Simulation Error';

                    this.loggerService.error(this.LOGGER_CLASSNAME, "loadZoneConfiguration", "SIMULATING loading error");
                  }else{
                    this.loggerService.debug(this.LOGGER_CLASSNAME, "loadZoneConfiguration", "data : " + data);

                    this.errorsInARow_load = 0;

                    this._sabamId = data.sabamId;
                    this._intercomUserHash = data.intercomUserHash;
                    this._region = data.region;
                    this._royaltyFree = data.royaltyFree;

                    this.serverSideLanguage = new DTO_Language();
                    this.serverSideLanguage.language = data.language;
                    this._language = data.language;

                    const offset = new Date().getTimezoneOffset();
                    this.loggerService.debug(this.LOGGER_CLASSNAME, "loadZoneConfiguration", "Dates offset: " + offset + " --- from date: " + new Date().getTimezoneOffset() );

                    const dateStringToConvert = (data.currentLocalDateTime && data.currentLocalDateTime !="")?data.currentLocalDateTime + "+00:00":"";
                    const localDateTime = dateStringToConvert != ""?new Date((new Date(dateStringToConvert).getTime() + offset * 60000)):new Date();

                    //let localDateTime = (data.currentLocalDateTime && data.currentLocalDateTime != "")?new Date(data.currentLocalDateTime): new Date();
                    const localDateTimeFromZonedDateTime = (data.currentDateTime && data.currentDateTime != "")?new Date(data.currentDateTime): new Date();

                    this.loggerService.debug(this.LOGGER_CLASSNAME, "loadZoneConfiguration", "Dates loaded. localDateTime (we are using this one): " + localDateTime.toTimeString() + " --- localDateTimeFromZonedDateTime: " +localDateTimeFromZonedDateTime.toTimeString() );

                    //if the above are not the same -> TimeZone configuration of this zone is different from the Timezone on this system
                    const difference = Math.abs(localDateTime.getTime() - localDateTimeFromZonedDateTime.getTime());
                    if (difference < 10000){ //less then 10 seconds difference -> same time zone
                        this.loggerService.debug(this.LOGGER_CLASSNAME, "loadZoneConfiguration", "Configured Time zone for this zone is the same as the systems time zone" );
                    }else{
                        this.loggerService.warn(this.LOGGER_CLASSNAME, "loadZoneConfiguration", "Configured Time zone is different as the systems time zone. Differnce: " + difference / 1000 + " seconds" );
                    }

                    //we should improve our times fetched from server
                    //chrome is just parsing a date without a timezone and handles it as if it was in the timezone of the browser
                    //safari adds +00:00 to the time and makes a convertion

                    //we need to add the browser timezone to the string before parsing?
                    this._currentDateTimeForZone = localDateTime;

                    this._clientLoggingLevel = data.clientLoggingLevel;

                    this._zoneConfigurationLoaded = true;
                  }
                },
                (error : unknown) => {
                  let errMsg = 'Server error: ' + error;
                  if (error instanceof HttpErrorResponse){
                    errMsg = (error.message) ? error.message :
                    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                  }
                  this.loggerService.error(this.LOGGER_CLASSNAME, "loadZoneConfiguration", "Error loading: " + errMsg);

                  this.errorsInARow_load++;

                  this._zoneConfigurationError = this.translateService.instant("startup.loadingSettings.error");
                }
            );
    }




}
