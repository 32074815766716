import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { QueueService } from '@service/queue.service';
import { ZoneConnectionsService } from '../../authentication/zone-connections.service';
import { LoggerService } from '../../loggers/logger.service';
import { DTO_QueueType } from '../../api/queue-api.service';
import { PlaylistService } from '../playlist.service';
import { MusicSelectionService } from '../music-selection.service';
import { MusicChannelService } from '../music-channel.service';
import { CalendarGroupService } from '@service/calendar-group.service';
import { CalendarService } from '../calendar.service';
import { MessageBlocksService } from '@service/message-blocks.service';
import { RecommendationsService } from '@service/recommendations.service';

export enum VersionedResourceType{
  queue_waiting = "queue_waiting",
	queue_radio = "queue_radio",
	queue_nextRadio = "queue_nextRadio",
	custom_playlists = "custom_playlists",
	music_selection = "music_selection",
  calendarGroup = "calendarGroup",
  musicChannelGroupRadio = "musicChannelGroupRadio",
  messagesPlanning = "messagesPlanning",
  item_item_recommendation = "item_item_recommendation"
}

export enum VersionedIdResourceType{
  calendar = "calendar",
	playlist = "playlist",
  musicCollection = "musicCollection"
}

/**
 * This interceptor checks and handles any updateInfo in the response
 */
@Injectable()
export class UpdateInfoInterceptor implements HttpInterceptor {

  private LOGGER_NAME = "UpdateInfoInterceptor";

  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private queueService: QueueService,
    private loggerService: LoggerService,
    private playlistService: PlaylistService,
    private musicSelectionService: MusicSelectionService,
    private musicChannelService: MusicChannelService,
    private calendarGroupService: CalendarGroupService,
    private calendarService: CalendarService,
    private messageBlocksService: MessageBlocksService,
    private recommendationsService: RecommendationsService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

        return next.handle(req).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse){
                       this.checkEvent(event);
                    }
                    //console.log('interceptor fired!')
                }
            )
        );
  }

  private checkEvent(event: HttpResponse<any>){

    if ( this.zoneConnectionsService.activeZoneConnection != null && event.body){
        if (Object.prototype.hasOwnProperty.call(event.body, "updateInfos") && event.body.updateInfos instanceof Array){
            for (let i = 0; i < event.body.updateInfos.length; i++) {
                const updateInfo = event.body.updateInfos[i];
                if (updateInfo.zoneId == this.zoneConnectionsService.activeZoneConnection.zoneId){
                    if (Object.prototype.hasOwnProperty.call(updateInfo, "updateVersionedResourceInfos") && updateInfo.updateVersionedResourceInfos instanceof Array){
                        for (let j = 0; j < updateInfo.updateVersionedResourceInfos.length; j++) {

                          const type = updateInfo.updateVersionedResourceInfos[i].type;
                          const timestamp = updateInfo.updateVersionedResourceInfos[j].timestamp;

                          switch(type){
                            case VersionedResourceType.queue_waiting: {
                              this.queueService.handleUpdateInfo(DTO_QueueType.Waiting, timestamp);
                              break;
                            }
                            case VersionedResourceType.queue_radio: {
                              this.queueService.handleUpdateInfo(DTO_QueueType.Radio, timestamp);
                              break;
                            }
                            case VersionedResourceType.queue_nextRadio: {
                              this.queueService.handleUpdateInfo(DTO_QueueType.NextRadio, timestamp);
                              break;
                            }
                            case VersionedResourceType.custom_playlists: {
                              this.playlistService.handleCustomPlaylistsUpdateInfo(timestamp);
                              break;
                            }
                            case VersionedResourceType.music_selection: {
                              this.musicSelectionService.handleMusicSelectionUpdateInfo(timestamp);
                              break;
                            }
                            case VersionedResourceType.calendarGroup: {
                              this.calendarGroupService.handleCalendarGroupsUpdateInfo(timestamp);
                              break;
                            }
                            case VersionedResourceType.musicChannelGroupRadio: {
                              this.musicChannelService.handleMusicChannelGroupsUpdateInfo(timestamp);
                              break;
                            }
                            case VersionedResourceType.messagesPlanning: {
                              this.messageBlocksService.handleMessagesPlanningUpdateInfo(timestamp);
                              break;
                            }
                            case VersionedResourceType.item_item_recommendation: {
                              this.recommendationsService.handleRecommendedMusicChannelGroupsUpdateInfo(timestamp);
                              break;
                            }
                            default: {
                              this.loggerService.error(this.LOGGER_NAME, "handle update versionedResourceInfo", "type not handled: " + updateInfo.updateVersionedResourceInfos[i].type);
                              break;
                            }

                          }

                        }
                    }
                    if (Object.prototype.hasOwnProperty.call(updateInfo, "updateVersionedIdResourceInfos") && updateInfo.updateVersionedIdResourceInfos instanceof Array){
                      for (let j = 0; j < updateInfo.updateVersionedIdResourceInfos.length; j++) {

                        const type = updateInfo.updateVersionedIdResourceInfos[i].type;
                        const timestamp = updateInfo.updateVersionedIdResourceInfos[j].timestamp;
                        const id = updateInfo.updateVersionedIdResourceInfos[j].id;

                        let matchData: string = null;
                        if (Object.prototype.hasOwnProperty.call(updateInfo.updateVersionedIdResourceInfos[j], 'matchData')){
                          matchData = updateInfo.updateVersionedIdResourceInfos[j].matchData;
                        }

                        switch(type){
                          case VersionedIdResourceType.playlist: {
                            this.playlistService.handlePlaylistUpdateInfo(id, timestamp);
                            break;
                          }
                          case VersionedIdResourceType.musicCollection: {
                            this.musicChannelService.handleMusicCollectionUpdateInfo(id, matchData, timestamp);
                            break;
                          }
                          case VersionedIdResourceType.calendar: {
                            this.calendarGroupService.handleCalendarUpdateInfo(id, timestamp);
                            this.calendarService.handleCalendarUpdateInfo(id, timestamp);
                            break;
                          }
                          default: {
                            this.loggerService.error(this.LOGGER_NAME, "handle update versionedIdResourceInfo", "type not handled: " + updateInfo.updateVersionedIdResourceInfos[i].type);
                            break;
                          }
                        }
                      }
                    }
                }
            }
        }
    }


  }

}
