import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * Use (tunResize)="onResize($event) on an html element that needs to detect any change on its size"
 *
 * The ResizedEvent is fired each time the html element resizes
 *
 * Note that this runs inside angulare zone, detaching a component in its constructor will cause to run the afterInit method
 */
export class ResizedEvent {
  public newRect: DOMRectReadOnly;
  public oldRect?: DOMRectReadOnly;
  public isFirst: boolean;

  public constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
      this.newRect = newRect;
      this.oldRect = oldRect;
      this.isFirst = oldRect == null;
  }
}

@Directive({
  selector: '[tunResize]'
})
export class ResizeDirective implements OnInit, OnDestroy {
  private observer: ResizeObserver;
  private oldRect?: DOMRectReadOnly;

  @Output()
  public readonly tunResize;

  public constructor(
    private readonly element: ElementRef,
    private readonly zone: NgZone
  )
  {
    this.tunResize = new EventEmitter<ResizedEvent>();
    this.observer = new ResizeObserver(entries => this.zone.run(() => this.observe(entries)));

    this.observer.observe(this.element.nativeElement);
  }

  public ngOnInit(): void {
    //console.log("init " + this.element.nativeElement);
    //Init is not called when the component is detached from change detection
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(entries: ResizeObserverEntry[]): void {
    const domSize = entries[0];
    const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
    this.oldRect = domSize.contentRect;
    this.tunResize.emit(resizedEvent);
  }

}
