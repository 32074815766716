import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TunifyChangeableParameterColor } from '@components/components-v5/changeable-parameters-panel-v5/changeable-parameters-panel-v5.component';
import { Calendar } from '@model/calendar';
import { CalendarItem } from '@model/calendarItem';
import { CalendarService } from '@service/calendar.service';
import { MusicChannelService } from '@service/music-channel.service';
import { MusicCollectionService } from '@service/music-collection.service';


@Component({
  selector: 'tun-overlay-tweak-calendar-item',
  templateUrl: './overlay-tweak-calendar-item.component.html',
  styleUrls: ['./overlay-tweak-calendar-item.component.scss']
})
export class OverlayTweakCalendarItemComponent implements OnChanges {

  @Input() calendar: Calendar;
  @Input()  calendarItem: CalendarItem;

  @Output() close = new EventEmitter<void>();

  public TunifyChangeableParameterColor = TunifyChangeableParameterColor;

  get isPlaylist(){
    return this.calendarItem && this.calendarItem.changeableParametersLoaded && this.calendarItem.changeableParameter == null
  }

  get isContext(){
    return this.calendarItem && this.calendarItem.changeableParametersLoaded && this.calendarItem.changeableParameter != null
  }

  get loading$(){
    return this.calendarItem.changeableParametersLoading$;
  }

  get loadingError(){
    return this.calendarItem && this.calendarItem.changeableParametersLoadingError;
  }

  get changeableParameters(){
    if (this.isContext){
      return this.calendarItem.changeableParameters
    }
    return [];
  }

  get tweakInfo() {
   if (
      this.isContext
    ) {
      return this.calendarItem.tweakInfo
    }
    return null;
  }

  get tweakInfoLoaded(): boolean {
    if (
      this.isContext
    ) {
      return this.calendarItem.tweakInfoLoaded
    }
    return false
  }

  get tweakInfoLoading(): boolean {
    if (
      this.isContext
    ) {
      return this.calendarItem.tweakInfoLoading;
    }
    return false
  }



  constructor(
    private musicChannelService: MusicChannelService,
    private musicCollectionService: MusicCollectionService,
    private calendarService: CalendarService
  ){
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes["calendarItem"]){
        this.loadDetailsIfNeeded();
      }
  }

  private loadDetailsIfNeeded(){
    if (this.calendarItem != null){
      if (!this.calendarItem.changeableParametersLoaded && !this.calendarItem.changeableParametersLoading){
        this.calendarService.loadCalendarItemDetails(this.calendarItem);
      }
    }
  }

  public retryClick(){
    this.loadDetailsIfNeeded();
  }



  public onClose(){
    this.close.emit();
  }

  public onToggleShuffle(event:MatSlideToggleChange){
    if (this.calendar != null && this.calendarItem != null){
      this.calendarItem.shufflePlaylist = event.checked;
      this.calendarService.saveCalendarItem(this.calendar, this.calendarItem);
    }
  }

  public onParameterChange(){
    if (this.calendar != null && this.calendarItem != null){
      this.calendarService.adjustParametersForCalendarItem(this.calendar, this.calendarItem);
      this.musicCollectionService.loadTweakInfo(this.calendarItem);
    }
  }

  public onReset(){
    if (this.calendar != null && this.calendarItem != null){
      this.calendarService.resetParameters(this.calendar, this.calendarItem);
    }
  }

}
