import { Component, Input } from '@angular/core';
import { MusicChannel } from '@model/musicChannel';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';

@Component({
  selector: 'tun-search-music-channel-button-view',
  templateUrl: './search-music-channel-button-view.component.html',
  styleUrls: ['./search-music-channel-button-view.component.scss']
})
export class SearchMusicChannelButtonViewComponent {

  @Input() searchMusicChannel: MusicChannel;

  constructor(
    private appV5StateService:AppV5StateService
  ){

  }

  public onShowDetails(){
    this.appV5StateService.showSearchMusicChannelInTunifyOrange(this.searchMusicChannel);
  }
}
