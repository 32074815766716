import { Component } from '@angular/core';

@Component({
  selector: 'tun-track-options',
  templateUrl: './track-options.component.html',
  styleUrls: ['./track-options.component.scss']
})
export class TrackOptionsComponent {

}
