<div class="wrapper">
  <div class="title">{{'playlist.label.title' | translate }}</div>

  <ng-container *ngIf="(playlistsLoading$ | async)">
    <tun-loader-v5 class="loader"></tun-loader-v5>
  </ng-container>

  <div *ngIf="(playlistsLoadingError$ | async)" class="errorContainer">
    <p>{{'general.status.error.message' | translate}}</p>
    <button (click)="onRetry()" class="retryButton">
      <p>
        {{'general.status.error.retry' | translate }}
      </p>
    </button>
  </div>

  <div >
  </div>

  <button
    *ngIf="!(playlistsLoading$ | async) && !(playlistsLoadingError$ | async)"
    class="create-button" (click)="onCreatePlaylist()"
  >
    <div class="create-icon-container">
      <tun-plus-icon-v5 class="icon icon-create-button"></tun-plus-icon-v5>
    </div>
     {{'playlist.button.newList' | translate}}
  </button>

  <div *ngIf="!(playlistsLoading$ | async) && !(playlistsLoadingError$ | async)" class="scrollContainer">

    <tun-playlist-button-view
      class="playlist first"
      *ngIf="favoriteList$ | async"
      [playlist]="favoriteList$ | async"
      isFavorite="true"
    >
    </tun-playlist-button-view>


    <ng-container *ngFor="let playlist of (playlists$ | async); index as i">
      <tun-playlist-button-view
        class="playlist {{ (i == 0 && (favoriteList$ | async) == null) ? 'first' : ''}}"
        [playlist]="playlist"
      >
      </tun-playlist-button-view>
    </ng-container>
  </div>
</div>
