import { Injectable, OnDestroy, ApplicationModule } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { LoggerService } from '../loggers/logger.service';
import { Subject, Observable, BehaviorSubject, Subscribable, Subscription } from 'rxjs';
import { Config } from '@service/config';
import { retry, finalize, takeUntil, filter } from 'rxjs/operators';
import { MusicPlayerService } from '@service/music-player.service';
import { setTimeout } from 'timers';
import { environment } from 'src/environments/environment';
import { ZoneConfigurationService } from './zone-configuration.service';
import { ApplicationMode, ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { SubscriptionsService } from './subscriptions.service';

/**
 * This class is responsible for handling playToken logic and decide if we are in player mode or remote mode.
 *
 * To choose the correct mode, this service works together with the remoteService:
 * When no other player is active, we can safely start player mode and generate a new playtoken from our servers.
 * When an other player is active, we need to ask the user for permission to take over.
 */

interface PlayTokenResponse {
    playToken: string;
}



@Injectable({
  providedIn: 'root'
})
export class PlayTokenService implements OnDestroy {

    private LOGGER_CLASSNAME = 'PlayTokenService';

    constructor(private http: HttpClient,
            private zoneConnectionsService: ZoneConnectionsService,
            private subscriptionsService: SubscriptionsService,
            private loggerService: LoggerService) {
              //we do this at startup for now, we should only be doing this once the user want to start playback (or after checking no other players are active)

        this.zoneConnectionsService.applicationMode$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          (applicationMode) => {
            if (applicationMode == ApplicationMode.playerMode){
              this.loadPlayToken();
            }else{
              this.invalidatePlayToken();
            }
          }
        )

        /*
        this.zoneConnectionsService.activeZoneConnection$
        .pipe(
            takeUntil(this.destroyed$)
        )
        .subscribe(
            (zoneConnection) => {
                if (zoneConnection != null){
                    //we just logged in, we need to check if there is already a player connected to this zone
                    this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged in ... starting playToken data');
                    this.startup();
                }else{
                    this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged out ... cleaning up playToken info');
                    this.cleanUpData();
                }
            },
            (err: unknown) => {
                this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'error from loginInSubscription in RemoteService: ' + err);
            }
        );
        */

        this.subscriptionsService.accessRights$
        .pipe(
            takeUntil(this.destroyed$)
        )
        .subscribe(
            (accessRigths) => {
                if (accessRigths != null){
                    if (!accessRigths.playMusic){
                        this.invalidatePlayToken();
                    }
                }
            }
        )
    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy(){
        this.destroyed$.next();
        this.destroyed$.complete();
        this.destroyed$ = null;

        this.cleanUpData();
    }

    private cleanUpData(){
        this._playToken = null;
        this._loading = null;
        this._loadingError = null;
        //todo -> we should also clean up the http request if one is busy!
    }







    /*
    private otherPlayerConnected: boolean;
    public remotePlayerInfoReceived(otherPlayerConnected: boolean, otherPlayerName: string){
        this.otherPlayerConnected = otherPlayerConnected;
        if (this.applicationMode == null){
            if (otherPlayerConnected && environment.allowMultipleLogin){
                //we are on startup -> ask for permission to start?
                this._applicationMode = ApplicationMode.remoteMode;
                this.loggerService.warn(this.LOGGER_CLASSNAME, "remotePlayerInfoReceived", "starting up application and other player is active -> going into remote mode");
            }else{
                this._applicationMode = ApplicationMode.playerMode;
                this.loggerService.debug(this.LOGGER_CLASSNAME, "remotePlayerInfoReceived", "starting up application and NO other player is active -> going into player mode");
            }
        }else if (this.applicationMode === ApplicationMode.playerMode){

        }else if (this.applicationMode === ApplicationMode.remoteMode){
            if (!otherPlayerConnected){
              this.loggerService.warn(this.LOGGER_CLASSNAME, "remotePlayerInfoReceived", "application is in remote mode and NO other player is active -> Can not listen to anything, ask user to go to player mode?");
                //todo -> no other player is connected. Show message to take over playermode?
            }
        }else{
            this.loggerService.error(this.LOGGER_CLASSNAME, "remotePlayerInfoReceived", "applicationMode not recognized: " + this.applicationMode);
        }
    }
    */



    /**
     * This method is trigerred when:
     *  - an invalid playToken check is received while fetching httpUrls
     *  - playMusic right is false
     *  */
    public invalidatePlayToken(otherDeviceName?: string){
      const otherDeviceForLogging = otherDeviceName ? otherDeviceName : 'none';
      if (this._playToken){
        this.loggerService.warn(this.LOGGER_CLASSNAME, "invalidatePlayToken", "going to disconnect. Other device: " + otherDeviceForLogging);
          this._playToken = null;
          this.zoneConnectionsService.deactivateCurrentZoneConnection(null, false, false);
      }else{
          //we did not started our playToken -> Wait until we receive one (the other player will log out)
          this.loggerService.warn(this.LOGGER_CLASSNAME, "invalidatePlayToken", "Still waiting for our play token. Other device: " + otherDeviceForLogging);
      }
    }

    /*
    public changeToPlayerMode(){
        this.loggerService.debug(this.LOGGER_CLASSNAME, "changeToPlayerMode", "app is going to playmode");
        this._applicationMode = ApplicationMode.playerMode;
    }
    */




    /**
     * loading
     */
    private get _loading(): boolean {
        return this._loadingSubject.value;
    }
    private set _loading(value: boolean) {
        if (this._loading !== value) {
            this._loadingSubject.next(value);
        }
    }
    get loading(): boolean {
        return this._loading;
    }
    private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loading$: Observable<boolean> = this._loadingSubject.asObservable();

    /**
     * Error emitter for retrieving the musicChannelGroups
     * @type {string}
     * @private
     */
    private __loadingError: string = null;
    private get _loadingError(): string {
        return this.__loadingError;
    }
    private set _loadingError(value: string) {
        if (this.__loadingError !== value) {
            this.__loadingError = value;
            this._loadingErrorSubject.next(this.__loadingError);
        }
    }
    get loadingError(): string{
        return this._loadingError;
    }
    private _loadingErrorSubject: Subject<string> = new BehaviorSubject<string>(null);
    public loadingError$: Observable<string> = this._loadingErrorSubject.asObservable();

    /**
     * playToken
     */
    private get _playToken(): string  {
        return this._playTokenSubject.value;
    }
    private set _playToken(value: string ) {
        if (this._playToken !== value) {
            this._playTokenSubject.next(value);
            if (this.playToken != null){
                this.loggerService.registerNewPlayToken(this.playToken);
            }
        }
    }
    get playToken(): string  {
        return this._playToken;
    }
    private _playTokenSubject: BehaviorSubject<string > = new BehaviorSubject<string >(null);
    public playToken$: Observable<string > = this._playTokenSubject.asObservable();

    public loadPlayToken(): Observable<string>{

        this.loggerService.debug(this.LOGGER_CLASSNAME, "loadPlayToken", "loading started");

        this._loading = true;

        let playTokenObservable:Observable<PlayTokenResponse>;



        if (this.zoneConnectionsService.activeZoneConnection) {
          const zoneId = this.zoneConnectionsService.activeZoneConnection.zoneId
          const url = Config.api4Url_playToken(zoneId);
          playTokenObservable = this.http.get<PlayTokenResponse>(url);


          playTokenObservable
          .pipe(
            finalize(() => {
              this._loading = false;
            }),
            takeUntil(this.zoneConnectionsService.activeZoneConnection$.pipe(filter(zoneConnection => zoneConnection == null || zoneConnection.zoneId != zoneId))) //cancel the request when the zone changes
          )
          .subscribe(
              (data) => {
                  //this.logger.debug(this.LOGGER_CLASSNAME, "loadMusicChannelGroups", "data : " + data);

                  this._playToken = data.playToken;
              },
              (error: unknown) => {
                let errMsg = 'Server error: ' + error;
                if (error instanceof HttpErrorResponse){
                  errMsg = (error.message) ? error.message :
                      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                }
                this.loggerService.error(this.LOGGER_CLASSNAME, "loadPlayToken", "Error loading: " + errMsg);
              }
          );
        }else{
          this.loggerService.warn(this.LOGGER_CLASSNAME, "loadPlayToken", "No active zoneConnection when trying to load a playtoken");
        }


        return this.playToken$;
    }
}
