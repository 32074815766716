import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ZoneConfigurationService } from '@service/zone-configuration.service';

/**
 * This interceptor is responsible for forcing a language
 */

@Injectable()
export class ForceLanguageInterceptor implements HttpInterceptor {
 
  constructor(private zoneConfiguration: ZoneConfigurationService) {}
 
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    
    //we don't want to add authorizontion in some cases
    if (
      !environment.mockBackend && 
      !req.url.includes("assets") //assets loaded over http (translations)
    ) {
      
      if (this.zoneConfiguration.language || this.zoneConfiguration.forcedLanguage){
        req = req.clone({
          headers: req.headers.set("Accept-Language", this.zoneConfiguration.usedLanguage)
        });
      }
    }

    return next.handle(req);
  }
}