<div class="wrapper">

  <!--
  <div class="imageWrapper">
    <img class="backgroundImage" [src]="imageUrl$ | async">
    <div class="gradientLayer"></div>
  </div>
  -->


  <div class="contentWrapper {{showingPopup ? 'blurredContent' : ''}}">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <button class="icon-button first-title-item" (click)="onPlayAll()">
          <tun-play-icon-v5 class="icon colored-icon"></tun-play-icon-v5>
        </button>

        <div class="title">
          {{title}}
        </div>


        <button class="icon-button" [disabled]="!canEditProperties" [matMenuTriggerFor]="menu">
          <tun-track-options class="icon"></tun-track-options>
        </button>
        <mat-menu #menu="matMenu">
          <button class="option-button" (click)="onChangeName()">
            <tun-edit-icon class="icon option-button-icon"></tun-edit-icon>
            <span translate>playlist.extraAction.changeName</span>
          </button>
          <button class="option-button" (click)="onDelete()">
            <tun-delete-icon-v5 class="icon option-button-icon"></tun-delete-icon-v5>
            <span translate>playlist.extraAction.delete</span>
          </button>
        </mat-menu>



      </div>

      <div class="remainingSpace"></div>

    </div>

    <ng-container *ngIf="showPlaylist">

      <!-- Special playlist states indicator -->

      <div
        *ngIf="playlistState != PlaylistState.normal"
        [ngSwitch]="playlistState"
        class="feedback-container"
      >
        <div
          *ngSwitchCase="PlaylistState.loadError"
          class="error-feedback-container"
        >
          <p>
            {{'calendar.contentPanel.load.error' | translate }}
          </p>
          <button (click)="retryClick()" class="retryButton">
            <p>
              {{'general.tryAgain' | translate }}
            </p>
          </button>
        </div>

        <div
          *ngSwitchCase="PlaylistState.empty"
          class="status-feedback-container"
        >
          <tun-menu-playlist-icon-v5 class="feedback-icon"></tun-menu-playlist-icon-v5>
          <p translate>playlist.status.empty</p>

          <button
            class="add-button" (click)="onAddSongs()"
          >
            <div class="add-icon-container">
              <tun-plus-icon-v5 class="icon icon-add-button"></tun-plus-icon-v5>
            </div>
            {{'playlist.button.addSongs' | translate}}
          </button>
        </div>

        <ng-container *ngSwitchCase="PlaylistState.loading">
          <tun-loader-v5 class="loader"></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="PlaylistState.creating">
          <tun-loader-v5 text="playlist.status.creating"></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="PlaylistState.deleting">
          <tun-loader-v5 text="playlist.status.deleting"></tun-loader-v5>
        </ng-container>

        <div
          *ngSwitchCase="PlaylistState.deleted"
          class="error-feedback-container"
        >
          <p translate>playlist.status.deleted</p>
          <button (click)="onBack()" class="retryButton">
            <p translate>playlist.status.deleted.close</p>
          </button>
        </div>
      </div>

      <div
        *ngIf="playlistState == PlaylistState.normal"
        class="amount-title"
      >
        <button
          class="add-button" (click)="onAddSongs()"
        >
          <div class="add-icon-container">
            <tun-plus-icon-v5 class="icon icon-add-button"></tun-plus-icon-v5>
          </div>
          {{'playlist.button.addSongs' | translate}}
        </button>
      </div>

      <div
        *ngIf="playlistState == PlaylistState.normal"
        class="select-title"
      >
        <div class="select-front">
          <tun-checkbox-v5
          class="select-all-checkbox"
          [title]="selectTitle"
          coloredTitleOnCheck="true"
          [checkBoxColor]="TunifyCheckBoxColor.ORANGE"
          [isChecked]="allComplete"
          [indeterminate]="someComplete()"
          (isCheckedChanged)="setAll($event)"
        >
        </tun-checkbox-v5>

        <button *ngIf="checkedTracks.length != 0" class="unselect-link-button" (click)="setAll(false)">
          <p translate>
            search.result.deselect.all
          </p>
        </button>

        </div>


        <button class="icon-button colored-action-button" [disabled]="checkedTracks.length == 0" (click)="onAddSelectedToQueue()">
          <tun-add-to-queue-v5 class="icon"></tun-add-to-queue-v5>
        </button>

        <button class="icon-button colored-action-button" [disabled]="checkedTracks.length == 0" (click)="onAddSelectedToPlaylist()">
          <tun-add-to-playlist-v5 class="icon"></tun-add-to-playlist-v5>
        </button>
      </div>

      <!-- normal content -->
      <cdk-virtual-scroll-viewport
        *ngIf="playlistState == PlaylistState.normal"
        #scrollViewport
        id="playlistTrackList"
        class="trackTable"
        [itemSize]="virtualScrollingItemSize$ | async"
        cdkDropList
        [cdkDropListData]="trackTableItems"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="['queueHeader', 'queue']"
        [cdkDropListAutoScrollDisabled]="disableAutoScroll$ | async"
      >
        <!--
        ---- templateCacheSize: 0
        ---- We can not use a cache to support drag & drop through the angular cdk
        ---- Drag & drop caches items indexes, this cache is not re-calculated if an item moves from on-screen to the cache
        -->



        <ng-container>
            <tun-track-view
              *cdkVirtualFor="
              let trackTableItem of trackTableItems;
              let i = index;
              templateCacheSize: 0
              "
              class="trackItem"
              [track]="trackTableItem.track"
              [useSelectionBox]="true"
              [showSelectionBox]="showSelectionBox"
              [isSelected]="isTrackChecked(trackTableItem.track)"
              (checkedChanged)="onCheckedChanged($event, trackTableItem.track)"
              [showAudioFileProperty]="audioFileProperty$ | async"
              tunifyColor="TunifyColor.orange"
              (play)="onPlay(trackTableItem.track)"
              [playEnabled]="startTrackEnabled$ | async"
              (showOptions)="onShowOptions(trackTableItem.track)"
              [style.height.px]="trackItemSize$ | async"
              cdkDrag
              [cdkDragData]="trackTableItem"
            >
            </tun-track-view>

        </ng-container>



      </cdk-virtual-scroll-viewport>



    </ng-container>


  </div>

  <!-- Popup: choose playlist-->
  <div #choosePlaylistPopupWrapper *ngIf="showChoosePlaylistOverlay" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, choosePlaylistPopupWrapper)">
    <tun-overlay-select-playlist
      class="select-playlist-overlay"
      confirmButtonTranslateableText="playlist.addTrack.popup.button.commit"
      (closeWithPlaylist)="onCloseSelectPlaylist($event)"
    >
    </tun-overlay-select-playlist>
  </div>


</div>

