import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-settings-music-rights-panel',
  templateUrl: './settings-music-rights-panel.component.html',
  styleUrls: ['./settings-music-rights-panel.component.scss']
})
export class SettingsMusicRightsPanelComponent {

  @Output() back = new EventEmitter<void>();

  get sabamId$(){
    return combineLatest([
      this.translateService.get('settings.musicRights.sabamId'),
      this.zoneConfigurationService.sabamId$
    ])
    .pipe(
      map(([text, sabamId]) => {
        return text.replace("{0}", sabamId);
      })
    );
  }


  constructor(
    private translateService: TranslateService,
    private zoneConfigurationService: ZoneConfigurationService
  ) {

  }

  onBack(){
    this.back.emit();
  }
}
