import { Component } from '@angular/core';

@Component({
  selector: 'tun-star-icon-v5',
  templateUrl: './star-icon-v5.component.html',
  styleUrls: ['./star-icon-v5.component.scss']
})
export class StarIconV5Component {

}
