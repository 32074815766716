<div class="wrapper">
  <div class="title">
    {{'search.autocompletion.title' | translate | uppercase}}
  </div>
  <div class="autocomplete-results">
    <ng-container *ngIf="autoCompleteResult">
    <ng-container *ngFor="let suggestion of autoCompleteResult.suggestions">
      <button class="autocompletion-button" (mousedown)="selectAutocompletion(suggestion)">
        <tun-autocomplete-song-icon-v5 *ngIf="suggestion.type == AutocompletionType.Song"  class="icon icon-scaled-svg-button icon-autocomplete-button"></tun-autocomplete-song-icon-v5>
        <tun-autocomplete-artist-icon-v5 *ngIf="suggestion.type == AutocompletionType.Artist"  class="icon icon-scaled-svg-button icon-autocomplete-button"></tun-autocomplete-artist-icon-v5>

        {{suggestion.autocompletionText}}
      </button>
    </ng-container>

    </ng-container>
  </div>

</div>
