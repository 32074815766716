import { slideUpAnimation } from '@util/animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { ListRange } from '@angular/cdk/collections';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CdkVirtualForOf } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TunifyCheckBoxColor } from '@components/components-v5/checkbox-v5/checkbox-v5.component';
import { TrackTableItem } from '@components/components-v5/track-list-view/track-list-view.component';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { AudioFile } from '@model/audioFile';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { Playlist } from '@model/playlist';
import { TranslateService } from '@ngx-translate/core';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { PlaylistService } from '@service/playlist.service';
import { QueueService } from '@service/queue.service';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { BehaviorSubject, Subject, combineLatest, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DeletePlaylistConfirmPopupComponent } from '@components/popups-v5/delete-playlist-confirm-popup/delete-playlist-confirm-popup.component';
import { SubscriptionsService } from '@service/subscriptions.service';

export enum PlaylistState{
  normal,
  creating,
  deleting,
  deleted,
  loading,
  loadError,
  empty,
  none
}

@Component({
  selector: 'tun-playlist-detail-content-view',
  templateUrl: './playlist-detail-content-view.component.html',
  styleUrls: ['./playlist-detail-content-view.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]
})
export class PlaylistDetailContentViewComponent implements OnDestroy {

  @Output() changeName = new EventEmitter<Playlist>();
  @Output() addSongs = new EventEmitter<Playlist>();

  /**
   * BUG SEMI FIX:
   *
   * Virtual scrolling and autoscroll during drag&drop does not work
   *
   * Strategy:
   * Less than 100 items: no virtual scrolling and allow autoscroll
   * 100 items+: virtual scrolling and disable autoscroll
   */

  public LOGGER_NAME = PlaylistDetailContentViewComponent.name;
  private SNACKBAR_DURATION = 5000;

  public TunifyColor = TunifyColor
  public TunifyCheckBoxColor = TunifyCheckBoxColor

  public get showingPopup(){
    return this.showChoosePlaylistOverlay;
  }
  public showChoosePlaylistOverlay = false

    public get disableAutoScroll$(){
      return this.disableAutoScrollSubject$.asObservable();
    }
    private disableAutoScrollSubject$ = new BehaviorSubject<boolean>(false);
    private adjustDisableAutoScrollSubject(){
      const newValue = this.showPlaylist && this.showPlaylist.audioFiles && this.showPlaylist.audioFiles.length > 100;
      if (newValue != this.disableAutoScrollSubject$.value){
        this.disableAutoScrollSubject$.next(newValue);
      }

    }

    //virtual scrolling can be disabled by providing no value for itemSize
    get virtualScrollingItemSize$(){
      return combineLatest([this.trackItemSize$, this.disableAutoScroll$])
      .pipe(
        map(
          ([itemSize, disableAutoScroll]) => {
            if (disableAutoScroll){
              return itemSize;
            }
            return "corrupt";
          }
        )
      )
    }


  //@ViewChild(CdkVirtualForOf, {static: true}) private virtualForOf: CdkVirtualForOf<TrackTableItem[]>;

  private range: ListRange;

  private virtualForOfChanged$ = new Subject<void>();
  private _virtualForOf: CdkVirtualForOf<TrackTableItem[]>;

  @ViewChild(CdkVirtualForOf) set virtualForOf(virtualForOf: CdkVirtualForOf<TrackTableItem[]>) {
    this.virtualForOfChanged$.next();
    this._virtualForOf = virtualForOf;

    if(this._virtualForOf) {
      //console.log("virtualForOf set")

        this._virtualForOf.viewChange
          .pipe(
            takeUntil(
              merge(
                this.virtualForOfChanged$,
                this.destroyed$
              )
            )
          )
          .subscribe((range: ListRange) => {
            this.range = range
            //console.log("range set: " + range.start + " to " + range.end)
          });
    }
 }


 private trackTableItemsChanged$ = new Subject<void>();
 private _trackTableItems: TrackTableItem[];
 public set trackTableItems(value: TrackTableItem[]){
   this._trackTableItems = value;
   this.trackTableItemsChanged$.next();
 }
 public get trackTableItems(){
   return this._trackTableItems;
 }


  private _showPlaylist: Playlist;
  private showingPlaylistChanged$ = new Subject<void>();
  @Input()
  set showPlaylist(value: Playlist) {
    this._showPlaylist = value

    this.adjustDisableAutoScrollSubject()

    this.showingPlaylistChanged$.next();
    if (this.showPlaylist){
      this.showPlaylist.audioFiles$
        .pipe(
          takeUntil(
            merge(
              this.showingPlaylistChanged$,
              this.destroyed$
            )
          )
        )
        .subscribe(
          audioFiles => {
            this.adjustDisableAutoScrollSubject()

            if (audioFiles){
              this.trackTableItems = audioFiles.map(track => new TrackTableItem(track))
            }else{
              this.trackTableItems = [];
            }

          }
        )
    }else{
      this.trackTableItems = [];
    }

    this.changeDetectorRef.detectChanges();
  }
  get showPlaylist():Playlist{
    return this._showPlaylist;
  }

  get canEditProperties(): boolean {
    return this._showPlaylist != null && this.playlistService.playlists.filter(playlist => playlist.id == this._showPlaylist.id).length > 0;
  }

  public get trackItemSize$(){
    return this.appV5StateService.pixelsFor1Rem$
    .pipe(
      map(px => {
        return 3 * px
      })
    )
  }

  public get title(){
    if (this.showPlaylist){
      return this.showPlaylist.title
    }
    return ""
  }

  //make the enum available in the html template
  public PlaylistState = PlaylistState;

  get playlistState(): PlaylistState{
    if (this.showPlaylist){
      if (this.showPlaylist.creatingPlaylist){
        return PlaylistState.creating;
      }else if (this.showPlaylist.deletingPlaylist){
        return PlaylistState.deleting;
      }else if (this.showPlaylist.deleted){
        return PlaylistState.deleted;
      }else if (this.showPlaylist.detailsLoading){
        return PlaylistState.loading;
      }else if (this.showPlaylist.detailsLoadingError != null){
        return PlaylistState.loadError;
      }else if (this.showPlaylist.audioFiles == null){
        return PlaylistState.none;
      }else if (this.showPlaylist.audioFiles.length == 0){
        return PlaylistState.empty;
      }
      return PlaylistState.normal;
    }
    return PlaylistState.none;
  }

  get audioFileProperty$(){
    return this.zoneConfigurationService.audioFileProperty$
  }


  public get startTrackEnabled$(){
    return this.subscriptionsService.accessRights$
      .pipe(
        map(
          (accessRights => {
            return accessRights == null || accessRights.startTrack
          })
        )
      )
  }

  constructor(
    private loggerService: LoggerService,
    private zoneConfigurationService: ZoneConfigurationService,
    private appV5StateService: AppV5StateService,
    private changeDetectorRef: ChangeDetectorRef,
    private musicManipulationService: MusicManipulationService,
    private queueService: QueueService,
    private playlistService: PlaylistService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private trackOverlayService: TrackOverlayService,
    private dialog: MatDialog,
    private subscriptionsService: SubscriptionsService
  ) {

  }


  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onBack(){
    this.appV5StateService.showPlaylistInTunifyOrange(null);
  }


  /* Handlers for header */

  public onPlayAll(){
    if (this.showPlaylist && this.showPlaylist.audioFiles){
      if (this.showPlaylist.audioFiles.length > 0){
        const tracksToQueue: AudioFile[] = [];
        this.showPlaylist.audioFiles.forEach(track => tracksToQueue.push(createClassObjectForAudioFile(track)));

        const trackToPlay = tracksToQueue.shift()
        this.musicManipulationService.playAudioFile(trackToPlay);

        if (tracksToQueue.length > 0){
          this.queueService.clearQueue();
          this.queueService.addAudioFilesToQueue(tracksToQueue);
        }
      }
    }
  }

  /* Handler for selection header */
  public checkedTracks : AudioFile[] = [];

  get showSelectionBox(){
    return this.checkedTracks.length > 0
  }

  get selectTitle(){
    if (this.checkedTracks.length == 0){
      return this.translateService.instant('search.result.select.all')
    }else if (this.checkedTracks.length == 1){
      return this.translateService.instant('search.result.select.amount.one')
    }else{
      return this.translateService.instant('search.result.select.amount.many').replace("{0}", this.checkedTracks.length);
    }
  }

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.trackTableItems.every(trackTableItem => this.checkedTracks.includes(trackTableItem.track))
  }

  someComplete(): boolean {
    if (this.trackTableItems == null) {
      return false;
    }
    return !this.allComplete && this.checkedTracks.length > 0;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.allComplete){
      this.checkedTracks = this.trackTableItems.map(trackTableItem => trackTableItem.track);
    }else{
      this.checkedTracks = [];
    }
  }

  onAddSelectedToQueue(){
    this.musicManipulationService.addAudioFilesToQueue(this.checkedTracks)
    .pipe(
      takeUntil(
        merge(
          this.trackTableItemsChanged$,
          this.destroyed$
        )
      )
    ).subscribe(
      (asyncStatus) => {
        if (asyncStatus == AsyncStatus.COMPLETED){
            const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
              duration: this.SNACKBAR_DURATION,
              panelClass: ['tunify-snackbar']
            });

        }
      }
    );


  }

  onAddSelectedToPlaylist(){
    this.showChoosePlaylistOverlay = true
  }


  /* Handlers for track item */
  public onPlay(track: AudioFile){
    this.musicManipulationService.playAudioFile(track);
  }

  public onShowOptions(track: AudioFile){
    this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = new TrackInfoOverlayData(track, new TrackInfoContext(TrackInfoContextMode.playlist, this.showPlaylist));
  }

  public onCheckedChanged(value:boolean, track: AudioFile){
    if (value){
      //add to checkedTracks
      if (this.checkedTracks.filter(t => t == track).length == 0){
        this.checkedTracks.push(track);
        this.updateAllComplete()
      }else{
        this.loggerService.error(this.LOGGER_NAME, "onCheckedChanged", "checked a track that was already checked");
      }
    }else{
      if (this.checkedTracks.filter(t => t == track).length > 0){
        this.checkedTracks = this.checkedTracks.filter(t => t != track);
        this.updateAllComplete()
      }else{
        this.loggerService.error(this.LOGGER_NAME, "onCheckedChanged", "un-checked a track that was already un-checked");
      }
    }
  }

  public isTrackChecked(track: AudioFile){
    return this.checkedTracks.filter(t => t == track).length > 0
  }

  /* Select playlist popup */
  onCloseSelectPlaylist(playlist: Playlist){
    if (playlist && this.checkedTracks.length > 0){
      this.playlistService.addAudioFileToPlaylist(playlist, this.checkedTracks)
      .pipe(
        takeUntil(
          merge(
            this.trackTableItemsChanged$,
            this.destroyed$
          )
        )
      ).subscribe(
        (asyncStatus) => {
          if (asyncStatus == AsyncStatus.RUNNING){
            /*
            const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
              duration: this.SNACKBAR_DURATION,
              panelClass: ['tunify-snackbar']
            });
            */

        }
          if (asyncStatus == AsyncStatus.COMPLETED){

              const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });

          }
        }
      );
    }
    this.showChoosePlaylistOverlay = false;
  }


  onClickOutside(event: Event, item){
    if (event.target !== item)
      return;
    this.showChoosePlaylistOverlay = false;
  }


  /* Drag & drop */

  public drop(event: CdkDragDrop<TrackTableItem[]>) {

		if( !event.isPointerOverContainer) {
			return
		}

    const PREV_IND_WITH_OFFSET: number = (this.range ? this.range.start : 0) + event.previousIndex;
    const CUR_IND_WITH_OFFSET: number = (this.range ? this.range.start : 0) + event.currentIndex;

    if (event.item.data instanceof TrackTableItem){
      const track = event.item.data.track;

      //move from inside
      if (event.previousContainer == event.container){

        //move to some position -> do nothing
        if (PREV_IND_WITH_OFFSET == CUR_IND_WITH_OFFSET){
          return
        }
        this.playlistService.moveAudioFileInPlaylist(this.showPlaylist, [track], CUR_IND_WITH_OFFSET);
      }else{
        this.playlistService.addAudioFileToPlaylist(this.showPlaylist, [track], CUR_IND_WITH_OFFSET);
      }

    }
  }

  onAddSongs(){
    if (this.showPlaylist){
      this.addSongs.next(this.showPlaylist);
    }
  }

  onChangeName(){
    if (this.showPlaylist){
      this.changeName.next(this.showPlaylist);
    }
  }

  onDelete(){
    if (this.showPlaylist){
      this.playlistService.deletePlaylist(this.showPlaylist);
    }
  }
}
