import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tun-home-panel-main-content-view',
  templateUrl: './home-panel-main-content-view.component.html',
  styleUrls: ['./home-panel-main-content-view.component.scss']
})
export class HomePanelMainContentViewComponent {
  public get enableHome(){
    return environment.enableHome;
  }
}
