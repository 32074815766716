import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { Playlist } from '@model/playlist';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';

@Component({
  selector: 'tun-song-info-select-playlist-view',
  templateUrl: './song-info-select-playlist-view.component.html',
  styleUrls: ['./song-info-select-playlist-view.component.scss']
})
export class SongInfoSelectPlaylistViewComponent {

  @Input() track: AudioFile;

  @Output() back = new EventEmitter<void>();

  constructor(
    private trackOverlayService: TrackOverlayService,
  ) {

  }


  public onBack(){
    this.back.emit();
  }

  public onCloseWithPlaylist(playlist: Playlist){
    if (this.track && playlist){
      this.trackOverlayService.addToPlaylist(this.track, playlist);
    }
  }
}
