export const search2  = [{
    "@type": "Song",
    "id": 412107,
    "duration": 165212,
    "group": "Martin Jensen ft Bjørnskov",
    "location": "2-7/000412107a9089c.mp3",
    "title": "Somebody I'm Not",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 100,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 33,
    "endAudioSignal": 164397,
    "fadeBeginPosition": 161767,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 409330,
    "duration": 226959,
    "group": "Robyn",
    "location": "2-30/000409330eb0120.mp3",
    "title": "Honey",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 116,
    "musicFeel": "Dance",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 37,
    "endAudioSignal": 224403,
    "fadeBeginPosition": 216810,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Dance",
    "rhythmDescription": "Free Dance",
    "tunifyTipValue": null
},
{
    "@type": "Song",
    "id": 413581,
    "duration": 158417,
    "group": "Don Diablo & Nate Dogg",
    "location": "2-81/000413581c336ff.mp3",
    "title": "I Got Love",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 123,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 32,
    "endAudioSignal": 157305,
    "fadeBeginPosition": 156268,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free Dance",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412141,
    "duration": 194601,
    "group": "Little Mix ft Kamille",
    "location": "2-41/00041214146e380.mp3",
    "title": "More Than Words",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 67,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Free",
    "startAudioSignal": 77,
    "endAudioSignal": 191823,
    "fadeBeginPosition": 183156,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 413741,
    "duration": 175052,
    "group": "Netsky, Toulouse & StarGate",
    "location": "2-41/00041374194acf3.mp3",
    "title": "Voodoo Magic",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 170,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 169274,
    "fadeBeginPosition": 168940,
    "musicStyle": "Electronic",
    "subStyle": "Basic",
    "genreDescription": "Electronic Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412543,
    "duration": 174835,
    "group": "Kris Kross Amsterdam, Ally Brooke & Messiah",
    "location": "2-43/000412543872a2b.mp3",
    "title": "Vámonos",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 105,
    "musicFeel": "Reggae",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 174316,
    "fadeBeginPosition": 173316,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Reggae",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412894,
    "duration": 231558,
    "group": "Clean Bandit, KYLE & Big Boi",
    "location": "2-94/000412894230c31.mp3",
    "title": "Out At Night",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 105,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 228780,
    "fadeBeginPosition": 228076,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412226,
    "duration": 197622,
    "group": "Blanche",
    "location": "2-26/0004122269d1dbd.mp3",
    "title": "Moment",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 182,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 195510,
    "fadeBeginPosition": 193399,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 410668,
    "duration": 184111,
    "group": "Ava Max",
    "location": "2-68/000410668b9c276.mp3",
    "title": "Sweet But Psycho",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 133,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 4,
    "endAudioSignal": 182666,
    "fadeBeginPosition": 179999,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 410128,
    "duration": 211650,
    "group": "Jess Glynne",
    "location": "2-28/000410128907379.mp3",
    "title": "Thursday",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 112,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 90,
    "endAudioSignal": 210538,
    "fadeBeginPosition": 208538,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 409804,
    "duration": 198179,
    "group": "Angèle ft Roméo Elvis",
    "location": "2-4/0004098047db682.mp3",
    "title": "Tout Oublier",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 105,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 196734,
    "fadeBeginPosition": 194623,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 413120,
    "duration": 170168,
    "group": "Laura Tesoro",
    "location": "2-20/0004131203a2006.mp3",
    "title": "Mutual",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 96,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 25,
    "endAudioSignal": 169390,
    "fadeBeginPosition": 164723,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 413209,
    "duration": 181642,
    "group": "Mike Mago & Dog Collective",
    "location": "2-9/000413209fb4c1d.mp3",
    "title": "Always On My Mind",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 124,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 6,
    "endAudioSignal": 179864,
    "fadeBeginPosition": 178530,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free Dance",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412582,
    "duration": 195834,
    "group": "Jamie Lawson",
    "location": "2-82/000412582455f3d.mp3",
    "title": "Testify",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 108,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 194722,
    "fadeBeginPosition": 186056,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 410960,
    "duration": 179581,
    "group": "Ellie Goulding, Diplo & Swae Lee",
    "location": "2-60/00041096097af7c.mp3",
    "title": "Close To Me",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 72,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Free",
    "startAudioSignal": 1,
    "endAudioSignal": 177469,
    "fadeBeginPosition": 163136,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412445,
    "duration": 204357,
    "group": "The Chainsmokers",
    "location": "2-45/00041244505285d.mp3",
    "title": "Beach House",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 90,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Free",
    "startAudioSignal": 6,
    "endAudioSignal": 203245,
    "fadeBeginPosition": 192245,
    "musicStyle": "Electronic",
    "subStyle": "Basic",
    "genreDescription": "Electronic Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 413883,
    "duration": 194604,
    "group": "Brynn Cartelli",
    "location": "2-83/0004138831f249d.mp3",
    "title": "Last Night's Mascara",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 102,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 271,
    "endAudioSignal": 194492,
    "fadeBeginPosition": 177492,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": null
},
{
    "@type": "Song",
    "id": 413826,
    "duration": 275509,
    "group": "India Arie",
    "location": "2-26/00041382663d7aa.mp3",
    "title": "That Magic",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 92,
    "musicFeel": "Soul",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Social",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 268175,
    "fadeBeginPosition": 229397,
    "musicStyle": "Urban",
    "subStyle": "R & B",
    "genreDescription": "R & B",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412972,
    "duration": 187904,
    "group": "John Gibbons & Nina Nesbitt",
    "location": "2-72/000412972b69877.mp3",
    "title": "A Spaceman Came Travelling",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 100,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 12,
    "endAudioSignal": 186459,
    "fadeBeginPosition": 177126,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 413113,
    "duration": 215050,
    "group": "Cheat Codes & Kim Petras",
    "location": "2-13/0004131137d97e0.mp3",
    "title": "Feeling Of Falling",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 120,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 2,
    "endAudioSignal": 210938,
    "fadeBeginPosition": 200605,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 412888,
    "duration": 185439,
    "group": "Jax Jones & Years & Years",
    "location": "2-88/0004128882f8da6.mp3",
    "title": "Play",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 125,
    "musicFeel": "Electronic",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 184327,
    "fadeBeginPosition": 183327,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop Electronic",
    "rhythmDescription": "Free Dance",
    "tunifyTipValue": "Tunify Tip"
},
{
    "@type": "Song",
    "id": 413597,
    "duration": 168169,
    "group": "Regi & Milo Meskens",
    "location": "2-97/000413597476984.mp3",
    "title": "Ordinary",
    "origin": {
        "type": "musicCollection",
        "data": "8619"
    },
    "composer": null,
    "bpm": 122,
    "musicFeel": "Pop",
    "localAvailable": null,
    "version": "Original",
    "year": "2018",
    "mood": "Bright",
    "dancingStyle": "Free",
    "startAudioSignal": 0,
    "endAudioSignal": 166724,
    "fadeBeginPosition": 164576,
    "musicStyle": "Pop",
    "subStyle": "Basic",
    "genreDescription": "Pop",
    "rhythmDescription": "Free",
    "tunifyTipValue": "Tunify Tip"
}
];