import { Component, Input, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';

export enum TooltipAlignment {
  LEFT,
  CENTER,
  RIGHT,
}

export interface TooltipAction {
  action: () => void;
  text: string;
}

@Component({
  selector: 'tun-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit, OnDestroy {
  
  private destroyed$ = new Subject<void>();

  // === Props === //
  /** Content of the tooltip */
  private _text: string;
  private nextTextReceived$ = new Subject<void>();
  @Input() set text (value: string){
    this._text = value;
    this.nextTextReceived$.next();
    if (value){
      this.translateService.stream(value)
      .pipe(
        takeUntil(
          merge(this.destroyed$, this.nextTextReceived$)
        )
      )
      .subscribe(
        value => {
          if (value){
            this.translatedTextLines = value.split('\n');
          }else{  
            this.translatedTextLines = [];
          }
        }
      )
    }else{
      this.translatedTextLines = [];
    }
  }
  /** Alignment of the tooltip text content */
  @Input() alignment = TooltipAlignment.CENTER;
  /** Action of the tooltip (optional) */
  @Input() tooltipAction: TooltipAction;
  @Input() connectedElementRef: ElementRef;
  @Input() darkThemed: boolean = false;

  // ==== Private state ==== //
  public translatedTextLines: string[];

  // === Getters === //
  get textAlignment(): string {
    switch (this.alignment) {
      case TooltipAlignment.LEFT:
        return 'left';
      case TooltipAlignment.CENTER:
        return 'center';
      case TooltipAlignment.RIGHT:
        return 'right';
    }
  }


  get theme():string{
    return this.darkThemed?"darkTheme":"";
  }

  constructor(
    public elementRef: ElementRef,
    private translateService:TranslateService
  ) {}

  ngOnInit() {}

  
  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
    this.destroyed$ = null;
    this.nextTextReceived$.complete();
    this.nextTextReceived$ = null;
  }

  onActionClick() {
    this.tooltipAction.action();
  }
}
