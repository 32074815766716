import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { ZoneConnection } from '../../model/zoneConnection';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { first, takeUntil } from 'rxjs/operators';
import { LoggerService } from '../loggers/logger.service';
import { Subject } from 'rxjs';
import * as Ably from 'ably';
import { DataUpdateService, retrieveExternalApplicationInfoFromMemberData } from './data-update.service';
import { ExternalApplicationInfo, ConnectionType } from '../data/vo/remote/remote-objects';
import { PlayTokenService } from '../data/play-token.service';
import { ApplicationMode } from '@service/authentication/zone-connections.service';

/**
 * This service looks at all known connected zones and checks the online presence of other devices.
 *
 * The service will only perform its checks if no zone is currently active
 */

//link between a channel and a zoneConnected -> does the actual checking
export class ZonePresenceChecker{

  private LOGGER_CLASSNAME = 'ZonePresenceChecker';

  public zoneConnection: ZoneConnection;

  //when this subject is set, the presence checker will emit to it as soon as a player has joined the channel
  public playerJoinsRoomSubject: Subject<ExternalApplicationInfo>;
  //when this subject is set, the presence checker will emit to it as soon as a remote has joined the channel
  public remoteJoinsRoomSubject: Subject<ExternalApplicationInfo>;
  //when this subject is set, the presence checker will emit to it as soon as a listener has joined the channel
  public listenerJoinsRoomSubject: Subject<ExternalApplicationInfo>;



  constructor(
    zoneConnection: ZoneConnection,
    private dataUpdateService: DataUpdateService,
    private loggerService: LoggerService,
    private ngZone: NgZone
  ){
    this.zoneConnection = zoneConnection;
    this.startListening();
  }

  private currentChannel : Ably.RealtimeChannel = null;
  public startListening(){
    this.loggerService.debug(this.LOGGER_CLASSNAME, "startListening", "Going to start listening to presence for zone " + this.zoneConnection.zoneId);

    if (this.currentChannel == null){
      const channelName = 'player:' + this.zoneConnection.zoneId + ':data';

      this.currentChannel = this.dataUpdateService.getChannel(channelName);

      if (this.currentChannel){

        this.currentChannel.presence.subscribe('enter', (member) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'channel presence message received', 'Member ' + member.clientId + ' entered. Connection: ' + member.connectionId + '(our connection is: ' + this.dataUpdateService.ablyClient.connection.id + ')');
          //check must be before we update the connections, because it uses the previously known connections to check if it is a new player/remote
          this.checkApplicationEntersForMember(member);
          this.checkConnections();
        });
        this.currentChannel.presence.subscribe('leave', (member) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'channel presence message received', 'Member ' + member.clientId + ' leaves. Connection: ' + member.connectionId + '(our connection is: ' + this.dataUpdateService.ablyClient.connection.id + ')');
          this.checkConnections();
        });
        this.currentChannel.presence.subscribe('update', (member) => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'channel presence message received', 'Member ' + member.clientId + ' updates. Connection: ' + member.connectionId + '(our connection is: ' + this.dataUpdateService.ablyClient.connection.id + ')');
          //check must be before we update the connections, because it uses the previously known connections to check if it is a new player/remote
          this.checkApplicationEntersForMember(member);
          this.checkConnections();
        });
        this.currentChannel.presence.subscribe('present', (member) => {
          //sometimes, the 'enter' event is not triggered, but there is a 'present' event instead.
          //in this case, we are just going to update our known connections
          this.checkConnections();
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'channel presence message received', 'Member ' + member.clientId + ' is present. Connection: ' + member.connectionId + '(our connection is: ' + this.dataUpdateService.ablyClient.connection.id + ')');
        });
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "startListening", "Could not create a channel");
      }
      this.checkConnections();
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, "startListening", "Already connected to a channel");
    }

  }

  private checkConnections(){
    if (this.currentChannel){

      this.currentChannel.presence.get()
      .then((members) => {

        const externalApplicationInfos : ExternalApplicationInfo[] = [];

        if (members != null){

          this.loggerService.debug(this.LOGGER_CLASSNAME, "checkConnections", "zone " + this.zoneConnection.zoneId + ": " + members.length + " client connections found");

          members.forEach(member => {
            if (member.connectionId == this.dataUpdateService.ablyClient.connection.id){
              this.loggerService.debug(this.LOGGER_CLASSNAME, "checkConnections", "zone " + this.zoneConnection.zoneId + ": " + "going to skip our own connection: " + member.clientId + '(connection: ' + member.connectionId + ')');
            }else{
              const memberExternalApplicationInfo = retrieveExternalApplicationInfoFromMemberData(member.data);
              if (memberExternalApplicationInfo != null){
                memberExternalApplicationInfo.clientId = member.clientId;
                memberExternalApplicationInfo.connectionId = member.connectionId;
                externalApplicationInfos.push(memberExternalApplicationInfo);
                this.loggerService.debug(this.LOGGER_CLASSNAME, "checkConnections", "zone " + this.zoneConnection.zoneId + ": " + "client connection: " + member.clientId + '(connection: ' + member.connectionId  + ') is pushed as a ' + memberExternalApplicationInfo.connectionType);
              }else{
                this.loggerService.debug(this.LOGGER_CLASSNAME, "checkConnections", "zone " + this.zoneConnection.zoneId + ": " + "checking client connection: " + member.clientId + '(connection: ' + member.connectionId  + ') has no appInfo');
              }
            }

          });
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, "checkConnections", "zone " + this.zoneConnection.zoneId + ": no members found.");
        }

        this.ngZone.run(
          () => {
            this.zoneConnection.externalApplicationsInfo.externalApplicationsInfo = externalApplicationInfos;
          }
        )

      })
      .catch((reason) => {
        this.loggerService.error(this.LOGGER_CLASSNAME, "checkConnections", "Error when loading presence members: " + reason);
      });

    }else{
      //do not unset, just keep our current info
      //this.zoneConnection.externalApplicationsInfo.externalApplicationsInfo = null;
    }
  }

  private checkApplicationEntersForMember(member: Ably.PresenceMessage){
    if (this.dataUpdateService.ablyClient != null && member.connectionId != this.dataUpdateService.ablyClient.connection.id){
      if (this.remoteJoinsRoomSubject || this.playerJoinsRoomSubject){
        const memberAppInfo = retrieveExternalApplicationInfoFromMemberData(member.data);
        if (memberAppInfo != null){
          memberAppInfo.clientId = member.clientId;
          memberAppInfo.connectionId = member.connectionId;
          const alreadyJoinedMember = this.zoneConnection.externalApplicationsInfo.externalApplicationsInfo.find(appInfo => appInfo.clientId == memberAppInfo.clientId && appInfo.connectionId == memberAppInfo.connectionId);
          if (this.listenerJoinsRoomSubject && memberAppInfo.connectionType == ConnectionType.listener){
            if (alreadyJoinedMember == null || alreadyJoinedMember.connectionType != ConnectionType.listener){
              this.loggerService.debug(this.LOGGER_CLASSNAME, "checkApplicationEntersForMember", "Zone " + this.zoneConnection.zoneId + ": Detected a new listener: " + memberAppInfo.deviceInfo);
              this.ngZone.run(
                () => {
                  this.listenerJoinsRoomSubject.next(memberAppInfo);
                }
              )
            }
          }
          if (this.remoteJoinsRoomSubject && memberAppInfo.connectionType == ConnectionType.remote){
            if (alreadyJoinedMember == null || alreadyJoinedMember.connectionType != ConnectionType.remote){
              this.loggerService.debug(this.LOGGER_CLASSNAME, "checkApplicationEntersForMember", "Zone " + this.zoneConnection.zoneId + ": Detected a new remote: " + memberAppInfo.deviceInfo);
              this.ngZone.run(
                () => {
                  this.remoteJoinsRoomSubject.next(memberAppInfo);
                }
              )
            }
          }
          if (this.playerJoinsRoomSubject && memberAppInfo.connectionType == ConnectionType.player){
            if (alreadyJoinedMember == null || alreadyJoinedMember.connectionType != ConnectionType.player){
              this.loggerService.debug(this.LOGGER_CLASSNAME, "checkApplicationEntersForMember", "Zone " + this.zoneConnection.zoneId + ": Detected a new player: " + memberAppInfo.deviceInfo);
              this.ngZone.run(
                () => {
                  this.playerJoinsRoomSubject.next(memberAppInfo);
                }
              )
            }
          }
        }
      }
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, "checkApplicationEntersForMember", "Going to skip our own connection");
    }
  }

  public cleanup(){
    if (this.currentChannel){
      this.loggerService.debug(this.LOGGER_CLASSNAME, "cleanup", "Going to stop listening to presence for zone " + this.zoneConnection.zoneId);
      this.currentChannel.presence.unsubscribe();
      this.dataUpdateService.releaseChannel(this.currentChannel);
      this.currentChannel = null;
    }else{
      this.loggerService.debug(this.LOGGER_CLASSNAME, "cleanup", "Nothing to cleanup for zone " + this.zoneConnection.zoneId);
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class ZonePresenceService implements OnDestroy {

  private LOGGER_CLASSNAME = ZonePresenceService.name;

  //when a remote needs to know the current player state -> emit event so that the remoteService can trigger a share if this is the current player
  private remoteJoinsSubject = new Subject<ExternalApplicationInfo>();
  public remoteJoins$ = this.remoteJoinsSubject.asObservable();

  private listenerJoinsSubject = new Subject<ExternalApplicationInfo>();
  public listenerJoins$ = this.listenerJoinsSubject.asObservable();

  private otherPlayerJoinsSubject = new Subject<ExternalApplicationInfo>();
  public otherPlayerJoins$ = this.otherPlayerJoinsSubject.asObservable();


  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private dataUpdateService: DataUpdateService,
    private loggerService: LoggerService,
    private playTokenService: PlayTokenService,
    private ngZone: NgZone
  ) {

    this.dataUpdateService.ablyClient$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (ablyClient) => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'ablyClient$', 'going to restart all zonePresenceCheckers');
        this.zonePresenceCheckers.forEach(zonePresenceChecker => {
          zonePresenceChecker.cleanup();
          if (ablyClient != null){
            zonePresenceChecker.startListening();
          }
        });
      }
    )

    this.zoneConnectionsService.zoneConnections$
      .pipe(
        takeUntil(
          this.destroyed$
        )
      )
      .subscribe(
        () => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'zoneConnections$', 'going to adjust connections');
          this.adjustConnections();
        }
      )

      this.zoneConnectionsService.activeZoneConnection$
      .pipe(
        takeUntil(
          this.destroyed$
        )
      )
      .subscribe(
        () => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'activeZoneConnection$', 'going to adjust connections');
          this.adjustConnections();
        }
      )



    this.zoneConnectionsService.applicationMode$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'applicationMode$', 'going to adjust connections');
        this.adjustConnections();
      }
    )

    this.otherPlayerJoins$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (externalApplicationInfo) => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'otherPlayerJoins$', 'going to invalidate playToken for ' + externalApplicationInfo.deviceInfo);
        this.playTokenService.invalidatePlayToken(externalApplicationInfo.deviceInfo);
      }
    );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private zonePresenceCheckers : ZonePresenceChecker[] = [];
  private adjustConnections(){

    const zonePresenceCheckersTemp : ZonePresenceChecker[] = [];
    const unFoundZoneConnections : ZoneConnection[] = [];

    //keep all zoneCheckers that are still needed
    if (this.zoneConnectionsService.zoneConnections != null){

      //if we have an active connection -> only keep that one, otherwise keep them all
      const zoneConnectionsToTrack =
        this.zoneConnectionsService.zoneConnections
          .filter(zoneConnection => this.zoneConnectionsService.activeZoneConnection == null || this.zoneConnectionsService.activeZoneConnection == zoneConnection);

      zoneConnectionsToTrack.forEach(
        zoneConnection => {
          const zonePresenceChecker = this.zonePresenceCheckers.find(zonePresenceChecker => zonePresenceChecker.zoneConnection.zoneId == zoneConnection.zoneId);
          if (zonePresenceChecker != null){
            zonePresenceChecker.zoneConnection = zoneConnection;
            zonePresenceChecker.listenerJoinsRoomSubject = (this.isActivePlayer(zoneConnection) ? this.listenerJoinsSubject : null)
            zonePresenceChecker.remoteJoinsRoomSubject = (this.isActivePlayer(zoneConnection) ? this.remoteJoinsSubject : null)
            zonePresenceChecker.playerJoinsRoomSubject = (this.isActivePlayer(zoneConnection) ? this.otherPlayerJoinsSubject : null)
            zonePresenceCheckersTemp.push(zonePresenceChecker);
            this.zonePresenceCheckers = this.zonePresenceCheckers.filter(obj => obj !== zonePresenceChecker);
          }else{
            unFoundZoneConnections.push(zoneConnection);
          }
        }
      )
    }

    //cleanup the zoneCheckers that are no longer needed, and throw them away
    this.zonePresenceCheckers.forEach(
      zoneConnectionChecker => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustConnections', 'going to cleanup zonePresenceChecker for zone ' + zoneConnectionChecker.zoneConnection.zoneId);
        zoneConnectionChecker.cleanup();
      }
    );

    this.zonePresenceCheckers = zonePresenceCheckersTemp;

    //add the ones that are not yet created
    unFoundZoneConnections.forEach(zoneConnection => {
      this.loggerService.debug(this.LOGGER_CLASSNAME, 'adjustConnections', 'going to create zonePresenceChecker for zone ' + zoneConnection.zoneId);
      const zonePresenceChecker = new ZonePresenceChecker(zoneConnection, this.dataUpdateService, this.loggerService, this.ngZone);
      zonePresenceChecker.listenerJoinsRoomSubject = (this.isActivePlayer(zoneConnection)  ? this.listenerJoinsSubject : null)
      zonePresenceChecker.remoteJoinsRoomSubject = (this.isActivePlayer(zoneConnection)  ? this.remoteJoinsSubject : null)
      zonePresenceChecker.playerJoinsRoomSubject = (this.isActivePlayer(zoneConnection) ? this.otherPlayerJoinsSubject : null)
      this.zonePresenceCheckers.push(zonePresenceChecker);
    });
  }

  private isActivePlayer(zoneConnection: ZoneConnection): Boolean{
    const isactive = zoneConnection == this.zoneConnectionsService.activeZoneConnection && this.zoneConnectionsService.applicationMode == ApplicationMode.playerMode
    return isactive;
  }

}
