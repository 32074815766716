import { Component } from '@angular/core';

@Component({
  selector: 'tun-phone20px-icon-v5',
  templateUrl: './phone20px-icon-v5.component.html',
  styleUrls: ['./phone20px-icon-v5.component.scss']
})
export class Phone20pxIconV5Component {

}
