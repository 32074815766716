<div class="wrapper">

  <div class="trackContent">
    <div class="trackTitleContainer">
      <tun-scrollable-text class="trackTitle" [text]="track.title"></tun-scrollable-text>
    </div>
    <div class="trackGroupContainer">
      <tun-scrollable-text class="trackGroup" [text]="track.group"></tun-scrollable-text>
    </div>
  </div>

  <button class="icon-button" (click)="onReset()">
    <tun-reset-icon-v5 class="icon icon-small-scaled-svg-button"></tun-reset-icon-v5>
  </button>

</div>
