<div *ngIf="musicCollection != null" class="content-wrapper">
  <div class="title-container">

    <tun-tweak-playlist-icon-v5 *ngIf="isPlaylist" class="icon icon-scaled-svg-button"></tun-tweak-playlist-icon-v5>
    <tun-tweak-context-icon-v5 *ngIf="isContext" class="icon icon-scaled-svg-button"></tun-tweak-context-icon-v5>

    <div class="title">{{musicCollection.title}}</div>

    <tun-tweak-strength-bar-v5
      *ngIf="tweakInfoLoaded"
      [class.disabled]="tweakInfoLoading"
      [percentage]="tweakInfo?.percentage || 0"
      [status]="tweakInfo?.status"
    ></tun-tweak-strength-bar-v5>

    <button
      *ngIf="isContext"
      class="resetButton"
      (click)="onReset()"
    >
      Reset
    </button>

    <button class="icon-button" (click)="onClose()">
      <tun-close-v5 class="icon close-icon icon-large-scaled-svg-button"></tun-close-v5>
    </button>

  </div>

  <div class="seperator"></div>

  <div
    *ngIf="musicCollection.detailsLoading"
    class="feedback-container"
  >
    <tun-loader-v5 class="loader"></tun-loader-v5>
  </div>

  <div
      *ngIf="musicCollection.detailsLoadingError != null"
      class="error-feedback-container"
  >
    <p>
      {{'general.status.error.message' | translate }}
    </p>
    <button (click)="retryClick()" class="retryButton">
      <p>
        {{'general.status.error.retry' | translate }}
      </p>
    </button>
  </div>


  <ng-container
    *ngIf="!musicCollection.detailsLoading && musicCollection.detailsLoadingError == null"
  >

    <tun-changeable-parameters-panel-v5
      *ngIf="isContext"
      [changeableParameters]="changeableParameters"
      (parameterchange)="onParameterChange()"
      class="content"
    ></tun-changeable-parameters-panel-v5>

    <tun-track-list-view
      *ngIf="isPlaylist"
      [tracks]="tracks"
      class="content"
      [tunifyColor]="TunifyColor.BLUE"
    >

    </tun-track-list-view>


  </ng-container>



</div>
