<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(0.000000, 1.000000)" stroke="currentColor">
      <path d="M6.5,16.5 L6.5,19.4913003 C6.47043892,20.3095157 6.1273717,21.0469101 5.5871409,21.5871409 C5.04695137,22.1273304 4.30962837,22.4703865 3.49135655,22.4999878 C2.66635688,22.4976611 1.91968415,22.1623248 1.37867966,21.6213203 C0.835786438,21.0784271 0.5,20.3284271 0.5,19.5 C0.5,18.6715729 0.835786438,17.9215729 1.37867966,17.3786797 C1.92157288,16.8357864 2.67157288,16.5 3.5,16.5 L6.5,16.5 Z"></path>
      <path d="M20.5,12.5 L20.5,15.4913003 C20.4704389,16.3095157 20.1273717,17.0469101 19.5871409,17.5871409 C19.0469514,18.1273304 18.3096284,18.4703865 17.4913565,18.4999878 C16.6663569,18.4976611 15.9196841,18.1623248 15.3786797,17.6213203 C14.8357864,17.0784271 14.5,16.3284271 14.5,15.5 C14.5,14.6715729 14.8357864,13.9215729 15.3786797,13.3786797 C15.9215729,12.8357864 16.6715729,12.5 17.5,12.5 L20.5,12.5 Z"></path>
      <path d="M6.5,18 L6.5,5.26444863 C6.49968841,4.80785249 6.81202956,4.39643914 7.29036272,4.22338926 L18.7448915,0.0838853605 C19.1385883,-0.0585520023 19.5868183,-0.0178044074 19.9394218,0.192477442 C20.2920253,0.402759291 20.5023665,0.754763343 20.5,1.130566 L20.5,13.5086045" stroke-linecap="round" stroke-linejoin="round"></path>
      <line x1="6.66666667" y1="9" x2="20.3370727" y2="3.87359775" id="Path" stroke-linecap="round" stroke-linejoin="round"></line>
    </g>
  </g>
</svg>
