import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TunifyChangeableParameterColor } from '@components/components-v5/changeable-parameters-panel-v5/changeable-parameters-panel-v5.component';




@Component({
  selector: 'tun-base-slider-v5',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseSliderV5Component implements AfterViewInit {

  // Unselected line defined in the HTML.
  @ViewChild('unselectedline') unselectedLine: ElementRef;

  @Input() sliderColor = TunifyChangeableParameterColor.BLUE



  // Emits the selectedMinValue after an event;
  @Output() selectedMinValueEmitter = new EventEmitter();

  // Emits the selectedMaxValue after an event;
  @Output() selectedMaxValueEmitter = new EventEmitter();


  // X-coordinate of the left slider.
  leftSliderClientX = 0;

  // Checks whether the left slider is clicked or not.
  leftSliderIsClicked = false;

  // Left attribute of the left slider, used for positioning.
  get leftSliderLeft(): SafeStyle{
    return `${this.leftSliderLeftPercent}%`
  }

  // Left attribute of the left slider in percent.
  leftSliderLeftPercent = 0;


  // X-coordinate of the right slider.
  rightSliderClientX = 0;

  // Checks whether the right slider is clicked or not.
  rightSliderIsClicked = false;

  // Right attribute of the right slider, used for positioning.
  get rightSliderRight(): SafeStyle{
    return `${this.rightSliderRightPercent}%`
  }

  // Right attribute of the right slider in percent.
  rightSliderRightPercent = 0;

  // X-coordinate of the unselected line.
  unselectedLineClientX = 0;

  // Speed at whicht the transition happens when clicking the bar.
  transitionSpeed: SafeStyle = '0s';

  constructor(
    protected sanitizer: DomSanitizer,
    protected cdRef: ChangeDetectorRef,
  ) {}

  // Called when the window is resized to fix the positions of the sliders.
  onResize() {
    this.unselectedLineClientX = 0;
    this.cdRef.detectChanges();
  }

  // A snap happens when a slider is released.
  onMouseUp = () => {
    this.releaseSliderClicks();
    this.cdRef.detectChanges();
  }

  // A snap happens when a slider is released.
  onTouchEnd = () => {
    this.releaseSliderClicks();
    this.cdRef.detectChanges();
  }

  // If a slider was clicked, a snap will be performed.
  onMouseLeave = () => {
    this.releaseSliderClicks();
    this.cdRef.detectChanges();
  }

  // If a slider was clicked, a snap will be performed.
  onTouchCancel = () => {
    this.releaseSliderClicks();
    this.cdRef.detectChanges();
  }

  // A move happens when the user moves the cursor.
  onMouseMove = (e: MouseEvent) => {
    if (this.leftSliderIsClicked) {
      this.leftSliderMouseMove(e.clientX);
    } else if (this.rightSliderIsClicked) {
      this.rightSliderMouseMove(e.clientX);
    }
    this.cdRef.detectChanges();
  }

  // A move happens when the user moves the cursor.
  onTouchMove = (e: TouchEvent | any) => {
    if (this.leftSliderIsClicked) {
      this.leftSliderMouseMove(e.touches[0].clientX);
    } else if (this.rightSliderIsClicked) {
      this.rightSliderMouseMove(e.touches[0].clientX);
    }
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {

  }

  // Called when the user clicks the line to move a slider.
  public lineClicked(e: MouseEvent){
    this.calculateUnselectedLineClientX();
    this.transitionSpeed = '0.2s';
  }

  // Calculates the X-coordinate of the unselected line.
  calculateUnselectedLineClientX() {
    if (this.unselectedLineClientX === 0) {
      const { x } = this.unselectedLine.nativeElement.getBoundingClientRect();
      this.unselectedLineClientX = x;
    }
  }

  // Resets the transition speed.
  resetTransitionSpeed() {
    setTimeout(() => {
      this.transitionSpeed = '0s';
    }, 200);
  }

  // Emit the selectedMinValue and selectedMaxValue of the slider.
  emitOutput(minValue: any, maxValue: any) {
    this.selectedMinValueEmitter.emit(minValue);
    this.selectedMaxValueEmitter.emit(maxValue);
  }

  // If a slider is clicked, it is released.
  releaseSliderClicks() {}

  // Registration that the left slider is currently clicked by the user.
  leftSliderMouseDown = () => {
    this.leftSliderHasMoved = false;
    this.leftSliderIsClicked = true;
    this.cdRef.detectChanges();
  }


  leftSliderHasMoved = false
  // Registration that the user is moving the left slider.
  leftSliderMouseMove(eventClientX: number, leftSliderValueWidth: number = 0) {
    // Current width of the unselected line.
    const width = this.unselectedLine.nativeElement.offsetWidth;

    // Updates the leftSliderClientX when the user clicks on the right slider after a snap.
    if (this.leftSliderClientX === 0) {
      this.leftSliderClientX = eventClientX;
    }

    // Updates the unselectedLineClientX after the screen is resized.
    this.calculateUnselectedLineClientX();

    let newLeftSliderLeftPercent = 0;
    let switchToOtherSliderIfNotMoved = false;
    // If the left slider or the cursor of the user deviates too much to the left.
    if (
      eventClientX <= this.unselectedLineClientX ||
      this.leftSliderLeftPercent +
        ((eventClientX - this.leftSliderClientX) / width) * 100 <=
        0
    ) {
      newLeftSliderLeftPercent = 0;
    } else if (
      eventClientX - this.unselectedLineClientX - leftSliderValueWidth >
        width * ((100 - this.rightSliderRightPercent) / 100) ||
      this.leftSliderLeftPercent +
        this.rightSliderRightPercent +
        ((eventClientX - this.leftSliderClientX) / width) * 100 >=
        100
    ) {
      newLeftSliderLeftPercent = 100 - this.rightSliderRightPercent;
      switchToOtherSliderIfNotMoved = true;
    } else {
      newLeftSliderLeftPercent = this.leftSliderLeftPercent +
        ((eventClientX - this.leftSliderClientX) / width) * 100;
    }

    if (newLeftSliderLeftPercent != this.leftSliderLeftPercent)  {
      this.leftSliderLeftPercent = newLeftSliderLeftPercent;
      this.leftSliderHasMoved = true;
    }

    if (switchToOtherSliderIfNotMoved && !this.leftSliderHasMoved){
      this.rightSliderIsClicked = true;
      this.rightSliderHasMoved = false;
      this.leftSliderIsClicked = false;
    }

    // Update the leftSliderClientX to the clientX of the mouse event.
    this.leftSliderClientX = eventClientX;
  }

  // Registration that the right slider is currently clicked by the user.
  rightSliderMouseDown = () => {
    this.rightSliderIsClicked = true;
    this.rightSliderHasMoved = false;
    this.cdRef.detectChanges();
  }

  rightSliderHasMoved = false
  // Registration that the user is moving the right slider.
  rightSliderMouseMove(
    eventClientX: number,
    rightSliderValueWidth: number = 0
  ) {
    // Current width of the unselected line.
    const width = this.unselectedLine.nativeElement.offsetWidth;

    // Updates the rightSliderClientX when the user clicks on the right slider after a snap.
    if (this.rightSliderClientX === 0) {
      this.rightSliderClientX = eventClientX;
    }

    // Updates the unselectedLineClientX after the screen is resized.
    this.calculateUnselectedLineClientX();


    let newRightSliderLeftPercent = 0;
    let switchToOtherSliderIfNotMoved = false;
    // If the right slider or cursor of the user deviates too much to the right.
    if (
      eventClientX >= this.unselectedLineClientX + width ||
      this.rightSliderRightPercent +
        ((this.rightSliderClientX - eventClientX) / width) * 100 <=
        0
    ) {
      newRightSliderLeftPercent = 0;
    } else if (
      eventClientX - this.unselectedLineClientX + rightSliderValueWidth <
        width * (this.leftSliderLeftPercent / 100) ||
      this.leftSliderLeftPercent +
        this.rightSliderRightPercent +
        ((this.rightSliderClientX - eventClientX) / width) * 100 >=
        100
    ) {
      newRightSliderLeftPercent = 100 - this.leftSliderLeftPercent;
      switchToOtherSliderIfNotMoved = true;
    } else {
      newRightSliderLeftPercent = this.rightSliderRightPercent +
        ((this.rightSliderClientX - eventClientX) / width) * 100;
    }


    if (newRightSliderLeftPercent != this.rightSliderRightPercent)  {
      this.rightSliderRightPercent = newRightSliderLeftPercent;
      this.rightSliderHasMoved = true;
    }

    if (switchToOtherSliderIfNotMoved && !this.rightSliderHasMoved){
      this.leftSliderIsClicked = true;
      this.leftSliderHasMoved = false;
      this.rightSliderIsClicked = false;
    }

    // Update the rightSliderClientX to the clientX of the mouse event.
    this.rightSliderClientX = eventClientX;
  }

  // Checks whether the two sliders collided.
  detectCollision(): boolean {
    return (
      Math.round(this.leftSliderLeftPercent + this.rightSliderRightPercent) >=
      100
    );
  }

}
