import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Context } from '@model/context';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { Playlist } from '@model/playlist';
import { MusicChannelService } from '@service/music-channel.service';
import { MusicCollectionService } from '@service/music-collection.service';

@Component({
  selector: 'tun-overlay-tweak-music-collection',
  templateUrl: './overlay-tweak-music-collection.component.html',
  styleUrls: ['./overlay-tweak-music-collection.component.scss']
})
export class OverlayTweakMusicCollectionComponent implements OnChanges {

  @Input()  musicCollection: MusicCollection;
  @Input()  musicChannel: MusicChannel;

  @Output() close = new EventEmitter<void>();
  @Output() parametersChanged = new EventEmitter<MusicCollection>();

  public TunifyColor = TunifyColor

  get isPlaylist(){
    return this.musicCollection && this.musicCollection instanceof Playlist
  }

  get tracks(){
    if (this.isPlaylist){
      return (this.musicCollection as Playlist).audioFiles
    }
    return [];
  }

  get isContext(){
    return this.musicCollection && this.musicCollection instanceof Context
  }

  get changeableParameters(){
    if (this.isContext){
      return (this.musicCollection as Context).changeableParameters
    }
    return [];
  }

  get tweakInfo() {
   if (
      this.musicCollection &&
      this.musicCollection instanceof Context
    ) {
      return (<Context>this.musicCollection).tweakInfo;
    }
    return null;
  }

  get tweakInfoLoaded(): boolean {
    if (
      this.musicCollection &&
      this.musicCollection instanceof Context
    ) {
      return (<Context>this.musicCollection).tweakInfoLoaded;
    }
    return false
  }

  get tweakInfoLoading(): boolean {
    if (
      this.musicCollection &&
      this.musicCollection instanceof Context
    ) {
      return (<Context>this.musicCollection).tweakInfoLoading;
    }
    return false
  }



  constructor(
    private musicChannelService: MusicChannelService,
    private musicCollectionService: MusicCollectionService
  ){
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes["musicCollection"] || changes["musicChannel"]){
        this.loadDetailsIfNeeded();
      }
  }

  private loadDetailsIfNeeded(){
    if (this.musicCollection && this.musicChannel){
      if (!this.musicCollection.detailsLoaded && !this.musicCollection.detailsLoading){
        this.musicChannelService.loadMusicCollection(this.musicChannel.id, this.musicCollection);
      }
    }
  }

  public retryClick(){
    this.loadDetailsIfNeeded();
  }


  public onClose(){
    this.close.emit();
  }

  public onParameterChange(){
    const context = this.musicCollection as Context;
    if (context && this.musicChannel) {
      this.parametersChanged.emit(context);
      /*
      this.musicChannelService.saveContextParameters(
        this.musicChannel,
        context
      );
      */

      this.musicCollectionService.loadTweakInfo(
        context
      );


    }
  }

  public onReset(){
    const context = this.musicCollection as Context;
    if (context && this.musicChannel) {
      context.resetParameters();
      this.parametersChanged.emit(context);
      /*
      this.musicChannelService.resetContextParameters(
        this.musicChannel,
        context
      );
      */

      this.musicCollectionService.loadTweakInfo(
        context
      );
    }

  }
}
