import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import {PlayerRoutingModule} from './player-routing.module';
import {PlayerComponent} from './player.component';
import { IconsModule } from '@components/icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';
import { ResizeModule } from '@components/resize/resize.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        PlayerRoutingModule,
        IconsModule,
        TranslateModule,
        ResizeModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        PlayerComponent,
    ],
    providers: [
    ]
})
export class PlayerModule {}
