import { Component } from '@angular/core';

@Component({
  selector: 'tun-plus-round-icon-v5',
  templateUrl: './plus-round-icon-v5.component.html',
  styleUrls: ['./plus-round-icon-v5.component.scss']
})
export class PlusRoundIconV5Component {

}
