import {MusicCollection} from './musicCollection';
import {AudioFile} from './audioFile';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { Subject, BehaviorSubject } from 'rxjs';
import { AsyncStatus } from '../services/data/vo/asyncStatus';

export class Playlist extends MusicCollection {

    private _audioFilesSubject = new BehaviorSubject<AudioFile[]>(null);
    audioFiles$ = this._audioFilesSubject.asObservable();
    set audioFiles(value: AudioFile[]){
        this._audioFilesSubject.next(value);
    }
    get audioFiles():AudioFile[]{
        return this._audioFilesSubject.value;
    }

    // an event emitter that is triggered when the audioFiles have changed (to update views etc)
    audioFilesHaveChanged: Subject<boolean> = new Subject<boolean>();


    constructor(playlist?: Playlist) {
        super(playlist);
        if (playlist) {
            if (playlist.audioFiles) {
                this.audioFiles = [];

                playlist.audioFiles.map(audioFile => this.audioFiles.push(createClassObjectForAudioFile(audioFile)));
            }
        }
    }


    // helper variables to save the playlist
    // the audioFiles that must be added right after the details are loaded (we can only add audioFiles once all audioFiles are loaded)
    audioFilesToAddAfterLoadSucces: AudioFile[];
    // marker to indicate if the audioFiles are changed and need to be saved to the server
    audioFilesChanged = false;

    //notifiers that are waiting for a save response
    saveNotifiers: BehaviorSubject<AsyncStatus>[] = [];

    // marker to indicate we are creating the playlist (for ui feedback)
    creatingPlaylist = false;
    // marker to indicate that creating the playlist has failed
    createPlaylistFailed = false;

    deletingPlaylist = false;
    deleted = false;

    public tranferUpToDateDetails(otherPlaylist: Playlist){
      if (otherPlaylist.detailsLoaded && otherPlaylist.id == this.id && otherPlaylist.timestamp >= this.timestamp){
        this.audioFiles = otherPlaylist.audioFiles;
        this.detailsLoaded = true;
      }
    }

    public copyDetailsFrom(musicCollection: MusicCollection){
      if (musicCollection != null && musicCollection.id == this.id && musicCollection instanceof Playlist){
        this.audioFiles = musicCollection.audioFiles;
        this.audioFilesHaveChanged.next(true);

        //local changes are thrown away (if any where still around)
        this.audioFilesChanged = false;
      }
      super.copyDetailsFrom(musicCollection);
    }




}

export function playlistContainsAudioFile(playlist: Playlist, audioFile: AudioFile) {
    let found = false;
    if (playlist != null && playlist.audioFiles != null && audioFile != null) {
        let index = 0;
        while (!found && index < playlist.audioFiles.length) {
            if (playlist.audioFiles[index].id == audioFile.id) {
                found = true;
            }
            index++;
        }
    }
    return found;
}
