<div class="wrapper">

  <!-- background -->
  <div class="imageWrapper">
    <img class="backgroundImage" [src]="imageUrl$ | async">
    <div class="gradientLayer"></div>
  </div>

  <!-- view content -->
  <div class="contentWrapper {{showingPopup ? 'blurredContent' : ''}}">

    <!-- top content (back / title / play button) -->
    <div class="topContent">

      <div class="topHeader">

        <button class="icon-button back-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>

        <div class="rightButtons">

          <ng-container *ngIf="!(editMode$ | async) && !isCreating ">
            <button *ngIf="isEditable" class="icon-button" (click)="onToggleEdit(true)">
              <tun-edit-icon-v5 class="icon icon-scaled-svg-button"></tun-edit-icon-v5>
            </button>

            <button *ngIf="isEditable" class="icon-button" (click)="onRemove()">
              <tun-delete-icon-v5 class="icon icon-scaled-svg-button"></tun-delete-icon-v5>
            </button>

            <button *ngIf="!isEditable" class="icon-button" (click)="onCopy()">
              <tun-duplicate-icon-v5 class="icon icon-scaled-svg-button"></tun-duplicate-icon-v5>
            </button>
          </ng-container>

          <ng-container *ngIf="(editMode$ | async)">
            <button  class="close-button" (click)="onToggleEdit(false)">
               {{'calendars.edit.close' | translate}}
               <tun-close-v5 class="icon icon-scaled-svg-button"></tun-close-v5>
            </button>
          </ng-container>


        </div>


      </div>


      <div class="titleContainer">
        <div class="title">
          {{title$ | async}}

          <button *ngIf="(editMode$ | async)" class="icon-button" (click)="onEditTitle()">
            <tun-edit-icon-v5 class="icon icon-scaled-svg-button"></tun-edit-icon-v5>
          </button>

        </div>



        <tun-play-music-selection-button
          *ngIf="!(editMode$ | async) && !isCreating"
          class="play-button"
          [itemToStart]="showingCalendar$ | async"
          (itemIsStarting)="onItemIsStarting()"
        >

        </tun-play-music-selection-button>


      </div>
    </div>

    <!-- calendar day navigator -->
    <tun-calendar-day-navigator class="dayNavigator"
      [currentIndex]="currentIndex"
      [inEditMode]="editMode$ | async"
      [animationDirection]="animationDirection"
      (openCurrentTime)="onOpenCurrentTime()"
      (daychange)="onDayChange($event)">

    </tun-calendar-day-navigator>


    <tun-calendar-content-view
      class="calendarContent"
      [currentIndex]="currentIndex"
      [animationDirection]="animationDirection"
      [currentTimeMultiplier]="currentTimeMultiplier"
      (openTweakPanel)="onOpenTweakPanel($event)"
      (selectMusicChannelForCalendarItem)="onSelectMusicChannel($event)"
      (selectPlaylistForCalendarItem)="onSelectPlaylist($event)"
      (createMusicChannelCalendarItem)="onCreateMusicChannelCalendarItem($event)"
      (createPlaylistCalendarItem)="onCreatePlaylistCalendarItem($event)"
    >

    </tun-calendar-content-view>

  </div>

    <!-- Popup: choose playlist-->
    <div #tweakPopupWrapper *ngIf="showTweakPopup" class="popupWrapper" @slideUpAnimation (mousedown)="onClickOutside($event, tweakPopupWrapper)">
      <tun-overlay-tweak-calendar-item
        class="tweak-calendarItem-overlay"
        [calendar]="showingCalendar$ | async"
        [calendarItem]="tweakCalendarItem"
        (close)="onCloseTweakPopup()"
      >
      </tun-overlay-tweak-calendar-item>
    </div>

    <!-- Popup: choose musicChannel -->
    <div #chooseMusicChannelPopupWrapper *ngIf="showChooseMusicChannelOverlay" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, chooseMusicChannelPopupWrapper)">
      <tun-overlay-select-music-channel
        class="select-musicChannel-overlay"
        (closeWithMusicChannelData)="onCloseSelectMusicChannel($event)"
      >
      </tun-overlay-select-music-channel>
    </div>


    <!-- Popup: choose playlist-->
    <div #choosePlaylistPopupWrapper *ngIf="showChoosePlaylistOverlay" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, choosePlaylistPopupWrapper)">
      <tun-overlay-select-playlist
        class="select-playlist-overlay"
        (closeWithPlaylist)="onCloseSelectPlaylist($event)"
      >
      </tun-overlay-select-playlist>
    </div>

</div>

