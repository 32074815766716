import { Pipe, PipeTransform } from "@angular/core";
import { ChangeableParameter } from "@model/changeableParameter";
import { ChangeableSelectionParameter } from "@model/changeableSelectionParameter";
import { ChangeableIntervalParameter } from "@model/changeableIntervalParameter";
import { Option } from "@model/option";

@Pipe({ name: "mapSliderOptionsV5" })
export class MapSliderOptionsV5 implements PipeTransform {
  transform(parameter: ChangeableParameter): Array<Option> {
    if (parameter instanceof ChangeableSelectionParameter) {
      const param = parameter as ChangeableSelectionParameter;
      let index = 0;
      const options: Option[] = [];
      param.value.forEach(value => {
        options.push(new Option(index++, value.value));
      });
      return options;
    } else if (parameter instanceof ChangeableIntervalParameter) {
      const param = parameter as ChangeableIntervalParameter;

      const amount: number = param.maximum - param.minimum + 1;
      const options: Array<Option> = new Array(amount)
        .fill(undefined)
        .map(_ => new Option(0, ""));

      options.forEach((option: Option, index: number) => {
        const value = (param.minimum + index);
        option.label = value.toString();
        option.value = value;
      });

      options[0].label = parameter.metadataDisplayMinimum;
      options[amount - 1].label = parameter.metadataDisplayMaximum;

      return options;
    }

    return undefined;
  }
}
