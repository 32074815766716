import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { LoggerService } from '@service/loggers/logger.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { MusicManipulationService } from '../../../../../services/audio/music-manipulation.service';

@Component({
  selector: 'tun-progress-view',
  templateUrl: './progress-view.component.html',
  styleUrls: ['./progress-view.component.scss']
})
export class ProgressViewComponent implements OnInit, OnDestroy {
  private LOGGER_CLASSNAME = 'ProgressViewComponent';

  @ViewChild('progressContainer') progressContainer:any;

  public get progressPercentage$(){
    return combineLatest([
      this.playingItemService.currentTimeInSeconds$,
      this.playingItemService.totalTimeInSeconds$,
    ])
    .pipe(
      map(([currentTime, totalTime]) => {
        if (totalTime > 0){
          const percentage = (currentTime / totalTime) * 100;
          //console.debug("percentage: " + percentage);
          return Math.abs(percentage)
        }
        return 0
      })
    )
  }

  public get time$(){
    return this.playingItemService.currentTimeInSeconds$
    .pipe(
      map((currentTime) => {
        return this.formatSecondsToTimeString(currentTime);
      })
    )
  }

  public get timeLeft$(){
    return combineLatest([
      this.playingItemService.currentTimeInSeconds$,
      this.playingItemService.totalTimeInSeconds$,
    ])
    .pipe(
      map(([currentTime, totalTime]) => {
        if (totalTime > 0){
          return this.formatSecondsToTimeString(totalTime - currentTime);
        }
        return 0
      })
    )
  }

  public get colorString$(){
    return this.playingMusicSelectionService.lastRealplayingMusicSelection$
      .pipe(
      map((playingMusicSelection) => {
        if (playingMusicSelection.origin == PlayingOrigin.musicChannel){
          return "blue"
        }else if (playingMusicSelection.origin == PlayingOrigin.calendar){
          return "green"
        }
        return "orange"
      })
    )
  }

  public get adjustable$(){
    return this.playingItemService.playingAudio$
    .pipe(
      map(
        audio => {
          return audio != null && audio instanceof AudioFile;
        }
      )
    )
  }

  constructor(
    private loggerService: LoggerService,
    private ref: ChangeDetectorRef,
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private playingItemService: PlayingItemService,
    private musicManipulationService: MusicManipulationService,
    ) {

  }

  ngOnInit(): void {
    combineLatest([
      this.playingItemService.currentTimeInSeconds$,
      this.playingItemService.totalTimeInSeconds$,
    ])
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      ()=>{
        this.ref.detectChanges();
      }
    )

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  formatSecondsToTimeString(secondsIn:number):string{
      const hours:number = Math.floor(( secondsIn / 3600 ) % 24);
      const minutes:number = Math.floor(( secondsIn / 60 ) % 60);
      const seconds:number = Math.floor(secondsIn % 60);

      return (hours>0?hours+":":"") + minutes + ":" + (seconds<10?"0":"") + seconds;
  }


  onClick(event: MouseEvent) {
    const rect = this.progressContainer.nativeElement.getBoundingClientRect();

    const x = event.clientX - rect.left;

    const percentClicked = x / rect.width;

    const timeToSeek: number = this.playingItemService.totalTimeInSeconds * percentClicked;

    this.loggerService.debug(this.LOGGER_CLASSNAME, "onProgressBarClick", "We should seek to " + timeToSeek);

    this.musicManipulationService.seekToTime(timeToSeek);

  }
}
