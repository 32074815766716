import { Injectable, OnDestroy } from '@angular/core';
import { PlaylistService } from '@service/playlist.service';
import { MusicPlayerService } from '@service/music-player.service';
import { BehaviorSubject, Observable, Subscription, Subject, merge } from 'rxjs';
import { QueueService } from '@service/queue.service';
import { AudioFile } from '@model/audioFile';
import { playlistContainsAudioFile, Playlist } from '@model/playlist';
import { PlayStateService } from '../audio/play-state.service';
import { takeUntil } from 'rxjs/operators';
import { RemotePlayStateService } from '../audio/remote-play-state.service';

/**
 * This service tracks the current and next track and the favorite list.
 */

@Injectable({
  providedIn: 'root'
})
export class TrackFavoriteBannedService implements OnDestroy{

  constructor(  private playlistService:PlaylistService,
                private musicPlayerService:MusicPlayerService,
                private playStateService: PlayStateService,
                private remotePlayStateService: RemotePlayStateService
  ){
    this.playlistService.favorites$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.watchAudioFilesOfFavorites();
        this.syncCurrentTrackIsFavorite();
        this.syncNextTrackIsFavorite();
      }
    );

    this.playlistService.banlist$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.watchAudioFilesOfBanlist();
        this.syncCurrentTrackIsBanned();
        this.syncNextTrackIsBanned();
        this.syncRemoteTrackIsBanned();
      }
    );

    this.musicPlayerService.currentActiveAudioFileWithPlayInfo$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.syncCurrentTrackIsFavorite();
        this.syncCurrentTrackIsBanned();
      }
    );

    this.playStateService.nextPlayableAudio$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.syncNextTrackIsFavorite();
        this.syncNextTrackIsBanned();
      }
    )

    this.remotePlayStateService.currentTrack$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.syncRemoteTrackIsFavorite();
        this.syncRemoteTrackIsBanned();
      }
    )

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }


  private stopWatchingFavorites$ = new Subject<void>();
  private watchAudioFilesOfFavorites(){
    this.stopWatchingFavorites$.next();
    if (this.playlistService.favorites != null){
      this.playlistService.favorites.audioFilesHaveChanged
      .pipe(
        takeUntil(
          merge(
            this.stopWatchingFavorites$,
            this.destroyed$
          )
        )
      )
      .subscribe(
        () => {
          this.syncCurrentTrackIsFavorite();
          this.syncNextTrackIsFavorite();
          this.syncRemoteTrackIsFavorite();
        }
      )
    }
  }


  private stopWatchingBanlist$ = new Subject<void>();
  private watchAudioFilesOfBanlist(){
    this.stopWatchingBanlist$.next();
    if (this.playlistService.banlist != null){
      this.playlistService.banlist.audioFilesHaveChanged
      .pipe(
        takeUntil(
          merge(
            this.stopWatchingBanlist$,
            this.destroyed$
          )
        )
      )
      .subscribe(
        () => {
          this.syncCurrentTrackIsBanned();
          this.syncNextTrackIsBanned();
          this.syncRemoteTrackIsBanned();
        }
      )
    }
  }


  /**
   * an observable that fires whenever one of the values changes - always emits true
   */
  private _anyValueChangedSubject: Subject<boolean> = new Subject<boolean>();
  public anyValueChanged$: Observable<boolean> = this._anyValueChangedSubject.asObservable();

  /**
  * current track is in favorite list
  */
  private get _currentTrackIsFavorite(): boolean {
    return this._currentTrackIsFavoriteSubject.value;
  }

  private set _currentTrackIsFavorite(value: boolean) {
    if (this._currentTrackIsFavorite !== value) {
        this._currentTrackIsFavoriteSubject.next(value);
        this._anyValueChangedSubject.next(true);
    }
  }
  get currentTrackIsFavorite(): boolean {
    return this._currentTrackIsFavorite;
  }
  private _currentTrackIsFavoriteSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentTrackIsFavorite$: Observable<boolean> = this._currentTrackIsFavoriteSubject.asObservable();

  /**
  * current track is in banlist
  */
 private get _currentTrackIsBanned(): boolean {
  return this._currentTrackIsBannedSubject.value;
}

private set _currentTrackIsBanned(value: boolean) {
  if (this._currentTrackIsBanned !== value) {
      this._currentTrackIsBannedSubject.next(value);
      this._anyValueChangedSubject.next(true);
  }
}
get currentTrackIsBanned(): boolean {
  return this._currentTrackIsBanned;
}
private _currentTrackIsBannedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
public currentTrackIsBanned$: Observable<boolean> = this._currentTrackIsBannedSubject.asObservable();


  /**
  * next track is in favorite list
  */
 private get _nextTrackIsFavorite(): boolean {
  return this._nextTrackIsFavoriteSubject.value;
}

private set _nextTrackIsFavorite(value: boolean) {
  if (this._nextTrackIsFavorite !== value) {
      this._nextTrackIsFavoriteSubject.next(value);
      this._anyValueChangedSubject.next(true);
  }
}
get nextTrackIsFavorite(): boolean {
  return this._nextTrackIsFavorite;
}
private _nextTrackIsFavoriteSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
public nextTrackIsFavorite$: Observable<boolean> = this._nextTrackIsFavoriteSubject.asObservable();

/**
* next track is in banlist
*/
private get _nextTrackIsBanned(): boolean {
return this._nextTrackIsBannedSubject.value;
}

private set _nextTrackIsBanned(value: boolean) {
if (this._nextTrackIsBanned !== value) {
    this._nextTrackIsBannedSubject.next(value);
    this._anyValueChangedSubject.next(true);
}
}
get nextTrackIsBanned(): boolean {
return this._nextTrackIsBanned;
}
private _nextTrackIsBannedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
public nextTrackIsBanned$: Observable<boolean> = this._nextTrackIsBannedSubject.asObservable();


/**
  * current remote track is in favorite list
  */
 private get _currentRemoteTrackIsFavorite(): boolean {
  return this._currentRemoteTrackIsFavoriteSubject.value;
}

private set _currentRemoteTrackIsFavorite(value: boolean) {
  if (this._currentRemoteTrackIsFavorite !== value) {
      this._currentRemoteTrackIsFavoriteSubject.next(value);
      this._anyValueChangedSubject.next(true);
  }
}
get currentRemoteTrackIsFavorite(): boolean {
  return this._currentRemoteTrackIsFavorite;
}
private _currentRemoteTrackIsFavoriteSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
public currentRemoteTrackIsFavorite$: Observable<boolean> = this._currentRemoteTrackIsFavoriteSubject.asObservable();


/**
* current remote track is in banlist
*/
private get _currentRemoteTrackIsBanned(): boolean {
  return this._currentRemoteTrackIsBannedSubject.value;
  }

  private set _currentRemoteTrackIsBanned(value: boolean) {
  if (this._currentRemoteTrackIsBanned != value) {
      this._currentRemoteTrackIsBannedSubject.next(value);
      this._anyValueChangedSubject.next(true);
  }
  }
  get currentRemoteTrackIsBanned(): boolean {
  return this._currentRemoteTrackIsBanned;
  }
  private _currentRemoteTrackIsBannedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentRemoteTrackIsBanned$: Observable<boolean> = this._currentRemoteTrackIsBannedSubject.asObservable();



/**
 * Methods that sync values to our model
 */

  private syncCurrentTrackIsFavorite(){
    if (this.playlistService.favoritesLoadingError == null){
      if (this.playlistService.favorites != null && this.playlistService.favorites.audioFiles != null && this.musicPlayerService.currentActiveAudioFileWithPlayInfo != null && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile != null && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile instanceof AudioFile){
        this._currentTrackIsFavorite = playlistContainsAudioFile(this.playlistService.favorites, this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile);
      }else{
        this._currentTrackIsFavorite = false;
      }
    }else{
      if (!this.playlistService.favoritesLoading){
        this.playlistService.loadFavorites();
      }
    }
  }

  private syncNextTrackIsFavorite(){
    if (this.playlistService.favorites != null && this.playlistService.favorites.audioFiles != null && this.playStateService.nextPlayableAudio != null && this.playStateService.nextPlayableAudio instanceof AudioFile){
      this._nextTrackIsFavorite = playlistContainsAudioFile(this.playlistService.favorites, this.playStateService.nextPlayableAudio);
    }else{
      this._nextTrackIsFavorite = false;
    }
  }

  private syncRemoteTrackIsFavorite(){
    if (this.playlistService.favorites != null && this.playlistService.favorites.audioFiles != null && this.remotePlayStateService.currentTrack != null){
      this._currentRemoteTrackIsFavorite = this.playlistService.favorites.audioFiles.filter(track => track.id == this.remotePlayStateService.currentTrack.id).length > 0;
    }else{
      this._currentRemoteTrackIsFavorite = false;
    }
  }

  private syncCurrentTrackIsBanned(){
    if (this.playlistService.banlistLoadingError == null){
      if (this.playlistService.banlist != null && this.playlistService.banlist.audioFiles != null && this.musicPlayerService.currentActiveAudioFileWithPlayInfo != null && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile != null  && this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile instanceof AudioFile){
        this._currentTrackIsBanned = playlistContainsAudioFile(this.playlistService.banlist, this.musicPlayerService.currentActiveAudioFileWithPlayInfo.audioFile);
      }else{
        this._currentTrackIsBanned = false;
      }
    }else{
      if (!this.playlistService.banlistLoading){
        this.playlistService.loadBanlist();
      }
    }
  }

  private syncNextTrackIsBanned(){
    if (this.playlistService.banlist != null && this.playlistService.banlist.audioFiles != null && this.playStateService.nextPlayableAudio != null && this.playStateService.nextPlayableAudio instanceof AudioFile){
      this._nextTrackIsBanned = playlistContainsAudioFile(this.playlistService.banlist, this.playStateService.nextPlayableAudio);
    }else{
      this._nextTrackIsBanned = false;
    }
  }

  private syncRemoteTrackIsBanned(){
    if (this.playlistService.banlist != null && this.playlistService.banlist.audioFiles != null && this.remotePlayStateService.currentTrack != null){
      this._currentRemoteTrackIsBanned = this.playlistService.banlist.audioFiles.filter(track => track.id == this.remotePlayStateService.currentTrack.id).length > 0;
    }else{
      this._currentRemoteTrackIsBanned = false;
    }
  }

  /**
   * Methods to adjust favorite / banned status
   */

  public adjustIsFavorite(audioFile: AudioFile, value: boolean){
    //only make adjustments once the favorites are loaded
    if (this.playlistService.favorites != null && this.playlistService.favorites.audioFiles !=null){
      if (value){
        if (!playlistContainsAudioFile(this.playlistService.favorites, audioFile)){
            this.playlistService.addAudioFileToPlaylist(this.playlistService.favorites, [audioFile]);
        }
        //remove from banlist - a favorite track can not be banned
        this.removeAllSameTracksFromPlaylist(this.playlistService.banlist, audioFile);
      }else{
        this.removeAllSameTracksFromPlaylist(this.playlistService.favorites, audioFile);
      }
    }
  }

  public adjustIsBanned(audioFile: AudioFile, value: boolean){
    //only make adjustments once the banlist is loaded
    if (this.playlistService.banlist != null && this.playlistService.banlist.audioFiles !=null){
      if (value){
        if (!playlistContainsAudioFile(this.playlistService.banlist, audioFile)){
            this.playlistService.addAudioFileToPlaylist(this.playlistService.banlist, [audioFile]);
        }
        //remove from banlist - a favorite track can not be banned
        this.removeAllSameTracksFromPlaylist(this.playlistService.favorites, audioFile);
      }else{
        this.removeAllSameTracksFromPlaylist(this.playlistService.banlist, audioFile);
      }
    }
  }


  private removeAllSameTracksFromPlaylist(playlist: Playlist, audioFile: AudioFile){
    if (playlist != null && playlist.audioFiles !=null){
      const audioFilesToRemove = [];
      playlist.audioFiles.forEach(
        audioFileInPlaylist=>{
          if (audioFile.id == audioFileInPlaylist.id){
            audioFilesToRemove.push(audioFileInPlaylist);
          }
        }
      )
      if (audioFilesToRemove.length > 0){
        this.playlistService.deleteAudioFileInPlaylist(playlist, audioFilesToRemove);
      }
    }
  }

}


