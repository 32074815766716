<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
   <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Group" stroke="currentColor">
        <path d="M1,4 C1,3.20435053 1.31607056,2.44128877 1.87867969,1.87867963 C2.44128883,1.3160705 3.20435053,1 4,1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
        <path d="M4,20 C3.20435053,20 2.44128883,19.6839299 1.87867969,19.1213207 C1.31607056,18.5587116 1,17.7956495 1,17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
        <path d="M17,1 C17.7956495,1 18.5587116,1.3160705 19.1213207,1.87867963 C19.6839299,2.44128877 20,3.20435053 20,4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
        <rect fill="currentColor" x="0.5" y="7.5" width="1" height="6" rx="0.5"></rect>
        <rect fill="currentColor" x="19.5" y="7.5" width="1" height="5" rx="0.5"></rect>
        <rect fill="currentColor" x="7.5" y="0.5" width="6" height="1" rx="0.5"></rect>
        <rect fill="currentColor" x="7.5" y="19.5" width="5" height="1" rx="0.5"></rect>
        <path d="M17.9997648,23 L19.4996467,19.5001767 L23,18.0001181 L15,15 L17.9997648,23 Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
    </g>
  </g>
</svg>
