import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';

@Component({
  selector: 'tun-overlay-select-music-collection-view',
  templateUrl: './overlay-select-music-collection-view.component.html',
  styleUrls: ['./overlay-select-music-collection-view.component.scss']
})
export class OverlaySelectMusicCollectionViewComponent {

  @Input() musicChannel: MusicChannel;

  @Output() back = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  @Output() selectMusicCollection = new EventEmitter<MusicCollection>();

  onSelectMusicCollection(musicCollection: MusicCollection){
    this.selectMusicCollection.emit(musicCollection);
  }

  onBack(){
    this.back.emit()
  }

  onClose(){
    this.close.emit();
  }
}
