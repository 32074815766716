export const musicCollection_8201 = {
    "@type": "Context",
    "id": 8201,
    "title": "Dynamisch",
    "description": "08",
    "lastChanged": null,
    "changeableParameter": [
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1980,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1980,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 4,
            "maximum": 5,
            "step": 1,
            "lowerSelection": 4,
            "upperSelection": 5,
            "metadataDisplayMinimum": "4",
            "metadataDisplayMaximum": "5"
        }
    ],
    "defaultChangeableParameter": [
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1980,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1980,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 4,
            "maximum": 5,
            "step": 1,
            "lowerSelection": 4,
            "upperSelection": 5,
            "metadataDisplayMinimum": "4",
            "metadataDisplayMaximum": "5"
        }
    ],
    "visible": true,
    "editable": false,
    "favourite": false,
    "selected": false,
    "mostPlayed": false,
    "defaultSelected": false
};