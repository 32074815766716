import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { Day } from '@model/day';
import { carouselAnimation, fadeAnimation } from '@util/animations';
import { Subject } from 'rxjs';
import { CALENDAR_CAROUSEL_ANIMATION_DURATION_MS } from '../calendar-detail-view.component';
import { animate, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { CalendarService } from '@service/calendar.service';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';

@Component({
  selector: 'tun-calendar-day-navigator',
  templateUrl: './calendar-day-navigator.component.html',
  styleUrls: ['./calendar-day-navigator.component.scss'],
  animations: [
    carouselAnimation,
    trigger('dayPosition', [
      state('left', style({
        transform: `translateX(-100%)`
      })),
      state('mid', style({
        transform: `translateX(0%)`
      })),
      state('right', style({
        transform: `translateX(100%)`
      })),
      transition('void => left', [
        style({ transform: `translateX(-200%)` }),
        animate(
          `${(CALENDAR_CAROUSEL_ANIMATION_DURATION_MS)}ms ease-in-out`,
          )
      ]),
      transition('left => void', [
        animate(
          `${(CALENDAR_CAROUSEL_ANIMATION_DURATION_MS)}ms ease-in-out`,
          style({ transform: `translateX(-200%)` }),
          )
      ]),
      transition('void => right', [
        style({ transform: `translateX(200%)` }),
        animate(
          `${(CALENDAR_CAROUSEL_ANIMATION_DURATION_MS)}ms ease-in-out`,
        )
      ]),
      transition('right => void', [
        animate(
          `${(CALENDAR_CAROUSEL_ANIMATION_DURATION_MS)}ms ease-in-out`,
          style({ transform: `translateX(200%)` }),
          )
      ]),
      transition('mid <=> right', [
        animate(
          `${(CALENDAR_CAROUSEL_ANIMATION_DURATION_MS)}ms ease-in-out`,
        ),
      ]),
      transition('left <=> mid', [
        animate(
          `${(CALENDAR_CAROUSEL_ANIMATION_DURATION_MS)}ms ease-in-out`,
        ),
      ])
  ]),
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, { params: { duration: 100 } })
      ]),
      transition(':leave', [
        // fade out
        useAnimation(fadeAnimation, { params: { from: 1, to: 0 } })
      ])
    ])],
})
export class CalendarDayNavigatorComponent {

  // === Constants === //
  readonly CAROUSEL_ANIMATION_DURATION = CALENDAR_CAROUSEL_ANIMATION_DURATION_MS;

  // === Props === //
  @Input() inEditMode: boolean;
  @Input() animationI: number;
  @Input() currentIndex = 0;
  @Input() animationDirection: 'next' | 'prev';

  // === State === //
  private _animatingInIndex: number;

  // === Emitters === //
  @Output() daychange = new EventEmitter<number>();
  @Output() openCurrentTime = new EventEmitter<void>();

  // === Getters === //
  get animatingInIndex(): number {
    return this._animatingInIndex;
  }
  get daysOfWeek(): Day[] {
    return Day.DAYS;
  }

  public shouldShowDay(dayIndex: number){
    return dayIndex === this.currentIndex
              || dayIndex === (this.currentIndex + 1) % this.daysOfWeek.length
              || dayIndex === (this.currentIndex + this.daysOfWeek.length - 1) % this.daysOfWeek.length
  }

  public dayPosition(dayIndex: number){
    if (dayIndex === this.currentIndex){
      return 'mid'
    }else if (dayIndex === (this.currentIndex + 1) % this.daysOfWeek.length){
      return 'right'
    }
    return 'left'
  }

  constructor(
    private appV5StateService: AppV5StateService,
    private calendarService: CalendarService
  ) {}



  private _destroy$ = new Subject<void>();
  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$ = null;
  }

  public onViewCurrentTime(){
    this.openCurrentTime.emit();
  }

  public onNext(){
    this.daychange.emit(1);
  }

  public onPrevious(){
    this.daychange.emit(-1);
  }

  public copyToDay(day: Day){
    if (this.appV5StateService.showDetailsForCalendar){
      this.calendarService.copyDay(this.appV5StateService.showDetailsForCalendar, Day.DAYS[this.currentIndex], [day]);
    }

  }

  public copyToAllDays(){
    if (this.appV5StateService.showDetailsForCalendar){
      this.calendarService.copyDay(this.appV5StateService.showDetailsForCalendar, Day.DAYS[this.currentIndex], Day.DAYS);
    }
  }

  public onDelete(){
    if (this.appV5StateService.showDetailsForCalendar){
      this.calendarService.clearDay(this.appV5StateService.showDetailsForCalendar, Day.DAYS[this.currentIndex]);
    }
  }
}
