<div class="containerPopup">

  <div mat-dialog-content class="content-container">

     <!-- header -->
    <tun-popup-header
      (close)="onCancelClick()"
      [isInMatDialog]="'true'">
        {{ 'popup.tooManyTracks.title' | translate }}
    </tun-popup-header>

      <div
        class="text-container">
          {{ text }}
      </div>
  </div>

</div>

