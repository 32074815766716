import {
  Component,
  Input,
  Type,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { getSlideAnimation } from '../popup-animations';
import { PopupContentDirective } from '../popup-content.directive';
import { PopupContent } from './interfaces';
import { AnimationBuilder, AnimationPlayer } from '@angular/animations';
import { PopupDirection } from './enums';
import { PopupArrowComponent } from '../popup-arrow/popup-arrow.component';
import { OnDestroy } from '@angular/core';
import { ResizedEvent } from '@components/resize/resize.directive';
import { Subject, merge, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnDestroy{
  // === Props === //
  @Input() isOpen = false;
  @Input() popupDirection: PopupDirection;
  @Input() hasArrow: boolean;

  @Output() resize = new EventEmitter<void>();

  // === Getters === //
  get extraPaddingTop(): number {
    return this.popupDirection === PopupDirection.UP
      ? PopupArrowComponent.arrowSize
      : 0;
  }

  get extraPaddingBottom(): number {
    return this.popupDirection === PopupDirection.DOWN
      ? PopupArrowComponent.arrowSize
      : 0;
  }

  // === ViewChildren === //
  @ViewChild(PopupContentDirective, { static: true })
  popupContent: PopupContentDirective;
  @ViewChild('animationElement', { static: true }) animationElement: ElementRef;

  constructor(
    private _animationBuilder: AnimationBuilder
  ) {}


  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.cleanupAnimation();

    this.newOnriszeEvent$.complete();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private animationPlayer: AnimationPlayer = null;
  private cleanupAnimation(){
    if (this.animationPlayer){
      this.animationPlayer.destroy();
    }
  }

  public createContentComponent(
    componentType: Type<PopupContent>
  ): PopupContent {

    const viewContainerRef = this.popupContent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentType);
    return componentRef.instance;
  }

  public animatePopup(duration: number) {
    const animation = this._animationBuilder.build(
      getSlideAnimation(
        this.popupDirection,
        this.isOpen,
        duration,
        this.hasArrow
      )
    );
    this.cleanupAnimation();
    this.animationPlayer = animation.create(this.animationElement.nativeElement);

    //player.onDone(()=>player.destroy());
    this.animationPlayer.play();

  }



  private newOnriszeEvent$ = new Subject<void>();
  public onResize(){
    this.newOnriszeEvent$.next();
    this.resize.emit();

    //Trigger resize event after 500ms, so repositioning is done after any css animation (if not longer than 500ms)
    timer(500)
    .pipe(
      takeUntil(
        merge(
          this.newOnriszeEvent$,
          this.destroyed$
        )
      )
    )
    .subscribe(
      () => {
        this.resize.emit();
      }
    );
  }

}
