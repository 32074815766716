import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlaySelectPlaylistComponent } from './overlay-select-playlist/overlay-select-playlist.component';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsV5Module } from '@components/components-v5/components-v5.module';
import { IconsV5Module } from '@components/icons-v5/icons-v5.module';
import { IconsModule } from '@components/icons/icons.module';
import { OverlaySongInfoComponent } from './overlay-song-info/overlay-song-info.component';
import { SongInfoViewComponent } from './overlay-song-info/song-info-view/song-info-view.component';
import { SongInfoSelectPlaylistViewComponent } from './overlay-song-info/song-info-select-playlist-view/song-info-select-playlist-view.component';
import { OverlayPlaylistButtonViewComponent } from './overlay-select-playlist/select-playlist-view/overlay-playlist-button-view/overlay-playlist-button-view.component';
import { SelectPlaylistViewComponent } from './overlay-select-playlist/select-playlist-view/select-playlist-view.component';
import { OverlayTweakMusicCollectionComponent } from './overlay-tweak-music-collection/overlay-tweak-music-collection.component';
import { OverlayTweakCalendarItemComponent } from './overlay-tweak-calendar-item/overlay-tweak-calendar-item.component';
import { OverlaySelectMusicChannelComponent } from './overlay-select-music-channel/overlay-select-music-channel.component';
import { OverlaySelectMusicChannelViewComponent } from './overlay-select-music-channel/overlay-select-music-channel-view/overlay-select-music-channel-view.component';
import { OverlaySelectMusicCollectionViewComponent } from './overlay-select-music-channel/overlay-select-music-collection-view/overlay-select-music-collection-view.component';
import { OverlayMusicCollectionButtonViewComponent } from './overlay-select-music-channel/overlay-select-music-collection-view/overlay-music-collection-button-view/overlay-music-collection-button-view.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@NgModule({
  declarations: [
    OverlaySelectPlaylistComponent,
    OverlayPlaylistButtonViewComponent,
    OverlaySongInfoComponent,
    SongInfoViewComponent,
    SelectPlaylistViewComponent,
    SongInfoSelectPlaylistViewComponent,
    OverlayTweakMusicCollectionComponent,
    OverlayTweakCalendarItemComponent,
    OverlaySelectMusicChannelComponent,
    OverlaySelectMusicChannelViewComponent,
    OverlaySelectMusicCollectionViewComponent,
    OverlayMusicCollectionButtonViewComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ComponentsV5Module,
    IconsV5Module,
    IconsModule,
    TranslateModule,
    MatSlideToggleModule
  ],
  exports: [
    OverlaySelectPlaylistComponent,
    OverlaySongInfoComponent,
    OverlayTweakMusicCollectionComponent,
    OverlayTweakCalendarItemComponent,
    OverlaySelectMusicChannelComponent
  ]
})
export class OverlaysV5Module { }
