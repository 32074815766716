import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MusicChannel } from '@model/musicChannel';
import { MusicCollection } from '@model/musicCollection';
import { MusicChannelService } from '@service/music-channel.service';
import { Subject } from 'rxjs';
import { MusicChannelData } from '../overlay-select-music-channel.component';

@Component({
  selector: 'tun-overlay-select-music-channel-view',
  templateUrl: './overlay-select-music-channel-view.component.html',
  styleUrls: ['./overlay-select-music-channel-view.component.scss']
})
export class OverlaySelectMusicChannelViewComponent implements OnDestroy {

  @Output() selectMusicChannel = new EventEmitter<MusicChannel>();
  @Output() close = new EventEmitter<void>();

  public get musicChannelsLoading$(){
    return this.musicChannelService.loading$
  }

  public get musicChannelsLoadingError$(){
    return this.musicChannelService.loadingError$
  }

  public get musicChannelGroups$(){
    return this.musicChannelService.musicChannelGroups$
  }

  constructor(
    private musicChannelService: MusicChannelService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onRetry(){
    this.musicChannelService.loadMusicChannelGroups();
  }

  public onShowDetails(musicChannel: MusicChannel){
    this.selectMusicChannel.emit(musicChannel);
  }

  public onClose(){
    this.close.emit();
  }
}
