export class Value {
    value: string;
    selected: boolean;

    constructor(value?: Value){
        if (value){
            this.value = value.value;
            this.selected = value.selected;
        }
    }
}