import { Component } from '@angular/core';
import { PlayState } from '@model/enums/playState.enum';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { ZoneConnectionService } from '@service/authentication/zone-connection.service';
import { ApplicationMode, ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-player-view',
  templateUrl: './player-view.component.html',
  styleUrls: ['./player-view.component.scss']
})
export class PlayerViewComponent {

  public open = true
  public showOpenContent = true
  public showClosedContent = false

  public get playing$(){
    return this.playingItemService.playState$
      .pipe(
        map(state => state == PlayState.PLAYING || state == PlayState.STARTING_TO_PLAY)
      )
  }

  get remoteMode$(){
    return this.zoneConnectionsService.applicationMode$
    .pipe(map(applicationMode => applicationMode == ApplicationMode.remoteMode))
  }

  constructor(
    private playingItemService: PlayingItemService,
    private zoneConnectionsService: ZoneConnectionsService,
    private musicManipulationService: MusicManipulationService
  ) {

  }

  public onToggleOpen(){
    this.open = !this.open;
    this.animationStarts();
    //directly hide the open / closed content
    if (!this.open){
      this.showOpenContent = false
    }else{
      this.showClosedContent = false
    }
  }

  public isAnimating = false
  private animationTime = 400;
  private animateTimer: NodeJS.Timeout;
  private animationStarts(){
    this.clearAnimationTimer();

    this.isAnimating = true;
    this.animateTimer = setTimeout(() => {
      this.isAnimating = false

      this.showOpenContent = this.open
      this.showClosedContent = !this.open

      this.clearAnimationTimer();
    }, this.animationTime);
  }

  private clearAnimationTimer(){
    if (this.animateTimer) {
      clearTimeout(this.animateTimer);
      this.animateTimer = null;
    }
  }


  public onTogglePlay(){
    if (this.playingItemService.isPlaying){
      this.musicManipulationService.pause()
    }else{
      this.musicManipulationService.play()
    }

  }
}
