import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Playlist } from '@model/playlist';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { PlaylistService } from '@service/playlist.service';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'tun-edit-title-for-playlist-view',
  templateUrl: './edit-title-for-playlist-view.component.html',
  styleUrls: ['./edit-title-for-playlist-view.component.scss']
})
export class EditTitleForPlaylistViewComponent implements OnDestroy, AfterViewInit {

   // === ViewChildren === //
   @ViewChild('nameInput')
   nameInput: ElementRef;

  //null = create a playlist
  private _playlist: Playlist;
  @Input()
  public set playlist(playlist: Playlist){
    this._playlist = playlist;
    if (playlist != null){
      this.currentName = this._playlist.title;
    }else{
      this.currentName = "";
    }

  }
  public get playlist(){
    return this._playlist;
  }

  private _canReceiveFocus = false;
  @Input()
  public set canReceiveFocus(value: boolean){
    this._canReceiveFocus = value;
    this.focusIfPossible();
  }
  public get canReceiveFocus(){
    return this._canReceiveFocus;
  }

  private componentDestroyed$ = new Subject<boolean>();

  @Output() back = new EventEmitter<void>();

  onBack(){
    this.back.emit();
  }

  public currentName = "";
  onNameChange(name: string){
    this.currentName = name;
  }

  constructor(
    private appV5StateService: AppV5StateService,
    private playlistService: PlaylistService
  ){

  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$ = null;
  }

  private _initDone = false;
  private set initDone(value: boolean){
    this._initDone = value;
    this.focusIfPossible();

  }
  private get initDone(){
    return this._initDone;
  }
  private focusIfPossible(){
    if (this.initDone && this.canReceiveFocus){
      if (this.nameInput) {
        this.nameInput.nativeElement.focus();
      }
    }
  }

  ngAfterViewInit(): void {

    //we need to set the focus after the views animation has finished
    timer(100)
    .pipe(
      takeUntil(
        this.componentDestroyed$
      )
    )
    .subscribe(
      () => {
        this.initDone = true;
      }
    )
  }

  onCommit(){
    if (this.playlist == null){
      //create playlist
      const playlist = new Playlist()
      playlist.title = this.currentName;
      this.playlistService.createPlaylist(playlist);

      this.appV5StateService.showPlaylistInTunifyOrange(playlist);
    }else{
      this.playlist.title = this.currentName;
      this.playlistService.savePlaylistTitle(this.playlist);
      this.onBack();
    }

  }
}
