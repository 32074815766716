import { Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '../loggers/logger.service';
import { Subject, BehaviorSubject, Observable, merge, timer } from 'rxjs';
import { MusicChannelGroup } from '@model/musicChannelGroup';
import { finalize, takeUntil } from 'rxjs/operators';
import { MusicChannel } from '@model/musicChannel';
import { MusicChannelApiService } from '@service/api/music-channel-api.service';
import { MusicCollection } from '@model/musicCollection';
import { Context } from '@model/context';
import { DTO_MusicCollection } from '@service/api/dto/music-collection';
import { DTO_Context } from '@service/api/dto/context';
import { convertChangeableParameterToDTO } from './util/model-to-dto-converters';
import { MusicChannelGroupType } from '@model/enums/musicChannelGroupType';
import { ZoneConfigurationService } from './zone-configuration.service';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { HttpErrorResponse } from '@angular/common/http';
import { musicSelection } from './mock/zoneConfiguration';
import { MusicCollectionService } from './music-collection.service';
import { createClassObjectForMusicCollection } from './util/musicCollectionUtil';
import { Playlist } from '../../model/playlist';
import { DTO_LoadMusicChannelGroupsResponse } from '../api/music-channel-api.service';
import { Config } from '@service/config';


@Injectable()
export class MusicChannelService implements OnDestroy {

  private LOGGER_CLASSNAME = 'MusicChannelService';

  //helper observable that triggers when the active zone changes
  //this will cancel any running request
  private activeZoneChanged$ = new Subject<void>();

  constructor(
    private musicChannelApiService: MusicChannelApiService,
    private loggerService: LoggerService,
    private zoneConnectionsService: ZoneConnectionsService,
    private zoneConfigurationService: ZoneConfigurationService,
    private musicCollectionService: MusicCollectionService
  ) {

    this.zoneConnectionsService.activeZoneConnection$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => {
          this.clearData();
          this.loadDataIfPossible();
        }
      );

    this.zoneConfigurationService.language$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (value) => {
          //start loading once the language is known. This will also reload the data when the language is changed.
          if (value != null) {
            this.loadDataIfPossible();
          }
        }
      );

      this.zoneConfigurationService.zoneConfigurationError$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (loadError) => {
          //When loading the zoneConfigration fails AND we did not load the calendarGroups: start loading without language
          if (loadError != null && this.musicChannelGroups == null && !this.loading) {
            this.loadDataIfPossible();
          }
        }
      );



  }

  private destroyed$ = new Subject<void>();
  public ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
    this.destroyed$ = null;

    this.activeZoneChanged$.next();
    this.activeZoneChanged$.complete();
    this.activeZoneChanged$ = null;

    this.musicChannelGroupsWillStartLoading$.complete();
    this.musicChannelGroupsWillStartLoading$ = null;
  }

  //load the data once all depending data is available
  private loadDataIfPossible() {
    if (this.zoneConnectionsService.activeZoneConnection != null && (this.zoneConfigurationService.language != null || this.zoneConfigurationService.zoneConfigurationError != null)) {
      this.loadMusicChannelGroups();
    }
  }

  private clearData() {
    //cancel any loading requests
    this.activeZoneChanged$.next();

    this._loading = false;
    this._loadingError = null;
    this._musicChannelGroups = null;
  }


  /**
   * loading
   * @type {boolean}
   * @private
   */
  private get _loading(): boolean {
    return this._loadingSubject.value;
  }
  private set _loading(value: boolean) {
    if (this._loading !== value) {
      this._loadingSubject.next(value);
    }
  }
  get loading(): boolean {
    return this._loading;
  }
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loadingSubject.asObservable();

  /**
   * Error emitter for retrieving the musicChannelGroups
   * @type {string}
   * @private
   */
  private get _loadingError(): string {
    return this._loadingErrorSubject.value;
  }
  private set _loadingError(value: string) {
    if (this._loadingError !== value) {
      this._loadingErrorSubject.next(value);
    }
  }
  get loadingError(): string {
    return this._loadingError;
  }
  private _loadingErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public loadingError$: Observable<string> = this._loadingErrorSubject.asObservable();

  /**
   * musicChannelGroups
   * @type {MusicChannelGroup[]}
   * @private
   */
  private get _musicChannelGroups(): MusicChannelGroup[] {
    return this._musicChannelGroupsSubject.value;
  }
  private set _musicChannelGroups(value: MusicChannelGroup[]) {
    if (this._musicChannelGroups !== value) {
      this.createAllMusicChannelArray(value);
      this._musicChannelGroupsSubject.next(value);
    }
  }
  get musicChannelGroups(): MusicChannelGroup[] {
    return this._musicChannelGroups;
  }
  private _musicChannelGroupsSubject: BehaviorSubject<MusicChannelGroup[]> = new BehaviorSubject<MusicChannelGroup[]>(null);
  public musicChannelGroups$: Observable<MusicChannelGroup[]> = this._musicChannelGroupsSubject.asObservable();


/**
   * Versioning
   */
 private musicChannelGroupsTimestamp: number = undefined;
 private timestampFromUpdateTrigger: number = undefined; //timestamp that we have received after a save or update event. This is used when the data is fetched to check if we have the most recent version
 private refetchedToAvoidVersionConflict = false; //boolean to avoid we fetch multiple times in a row (avoid endless loop when the server messes up the version numbers)

 public handleMusicChannelGroupsUpdateInfo(timestamp: number){
   //keep track of the highest seen timestamp
   if (timestamp != undefined){
     if (this.timestampFromUpdateTrigger == undefined || this.timestampFromUpdateTrigger < timestamp) {
       this.timestampFromUpdateTrigger = timestamp;
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleMusicChannelGroupsUpdateInfo', 'Going to check for newest version:  ' + timestamp);
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleMusicChannelGroupsUpdateInfo', 'Already going to check for an even newer version:  ' + this.timestampFromUpdateTrigger + '(we just received: ' + timestamp + ')');
     }
   }else{
     this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleMusicChannelGroupsUpdateInfo', 'No timestamp info');
   }

   this.checkReloadMusicChannelGroupsNeeded();
 }

 private checkReloadMusicChannelGroupsNeeded(){
   if (this.timestampFromUpdateTrigger != undefined){
     //if we are not loading the calendarGroups -> fetch
     if (!this.loading) {
       if (this.timestampFromUpdateTrigger > this.musicChannelGroupsTimestamp){
         this.loadMusicChannelGroups();
       }else{
         this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadMusicChannelGroupsNeeded', 'Already have a newer version (' + this.musicChannelGroupsTimestamp + '), not going to reload for update trigger timestamp ' + this.timestampFromUpdateTrigger);
       }
     }else{
       this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadMusicChannelGroupsNeeded', 'Not going to load right now: loading: ' + (this.loading ? 'true': 'false') + '.');
     }
   }
 }

  //the subject to cancel any previous request
  private musicChannelGroupsWillStartLoading$: Subject<void> = new Subject<void>();
  public loadMusicChannelGroups(): Observable<MusicChannelGroup[]> {

    const loadObservable: Observable<DTO_LoadMusicChannelGroupsResponse> = this.musicChannelApiService.loadMusicChannelGroups(MusicChannelGroupType.RADIO);

    if (loadObservable != null){

      this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadMusicChannelGroups', 'Going to load musicChannelGroups');
      this.musicChannelGroupsWillStartLoading$.next(null);

      this._loading = true;

      loadObservable
        .pipe(
          takeUntil(
            merge(
              this.destroyed$,
              this.activeZoneChanged$,
              this.musicChannelGroupsWillStartLoading$
            )
          ),
          finalize(() => {
            this._loading = false;
            this.checkReloadMusicChannelGroupsNeeded()
          })
        )
        .subscribe(
          (data) => {

            let useResponse = true;
            if (this.timestampFromUpdateTrigger != undefined && this.timestampFromUpdateTrigger > data.timestamp) {
              //there is a newer resource version on the server -> refetch
              if (!this.refetchedToAvoidVersionConflict) {
                //we are only going to refetch once, to avoid endless loop of fetching data when there is something wrong with our version timestamps
                this.refetchedToAvoidVersionConflict = true;
                useResponse = false;
              } else {
                //ignore the newer version and start using this queue

                this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadMusicChannelGroups', 'loadMusicChannelGroups receives an older version number but we already refetched before -> just start working with this version');
              }
            }

            if (useResponse) {

              //reset the conflict flag once we use the queue
              this.refetchedToAvoidVersionConflict = false;
              //reset the highest seen timestamp
              this.timestampFromUpdateTrigger = undefined;

              if (data.musicChannelGroups.length > 0) {
                //we should construct real objects with the correct type (so we can use instanceof to check the type)
                const realObjects = data.musicChannelGroups.map(res => new MusicChannelGroup(res));

                //If we need to store addition data (loaded musicCollections that are still up to date, etc.), we should copy this here

                this._musicChannelGroups = realObjects;
              } else {
                this._musicChannelGroups = [];
                this.loggerService.error(this.LOGGER_CLASSNAME, "loadMusicChannelGroups", "result is empty");
              }

              this.musicChannelGroupsTimestamp = data.timestamp;

              this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadMusicChannelGroups', 'loadMusicChannelGroups done (timestamp: ' + this.musicChannelGroupsTimestamp + '): ' + this.musicChannelGroups.length + ' musicChannelGroups.');
            } else {
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadMusicChannelGroups', 'Not going to use result -> reload musicChannelGroups from the finalize block.');
            }
          },
          (error : unknown) => {
            let errMsg = 'Error';
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            }
            this.loggerService.error(this.LOGGER_CLASSNAME, 'loadMusicChannelGroups error', errMsg);
            this._loadingError = 'GeneralError';
          });

    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadMusicChannelGroups', 'Observable to load musicChannelGroups was not created');
    }

    return this.musicChannelGroups$;
  }


  //helper array to look up musicChannels
  private allMusicChannels: MusicChannel[] = null;
  private createAllMusicChannelArray(musicChannelGroups: MusicChannelGroup[]) {
    if (musicChannelGroups) {
      this.allMusicChannels = [];
      musicChannelGroups.forEach(
        musicChannelGroup => {
          if (musicChannelGroup.musicChannels) {
            this.allMusicChannels = this.allMusicChannels.concat(musicChannelGroup.musicChannels);
          }
        }
      )
    } else {
      this.allMusicChannels = null;
    }
  }

  public findMusicChannelById(musicChannelId: number): MusicChannel {
    if (this.allMusicChannels) {
      return this.allMusicChannels.find(musicChannel => musicChannel.id === musicChannelId);
    }
    return null;
  }

  public saveMusicCollectionSelectionProperty(musicChannel: MusicChannel, musicCollection: MusicCollection) {
    musicCollection.localChanges = true;

    this.performSave(musicChannel.id, musicCollection);
  }

  public saveContextParameters(musicChannel: MusicChannel, context: Context) {
    context.localChanges = true;
    context.parametersChanged = true;

    this.performSave(musicChannel.id, context);
  }

  public resetContextParameters(musicChannel: MusicChannel, context: Context) {
    if (context.defaultChangeableParameter) {
      //improvement: check if there are changes before triggering the save to server
      context.resetParameters();

      this.saveContextParameters(musicChannel, context);
    }
  }

  private retrySaveMusicCollection(musicChannelId: number, musicCollection: MusicCollection) {
    const delay = Math.pow(2, musicCollection.saveFailedAttemps - 1) * Config.RETRY_BASE_INTERVAL_MS;
    timer(Math.min(delay, Config.RETRY_MAX_INTERVAL_MS))
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      ()=>{
         //if no reload happened, check if we need to re-save (or save new changes)
         if (musicCollection.localChanges && !musicCollection.detailsLoading){
          this.performSave(musicChannelId, musicCollection);
        }

      }
    )
  }

  private performSave(musicChannelId: number, musicCollection: MusicCollection) {

    if (!musicCollection.saving){
      let dto_musicCollection;
      if (musicCollection instanceof Context && musicCollection.parametersChanged){
        dto_musicCollection = new DTO_Context();

        dto_musicCollection.changeableParameter = [];
        musicCollection.changeableParameter.forEach(parameter =>
          dto_musicCollection.changeableParameter.push(convertChangeableParameterToDTO(parameter))
        );
      }else{
        dto_musicCollection = new DTO_MusicCollection();
      }

      dto_musicCollection.id = musicCollection.id;
      dto_musicCollection["@type"] = musicCollection["@type"];
      dto_musicCollection.selected = musicCollection.selected;
      dto_musicCollection.timestamp = musicCollection.timestamp;

      const saveObservable = this.musicChannelApiService.saveMusicCollection(musicChannelId, dto_musicCollection);

      if (saveObservable != null){
        musicCollection.saving = true;

        let parametersChangedBeforeSave = false;
        if (musicCollection instanceof Context){
          parametersChangedBeforeSave = musicCollection.parametersChanged;
          musicCollection.parametersChanged = false;
        }
        musicCollection.localChanges = false;


        saveObservable
          .pipe(
            finalize(() => {
              musicCollection.saving = false;

              //before we re-save or do a second save -> check if we need to reload
              //if the musicCollection is reloaded, all local changes will be thrown away
              this.checkReloadMusicCollectionNeeded(musicChannelId, musicCollection);

              //if no reload happened, check if we need to re-save (or save new changes)
              if (musicCollection.localChanges && !musicCollection.detailsLoading){
                this.retrySaveMusicCollection(musicChannelId, musicCollection);
              }

            }),
            takeUntil(
              this.destroyed$
            )
          )
          .subscribe(
            (data) => {
              if (data.timestamp != undefined){
                musicCollection.timestamp = data.timestamp;
              }
              musicCollection.saveFailedAttemps = 0;
            },
            (error: unknown) => {
              let errMsg = 'Server error';
              let discardChanges = false;

              musicCollection.saveFailedAttemps++;

              if (error instanceof HttpErrorResponse){
                errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';

                if (error.status == 409){
                  musicCollection.refetchNeededForConflict = true;
                }else if (error.status == 404){
                  //musicCollection was removed in the meanwhile
                  this.loggerService.error(this.LOGGER_CLASSNAME, "performSave", "404 error -> musicCollection was removed in the meanwhile. Going to discard the local changes.");
                  discardChanges = true;
                }else if (error.status == 400){
                  //BAD REQUEST: somehow had weird settings
                  this.loggerService.error(this.LOGGER_CLASSNAME, "performSave", "400 error -> musicCollection save has a bad request. Going to discard the local changes.");
                  discardChanges = true;
                }
              }
              this.loggerService.error(this.LOGGER_CLASSNAME, "performSave", "error: " + errMsg);

              //save failed -> we have local changes
              musicCollection.localChanges = !discardChanges;
              if (!discardChanges && musicCollection instanceof Context){
                musicCollection.parametersChanged = musicCollection.parametersChanged || parametersChangedBeforeSave;
              }

            }
          );
      }
    }
  }

  /**
   * Versioning
   */

   public handleMusicCollectionUpdateInfo(musicCollectionId: number, matchData: string, timestamp: number){


    if (timestamp != undefined){

      if (musicCollectionId != null && matchData != null){
        //find the musicCollection
        if (this.musicChannelGroups){

          let found = false;
          this.musicChannelGroups.forEach(musicChannelGroup => {
            if (musicChannelGroup.musicChannels){
              musicChannelGroup.musicChannels.forEach(musicChannel => {
                if (musicChannel.musicCollections && matchData.toLowerCase() == 'musicchannel-' +  musicChannel.id){
                  musicChannel.musicCollections.forEach(musicCollection => {
                    if (musicCollection.id == musicCollectionId){
                      found = true;
                      if (musicCollection.timestampFromUpdateTrigger == undefined || musicCollection.timestampFromUpdateTrigger < timestamp){
                        musicCollection.timestampFromUpdateTrigger = timestamp;
                        this.checkReloadMusicCollectionNeeded(musicChannel.id, musicCollection);
                      }
                    }
                  });
                }
              })
            }
          });

          if (!found){
            this.loggerService.error(this.LOGGER_CLASSNAME, "handleMusicCollectionUpdateInfo", "musicCollection " + musicCollectionId + " not found.");
          }
        }
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, 'handleMusicCollectionUpdateInfo', 'should handle updateInfo - musicCollection id or matchData not filled in');
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'handleMusicCollectionUpdateInfo', 'should handle updateInfo for musicCollection with id ' + musicCollectionId + ', but no timestamp received.');
    }
  }

   private checkReloadMusicCollectionNeeded(musicChannelId: number, musicCollection: MusicCollection){
    if (musicCollection.refetchNeededForConflict){
      this.loadMusicCollection(musicChannelId, musicCollection);
    }else if (musicCollection.timestampFromUpdateTrigger != undefined){
      if (musicCollection.timestampFromUpdateTrigger > musicCollection.timestamp){
        if (!musicCollection.saving && !musicCollection.detailsLoading){
          this.loadMusicCollection(musicChannelId, musicCollection);
        }
      }else{
        if (musicCollection.timestampFromUpdateTrigger < musicCollection.timestamp){
          this.loggerService.error(this.LOGGER_CLASSNAME, 'checkReloadMusicCollectionNeeded', 'musicCollection ' + musicCollection.id + ' has a newer version (' + musicCollection.timestamp + ') then seen in the updateInfo (' + musicCollection.timestampFromUpdateTrigger + ') -> going to ignore update info timestamp.');
        }else{
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'checkReloadMusicCollectionNeeded', 'musicCollection ' + musicCollection.id + ' is already on the correct version');
        }
      }
    }
  }

  public loadMusicCollection(musicChannelId: number, musicCollection: MusicCollection) {

    this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadMusicCollectionFromMusicChannelDetails', 'loading musicCollection details started for ' + musicCollection.title + '(' + musicCollection.id + ') from musicChnnale ' + musicChannelId);

    if (!musicCollection.detailsLoading) {

      musicCollection.detailsLoading = true;
      musicCollection.detailsLoadingError = null;
      musicCollection.refetchNeededForConflict = false;
      //musicCollection.detailsLoaded = false;
      // erase old data?

      const musicCollectionObservable: Observable<MusicCollection> = this.musicChannelApiService.loadMusicCollection(musicChannelId, musicCollection.id);

      musicCollectionObservable.pipe(
        finalize(() => {
            musicCollection.detailsLoading = false;
        }))
        .subscribe(
        (data) => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadMusicCollection', 'data : ' + data);

            /*
            let dataWithMusicCollection;
            if (data instanceof Array && data.length > 0){
              dataWithMusicCollection = data[0];
            }else if (data){
              dataWithMusicCollection = data;
            }
            */

            // we should construct real objects with the correct type (so we can use instanceof to check the type)
            const realObject = createClassObjectForMusicCollection(data);

            musicCollection.copyDetailsFrom(realObject);

            if (musicCollection instanceof Context && realObject instanceof Context) {
              //by now, the details
              musicCollection.detailsLoaded = true;

              // we will also load the tweakInfo once the changeableParameters are loaded
              this.musicCollectionService.loadTweakInfo(musicCollection);
            } else if (musicCollection instanceof Playlist && realObject instanceof Playlist) {
              musicCollection.detailsLoaded = true;
            }else{
              musicCollection.detailsLoadingError = 'MusicCollection type not recognized';
            }

            musicCollection.detailsloadingDone.next(true);
        },
        (error: unknown) => {
          let errMsg = 'Error';
          if (error instanceof HttpErrorResponse){
            errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';

          }

            console.error(errMsg);
            console.log(musicCollection);
            musicCollection.detailsLoadingError = 'GeneralError';
            musicCollection.detailsloadingDone.next(false);
        });
    }
  }





}
