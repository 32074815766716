import { ChangeableParameter } from './changeableParameter';

export class ChangeableIntervalParameter extends ChangeableParameter{
    minimum: number;
    maximum: number;
    step: number;
    lowerSelection: number;
    upperSelection: number;
    metadataDisplayMinimum: string;
    metadataDisplayMaximum: string;

    constructor(changeableIntervalParameter?: ChangeableIntervalParameter){
        super(changeableIntervalParameter);
        if (changeableIntervalParameter){
            this.minimum = changeableIntervalParameter.minimum;
            this.maximum = changeableIntervalParameter.maximum;
            this.step = changeableIntervalParameter.step;
            this.lowerSelection = changeableIntervalParameter.lowerSelection;
            this.upperSelection = changeableIntervalParameter.upperSelection;
            this.metadataDisplayMinimum = changeableIntervalParameter.metadataDisplayMinimum;
            this.metadataDisplayMaximum = changeableIntervalParameter.metadataDisplayMaximum;
        }
    }
}