import { Injectable } from '@angular/core';
import { LoggerService } from '../loggers/logger.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '@service/config';
import { environment } from 'src/environments/environment';
import { DeviceService } from '@service/device.service';
import { request } from 'http';
import { AppVersionService } from '@service/app-version.service';

export interface DTO_RefreshToken {
  id: number;
  zoneId: number;
  refreshToken: string;
}

export interface DTO_AccessToken {
  accessToken: string;
}

export interface DTO_ZoneInfo {
  name: string;
  location: string;
  appFamilyId: number;
  externalZoneId: string;
}

class DTO_RefreshTokenRequest {
  refreshToken: string;
  appVersion : string;
  device: string;
  deviceInfo: string;
  userAgent: string;
}


@Injectable({
  providedIn: 'root'
})
export class ZoneConnectionApiService {

  private LOGGER_CLASSNAME = 'ZoneConnectionApiService';

  public appVersion = environment.VERSION;

  constructor(
    private httpClient: HttpClient,
    private loggerService: LoggerService,
    private deviceService: DeviceService,
  ) {

  }



  public loadRefreshTokenForZoneCode(zoneCode:string): Observable<DTO_RefreshToken>{
    let resultObservable: Observable<DTO_RefreshToken>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadRefreshTokenForAuthenticatorToken', 'about to mock loadRefreshTokenForAuthenticatorToken request');
      resultObservable = this.httpClient.get<DTO_RefreshToken>("api/refreshToken");
    }else{
      let url = Config.api4Url_refreshTokenForZoneCode(zoneCode);
      const headers = new HttpHeaders({"Authorization": "Basic " + btoa(environment.appId + ":" + environment.api_appPassword)});
      resultObservable = this.httpClient.post<DTO_RefreshToken>(url, null, {headers: headers} );
    }
    return resultObservable;
  }

  public loadRefreshTokenForAuthenticatorToken(zoneId: number, accessToken: string): Observable<DTO_RefreshToken>{
    let resultObservable: Observable<DTO_RefreshToken>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadRefreshTokenForAuthenticatorToken', 'about to mock loadRefreshTokenForAuthenticatorToken request');
      resultObservable = this.httpClient.get<DTO_RefreshToken>("api/refreshToken");
    }else{
      let url = Config.api4Url_refreshToken(zoneId);
      const headers = new HttpHeaders({"Authorization": "Bearer " + accessToken});
      resultObservable = this.httpClient.post<DTO_RefreshToken>(url, null, {headers: headers} );
    }
    return resultObservable;
  }

  public loadAccessToken(zoneId: number, refreshToken: string): Observable<DTO_AccessToken>{
    let resultObservable: Observable<DTO_AccessToken>;
    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadAccessToken', 'about to mock loadAccessToken request');
      resultObservable = this.httpClient.get<DTO_AccessToken>("api/accessToken");
    }else{
      let url = Config.api4Url_accessToken(zoneId);
      const headers = new HttpHeaders({"Authorization": "Basic " + btoa(environment.appId + ":" + environment.api_appPassword)});
      const requestBody = new DTO_RefreshTokenRequest();
      requestBody.refreshToken = refreshToken;
      requestBody.appVersion = this.appVersion;
      requestBody.device = this.deviceService.deviceShortReadableInfo();
      requestBody.deviceInfo = this.deviceService.deviceExtraReadableInfo();
      requestBody.userAgent = this.deviceService.userAgent();


      resultObservable = this.httpClient.post<DTO_AccessToken>(url, requestBody, {headers: headers} );
    }
    return resultObservable;
  }

  public loadZoneInfo(zoneId: number, accessToken: string){
    let resultObservable: Observable<DTO_ZoneInfo>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadZoneInfo', 'about to mock loadZoneInfo request');
      resultObservable = this.httpClient.get<DTO_ZoneInfo>("api/zoneConnectionInfo");
    }else{
      let url = Config.api4Url_zoneConnectionInfo(zoneId);
      const headers = new HttpHeaders({'Authorization': "Bearer " + accessToken});
      //we need to add the accessToken here, since it should be the accessToken of the zoneConnection
      resultObservable = this.httpClient.get<DTO_ZoneInfo>(url, {headers: headers});
    }
    return resultObservable;
  }

}
