import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Calendar } from '@model/calendar';

export interface CopyCalendarConfirmPopupComponentData{
  calendar: Calendar
  confirm: boolean;
}

@Component({
  selector: 'tun-copy-calendar-popup',
  templateUrl: './copy-calendar-popup.component.html',
  styleUrls: ['./copy-calendar-popup.component.scss']
})
export class CopyCalendarPopupComponent {
  public static widthForPopup = "min(25rem,30vw)";

  constructor(
    public dialogRef: MatDialogRef<CopyCalendarPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CopyCalendarConfirmPopupComponentData,
  ) {

  }

  public onYesClick(){
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }

  public onNoClick(){
    this.data.confirm = false;
    this.dialogRef.close(this.data);
  }
}
