<div class="confirmPopup">

  <!-- header -->
  <tun-popup-header
    (close)="onCancelClick()"
    [isInMatDialog]="'true'">
    {{'popup.confirm.title' | translate}}
  </tun-popup-header>

  <form [formGroup]="confirmForm" (ngSubmit)="performConfirm()">

    <div mat-dialog-content class="content-container">
      <div class="text-container">
        <span *ngIf="playList">{{'popup.confirm.removePlaylist.info.beforeTitle' | translate}} {{playList.title}} {{'popup.confirm.removePlaylist.info.afterTitle' | translate}}</span>
        <span *ngIf="calendar">{{'popup.confirm.removeCalendar.info.beforeTitle' | translate}} {{calendar.name}} {{'popup.confirm.removeCalendar.info.afterTitle' | translate}}</span>
         
      </div>
      <!--
      <div *ngIf="calendar" class="text-container">
        Je gaat de agenda {{calendar.name}} definitief verwijderen.
     </div>
     -->
      <div class="text-container">
        {{'popup.confirm.type.info' | translate}}
      </div>
      <div class="input-container">
        <input #confirmInput id="confirmText" name="confirmText" formControlName="confirmText" class="titleLabel edit" (keyup.enter)="onPressEnter()" [class.error]="confirmFieldShowError"/>
        <p class="error-message" *ngIf="showErrors">
          {{'popup.confirm.type.error' | translate}}
        </p>
      </div>

    </div>
  </form>
  
    <div mat-dialog-actions>
      <div class="buttons-container">
          <button (click)="tryToConfirm()">{{'general.remove' | translate}}</button>
          <button (click)="onCancelClick()">{{'general.cancel' | translate}}</button>
      </div>
    </div>
  
</div>

