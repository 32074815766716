import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { PlayerV5RoutingModule } from './player-v5-routing.module';
import { PlayerV5Component } from './player-v5.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MainContentComponent } from './main-content/main-content.component';
import { MainPlayerComponent } from './main-player/main-player.component';
import { HelpMenuComponent } from './main-menu/help-menu/help-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@components/icons/icons.module';
import { TabMenuComponent } from './main-menu/tab-menu/tab-menu.component';
import { HomePanelComponent } from './main-content/home-panel/home-panel.component';
import { CalendarsPanelComponent } from './main-content/calendars-panel/calendars-panel.component';
import { MusicChannelsPanelComponent } from './main-content/music-channels-panel/music-channels-panel.component';
import { PlaylistsPanelComponent } from './main-content/playlists-panel/playlists-panel.component';
import { SearchPanelComponent } from './main-content/search-panel/search-panel.component';
import { SettingsPanelComponent } from './main-content/settings-panel/settings-panel.component';
import { PlayerViewComponent } from './main-player/player-view/player-view.component';
import { IconsV5Module } from '@components/icons-v5/icons-v5.module';
import { QueueViewComponent } from './main-player/queue-view/queue-view.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WaitingQueueHeaderComponent } from './main-player/queue-view/waiting-queue-header/waiting-queue-header.component';
import { RadioQueueHeaderComponent } from './main-player/queue-view/radio-queue-header/radio-queue-header.component';
import { ComponentsV5Module } from '@components/components-v5/components-v5.module';
import { MiniTrackInfoViewComponent } from './main-player/player-view/mini-track-info-view/mini-track-info-view.component';
import { MiniTrackOriginViewComponent } from './main-player/player-view/mini-track-origin-view/mini-track-origin-view.component';
import { LargeControlPanelComponent } from './main-player/player-view/large-control-panel/large-control-panel.component';
import { TrackOriginImageViewComponent } from './main-player/player-view/track-origin-image-view/track-origin-image-view.component';
import { RoundProgressViewComponent } from './main-player/player-view/round-progress-view/round-progress-view.component';
import { TogglePlayButtonComponent } from './main-player/player-view/toggle-play-button/toggle-play-button.component';
import { CircleProgressModule } from '@components/circle-progress/circle-progress.module';
import { ProgressViewComponent } from './main-player/player-view/progress-view/progress-view.component';
import { AppComponentsModule } from '@components/app-components/app-components.module';
import { MusicChannelDetailViewComponent } from './main-content/music-channels-panel/music-channel-detail-view/music-channel-detail-view.component';
import { MusicCollectionViewComponent } from './main-content/music-channels-panel/music-channel-detail-view/music-channel-detail-content-view/music-collection-view/music-collection-view.component';
import { MusicCollectionSorter } from './main-content/music-channels-panel/music-channel-detail-view/music-collection-sorter.pipe';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CalendarGroupsViewComponent } from './main-content/calendars-panel/calendar-groups-view/calendar-groups-view.component';
import { CalendarGroupViewComponent } from './main-content/calendars-panel/calendar-groups-view/calendar-group-view/calendar-group-view.component';
import { CalendarViewComponent } from './main-content/calendars-panel/calendar-groups-view/calendar-group-view/calendar-view/calendar-view.component';
import { CalendarDetailViewComponent } from './main-content/calendars-panel/calendar-detail-view/calendar-detail-view.component';
import { CalendarDayNavigatorComponent } from './main-content/calendars-panel/calendar-detail-view/calendar-day-navigator/calendar-day-navigator.component';
import { CalendarContentViewComponent } from './main-content/calendars-panel/calendar-detail-view/calendar-content-view/calendar-content-view.component';
import { CalendarItemsDayPositionFilter } from './main-content/calendars-panel/calendar-detail-view/calendar-content-view/calendar-items-day-position.pipe';
import { CalendarItemViewComponent } from './main-content/calendars-panel/calendar-detail-view/calendar-content-view/calendar-item-view/calendar-item-view.component';
import { TimelineViewComponent } from './main-content/calendars-panel/calendar-detail-view/calendar-content-view/timeline-view/timeline-view.component';
import { ResizeModule } from '@components/resize/resize.module';
import { PlaylistsViewComponent } from './main-content/playlists-panel/playlists-view/playlists-view.component';
import { PlaylistDetailViewComponent } from './main-content/playlists-panel/playlist-detail-view/playlist-detail-view.component';
import { PlaylistButtonViewComponent } from './main-content/playlists-panel/playlists-view/playlist-button-view/playlist-button-view.component';
import { SearchListsViewComponent } from './main-content/search-panel/search-lists-view/search-lists-view.component';
import { SearchMusicChannelsViewComponent } from './main-content/search-panel/search-music-channels-view/search-music-channels-view.component';
import { SearchMusicChannelViewComponent } from './main-content/search-panel/search-music-channel-view/search-music-channel-view.component';
import { SearchMusicChannelButtonViewComponent } from './main-content/search-panel/search-music-channels-view/search-music-channel-button-view/search-music-channel-button-view.component';
import { SearchMusicCollectionButtonViewComponent } from './main-content/search-panel/search-music-channel-view/search-music-collection-button-view/search-music-collection-button-view.component';
import { SearchMusicCollectionResultViewComponent } from './main-content/search-panel/search-music-collection-result-view/search-music-collection-result-view.component';
import { SearchTextViewComponent } from './main-content/search-panel/search-text-view/search-text-view.component';
import { SearchInputComponent } from './main-content/search-panel/search-text-view/search-text-content-view/search-input/search-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { OverlaysV5Module } from '@components/overlays-v5/overlays-v5.module';
import { SettingsHomePanelComponent } from './main-content/settings-panel/settings-home-panel/settings-home-panel.component';
import { SettingsBanlistPanelComponent } from './main-content/settings-panel/settings-banlist-panel/settings-banlist-panel.component';
import { SettingsLanguagePanelComponent } from './main-content/settings-panel/settings-language-panel/settings-language-panel.component';
import { SettingsMusicRightsPanelComponent } from './main-content/settings-panel/settings-music-rights-panel/settings-music-rights-panel.component';
import { SettingsZoneInfoComponent } from './main-content/settings-panel/settings-home-panel/settings-zone-info/settings-zone-info.component';
import { SettingsBannedTrackComponent } from './main-content/settings-panel/settings-banlist-panel/settings-banned-track/settings-banned-track.component';
import { PopupsV5Module } from '@components/popups-v5/popups-v5.module';
import { SearchTextContentViewComponent } from './main-content/search-panel/search-text-view/search-text-content-view/search-text-content-view.component';
import { PlaylistDetailContentViewComponent } from './main-content/playlists-panel/playlist-detail-view/playlist-detail-content-view/playlist-detail-content-view.component';
import { SearchMusicCollectionResultContentViewComponent } from './main-content/search-panel/search-music-collection-result-view/search-music-collection-result-content-view/search-music-collection-result-content-view.component';
import { MusicChannelDetailContentViewComponent } from './main-content/music-channels-panel/music-channel-detail-view/music-channel-detail-content-view/music-channel-detail-content-view.component';
import { SearchResultViewComponent } from './main-content/search-panel/search-result-view/search-result-view.component';
import { SearchResultContentViewComponent } from './main-content/search-panel/search-result-view/search-result-content-view/search-result-content-view.component';
import { AutocompletionComponent } from './main-content/search-panel/search-text-view/search-text-content-view/autocompletion/autocompletion.component';
import { EditTitleForPlaylistViewComponent } from './main-content/playlists-panel/edit-title-for-playlist-view/edit-title-for-playlist-view.component';
import {MatMenuModule} from '@angular/material/menu';
import { SearchToAddViewComponent } from './main-content/playlists-panel/search-to-add-view/search-to-add-view.component';
import { SearchToAddContentViewComponent } from './main-content/playlists-panel/search-to-add-view/search-to-add-content-view/search-to-add-content-view.component';
import { PopupsModule } from '@components/popups/popups.module';
import { RemoteInfoButtonsViewComponent } from './main-player/player-view/remote-info-buttons-view/remote-info-buttons-view.component';
import { AccessRightOverlayViewComponent } from './main-content/general/access-right-overlay-view/access-right-overlay-view.component';
import { CreateCalendarItemComponent } from './main-content/calendars-panel/calendar-detail-view/calendar-content-view/create-calendar-item/create-calendar-item.component';
import { MusicChannelGroupsViewComponent } from './main-content/music-channels-panel/music-channel-groups-view/music-channel-groups-view.component';
import { EditTitleForCalendarViewComponent } from './main-content/calendars-panel/edit-title-for-calendar-view/edit-title-for-calendar-view.component';
import { SettingsSongRequestPanelComponent } from './main-content/settings-panel/settings-song-request-panel/settings-song-request-panel.component';
import { SettingsDesktopAppPanelComponent } from './main-content/settings-panel/settings-desktop-app-panel/settings-desktop-app-panel.component';
import { HomePanelMainContentViewComponent } from './main-content/home-panel/home-panel-main-content-view/home-panel-main-content-view.component';
import { RecommendationsViewComponent } from './main-content/home-panel/home-panel-main-content-view/recommendations-view/recommendations-view.component';



@NgModule({
  imports: [
    CommonModule,
    PlayerV5RoutingModule,
    TranslateModule,
    IconsModule,
    IconsV5Module,
    ComponentsV5Module,
    ScrollingModule,
    DragDropModule,
    ComponentsV5Module,
    NgOptimizedImage,
    CircleProgressModule.forRoot({
    }),
    AppComponentsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    ResizeModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    OverlaysV5Module,
    PopupsV5Module,
    PopupsModule,
    MatMenuModule
  ],
  declarations: [
    PlayerV5Component,
    MainMenuComponent,
    MainContentComponent,
    MainPlayerComponent,
    HelpMenuComponent,
    TabMenuComponent,
    HomePanelComponent,
    CalendarsPanelComponent,
    MusicChannelsPanelComponent,
    PlaylistsPanelComponent,
    SearchPanelComponent,
    SettingsPanelComponent,
    PlayerViewComponent,
    QueueViewComponent,
    WaitingQueueHeaderComponent,
    RadioQueueHeaderComponent,
    MiniTrackInfoViewComponent,
    MiniTrackOriginViewComponent,
    LargeControlPanelComponent,
    TrackOriginImageViewComponent,
    RoundProgressViewComponent,
    TogglePlayButtonComponent,
    ProgressViewComponent,
    MusicChannelDetailViewComponent,
    MusicCollectionViewComponent,
    MusicCollectionSorter,
    CalendarGroupsViewComponent,
    CalendarGroupViewComponent,
    CalendarViewComponent,
    CalendarDetailViewComponent,
    CalendarDayNavigatorComponent,
    CalendarContentViewComponent,
    CalendarItemsDayPositionFilter,
    CalendarItemViewComponent,
    TimelineViewComponent,
    PlaylistsViewComponent,
    PlaylistDetailViewComponent,
    PlaylistButtonViewComponent,
    SearchListsViewComponent,
    SearchMusicChannelsViewComponent,
    SearchMusicChannelViewComponent,
    SearchMusicChannelButtonViewComponent,
    SearchMusicCollectionButtonViewComponent,
    SearchMusicCollectionResultViewComponent,
    SearchTextViewComponent,
    SearchInputComponent,
    SettingsHomePanelComponent,
    SettingsBanlistPanelComponent,
    SettingsLanguagePanelComponent,
    SettingsMusicRightsPanelComponent,
    SettingsZoneInfoComponent,
    SettingsBannedTrackComponent,
    SearchTextContentViewComponent,
    PlaylistDetailContentViewComponent,
    SearchMusicCollectionResultContentViewComponent,
    MusicChannelDetailContentViewComponent,
    SearchResultViewComponent,
    SearchResultContentViewComponent,
    AutocompletionComponent,
    EditTitleForPlaylistViewComponent,
    SearchToAddViewComponent,
    SearchToAddContentViewComponent,
    RemoteInfoButtonsViewComponent,
    AccessRightOverlayViewComponent,
    CreateCalendarItemComponent,
    MusicChannelGroupsViewComponent,
    EditTitleForCalendarViewComponent,
    SettingsSongRequestPanelComponent,
    SettingsDesktopAppPanelComponent,
    HomePanelMainContentViewComponent,
    RecommendationsViewComponent
  ]
})
export class PlayerV5Module { }
