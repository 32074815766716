import { MusicCollection } from './musicCollection';
import { ChangeableParameter } from './changeableParameter';
import { createClassObjectForChangeableParameter } from '@service/util/changeableParameterUtil';
import { TweakInfo, TweakInfoHolder } from './tweakInfo';
import { BehaviorSubject } from 'rxjs';

export class Context extends MusicCollection implements TweakInfoHolder {
    changeableParameter: ChangeableParameter[];
    defaultChangeableParameter: ChangeableParameter[];

    // marker to indicate if the changeableParameters are changed and need to be saved to the server
    parametersChanged = false;

    constructor(context?: Context){
        super(context);

        if (context){
            if (context.changeableParameter){
                this.changeableParameter = [];
                context.changeableParameter.map(changeableParam => this.changeableParameter.push(createClassObjectForChangeableParameter(changeableParam)));
            }
            if (context.defaultChangeableParameter){
                this.defaultChangeableParameter = [];
                context.defaultChangeableParameter.map(changeableParam => this.defaultChangeableParameter.push(createClassObjectForChangeableParameter(changeableParam)));
            }
            if (context.tweakInfo){
                this.tweakInfo = new TweakInfo(context.tweakInfo);
            }
            this.tweakInfoLoaded = context.tweakInfoLoaded;
            //we don't copy the tweakInfoLoading and tweakInfoLoadingError properties, loading tweakInfo is at object level.
            //If you take a copy of a context while the tweakInfo is loading, the details won't be available into the new copy once the http call completes.
        }
    }

    get contextId():number{
        return this.id;
    }
    get changeableParameters(): ChangeableParameter[]{
        return this.changeableParameter;
    }

    tweakInfo: TweakInfo;

    private _tweakInfoLoadingSubject = new BehaviorSubject<boolean>(false);
    tweakInfoLoading$ = this._tweakInfoLoadingSubject.asObservable();
    set tweakInfoLoading(value: boolean){
        this._tweakInfoLoadingSubject.next(value);
    }
    get tweakInfoLoading():boolean{
        return this._tweakInfoLoadingSubject.value;
    }

    tweakInfoLoaded: boolean = false;
    tweakInfoLoadingError: string;

    public resetParameters(){
        if (this.defaultChangeableParameter){
            const copyOfDefaults: ChangeableParameter[] = [];
            this.defaultChangeableParameter.forEach(param => {
                copyOfDefaults.push(createClassObjectForChangeableParameter(param));
            });
            this.changeableParameter = copyOfDefaults;
            this.parametersChanged = true;
        }
    }

    public copyDetailsFrom(musicCollection: MusicCollection){
      if (musicCollection != null && musicCollection.id == this.id && musicCollection instanceof Context){
        this.changeableParameter = musicCollection.changeableParameter;
        this.defaultChangeableParameter = musicCollection.defaultChangeableParameter;

        //local changes are thrown away (if any where still around)
        this.parametersChanged = false;
      }
      super.copyDetailsFrom(musicCollection);
    }




}
