import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AutocompletionObject, AutocompletionResult, AutocompletionService, AutocompletionType } from '@service/autocompletion.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-autocompletion',
  templateUrl: './autocompletion.component.html',
  styleUrls: ['./autocompletion.component.scss']
})
export class AutocompletionComponent implements OnDestroy{


  public get showAutocomplete(): boolean{
    return this._autocompleteValue != null && this._autocompleteValue != "";
  }

  _autocompleteValue: string;
  @Input()
  set autocompleteValue(value: string) {
    this._autocompleteValue = value;

    this.autocompletionService
      .autoComplete(value)
      .pipe(
        takeUntil(this._destroyed$)
      )
      .subscribe(
        (result) => {
          this.autoCompleteResult= result
        }
      )
  }

  @Output() selectAutocomplete = new EventEmitter<AutocompletionObject>();

  public AutocompletionType = AutocompletionType
  public autoCompleteResult: AutocompletionResult


  constructor(
    private autocompletionService: AutocompletionService
  ){

  }

  private _destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
      this._destroyed$.next();
      this._destroyed$.complete();
  }

  selectAutocompletion(autocompletionObject: AutocompletionObject){
    this.selectAutocomplete.emit(autocompletionObject);
  }

}
