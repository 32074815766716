import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tun-back-arrow-icon',
  templateUrl: './back-arrow-icon.component.html',
  styleUrls: ['./back-arrow-icon.component.scss']
})
export class BackArrowIconComponent implements OnInit {

  constructor() { }


  
  ngOnInit() {
  }

}
