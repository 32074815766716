import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ResizedEvent } from '@components/resize/resize.directive';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-round-progress-view',
  templateUrl: './round-progress-view.component.html',
  styleUrls: ['./round-progress-view.component.scss']
})
export class RoundProgressViewComponent implements OnInit, AfterViewInit, OnDestroy{

  @ViewChild('progressWrapper', {static: true}) progressWrapper:any;

  public strokeWidth = 4;
  public radius = 22;

  public get gradientColor1$(){
    return this.playingMusicSelectionService.lastRealplayingMusicSelection$
      .pipe(
      map((playingMusicSelection) => {
        if (playingMusicSelection.origin == PlayingOrigin.musicChannel){
          return "#75ACFF"
        }else if (playingMusicSelection.origin == PlayingOrigin.calendar){
          return "#49CE8F"
        }
        return "#FEA05D"
      })
    )
  }

  public get gradientColor2$(){
    return this.playingMusicSelectionService.lastRealplayingMusicSelection$
      .pipe(
      map((playingMusicSelection) => {
        if (playingMusicSelection.origin == PlayingOrigin.musicChannel){
          return "#96D4F8"
        }else if (playingMusicSelection.origin == PlayingOrigin.calendar){
          return "#22B580"
        }
        return "#F6836A"
      })
    )
  }

  public get progressPercentage$(){
    return combineLatest([
      this.playingItemService.currentTimeInSeconds$,
      this.playingItemService.totalTimeInSeconds$,
    ])
    .pipe(
      map(([currentTime, totalTime]) => {
        if (totalTime > 0){
          const percentage = (currentTime / totalTime) * 100;
          //console.debug("percentage: " + percentage);
          return Math.abs(percentage)
        }
        return 0
      })
    )
  }

  constructor(
    private playingItemService: PlayingItemService,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private playingMusicSelectionService: PlayingMusicSelectionService
    ) {

    }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  ngOnInit(): void {
    this.changeDetectorRef.detach();

    this.progressPercentage$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.changeDetectorRef.detectChanges();
        }
      )

    this.progressSizeCalculation();
  }

  ngAfterViewInit(){
    this.progressSizeCalculation();
  }

  public onResize(event:ResizedEvent){
    this.progressSizeCalculation();
  }

  private progressSizeCalculation() {
    const playerViewRect = this.progressWrapper.nativeElement.getBoundingClientRect();

    if (playerViewRect.height > 0){
      this.strokeWidth = playerViewRect.height / 15
      this.radius = ((playerViewRect.height - 4) / 2) - this.strokeWidth
      //console.log("height: " + playerViewRect.height)
      this.changeDetectorRef.markForCheck()
    }

    //this.loggerService.debug(this.LOGGER_CLASSNAME, "playerSizeCalculation", "width: " + playerViewRect.width + " - height: " + playerViewRect.height);
  }



}
