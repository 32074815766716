export const subscriptionInfo = {
    "daysFilledIn": true,
    "remainingDays": 3026,
    "minutesFilledIn": false,
    "remainingMinutes": 4357965,
    "extendedPeriodEndsAt": 9,
    "customerStatus": "customer",
    "subscriptionType": "orange",
    "royaltyFree": false,
    "musicPerformanceRightsId": 1,
    "inExtendedActivationPeriod": false
}

