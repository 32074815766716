import { Injectable, OnDestroy } from '@angular/core';
import { AppVersionService } from '@service/app-version.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { AppV5StateService, TunifyV5Tab } from './app-v5-state.service';
import { Calendar } from '@model/calendar';
import { BehaviorSubject, Subject, combineLatest, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarItem } from '@model/calendarItem';
import { Day } from '@model/day';
import { LoggerService } from '@service/loggers/logger.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveCalendarItemsService implements OnDestroy {

  private LOGGER_CLASSNAME = ActiveCalendarItemsService.name;

  //current calendarItems
  private get _currentCalendarItems(): CalendarItem[] {
    return this._currentCalendarItemsSubject.value;
  }
  private set _currentCalendarItems(value: CalendarItem[]) {
    if (this._currentCalendarItems !== value) {
      this._currentCalendarItemsSubject.next(value);
    }
  }
  public get currentCalendarItems(): CalendarItem[] {
    return this._currentCalendarItems;
  }
  private _currentCalendarItemsSubject = new BehaviorSubject<CalendarItem[]>([]);
  public currentCalendarItems$ = this._currentCalendarItemsSubject.asObservable();


  constructor(
    private zoneConfigurationService: ZoneConfigurationService,
    private appV5StateService: AppV5StateService,
    private loggerService: LoggerService
  ) {
    combineLatest([
      this.appV5StateService.selectedTab$,
      this.appV5StateService.showDetailsForCalendar$
    ])
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      ([selectedTab, showCalendar]) => {
        if (selectedTab == TunifyV5Tab.CALENDAR){
          this.watchingCalendar = showCalendar
        }else{
          this.watchingCalendar = null;
        }
      }
    );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private _watchingCalendar: Calendar;
  private watchingCalendarItemChanged = new Subject<void>();
  private set watchingCalendar(value: Calendar){
    this._watchingCalendar = value;
    if (this._watchingCalendar){
      this.forceReload = true;
      this.adjustTime();

      this._watchingCalendar.calendarItemsLoadingDone$
      .pipe(
        takeUntil(
          merge(
            this.watchingCalendarItemChanged,
            this.destroyed$
          )
        )
      )
      .subscribe(
        () => {
          this.forceReload = true;
          this.adjustTime();
        }
      )
    }
  }
  private get watchingCalendar(){
    return this._watchingCalendar;
  }

  private delayedAdjustTimeCallback = null;
  private delayedAdjustTime(periodInSeconds: number) {
    this.clearDelayedAdjustTime();
    if (this.delayedAdjustTimeCallback == null) {
      this.delayedAdjustTimeCallback = setTimeout(
        this.adjustTime
      , periodInSeconds * 1000);
    }
  }

  private clearDelayedAdjustTime() {
    if (this.delayedAdjustTimeCallback) {
      clearTimeout(this.delayedAdjustTimeCallback);
      this.delayedAdjustTimeCallback = null;
    }
  }




  private dateTimeOfPreviousFetch: Date;
  private forceReload = false;

  private activeDay: Day;
  private currentDatetime: Date;

  private adjustTime = () => {
    this.loggerService.debug(this.LOGGER_CLASSNAME, "adjustTime", "into adjustTime");
    this.clearDelayedAdjustTime();

    this.currentDatetime = this.zoneConfigurationService.currentDateTimeForZone;
    const currentDayIndex = this.currentDatetime.getDay() == 0 ? 6 : this.currentDatetime.getDay() - 1;
    this.activeDay = Day.DAYS[currentDayIndex];

    const seconds = this.currentDatetime.getSeconds();

    if (this.forceReload || this.shouldFetchAgain(this.currentDatetime, this.dateTimeOfPreviousFetch)){
      this.fetchCurrentCalendarItems();
    }

    this.delayedAdjustTime(60.1 - seconds)
  }

  private shouldFetchAgain(currentDateTime: Date, previousDateTime: Date){
    if (currentDateTime != null && previousDateTime != null) {
      const currentHour = currentDateTime.getHours();
      const currentMinutes = currentDateTime.getMinutes();
      const previousHour = previousDateTime.getHours();
      const previousMinutes = previousDateTime.getMinutes();
      return currentHour != previousHour || (Math.floor(currentMinutes / 30.0) != Math.floor(previousMinutes / 30.0))
    }
    return true
  }

  private fetchingCurrentCalendarItems = false
  private fetchCurrentCalendarItems(){
    this.loggerService.debug(this.LOGGER_CLASSNAME, "fetchCurrentCalendarItems", "into fetchCurrentCalendarItems");
    this.fetchingCurrentCalendarItems = true;
    this.forceReload = false;

    const newCurrentCalendarItems : CalendarItem[] = [];
    if (this.activeDay && this.currentDatetime && this.watchingCalendar && this.watchingCalendar.calendarItems){
      const currentHours = this.currentDatetime.getHours();
      const currentMinutes = this.currentDatetime.getMinutes();

      const currentTime = currentHours + currentMinutes / 60;
      this.watchingCalendar.calendarItems.forEach(calendarItem => {
        const calendarItemStartTime = calendarItem.startHour + calendarItem.startMinutes / 60
        if (calendarItem.day == this.activeDay && currentTime >= calendarItemStartTime && currentTime < calendarItemStartTime + calendarItem.duration / 60){
          newCurrentCalendarItems.push(calendarItem);
        }
      })
    }

    this._currentCalendarItems = newCurrentCalendarItems;
    this.fetchingCurrentCalendarItems = false;
  }

}
