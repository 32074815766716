import { Component } from '@angular/core';

@Component({
  selector: 'tun-angle-up',
  templateUrl: './angle-up.component.html',
  styleUrls: ['./angle-up.component.scss']
})
export class AngleUpComponent {

}
