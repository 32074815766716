import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Playlist } from '@model/playlist';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { SearchTextV5Service } from '@service/app-v5/search-text-v5.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { carouselAnimation, fadeAnimation, slideAnimation, viewPositionAnimation } from '@util/animations';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export enum PLAYLIST_VIEW {
  PLAYLIST_VIEW_MAIN = "playlistViewMain",
  PLAYLIST_VIEW_CREATE = "playlistViewCreate",
  PLAYLIST_VIEW_EDIT_TITLE = "playlistViewEditTitle",
  PLAYLIST_VIEW_DETAIL = "playlistViewDetail",
  PLAYLIST_VIEW_SEARCH_TO_ADD = "playlistViewSearchToAdd"
}

export const PlaylistViewLevel = new Map<PLAYLIST_VIEW, number>([
  [PLAYLIST_VIEW.PLAYLIST_VIEW_MAIN, 0],
  [PLAYLIST_VIEW.PLAYLIST_VIEW_CREATE, 1],
  [PLAYLIST_VIEW.PLAYLIST_VIEW_DETAIL, 2],
  [PLAYLIST_VIEW.PLAYLIST_VIEW_EDIT_TITLE, 3],
  [PLAYLIST_VIEW.PLAYLIST_VIEW_SEARCH_TO_ADD, 3],
]);

@Component({
  selector: 'tun-playlists-panel',
  templateUrl: './playlists-panel.component.html',
  styleUrls: ['./playlists-panel.component.scss'],
  animations: [
    carouselAnimation,
    viewPositionAnimation,
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, { params: { duration: 10 } })
      ])
    ])
  ]
})
export class PlaylistsPanelComponent implements OnDestroy {

  public CAROUSEL_DURATION = 300;
  public animationDirection: 'next' | 'prev' = 'next';

  public get accessToPlaylists$(){
    return  this.subscriptionsService.accessRights$
      .pipe(
        map(
          accessRights => {
            return accessRights && accessRights.customPlaylists
          }
        )
      )
  }

  public get mainViewPosition$(){
    return this.playlistView$
    .pipe(
      map(
        (playlistView => {
          if (playlistView == PLAYLIST_VIEW.PLAYLIST_VIEW_MAIN){
            return 'mid';
          }
          return 'left';
        })
      )
    )
  }

  public PLAYLIST_VIEW = PLAYLIST_VIEW;
  private _playlistView$ = new BehaviorSubject<PLAYLIST_VIEW>(PLAYLIST_VIEW.PLAYLIST_VIEW_MAIN);
  public set playlistView(view: PLAYLIST_VIEW){
    if (this._playlistView$.value != view){
      if (PlaylistViewLevel.get(this._playlistView$.value) < PlaylistViewLevel.get(view)){
        this.animationDirection = 'next';
      }else{
        this.animationDirection = 'prev';
      }
      this.changeDetectorRef.detectChanges();
      this._playlistView$.next(view)
    }
  }
  public get playlistView$(){
    return this._playlistView$;
  }

  public get showDetailsForPlaylist$() {
    return this.appV5StateService.showDetailsForPlaylist$;
  }

  public get editTitleForPlaylist$(){
    return this.appV5StateService.showChangeNameForPlaylist$
  }

  public get addSongsToPlaylist$(){
    return this.appV5StateService.showSearchForPlaylist$;
  }

  constructor(
    private appV5StateService: AppV5StateService,
    private changeDetectorRef: ChangeDetectorRef,
    private searchTextV5Service: SearchTextV5Service,
    private subscriptionsService: SubscriptionsService
  ) {

  }


  ngOnInit(): void {

    combineLatest([
      this.appV5StateService.showDetailsForPlaylist$,
      this.appV5StateService.showCreatePlaylist$,
      this.appV5StateService.showChangeNameForPlaylist$,
      this.appV5StateService.showSearchForPlaylist$
    ])
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ([showDetailsForPlaylist, showCreatePlaylist, showChangeNameForPlaylist, showSearchForPlaylist]) => {
        if (showChangeNameForPlaylist){
          this.playlistView = PLAYLIST_VIEW.PLAYLIST_VIEW_EDIT_TITLE
        }else if (showSearchForPlaylist){
          this.playlistView = PLAYLIST_VIEW.PLAYLIST_VIEW_SEARCH_TO_ADD
        }else if (showDetailsForPlaylist){
          this.playlistView = PLAYLIST_VIEW.PLAYLIST_VIEW_DETAIL
        }else if (showCreatePlaylist){
          this.playlistView = PLAYLIST_VIEW.PLAYLIST_VIEW_CREATE
        }else{
          this.playlistView = PLAYLIST_VIEW.PLAYLIST_VIEW_MAIN
        }
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onCreatePlaylist(){
    this.appV5StateService.showCreatePlaylist = true;
  }

  onAddSongs(playlist: Playlist){
    this.searchTextV5Service.clearLastSearch();
    this.appV5StateService.showSearchForPlaylist = playlist;
  }

  onChangeName(playlist: Playlist){
    this.appV5StateService.showChangeNameForPlaylist = playlist;
  }

  onBackFromEditTitleForPlaylist(){
    this.appV5StateService.showCreatePlaylist = false;
    this.appV5StateService.showChangeNameForPlaylist = null;
  }

  onCloseSearch(){
    this.appV5StateService.showSearchForPlaylist = null;
  }

  public textViewIsAnimating = false;
  public textViewAnimationStart(event: AnimationEvent){
    this.textViewIsAnimating = true;
  }
  public textViewAnimationDone(event: AnimationEvent){
    this.textViewIsAnimating = false;
  }


}
