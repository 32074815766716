export const banlist = {
    "@type": "Playlist",
    "id": 10011,
    "title": "Geblokkeerde nummers",
    "description": "",
    "lastChanged": null,
    "audioFiles": [
        {
            "@type": "Song",
            "id": 413208,
            "duration": 202373,
            "group": "Satin Jackets ft Niya Wells",
            "location": "2-8/0004132089e027a.mp3",
            "title": "Lost In Japan",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 105,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 201261,
            "fadeBeginPosition": 198373,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 405333,
            "duration": 193762,
            "group": "Dean Lewis",
            "location": "2-33/0004053337511e1.mp3",
            "title": "Be Alright",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 63,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 881,
            "endAudioSignal": 192317,
            "fadeBeginPosition": 190650,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412699,
            "duration": 178256,
            "group": "Rita Ora",
            "location": "2-99/0004126994a3d10.mp3",
            "title": "Only Want You",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 82,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 176107,
            "fadeBeginPosition": 163107,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        }
    ],
    "visible": true,
    "editable": true
}