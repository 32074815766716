import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { filter, take, switchMap } from 'rxjs/operators';
import { ZoneConnectionsService } from '../../authentication/zone-connections.service';
import { BYPASS_ACCESSTOKEN } from './authentication-interceptor';

/**
 * This interceptor will check if we are refreshing our access token.
 * The request is delayed until the refresh finishes and will then continue
 */

@Injectable()
export class RefreshingAccessTokenInterceptor implements HttpInterceptor {

  constructor(private zoneConnectionsService: ZoneConnectionsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

     //do not handle request where we bypass the accessToken mechanism
     if (req.context.get(BYPASS_ACCESSTOKEN) === true)
     return next.handle(req);


    //we don't want to add authorizontion if it is already present (authentication request, refresh access token request) or for our old webservices
    if (
      !req.headers.has('Authorization')
      && !req.url.includes("blackbox2/services/")
      && !req.url.includes("assets")
    ) {

      if (
        this.zoneConnectionsService.activeZoneConnection == null
      ){
        throw new HttpErrorResponse({
          error: 'RefreshingAccessTokenInterceptor - No active zoneConnection',
          headers: req.headers,
          status: 501,
          statusText: 'Warning',
          url: req.url
        });
      }


      //wait until we are no longer loading the access token
      return this.zoneConnectionsService.activeZoneConnection.accessToken$
      .pipe(
        filter(accessToken => accessToken != null),
        take(1),
        switchMap(() => next.handle(req))
      );
      /*
      loadingAccessToken$.pipe(
        filter(result => result == false),
        take(1),
        switchMap(() => next.handle(req))
      );
      */
    }

    return next.handle(req);
  }
}
