import { LOGLEVEL, logLevelToString } from './logTarget';
import { BaseLogTarget } from './baseLogTarget';
import { HttpErrorResponse } from '@angular/common/http';
import { DTO_ClientActivity, LoggingApiService } from '../api/logging-api.service';
import { LoggerService } from './logger.service';
import * as moment from 'moment';



export class Api4LogTarget extends BaseLogTarget{

  private LOGGER_CLASSNAME = 'Api4LogTarget';

  //logging from these classes will be sent for every user, regardless of their clientLoggingLevel setting

  protected enableAllLoggingForClassnames = [
    //"BufferService",
    "AudioTagService",
    "AudioFileWithPlayInfo",
    "MusicPlayerService",
    //"PlayStateService",
    "ZoneConnectionsService",
    "RemoteActionsService",
    "RemoteService",
    "view.AppComponent",
    //"DataUpdateService",
    "PlayTokenService",
    "RealUserMonitorService" //needed for Real User Monitor data analysis
  ];


  public guid: string;
  public lastSeenZoneId: string;
  public lastSeenPlayToken: string;

  private MAX_BUFFER_LENGTH = 50;
  //private MAX_BUFFER_MILLISECONDS = 10000;
  private MAX_BUFFER_MILLISECONDS = 240000; //every 4 min

  constructor(
    private loggingApiService: LoggingApiService,
    private loggerService: LoggerService
  ){
    super();
  }

  private logBuffer : DTO_ClientActivity[]= [];
  protected performLogging(logLevel: LOGLEVEL, message: string){
    const clientActivity = new DTO_ClientActivity();
    clientActivity.guid = this.guid;
    clientActivity.zoneId = this.lastSeenZoneId;
    clientActivity.playerToken = this.lastSeenPlayToken;
    clientActivity.date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    clientActivity.level = logLevelToString(logLevel);
    clientActivity.message = message;

    this.logBuffer.push(clientActivity);

    if (this.logBuffer.length >= this.MAX_BUFFER_LENGTH) {
      this.sendLogBuffer();
    } else {
      this.delayedSendLogBufferIfNeeded();
    }
  }

  public flushBuffer(){
    this.sendLogBuffer();
  }

  private delayedSendLogBufferCallback = null;
  private delayedSendLogBufferIfNeeded() {
    if (this.delayedSendLogBufferCallback == null) {
      this.delayedSendLogBufferCallback = setTimeout(() => {
        this.delayedSendLogBufferCallback = null;
        this.sendLogBuffer();
      }, this.MAX_BUFFER_MILLISECONDS);
    }
  }

  private clearDelayedSendLogBufferCallback() {
    if (this.delayedSendLogBufferCallback) {
      clearTimeout(this.delayedSendLogBufferCallback);
      this.delayedSendLogBufferCallback = null;
    }
  }

  private errorsInARow = 0;
  private sendLogBuffer() {
    this.clearDelayedSendLogBufferCallback();

    if (this.logBuffer && this.logBuffer.length > 0) {
      const logsToSend = this.logBuffer;
      this.logBuffer = [];

      const logObservable = this.loggingApiService.sendClientActivityLogs(logsToSend);

      if (logObservable){
        logObservable
        .subscribe(
          () => {
            //log send ok
            this.errorsInARow = 0;
          },
          (error: unknown) => {
            this.errorsInARow += 1;
              if (error instanceof HttpErrorResponse){
                const status = error.status ? error.status : 500;
                const errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                this.loggerService.error(this.LOGGER_CLASSNAME, "sendLogBuffer", "Failed " + this.errorsInARow + " times to send buffer ( " + logsToSend.length + " logs): " + errMsg);
              }else{
                this.loggerService.error(this.LOGGER_CLASSNAME, "sendLogBuffer", "Failed " + this.errorsInARow + " times to send buffer ( " + logsToSend.length + " logs), unknown error type");
              }
          }
        )
      }
    }
  }

}
