import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { carouselAnimation, fadeAnimation, slideAnimation, viewPositionAnimation } from '@util/animations';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export enum CALENDAR_VIEW {
  CALENDAR_VIEW_MAIN = "calendaarViewMain",
  CALENDAR_VIEW_CREATE = "calendarViewCreate",
  CALENDAR_VIEW_DETAIL = "calendarViewDetail",
  CALENDAR_VIEW_EDIT_TITLE = "calendarViewEditTitle",
}

export const CalendarViewLevel = new Map<CALENDAR_VIEW, number>([
  [CALENDAR_VIEW.CALENDAR_VIEW_MAIN, 0],
  [CALENDAR_VIEW.CALENDAR_VIEW_CREATE, 1],
  [CALENDAR_VIEW.CALENDAR_VIEW_DETAIL, 2],
  [CALENDAR_VIEW.CALENDAR_VIEW_EDIT_TITLE, 3],
]);

@Component({
  selector: 'tun-calendars-panel',
  templateUrl: './calendars-panel.component.html',
  styleUrls: ['./calendars-panel.component.scss'],
  animations: [
    carouselAnimation,
    viewPositionAnimation,
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(fadeAnimation, { params: { duration: 10 } })
      ])
    ])
  ]
})
export class CalendarsPanelComponent implements OnInit, OnDestroy {

  public CAROUSEL_DURATION = 300;
  public animationDirection: 'next' | 'prev' = 'next';

  public get mainViewPosition$(){
    return this.appV5StateService.showDetailsForCalendar$
    .pipe(
      map(
        calendar => {
          if (calendar){
            return 'left';
          }
          return 'mid';
        }
      )
    )
  }

  public CALENDAR_VIEW = CALENDAR_VIEW;
  private _calendarView$ = new BehaviorSubject<CALENDAR_VIEW>(CALENDAR_VIEW.CALENDAR_VIEW_MAIN);
  public set calendarView(view: CALENDAR_VIEW){
    if (this._calendarView$.value != view){
      if (CalendarViewLevel.get(this._calendarView$.value) < CalendarViewLevel.get(view)){
        this.animationDirection = 'next';
      }else{
        this.animationDirection = 'prev';
      }
      this.changeDetectorRef.detectChanges();
      this._calendarView$.next(view)
    }
  }
  public get calendarView$(){
    return this._calendarView$;
  }

  public get editTitleForCalendar$(){
    return this.appV5StateService.showChangeNameForCalendar$;
  }

  public get showDetailsForCalendar$() {
    return this.appV5StateService.showDetailsForCalendar$;
  }

  constructor(
    private appV5StateService: AppV5StateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {

    combineLatest([
      this.appV5StateService.showDetailsForCalendar$,
      this.appV5StateService.showCreateCalendar$,
      this.appV5StateService.showChangeNameForCalendar$
    ])
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ([showDetailsForCalendar, showCreateCalendar, showChangeNameForCalendar]) => {
        if (showChangeNameForCalendar){
          this.calendarView = CALENDAR_VIEW.CALENDAR_VIEW_EDIT_TITLE
        }else if (showDetailsForCalendar){
          this.calendarView = CALENDAR_VIEW.CALENDAR_VIEW_DETAIL
        }else if (showCreateCalendar){
          this.calendarView = CALENDAR_VIEW.CALENDAR_VIEW_CREATE
        }else{
          this.calendarView = CALENDAR_VIEW.CALENDAR_VIEW_MAIN
        }
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onBackFromEditTitleForCalendar(){
    this.appV5StateService.showCreateCalendar = false;
    this.appV5StateService.showChangeNameForCalendar = null;
  }


  public textViewIsAnimating = false;
  public textViewAnimationStart(event: AnimationEvent){
    this.textViewIsAnimating = true;
  }
  public textViewAnimationDone(event: AnimationEvent){
    this.textViewIsAnimating = false;
  }

}
