import { Component, Input } from '@angular/core';
import { Playlist } from '@model/playlist';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';

@Component({
  selector: 'tun-playlist-button-view',
  templateUrl: './playlist-button-view.component.html',
  styleUrls: ['./playlist-button-view.component.scss']
})
export class PlaylistButtonViewComponent {

  @Input() playlist: Playlist;
  @Input() isFavorite: boolean;

  constructor(
    private appV5StateService:AppV5StateService
  ){

  }

  public onShowDetails(){
    this.appV5StateService.showPlaylistInTunifyOrange(this.playlist);
  }

}
