import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '@service/authentication.service';
import { LoggerService } from '@service/loggers/logger.service';
import { environment } from 'src/environments/environment';
import { DTO_MusicCollection } from './dto/music-collection';
import { Observable } from 'rxjs';
import { Config } from '@service/config';
import { MusicChannelGroup } from '@model/musicChannelGroup';
import { DTO_Context } from './dto/context';
import { MusicChannelGroupType } from '@model/enums/musicChannelGroupType';
import { musicSelection } from '../data/mock/zoneConfiguration';
import { VersionedResourceResponse } from './dto/versionedResourceResponse';
import { MusicCollection } from '../../model/musicCollection';
import { InMemoryDataService } from '../data/in-memory-data.service';
import { Context } from '@angular/compiler-cli/src/ngtsc/translator';

export class DTO_MusicChannel {
  id: number;
  name: string;
  musicChannelGroupId: number;
  musicCollections: DTO_MusicCollection[];
}

export class DTO_LoadMusicChannelGroupsResponse {
  timestamp: number;
  musicChannelGroups: MusicChannelGroup[];
}

export class DTO_MusicChannelGroup {
  id: number;
  name: string;

  musicChannels: DTO_MusicChannel[];
}


@Injectable({
  providedIn: 'root'
})
export class MusicChannelApiService {

  private LOGGER_CLASSNAME = 'MusicChannelApiService';

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService) { }

  public loadMusicChannelGroups(type: MusicChannelGroupType): Observable<DTO_LoadMusicChannelGroupsResponse>{
    let resultObservable: Observable<DTO_LoadMusicChannelGroupsResponse>;

    if (environment.mockBackend){
      this.loggerService.info(this.LOGGER_CLASSNAME, 'loadMusicChannelGroups', 'about to mock loadMusicChannelGroups request');
      resultObservable = this.httpClient.get<DTO_LoadMusicChannelGroupsResponse>("api/" + type + "_musicChannelGroup");
    }else{
      const params = new HttpParams()
        .set("type", type);

      const url = Config.api4Url_musicChannelGroups(this.authenticationService.zoneId);
      resultObservable = this.httpClient.get<DTO_LoadMusicChannelGroupsResponse>(url, {params: params});
    }

    return resultObservable;
  }

  public loadMusicCollection(musicChannelId: number, musicCollectionId: number): Observable<MusicCollection>{
    let resultObservable: Observable<MusicCollection>;

    if (environment.mockBackend){
        this.loggerService.error(this.LOGGER_CLASSNAME, "loadMusicCollection", "load musicCollection for musicChannel not implemented");
    }else{
      const url = Config.api4Url_musicChannel_musicCollection(this.authenticationService.zoneId, musicChannelId, musicCollectionId);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "loadMusicCollection", "going to load musicSelection from " + url);

      resultObservable = this.httpClient.get<MusicCollection>(url);
    }
    return resultObservable;
  }

  public saveMusicCollection(musicChannelId: number, musicCollection: DTO_MusicCollection): Observable<VersionedResourceResponse>{

    let saveObservable: Observable<VersionedResourceResponse>;

    if (environment.mockBackend){
      this.loggerService.debug(this.LOGGER_CLASSNAME, "saveMusicCollection", "backend is mocked -> not going to save anything");
    }else{
      const url = Config.api4Url_musicChannel_musicCollection(this.authenticationService.zoneId, musicChannelId, musicCollection.id);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "saveMusicCollection", "going to save musicSelection to " + url);

      const objectToSave = new Object();
      if (musicCollection.selected != null){
        objectToSave["selected"] = musicCollection.selected;
      }
      if (musicCollection instanceof DTO_Context && musicCollection.changeableParameter){
        objectToSave["changeableParameters"] = musicCollection.changeableParameter;
      }
      if (musicSelection.timestamp != null){
        objectToSave["timestamp"] = musicCollection.timestamp;
      }

      saveObservable = this.httpClient.patch<VersionedResourceResponse>(
        url,
        objectToSave
      );
    }

    return saveObservable;
  }

}
