<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">

  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <circle stroke="currentColor" cx="7.5" cy="6" r="4"></circle>
    <rect stroke="currentColor" fill="currentColor" x="0.5" y="5.5" width="3" height="1" rx="0.5"></rect>
    <rect stroke="currentColor" fill="currentColor" x="11.5" y="5.5" width="12" height="1" rx="0.5"></rect>
    <circle stroke="currentColor" cx="16.5" cy="19" r="4"></circle>
    <rect stroke="currentColor" fill="currentColor" x="20.5" y="18.5" width="3" height="1" rx="0.5"></rect>
    <rect stroke="currentColor" fill="currentColor" x="0.5" y="18.5" width="12" height="1" rx="0.5"></rect>
  </g>
</svg>
