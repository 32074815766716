/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InvalidAccessTokenInterceptor } from './invalid-access-token-interceptor';
import { AuthenticationInterceptor } from './authentication-interceptor';
import { RefreshingAccessTokenInterceptor } from './refresing-access-token-interceptor';
import { RetryInterceptor } from './retryInterceptor';
import { UpdateInfoInterceptor } from './update-info-interceptor';
import { ForceLanguageInterceptor } from './force-language-interceptor';


/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass:  UpdateInfoInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
  
  { provide: HTTP_INTERCEPTORS, useClass: RefreshingAccessTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: InvalidAccessTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ForceLanguageInterceptor, multi: true },
  
];