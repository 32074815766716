import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, DoCheck, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResizedEvent } from '@components/resize/resize.directive';
import { TranslateService } from '@ngx-translate/core';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { TrackOverlayActionFeedback, TrackOverlayActionFeedbackType, TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { AppService } from '@service/app.service';
import { LoggerService } from '@service/loggers/logger.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { slideUpAnimation } from '@util/animations';
import { Subject, merge } from 'rxjs';
import { map, mergeAll, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const SNACKBAR_DURATION = 5000;

@Component({
  selector: 'tun-player-v5',
  templateUrl: './player-v5.component.html',
  styleUrls: ['./player-v5.component.scss'],
  host: {
    'class': 'router-flex'
  },
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]
})



export class PlayerV5Component implements DoCheck, OnInit, OnDestroy{

  private LOGGER_CLASSNAME = PlayerV5Component.name;

  public get useTunifyV5(){
    return !environment.use_redirect_location || environment.build_version == '5';
  }

  get heightPerItem$(){
    return this.appService.heightPerItem$;
  }

  get showingPopup$(){
    return this.showTrackOverlay$
  }

  get showTrackOverlay$(){
    return this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData$
    .pipe(
      map(trackOverlayData => {
        return trackOverlayData != null;
      })
    )
  }
  get trackInfoOverlayData$(){
    return this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData$;
  }

  @ViewChild('playerWrapper', {static: true}) playerWrapper:ElementRef;

  constructor(
    private loggerService:LoggerService,
    private appV5StateService: AppV5StateService,
    private trackOverlayService: TrackOverlayService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private appService: AppService
  ) {

  }

  ngDoCheck(){
    this.loggerService.debug(this.LOGGER_CLASSNAME, "ngDoCheck", "Change detection runs");
  }

  ngOnInit(): void {
    this.trackOverlayService.trackOverlayActionFeedback$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      trackOverlayActionFeedback => {
        this.trackOverlayActionFeedback = trackOverlayActionFeedback
      }
    );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
    this.trackOverlayActionFeedbackChanged$.complete();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onResize(event:ResizedEvent){
    this.playerSizeCalculation();
  }

  private playerSizeCalculation() {
    const playerViewRect = this.playerWrapper.nativeElement.getBoundingClientRect();

    if (playerViewRect.height > 0){
      const size = 6.4 + playerViewRect.width * 0.005 + playerViewRect.height * 0.003;

      this.appV5StateService.adjustPixelsFor1Rem(Math.round(size));
      this.loggerService.debug(this.LOGGER_CLASSNAME, "playerSizeCalculation", "1rem = " + size + " pixels");
      //calculate 1 rem to pixels

    }

  }

  onClosePopup(){
    this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = null;
  }

  onClickOutside(event: Event, item){
    if (event.target !== item)
      return;
      this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = null;
  }

  private _trackOverlayActionFeedback : TrackOverlayActionFeedback;
  private trackOverlayActionFeedbackChanged$ = new Subject<void>();
  private set trackOverlayActionFeedback (value: TrackOverlayActionFeedback) {
    this.trackOverlayActionFeedbackChanged$.next();
    this._trackOverlayActionFeedback = value;
    if (this._trackOverlayActionFeedback){
      if (this._trackOverlayActionFeedback.observable){
        this._trackOverlayActionFeedback.observable
        .pipe(
          takeUntil(
            merge(
              this.trackOverlayActionFeedbackChanged$,
              this.destroyed$
            )
          )
        )
        .subscribe(
          (asyncStatus) => {
            if (asyncStatus == AsyncStatus.COMPLETED){
              if (this._trackOverlayActionFeedback.type == TrackOverlayActionFeedbackType.RemoveFromPlaylist){
                //message = this.translateService.instant('musicChannel.start.succes').replace('{0}', musicChannel.name)
                this.showTrackOverlayActionFeedback(this.translateService.instant('trackOptions.list.remove.done'));
              }else if (this._trackOverlayActionFeedback.type == TrackOverlayActionFeedbackType.AddToQueue){
                this.showTrackOverlayActionFeedback(this.translateService.instant('trackOptions.queue.add.done'));
              }else if (this._trackOverlayActionFeedback.type == TrackOverlayActionFeedbackType.AddToPlaylist){
                this.showTrackOverlayActionFeedback(this.translateService.instant('trackOptions.playlist.add.status.done_noTitle'));
              }
            }
          }
        )
      }else{
        //no observable -> instant feedback
        if (this._trackOverlayActionFeedback.type == TrackOverlayActionFeedbackType.RemovedFromFavorites){
          this.showTrackOverlayActionFeedback(this.translateService.instant('trackOptions.favorite.remove.done'))
        }else if (this._trackOverlayActionFeedback.type == TrackOverlayActionFeedbackType.AddedToFavorites){
          this.showTrackOverlayActionFeedback(this.translateService.instant('trackOptions.favorite.add.done'))
        }else if (this._trackOverlayActionFeedback.type == TrackOverlayActionFeedbackType.RemovedFromBanlist){
          this.showTrackOverlayActionFeedback(this.translateService.instant('trackOptions.banned.remove.done'))
        }else if (this._trackOverlayActionFeedback.type == TrackOverlayActionFeedbackType.AddedToBanlist){
          this.showTrackOverlayActionFeedback(this.translateService.instant('trackOptions.banned.add.done'))
        }else if (this._trackOverlayActionFeedback.type == TrackOverlayActionFeedbackType.RemovedFromQueue){
          this.showTrackOverlayActionFeedback(this.translateService.instant('trackOptions.queue.remove.done'))
        }
      }
    }
  }

  private showTrackOverlayActionFeedback(message: string){
    const snackBarRef = this.snackBar.open(message, null, {
      duration: SNACKBAR_DURATION,
      panelClass: ['tunify-snackbar']
    });
  }

}
