import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClearBanlistPopupComponent } from './clear-banlist-popup/clear-banlist-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IconsV5Module } from '@components/icons-v5/icons-v5.module';
import { TranslateModule } from '@ngx-translate/core';
import { DeletePlaylistConfirmPopupComponent } from './delete-playlist-confirm-popup/delete-playlist-confirm-popup.component';
import { UpgradeVersionPopupComponent } from './upgrade-version-popup/upgrade-version-popup.component';
import { CopyCalendarPopupComponent } from './copy-calendar-popup/copy-calendar-popup.component';



@NgModule({
  declarations: [
    ClearBanlistPopupComponent,
    DeletePlaylistConfirmPopupComponent,
    UpgradeVersionPopupComponent,
    CopyCalendarPopupComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    IconsV5Module,
    TranslateModule
  ],
  exports: [
    ClearBanlistPopupComponent,
    DeletePlaylistConfirmPopupComponent,
    UpgradeVersionPopupComponent,
    CopyCalendarPopupComponent,
  ]
})
export class PopupsV5Module { }
