import { DTO_ChangeableParameter } from './changeableParameter';

export class DTO_ChangeableIntervalParameter extends DTO_ChangeableParameter{
    minimum: number;
    maximum: number;
    step: number;
    lowerSelection: number;
    upperSelection: number;
    metadataDisplayMinimum: string;
    metadataDisplayMaximum: string;
}