<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 17 17"
  enable-background="new 0 0 17 17"
  xml:space="preserve"
>
  <path
    fill="currentColor"
    d="M15.363,5.478l-2.842-2.841c-0.58-0.58-1.52-0.58-2.1,0L3,10.059V15h4.942l7.421-7.421
	C15.943,6.999,15.943,6.058,15.363,5.478z M7.527,14H4v-3.527l5.05-5.05l3.527,3.527L7.527,14z M14.656,6.872l-1.372,1.372
	L9.757,4.716l1.372-1.372c0.189-0.19,0.496-0.19,0.686,0l2.842,2.841C14.846,6.375,14.846,6.682,14.656,6.872z"
  />
</svg>
