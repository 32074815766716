import { Injectable, NgZone, Renderer2, OnDestroy } from '@angular/core';
import { BrandMessage } from '../../model/brandMessage';
import { AudioFileWithPlayInfo } from './audioFileWithPlayInfo';
import { LoggerService } from '../loggers/logger.service';
import { AudiofileUrlService } from '../data/audiofile-url.service';
import { AudioTagService } from './audio.tag.service';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { Subscription, timer, Subject } from 'rxjs';
import { buffer, takeUntil } from 'rxjs/operators';

class BufferedBrandMessage{
  lastUsedAt: Date;
  brandMessageWithPlayInfo: AudioFileWithPlayInfo;
  amountInUse = 0;
  cleanupAfterDoneUsing = false;
}

@Injectable({
  providedIn: 'root'
})
export class BrandMessageBufferService implements OnDestroy{

  private LOGGER_CLASSNAME = 'BrandMessageBufferService';

  readonly removeFromBufferAfterUnusedSeconds = 25 * 60 * 60;
  readonly bufferCleanupIntervalSeconds = 1200; //every 60 minutes -> check for messages that should be cleaned up

  //testing values
  //readonly removeFromBufferAfterUnusedSeconds = 10;
  //readonly bufferCleanupIntervalSeconds = 5;


  constructor(
    private loggerService: LoggerService,
    private audioFileUrlService: AudiofileUrlService,
    private audioTagService: AudioTagService,
    private _ngZone: NgZone,
    private zoneConnectionsService: ZoneConnectionsService
  ) {
    this.zoneConnectionsService.activeZoneConnection$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (zoneConnection) => {

          this.cleanUpData();
          this.adjustbufferCleanupPolling();

          if (zoneConnection != null) {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged in ... start timers');
          } else {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'logged out ... unloading');
          }
        }, err => {
          this.loggerService.debug(this.LOGGER_CLASSNAME, 'loggedInSubscription', 'error from subscriptions: ' + err);
        }
      );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private cleanUpData(){
    while (this.bufferedBrandMessages.length > 0){
      let bufferBrandMessage = this.bufferedBrandMessages.pop();
      if (bufferBrandMessage.amountInUse > 0){
        this.loggerService.error(this.LOGGER_CLASSNAME, 'cleanupAll', 'Cleaning up ' + bufferBrandMessage.brandMessageWithPlayInfo.audioFile.title + ' (' + bufferBrandMessage.brandMessageWithPlayInfo.audioFile.id + ') from buffer. But it is in use! ');
      }else{
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'cleanupAll', 'Cleaning up ' + bufferBrandMessage.brandMessageWithPlayInfo.audioFile.title + ' (' + bufferBrandMessage.brandMessageWithPlayInfo.audioFile.id + ') from buffer');
      }
      bufferBrandMessage.brandMessageWithPlayInfo.cleanUp();
    }
  }

  // we need a renderer to add AUDIO tags to the DOM and to register the event listeners
  private renderer: Renderer2;
  bootstrapRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }

  private bufferedBrandMessages: BufferedBrandMessage[] = [];

  public addBrandMessageToBuffer(brandMessage: BrandMessage){
    //check if we already have it in the buffer
    if (this.bufferedBrandMessages.filter((bufferedBrandMessage) => {
      return bufferedBrandMessage.brandMessageWithPlayInfo.audioFile.id == brandMessage.id;
    }).length == 0){

      var bufferedBrandMessage = this.createBufferedBrandMessage(brandMessage);
      this.bufferedBrandMessages.push(bufferedBrandMessage);
    }
  }

  //only return an instance when we have one in our buffer.
  public retrievePlayableAudioWithPlayInfo(brandMessage: BrandMessage): AudioFileWithPlayInfo{
    var bufferedBrandMessage = this.bufferedBrandMessages.find(bufferedBrandMessage => {
      return bufferedBrandMessage.brandMessageWithPlayInfo.audioFile.id == brandMessage.id
    });

    if (bufferedBrandMessage == null){
      bufferedBrandMessage = this.createBufferedBrandMessage(brandMessage);
      this.bufferedBrandMessages.push(bufferedBrandMessage);
    }else{
      bufferedBrandMessage.lastUsedAt = new Date();
    }
    bufferedBrandMessage.amountInUse++;
    return bufferedBrandMessage.brandMessageWithPlayInfo;
  }

  public doneUsingPlayableAudioWithPlayInfo(audioFileWithPlayInfo: AudioFileWithPlayInfo){

    //Find instance in buffer
    var bufferedBrandMessage = this.bufferedBrandMessages.find(bufferedBrandMessage => {
      return bufferedBrandMessage.brandMessageWithPlayInfo == audioFileWithPlayInfo
    });

    if (bufferedBrandMessage != null){
      bufferedBrandMessage.amountInUse--;
      if (bufferedBrandMessage.amountInUse == 0 && bufferedBrandMessage.cleanupAfterDoneUsing){
        this.loggerService.debug(this.LOGGER_CLASSNAME, 'doneUsingPlayableAudioWithPlayInfo', 'Cleaning up ' + bufferedBrandMessage.brandMessageWithPlayInfo.audioFile.title + ' (' + bufferedBrandMessage.brandMessageWithPlayInfo.audioFile.id + ') from buffer');
        bufferedBrandMessage.brandMessageWithPlayInfo.cleanUp();
        this.bufferedBrandMessages = this.bufferedBrandMessages.filter(bbm => bbm != bufferedBrandMessage);
      }
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'doneUsingPlayableAudioWithPlayInfo', 'Can not find item ' + audioFileWithPlayInfo.audioFile.title + ' (' + audioFileWithPlayInfo.audioFile.id + ') in buffer. Cleaning up anyway.');
      audioFileWithPlayInfo.cleanUp();
    }

  }

  private createBufferedBrandMessage(brandMessage: BrandMessage): BufferedBrandMessage{
    var bufferedBrandMessage = new BufferedBrandMessage();
    bufferedBrandMessage.lastUsedAt = new Date();
    const audioFileWithPlayInfoToBuffer = this.createAudioFileWithPlayInfo();
    audioFileWithPlayInfoToBuffer.audioFile = brandMessage;
    audioFileWithPlayInfoToBuffer.startPrepare();
    bufferedBrandMessage.brandMessageWithPlayInfo = audioFileWithPlayInfoToBuffer;

    return bufferedBrandMessage;
  }

  private createAudioFileWithPlayInfo(): AudioFileWithPlayInfo {
    const audioFileWithPlayInfo = new AudioFileWithPlayInfo(this.audioFileUrlService, this.renderer, this.audioTagService, this.loggerService, this._ngZone);
    return audioFileWithPlayInfo;
  }


  private bufferCleanupSubscription: Subscription;
  private adjustbufferCleanupPolling(){
    if (this.zoneConnectionsService.activeZoneConnection != null){
      if (this.bufferCleanupSubscription == null){
        //start timer
        this.bufferCleanupSubscription = timer(0, this.bufferCleanupIntervalSeconds * 1000)
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(
            () => {
              this.runPeriodicCleanUpBuffer();
            }
          );
      }
    }else{
      if (this.bufferCleanupSubscription){
        this.bufferCleanupSubscription.unsubscribe();
        this.bufferCleanupSubscription = null;
      }
    }

  }

  private runPeriodicCleanUpBuffer(){
    this.loggerService.debug(this.LOGGER_CLASSNAME, 'runPeriodicCleanUpBuffer', 'Going to check if we need to cleanup. Currently ' + this.bufferedBrandMessages.length + ' items buffered');

    let index = this.bufferedBrandMessages.length - 1;

    while (index >= 0) {
      if (this.bufferedBrandMessages[index].lastUsedAt.getTime() +  this.removeFromBufferAfterUnusedSeconds * 1000 < new Date().getTime()) {

        if (this.bufferedBrandMessages[index].amountInUse == 0){
          this.bufferedBrandMessages.splice(index, 1).forEach(deletedBufferedBrandMessage => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'runPeriodicCleanUpBuffer', 'Cleaning up ' + deletedBufferedBrandMessage.brandMessageWithPlayInfo.audioFile.title + ' (' + deletedBufferedBrandMessage.brandMessageWithPlayInfo.audioFile.id + ') from buffer');
            deletedBufferedBrandMessage.brandMessageWithPlayInfo.cleanUp();
          });
        }else{
          let bufferedBrandMessageInUse = this.bufferedBrandMessages[index];
          this.loggerService.warn(this.LOGGER_CLASSNAME, 'runPeriodicCleanUpBuffer', 'Item in use. Will clean up after use: ' + bufferedBrandMessageInUse.brandMessageWithPlayInfo.audioFile.title + ' (' + bufferedBrandMessageInUse.brandMessageWithPlayInfo.audioFile.id + ')');
          bufferedBrandMessageInUse.cleanupAfterDoneUsing = true;
        }

      }
      index -= 1;
    }
  }


}
