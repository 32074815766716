import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { StreamMode } from '@model/enums/streamMode';
import { MusicChannel } from '@model/musicChannel';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { Subject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlayingMusicSelectionService, PlayingOrigin } from '../../../../services/app-v5/playing-music-selection.service';
import { PlayingItemService } from '../../../../services/app-v5/playing-item.service';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';

@Component({
  selector: 'tun-music-channel-view',
  templateUrl: './music-channel-view.component.html',
  styleUrls: ['./music-channel-view.component.scss']
})
export class MusicChannelViewComponent implements OnDestroy, OnChanges{

  @Input() musicChannel: MusicChannel;
  @Input() recommendationsAmount: number = 0;
  @Input() showTogglePlayButton: boolean = false;

  @Output() showDetails = new EventEmitter<MusicChannel>();

  public playAnimationColors : MusicPlayAnimationColors

  public get isActive$(){
    return combineLatest([this.activeMusicSelectionService.selectedStreamMode$, this.activeMusicSelectionService.selectedMusicChannel$])
      .pipe(
        map(([streamMode, selectedMusicChannel]) => {
          return streamMode == StreamMode.MUSIC_CHANNEL && selectedMusicChannel == this.musicChannel
        })
      )
  }

  public get showPlayAnimation$(){
    return this.playingMusicSelectionService.playingMusicSelection$
      .pipe(
        map(playingMusicSelection => {
          return playingMusicSelection.origin == PlayingOrigin.musicChannel && playingMusicSelection.musicChannel == this.musicChannel
        })
      )
  }

  public get animatePlayAnimation$(){
    return this.playingItemService.isPlaying$
  }

  public get imageUrl(){
    if (this.musicChannel && this.musicChannel.imageInfo){
      return this.musicChannel.imageInfo.urlWebSquare
    }
    return null;
  }

  constructor(
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private playingItemService: PlayingItemService,
    private appV5StateService: AppV5StateService
    ) {

      this.playAnimationColors = new MusicPlayAnimationColors()
          .selectedColor('#8E8E93')
          .unselectedColor('#8E8E93');

    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
    }

    ngOnChanges(simpleChanges: SimpleChanges) {


    }

    public onShowDetails(){
      this.showDetails.emit(this.musicChannel);
    }

    //We could watch the musicChannel and show the details when it is starting
    public onItemIsStarting(){

    }
}
