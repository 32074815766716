import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Calendar } from '@model/calendar';
import { StreamMode } from '@model/enums/streamMode';
import { MusicPlayAnimationColors } from '@model/fieldModels/musicPlayAnimationColors';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss']
})
export class CalendarViewComponent {

  @Input() calendar: Calendar;
  @Input() showTogglePlayButton: boolean = false;

  @Output() showDetails = new EventEmitter<Calendar>();

  public playAnimationColors : MusicPlayAnimationColors

  public get isActive$(){
    return combineLatest([this.activeMusicSelectionService.selectedStreamMode$, this.activeMusicSelectionService.selectedCalendar$])
      .pipe(
        map(([streamMode, selectedCalendar]) => {
          return streamMode == StreamMode.CALENDAR && selectedCalendar == this.calendar
        })
      )
  }

  public get showPlayAnimation$(){
    return this.playingMusicSelectionService.playingMusicSelection$
      .pipe(
        map(playingMusicSelection => {
          return playingMusicSelection.origin == PlayingOrigin.calendar && playingMusicSelection.calendar == this.calendar
        })
      )
  }

  public get animatePlayAnimation$(){
    return this.playingItemService.isPlaying$
  }



  constructor(
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private playingItemService: PlayingItemService,
    private appV5StateService: AppV5StateService
    ) {

      this.playAnimationColors = new MusicPlayAnimationColors()
          .selectedColor('#8E8E93')
          .unselectedColor('#8E8E93');

    }

    private destroyed$ = new Subject<void>();
    ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
      this.destroyed$ = null;
    }

    ngOnChanges(simpleChanges: SimpleChanges) {


    }

    public onShowDetails(){
      this.showDetails.emit(this.calendar);
    }

    //We could watch the musicChannel and show the details when it is starting
    public onItemIsStarting(){

    }

}
