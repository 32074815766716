import { Component } from '@angular/core';

@Component({
  selector: 'tun-close-v5',
  templateUrl: './close-v5.component.html',
  styleUrls: ['./close-v5.component.scss']
})
export class CloseV5Component {

}
