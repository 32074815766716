import { Component } from '@angular/core';

@Component({
  selector: 'tun-delete-icon-v5',
  templateUrl: './delete-icon-v5.component.html',
  styleUrls: ['./delete-icon-v5.component.scss']
})
export class DeleteIconV5Component {

}
