import { ListRange } from '@angular/cdk/collections';
import { CdkDrag, CdkDragDrop, CdkDragEnter, CdkDragMove, CdkDropList } from '@angular/cdk/drag-drop';
import { CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { QueueService } from '@service/queue.service';
import { RemoteActionsService } from '@service/remote-actions.service';
import { RemotePlayStateService } from '@service/remote-play-state.service';
import { isFinal } from '@service/vo/asyncStatus';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { combineAll, last, map, take, takeUntil } from 'rxjs/operators';
import { TunifyColor } from '../../../../model/enums/tunifyColor.enum';
import { MusicSelectionService } from '@service/music-selection.service';
import { DTO_ActiveMode } from '@service/api/zone-configuration-api.service';
import { ActiveMusicSelectionService } from '@service/active-music-selection.service';
import { StreamMode } from '@model/enums/streamMode';
import { TrackTableItem, TrackTableItemType } from '../../../../components/components-v5/track-list-view/track-list-view.component';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { ResizedEvent } from '@components/resize/resize.directive';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tun-queue-view',
  templateUrl: './queue-view.component.html',
  styleUrls: ['./queue-view.component.scss']
})
export class QueueViewComponent implements OnInit, OnDestroy {

  public TrackInfoContextMode = TrackInfoContextMode

  @ViewChild(CdkVirtualScrollViewport, {static: true}) private viewport: CdkVirtualScrollViewport;
  @ViewChild(CdkVirtualForOf, {static: true}) private virtualForOf: CdkVirtualForOf<TrackTableItem[]>;

  /**
   * BUG SEMI FIX:
   *
   * Virtual scrolling and autoscroll during drag&drop does not work
   *
   * Strategy:
   * Less than 100 items: no virtual scrolling and allow autoscroll
   * 100 items+: virtual scrolling and disable autoscroll
   */

  public get disableAutoScroll$(){
    return combineLatest([this.open$, this.queueService.queue$])
      .pipe(
        map(([open, queuedTracks]) => {
          return open && queuedTracks && queuedTracks.length > 100
        })
      )
  }

  //virtual scrolling can be disabled by providing no value for itemSize
  get virtualScrollingItemSize$(){
    return combineLatest([this.itemSize$, this.disableAutoScroll$])
    .pipe(
      map(
        ([itemSize, disableAutoScroll]) => {
          if (disableAutoScroll){
            return itemSize;
          }
          return "corrupt";
        }
      )
    )
  }

  /**
   * BUGFIX: resize does not trigger correct viewport size re-calculations, trigger it ourselfs
   */

  onResize(event:ResizedEvent){
    this.viewport.checkViewportSize();
  }




  // === State === //

  get itemSize$(){
    return this.appV5StateService.pixelsFor1Rem$
    .pipe(
      map(
        px => {
          return px * 3;
        }
      )
    )
  }

  get busyText$(){
    return combineLatest([this.remoteActionSending$, this.loading$, this.shuffling$])
      .pipe(
        map(([remoteActionSending, loading, shuffling]) =>
          remoteActionSending ? "connect.sync.action" :
            (shuffling ? "waitingQueue.shuffle.busy" :
              (loading ? "general.loading" : "")
            )
        )
      )
  }

  get busy$(){
    return combineLatest([this.remoteActionSending$, this.loading$, this.shuffling$])
      .pipe(
        map(([remoteActionSending, loading, shuffling]) => remoteActionSending || loading || shuffling)
      )
  }

  get loading$(){
    return this.queueService.loadingQueue$;
  }

  public get remoteActionSending$(){
    return combineLatest([this.startingTrack$, this.waitingForNextTrack$])
      .pipe(
        map(([startingTrack, waitingForNextTrack]) => startingTrack || waitingForNextTrack)
      )
  }

  public get startingTrack$(){
    return this.remoteActionsService.startingTrack$
      .pipe(map(audioFile => audioFile != null));
  }

  public get waitingForNextTrack$(){
    return this.remotePlayStateService.waitingForTrackInfoAfterNext$;
  }

  public TunifyColor = TunifyColor
  public get radioQueueTunifyColor$(){
    return this.activeMusicSelectionService.selectedStreamMode$
    .pipe(map((streamMode) => {
      if (streamMode && streamMode == StreamMode.CALENDAR) {
        return TunifyColor.GREEN
      }
      return TunifyColor.BLUE
    }));
  }

  private _openSubject = new BehaviorSubject<Boolean>(true);
  public open$ = this._openSubject.asObservable();

  public TrackTableItemType = TrackTableItemType;

  public queueTableItems : TrackTableItem[] = [];

  private _radioHeaderItem : TrackTableItem;
  private get radioHeaderItem(){
    if (this._radioHeaderItem == null){
      this._radioHeaderItem = new TrackTableItem();
      this._radioHeaderItem.type = TrackTableItemType.RADIO_QUEUE_HEADER;
    }
    return this._radioHeaderItem;
  }

  public get queueTableItems$(){
    return combineLatest([this.open$, this.queueService.queue$, this.queueService.radioQueue$])
            .pipe(
              map(([open, waitingQueueTracks, radioQueueTracks ]) => {
                let items : TrackTableItem[] = [];
                if (open && waitingQueueTracks != null) {
                  const waitingQueueTableItems = waitingQueueTracks.map(track => {
                    const item = new TrackTableItem();
                    item.type = TrackTableItemType.WAITING_QUEUE_ITEM;
                    item.track = track;
                    return item;
                  })
                  items = items.concat(waitingQueueTableItems);
                }

                items = items.concat(this.radioHeaderItem);

                if (radioQueueTracks != null){

                  const radioQueueTableItems = radioQueueTracks.slice(0, 5).map(track => {
                    const item = new TrackTableItem();
                    item.type = TrackTableItemType.RADIO_QUEUE_ITEM;
                    item.track = track;
                    return item;
                  })
                  items = items.concat(radioQueueTableItems);
                }


                return items;
              })
            )

  }

  public get queueTracks$(){
    return this.queueService.queue$;
  }

  public get radioQueueTracks$(){
    return this.queueService.radioQueue$;
  }

  public get startTrackEnabled$(){
    return this.subscriptionsService.accessRights$
      .pipe(
        map(
          (accessRights => {
            return accessRights == null || accessRights.startTrack
          })
        )
      )
  }

  constructor(
    private queueService: QueueService,
    private musicManipulationService: MusicManipulationService,
    private remoteActionsService: RemoteActionsService,
    private remotePlayStateService: RemotePlayStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private activeMusicSelectionService: ActiveMusicSelectionService,
    private appV5StateService: AppV5StateService,
    private trackOverlayService: TrackOverlayService,
    private subscriptionsService: SubscriptionsService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {

  }

  private range: ListRange;
  public ngOnInit(): void {

    this.queueTableItems$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      value => {
        this.queueTableItems = value;
        this.changeDetectorRef.markForCheck()
      }
    )

    // here we subscribe on viewChange to return ListRange object
    this.virtualForOf.viewChange.subscribe((range: ListRange) => {
    this.range = range
    //console.log("range set: " + range.start + " to " + range.end)
    }
    );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onToggleOpen(){
    this._openSubject.next(!this._openSubject.value);
  }

  public shuffling$ = new BehaviorSubject(false);
  public onShuffle(){
    this.musicManipulationService.shuffleQueue()
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      (asyncStatus) => {
        this.shuffling$.next(!isFinal(asyncStatus));
        console.warn(asyncStatus);
      }
    )
  }

  public onClear(){
    this.musicManipulationService.clearQueue();
  }

  /* Handlers for track item */
  public onPlay(track: AudioFile){
    this.musicManipulationService.playAudioFile(track);
  }

  public onShowOptions(track: AudioFile, trackInfoContextMode: TrackInfoContextMode){
    const trackInfoOverlayData = new TrackInfoOverlayData(track, new TrackInfoContext(trackInfoContextMode));
    this.trackOverlayService.showTrackOverlayForTrackInfoOverlayData = trackInfoOverlayData;
  }

  /* Drag & drop */

  /*
  private _canceledByEsq = false;

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
        this._canceledByEsq = true;
        document.dispatchEvent(new Event('mouseup'));
    }
  }
  */



  public insideList = true

	itemMoved(event: CdkDragMove<TrackTableItem>): void {

    if(!document.elementsFromPoint(event.pointerPosition.x, event.pointerPosition.y).some(el => el.classList.contains('cdk-drop-list'))){
      console.log("not insideList")

      this.insideList = false;

		}else{
      this.insideList = true;
      console.log("insideList")

    }
	}



  public drop(event: CdkDragDrop<TrackTableItem[]>) {

    /*
		if( !event.isPointerOverContainer) {
			return
		}
    */

    const PREV_IND_WITH_OFFSET: number = this.range.start + event.previousIndex;
    const CUR_IND_WITH_OFFSET: number = this.range.start + event.currentIndex;


    if (event.item.data instanceof TrackTableItem){
      const track = event.item.data.track;

      //move from inside
      if (event.previousContainer == event.container){

        //move to some position -> do nothing
        if (PREV_IND_WITH_OFFSET == CUR_IND_WITH_OFFSET){
          return
        }
        if (event.item.data.type == TrackTableItemType.RADIO_QUEUE_ITEM){
          this.musicManipulationService.addAudioFilesToQueue(
            [track],
            CUR_IND_WITH_OFFSET
          );
        }else if (event.item.data.type == TrackTableItemType.WAITING_QUEUE_ITEM){
          this.musicManipulationService.moveAudioFileInQueue(
            track,
            CUR_IND_WITH_OFFSET
          );
        }
      }else{
        //added from outside this list
        this.musicManipulationService.addAudioFilesToQueue(
          [track],
          CUR_IND_WITH_OFFSET
        );
      }

    }
  }

  /**
   * Predicate function that only allows dropping above the radio header item
   */
  public radioHeaderPredicate = (index: number, item: CdkDrag<TrackTableItem>, drop: CdkDropList) => {
    const headerIndex = drop.data.indexOf(this.radioHeaderItem)
    const itemIndex = drop.data.indexOf(item.data)

    const itemFromOutside = item.data.type != TrackTableItemType.WAITING_QUEUE_ITEM && item.data.type != TrackTableItemType.RADIO_QUEUE_ITEM
    //console.log("range start: " + this.range.start + " end: " + this.range.end);
    //console.log("index: " + index + " -- headerIndex: " + headerIndex + " -- itemIndex: " + itemIndex)



    if (headerIndex >= 0) {
      if (index < headerIndex || index == itemIndex){
        return true
      }
      //special case -> when dragging from below the radio header: allow drop on radioHeader (= just above as new item)
      if (itemIndex > headerIndex && index == headerIndex){
        return true
      }
      //special case -> when dragging from outside the list: allow drop on radioHeader (= just above as new item)
      if (itemFromOutside && index == headerIndex){
        return true
      }
    }
    return false;
  }
}
