import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AudioFile } from '@model/audioFile';
import { TrackAction, TrackEvent } from '@model/events/trackEvent';
import { SearchApiService, SearchType, SuggestionsType } from '@service/api/search-api.service';
import { AutocompletionObject, AutocompletionType } from '@service/autocompletion.service';
import { LoggerService } from '@service/loggers/logger.service';
import { SearchData } from '@service/search.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchV5Service implements OnDestroy{

  private LOGGER_CLASSNAME = SearchV5Service.name;

  /**
   * Searching property
   */
  private get _searching(): boolean {
    return this._searchingSubject.value;
  }
  private set _searching(value: boolean) {
    if (this._searching !== value) {
        this._searchingSubject.next(value);
    }
  }
  get searching(): boolean {
    return this._searching;
  }
  private _searchingSubject = new BehaviorSubject<boolean>(false);
  public searching$ = this._searchingSubject.asObservable();


  /**
   * Search error
   */
  private get _searchError(): boolean {
    return this._searchErrorSubject.value;
  }
  private set _searchError(value: boolean) {
    if (this._searchError !== value) {
        this._searchErrorSubject.next(value);
    }
  }
  get searchError(): boolean {
    return this._searchError;
  }
  private _searchErrorSubject = new BehaviorSubject<boolean>(false);
  public searchError$ = this._searchErrorSubject.asObservable();


  /**
   * Search result
   */
  private get _searchResult(): AudioFile[] {
    return this._searchResultSubject.value;
  }
  private set _searchResult(value: AudioFile[]) {
    if (this._searchResult !== value) {
        this._searchResultSubject.next(value);
    }
  }
  get searchResult(): AudioFile[] {
    return this._searchResult;
  }
  private _searchResultSubject: BehaviorSubject<AudioFile[]> = new BehaviorSubject<AudioFile[]>(null);
  public searchResult$: Observable<AudioFile[]> = this._searchResultSubject.asObservable();

  /**
   * Last search input
   */

  private get _lastSearch(): SearchData {
    return this._lastSearchSubject.value;
  }
  private set _lastSearch(value: SearchData) {
    if (this._lastSearch !== value) {
        this._lastSearchSubject.next(value);
    }
  }
  get lastSearch(): SearchData {
    return this._lastSearch;
  }
  private _lastSearchSubject: BehaviorSubject<SearchData> = new BehaviorSubject<SearchData>(null);
  public lastSearch$: Observable<SearchData> = this._lastSearchSubject.asObservable();


  constructor(
    private loggerService: LoggerService,
    private searchApiService: SearchApiService,
    private subscriptionsService: SubscriptionsService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
      this.searchStarts$.complete();

      this.destroyed$.next();
      this.destroyed$.complete();
  }


  public handleSearchOnTrack(trackEvent: TrackEvent) {

    if (this.subscriptionsService.accessRights != null && this.subscriptionsService.accessRights.search){

        if (trackEvent.action === TrackAction.SEARCH_ON_TITLE) {
          this.searchOnTrack(SearchType.SEARCH_ON_TITLE, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_GROUP) {
          this.searchOnTrack(SearchType.SEARCH_ON_GROUP, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_BPM) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_BPM, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_DANCING_STYLE) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_DANCING_STYLE, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_MUSIC_STYLE) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_STYLE, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_MOOD) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_MOOD, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_ON_YEAR) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_ON_YEAR, trackEvent.track);
        } else if (trackEvent.action === TrackAction.SEARCH_SIMILAR) {
          this.suggestionsForTrack(SuggestionsType.SUGGEST_SIMILAR, trackEvent.track);
        }
    }else{
      //zone has no access to search -> no feedback?
      this.loggerService.debug(this.LOGGER_CLASSNAME, "handleSearchOnTrack", "Zone has no access to search (" + trackEvent.action + ")");
    }


  }

  public searchOnTrack(searchType: SearchType, track: AudioFile) {
    this.clearLastSearch();

    const searchData = new SearchData();
    searchData.track = track;
    searchData.searchType = searchType;

    const searchObservable: Observable<AudioFile[]> = this.searchApiService.searchOnTrack(searchType, track);

    this.handleSearchObservable(searchData, searchObservable);
  }


    public suggestionsForTrack(suggestionsType: SuggestionsType, tracks: AudioFile) {
      this.clearLastSearch();

      const searchData = new SearchData();
      searchData.searchType = SearchType.SEARCH_SUGGESTIONS;
      searchData.suggestionsTrack = tracks;
      searchData.suggestionsType = suggestionsType;

      // fixed similarity of 5
      const searchObservable: Observable<AudioFile[]> = this.searchApiService.searchSuggestions(suggestionsType, tracks, 5);

      this.handleSearchObservable(searchData, searchObservable);

    }

    private searchStarts$ = new Subject<void>();
    private handleSearchObservable(searchData: SearchData, searchObservable: Observable<AudioFile[]>) {
      if (searchObservable) {

        this._lastSearch = searchData;
        this.searchStarts$.next();

        //set loading after emitting search starts -> finalize will be triggered and we need to keep the searching state for the latest request
        this._searching = true;

        searchObservable.pipe(
          finalize(() => {
            this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleSearchObservable', 'finalize for ' + searchData.toString() + ' loading: ' + this.searching);
            if (!this.searching) {
              this.loggerService.error(this.LOGGER_CLASSNAME, 'handleSearchObservable', 'finalize for ' + searchData.toString()  + ' but we are not loading!');
            }
            this._searching = false;
          }),
          takeUntil(
            merge(
              this.destroyed$,
              this.searchStarts$
              )
          )
        ).subscribe(
            (data) => {
              this.loggerService.debug(this.LOGGER_CLASSNAME, 'handleSearchObservable', 'data received for ' + searchData.toString() + ': ' + data);

              const realObjects = data.map(res => createClassObjectForAudioFile(res));
              this._searchResult = realObjects;
            }
          ,
          (error : unknown) => {
            let errMsg = 'Error';
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            }
            this.loggerService.error(this.LOGGER_CLASSNAME, 'loadPlaylists error', errMsg);
            this._searchError = true;
          });
        }
    }


  public clearLastSearch() {
    this._lastSearch = null;
    this._searchResult = null;
    this._searching = false;
    this._searchError = null;
  }
}
