<div
  [ngClass]="['checkbox-container', checkboxColorClass]"
>
  <mat-checkbox
    [disableRipple]="false"
    [checked]="isChecked"
    [disabled]="disabled"
    [indeterminate]="indeterminate"
    (change)="toggleChecked()"
  >
    <div class="label-container" [class.active]="coloredTitleOnCheck && (isChecked || indeterminate)">
      <div #label class="label" tunScrollOverflow>
        {{ title }}
      </div>
    </div>
  </mat-checkbox>
</div>
