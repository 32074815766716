<div class="content-wrapper">

  <div class="title-container">
    <div class="track-container">
      <div *ngIf="showView" class="track-title-container">
        <tun-scrollable-text class="trackTitle" [text]="trackInfoOverlayData.track.title"></tun-scrollable-text>
      </div>
      <div *ngIf="showView" class="track-group-container">
        <tun-scrollable-text class="trackGroup" [text]="trackInfoOverlayData.track.group"></tun-scrollable-text>
      </div>
    </div>

    <button class="icon-button" (click)="onClose()">
      <tun-close-v5 class="icon icon-large-scaled-svg-button"></tun-close-v5>
    </button>

  </div>

  <div class="animationWrapper" @fadeIn>
    <tun-song-info-view
      *ngIf="(songInfoOverlayView$ | async) == SONG_INFO_OVERLAY_VIEW.SONG_INFO_OVERLAY_VIEW_MAIN"
      [trackInfoOverlayData]="trackInfoOverlayData"
      (selectPlaylist)="onSelectPlaylist()"
      [@carousel]="{
        value: animationDirection,
        params: {
          duration: CAROUSEL_DURATION
        }
      }"
      class="fullContainer"
    >
    </tun-song-info-view>

    <tun-song-info-select-playlist-view
      *ngIf="(songInfoOverlayView$ | async) == SONG_INFO_OVERLAY_VIEW.SONG_INFO_OVERLAY_VIEW_PLAYLISTS"
      [track]="trackInfoOverlayData.track"
      (back)="onBack()"
      [@carousel]="{
        value: animationDirection,
        params: {
          duration: CAROUSEL_DURATION
        }
      }"
      class="fullContainer"
    >

    </tun-song-info-select-playlist-view>


  </div>

</div>
