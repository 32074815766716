<div #container (tunResize)="onResize()" class="container">

  <div #linesContainer class="options">
    <div
      #leftSliderValue
      class="option lower-value"
      [style.left]="leftSliderLeft"
      [style.margin-left.px]="-1 * leftLabelAdjustment"
      [style.transition]="transitionSpeed"
    >
      {{ selectedMinValue }} {{ label }}
    </div>
    <div
      #connector
      class="option"
      [style.left]="leftSliderLeft"
      [style.margin-left.px]="connectorMargin"
      [style.transition]="transitionSpeed"
    >
      <span class="connector {{showConnector ? 'visible' : 'invisible'}}">
        -
      </span>

    </div>
    <div
      #rightSliderValue
      class="option upper-value"
      [style.right]="rightSliderRight"
      [style.margin-right.px]="-1 * rigthLabelAdjustment"
      [style.transition]="transitionSpeed"
    >
      {{ selectedMaxValue }} {{ label }}
    </div>
  </div>

  <!-- lines & thumb content -->
  <div class="sliderContent" >


    <!-- Selected and unselected lines. -->
    <div class="lines-container" (click)="lineClicked($event)">

      <div class="lines">

        <div
          #unselectedline
          class="line unselected-line"

        ></div>

        <div
          class="line selected-line {{sliderColor}}"
          [style.left]="leftSliderLeft"
          [style.right]="rightSliderRight"
          [style.transition]="transitionSpeed"
          [style.-webkit-transition]="transitionSpeed"
          [style.-moz-transition]="transitionSpeed"
        ></div>

      </div>

    </div>

    <!-- Left thumb -->
    <div
        #leftSliderBox
        class="thumb-box thumb-box-left "
        [style.left]="leftSliderLeft"
        [style.transition]="transitionSpeed"
    >

        <div class="thumb thumb-left {{sliderColor}}"></div>

    </div>


      <!-- Right thumb -->
      <div
        #rightSliderBox
        class="thumb-box thumb-box-right"
        [style.right]="rightSliderRight"
        [style.transition]="transitionSpeed"
      >
        <div class="thumb thumb-right {{sliderColor}}"></div>
      </div>

  </div>


</div>
