<div class="wrapper">

  <div class="contentWrapper">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <div class="title">
          {{ 'settings.language.edit.title' | translate }}
        </div>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- normal content -->
    <div class="scrollContainer">
      <div class="checkBox-container">
        <ng-container *ngFor="let language of languages"
        >
          <tun-checkbox-v5
            [title]="translateableLanguageString(language) | translate"
            [checkBoxColor]="TunifyCheckBoxColor.BLACK"
            class="checkBox"
            [isChecked]="isChecked(language)"
            [disabled]="isChecked(language)"
            (isCheckedChanged)="checkedChanged(language, $event)"
            >

          </tun-checkbox-v5>
        </ng-container>
      </div>


    </div>


</div>
