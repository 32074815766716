import {
  Component,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { BaseSliderV5Component } from '../base-slider-v5/base-slider-v5.component';

@Component({
  selector: 'tun-flexible-slider-v5',
  templateUrl: './flexible-slider-v5.component.html',
  styleUrls: ['./flexible-slider-v5.component.scss']
})
export class FlexibleSliderV5Component extends BaseSliderV5Component
implements OnChanges, AfterViewInit, OnDestroy {

@ViewChild('container', { static: true }) container: ElementRef;
@ViewChild('linesContainer', { static: true }) linesContainer: ElementRef;

@ViewChild('leftSliderValue', { static: true }) leftSliderValue: ElementRef;
@ViewChild('leftSliderBox', { static: true }) leftSliderBox: ElementRef;
@ViewChild('rightSliderBox', { static: true }) rightSliderBox: ElementRef;
@ViewChild('rightSliderValue', { static: true }) rightSliderValue: ElementRef;

@ViewChild('connector', { static: true }) connector: ElementRef;


@Input() label: string;

// Input for the lowest number;
@Input() minValue: number;

// Input for the highest number;
@Input() maxValue: number;

// Currently selected minimum value.
@Input() selectedMinValue = 0;

// Currently selected maximal value.
@Input() selectedMaxValue = 0;

leftThumbPosition = 0;

// Left attribute of the selected line, used for positioning.
selectedLineLeft = 0;

// Right attribute of the selected line, used for positioning.
selectedLineRight = 0;

// List of breakpoints a slider can snap to.
breakpoints: number[] = [];

// Style of the value next to the slider.
valueStyle: SafeStyle;

// when one of the values goes beyond this percentage, the label is flipped to the other side
private insideFromPercentage = 90;

constructor(
  protected cdRef: ChangeDetectorRef,
  protected sanitizer: DomSanitizer,
  protected ngZone: NgZone,
) {
  super(sanitizer, cdRef);
}

// Called when the component is resized to fix the positions of the sliders.
onResize = () => {
  super.onResize();
  this.updateThumbPositions();
}

ngOnChanges(simpleChanges: SimpleChanges) {
  let breakPointsChanged = false;
  if (simpleChanges.minValue || simpleChanges.maxValue) {
    this.breakpoints = this.getBreakpoints();
    breakPointsChanged = true;
  }
  let sliderChanged = false;
  if (simpleChanges.selectedMinValue || breakPointsChanged) {
    const percentage =
      (100 * (this.selectedMinValue - this.minValue)) /
      (this.maxValue - this.minValue);
    // if the representation value is the same -> don't adjust (= don't snap to real values)
    if (
      this.closestBreakPointIndexOfPercentage(this.leftSliderLeftPercent) !=
      this.closestBreakPointIndexOfPercentage(percentage)
    ) {
      this.leftSliderLeftPercent = percentage;
      sliderChanged = true;
    }
  }

  if (simpleChanges.selectedMaxValue) {
    const percentage =
      (100 * (this.selectedMaxValue - this.minValue)) /
      (this.maxValue - this.minValue);
    // if the representation value is the same -> don't adjust (= don't snap to real values)
    if (
      this.closestBreakPointIndexOfPercentage(this.rightSliderRightPercent) !=
      this.closestBreakPointIndexOfPercentage(100 - percentage)
    ) {
      this.rightSliderRightPercent = 100 - percentage;
      sliderChanged = true;
    }
  }

  if (sliderChanged) {
    this.updateSelectedLine();
    this.updateThumbPositions();
  }
}

ngAfterViewInit() {
  super.ngAfterViewInit();

  this.updateThumbPositions();
  this.cdRef.detectChanges();

  this.ngZone.runOutsideAngular(() => {
    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseleave', this.onMouseLeave);
    window.addEventListener('touchmove', this.onTouchMove);
    window.addEventListener('touchend', this.onTouchEnd);
    window.addEventListener('touchcancel', this.onTouchCancel);
    window.addEventListener('resize', this.onResize);

    this.leftSliderBox.nativeElement.addEventListener(
      'mousedown',
      this.leftSliderMouseDown
    );
    this.leftSliderBox.nativeElement.addEventListener(
      'touchstart',
      this.leftSliderMouseDown
    );
    this.rightSliderBox.nativeElement.addEventListener(
      'mousedown',
      this.rightSliderMouseDown
    );
    this.rightSliderBox.nativeElement.addEventListener(
      'touchstart',
      this.rightSliderMouseDown
    );
  });
}

ngOnDestroy() {
  this.ngZone.runOutsideAngular(() => {
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseleave', this.onMouseLeave);
    window.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('touchend', this.onTouchEnd);
    window.removeEventListener('touchcancel', this.onTouchCancel);
    window.removeEventListener('resize', this.onResize);

    this.leftSliderBox.nativeElement.removeEventListener(
      'mousedown',
      this.leftSliderMouseDown
    );
    this.leftSliderBox.nativeElement.removeEventListener(
      'touchstart',
      this.leftSliderMouseDown
    );
    this.rightSliderBox.nativeElement.removeEventListener(
      'mousedown',
      this.rightSliderMouseDown
    );
    this.rightSliderBox.nativeElement.removeEventListener(
      'touchstart',
      this.rightSliderMouseDown
    );
  });
}

// Called when the user clicks the line to move a slider.
lineClicked = (e: MouseEvent) => {
  super.lineClicked(e);

  const width = this.unselectedLine.nativeElement.offsetWidth;
  const percentage = ((e.clientX - this.unselectedLineClientX) / width) * 100;

  //for debugging weird bug where slider values was not correctly adjusted on a click
  //console.log("percentage: " + percentage + "e.clientX: " + e.clientX + " --- unselectedLineClientX: " + this.unselectedLineClientX + " --- width:" + width )

  // If the left slider is closer to the point on the line that was clicked.
  if (
    Math.max(percentage, this.leftSliderLeftPercent) -
      Math.min(percentage, this.leftSliderLeftPercent) <
    Math.max(percentage, 100 - this.rightSliderRightPercent) -
      Math.min(percentage, 100 - this.rightSliderRightPercent)
  ) {
    this.leftSliderLeftPercent = percentage;
    this.moveLeftSlider();
  } else if (
    Math.max(percentage, this.leftSliderLeftPercent) -
      Math.min(percentage, this.leftSliderLeftPercent) >
    Math.max(percentage, 100 - this.rightSliderRightPercent) -
      Math.min(percentage, 100 - this.rightSliderRightPercent)
  ) {
    this.rightSliderRightPercent = 100 - percentage;
    this.moveRightSlider();
  } else {
    // If the clicked point is to the left of the left slider.
    if (this.calculateLeftSliderClientX() >= e.clientX) {
      this.leftSliderLeftPercent = percentage;
      this.moveLeftSlider();
    } else {
      this.rightSliderRightPercent = 100 - percentage;
      this.moveRightSlider();
    }
  }

  this.resetTransitionSpeed();

  this.emitOutput(this.selectedMinValue, this.selectedMaxValue);
}

// Calculates the amount of options.
getAmountOfBreakpoints(): number {
  return this.maxValue - this.minValue + 1;
}

// Get an array of percentages used for snapping.
getBreakpoints() {
  const length = this.getAmountOfBreakpoints();
  return new Array(length).fill(0).map((e, i) => this.minValue + i);
}

// Calcultates the X-coordinate of the left slider.
calculateLeftSliderClientX(): number {
  const { x } = this.leftSliderBox.nativeElement.getBoundingClientRect();
  return x;
}

// Updates the left and right attributes of the selected line to match the sliders.
updateSelectedLine() {
  if (
    Math.round(this.leftSliderLeftPercent + this.rightSliderRightPercent) <
    100
  ) {
    this.selectedLineLeft = this.leftSliderLeftPercent;
    this.selectedLineRight = this.rightSliderRightPercent;
  } else {
    if (this.leftSliderLeftPercent <= 1) {
      this.selectedLineLeft = this.leftSliderLeftPercent;
      this.selectedLineRight = this.rightSliderRightPercent - 1;
    } else if (this.rightSliderRightPercent <= 1) {
      this.selectedLineLeft = this.leftSliderLeftPercent - 1;
      this.selectedLineRight = this.rightSliderRightPercent;
    } else {
      this.selectedLineLeft = this.leftSliderLeftPercent - 0.5;
      this.selectedLineRight = this.rightSliderRightPercent - 0.5;
    }
  }
}

//adjust label positions when scrolled to the edge or close to each other
leftLabelAdjustment = 0;
rigthLabelAdjustment = 0;
showConnector = false
connectorMargin = 0;

updateThumbPositions(){
  const extraSpace = (this.container.nativeElement.offsetWidth - this.linesContainer.nativeElement.offsetWidth) / 2

  const spaceLeft = extraSpace + this.linesContainer.nativeElement.offsetWidth * this.leftSliderLeftPercent / 100;
  if (spaceLeft > this.leftSliderValue.nativeElement.offsetWidth / 2){
    this.leftLabelAdjustment = this.leftSliderValue.nativeElement.offsetWidth / 2
  }else{
    this.leftLabelAdjustment = spaceLeft;
  }

  const possibleSpaceLeft = spaceLeft - this.leftLabelAdjustment ;

  const spaceRight = extraSpace + this.linesContainer.nativeElement.offsetWidth * this.rightSliderRightPercent / 100;
  if (spaceRight > this.rightSliderValue.nativeElement.offsetWidth / 2){
    this.rigthLabelAdjustment = this.rightSliderValue.nativeElement.offsetWidth / 2
  }else{
    this.rigthLabelAdjustment = spaceRight;
  }
  const possibleSpaceRight = spaceRight - this.rigthLabelAdjustment;

  const overlap = this.leftSliderValue.nativeElement.offsetWidth + this.rightSliderValue.nativeElement.offsetWidth - this.linesContainer.nativeElement.offsetWidth * ((100 - this.leftSliderLeftPercent - this.rightSliderRightPercent) / 100) - this.leftLabelAdjustment - this.rigthLabelAdjustment

  let neededAdjustment = 0
  if (overlap + this.connector.nativeElement.offsetWidth + 10 > 0){
    neededAdjustment = overlap + this.connector.nativeElement.offsetWidth + 10
    this.showConnector = true

  }else{
    this.showConnector = false
  }

  let leftAdjustment = neededAdjustment / 2;
  if (possibleSpaceLeft <  neededAdjustment / 2){
    leftAdjustment = possibleSpaceLeft;
  }else if (possibleSpaceRight < neededAdjustment / 2){
    leftAdjustment = neededAdjustment - possibleSpaceRight
  }

  this.leftLabelAdjustment += leftAdjustment
  this.rigthLabelAdjustment += neededAdjustment - leftAdjustment;

  this.connectorMargin = this.leftSliderValue.nativeElement.offsetWidth - this.leftLabelAdjustment - (Math.min(0, overlap - neededAdjustment + this.connector.nativeElement.offsetWidth)  / 2);
}

// If a slider is clicked, it is released and the outputs are emitted.
releaseSliderClicks() {
  if (this.leftSliderIsClicked) {
    this.leftSliderIsClicked = false;
    this.leftSliderClientX = 0;
    this.emitOutput(this.selectedMinValue, this.selectedMaxValue);
  } else if (this.rightSliderIsClicked) {
    this.rightSliderIsClicked = false;
    this.rightSliderClientX = 0;
    this.emitOutput(this.selectedMinValue, this.selectedMaxValue);
  }
}

// Registration that the user is moving the left slider.
leftSliderMouseMove(eventClientX: number) {
  // Current width of the left slider value.
  const leftSliderValueWidth = this.leftSliderValue.nativeElement.offsetWidth;

  super.leftSliderMouseMove(eventClientX, leftSliderValueWidth);

  // Move the slider.
  this.moveLeftSlider();
}

// Calculate and set the currently selected minimum value.
updateMinValue() {
  const index = this.closestBreakPointIndexOfPercentage(
    this.leftSliderLeftPercent
  );
  this.selectedMinValue = this.breakpoints[index];
}

private closestBreakPointIndexOfPercentage(percentage: number) {
  return Math.round(((this.getAmountOfBreakpoints() - 1) * percentage) / 100);
}

// Move the left slider.
moveLeftSlider() {
  this.updateMinValue();
  this.updateSelectedLine();
  this.updateThumbPositions();
}

// Registration that the user is moving the right slider.
rightSliderMouseMove(eventClientX: number) {
  // Current width of the right slider value.
  const rightSliderValueWidth = this.rightSliderValue.nativeElement
    .offsetWidth;

  super.rightSliderMouseMove(eventClientX, rightSliderValueWidth);

  // Move the slider.
  this.moveRightSlider();
}

// Move the right slider.
moveRightSlider() {
  this.updateMaxValue();
  this.updateSelectedLine();
  this.updateThumbPositions();
}

// Calculate and set the currently selected maximum value.
updateMaxValue() {
  const index = this.closestBreakPointIndexOfPercentage(
    100 - this.rightSliderRightPercent
  );
  this.selectedMaxValue = this.breakpoints[index];
}

}
