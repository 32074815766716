import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountIconComponent } from './account-icon/account-icon.component';
import { AddToListIconComponent } from './add-to-list-icon/add-to-list-icon.component';
import { AddToQueueIconComponent } from './add-to-queue-icon/add-to-queue-icon.component';
import { BackArrowIconComponent } from './back-arrow-icon/back-arrow-icon.component';
import { CalendarIconComponent } from './calendar-icon/calendar-icon.component';
import { CdIconComponent } from './cd-icon/cd-icon.component';
import { CheckIconComponent } from './check-icon/check-icon.component';
import { ChevronLeftComponent } from './chevron-left/chevron-left.component';
import { ChevronRightComponent } from './chevron-right/chevron-right.component';
import { ClockIconComponent } from './clock-icon/clock-icon.component';
import { CloseIconComponent } from './close-icon/close-icon.component';
import { CopyIconComponent } from './copy-icon/copy-icon.component';
import { DeejayIconComponent } from './deejay-icon/deejay-icon.component';
import { DeleteIconComponent } from './delete-icon/delete-icon.component';
import { EditIconComponent } from './edit-icon/edit-icon.component';
import { EqualizerAnimatedIconComponent } from './equalizer-animated-icon/equalizer-animated-icon.component';
import { EyeIconComponent } from './eye-icon/eye-icon.component';
import { FactorySettingsIconComponent } from './factory-settings-icon/factory-settings-icon.component';
import { ListIconComponent } from './list-icon/list-icon.component';
import { LoadingIconComponent } from './loading-icon/loading-icon.component';
import { MoreVerticalIconComponent } from './more-vertical-icon/more-vertical-icon.component';
import { PlayIconComponent } from './play-icon/play-icon.component';
import { PlaylistsIconComponent } from './playlists-icon/playlists-icon.component';
import { PlusIconComponent } from './plus-icon/plus-icon.component';
import { PreviewIconComponent } from './preview-icon/preview-icon.component';
import { QueueIconComponent } from './queue-icon/queue-icon.component';
import { RefreshIconComponent } from './refresh-icon/refresh-icon.component';
import { SearchIconComponent } from './search-icon/search-icon.component';
import { SettingsIconComponent } from './settings-icon/settings-icon.component';
import { ShuffleIconComponent } from './shuffle-icon/shuffle-icon.component';
import { SimilarIconComponent } from './similar-icon/similar-icon.component';
import { SortIconComponent } from './sort-icon/sort-icon.component';
import { StreamIconComponent } from './stream-icon/stream-icon.component';
import { SuggestionIconComponent } from './suggestion-icon/suggestion-icon.component';
import { ThumbDownIconComponent } from './thumb-down-icon/thumb-down-icon.component';
import { ThumbUpIconComponent } from './thumb-up-icon/thumb-up-icon.component';
import { TunifyIconComponent } from './tunify-icon/tunify-icon.component';
import { ArrowDownIconComponent } from './arrow-down/arrow-down-icon.component';
import { EditArrowRightIconComponent } from './edit-arrow-right-icon/edit-arrow-right-icon.component';
import { EditArrowLeftIconComponent } from './edit-arrow-left-icon/edit-arrow-left-icon.component';
import { PauseIconComponent } from './pause-icon/pause-icon.component';
import { SkipIconComponent } from './skip-icon/skip-icon.component';
import { InfoIconComponent } from '@components/icons/info-icon/info-icon.component';
import { HelpIconComponent } from './help-icon/help-icon.component';
import { MessagesIconComponent } from './messages-icon/messages-icon.component';
import { PrelistenForwardIconComponent } from './prelisten-forward-icon/prelisten-forward-icon.component';
import { PrelistenBackIconComponent } from './prelisten-back-icon/prelisten-back-icon.component';
import { LocationIconComponent } from './location-icon/location-icon.component';
import { RemoteIconComponent } from './remote-icon/remote-icon.component';

@NgModule({
  declarations: [
    CalendarIconComponent,
    StreamIconComponent,
    DeejayIconComponent,
    TunifyIconComponent,
    EqualizerAnimatedIconComponent,
    ListIconComponent,
    SettingsIconComponent,
    CloseIconComponent,
    FactorySettingsIconComponent,
    EqualizerAnimatedIconComponent,
    LoadingIconComponent,
    AddToQueueIconComponent,
    SortIconComponent,
    MoreVerticalIconComponent,
    RefreshIconComponent,
    PlaylistsIconComponent,
    ShuffleIconComponent,
    SearchIconComponent,
    EyeIconComponent,
    PlayIconComponent,
    PreviewIconComponent,
    SimilarIconComponent,
    AddToListIconComponent,
    DeleteIconComponent,
    BackArrowIconComponent,
    ThumbUpIconComponent,
    ThumbDownIconComponent,
    QueueIconComponent,
    EditIconComponent,
    CopyIconComponent,
    PlusIconComponent,
    ClockIconComponent,
    ChevronLeftComponent,
    ChevronRightComponent,
    AccountIconComponent,
    CdIconComponent,
    SuggestionIconComponent,
    CheckIconComponent,
    ArrowDownIconComponent,
    EditArrowRightIconComponent,
    EditArrowLeftIconComponent,
    PauseIconComponent,
    SkipIconComponent,
		QueueIconComponent,
		InfoIconComponent,
		HelpIconComponent,
		MessagesIconComponent,
		PrelistenForwardIconComponent,
		PrelistenBackIconComponent,
		LocationIconComponent,
    RemoteIconComponent
  ],
  imports: [CommonModule],
  exports: [
    CalendarIconComponent,
    StreamIconComponent,
    DeejayIconComponent,
    TunifyIconComponent,
    EqualizerAnimatedIconComponent,
    ListIconComponent,
    SettingsIconComponent,
    CloseIconComponent,
    FactorySettingsIconComponent,
    EqualizerAnimatedIconComponent,
    LoadingIconComponent,
    AddToQueueIconComponent,
    SortIconComponent,
    MoreVerticalIconComponent,
    RefreshIconComponent,
    PlaylistsIconComponent,
    ShuffleIconComponent,
    SearchIconComponent,
    EyeIconComponent,
    PlayIconComponent,
    PreviewIconComponent,
    SimilarIconComponent,
    AddToListIconComponent,
    DeleteIconComponent,
    BackArrowIconComponent,
    ThumbUpIconComponent,
    ThumbDownIconComponent,
    EditIconComponent,
    CopyIconComponent,
    PlusIconComponent,
    ClockIconComponent,
    ChevronLeftComponent,
    ChevronRightComponent,
    AccountIconComponent,
    CdIconComponent,
    SuggestionIconComponent,
    CheckIconComponent,
    ArrowDownIconComponent,
    EditArrowRightIconComponent,
    EditArrowLeftIconComponent,
    PauseIconComponent,
		SkipIconComponent,
		QueueIconComponent,
    InfoIconComponent,
    HelpIconComponent,
    MessagesIconComponent,
    PrelistenForwardIconComponent,
    PrelistenBackIconComponent,
    LocationIconComponent,
    RemoteIconComponent
  ],
})
export class IconsModule {}
