import { Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '../loggers/logger.service';
import { ZoneConfigurationService } from './zone-configuration.service';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { ConfigApiService, DTO_configStatus } from '@service/api/config-api.service';
import { Config } from '@service/config';

export enum AutocompletionType{
  Song = "song",
  Artist = "artist"
}

export class AutocompletionObject{
  type: AutocompletionType;
  text: string;
  autocompletionText: string;
  rank: number;

  toString(){
    return this.autocompletionText + "(" + this.type + " - " + this.rank + ")";
  }
}

export class AutocompletionResult{
  text: string;
  suggestions: AutocompletionObject[];

  toString(){
    return "results for " + this.text + ": " + this.suggestions.toString();
  }
}

@Injectable({
  providedIn: 'root'
})
export class AutocompletionService implements OnDestroy {

  private LOGGER_CLASSNAME = AutocompletionService.name;

  private MAX_AMOUNT_ARTISTS = 3;
  private MAX_AMOUNT_SONGS = 5;

  private readonly KEY_AUTOCOMPLETION_URL = "AUTOCOMPLETION7_URL";
  private readonly KEY_AUTOCOMPLETION_USERNAME = "AUTOCOMPLETION_CRED_USERNAME";
  private readonly KEY_AUTOCOMPLETION_PASSWORD = "AUTOCOMPLETION_CRED_PASSWORD";


  constructor(
    private loggerService: LoggerService,
    private httpClient: HttpClient,
    private zoneConfigurationService: ZoneConfigurationService,
    private configApiService: ConfigApiService
  ){
    this.zoneConfigurationService.royaltyFree$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      (value) => {
        //we only support autocompletion for commercial licenced music
        this.autocompletionCanBeEnabledForRoyaltyFree = !value;
        this.adjustAutoCompletionEnabled();
      }
    );

    this.zoneConfigurationService.region$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      () => {
        this.adjustAutoCompletionEnabled();
      }
    );

    this.fetchAutocompletionProperties();
  }

  private autocompletionCanBeEnabledForRoyaltyFree = false;

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next(null);
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private autocompletionUrl:string = "";
  private autocompletionCredentialsUsername: string = "";
  private autocompletionCredentialsPassword: string = "";
  private autocompletionAuthentication: string = "";


  private loadingPublicConfigs = false;
  private errorLoadingPublicConfigs = false;
  private amountOfErrors = 0;
  private fetchAutocompletionProperties(){
    if (!this.loadingPublicConfigs){
      const autocompletionConfigObservable = this.configApiService.loadPublicConfigs([this.KEY_AUTOCOMPLETION_URL, this.KEY_AUTOCOMPLETION_USERNAME, this.KEY_AUTOCOMPLETION_PASSWORD]);
      if (autocompletionConfigObservable){
        this.loadingPublicConfigs = true;

        autocompletionConfigObservable
        .pipe(
          finalize(() => {
            this.loadingPublicConfigs = false;
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          values => {
            let hasError = false;
            values.forEach((configValue) => {
              if (configValue.status == DTO_configStatus.OK){
                switch(configValue.key){
                  case this.KEY_AUTOCOMPLETION_URL:
                    this.autocompletionUrl = configValue.value;
                  break;
                  case this.KEY_AUTOCOMPLETION_USERNAME:
                    this.autocompletionCredentialsUsername = configValue.value;
                  break;
                  case this.KEY_AUTOCOMPLETION_PASSWORD:
                    this.autocompletionCredentialsPassword = configValue.value;
                  break;
                }
              }else{
                this.loggerService.error(this.LOGGER_CLASSNAME, "fetchAutocompletionProperties", "received status " + configValue.status + " for key " + configValue.key);
                hasError = true;
              }
            });

            if (hasError){
              this.handleLoadingConfigValuesError();
            }else{
              this.errorLoadingPublicConfigs = false;
            }

            this.adjustAutoCompletionEnabled();

          }
        ),
        (error: unknown) => {
          let errMsg = '';
          if (error instanceof HttpErrorResponse){
            errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';

          }else if (error instanceof Error){
            errMsg = error.message;
          }else{
            errMsg = 'unknown error type: ' + error;
          }

          this.loggerService.error(this.LOGGER_CLASSNAME, "fetchAutocompletionProperties", "Error occured: " + errMsg);
          this.handleLoadingConfigValuesError();
        }
      }
    }
  }

  private handleLoadingConfigValuesError(){
    this.amountOfErrors++;
    this.errorLoadingPublicConfigs = true;

    //this wil reload the public configs after a short waiting period
    this.retryLoadingConfigValues();
  }

  private retryTimeout: NodeJS.Timeout;
  private retryLoadingConfigValues(){
    const delay = Math.min(Config.RETRY_MAX_INTERVAL_MS, Math.pow(2, this.amountOfErrors - 1) * Config.RETRY_BASE_INTERVAL_MS);
      this.loggerService.debug(this.LOGGER_CLASSNAME, "retryLoadingConfigValues", "Going to wait " + delay + "milliseconds");
      this.retryTimeout = setTimeout(() => {
        this.loggerService.debug(this.LOGGER_CLASSNAME, "retryLoadingConfigValues", "retryTimeout fired. Going to load config values");
        this.fetchAutocompletionProperties();
      }, delay);
  }


  private _autocompletionEnabled = false;

  public get autocompletionEnabled(): boolean {
    return this._autocompletionEnabled;
  }

  private set autocompletionEnabled(value: boolean) {
    this._autocompletionEnabled = value;
  }

  private adjustAutoCompletionEnabled(){
    //todo -> enable autocompletion after fetching of the autocompletion properties are successsfull
    this.autocompletionEnabled =
      this.autocompletionCanBeEnabledForRoyaltyFree &&
      this.zoneConfigurationService.region != null && this.zoneConfigurationService.region.code != null &&
      this.autocompletionUrl != "" &&
      this.autocompletionCredentialsUsername != "" &&
      this.autocompletionCredentialsPassword != "";

    if (this.autocompletionEnabled){
      this.autocompletionAuthentication = "Basic " + btoa(this.autocompletionCredentialsUsername + ":" + this.autocompletionCredentialsPassword);
    }
  }

  //when autocompletion is no longer needed (search starts, input is cleared)
  public cancelPrevious(){
    this.cancelAutocompletionSubject.next(false);
  }

  //observable that cancels any previous autocompletion http request
  //the boolean indicates if the cancel is form a new request (true) or from a cancel (false)
  private cancelAutocompletionSubject: Subject<boolean> = new Subject<boolean>();

  //only the direct invoker of this function is able to listen to the autocompletion results (it is not part of the application state)
  // => we can just map the http observable and return that one
  public autoComplete(text: string): Observable<AutocompletionResult>{
    if (this.autocompletionEnabled){
      let suggestApi = {
        _source: ["song-autocompletion-output", "artist-autocompletion-output"],
        suggest: {
          songs: {
            completion: {
              field: "song-autocompletion",
              size: this.MAX_AMOUNT_SONGS * 3, //to get good results, ask 3 times the required amount and only parse the first MAX_AMOUNT
              contexts: {
                r: ["w", this.zoneConfigurationService.region.code]
              }
            }
          },
          artists: {
            completion: {
              field: "artist-autocompletion",
              size: this.MAX_AMOUNT_ARTISTS * 3, //to get good results, ask 3 times the required amount and only parse the first MAX_AMOUNT
              contexts: {
                r: ["w", this.zoneConfigurationService.region.code]
              }
            }
          },
          text: text
        }
      };

      const headers = new HttpHeaders({"Authorization": this.autocompletionAuthentication});

      this.cancelAutocompletionSubject.next(true);

      let autocompletionObservable = this.httpClient.post(this.autocompletionUrl, suggestApi, {headers: headers});

      let parsedAutocompletionObservable = autocompletionObservable.pipe(
        map(data => this.parseAutocompletionResult(text, data)),
        takeUntil(this.cancelAutocompletionSubject)
      );

      return parsedAutocompletionObservable;
    }

    //no autocompletion -> return empty result?

    return null;
  }

  parseAutocompletionResult(searchText:string, autocompletionData: any):AutocompletionResult{
    this.loggerService.debug(this.LOGGER_CLASSNAME, "autoComplete response", "data: " + autocompletionData);

    //parse the elasticsearch result to a readable format

    let autocompletionObjects: AutocompletionObject[] = [];

    if (Object.prototype.hasOwnProperty.call(autocompletionData, "suggest")){
      let suggestResult = autocompletionData["suggest"];
      let autocompletionRank = 0;


      //parse artists
      let artistAutocompletionObjects =  this.parseAutocompletionObjects(suggestResult, "artists", "artist-autocompletion-output", AutocompletionType.Artist, this.MAX_AMOUNT_ARTISTS);
      artistAutocompletionObjects.forEach(
        (autocompletionObject) => {
          autocompletionObject.rank = autocompletionRank++;
          autocompletionObjects.push(autocompletionObject);
        }
      );

      //parse songs
      let songAutocompletionObjects =  this.parseAutocompletionObjects(suggestResult, "songs", "song-autocompletion-output", AutocompletionType.Song, this.MAX_AMOUNT_SONGS);
      songAutocompletionObjects.forEach(
        (autocompletionObject) => {
          autocompletionObject.rank = autocompletionRank++;
          autocompletionObjects.push(autocompletionObject);
        }
      );
    }

    let autocompletionResult = new AutocompletionResult();
    autocompletionResult.suggestions = autocompletionObjects;
    autocompletionResult.text = searchText;

    return autocompletionResult;
  }


  private parseAutocompletionObjects(suggestionsResult: any,  autocompletionField: string,  autocompletionTextField: string, type: AutocompletionType, maxAmount: number): AutocompletionObject[]{
    let autocompletionObjects: AutocompletionObject[] = [];
    if ( Object.prototype.hasOwnProperty.call(suggestionsResult, autocompletionField) && suggestionsResult[autocompletionField] instanceof Array){
      let autocompletionresults = suggestionsResult[autocompletionField][0];
      let autocompletionText = autocompletionresults.text;
      if (Object.prototype.hasOwnProperty.call(autocompletionresults, "options") && autocompletionresults["options"] instanceof Array){
        let autocompletionOptions: Array<any> = autocompletionresults["options"];
        for (let i = 0; i < Math.min(maxAmount, autocompletionOptions.length); i++){
          let autocompletionOption = autocompletionOptions[i];
          let autocompletionObject = new AutocompletionObject();
          autocompletionObject.text = autocompletionText;
          autocompletionObject.type = type;
          autocompletionObject.autocompletionText = autocompletionOption["_source"][autocompletionTextField];
          autocompletionObjects.push(autocompletionObject);
        }
      }
    }
    return autocompletionObjects;
  }
}
