import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
@NgModule({
    imports: [
        RouterModule.forRoot([
        ], { preloadingStrategy: PreloadAllModules })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
