
import { NgZone, Renderer2, OnDestroy } from '@angular/core';
import {BehaviorSubject, Subject, Observable, Subscription, timer, merge} from 'rxjs';
import {AudioTagService} from './audio.tag.service';
import {AudioTagWrapper} from './audioTagWrapper';
import { LiteEvent } from '@util/lite.event';
import { Song } from '@model/song';
import { Config } from '@service/config';
import { LoggerService } from '@service/loggers/logger.service';
import { Util } from '@util/util';
import { PlayState } from '@model/enums/playState.enum';
import { AudiofileUrlService, MediaUrlOrigin } from '@service/audiofile-url.service';
import { environment } from 'src/environments/environment';
import { takeUntil, finalize, filter } from 'rxjs/operators';
import { PlayableAudio } from '../../model/audioFile';
import { MediaUrl } from '../data/audiofile-url.service';
import { ValidatorFn } from '@angular/forms';


export class AudioFileWithPlayInfo {

  private LOGGER_CLASSNAME = 'AudioFileWithPlayInfo';

  public static get FADEOUT_PERCENTAGE_EARLY_FADEBEGINPOSITION():number {return 0.25} //short, because we are going to let the audio perform a small part of its own fadeout
  public static get FADEOUT_PERCENTAGE_DEFAULT():number {return 0.35}
  public static get FADEOUT_PERCENTAGE_NO_OR_LATE_FADEBEGINPOSITION():number {return 0.5}
  public static get FADEOUT_PERCENTAGE_NO_CROSSFADE():number {return 0.6}

  /**
   * Events
   */
  private readonly onAudioReachedEnd = new LiteEvent<void>();
  public get audioReachedEnd() {
    return this.onAudioReachedEnd.expose();
  }

  private readonly onAudioFullyLoaded = new LiteEvent<void>();
  public get audioFullyLoaded() {
    return this.onAudioFullyLoaded.expose();
  }

  private readonly onAudioReachedFadeOutPosition= new LiteEvent<void>();
  public get audioReachedFadeOutPosition() {
    return this.onAudioReachedFadeOutPosition.expose();
  }

  private readonly onSeekDone= new LiteEvent<void>();
  public get seekDone() {
    return this.onSeekDone.expose();
  }

  //this event emits when we detect we are reloading all urls over and over
  private readonly onAudioFileLoadingError= new LiteEvent<void>();
  public get audioFileLoadingError() {
    return this.onAudioFileLoadingError.expose();
  }

  //this event emits when we all urls became invalid
  private readonly onMediaUrlsAreInvalid= new LiteEvent<void>();
  public get mediaUrlsAreInvalid() {
    return this.onMediaUrlsAreInvalid.expose();
  }


  /**
   * Properties
   */

  public remainingSeconds():number{
    let endOfAudio = this.endAudioSignalAccordingToAudioFile;
    if (this.startFadeOutPosition > 0){
      endOfAudio = this.startFadeOutPosition;
    }
    return Math.max(endOfAudio - this.currentTime, 0);
  }

  private __fadeOutPositionReached = false;
  private previousEmitAt : number = null
  private set _fadeOutPositionReached (value) {
    if (this.__fadeOutPositionReached !== value) {
      this.__fadeOutPositionReached = value;
      //reset emit timer when the value changes
      this.previousEmitAt = null;
      this.logger.debug( this.LOGGER_CLASSNAME, '_fadeOutPositionReached setter', 'fade position reached: ' + this.__fadeOutPositionReached);

    }



    if (this.fadeOutPositionReached && (this.previousEmitAt == null || Math.abs(this.previousEmitAt - this.currentTime) > 1)){
      this.previousEmitAt = this.currentTime;

      if (this.audioFile && !this.audioFile.canCrossFade){
        this.logger.debug( this.LOGGER_CLASSNAME, '_fadeOutPositionReached setter', 'AudioFile can NOT crossFade: going to emit onAudioReachedEnd event');
        this._ngZone.run(() => {
          //No crossFade -> emit end instead of fadeoutposition
          this.onAudioReachedEnd.trigger();
        });

      }else{
        this.logger.debug( this.LOGGER_CLASSNAME, '_fadeOutPositionReached setter', 'AudioFile can crossFade: going to emit onAudioReachedFadeOutPosition event');
        this._ngZone.run(() => {
          this.onAudioReachedFadeOutPosition.trigger();
        });
      }
    }
  }
  private get _fadeOutPositionReached(): boolean {
    return this.__fadeOutPositionReached;
  }
  public get fadeOutPositionReached(): boolean {
    return this.__fadeOutPositionReached;
  }


  private __buffering = false;
  private set _buffering (value) {
    if (this.__buffering !== value) {
      this.__buffering = value;
      this.logger.debug( this.LOGGER_CLASSNAME, '_buffering setter', this.audioFile.toString() + ' buffering changed: ' + this.__buffering);
    }
  }
  private get _buffering(): boolean {
    return this.__buffering;
  }
  public get buffering(): boolean {
    return this.__buffering;
  }


  private __suspended = false;
  private set _suspended (value) {
    if (this.__suspended !== value) {
      this.__suspended = value;
      this.logger.debug( this.LOGGER_CLASSNAME, '_suspended setter', this.audioFile.toString() + ' suspended changed: ' + this.__suspended);

      this.loadingData = !this.suspended;
    }
  }
  private get _suspended(): boolean {
    return this.__suspended;
  }
  public get suspended(): boolean {
    return this.__suspended;
  }


  private set _needClickToUseAudioTag (value) {
    if (this._needClickToUseAudioTag !== value) {
      this._needClickToUseAudioTagSubject.next(value);
      this.logger.debug( this.LOGGER_CLASSNAME, '_needClickToUseAudioTag setter', this.audioFile.toString() + ' needClickToUseAudioTag changed: ' + this._needClickToUseAudioTag);
    }
  }
  private get _needClickToUseAudioTag(): boolean {
    return this._needClickToUseAudioTagSubject.value;
  }
  public get needClickToUseAudioTag(): boolean {
    return this._needClickToUseAudioTag;
  }

  private _needClickToUseAudioTagSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public needClickToUseAudioTag$: Observable<boolean> = this._needClickToUseAudioTagSubject.asObservable();

  public  = false;

  /**
   * Fadeout & final stop position calculations
   */
  private _startFadeOutPosition = 0.0;
  public get startFadeOutPosition(): number{
    return this._startFadeOutPosition;
  }

  private endAudioSignalAccordingToAudioFile = 0.0; //the point where the audio has decreased to a non-hearable volume
  public get endAudioSignal(): number{
    return this.endAudioSignalAccordingToAudioFile;
  }

  //All in seconds
  private startFadeOutPositionAccordingToAudioFile = 0.0; //the point where the audio starts to decrease its volume

  private finalStopPositionAccordingToAudioFile = 0.0; //the point where the track completely stops
  private finalStopPosition = 0.0;
  private _fadePercentageBeforeNextOnAutoStart = AudioFileWithPlayInfo.FADEOUT_PERCENTAGE_DEFAULT; //how far this track should fade-out before starting the next track
  public get fadePercentageBeforeNextOnAutoStart(){ return this._fadePercentageBeforeNextOnAutoStart}
  private calculateFadeOutPosition() {

    this.startFadeOutPositionAccordingToAudioFile = 0.0;
    this.endAudioSignalAccordingToAudioFile = 0.0;
    this.finalStopPositionAccordingToAudioFile = 0.0;



    if (this.audioFile) {

      this.finalStopPositionAccordingToAudioFile = this.audioFile.duration / 1000;
      this.endAudioSignalAccordingToAudioFile = this.finalStopPositionAccordingToAudioFile;

      if (this.audioFile.canCrossFade){
        if (this.audioFile instanceof Song){

          this.logger.debug(this.LOGGER_CLASSNAME, 'calculateFadeOutPosition', this.audioFile + ': fadeBeginPosition: ' + this.audioFile.fadeBeginPosition + ', endAudioSignal: ' + this.audioFile.endAudioSignal + ', duration: ' + this.audioFile.duration);

          if (this.audioFile.endAudioSignal > 0 && this.audioFile.endAudioSignal / 1000 < this.finalStopPositionAccordingToAudioFile){
            this.endAudioSignalAccordingToAudioFile = this.audioFile.endAudioSignal / 1000;
          }


          //valid fadeout position -> must be at least MIN FADEOUT before the end of the audio signal
          if ( this.audioFile.fadeBeginPosition > 0 && this.audioFile.fadeBeginPosition / 1000 <= this.endAudioSignalAccordingToAudioFile - Config.MIN_FADEDURATION_MILLISECONDS / 1000){


            //Useable fadeBeginPosition -> start fadeout even earlier
            const startFadeOutPosition = this.audioFile.fadeBeginPosition / 1000;

             //Assuming the audio will fade out lineair to the endAudioSignal, we will keep on playing until we have decreased 25% volume in the track itself
             const totalFadeDurationInAudio = this.endAudioSignalAccordingToAudioFile - startFadeOutPosition;

             //add extra playtime
             const extraPlaytimeAfterAudioFadeoutPoint = Math.min(0.2 * totalFadeDurationInAudio, 3.0);

             this.startFadeOutPositionAccordingToAudioFile = startFadeOutPosition + extraPlaytimeAfterAudioFadeoutPoint;

             this.logger.debug(this.LOGGER_CLASSNAME, 'calculateFadeOutPosition', this.audioFile + ': Going to fadeout at later point: ' + this.startFadeOutPositionAccordingToAudioFile + ' (fadeBeginPosition: ' + this.audioFile.fadeBeginPosition + ')');

             this._fadePercentageBeforeNextOnAutoStart = AudioFileWithPlayInfo.FADEOUT_PERCENTAGE_EARLY_FADEBEGINPOSITION;
          } else if (this.endAudioSignalAccordingToAudioFile > Config.MAX_FADEDURATION_MILLISECONDS / 1000) {
              this.startFadeOutPositionAccordingToAudioFile = this.endAudioSignalAccordingToAudioFile - Config.MAX_FADEDURATION_MILLISECONDS / 1000;
              this._fadePercentageBeforeNextOnAutoStart = AudioFileWithPlayInfo.FADEOUT_PERCENTAGE_NO_OR_LATE_FADEBEGINPOSITION;
          } else if (this.endAudioSignalAccordingToAudioFile > Config.MIN_FADEDURATION_MILLISECONDS / 1000) {
              this.startFadeOutPositionAccordingToAudioFile = this.endAudioSignalAccordingToAudioFile - Config.MIN_FADEDURATION_MILLISECONDS / 1000;
              this._fadePercentageBeforeNextOnAutoStart = AudioFileWithPlayInfo.FADEOUT_PERCENTAGE_NO_OR_LATE_FADEBEGINPOSITION
          }
        }else{
          this.logger.error(this.LOGGER_CLASSNAME, 'calculateFadeOutPosition', 'We should crossFade but we do not know any logic to calculate the start fade position -> going to use defaults for ' + this.audioFile);
          if (this.audioFile.duration > 0) {
            this.startFadeOutPositionAccordingToAudioFile = (this.audioFile.duration - Config.MAX_FADEDURATION_MILLISECONDS) / 1000;
          }
          this._fadePercentageBeforeNextOnAutoStart = AudioFileWithPlayInfo.FADEOUT_PERCENTAGE_DEFAULT
        }
      }else{
        //no crossFade -> play to end
        this.startFadeOutPositionAccordingToAudioFile = this.audioFile.duration / 1000;
        this._fadePercentageBeforeNextOnAutoStart = AudioFileWithPlayInfo.FADEOUT_PERCENTAGE_NO_CROSSFADE
      }
    }

    this.finalStopPosition = this.finalStopPositionAccordingToAudioFile;
    this._startFadeOutPosition = this.startFadeOutPositionAccordingToAudioFile;

    this.logger.debug(this.LOGGER_CLASSNAME, 'calculateFadeOutPosition', this.audioFile + ': start fadeout: ' + this._startFadeOutPosition + ' | end audio signal: ' + this.endAudioSignalAccordingToAudioFile + ' | duration: ' + this.audioFile.duration);
  }

  private _audioFile: PlayableAudio;
  private audioFileChangesSubject = new Subject<void>();
  public set audioFile(value: PlayableAudio) {
    if (this._audioFile !== value) {

      //only reset things if there was actually a different audioFile (on id)
      let updateAfterSet = false;
      if (this._audioFile == null || value == null || this._audioFile.id != value.id){
        updateAfterSet = true;
        //this cancels loading of the urls..
        this.audioFileChangesSubject.next();

        if (this._audioFile != null){
          this.logger.warn(this.LOGGER_CLASSNAME, 'set audioFile', 'Going to change audioFile to ' + (value != null ? value : 'null') + ' (previous value: ' + this._audioFile + ')');
        }

      }
      this._audioFile = value;

      if (updateAfterSet){
        this._duration = this._audioFile.duration / 1000;

        //as soon as we ready this audioFile -> try to set to the correct time for a normal start
        if (this.audioFile && this.audioFile instanceof Song && this.audioFile.startAudioSignal > 0) {
          this.lastSeekToTime = this.audioFile.startAudioSignal / 1000;
        }
        if (this.audio) {
          this.audio.pause();
          this.audio.currentTime = 0;
          //change src gives cracking sound in some chrome browsers
          //this.audio.removeAttribute('src');
          this.audio.volume = 1;
        }
        this._playState = PlayState.STOPPED;
        this._isReadyToPlay = false;
        this._isFullyLoaded = false;
        this.loading = false;
        this.httpUrls = null;
        this.publicUrlAdded = false;

        this.calculateFadeOutPosition();
        this._fadeOutPositionReached = false;
      }
    }
  }
  public get audioFile(): PlayableAudio {
    return this._audioFile;
  }

  private httpUrls : MediaUrl[] = null;


  public audioTagWrapper: AudioTagWrapper;

  private get audio(): HTMLAudioElement {
    if (this.audioTagWrapper) {
      return this.audioTagWrapper.audioElement;
    }
    return null;
  }

  private __isReadyToPlay = false;
  private set _isReadyToPlay (value) {
    if (this.__isReadyToPlay !== value) {
      this.logger.debug(this.LOGGER_CLASSNAME, '_isReadyToPlay', 'isReady changing to ' + value);
      this.__isReadyToPlay = value;
      if (this.isReadyToPlay) {

        if (this.lastSeekToTime > 0){
          this.logger.debug(this.LOGGER_CLASSNAME, "set _isReadyToPlay", this.logStringForAudioTag() + ": Going to seek to " + this.lastSeekToTime);
          this.seekToTime(this.lastSeekToTime);
        }

        if (this.playState === PlayState.STARTING_TO_PLAY) {
          this.audioTagWrapper.adjustVolume(1);
          if (this.useAudioTag) {
            this.startAudioTagPlayback();
          } else if (this.useXMLHttpRequest) {
            this.playAudioBufferSource();
          }
        }
      }
      this._isReadyToPlaySubject.next(this.isReadyToPlay);
    }
  }
  private get _isReadyToPlay(): boolean {
    return this.__isReadyToPlay;
  }
  public get isReadyToPlay(): boolean {
    return this._isReadyToPlay;
  }

  private _isReadyToPlaySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isReadyToPlay$: Observable<boolean> = this._isReadyToPlaySubject.asObservable();


  private set _isFullyLoaded (value) {
    if (this._isFullyLoaded !== value) {
      //go back to angular zone
      this._ngZone.run(() => {
        if (value) {
          //must be before changing value (before anything listens to it)
          this.loading = false;
          this.loadingFromMediaUrl = null;

          //once fully loaded -> clear validity timers
          this.stopValidityTimer();
        }
        this._isFullyLoadedSubject.next(value);

        this.loadingData = false;
        if (value) {
          //must be after changing value
          this.onAudioFullyLoaded.trigger();
        }
      });
    }
  }
  private get _isFullyLoaded(): boolean {
    return this._isFullyLoadedSubject.value;
  }
  public get isFullyLoaded(): boolean {
    return this._isFullyLoaded;
  }

  private _isFullyLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isFullyLoaded$: Observable<boolean> = this._isFullyLoadedSubject.asObservable();

  /**
   * Playstate of the audioFile
   * @type {PlayState}
   * @private
   */
  private get _playState(): PlayState {
    return this._playStateSubject.value;
  }
  private set _playState(value: PlayState) {
    if (this._playState !== value) {
      let tagId = -1;
      if (this.audioTagWrapper) {
        tagId = this.audioTagWrapper.audioTagWrapperId;
      }
      this.logger.debug( this.LOGGER_CLASSNAME, '_playState setter', 'audioFileWithPlayinfo: ' + tagId + ' -> playstate changed: ' + value);
      this._ngZone.run(()=>{
        this._playStateSubject.next(value);
      });
    }
  }
  public get playState(): PlayState {
    return this._playState;
  }
  private _playStateSubject: BehaviorSubject<PlayState> = new BehaviorSubject<PlayState>(null);
  public playerState$: Observable<PlayState> = this._playStateSubject.asObservable();


  /**
   * current time of the audioFile
   * @type {number}
   * @private
   */
  private __currentTime: number = null;
  private get _currentTime(): number {
    return this.__currentTime;
  }
  private set _currentTime(value: number) {
    if (this.__currentTime !== value) {
      this.__currentTime = value;
      this._currentTimeSubject.next(this.__currentTime);

      //this.logger.debug(this.LOGGER_CLASSNAME, "setCurrentTime", "currenTime: " + this._currentTime);

      this._fadeOutPositionReached = this.currentTime >= this.startFadeOutPosition;

    }
  }
  public get currentTime(): number {
    return this._currentTime;
  }
  private _currentTimeSubject: Subject<number> = new BehaviorSubject<number>(0);
  public currentTime$: Observable<number> = this._currentTimeSubject.asObservable();


  /**
   * duration of the audioFile
   * @type {number}
   * @private
   */
  private __duration: number = null;
  private get _duration(): number {
    return this.__duration;
  }
  private set _duration(value: number) {
    if (this.__duration !== value) {
      this.__duration = value;
      this._durationSubject.next(this.__duration);
    }
  }
  public get duration(): number {
    return this._duration;
  }
  private _durationSubject: Subject<number> = new BehaviorSubject<number>(0);
  public duration$: Observable<number> = this._durationSubject.asObservable();

  constructor(private audioFileUrlService: AudiofileUrlService,
              private renderer: Renderer2,
              private audioTagService: AudioTagService,
              private logger: LoggerService,
              private _ngZone: NgZone) {
    this._playState = PlayState.STOPPED;
  }



  public startPrepare() {

    this.loadMediaUrlsIfNeeded();
    if (!this.publicUrlAdded){
      if (this.loadingMediaUrls){
        this.delayedUsePublicUrlIfNeeded();
      }else if (!this.mediaUrlsLoaded){
        this.usePublicUrl();
      }
    }

    if (this.httpUrls != null && this.httpUrls.length > 0 && !this.loading){
      this.startBuffering();
    }
  }

  private get loadingMediaUrls(): boolean{
    return this.httpUrlSubscription != null;
  }

  private get mediaUrlsLoaded(): boolean{
    return this.httpUrls != null && this.httpUrls.filter(mediaUrl => mediaUrl.origin == MediaUrlOrigin.dto).length > 0;
  }

  private httpUrlSubscription = null;
  private loadMediaUrlsIfNeeded(){
    if (this.audioFile && this.audioFile.canLoadMediaUrls && !this.mediaUrlsLoaded && !this.loadingMediaUrls){
      this.logger.debug( this.LOGGER_CLASSNAME, 'loadMediaUrlsIfNeeded',  'starting to load media urls for ' + this.audioFile.toString());
      this.httpUrlSubscription = this.audioFileUrlService.loadAudioFileUrls(this.audioFile)
        .pipe(
          takeUntil(
            merge(
              this.destroy$,
              this.audioFileChangesSubject
            )
          ),
          finalize(() => {
            if (this.httpUrlSubscription != null){
              this.httpUrlSubscription.unsubscribe();
              this.httpUrlSubscription = null;
            }
          })
        )
        .subscribe(
          (data) => {
            if (data && data.length > 0) {
              this.clearDelayedUsePublicUrlCallback();

              this.logger.debug(this.LOGGER_CLASSNAME, 'loadMediaUrlsIfNeeded', 'received mediaUrls: ' + Util.formatObjectArray(data));


              if (this.httpUrls == null){
                this.httpUrls = [];
              }
              data.forEach(mediaUrl => this.httpUrls.push(mediaUrl));
              this.startBuffering();
            } else {
              this.logger.error( this.LOGGER_CLASSNAME, 'loadMediaUrlsIfNeeded',  'no media url received for ' + this.audioFile.toString());
            }
          });
    } else {
        this.logger.debug( this.LOGGER_CLASSNAME, 'loadMediaUrlsIfNeeded', 'Not going to load mediaUrls: ' + (this.audioFile == null ? 'no audioFile' : (this.audioFile + ': canLoadMediaUrls: ' + (this.audioFile.canLoadMediaUrls ? 'yes' : 'no'))) + (this.mediaUrlsLoaded ?  ' --already loaded mediaUrls-- ' : ' --not yet loaded mediaUrls-- ') + (this.loadingMediaUrls ? ' --curently loading mediaUrls--' : ' -- not loading mediaUrls--'));
    }
  }

  private delayedUsePublicUrlCallback = null;
  private delayForPublicUrl = 3000;
  private delayedUsePublicUrlIfNeeded() {
    if (this.playState === PlayState.STARTING_TO_PLAY && this.delayedUsePublicUrlCallback === null) {
      this.delayedUsePublicUrlCallback = setTimeout(() => {
        this.delayedUsePublicUrlCallback = null;
        this.usePublicUrl();
      }, this.delayForPublicUrl);
    }
  }

  private clearDelayedUsePublicUrlCallback() {
    if (this.delayedUsePublicUrlCallback) {
      clearTimeout(this.delayedUsePublicUrlCallback);
      this.delayedUsePublicUrlCallback = null;
    }
  }

  private publicUrlAdded = false;
  private usePublicUrl() {
    if (this.audioFile) {
      if (!this.publicUrlAdded){

        const publicMediaUrl = new MediaUrl();
        publicMediaUrl.url = this.audioFile.publicUrl;
        publicMediaUrl.origin = MediaUrlOrigin.public;
        if (publicMediaUrl.url != null){
          this.logger.debug(this.LOGGER_CLASSNAME, 'usePublicUrl', 'Going to add public url: ' + publicMediaUrl.url);

          if (this.httpUrls == null) {
            this.httpUrls = [];
          }
          this.httpUrls.push(publicMediaUrl);
          this.publicUrlAdded = true;
          this.startBuffering();
        }else{
          this.logger.error(this.LOGGER_CLASSNAME, 'usePublicUrl', 'We should be using the public url, but there is none');
        }
      }else{
        this.logger.debug(this.LOGGER_CLASSNAME, 'usePublicUrl', 'Already added a public url');
      }
    }else{
      this.logger.error(this.LOGGER_CLASSNAME, 'usePublicUrl', 'We should be using the public url, but the audioFile is null');
    }
  }


  /**
   * use the audio tag to load the audio mp3
   */

  private loadedMetadataHandler: Function;
  private progressHandler: Function;
  private errorHandler: Function;
  private suspendHandler: Function;
  private stalledHandler: Function;
  private durationChangeHandler: Function;
  private canPlayHandler: Function;
  private waitingHandler: Function;
  private playingHandler: Function;
  private timeUpdateListener: Function;
  private playListener: Function;
  private pauseListener: Function;
  private endListener: Function;

  private _useAudioTag = false;
  private set useAudioTag(value) {
    if (this._useAudioTag !== value) {
      this._useAudioTag = value;

      if (this._useAudioTag) {
        this.useXMLHttpRequest = false;
        this.logger.info( this.LOGGER_CLASSNAME, 'useAudioTag setter', 'adding handlers to audio Tag. ' + this.audioTagWrapper.toString());

        this.loadedMetadataHandler = this.renderer.listen(this.audio, 'loadedmetadata', (event: Event) => { this.loadedMetadata(); });
        this.errorHandler = this.renderer.listen(this.audio, 'error', (event: Event) => { this.onError(event); });
        this.suspendHandler = this.renderer.listen(this.audio, 'suspend', (event: Event) => { this.onSuspend(); });
        this.stalledHandler = this.renderer.listen(this.audio, 'stalled', (event: Event) => { this.onStalled(); });

        this.durationChangeHandler = this.renderer.listen(this.audio, 'durationchange', (event: Event) => { this.onDurationChange(); });
        this.canPlayHandler = this.renderer.listen(this.audio, 'canplay', (event: Event) => { this.onCanPlay(); });

        this.waitingHandler = this.renderer.listen(this.audio, 'waiting', (event: Event) => { this.onWaiting(); });
        this.playingHandler = this.renderer.listen(this.audio, 'playing', (event: Event) => { this.onPlaying(); });
        this.playListener = this.renderer.listen(this.audio, 'play', (event: Event) => this.onPlay());
        this.pauseListener = this.renderer.listen(this.audio, 'pause', (event: Event) => this.onPause());
        this.endListener = this.renderer.listen(this.audio, 'ended', (event: Event) => this.onAudioEnded());

        // create handlers outside angular
        this._ngZone.runOutsideAngular(() => {
          this.progressHandler = this.renderer.listen(this.audio, 'progress', (event: Event) => { this.onProgress(); });
          this.timeUpdateListener = this.renderer.listen(this.audio, 'timeupdate', (event: Event) => this.onTimeUpdate());
        });

      }else {
        // unlisten
        this.logger.info( this.LOGGER_CLASSNAME, 'useAudioTag setter', 'cleaning up handlers from audio Tag. ' + this.audioTagWrapper.toString());
        this.loadedMetadataHandler();
        this.loadedMetadataHandler = null;
        this.progressHandler();
        this.progressHandler = null;
        this.errorHandler();
        this.errorHandler = null;
        this.suspendHandler();
        this.suspendHandler = null;
        this.stalledHandler();
        this.stalledHandler = null;
        this.durationChangeHandler();
        this.durationChangeHandler = null;
        this.canPlayHandler();
        this.canPlayHandler = null;
        this.waitingHandler();
        this.waitingHandler = null;
        this.playingHandler();
        this.playingHandler = null;
        this.timeUpdateListener();
        this.timeUpdateListener = null;
        this.playListener();
        this.playListener = null;
        this.pauseListener();
        this.pauseListener = null;
        this.endListener();
        this.endListener = null;

        if (this.audio) {
          //changing the src gives a cracking sound in chrome
          //this.audio.removeAttribute('src');
          this.audio.pause();
          this.audio.currentTime = 0;

          this._isReadyToPlay = false;
          this._isFullyLoaded = false;
        }
      }

    }
  }
  private get useAudioTag(): boolean {
    return this._useAudioTag;
  }

  private loadAudioTagFromUrl(urlToLoad) {
    this.audio.src = urlToLoad;
    this.audio.load();
  }

  public startForAudioContextResume(){
    this.startAudioTagPlayback(true);
  }

  private logStringForAudioTag(){
    if (this.audioTagWrapper){
      return this.audioTagWrapper.toString() + ": ";
    }
    return "AudioTagWrapper is null: "
  }

  private audioStateSubscription: Subscription;
  private startAudioTagPlayback(forced: boolean = false) {
    this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'into startAudioTagPlayback...');
    if (this.audio && this.isReadyToPlay) {

      if (forced || this.audioTagService.audioContext == null || this.audioTagService.audioContext.state == 'running'){
        this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'trying to start audio... audioContext state: ' + (this.audioTagService.audioContext?this.audioTagService.audioContext.state:'web audio api not available'));
        const promise = this.audio.play();

        if (promise !== undefined) {

          promise.then(() => {
            this._needClickToUseAudioTag = false;
            this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'audio playback started => all ok');
          }).catch((error) => {
            this.logger.error( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'error received: ' + error);
            if (environment.enableXMLHttpRequest && this.audioTagService.audioContext && this.useAudioTag) {
              this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'audio playback was prevented => change loading tech to web audio api');
              this.useAudioTag = false;
              this._needClickToUseAudioTag = true;
              this.useXMLHttpRequest = true;

              this.loadAttempt = 0;
              this.startLoading();
            } else {
              this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'audio playback was prevented and we really want audioTags => add listeners to startup');

              if (this.audioTagService.audioContext && this.audioTagService.audioContext.state === 'running'){
                //firefox can prevent playback while having a running audioContext
                this._needClickToUseAudioTag = true;
              }

              //add handler to detect the audioContext becomes available
              this.audioStateSubscription = this.audioTagService.audioContextState$
              .pipe(
                finalize(
                  () =>  { this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'audioContext state subscription removed'); }
                ),
                takeUntil(
                  this.destroy$
                )
              ).subscribe(
                (audioContextState) => {
                  this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'audioContext state changed to ' + audioContextState);
                  if (audioContextState == 'running'){
                    //the subscription needs to be assigned before we can clean it up
                    timer(0).subscribe(()=>{
                      if (this.audioStateSubscription){
                        this.audioStateSubscription.unsubscribe();
                        this.audioStateSubscription = null;
                      }else{
                        this.logger.error( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'audioContext cleaning up subscription error -> we are in the handler but there is no subscription!');
                      }
                    });

                    if (this.playState == PlayState.STARTING_TO_PLAY || this.playState == PlayState.PLAYING){
                      this.logger.debug( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', this.logStringForAudioTag() + 'starting audio..');
                      this.audio.play();
                    }
                  }
                }
              )
            }
          });
        }

      }
    }else{
      this.logger.error( this.LOGGER_CLASSNAME, 'startAudioTagPlayback', 'no audio or not ready to play...');
    }
  }

  // general logging functions
  private loadedMetadata() {
    this._duration = this.audio.duration;

    // TEST END OF AUDIOFILE
    // this.audio.currentTime = this.audio.duration - 10;

    this.logger.debug( this.LOGGER_CLASSNAME, 'loadedMetadata', 'duration loaded: ' + this.duration + ' seconds. Duration from audiofile: ' + this.audioFile.duration + ' Fade position: ' + this._startFadeOutPosition);
  }


  private onProgress() {
    //as soon as we receive something -> not suspended
    this._suspended = false;
    this.loadingData = true;

    let bufferLength = 0;
    let bufferedDetails = '';
    if (this.audio != null){

      const bufferedTimeRanges = this.audio.buffered;
      if (bufferedTimeRanges != null){
        for (let i = 0; i < bufferedTimeRanges.length; i++) {
          bufferLength +=  bufferedTimeRanges.end(i) - bufferedTimeRanges.start(i);
          bufferedDetails += ' part ' + (i + 1) + '  ---> from ' + bufferedTimeRanges.start(i) + ' to ' + bufferedTimeRanges.end(i);

          if (bufferedTimeRanges.end(i) >= this.duration) {
            this._isFullyLoaded = true;
          }
        }

        const bufferStatusLog : string = 'Buffered length: ' + bufferLength + ' seconds (duration: ' + this.duration + ').' ;
        const bufferPartsStatus : string = '' + bufferedTimeRanges.length + ' parts: ' + bufferedDetails;

        this.logger.debug( this.LOGGER_CLASSNAME, 'onProgress',  this.audioFile.toString() + ' ' + bufferStatusLog + ' --- ' + bufferPartsStatus);
      }else{
        this.logger.error(this.LOGGER_CLASSNAME, 'onProgress', 'No buffered properties available on audio object');
      }
    }else{
      this.logger.error(this.LOGGER_CLASSNAME, 'onProgress', 'No audio object present');
    }
  }

  private amountMediaErrDecodeErrors = 0;
  private onError(e: Event) {
    this.logger.error( this.LOGGER_CLASSNAME, 'onError',  'into onError');

    if (this.audio && this.audio.error && this.audio.error.code){
      switch (this.audio.error.code) {
        case this.audio.error.MEDIA_ERR_ABORTED:
          this.logger.error(this.LOGGER_CLASSNAME, 'onError', 'The user aborted the audio playback.');
          break;
        case this.audio.error.MEDIA_ERR_NETWORK:
          this.logger.error(this.LOGGER_CLASSNAME, 'onError', 'A network error caused the audio download to fail.');
          break;
        case this.audio.error.MEDIA_ERR_DECODE:
          this.logger.error(this.LOGGER_CLASSNAME, 'onError', 'The audio playback was aborted due to a corruption problem or because the audio used features your browser did not support.');

          //bugfix firefox: in some rare cases this error occurs when seeking to a specific point.
          if (this.lastSeekToTime && this.lastSeekToTime == this.audio.currentTime){
            this.lastSeekToTime = this.lastSeekToTime + 0.02;
            this.logger.error(this.LOGGER_CLASSNAME, 'onError MEDIA_ERR_DECODE', 'Adding a small offset. lastSeekToTime: ' + this.lastSeekToTime + ' and currentTime: ' + this.audio.currentTime);
          }else{
            this.logger.error(this.LOGGER_CLASSNAME, 'onError MEDIA_ERR_DECODE', 'Not going to add a small offset. lastSeekToTime: ' + this.lastSeekToTime + ' and currentTime: ' + this.audio.currentTime);
          }

          this.amountMediaErrDecodeErrors++;
          break;
        case this.audio.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          this.logger.error(this.LOGGER_CLASSNAME, 'onError', 'The audio could not be loaded, either because the server or network failed or because the format is not supported.');
          break;
        default:
          this.logger.error(this.LOGGER_CLASSNAME, 'onError', 'An unknown error occurred.' + this.audio.error.message + '(' + this.audio.error.code + ')');
          break;
      }
    }else{
      if (this.audio){
        if (this.audio.error){
          this.logger.error(this.LOGGER_CLASSNAME, 'onError', 'error ' + this.audio.error + ' for ' + (this.audioFile ? this.audioFile : 'null object'));
        }else{
          this.logger.error(this.LOGGER_CLASSNAME, 'onError', 'audio.error object is null for ' + (this.audioFile ? this.audioFile : 'null object'));
        }
      }else{
        this.logger.error(this.LOGGER_CLASSNAME, 'onError', 'audio object is null for ' + (this.audioFile ? this.audioFile : 'null object'));
      }
    }

    this.loadNextUrl();
  }

  private onSuspend() {
    this.logger.debug( this.LOGGER_CLASSNAME, 'onSuspend', this.audioFile.toString() + ' into onSuspend (loading data is suspended). fullyLoaded: ' + (this.isFullyLoaded?'true':'false'));
    //only go in suspended mode when the audio is not fully loaded.
    this._suspended = !this.isFullyLoaded;
  }

  private onStalled() {
    this.logger.debug( this.LOGGER_CLASSNAME, 'onStalled', this.audioFile.toString() + ' into onStalled');
  }

  private onDurationChange() {
    this._duration = this.audio.duration;
    this.logger.debug( this.LOGGER_CLASSNAME, 'onDurationChange', this.audioFile.toString() + ' duration changed: ' + this.duration + ' seconds. Duration from audiofile: ' + this.audioFile.duration);
  }

  private onCanPlay() {
    this.logger.debug( this.LOGGER_CLASSNAME, 'onCanPlay', this.audioFile.toString() + ' into onCanPlay');
    this._isReadyToPlay = true;
  }

  private onWaiting() {
    this._buffering = true;
    if (this._playState === PlayState.PLAYING){
      this._playState = PlayState.STARTING_TO_PLAY;
    }
    this.logger.debug( this.LOGGER_CLASSNAME, 'onWaiting', this.audioFile.toString() + ' into onWaiting (for buffering)');
  }

  private onPlaying() {
    this._buffering = false;
    this._playState = PlayState.PLAYING;
    this.logger.debug( this.LOGGER_CLASSNAME, 'onPlaying', this.audioFile.toString() +' into onPlaying (after buffering)');
  }

  private onPlay() {
    this.logger.debug( this.LOGGER_CLASSNAME, 'onPlay', this.audioFile.toString() +' into onPlay');
    this._currentTime = this.audio.currentTime;
    this._playState = PlayState.PLAYING;
  }

  private onPause() {
    this.logger.debug( this.LOGGER_CLASSNAME, 'onPause', this.audioFile.toString() + ' into onPaused');
    this._playState = PlayState.PAUSED;
    this.lastSeekToTime = this.audio.currentTime;
  }

  private onTimeUpdate() {

    //this.logger.debug( this.LOGGER_CLASSNAME, 'onTimeUpdate', this.audioTagWrapper + ' updates time:' + this.audio.currentTime);
    this._currentTime = this.audio.currentTime;
  }

  private onAudioEnded() {
    this.onAudioReachedEnd.trigger();
  }

  /**
   * Helper function to measure how long we were loading the audio
   */
  private _loadingData = false;
  private set loadingData(value: boolean) {
    if (this._loadingData !== value) {
      this._loadingData = value;
      if (this._loadingData) {
        this.loadingDataStartTime = Date.now();
      } else {
        this.totalLoadingDataTime += Date.now() - this.loadingDataStartTime
      }
    }

    const trackLogInfo = this.audioFile ? this.audioFile.toString() : 'no audioFile';
    let urlInfo = "MEDIAURL unknown";
    if (this.httpUrls && this.loadFromUrlAtIndex - 1 < this.httpUrls.length){
      urlInfo = "MEDIAURL: " + this.httpUrls[this.loadFromUrlAtIndex - 1].url + " (" + this.httpUrls[this.loadFromUrlAtIndex - 1].origin + ")";
    }


    if (!this.loadingData && this.isFullyLoaded){
        //log the total loading time
        if (this.totalLoadingDataTime > this.duration * 1000){
          this.logger.error( this.LOGGER_CLASSNAME, 'loadingData', 'loading data was above duration: ' + this.totalLoadingDataTime / 1000 + ' seconds. Duration: ' + this.duration + 's ' + trackLogInfo + " " + urlInfo );
        }else if (this.totalLoadingDataTime > 10000){
          let aboveAmount = '10s';
          if (this.totalLoadingDataTime > 60000){
            aboveAmount = '60s';
          }else if (this.totalLoadingDataTime > 30000){
            aboveAmount = '30s';
          }else  if (this.totalLoadingDataTime > 20000){
            aboveAmount = '20s';
          }
          this.logger.error( this.LOGGER_CLASSNAME, 'loadingData', 'loading data was above '+ aboveAmount+ ': ' + this.totalLoadingDataTime / 1000 + ' seconds. Duration: ' + this.duration  + 's ' + trackLogInfo + " " + urlInfo);
        }else{
          this.logger.debug( this.LOGGER_CLASSNAME, 'loadingData', 'loading data was ok: ' + this.totalLoadingDataTime / 1000 + ' seconds. Duration: ' + this.duration  + 's ' + trackLogInfo + " " + urlInfo);
        }
      }
  }
  private get loadingData(): boolean {
    return this._loadingData;
  }

  private totalLoadingDataTime = 0;
  private loadingDataStartTime = 0;


  /**
   *
   * use XML http request to load the audio mp3
   */

  private _useXMLHttpRequest = false;
  private set useXMLHttpRequest(value) {
    if (this._useXMLHttpRequest !== value) {
      this.logger.debug( this.LOGGER_CLASSNAME, 'useXMLHttpRequest setter', 'useXMLHttpRequest change to ' + value);
      this._useXMLHttpRequest = value;
      if (this._useXMLHttpRequest) {
        this.useAudioTag = false;
        this.startedAt = 0;
        this.pausedAt = 0;
      }
    }
  }
  private get useXMLHttpRequest(): boolean {
    return this._useXMLHttpRequest;
  }


  private decodedAudioBuffer: AudioBuffer = null;
  private loadAudioBufferFromUrl(url) {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer';

    // Decode asynchronously
    request.onload = () => {

      this.logger.debug( this.LOGGER_CLASSNAME, 'loadAudioBufferFromUrl', 'Audio data loaded.. going to decode' + (NgZone.isInAngularZone()?'': ' NOT') + ' inside angular zone');
      this.audioTagService.audioContext.decodeAudioData(request.response, (buffer) => {
        this.logger.debug( this.LOGGER_CLASSNAME, 'loadAudioBufferFromUrl', 'Audio data decoded' + (NgZone.isInAngularZone()?'': ' NOT') + ' inside angular zone');
        this.decodedAudioBuffer = buffer;
        // after an asyncronous call, return to the right zone so views get updates from these values
        this._ngZone.run(() => {
          this._duration = this.decodedAudioBuffer.duration;

          /*if (this.audioFile && this.audioFile instanceof Song && this.audioFile.startAudioSignal) {
            this.pausedAt = this.audioFile.startAudioSignal / 1000;
          }*/

          this._isReadyToPlay = true;
          this._isFullyLoaded = true;
        });
      }, (error) => {
        this.onLoadXMLHttpRequestError(error);
      });
    }

    request.onerror = () => {
      this.logger.debug( this.LOGGER_CLASSNAME, 'loadAudioBufferFromUrl', 'Audio data could not be loaded, try next url..');
      this.loadNextUrl();
    }
    request.send();
  }

  private onLoadXMLHttpRequestError(error) {
    this.logger.debug( this.LOGGER_CLASSNAME, 'onLoadXMLHttpRequestError', 'into onLoadXMLHttpRequestError ' + error.toString());
    this.loadNextUrl();
  }

  private startedAt = 0;
  private pausedAt = 0;

  private _audioBufferSourceNode: AudioBufferSourceNode = null;
  private set audioBufferSourceNode(value) {
    this._audioBufferSourceNode = value;
    this.watchTime = this._audioBufferSourceNode !== null;
  }
  private get audioBufferSourceNode(): AudioBufferSourceNode {
    return this._audioBufferSourceNode;
  }


  private playAudioBufferSource() {
    if (this.decodedAudioBuffer) {
      this.logger.debug( this.LOGGER_CLASSNAME, 'playAudioBufferSource',  'wrapperId: ' + this.audioTagWrapper.audioTagWrapperId + ': going to start audioFile: ' + this.audioFile.toString());
      //we should only assing the sourceNode after everything is set up
      const audioBufferSourceNodeTemp = this.audioTagService.audioContext.createBufferSource();

      audioBufferSourceNodeTemp.buffer = this.decodedAudioBuffer;
      audioBufferSourceNodeTemp.connect(this.audioTagWrapper.audioGainNode);
      audioBufferSourceNodeTemp.onended = () => {
        this.onAudioEnded();
      };
      audioBufferSourceNodeTemp.start(0, this.pausedAt);

      this.logger.debug( this.LOGGER_CLASSNAME, 'playAudioBufferSource', 'audioFile started');
      this.startedAt = this.audioTagService.audioContext.currentTime - this.pausedAt;
      this.pausedAt = 0;

      this.audioBufferSourceNode = audioBufferSourceNodeTemp;
      this._playState = PlayState.PLAYING;
    } else{
      this._playState = PlayState.STARTING_TO_PLAY;
    }
  }

  private pauseAudioBufferSource() {
    const elapsed = this.audioTagService.audioContext.currentTime - this.startedAt;
    this.stopAudioBufferSourceNode();
    this.startedAt = undefined;
    this.pausedAt = elapsed;
    this._playState = PlayState.PAUSED;
  }



  private stopAudioBufferSource() {
    this.stopAudioBufferSourceNode();
    this.startedAt = undefined;
    this.pausedAt = undefined;
    this._playState = PlayState.STOPPED;
  }

  private seekToTimeInAudioBufferSource(time) {
    // we need a new node
    let restartNeeded = false;
    if (this.playState === PlayState.PLAYING) {
      restartNeeded = true;
      this.stopAudioBufferSourceNode();
    }

    this.pausedAt = time;

    this.logger.debug( this.LOGGER_CLASSNAME, 'seekToTimeInAudioBufferSource', 'AudioBufferSource seeked to time:' + time);

    if (restartNeeded) {
      this.playAudioBufferSource();
    }

    this.onSeekDone.trigger();
  }

  private _watchTime = false;
  private set watchTime(value) {
    if (this._watchTime !== value) {
      this._watchTime = value;
      if (this.watchTime) {
        this._ngZone.runOutsideAngular(() => {
          this.updateTimeCallback();
        });
      } else {
        this.clearDelayedCallback();
      }
    }
  }
  private get watchTime(): boolean {
    return this._watchTime;
  }

  private updateTimeDelayedCallback = null;
  private updateTimeCallback() {
    this.updateTimeDelayedCallback = setTimeout(() => {
      this.updateTimeCallback();
    }, 250);

    this.handleUpdateTimeTimerTick();
  }

  private handleUpdateTimeTimerTick() {
    if (this.playState === PlayState.PAUSED) {
      this._currentTime = this.pausedAt;
    } else if (this.startedAt != undefined) {
      this._currentTime = this.audioTagService.audioContext.currentTime - this.startedAt;
    } else {
      //this.logger.debug(this.LOGGER_CLASSNAME, 'handleUpdateTimeTimerTick', 'we should not be here');
      this._currentTime = 0; //todo -> check if we get here when pauze/play
    }
  }

  private clearDelayedCallback() {
    if (this.updateTimeDelayedCallback) {
      clearTimeout(this.updateTimeDelayedCallback);
      this.updateTimeDelayedCallback = null;
    }
  }

  /**
   * Helper functions to create and clear AudioBufferSourceNodes
   */
  private stopAudioBufferSourceNode() {
    if (this.audioBufferSourceNode) {
      this.audioBufferSourceNode.onended = null;
      this.audioBufferSourceNode.stop(0);
      this.audioBufferSourceNode.disconnect(this.audioTagWrapper.audioGainNode);
      this.audioBufferSourceNode = null;
    }
  }


  /**
   * Load mp3 from url
   */

  private loading = false;
  private startBuffering() {
    if (this.httpUrls != null && this.httpUrls.length > 0) {

      if (!this.loading) {
        this.loading = true;

        if (this.audioTagWrapper == null ) {

          this.audioTagWrapper = this.audioTagService.getAudioTagFromPool();

          // this.useXMLHttpRequest = true;

          if (this.playState === PlayState.STARTING_TO_PLAY && environment.useAudioTagForFastStart) {
            // When playback needs to start right away -> load using audio tag
            this.useAudioTag = true;
          } else {
            if (environment.enableXMLHttpRequest && this.audioTagService.audioContext) {
              // When we are loading to buffer AND audioContext is available -> use XMLHttpRequest
              this.useXMLHttpRequest = true;
            } else {
              this.useAudioTag = true;
            }
          }
        }

        this.loadAttempt = 0;
        this.startLoading();
      }

      if (this.reloadingMediaUrls){
        this.reloadingMediaUrls = false;
        //reloading because we needed to reload the media urls does not count towards a full load attempt
        this.loadAttempt--;
        this.startLoading();
      }
    }
  }

  private loadAttempt = 0;
  private startLoading() {
    this.loadAttempt++;

    this.loadFromUrlAtIndex = 0;
    this.urlAttempts = 0; //How many urls have been tried during this loadAttempt
    this.triedToLoadFromAllUrl = false;
    this.loadNextUrl();
  }


  private loadFromUrlAtIndex = 0;
  private urlAttempts = 0;
  private triedToLoadFromAllUrl = false;
  private reloadingMediaUrls = false;
  private loadNextUrl() {

    this.urlAttempts++;

    if (this.loadFromUrlAtIndex >= this.httpUrls.length){
      this.loadFromUrlAtIndex = 0;
      this.triedToLoadFromAllUrl = true;
    }

    //throw away mediaUrls until the one at the loadFromIndex becomes a valid one (or until we have none left)
    while (this.httpUrls.length > 0 && !this.httpUrls[this.loadFromUrlAtIndex].isValidForNewDownload){
      const mediaUrlToThrowAway = this.httpUrls[this.loadFromUrlAtIndex];
      this.logger.warn(this.LOGGER_CLASSNAME, 'loadNextUrl', 'Throwing away MediaUrl for ' + this.audioFile.toString() + ' because it is not valid any more: ' + mediaUrlToThrowAway);
      this.httpUrls = this.httpUrls.filter(mediaUrl => mediaUrl != mediaUrlToThrowAway);

      if (this.loadFromUrlAtIndex >= this.httpUrls.length){
        this.loadFromUrlAtIndex = 0;
        this.triedToLoadFromAllUrl = true;
      }
    }

    if (this.loadFromUrlAtIndex < this.httpUrls.length){

      const urlToLoad = this.httpUrls[this.loadFromUrlAtIndex];
      this.loadFromUrlAtIndex++;

      //at least try 2 times or until all urls have been tried
      if (this.urlAttempts < 3 || !this.triedToLoadFromAllUrl){
        this.loadUrl(urlToLoad);
      } else {

        this.logger.error( this.LOGGER_CLASSNAME, 'loadNextUrl', 'tried all urls for ' + this.audioFile.toString() + ' and all failed. Tried ' + this.loadAttempt + ' times');

        //try to load from all url 3 times, then give up on this audioFile
        if (this.loadAttempt > 2){
          //emit error so we can skip
          this.onAudioFileLoadingError.trigger();
        }

        //add public url for next try
        this.usePublicUrl();

        //as long as this audioFileWithPlayInfo is alive, try to reload
        this.tryDelayedLoad();
      }
    }else{
      this.logger.error(this.LOGGER_CLASSNAME, 'loadNextUrl', 'could not determine any url to download from. amount of httpUrls: ' + this.httpUrls.length + ' --> going to load media urls again for ' + this.audioFile.toString());
      this.reloadingMediaUrls = true;
      this.startPrepare();
    }
  }


  private tryToLoadDelayedCallback = null;
  private initialDelay = 1000;
  private delayIncrement = 5000;
  private maxDelay = 30000;
  private tryDelayedLoad() {
    const delay = Math.min(this.initialDelay + this.delayIncrement + (this.loadAttempt - 1) * this.delayIncrement, this.maxDelay);
    this.tryToLoadDelayedCallback = setTimeout(() => {
      this.clearDelayedLoadCallback();
      this.startLoading();
    }, delay);
  }

  private clearDelayedLoadCallback() {
    if (this.tryToLoadDelayedCallback) {
      clearTimeout(this.tryToLoadDelayedCallback);
      this.tryToLoadDelayedCallback = null;
    }
  }



  private loadUrl(urlToLoad: MediaUrl) {

    // TEST purpose -> make some urls not valid
    /*
    if (Math.random() > 0.3) {
      urlToLoad.url = urlToLoad.url.replace('http://', 'http://werktniet');
    }
    */

    this.logger.debug( this.LOGGER_CLASSNAME, 'loadUrl', 'We should be loading from: ' + urlToLoad.url);

    // reform to https
    let url = urlToLoad.url.replace('http://', 'https://');

    //if we are currently playing and we are going to load and url -> save playing state and
    if (this.playState == PlayState.PLAYING){
      this.lastSeekToTime = this.currentTime;
      this._playState = PlayState.STARTING_TO_PLAY;
    }

    this._isReadyToPlay = false;
    this._isFullyLoaded = false;
    this._suspended = false;

    this.loadingFromMediaUrl = urlToLoad;

    if (this.useAudioTag) {
      this.loadAudioTagFromUrl(url);
    }else if (this.useXMLHttpRequest) {
      this.loadAudioBufferFromUrl(url);
    }
  }

  private _loadingFromMediaUrl: MediaUrl = null;
  private set loadingFromMediaUrl(value: MediaUrl){
    this._loadingFromMediaUrl = value;
    this.startValidityTimer();
  }
  private get loadingFromMediaUrl():MediaUrl{
    return this._loadingFromMediaUrl;
  }

  private validityTimeout: NodeJS.Timeout;
  private startValidityTimer() {
    this.stopValidityTimer();

    if (!this.isFullyLoaded && this.loadingFromMediaUrl != null){
      //we just use a variable for logging/debugging purpose
      const validity = this.loadingFromMediaUrl.validForSeconds;
      if (validity != undefined){
        if (validity < 0){
          this.logger.error(this.LOGGER_CLASSNAME, 'loadUrl', 'Somehow received an invalid url to load (skip to next url): ' + this.loadingFromMediaUrl);
          this.loadNextUrl();
        }else{
          this.validityTimeout = setTimeout(() => {
            if (!this.isFullyLoaded && this.loadingFromMediaUrl && !this.loadingFromMediaUrl.isValid){
              this.logger.error(this.LOGGER_CLASSNAME, 'validityTimeout', 'validityTimeout fired. The mediaUrl we are loading from is now invalid and not fully loaded-> try next mediaUrl');
              this.loadNextUrl();
            }else{
              if (this.loadingFromMediaUrl){
                this.logger.error(this.LOGGER_CLASSNAME, 'validityTimeout', 'validityTimeout fired but we do not need to try next url. loadingFromMediaUrl valid: ' + (this.loadingFromMediaUrl.isValid ? 'yes': 'no') + ' fully loaded: ' + (this.isFullyLoaded ? 'yes': 'no'));
                this.startValidityTimer();
              }else{
                this.logger.error(this.LOGGER_CLASSNAME, 'validityTimeout', 'validityTimeout fired but we do not need to try next url. loadingFromMediaUrl is null.');
                this.stopValidityTimer();
              }
            }
          }, validity * 1000 + 500);
        }
      }
    }
  }

  private stopValidityTimer() {
    if (this.validityTimeout) {
      clearTimeout(this.validityTimeout);
      this.validityTimeout = null;
    }
  }



  public play() {
    this.logger.debug( this.LOGGER_CLASSNAME, 'play', 'into play() for httpurl: ' + Util.formatObjectArray(this.httpUrls));

    if (this.isReadyToPlay) {

      //reset volume
      //this.audioTagWrapper.adjustVolume(1);

      if (this.playState !== PlayState.PLAYING) {
        this._playState = PlayState.STARTING_TO_PLAY;
        if (this.useAudioTag) {
          this.startAudioTagPlayback();
        }else if (this.useXMLHttpRequest) {
          this.playAudioBufferSource();
        }
      }
    } else {
      this._playState = PlayState.STARTING_TO_PLAY;
      this.startPrepare();
    }
  }

  public pause() {
    this.logger.debug( this.LOGGER_CLASSNAME, 'pause', 'into pause');
    if (this.isReadyToPlay) {
      if (this.useAudioTag) {
        this.audio.pause();
      }else if (this.useXMLHttpRequest) {
        this.pauseAudioBufferSource();
      }
    }else {
      this._playState = PlayState.PAUSED;
    }
  }

  private lastSeekToTime = 0.0;
  public seekToTime(time) {
    this.lastSeekToTime = time;

    if (this.isReadyToPlay){
      if (this.useAudioTag) {

        if (this.audio){

          //if we are already close to the position -> do not seek
          if (Math.abs(this.audio.currentTime - time) < 0.2){
            this.logger.info(this.LOGGER_CLASSNAME, 'seekToTime', 'not going to seek to ' + time + ' -> we are already at position ' + this.audio.currentTime + ' which is close enough');
          }else{
            const seekableTimeRanges = this.audio.seekable;
            let timeIsSeekable = false;
            if (seekableTimeRanges){
              for (let i = 0; i < seekableTimeRanges.length; i++) {
                if (time >= seekableTimeRanges.start(i) && time <= seekableTimeRanges.end(i)) {
                  this.logger.info(this.LOGGER_CLASSNAME, 'seekToTime', 'time ' + time + ' is seekable (part ' + i + ': from ' + seekableTimeRanges.start(i) + ' to ' + seekableTimeRanges.end(i));
                  timeIsSeekable = true;
                }
              }
            }

            if (timeIsSeekable) {
              this.logger.info( this.LOGGER_CLASSNAME, 'seekToTime', 'seeking to ' + time);
              this.audio.currentTime = this.lastSeekToTime;
              this.onSeekDone.trigger();
            } else {
              if (seekableTimeRanges){
                let timerangesString = '';
                for (let i = 0; i < seekableTimeRanges.length; i++) {
                  timerangesString += ' part ' + i + ': from ' + seekableTimeRanges.start(i) + ' to ' + seekableTimeRanges.end(i);
                }
                this.logger.debug( this.LOGGER_CLASSNAME, 'seekToTime', 'have to seek to ' + time + ' but not seekable. timeranges: ' + timerangesString);
              }else{
                this.logger.debug( this.LOGGER_CLASSNAME, 'seekToTime', 'have to seek to ' + time + ' but not seekable (no timeranges)');
              }
            }
          }
        }else{
          this.logger.debug( this.LOGGER_CLASSNAME, 'seekToTime', 'have to seek to ' + time + ' but no audio object available');
        }




      }else if (this.useXMLHttpRequest) {
        this.seekToTimeInAudioBufferSource(this.lastSeekToTime);

      }
    }

  }



  private destroy$ = new Subject<void>();
  public cleanUp() {
    this.useAudioTag = false;
    this.useXMLHttpRequest = false;

    this.stopAudioBufferSourceNode();

    this.stopValidityTimer();
    this.loadingFromMediaUrl = null;


    if (this.audioTagWrapper) {
      this.audioTagService.freeAudioTagForPool(this.audioTagWrapper);
      this.audioTagWrapper = null;
    }

    this.clearDelayedUsePublicUrlCallback();
    this.clearDelayedLoadCallback();
    this.clearDelayedCallback();

    this.audioFileChangesSubject.complete();
    this.audioFileChangesSubject = null;

    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = null;
  }

  public toString(): string {
    let infoString = 'AudioFileWithPlayInfo: {';
    if (this.audioFile) {
      infoString = infoString + this.audioFile.toString();
    } else {
      infoString = infoString + 'no audioFile';
    }
    if (this.audioTagWrapper) {
      infoString = infoString + ', ' + this.audioTagWrapper.toString();
    } else {
      infoString = infoString + ', no audioTagWrapper';
    }
    infoString = infoString + '}';
    return infoString;
  }

  /*
  public downloadCanCompleteAfterSeconds(secondsWhenPlayWillStart: number){
    if (this.suspended && this.loadingFromMediaUrl){
      let validityOfMediaUrl = this.loadingFromMediaUrl.validForSeconds;
      if (validityOfMediaUrl != undefined && validityOfMediaUrl < secondsWhenPlayWillStart){
        this.logger.warn(this.LOGGER_CLASSNAME, 'downloadCanCompleteAfterSeconds', 'detected download can not complete in time for ' + this.audioFile);
        return false;
      }
    }
    return true;
  }
  */


}
