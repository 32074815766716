import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Calendar } from '@model/calendar';
import { CalendarGroup } from '@model/calendarGroup';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'tun-calendar-group-view',
  templateUrl: './calendar-group-view.component.html',
  styleUrls: ['./calendar-group-view.component.scss']
})
export class CalendarGroupViewComponent implements OnDestroy {

  //custom calendars
  @Input() showAddButton = false;

  @Input() groupName: string;
  @Input() calendars: Calendar[];
  @Input() lockOverlayMessage : string = null;

  @Output() addCalendar = new EventEmitter<void>();

  constructor(
    private appV5StateService: AppV5StateService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  onAdd(){
    this.addCalendar.emit();
  }

  public onShowDetails(calendar: Calendar){
    if (this.appV5StateService.showDetailsForCalendar == null){
      this.appV5StateService.showCalendarInTunifyGreen(calendar);
    }
  }

}
