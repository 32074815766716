export const custom_calendars = [
    {
        "calendarId": 35922,
        "name": "Test",
        "calendarGroupId": 0,
        "isPublished": false,
        "calendarItems": null,
        "type": "Custom",
        "isVisible": true,
        "timestamp": 123456789
    },
    {
        "calendarId": 35773,
        "name": "Test 2",
        "calendarGroupId": 0,
        "isPublished": false,
        "calendarItems": null,
        "type": "Custom",
        "isVisible": true,
        "timestamp": 123456789
    },
    {
        "calendarId": 35923,
        "name": "Oldies Café 1",
        "calendarGroupId": 0,
        "isPublished": false,
        "calendarItems": null,
        "type": "Custom",
        "isVisible": true,
        "timestamp": 123456789
    },
    {
        "calendarId": 30547,
        "name": "Vlot Nostalgisch",
        "calendarGroupId": 0,
        "isPublished": false,
        "calendarItems": null,
        "type": "Custom",
        "isVisible": true,
        "timestamp": 123456789
    },
    {
        "calendarId": 31105,
        "name": "Stijlvol Eigentijds",
        "calendarGroupId": 0,
        "isPublished": false,
        "calendarItems": null,
        "type": "Custom",
        "isVisible": true,
        "timestamp": 123456789
    },
    {
        "calendarId": 31413,
        "name": "Disco & Soul 1",
        "calendarGroupId": 0,
        "isPublished": false,
        "calendarItems": null,
        "type": "Custom",
        "isVisible": true,
        "timestamp": 123456789
    }
]