import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Playlist } from '@model/playlist';
import { UntypedFormGroup, ValidatorFn, AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Calendar } from '@model/calendar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmPopupData{
  playlist: Playlist;
  calendar: Calendar;
  confirmed: boolean;
}

@Component({
  selector: 'tun-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {

  @ViewChild('confirmInput') confirmInput: ElementRef;

  public static widthForPopup = "30vw";

  // === State === //
  confirmForm: UntypedFormGroup;
  buttonClicked = false;

  playList: Playlist;
  calendar: Calendar;

  get confirmField(): AbstractControl {
    return this.confirmForm.get('confirmText');
  }

  get confirmFieldTouched(): boolean {
    return this.confirmField.touched ;
  }

  get confirmFieldShowError(): boolean {
    return (this.confirmFieldTouched || this.buttonClicked) && this.confirmField.invalid;
  }

  get hasErrors():boolean{
    return this.confirmField.invalid;
  }

  get showErrors(): boolean {
    return (this.confirmFieldTouched || this.buttonClicked) && this.hasErrors;
  }


  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>,
              private _fb: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmPopupData) {}

  ngOnInit() {
    if (this.data && (this.data.playlist != null  || this.data.calendar != null)){

      this.playList = this.data.playlist? this.data.playlist : null;
      this.calendar = this.data.calendar? this.data.calendar : null;
      this.buildForm();
      //we are ok - load extra properties?

    }else{
      this.dialogRef.close();
    }
  }

  public onPressEnter(){
    this.confirmInput.nativeElement.blur();
    //this.tryToConfirm();
  }

  public onYesClick(){
    //test confirmation text
    this.tryToConfirm();
  }

  public onCancelClick(){
    this.data.confirmed = false;
    this.dialogRef.close(this.data);
  }

  public tryToConfirm(){
    this.buttonClicked = true;
    const { value } = this.confirmForm;
    if (!this.hasErrors){
      this.performConfirm();
    }else{
      //animate the error?
    }
  }

  performConfirm() {
    //const { value } = this.confirmForm;
    //console.log('value', value);
    this.data.confirmed = true;
    this.dialogRef.close(this.data);
  }

  private buildForm() {
    this.confirmForm = this._fb.group({
      confirmText: ['', [this.mustMathch()]]
    });
  }

    /** value must be abcd */
  private mustMathch(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = (control.value as string).toLowerCase() != "abcd";
      return forbidden ? {'noMatch': 'Gelieve abcd te typen'} : null;
    };
  }
}
