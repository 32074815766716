export const searchTrack = {
  "@type": "Song",
  "id": 411314,
  "duration": 191957,
  "group": "Martin Garrix",
  "location": "2-14/000411314b564e3.mp3",
  "title": "Dreamer",
  "origin": {
      "type": "musicCollection",
      "data": "8619"
  },
  "composer": null,
  "bpm": 105,
  "musicFeel": "Electronic",
  "localAvailable": null,
  "version": "Original",
  "year": "2018",
  "mood": "Bright",
  "dancingStyle": "Free",
  "startAudioSignal": 0,
  "endAudioSignal": 189845,
  "fadeBeginPosition": 173734,
  "musicStyle": "Pop",
  "subStyle": "Basic",
  "genreDescription": "Pop Electronic",
  "rhythmDescription": "Free",
  "tunifyTipValue": "Tunify Tip"
};