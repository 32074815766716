import { Component } from '@angular/core';

@Component({
  selector: 'tun-duplicate-icon-v5',
  templateUrl: './duplicate-icon-v5.component.html',
  styleUrls: ['./duplicate-icon-v5.component.scss']
})
export class DuplicateIconV5Component {

}
