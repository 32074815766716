
export enum LOGLEVEL {
  INFO = 0,
  DEBUG = 1,
  WARN = 2,
  ERROR = 3,
  OFF = 4
}

export function stringToLogLevel(logLevelString: string): LOGLEVEL{
  let level = null;
  if (logLevelString != null){
    switch(logLevelString.toLowerCase()){
      case "off":
      case "false":
        level = LOGLEVEL.OFF;
        break;
      case "error":
      case "err":
        level = LOGLEVEL.ERROR;
        break;
      case "warn":
      case "warning":
        level = LOGLEVEL.WARN;
        break;
      case "debug":
      case "debugging":
        level = LOGLEVEL.DEBUG;
        break;
      case "info":
        level = LOGLEVEL.INFO;
        break;
    }
  }
  return level;
}

export function logLevelToString(loglevel: LOGLEVEL):string{
  switch (loglevel){
    case LOGLEVEL.INFO:
      return "Info";
    case LOGLEVEL.DEBUG:
      return "Debug";
    case LOGLEVEL.WARN:
      return "Warning";
    case LOGLEVEL.ERROR:
      return "Error";
    case LOGLEVEL.OFF:
      return "Off";
  }
  return "None";
}

export abstract class LogTarget {

  abstract adjustLogLevel(logLevel: LOGLEVEL);
  abstract get currentLogLevel():LOGLEVEL;

  abstract log(logLevel: LOGLEVEL, classname: string, method: string, message: string);
  abstract flushBuffer();
}