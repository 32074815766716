import { Component } from '@angular/core';

@Component({
  selector: 'tun-lock-large-icon-v5',
  templateUrl: './lock-large-icon-v5.component.html',
  styleUrls: ['./lock-large-icon-v5.component.scss']
})
export class LockLargeIconV5Component {

}
