export enum AsyncStatus {
  RUNNING = 'running', // async operation is running
  INVALID_ACTION = 'invalidAction', // the operation was not valid, nothing was be changed.
  CANCELLED = 'cancelled', // the async operation was cancelled by a user action
  COMPLETED = 'completed', // async operation completed normally
  ERROR = 'error', // the async operation did not complete, error from backend
}

export interface AsyncStatusWithData{
  data: any;
  asyncStatus: AsyncStatus;
}

export function isFinal(asyncStatus: AsyncStatus): boolean {
  return asyncStatus === AsyncStatus.INVALID_ACTION 
          || asyncStatus === AsyncStatus.CANCELLED 
          || asyncStatus === AsyncStatus.COMPLETED 
          || asyncStatus === AsyncStatus.ERROR;
           
}
