<cdk-virtual-scroll-viewport
  class="trackTable"
  [itemSize]="trackItemSize$ | async"
  cdkDropList
  [cdkDropListData]="trackTableItems"
  [cdkDropListConnectedTo]="['queueHeader', 'queue']"
  cdkDropListSortingDisabled="true"
  cdkDropListAutoScrollDisabled="true"
>
  <!--
  ---- templateCacheSize: 0
  ---- We can not use a cache to support drag & drop through the angular cdk
  ---- Drag & drop caches items indexes, this cache is not re-calculated if an item moves from on-screen to the cache
  -->

  <tun-track-view
    *cdkVirtualFor="
      let trackTableItem of trackTableItems;
      let i = index;
      templateCacheSize: 0
    "
    class="trackItem"
    [track]="trackTableItem.track"
    [useSelectionBox]="useSelectionBox"
    [showSelectionBox]="showSelectionBox"
    [isSelected]="isTrackChecked(trackTableItem.track)"
    (checkedChanged)="onCheckedChanged($event, trackTableItem.track)"
    [showAudioFileProperty]="audioFileProperty$ | async"
    [tunifyColor]="tunifyColor"
    (play)="onPlay(trackTableItem.track)"
    [playEnabled]="startTrackEnabled$ | async"
    (showOptions)="onShowOptions(trackTableItem.track)"
    [style.height.px]="trackItemSize$ | async"
    cdkDrag
    [cdkDragData]="trackTableItem"
  >
  </tun-track-view>

</cdk-virtual-scroll-viewport>
