import { Component } from '@angular/core';

@Component({
  selector: 'tun-autocomplete-song-icon-v5',
  templateUrl: './autocomplete-song-icon-v5.component.html',
  styleUrls: ['./autocomplete-song-icon-v5.component.scss']
})
export class AutocompleteSongIconV5Component {

}
