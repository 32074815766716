import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tun-popup-header',
  templateUrl: './popup-header.component.html',
  styleUrls: ['./popup-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupHeaderComponent {

  // === Props === //
  @Input() showBackButton: boolean;
  @Input() isInMatDialog = false;

  // === Emitters === //
  @Output() back = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  // === Event handlers === //
  onBackClick = () => this.back.emit();

  onCloseClick = () => this.close.emit();
}
