import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tun-edit-icon',
  templateUrl: './edit-icon.component.html',
  styleUrls: ['./edit-icon.component.scss']
})
export class EditIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
