import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Subject } from 'rxjs';
import { AudioFile } from '@model/audioFile';
import { takeUntil } from 'rxjs/operators';
import { PlayTokenService } from './play-token.service';
import { LoggerService } from '../loggers/logger.service';
import { DTO_TrackActivity, DTO_TrackActivity_Action, DTO_TrackActivity_Track, LoggingApiService, DTO_TrackActivityLogs } from '@service/api/logging-api.service';
import { ZoneConfigurationService } from './zone-configuration.service';
import { PlayableAudio } from '../../model/audioFile';
import { BrandMessage } from '../../model/brandMessage';
import { TrackOrigin } from '@model/trackOrigin';
import { AudioFileOriginType } from '../../model/enums/audioFileOriginType';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LogTrackActionService implements OnDestroy {

  private LOGGER_CLASSNAME = 'LogTrackActionService';

  private MAX_BUFFER_LENGTH = 20;
  private MAX_BUFFER_MILLISECONDS = 8000;

  private lastSeenPlayToken = "";
  private lastSeenZoneId = 0;
  private lastSeenRegionId:number = null;

  constructor(
    private trackActivityLogApiService: LoggingApiService,
    private authenticationService: AuthenticationService,
    private playTokenService: PlayTokenService,
    private zoneConfigurationService: ZoneConfigurationService,
    private loggerService: LoggerService
  ) {

    this.authenticationService.loggedIn$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          if (!value) {
            this.sendLogBuffer();
          }
        }
      );

    this.authenticationService.zoneId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (zoneId) => {
          if (zoneId != null) {
            this.lastSeenZoneId = zoneId;
          }
        }
      );

    this.playTokenService.playToken$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (playToken) => {
          //keep track of our last playToken
          if (playToken != null) {
            this.lastSeenPlayToken = playToken;
          }
        }
      );

    this.zoneConfigurationService.region$
    .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (region) => {
          //keep track of our last playToken
          if (region != null && region.id != null && region.id != undefined) {
            this.lastSeenRegionId = region.id;
          }
        }
      );
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  private logBuffer: DTO_TrackActivity[] = [];
  public logTrackAction(playableAudio: PlayableAudio, trackAction: DTO_TrackActivity_Action, positionInSeconds: number, playerId: string) {
    const trackActivity: DTO_TrackActivity = new DTO_TrackActivity();
    trackActivity.action = trackAction;
    trackActivity.timestamp = new Date().getTime();
    trackActivity.track = new DTO_TrackActivity_Track();
    trackActivity.track.id = playableAudio.id;

    trackActivity.playerType = playerId;
    trackActivity.positionMillis = positionInSeconds * 1000;
    trackActivity.regionId = this.lastSeenRegionId;
    trackActivity.playToken = this.lastSeenPlayToken;

    if (playableAudio instanceof AudioFile){
      const track = playableAudio as AudioFile;
      trackActivity.track.origin = track.origin;
    }else if (playableAudio instanceof BrandMessage){
      trackActivity.track.origin = new TrackOrigin();
      trackActivity.track.origin.type = AudioFileOriginType.BRAND_AUDIO_MESSAGE;
      trackActivity.track.origin.data = '';
      trackActivity.track.origin.correlationId = '';
    }else{
      this.loggerService.error(this.LOGGER_CLASSNAME, 'logTrackAction', 'playableAudio type not supported ' + playableAudio);
      return;

    }


    this.logBuffer.push(trackActivity);

    if (this.logBuffer.length >= this.MAX_BUFFER_LENGTH) {
      this.sendLogBuffer();
    } else {
      this.delayedSendLogBufferIfNeeded();
    }
  }

  private delayedSendLogBufferCallback = null;
  private delayedSendLogBufferIfNeeded() {
    if (this.delayedSendLogBufferCallback == null) {
      this.delayedSendLogBufferCallback = setTimeout(() => {
        this.delayedSendLogBufferCallback = null;
        this.sendLogBuffer();
      }, this.MAX_BUFFER_MILLISECONDS);
    }
  }

  private clearDelayedSendLogBufferCallback() {
    if (this.delayedSendLogBufferCallback) {
      clearTimeout(this.delayedSendLogBufferCallback);
      this.delayedSendLogBufferCallback = null;
    }
  }

  public flushBuffer() {
    this.sendLogBuffer();
  }


  private sendLogBuffer() {
    this.clearDelayedSendLogBufferCallback();
    if (this.logBuffer && this.logBuffer.length > 0) {
      const logsToSend = this.logBuffer;
      this.logBuffer = [];

      const trackActivityLogs = new DTO_TrackActivityLogs();
      trackActivityLogs.timestamp = new Date().getTime();
      trackActivityLogs.trackActivities = logsToSend;

      const logObservable = this.trackActivityLogApiService.sendLogs(this.lastSeenZoneId, trackActivityLogs);

      if (logObservable != null) {
        logObservable
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(
            (data) => {
              this.loggerService.debug( this.LOGGER_CLASSNAME, 'sendLogBuffer', 'logging saved');
            },
            (error: unknown) => {
              let errMsg = '';
              if (error instanceof HttpErrorResponse){
                errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';

              }else if (error instanceof Error){
                errMsg = error.message;
              }else{
                errMsg = 'unknown error type: ' + error;
              }

              this.loggerService.error(this.LOGGER_CLASSNAME, "sendLogBuffer", "Error occured: " + errMsg);
            });
      }
    }
  }

    // this.runningTimeString = moment("1900-01-01 00:00:00").add(this.progress, 'seconds').format("m:ss");
    /*
    private sendLogBuffer() {
      this.clearDelayedSendLogBufferCallback();
      if (this.logBuffer && this.logBuffer.length > 0) {
        let logsToSend = this.logBuffer;
        this.logBuffer = [];

        let logObservable = this.logAudioFileActionsApiService.sendLogs(this.soapCredentials, logsToSend);

        if (logObservable != null){
          logObservable
            .pipe(retry(3))
            .subscribe(
            (data) => {
              const result1 = xml2json(data, {compact: true, spaces: 4});

              const bodyResponse = JSON.parse(result1)['soapenv:Envelope']['soapenv:Body']['ns1:logAudioFileActionsResponse'];
              // console.log("bodyResponse: ", bodyResponse);

              const statusCode = bodyResponse.status.statusCode._text;
              //console.log('statuscode: ', statusCode);

              if (statusCode.toLowerCase() === 'ok'){
                //console.log('logging succesfully saved');
              }else {
                this.loggerService.warn( this.LOGGER_CLASSNAME, 'sendLogBuffer', 'logging could not be saved: ' + statusCode);
              }
            },
            error => {
              const errMsg = (error.message) ? error.message :
                error.status ? `${error.status} - ${error.statusText}` : 'Server error';
              console.error(errMsg);
            }
          );
        }

      }
    }
    */
  }
