

<!-- This wrapper shows the background without any animations on it-->
<div class="backGroundWrapper">

  <!--
  We need the fadeIn animation on the container
  to prevent the slideOutLeft animation from running at the initial render.
  -->
<div class="contentWrapper" @fadeIn>

  <!-- Keep the groups on screen to store the scrollPosition? -->
  <tun-home-panel-main-content-view
    [@viewPosition]="mainViewPosition"
    class="fullContainer">
  </tun-home-panel-main-content-view>


</div>


</div>
