import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'tun-scrollable-text',
  templateUrl: './scrollable-text.component.html',
  styleUrls: ['./scrollable-text.component.scss']
})
export class ScrollableTextComponent implements AfterViewInit, OnDestroy, OnChanges {

  // === Props === //
  @Input() text: string;

  // === ViewChildren === //
  @ViewChild('textfield') songField: ElementRef;
  @ViewChild('textfieldtext') songFieldText: ElementRef;

  // === Emitters === //


  textFieldMarginLeft: SafeStyle = '0px';
  offsetWidth = 0;
  scrollWidth = 0;
  transitionSpeed = 0;
  transition: SafeStyle = 'margin 0ms linear 0s';

  private songFieldHoverTimeoutId: NodeJS.Timeout | null = null;
   // Method to clear the timeout
   private clearSongFieldHoverTimeout() {
    if (this.songFieldHoverTimeoutId) {
      clearTimeout(this.songFieldHoverTimeoutId);
      this.songFieldHoverTimeoutId = null;
    }
  }

  constructor(
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.text) {
      this.resetLabel();
    }
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.songField.nativeElement.addEventListener(
        'mouseenter',
        this.onSongFieldHover
      );
      this.songField.nativeElement.addEventListener(
        'mouseleave',
        this.onSongFieldStopHover
      );
    });
  }

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      this.songField.nativeElement.removeEventListener(
        'mouseenter',
        this.onSongFieldHover
      );
      this.songField.nativeElement.removeEventListener(
        'mouseleave',
        this.onSongFieldStopHover
      );
    });
  }

  onSongFieldHover = () => {
    this.clearSongFieldHoverTimeout();


    this.offsetWidth = this.songFieldText.nativeElement.offsetWidth;
    this.scrollWidth = this.songFieldText.nativeElement.scrollWidth;


    if (this.offsetWidth < this.scrollWidth) {
      this.transitionSpeed =
        (((this.scrollWidth - this.offsetWidth) * 50) / 11) * 3;
      this.transition = `margin ${this.transitionSpeed}ms linear 0s`;
      this.textFieldMarginLeft = this.sanitizer.bypassSecurityTrustStyle(
        `-${this.scrollWidth - this.offsetWidth}px`
      );
    }
    this.cdRef.detectChanges();
  }

  onSongFieldStopHover = () => {
    if (this.textFieldMarginLeft !== ('0px' as SafeStyle)) {
      this.textFieldMarginLeft = '0px';
      this.transitionSpeed = ((this.scrollWidth - this.offsetWidth) * 50) / 11;
      this.transition = `margin ${this.transitionSpeed}ms linear 0s`;

      this.songFieldHoverTimeoutId = setTimeout(() => {
        this.transitionSpeed = 0;
        this.transition = 'margin 0ms linear 0s';
        this.cdRef.detectChanges();
      }, this.transitionSpeed);
    }
    this.cdRef.detectChanges();
  }

  resetLabel = () => {
    this.offsetWidth = 0;
    this.scrollWidth = 0;
    this.transitionSpeed = 0;
    this.transition = 'margin 0ms linear 0s';
    this.textFieldMarginLeft = '0px';
    this.cdRef.detectChanges();
  }

}
