import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplitInputService {

  constructor() { }

  private _clearSplitInputSource = new Subject<any>();
  private _onSplitInputCleared: Observable<any> = this._clearSplitInputSource.asObservable();

  /** Trigger clearing of a SplitInput */
  clearSplitInput(): void {
    this._clearSplitInputSource.next(null);
  }

  /** Emits when a SplitInput has been cleared. */
  get onSplitInputCleared(): Observable<any> {
    return this._onSplitInputCleared;
  }
}
