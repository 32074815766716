<div
  #container
  (tunResize)="onResizeContainer($event)"
  class="wrapper"
>
  <div class="title">{{ (musicChannelGroup != null) ? musicChannelGroup.name : ''}}</div>
  <hr class="horizontalLine"/>
  <div *ngIf="musicChannelGroup != null" class="musicChannelContainer {{ gridClassName }}">
    <ng-container *ngFor="let musicChannel of musicChannelGroup.musicChannels">
      <tun-music-channel-view
        class="musicChannel"
        [musicChannel]="musicChannel"
        (showDetails)="onShowDetails($event)">
      </tun-music-channel-view>

    </ng-container>

  </div>
</div>
