import { Component } from '@angular/core';

@Component({
  selector: 'tun-tweak-playlist-icon-v5',
  templateUrl: './tweak-playlist-icon-v5.component.html',
  styleUrls: ['./tweak-playlist-icon-v5.component.scss']
})
export class TweakPlaylistIconV5Component {

}
