import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

@Component({
  selector: 'tun-image-on-screen-loader',
  templateUrl: './image-on-screen-loader.component.html',
  styleUrls: ['./image-on-screen-loader.component.scss']
})
export class ImageOnScreenLoaderComponent implements AfterViewInit, OnDestroy {

  @Input() src: string;

  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

    constructor() { }

    private observer : IntersectionObserver;

  public wasOnceVisible = false;

  ngAfterViewInit() {
    const options = {
      root: null,
      rootMargin: '10px',
      threshold: 0
    };

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          //this.isVisibleInViewport = true;
          this.wasOnceVisible = true;
        } else {
          //this.isVisibleInViewport = false;
        }
      });
    }, options);

    this.observer.observe(this.wrapper.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
