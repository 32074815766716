import { AudioFile } from '@model/audioFile';
import { Song } from '@model/song';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

export enum AudioFileProperty {
    YEAR = "year",
    DURATION = "duration",
    BPM = "bpm",
    MOOD = "mood",
    MUSIC_STYLE = "musicStyle",
    DANCING_STYLE = "dancingStyle"
}

export function isTrackPropertyAvailable(track: AudioFile, audioFileProperty: AudioFileProperty): boolean{
    if (audioFileProperty === AudioFileProperty.YEAR){
        return track instanceof Song && track.year != null && track.year !== "";
    }else if (audioFileProperty === AudioFileProperty.DURATION){
        return track.duration > 0;
    }else if (audioFileProperty === AudioFileProperty.BPM){
        return track instanceof Song && track.bpm > 0;
    }else if (audioFileProperty === AudioFileProperty.MOOD){
        return track instanceof Song && track.mood != null && track.mood !== "";
    }else if (audioFileProperty === AudioFileProperty.MUSIC_STYLE){
        return track instanceof Song && track.genreDescription != null && track.genreDescription !== "";
    }else if (audioFileProperty === AudioFileProperty.DANCING_STYLE){
        return track instanceof Song && track.rhythmDescription != null && track.rhythmDescription !== "";
    }
    return false;
}

export function isTrackPropertySearchable(track:AudioFile, audioFileProperty:AudioFileProperty):boolean{
    return isTrackPropertyAvailable(track, audioFileProperty) &&
            (audioFileProperty === AudioFileProperty.DANCING_STYLE ||
            audioFileProperty === AudioFileProperty.BPM ||
            audioFileProperty === AudioFileProperty.YEAR ||
            audioFileProperty === AudioFileProperty.MOOD ||
            audioFileProperty === AudioFileProperty.MUSIC_STYLE);
}

export function formatTrackProperty(track: AudioFile, audioFileProperty: AudioFileProperty): string{
    if (isTrackPropertyAvailable(track, audioFileProperty)){
        if (audioFileProperty === AudioFileProperty.YEAR){
            return (<Song>track).year;
        }else if (audioFileProperty === AudioFileProperty.DURATION){
            var mm = moment.duration(track.duration);
            return (mm.minutes() > 0 ? mm.minutes()+":":"") + (mm.seconds()<10?"0"+mm.seconds():mm.seconds());
        }else if (audioFileProperty === AudioFileProperty.BPM){
            return (<Song>track).bpm + " bpm";
        }else if (audioFileProperty === AudioFileProperty.MOOD){
            return (<Song>track).mood;
        }else if (audioFileProperty === AudioFileProperty.MUSIC_STYLE){
            return (<Song>track).genreDescription;
        }else if (audioFileProperty === AudioFileProperty.DANCING_STYLE){
            return (<Song>track).rhythmDescription;
        }
    }else{
        return "-";
    }
}

//just returns the
export function trackPropertyValue(track: AudioFile, audioFileProperty: AudioFileProperty): any{
    if (isTrackPropertyAvailable(track, audioFileProperty)){
        if (audioFileProperty === AudioFileProperty.YEAR){
            return (<Song>track).year;
        }else if (audioFileProperty === AudioFileProperty.DURATION){
            return track.duration;
        }else if (audioFileProperty === AudioFileProperty.BPM){
            return (<Song>track).bpm;
        }else if (audioFileProperty === AudioFileProperty.MOOD){
            return (<Song>track).mood;
        }else if (audioFileProperty === AudioFileProperty.MUSIC_STYLE){
            return (<Song>track).genreDescription;
        }else if (audioFileProperty === AudioFileProperty.DANCING_STYLE){
            return (<Song>track).rhythmDescription;
        }
    }else{
        return "-";
    }
}

//todo -> translate file?
export function getPropertyLabel(translateService: TranslateService, audioFileProperty: AudioFileProperty): string{
    let property = "";
    if (audioFileProperty === AudioFileProperty.YEAR){
        property = "label.year";
    }else if (audioFileProperty === AudioFileProperty.DURATION){
        property = "label.duration";
    }else if (audioFileProperty === AudioFileProperty.BPM){
        property = "label.bpm";
    }else if (audioFileProperty === AudioFileProperty.MOOD){
        property = "label.mood";
    }else if (audioFileProperty === AudioFileProperty.MUSIC_STYLE){
        property = "label.musicStyle"
    }else if (audioFileProperty === AudioFileProperty.DANCING_STYLE){
        property = "label.dancingStyle";
    }
    return translateService.instant(property);

}
