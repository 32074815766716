import { Component } from '@angular/core';

@Component({
  selector: 'tun-external-link-icon-v5',
  templateUrl: './external-link-icon-v5.component.html',
  styleUrls: ['./external-link-icon-v5.component.scss']
})
export class ExternalLinkIconV5Component {

}
