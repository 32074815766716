<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 36 36" enable-background="new 0 0 36 36" xml:space="preserve">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M18.2573598,32.7426408 C19.3825781,33.867859 20.9087011,34.5 22.5,34.5 C24.0912989,34.5 25.6174219,33.867859 26.7426402,32.7426408 C27.8678584,31.6174226 28.5,30.0912995 28.5,28.5 C28.5,26.9087017 27.8678584,25.3825774 26.7426402,24.2573592 C25.6174219,23.132141 24.0912989,22.5 22.5,22.5 C20.9087011,22.5 19.3825781,23.132141 18.2573598,24.2573592 C17.1321416,25.3825774 16.5,26.9087017 16.5,28.5 L16.5,28.5 C16.5,30.0912995 17.1321416,31.6174226 18.2573598,32.7426408 Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
      <path d="M9.25735982,11.7426408 C10.3825781,12.867859 11.9087011,13.5 13.5,13.5 C15.0912989,13.5 16.6174219,12.867859 17.7426402,11.7426408 C18.8678584,10.6174226 19.5,9.09129953 19.5,7.5 C19.5,5.90870174 18.8678584,4.38257742 17.7426402,3.25735919 C16.6174219,2.13214095 15.0912989,1.5 13.5,1.5 C11.9087011,1.5 10.3825781,2.13214095 9.25735982,3.25735919 C8.13214159,4.38257742 7.5,5.90870174 7.5,7.5 L7.5,7.5 C7.5,9.09129953 8.13214159,10.6174226 9.25735982,11.7426408 Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"></path>
      <line x1="19.5" y1="7.5" x2="34.5" y2="7.5" stroke="currentColor" stroke-width="3" stroke-linecap="round"></line>
      <line x1="1.5" y1="28.5" x2="16.5" y2="28.5" stroke="currentColor" stroke-width="3" stroke-linecap="round"></line>
      <line x1="28.5" y1="28.5" x2="34.5" y2="28.5" stroke="currentColor" stroke-width="3" stroke-linecap="round"></line>
      <line x1="1.5" y1="7.5" x2="7.5" y2="7.5" stroke="currentColor" stroke-width="3" stroke-linecap="round"></line>
    </g>
</svg>
