import { Component } from '@angular/core';

@Component({
  selector: 'tun-edit-icon-v5',
  templateUrl: './edit-icon-v5.component.html',
  styleUrls: ['./edit-icon-v5.component.scss']
})
export class EditIconV5Component {

}
