import { BehaviorSubject, Observable, Subject } from 'rxjs';

export class MusicCollection {
    id: number;
    description: string;
    '@type': string;
    timestamp: number;

    private _titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public title$: Observable<string> = this._titleSubject.asObservable();
    public get title(): string{
      return this._titleSubject.value;
    }
    public set title(value: string){
      if (this.title != value){
        this._titleSubject.next(value);
      }
    }

    public refetchNeededForConflict = false; //on a conflict -> we need to refetch even if we don't know the correct timestamp
    public timestampFromUpdateTrigger: number = undefined; //timestamp that we have received after a save or update event. This is used saving is done to check if we have the most recent version
    public refetchedToAvoidVersionConflict = false; //boolean to avoid we fetch multiple times in a row (avoid endless loop when the server messes up the version numbers)

    // marker to indicate we are saving the musicCollection
    public saving = false;
    //counter to indicate the amount of failed saves
    public saveFailedAttemps = 0;
    //marker to indicate that we have local changes that needs to be saved to the server
    public localChanges = false;

    /**
    * isSelected property - available as subscription
    */
    public get selected(): boolean  {
        return this._selectedSubject.value;
    }
    public set selected(value: boolean) {
        if (this.selected !== value) {
            this._selectedSubject.next(value);
        }
    }
    private _selectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selected$: Observable<boolean> = this._selectedSubject.asObservable();

     /**
    * isRecommendation property - available as subscription
    * Indicates if this musicCollection is considered as a recommendation.
    */
    public get isRecommendation(): boolean  {
      return this._isRecommendationSubject.value;
    }
    public set isRecommendation(value: boolean) {
      if (this.isRecommendation !== value) {
        if (value){
          this._isRecommendationSubject.next(true);
        }
          this._isRecommendationSubject.next(value);
      }
    }
    private _isRecommendationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isRecommendation$: Observable<boolean> = this._isRecommendationSubject.asObservable();

    constructor(musicCollection?: MusicCollection){
        if (musicCollection){
            this.id = musicCollection.id;
            this.title = musicCollection.title;
            this.description = musicCollection.description;
            this['@type'] = musicCollection['@type'];
            this.selected = musicCollection.selected;
            this.timestamp = musicCollection.timestamp;

            this.detailsLoaded = musicCollection.detailsLoaded;
            //we don't copy the detailsLoading and detailsLoadingError properties, loading details is at object level.
            //If you take a copy of a musicCollection while the details are loading, the details won't be available into the new copy once the http call completes
        }
    }


    private _detailsLoadingSubject = new BehaviorSubject<boolean>(false);
    detailsLoading$ = this._detailsLoadingSubject.asObservable();
    set detailsLoading(value: boolean){
        this._detailsLoadingSubject.next(value);
    }
    get detailsLoading():boolean{
        return this._detailsLoadingSubject.value;
    }



    detailsLoaded: boolean = false;
    detailsLoadingError: string;
    //an event emitter that is triggered when the loading is done, with a boolean indicating a success (true = success, false = failure)
    detailsloadingDone:Subject<boolean> = new Subject<boolean>();


    public copyDetailsFrom(musicCollection: MusicCollection){
      if (musicCollection != null && musicCollection.id == this.id){
        this.title = musicCollection.title;
        this.timestamp = musicCollection.timestamp;
        this.selected = musicCollection.selected;
      }
    }
}
