<div class="wrapper">

  <button class="search-button" (click)="onSearchText()">
    <tun-menu-search-icon-v5 class="icon icon-scaled-svg-button icon-search-button"></tun-menu-search-icon-v5> {{'searchList.button.search' | translate}}
  </button>


  <div class="title">{{'searchList.label.title' | translate }}</div>

  <ng-container *ngIf="(searchMusicChannelGroupsLoading$ | async)">
    <tun-loader-v5 class="loader"></tun-loader-v5>
  </ng-container>

  <div *ngIf="(searchMusicChannelGroupsError$ | async)" class="errorContainer">
    <p>{{'general.status.error.message' | translate}}</p>
    <button (click)="onRetry()" class="retryButton">
      <p>
        {{'general.status.error.retry' | translate }}
      </p>
    </button>
  </div>

  <div *ngIf="!(searchMusicChannelGroupsLoading$ | async) && !(searchMusicChannelGroupsError$ | async)" class="scrollContainer">
    <ng-container *ngFor="let searchMusicChannel of (searchMusicChannels$ | async); index as i">
      <tun-search-music-channel-button-view
        class="musicChannel {{ i == 0 ? 'first' : ''}}"
        [searchMusicChannel]="searchMusicChannel"
      >
      </tun-search-music-channel-button-view>
    </ng-container>
  </div>
</div>
