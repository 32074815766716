<div class="wrapper">
  <div class="top-content">
    <div class="content-item logo-content">
      <img class="fullfit" [src]="(royaltyFree$ | async)?'assets/logo/tunify_white_royaltyFree2.svg':'assets/logo/tunify_white.svg'">
    </div>
    <tun-tab-menu class="menu-content"></tun-tab-menu>
  </div>
  <tun-help-menu class="bottom-content">
  </tun-help-menu>

</div>
