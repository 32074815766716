export enum PopupDirection {
  UP,
  DOWN,
  RIGHT,
}

export enum PopupPosition {
  TOP_CENTER,
  TOP_RIGHT,
  BOTTOM_CENTER,
  BOTTOM_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_CENTER_THEN_TOP,
  RIGHT,
  RIGHT_CENTER,
}
