<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(12, 12) scale(1, -1) translate(-12, -12)translate(1, 1)" stroke="currentColor" stroke-linecap="round" stroke-width="2">
      <path d="M22,0.8 C22,0.3581722 21.6418278,0 21.2,0 L6.8,0 C6.3581722,0 6,0.3581722 6,0.8 L6,15.2 C6,15.6418278 6.3581722,16 6.8,16 L21.2,16 C21.6418278,16 22,15.6418278 22,15.2 L22,0.8 Z" stroke-linejoin="round"></path>
      <line x1="14" y1="12" x2="14" y2="4"></line>
      <line x1="10" y1="8" x2="18" y2="8"></line>
      <path d="M2.40720909,6 L0.80240303,6 C0.359248073,6 0,6.37702337 0,6.84210526 L0,21.1578947 C0,21.6229766 0.359248073,22 0.80240303,22 L15.0471464,22 C15.4903014,22 16,21.4650819 16,21 L16,19" stroke-linejoin="round"></path>
    </g>
  </g>
</svg>
