<div class="wrapper">

  <!-- lines -->
  <div class="lineContainer">

  </div>

  <!-- content buttons -->
  <div class="day-navigator-container">

    <!-- Time Day -->
    <button class="icon-button" (click)="onViewCurrentTime()">
      <tun-clock-icon-v5 class="icon icon-scaled-svg-button"></tun-clock-icon-v5>
    </button>

    <!-- Previous Day -->
    <button class="icon-button" (click)="onPrevious()">
      <tun-angle-left-icon-v5 class="icon icon-scaled-svg-button"></tun-angle-left-icon-v5>
    </button>



    <!--
    <div class="days-container">
      <ng-container *ngFor="let day of daysOfWeek; let dayIndex = index"></ng-container>
    </div>
  -->

    <div class="days-container">

      <div class="current-day-container">
        <ng-container *ngFor="let day of daysOfWeek; let dayIndex = index">

          <div
            *ngIf="shouldShowDay(dayIndex)"
            [@dayPosition]="dayPosition(dayIndex)"
            class="day {{dayIndex === currentIndex ? 'active' : ''}}">

            <!-- Copy (only shown in edit mode-)-->
            <button
              *ngIf="inEditMode"
              class="icon-button day-button left {{dayIndex === currentIndex ? '' : 'hidden' }}"
              [style.opacity]="dayIndex === currentIndex ? 1 : 0"
              [matMenuTriggerFor]="copyDayMenu"
            >
              <tun-duplicate-fine-icon-v5 class="icon icon-scaled-svg-button"></tun-duplicate-fine-icon-v5>
            </button>


            <div class="dayLabel {{dayIndex === currentIndex ? 'active' : ''}}">
              {{ day.nameToTranslate | translate }}
            </div>

             <!-- Delete (only shown in edit mode)-->
            <button
              *ngIf="inEditMode"
              class="icon-button day-button right {{dayIndex === currentIndex ? '' : 'hidden' }}"
              [style.opacity]="dayIndex === currentIndex ? 1 : 0"
              (click)="onDelete()"
            >
                <tun-delete-icon-v5 class="icon icon-scaled-svg-button"></tun-delete-icon-v5>
            </button>
          </div>

        </ng-container>

        <mat-menu #copyDayMenu="matMenu">

          <div class="option-item">
            {{'calendar.header.copyDay.title' | translate}}
          </div>

          <div class="option-seperator"></div>

          <button
            *ngFor="let day of daysOfWeek; let dayIndex = index"
            class="option-item option-button"
            [disabled]="dayIndex === currentIndex"
            (click)="copyToDay(day)"
          >
            {{day.nameToTranslate | translate}}
          </button>

          <div class="option-seperator"></div>

          <button
            class="option-item option-button"
            (click)="copyToAllDays()"
          >
            {{'calendar.header.copyDay.allDays' | translate}}
          </button>

        </mat-menu>

      </div>
    </div>



    <!-- Next Day-->
    <button class="icon-button" (click)="onNext()">
      <tun-angle-right-icon-v5 class="icon icon-scaled-svg-button"></tun-angle-right-icon-v5>
    </button>

  </div>
</div>


