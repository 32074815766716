import { Component, ElementRef, ViewChild } from '@angular/core';
import { ResizedEvent } from '@components/resize/resize.directive';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ZoneConnection } from '@model/zoneConnection';
import { DesktopAppInstallerService } from '@service/app-v5/desktop-app-installer.service';
import { AppService } from '@service/app.service';
import { ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { BehaviorSubject, combineLatest, delay, map, Observable, Subject, takeUntil, timer } from 'rxjs';

export enum ConnectDesktopAppViewComponent_Status{
  NO_CONNECTIONS,
  CHECKING,
  NOT_RUNNING,
  RUNNING
}

@Component({
  selector: 'tun-connect-desktop-app-view',
  templateUrl: './connect-desktop-app-view.component.html',
  styleUrl: './connect-desktop-app-view.component.scss',
  host: {
    'class': 'router-flex darkPanelBackground'
  }
})
export class ConnectDesktopAppViewComponent {

  @ViewChild('zoneConnectionsView', {static: true}) zoneConnectionsView:any;
  @ViewChild('scrollableZoneContainer', {static: true}) scrollableZoneContainer:ElementRef;

  public ConnectDesktopAppViewComponent_Status = ConnectDesktopAppViewComponent_Status;

  public get connectDesktopAppViewComponentStatus$(){
    return combineLatest([this.zoneConnectionsAmount$, this.checkingIsRunning$, this.isRunning$])
    .pipe(
      map(([amount, checking, running]) => {
        if (amount == 0){
          return ConnectDesktopAppViewComponent_Status.NO_CONNECTIONS;
        }
        if (checking){
          return ConnectDesktopAppViewComponent_Status.CHECKING;
        }
        if (!running){
          return ConnectDesktopAppViewComponent_Status.NOT_RUNNING;
        }
        return ConnectDesktopAppViewComponent_Status.RUNNING;
      }
    ));
  }

  private set checkingIsRunning(value: boolean){
    this.checkingIsRunningSubject.next(value);
  }
  private get checkingIsRunning(){
    return this.checkingIsRunningSubject.value;
  }
  private checkingIsRunningSubject = new BehaviorSubject<boolean>(false);
  private checkingIsRunning$ = this.checkingIsRunningSubject.asObservable();


  private set isRunning(value: boolean){
    this.isRunningSubject.next(value);
  }
  private get isRunning(){
    return this.isRunningSubject.value;
  }
  private isRunningSubject = new BehaviorSubject<boolean>(false);
  private isRunning$ = this.isRunningSubject.asObservable();




  public faAngleLeft = faAngleLeft;
  public faAngleRight = faAngleRight;

  //we keep track of the errors and state of the first zoneConnection
  private _focussedZoneConnectionSubject = new BehaviorSubject<ZoneConnection>(null);
  private focussedZoneConnection$ = this._focussedZoneConnectionSubject.asObservable();
  private set focussedZoneConnection(zoneConnection: ZoneConnection){
    if (this._focussedZoneConnectionSubject.value != zoneConnection){
      this._focussedZoneConnectionSubject.next(zoneConnection);

      const currentFocussedZoneConnection = this.focussedZoneConnection;
      if (currentFocussedZoneConnection){

      }
    }
  }
  private get focussedZoneConnection(){
    return this._focussedZoneConnectionSubject.value;
  }

  get zoneConnections$(){
    return this.zoneConnectionService.zoneConnections$;
  }

  get heightPerItem$(){
    return this.appService.heightPerItem$;
  }



  get gridWidth$(): Observable<number>{
    return combineLatest([this.amountOfGridColumns$, this.itemWidth$])
      .pipe(
        map(([amountOfColumns, itemWidth]) => {
          //console.log("Going to change: itemWidth: " + itemWidth + " , amountOfItems: " + amountOfItems + " -> " + itemWidth * amountOfItems);
          return itemWidth * amountOfColumns
        })
      );
  }

  public get amountOfGridColumns$():Observable<number>{
    return combineLatest([this.zoneConnectionsAmount$, this.maxColumns$])
    .pipe(
      map(([amount, maxColumns]) => Math.max(1, Math.min(maxColumns, amount)))
    )
  }


  private get zoneConnectionsAmount$(): Observable<number>{
    return this.zoneConnectionService.zoneConnections$
      .pipe(
        map(zoneConnections => zoneConnections.length)
      );
  }

  get itemWidth$(): Observable<number>{
    return this.appService.widthForMenuPanel$
            .pipe(
              map(width => Math.max(width, 200))
            );
  }

  constructor(
    private zoneConnectionService: ZoneConnectionsService,
    private appService: AppService,
    private desktopAppInstallerService: DesktopAppInstallerService
  ){

    timer(0, 10000)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(() => {
      this.checkConnectedToDesktop();
    });


    if (this.zoneConnectionService.zoneConnections.length == 0){
      //Nothing to share
    }

    this.zoneConnectionService.zoneConnections$
    .pipe(
      delay(100),
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      () => {
        this.calcCurrentVisibleElement();
        this.determineFocussedZoneConnection();
      }
    );
  }

  private sharedFirst = false;
  private checkConnectedToDesktop(){
    if (!this.isRunning){
      this.checkingIsRunning = true;
    }
    this.desktopAppInstallerService.isCommServerRunning().then((isRunning) => {
      this.checkingIsRunning = false;
      this.isRunning = isRunning;

      //share first zoneConnection
      if (this.isRunning && !this.sharedFirst && this.zoneConnectionService.zoneConnections.length > 0){
        this.sharedFirst = true;
        this.desktopAppInstallerService.startSharingZoneConnection(this.zoneConnectionService.zoneConnections[0]);
      }
    });
  }

  private determineFocussedZoneConnection(){
    if (this.zoneConnectionService.zoneConnections != null && this.zoneConnectionService.zoneConnections.length > 0 && this.visibleZoneConnectionIndex >= 0 && this.visibleZoneConnectionIndex < this.zoneConnectionService.zoneConnections.length){
      this.focussedZoneConnection = this.zoneConnectionService.zoneConnections[this.visibleZoneConnectionIndex];
    }else{
      this.focussedZoneConnection = null;
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.startScrollDetection();
    this.calcCurrentVisibleElement();
  }

  private maxColumns$ = new BehaviorSubject<number>(1);

  public onResize(event:ResizedEvent){
    const viewRect = this.zoneConnectionsView.nativeElement.getBoundingClientRect();

    if (viewRect.height > 0){
      this.appService.adjustPlayerSizeToHeight(viewRect.height);
      this.appService.adjustPlayerSizeToWidth(viewRect.width);
    }
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;

    this._focussedZoneConnectionSubject.complete();
  }


  /**
   * Scrolling detection
   */

  //Timer, used to detect whether horizontal scrolling is over
  private timer = null;
  //Scrolling event start
  private startScrollDetection(){
    this.scrollableZoneContainer.nativeElement.addEventListener('scroll', () => {
      clearTimeout(this.timer);
      //Renew timer
      this.timer = setTimeout(() => {
        this.scrollingDidEnd();
      }, 100);
    });
  }

  private scrollingDidEnd() {
    this.calcCurrentVisibleElement();
  }

  private visibleZoneConnectionIndex = -1;
  private calcCurrentVisibleElement() {
    //mid of scrollContainer:
    var mid = this.scrollableZoneContainer.nativeElement.getBoundingClientRect().width / 2;

    this.visibleZoneConnectionIndex = -1;
    [].slice.call(this.scrollableZoneContainer.nativeElement.children).forEach((ele, index) => {

      if (ele.getBoundingClientRect().left < mid &&ele.getBoundingClientRect().right > mid){
        //console.log("Element " + index + " is showing");
        this.visibleZoneConnectionIndex = index;
        this.determineFocussedZoneConnection();
      }
    });
  }

  public scrollLeft(){
    if (this.scrollableZoneContainer.nativeElement.children != null && this.scrollableZoneContainer.nativeElement.children.length > 0){
      let element = this.scrollableZoneContainer.nativeElement.children[0];
      let withMinusLargestPadding = this.getScrollingWidthForElement(element);
      this.scrollableZoneContainer.nativeElement.scroll({left: this.scrollableZoneContainer.nativeElement.scrollLeft - withMinusLargestPadding, behavior: 'smooth'});
    }
  }

  public scrollRight(){
    if (this.scrollableZoneContainer.nativeElement.children != null && this.scrollableZoneContainer.nativeElement.children.length > 0){
      let element = this.scrollableZoneContainer.nativeElement.children[0];
      let withMinusLargestPadding = this.getScrollingWidthForElement(element);
      this.scrollableZoneContainer.nativeElement.scroll({left: this.scrollableZoneContainer.nativeElement.scrollLeft + withMinusLargestPadding, behavior: 'smooth'});
    }
  }

  private getScrollingWidthForElement(element: any): number{

      let compytedStyle = getComputedStyle(element);
      let elementWidth = element.clientWidth;
      //only allow the minimum padding to be calculated in the scroll distance
      let withMinusLargestPadding = elementWidth - Math.max(parseFloat(compytedStyle.paddingLeft), parseFloat(compytedStyle.paddingRight));
      return withMinusLargestPadding;
  }

}
