
//Start Tunify Blue configuration
export const musicSelection =
{
  "active": "MusicChannel",
  "musicChannelId": 134,
  "calendarId": 2,
  "timestamp": 1
}

export const zoneConfiguration =
{
    "musicSelection": musicSelection,
    "sabamId": "11BB637A4A860",
    "royaltyFree": false,
    "regionCode": "vl",
    "accessRights": {
        "playMusic": true,
        "accessBlue": true,
        "accessOrange": true,
        "prelisten": true,
        "customCalendars": true,
        "customPlaylists": true,
        "search": true,
        "addToQueue": true,
        "startTrack": true
    }
}




/*
//Start Tunify Green configuration
export const zoneConfiguration =
{
    "musicSelection": {
        "active": "Calendar",
        "musicChannelId": 134,
        "calendarId": 2
    },
    "sabamId": "11BB637A4A860"
}
*/
