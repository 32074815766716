<div class="wrapper">

  <div class="contentWrapper">

    <!-- header -->
    <div class="topContent">

      <div class="remainingSpace">
        <button class="icon-button header-icon-button" (click)="onBack()">
          <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
        </button>
      </div>

      <div class="titleContainer">

        <div *ngIf="showSearchMusicChannel" class="title">
          {{showSearchMusicChannel.name}}
        </div>

      </div>

      <div class="remainingSpace"></div>

    </div>

    <!-- normal content -->
    <div *ngIf="showSearchMusicChannel" class="scrollContainer">
      <ng-container *ngFor="let searchMusicCollection of showSearchMusicChannel.musicCollections; index as i">
        <tun-search-music-collection-button-view
          class="musicCollection {{ i == 0 ? 'first' : ''}}"
          [searchMusicCollection]="searchMusicCollection"
        >
      </tun-search-music-collection-button-view>
      </ng-container>
    </div>


</div>

