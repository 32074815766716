<div class="content-wrapper">

  <div class="title-container">

    <div class="backHeader">
      <button class="icon-button" (click)="onBack()">
        <tun-arrow-back36px-icon-v5 class="icon icon-large-scaled-svg-button"></tun-arrow-back36px-icon-v5>
      </button>
    </div>

    <div class="title">{{ musicChannel.name }}</div>

    <button class="icon-button" (click)="onClose()">
      <tun-close-v5 class="icon icon-large-scaled-svg-button"></tun-close-v5>
    </button>

  </div>

  <div class="seperator"></div>

  <div class="scrollContainer">
    <ng-container *ngFor="let musicCollection of musicChannel.musicCollections; index as i">
      <tun-overlay-music-collection-button-view
        class="musicCollection {{ i == 0 ? 'first' : ''}}"
        [musicCollection]="musicCollection"
        (selectMusicCollection)="onSelectMusicCollection($event)"
      >
      </tun-overlay-music-collection-button-view>
    </ng-container>

  </div>



</div>
