import { Component } from '@angular/core';

@Component({
  selector: 'tun-main-player',
  templateUrl: './main-player.component.html',
  styleUrls: ['./main-player.component.scss']
})
export class MainPlayerComponent {

}
