export const musicCollection_8992 = {
    "@type": "Context",
    "id": 8992,
    "title": "Nu Urban",
    "description": "10",
    "lastChanged": null,
    "changeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Music Feel",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Music Feel",
            "value": [
                {
                    "value": "Dance",
                    "selected": true
                },
                {
                    "value": "Reggae",
                    "selected": true
                },
                {
                    "value": "Soul",
                    "selected": true
                },
                {
                    "value": "Urban",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Substyle",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Substyle",
            "value": [
                {
                    "value": "Basic",
                    "selected": true
                },
                {
                    "value": "Hip Hop",
                    "selected": true
                },
                {
                    "value": "R & B",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 2000,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 2000,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 4,
            "maximum": 5,
            "step": 1,
            "lowerSelection": 4,
            "upperSelection": 5,
            "metadataDisplayMinimum": "4",
            "metadataDisplayMaximum": "5"
        },
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Mood",
            "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Mood",
            "value": [
                {
                    "value": "Social",
                    "selected": true
                },
                {
                    "value": "Bright",
                    "selected": true
                },
                {
                    "value": "Dynamic",
                    "selected": false
                }
            ]
        }
    ],
    "defaultChangeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Music Feel",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Music Feel",
            "value": [
                {
                    "value": "Dance",
                    "selected": true
                },
                {
                    "value": "Reggae",
                    "selected": true
                },
                {
                    "value": "Soul",
                    "selected": true
                },
                {
                    "value": "Urban",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Substyle",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Substyle",
            "value": [
                {
                    "value": "Basic",
                    "selected": true
                },
                {
                    "value": "Hip Hop",
                    "selected": true
                },
                {
                    "value": "R & B",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 2000,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 2000,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Region Popularity",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Region Popularity",
            "minimum": 4,
            "maximum": 5,
            "step": 1,
            "lowerSelection": 4,
            "upperSelection": 5,
            "metadataDisplayMinimum": "4",
            "metadataDisplayMaximum": "5"
        },
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Mood",
            "metadataDisplayType": "TEXT_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Mood",
            "value": [
                {
                    "value": "Social",
                    "selected": true
                },
                {
                    "value": "Bright",
                    "selected": true
                },
                {
                    "value": "Dynamic",
                    "selected": false
                }
            ]
        }
    ],
    "visible": true,
    "editable": false,
    "favourite": false,
    "selected": false,
    "mostPlayed": false,
    "defaultSelected": false
};