export const waitingQueue = {
    "timestamp": 1,
    "id": 1,
    "zoneId" : 331412,
    "type": "waiting",
    "tracks": [
        {
            "@type": "Song",
            "id": 411314,
            "duration": 191957,
            "group": "Martin Garrix",
            "location": "2-14/000411314b564e3.mp3",
            "title": "Dreamer",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 105,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 189845,
            "fadeBeginPosition": 173734,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 403782,
            "duration": 147775,
            "group": "Rosalía",
            "location": "2-82/00040378271af75.mp3",
            "title": "Malamente",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 98,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 146663,
            "fadeBeginPosition": 144663,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412453,
            "duration": 164234,
            "group": "Sultan & Ned Shepard",
            "location": "2-53/000412453c6ba18.mp3",
            "title": "Where Are You?",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 124,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 163122,
            "fadeBeginPosition": 162122,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 413741,
            "duration": 175052,
            "group": "Netsky, Toulouse & StarGate",
            "location": "2-41/00041374194acf3.mp3",
            "title": "Voodoo Magic",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 170,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 169274,
            "fadeBeginPosition": 168940,
            "musicStyle": "Electronic",
            "subStyle": "Basic",
            "genreDescription": "Electronic Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412543,
            "duration": 174835,
            "group": "Kris Kross Amsterdam, Ally Brooke & Messiah",
            "location": "2-43/000412543872a2b.mp3",
            "title": "Vámonos",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 105,
            "musicFeel": "Reggae",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 174316,
            "fadeBeginPosition": 173316,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Reggae",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412894,
            "duration": 231558,
            "group": "Clean Bandit, KYLE & Big Boi",
            "location": "2-94/000412894230c31.mp3",
            "title": "Out At Night",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 105,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 228780,
            "fadeBeginPosition": 228076,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412226,
            "duration": 197622,
            "group": "Blanche",
            "location": "2-26/0004122269d1dbd.mp3",
            "title": "Moment",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 182,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 195510,
            "fadeBeginPosition": 193399,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412107,
            "duration": 165212,
            "group": "Martin Jensen ft Bjørnskov",
            "location": "2-7/000412107a9089c.mp3",
            "title": "Somebody I'm Not",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 100,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 33,
            "endAudioSignal": 164397,
            "fadeBeginPosition": 161767,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 409330,
            "duration": 226959,
            "group": "Robyn",
            "location": "2-30/000409330eb0120.mp3",
            "title": "Honey",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 116,
            "musicFeel": "Dance",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 37,
            "endAudioSignal": 224403,
            "fadeBeginPosition": 216810,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Dance",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": null
        },
        {
            "@type": "Song",
            "id": 413581,
            "duration": 158417,
            "group": "Don Diablo & Nate Dogg",
            "location": "2-81/000413581c336ff.mp3",
            "title": "I Got Love",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 123,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 32,
            "endAudioSignal": 157305,
            "fadeBeginPosition": 156268,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412141,
            "duration": 194601,
            "group": "Little Mix ft Kamille",
            "location": "2-41/00041214146e380.mp3",
            "title": "More Than Words",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 67,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 77,
            "endAudioSignal": 191823,
            "fadeBeginPosition": 183156,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
        "@type": "Song",
        "id": "411560",
        "duration": 209945,
        "group": "Karol G & Maluma",
        "location": "2-60/000411560d289ab.mp3",
        "title": "Créeme",
        "origin": {
            "type": "musicCollection",
            "data": "8619"
        },
        "composer": null,
        "bpm": 97,
        "musicFeel": "Reggae",
        "localAvailable": null,
        "version": "Original",
        "year": "2018",
        "mood": "Bright",
        "dancingStyle": "Reggaeton",
        "startAudioSignal": 0,
        "endAudioSignal": 208833,
        "fadeBeginPosition": 197833,
        "musicStyle": "Pop",
        "subStyle": "Basic",
        "genreDescription": "Pop Reggae",
        "rhythmDescription": "Reggaeton",
        "tunifyTipValue": "Tunify Tip"
        },
        {
        "@type": "Song",
        "id": "409261",
        "duration": 233787,
        "group": "Oscar & The Wolf",
        "location": "2-61/000409261dc1f74.mp3",
        "title": "On Fire",
        "origin": {
            "type": "musicCollection",
            "data": "8619"
        },
        "composer": null,
        "bpm": 117,
        "musicFeel": "Pop",
        "localAvailable": null,
        "version": "Original",
        "year": "2018",
        "mood": "Bright",
        "dancingStyle": "Free",
        "startAudioSignal": 50,
        "endAudioSignal": 231342,
        "fadeBeginPosition": 214787,
        "musicStyle": "Pop",
        "subStyle": "Basic",
        "genreDescription": "Pop",
        "rhythmDescription": "Free",
        "tunifyTipValue": null
        }
    ]
};