<div class="wrapper">

  <div *ngIf="!(showQueue$ | async)" class="backgroundImageContainer">
    <img class="backgroundImage" [src]="(originImageUrl$ | async)">
  </div>

  <div *ngIf="!(showQueue$ | async)" class="frontImageContainer">
    <img class="frontImage" [src]="(originImageUrl$ | async)">
  </div>

  <div *ngIf="showQueue$ | async" class="frontImageContainer">
    <tun-queue-full-icon-v5 class="icon queue-icon"></tun-queue-full-icon-v5>
  </div>

  <div
    class="remoteOverlay"
    [style.opacity]="((showOverlay$ | async) && canShowRemoteOverlay)?1:0"
    [style.visibility]="((showOverlay$ | async) && canShowRemoteOverlay) ? 'visible' : 'hidden'"
  >
    <tun-remote-info-buttons-view
      *ngIf="canShowRemoteOverlay"
      class="remoteInfoButtons"
      [canShowTooltips]="true"
    >
    </tun-remote-info-buttons-view>
  </div>


</div>
