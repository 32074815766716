export const calendarGroups = {
"timestamp": 1221,
"calendarGroups": [
    {
        "id": 229,
        "name": "Shop & City",
        "calendars": [
            {
                "calendarId": 34595,
                "name": "Cosy Shopping",
                "calendarGroupId": 229,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34611,
                "name": "Easy Shopping",
                "calendarGroupId": 229,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34627,
                "name": "Happy Shopping",
                "calendarGroupId": 229,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34701,
                "name": "Trendy Shopping",
                "calendarGroupId": 229,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 50,
                "name": "Vintage Shopping",
                "calendarGroupId": 229,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 227,
        "name": "Body & Mind",
        "calendars": [
            {
                "calendarId": 34555,
                "name": "Beauty & Lounge",
                "calendarGroupId": 227,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 60,
                "name": "Beauty & Soul",
                "calendarGroupId": 227,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34719,
                "name": "Wellness & Relax",
                "calendarGroupId": 227,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 196,
        "name": "Café & Nightlife",
        "calendars": [
            {
                "calendarId": 3,
                "name": "Oldies Café",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 7,
                "name": "Rock Café",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 8,
                "name": "Blues Bar",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 9,
                "name": "Soul Bar",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 11,
                "name": "Urban Street",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 1,
                "name": "Best Of Café",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 2,
                "name": "Alternatief Café",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 4,
                "name": "Schlager Café",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 5,
                "name": "Volkscafé",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 12,
                "name": "Eigentijds Café",
                "calendarGroupId": 196,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 218,
        "name": "Eat & Drink",
        "calendars": [
            {
                "calendarId": 21000,
                "name": "Barista Bar",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34583,
                "name": "Coffee Lounge",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34623,
                "name": "Grand Café",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34635,
                "name": "Haute Cuisine",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34663,
                "name": "Ristorante Italiano",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34685,
                "name": "Stijlvol Restaurant",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34689,
                "name": "Summer Terrace",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34693,
                "name": "Tapas Bar",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34697,
                "name": "Tea Jazz",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 20000,
                "name": "Quality Food",
                "calendarGroupId": 218,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 197,
        "name": "Club & Lounge",
        "calendars": [
            {
                "calendarId": 10,
                "name": "Jazz Club",
                "calendarGroupId": 197,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34647,
                "name": "Latin Club",
                "calendarGroupId": 197,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34655,
                "name": "Lounge Club",
                "calendarGroupId": 197,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34705,
                "name": "Tropical Club",
                "calendarGroupId": 197,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34551,
                "name": "Beach Club",
                "calendarGroupId": 197,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34575,
                "name": "Chill Out Zone",
                "calendarGroupId": 197,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34599,
                "name": "Dance Club",
                "calendarGroupId": 197,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34603,
                "name": "Disco Club",
                "calendarGroupId": 197,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 198,
        "name": "Fashion & Style",
        "calendars": [
            {
                "calendarId": 34567,
                "name": "Business & Style",
                "calendarGroupId": 198,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34571,
                "name": "Casual & Jeans",
                "calendarGroupId": 198,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34579,
                "name": "Cocktail & Party",
                "calendarGroupId": 198,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34587,
                "name": "Cool & Trendy",
                "calendarGroupId": 198,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34631,
                "name": "Haute Couture",
                "calendarGroupId": 198,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34681,
                "name": "Sports & Leisure",
                "calendarGroupId": 198,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34713,
                "name": "Urban & Street",
                "calendarGroupId": 198,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 40,
                "name": "Soft & Sensual",
                "calendarGroupId": 198,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 226,
        "name": "Merry Christmas",
        "calendars": [
            {
                "calendarId": 37693,
                "name": "Xmas Pure: Stijlvol",
                "calendarGroupId": 226,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 37696,
                "name": "Xmas Mix: Eigentijds",
                "calendarGroupId": 226,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 37695,
                "name": "Xmas Mix: Oldies",
                "calendarGroupId": 226,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 37694,
                "name": "Xmas Mix: Stijlvol",
                "calendarGroupId": 226,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 37691,
                "name": "Xmas Pure: Lite",
                "calendarGroupId": 226,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 37692,
                "name": "Xmas Pure: Pop & Jazz",
                "calendarGroupId": 226,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 228,
        "name": "Hotel & Lodging",
        "calendars": [
            {
                "calendarId": 30,
                "name": "Care Center",
                "calendarGroupId": 228,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34673,
                "name": "Smooth Reception",
                "calendarGroupId": 228,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34591,
                "name": "Cosy Background",
                "calendarGroupId": 228,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34607,
                "name": "Easy Background",
                "calendarGroupId": 228,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34643,
                "name": "Jazz & Piano Bar",
                "calendarGroupId": 228,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 34651,
                "name": "Lobby Lounge",
                "calendarGroupId": 228,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 311,
        "name": "Health & Care",
        "calendars": [
            {
                "calendarId": 21,
                "name": "Easy Care",
                "calendarGroupId": 311,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 23,
                "name": "Family Care",
                "calendarGroupId": 311,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 20,
                "name": "Lounge Care",
                "calendarGroupId": 311,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 24,
                "name": "Senior Care",
                "calendarGroupId": 311,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 22,
                "name": "Relax Care",
                "calendarGroupId": 311,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 312,
        "name": "Fitness & Sports",
        "calendars": [
            {
                "calendarId": 70,
                "name": "Cool Down",
                "calendarGroupId": 312,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 71,
                "name": "Warm Up",
                "calendarGroupId": 312,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            },
            {
                "calendarId": 72,
                "name": "Workout",
                "calendarGroupId": 312,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    },
    {
        "id": 316,
        "name": "Fun & Leisure",
        "calendars": [
            {
                "calendarId": 80,
                "name": "Fun & Leisure",
                "calendarGroupId": 316,
                "isPublished": true,
                "calendarItems": null,
                "type": "Tunify",
                "isVisible": true,
                "timestamp": -1
            }
        ]
    }
]}
