<div class="content-wrapper">

  <div *ngIf="showView" class="properties-container">
    <ng-container *ngFor="let audioFileProperty of audioFileProperties">
      <div *ngIf="showProperty(trackInfoOverlayData.track, audioFileProperty)" class="property">
        <div class="frontLabel">{{labelForProperty(audioFileProperty)}}</div>
        <tun-scrollable-text class="valueLabel" [text]="valueForProperty(trackInfoOverlayData.track, audioFileProperty)"></tun-scrollable-text>
      </div>
    </ng-container>
  </div>

  <div *ngIf="showView" class="action-container">

    <!-- remove from playlist -->
    <button *ngIf="removeFromPlaylistActionAvailable" class="action-button" (click)="onRemoveFromPlaylist()">
      <tun-delete-icon-v5 class="icon icon-scaled-svg-button icon-action-button"></tun-delete-icon-v5 > {{'trackOptions.list.remove' | translate}}
    </button>

     <!-- remove from favorite (only from favorite list) -->
     <button *ngIf="removeFromFavoriteActionAvailable" class="action-button" (click)="onRemoveFromPlaylist()">
      <tun-delete-icon-v5 class="icon icon-scaled-svg-button icon-action-button"></tun-delete-icon-v5 > {{'trackOptions.favorite.remove' | translate}}
    </button>

    <!-- remove from queue (only from queue) -->
    <button *ngIf="removeFromQueurAvailable" class="action-button" (click)="onRemoveFromQueue()">
      <tun-delete-icon-v5 class="icon icon-scaled-svg-button icon-action-button"></tun-delete-icon-v5 > {{'trackOptions.queue.remove' | translate}}
    </button>

    <!-- toggle favorite -->
    <button *ngIf="toggleFavoriteActionAvailable" class="action-button" (click)="onToggleFavorite()">
      <tun-thumb-up-icon-v5 *ngIf="!isFavorite" class="icon icon-scaled-svg-button icon-action-button"></tun-thumb-up-icon-v5 >
      <tun-thumb-up-active-icon-v5 *ngIf="isFavorite" class="icon icon-scaled-svg-button icon-action-button"></tun-thumb-up-active-icon-v5 >
      {{(isFavorite ? 'trackOptions.favorite.remove' : 'trackOptions.favorite.add') | translate}}
    </button>

    <!-- toggle banned -->
    <button *ngIf="toggleBannedActionAvailable" class="action-button" (click)="onToggleBanlist()">
      <tun-thumb-down-icon-v5 *ngIf="!isBanned" class="icon icon-scaled-svg-button icon-action-button"></tun-thumb-down-icon-v5 >
      <tun-thumb-down-active-icon-v5 *ngIf="isBanned" class="icon icon-scaled-svg-button icon-action-button"></tun-thumb-down-active-icon-v5 >
      {{((favBannedTrack.trackIsBanned$ | async) ? 'trackOptions.banned.remove' : 'trackOptions.banned.add') | translate}}
    </button>

    <!-- add to playlist -->
    <button *ngIf="addToPlaylistAvailable" class="action-button {{ (customPlaylistsEnabled$ | async) ? '' : 'disabled-action'}}" (click)="onAddToPlaylist()">
      <tun-add-to-playlist-v5 class="icon icon-scaled-svg-button icon-action-button"></tun-add-to-playlist-v5>{{'trackOptions.playlist.add' | translate}}
    </button>

    <!-- toggle queue -->
    <button *ngIf="toggleQueueAvailable" class="action-button {{ (addToQueueEnabled$ | async) ? '' : 'disabled-action'}}" (click)="onToggleQueue()">
      <tun-add-to-queue-v5 *ngIf="!trackIsInQueue" class="icon icon-scaled-svg-button icon-action-button"></tun-add-to-queue-v5 >
      <tun-queue-full-icon-v5 *ngIf="trackIsInQueue" class="icon icon-scaled-svg-button icon-action-button"></tun-queue-full-icon-v5>
      {{(trackIsInQueue ? 'trackOptions.queue.remove' : 'trackOptions.queue.add') | translate}}
    </button>

    <button class="action-button {{ (searchEnabled$ | async) ? '' : 'disabled-action'}}" (click)="onSearchArtist()">
      <tun-search-result-artist-icon-v5 class="icon icon-scaled-svg-button icon-action-button"></tun-search-result-artist-icon-v5 > {{'trackOptions.search.artist' | translate}}
    </button>

    <button class="action-button {{ (searchEnabled$ | async) ? '' : 'disabled-action'}}" (click)="onSearchTitle()">
      <tun-search-result-song-icon-v5 class="icon icon-scaled-svg-button icon-action-button"></tun-search-result-song-icon-v5 > {{'trackOptions.search.title' | translate}}
    </button>

    <button class="action-button {{ (searchEnabled$ | async) ? '' : 'disabled-action'}}" (click)="onSearchSimilar()">
      <tun-menu-search-icon-v5 class="icon icon-scaled-svg-button icon-action-button"></tun-menu-search-icon-v5 > {{'trackOptions.search.similar' | translate}}
    </button>

    <ng-container *ngFor="let audioFileProperty of audioFileProperties">
      <button *ngIf="showPropertySearch(trackInfoOverlayData.track, audioFileProperty)" class="action-button {{ (searchEnabled$ | async) ? '' : 'disabled-action'}}" (click)="onSearchProperty(audioFileProperty)">
        <tun-menu-search-icon-v5 class="icon icon-scaled-svg-button icon-action-button"></tun-menu-search-icon-v5 > {{searchTextForProperty(trackInfoOverlayData.track, audioFileProperty)}}
      </button>
    </ng-container>

  </div>

</div>
