import { Component } from '@angular/core';

@Component({
  selector: 'tun-musicnote-icon-v5',
  templateUrl: './musicnote-icon-v5.component.html',
  styleUrls: ['./musicnote-icon-v5.component.scss']
})
export class MusicnoteIconV5Component {

}
