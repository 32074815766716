import { AudioFileOriginType } from './enums/audioFileOriginType';

export class TrackOrigin {
    
    type: AudioFileOriginType;
    data: string;
    correlationId: string;

    constructor(trackOrigin ?: TrackOrigin ){
        if (trackOrigin){
            this.type = trackOrigin.type;
            this.data = trackOrigin.data;
            this.correlationId = trackOrigin.correlationId;
        }
    }
}