export const musicCollection_8321 = {
	"@type": "Context",
	"id": 8321,
	"title": "Easy Lounge",
	"description": "07",
	"lastChanged": null,
	"editable": false,
	"visible": true,
	"favourite": false,
	"selected": false,
	"mostPlayed": false,
	"defaultSelected": true,
	"changeableParameter": [{
		"@type": "ChangeableSelectionParameter",
		"name": "Music Feel",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Music Feel",
		"value": [{
			"value": "Electronic",
			"selected": true
		}, {
			"value": "Jazz",
			"selected": true
		}, {
			"value": "Lounge",
			"selected": true
		}, {
			"value": "Pop",
			"selected": true
		}]
	}, {
		"@type": "ChangeableSelectionParameter",
		"name": "Substyle",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Substyle",
		"value": [{
			"value": "Ambient",
			"selected": true
		}, {
			"value": "Basic",
			"selected": true
		}, {
			"value": "New Age",
			"selected": true
		}]
	}, {
		"@type": "ChangeableSelectionParameter",
		"name": "Voice",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Voice",
		"value": [{
			"value": "Instrumental",
			"selected": true
		}, {
			"value": "Not Instrumental",
			"selected": true
		}]
	}, {
		"@type": "ChangeableIntervalParameter",
		"name": "year",
		"metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
		"metadataDisplayName": "year",
		"minimum": 1964.0,
		"maximum": 2019.0,
		"step": 1.0,
		"lowerSelection": 1964.0,
		"upperSelection": 2019.0,
		"metadataDisplayMinimum": "-",
		"metadataDisplayMaximum": "+"
	}],
	"defaultChangeableParameter": [{
		"@type": "ChangeableSelectionParameter",
		"name": "Music Feel",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Music Feel",
		"value": [{
			"value": "Electronic",
			"selected": true
		}, {
			"value": "Jazz",
			"selected": true
		}, {
			"value": "Lounge",
			"selected": true
		}, {
			"value": "Pop",
			"selected": true
		}]
	}, {
		"@type": "ChangeableSelectionParameter",
		"name": "Substyle",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Substyle",
		"value": [{
			"value": "Ambient",
			"selected": true
		}, {
			"value": "Basic",
			"selected": true
		}, {
			"value": "New Age",
			"selected": true
		}]
	}, {
		"@type": "ChangeableSelectionParameter",
		"name": "Voice",
		"metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
		"metadataDisplayName": "Voice",
		"value": [{
			"value": "Instrumental",
			"selected": true
		}, {
			"value": "Not Instrumental",
			"selected": true
		}]
	}, {
		"@type": "ChangeableIntervalParameter",
		"name": "year",
		"metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
		"metadataDisplayName": "year",
		"minimum": 1964.0,
		"maximum": 2019.0,
		"step": 1.0,
		"lowerSelection": 1964.0,
		"upperSelection": 2019.0,
		"metadataDisplayMinimum": "-",
		"metadataDisplayMaximum": "+"
	}]
}