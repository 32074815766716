import { Component } from '@angular/core';

@Component({
  selector: 'tun-skip-icon-v5',
  templateUrl: './skip-icon-v5.component.html',
  styleUrls: ['./skip-icon-v5.component.scss']
})
export class SkipIconV5Component {

}
