<div class="container" (tunResize)="onResize()">

   <!-- Labels for slider with multiple labels. -->
   <div class="options" *ngIf="!isSingleLabel">
    <ng-container *ngFor="let option of options, let last = last, let first = first">
      <div #column class="column {{(first && options.length < 4) ? 'first-column' : ''}} {{(last && options.length < 4) ? 'last-column' : ''}}">
        {{ option.label }}
      </div>
    </ng-container>
  </div>

  <!-- Label for slider with a single label. -->
  <div class="options options-single" *ngIf="isSingleLabel">
    <div class="lower-value">
      {{ getFirstOptionLabel() }}
    </div>
    <div class="upper-value">
      {{ getLastOptionLabel() }}
    </div>
  </div>

  <!-- lines & thumb content -->
  <div class="sliderContent" >


    <!-- Selected and unselected lines. -->
    <div class="lines-container" (click)="lineClicked($event)">

      <div class="lines">

        <div
          #unselectedline
          class="line unselected-line"

        ></div>

        <div
          class="line selected-line {{sliderColor}}"
          [style.left]="leftSliderLeft"
          [style.right]="rightSliderRight"
          [style.transition]="transitionSpeed"
          [style.-webkit-transition]="transitionSpeed"
          [style.-moz-transition]="transitionSpeed"
        ></div>
        <div
          #verticalLine
          class="vertical-line"
          *ngFor="let option of options | slice: 1; let i = index"
          [style.left.%]="(100 / getAmountOfOptions()) * (i + 1)"
        ></div>

      </div>

    </div>

    <!-- Left thumb -->
    <div
        #leftSliderBox
        class="thumb-box thumb-box-left"
        [style.left]="leftSliderLeft"
        [style.transition]="transitionSpeed"
    >

        <div class="thumb thumb-left {{sliderColor}}"></div>

    </div>


      <!-- Right thumb -->
      <div
        #rightSliderBox
        class="thumb-box thumb-box-right"
        [style.right]="rightSliderRight"
        [style.transition]="transitionSpeed"
      >
        <div class="thumb thumb-right {{sliderColor}}"></div>
      </div>

  </div>


</div>
