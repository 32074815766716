export const musicCollection_8991 = {
    "@type": "Context",
    "id": 8991,
    "title": "Drum & Bass",
    "description": "13",
    "lastChanged": null,
    "changeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Substyle",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Substyle",
            "value": [
                {
                    "value": "Drum & Bass",
                    "selected": true
                },
                {
                    "value": "Dubstep",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1991,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1991,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Energy",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Energy",
            "minimum": 4,
            "maximum": 7,
            "step": 1,
            "lowerSelection": 4,
            "upperSelection": 6,
            "metadataDisplayMinimum": "4",
            "metadataDisplayMaximum": "7"
        }
    ],
    "defaultChangeableParameter": [
        {
            "@type": "ChangeableSelectionParameter",
            "name": "Substyle",
            "metadataDisplayType": "TEXT_CHECKBOX_SELECTION",
            "metadataDisplayName": "Substyle",
            "value": [
                {
                    "value": "Drum & Bass",
                    "selected": true
                },
                {
                    "value": "Dubstep",
                    "selected": true
                }
            ]
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "year",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "year",
            "minimum": 1991,
            "maximum": 2019,
            "step": 1,
            "lowerSelection": 1991,
            "upperSelection": 2019,
            "metadataDisplayMinimum": "-",
            "metadataDisplayMaximum": "+"
        },
        {
            "@type": "ChangeableIntervalParameter",
            "name": "Energy",
            "metadataDisplayType": "NUMERIC_SLIDER_RANGE_SELECTION",
            "metadataDisplayName": "Energy",
            "minimum": 4,
            "maximum": 7,
            "step": 1,
            "lowerSelection": 4,
            "upperSelection": 6,
            "metadataDisplayMinimum": "4",
            "metadataDisplayMaximum": "7"
        }
    ],
    "visible": true,
    "editable": false,
    "favourite": false,
    "selected": false,
    "mostPlayed": false,
    "defaultSelected": false
};