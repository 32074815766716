<div class="content">

  <!-- not able to connect as player -->
  <ng-container *ngIf="applicationMode == ApplicationMode.playerMode">
    <div  class="title">
      {{'playerNotAvailable.title' | translate}}
  </div>


    <div class="info">
      {{'playerNotAvailable.info1' | translate}}
    </div>

    <div class="devices">
      <div *ngIf="(zoneConnectionPlayerApplicationInfo$ | async) != null">
        {{(zoneConnectionPlayerApplicationInfo$ | async).deviceInfo}}
      </div>
    </div>

    <div *ngIf="(enableConnect$ | async)" class="info infoLast">
      {{'playerNotAvailable.info2' | translate}}
    </div>
    <div *ngIf="!(enableConnect$ | async)" class="info infoLast">
      {{'playerNotAvailable.noConnect.info2' | translate}}
    </div>
  </ng-container>

  <!-- not able to connect as remote -->
  <ng-container *ngIf="applicationMode == ApplicationMode.remoteMode">
    <div  class="title">
      {{'connectNotAvailable.title' | translate}}
  </div>


    <div class="info">
      {{'connectNotAvailable.info1' | translate}}
    </div>

    <div class="devices">
      <div *ngIf="(zoneConnectionPlayerApplicationInfo$ | async) != null">
        {{(zoneConnectionPlayerApplicationInfo$ | async).deviceInfo}}
      </div>
    </div>

    <div class="info infoLast">
      {{'connectNotAvailable.info2' | translate}}
    </div>
  </ng-container>



  <div class="button-container">
    <button type="submit"
      (click)="onClose()" class="button button-ok"
    >
        OK
    </button>
  </div>

</div>
