<div #playerView (tunResize)="onResize($event)" class="wrapper">

  <div
    *ngIf="!useTunifyV4 && sizeCalculated"
    class="redirect-container playerBackground"
  >

    <div class="fade-borders">
      <div
        [style.height.px]="heightPerItem$ | async"
        [style.padding-top.px]="2 * (heightPerItem$ | async)"
      >
        <tun-tunify-icon></tun-tunify-icon>
      </div>

      <div class="spinner-container">
        <mat-spinner class="redirect-spinner"></mat-spinner>
      <span class="redirect-text">{{'general.redirecting' | translate}}</span>
      </div>
    </div>


  </div>

</div>



