import { OnDestroy } from '@angular/core';
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AnimationBuilder,
  style,
  animate,
  keyframes,
  AnimationPlayer,
} from '@angular/animations';

@Component({
  selector: 'tun-popup-arrow',
  templateUrl: './popup-arrow.component.html',
  styleUrls: ['./popup-arrow.component.scss'],
})
export class PopupArrowComponent implements OnInit, OnDestroy {
  // === Constants === //
  static readonly ARROW_OFFSET = 5;

  // === Props === //
  @Input() public direction = 'arrow-up';
  @Input() isShown: boolean;
  @Input() darkThemed: boolean = false;

  // === Getters === //
  static get arrowSize(): number {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    const baseArrowSize = 8;
    const breakpoint = 700;
    const extraSize = 1.5;

    if (windowWidth / windowHeight >= 2) {
      return baseArrowSize;
    }

    return baseArrowSize + (windowWidth / breakpoint) * extraSize;
  }

  get arrowSize(): number {
    return PopupArrowComponent.arrowSize;
  }

  get theme(): string{
    return this.darkThemed?"darkThemed":"";
  }

  constructor(
    private _animationBuilder: AnimationBuilder,
    private _elementRef: ElementRef,
    public cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(){
    this.cleanupAnimation();
  }

  private animationPlayer: AnimationPlayer = null;
  private cleanupAnimation(){
    if (this.animationPlayer){
      this.animationPlayer.destroy();
    }
  }


  public fadeArrowIn(duration: number) {
    const animationBuilder = this._animationBuilder.build(
      this._fadeInLate(duration, this.isShown)
    );
    this.cleanupAnimation();
    this.animationPlayer = animationBuilder.create(this._elementRef.nativeElement);
    //player.onDone(()=>player.destroy());
    this.animationPlayer.play();
  }

  private _fadeInLate(duration: number, show: boolean, from = 0, to = 1) {
    return show
      ? [
          style({}),
          animate(
            duration,
            keyframes([
              style({ opacity: from, offset: 0 }),
              style({ opacity: from, offset: 0.8 }),
              style({ opacity: to, offset: 1 }),
            ])
          ),
        ]
      : [
          style({}),
          animate(
            duration,
            keyframes([
              style({ opacity: to, offset: 0 }),
              style({ opacity: from, offset: 0.4 }),
            ])
          ),
        ];
  }
}
