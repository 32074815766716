import { Component } from '@angular/core';

@Component({
  selector: 'tun-thumb-down-active-icon-v5',
  templateUrl: './thumb-down-active-icon-v5.component.html',
  styleUrls: ['./thumb-down-active-icon-v5.component.scss']
})
export class ThumbDownActiveIconV5Component {

}
