import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from '../loggers/logger.service';
import { merge, Observable, Subject, timer } from 'rxjs';
import { PlayTokenService } from './play-token.service';
import { Config } from '@service/config';
import { filter, take, takeUntil } from 'rxjs/operators';
import { PlayableAudio } from '../../model/audioFile';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { ApplicationMode } from '@service/authentication/zone-connections.service';

class DtoMediaUrlRequest{
  playToken: string;
  secure: boolean = true;
}

interface DtoMediaUrl{
  url: string;
  validForSeconds: number;
}

export enum MediaUrlOrigin{
  none = 'none',
  dto = 'dto',
  public = 'public'
}

export class MediaUrl{
  url: string;
  origin: MediaUrlOrigin = MediaUrlOrigin.none;

  //validForSeconds: undefined = forever valid
  private _validForSeconds = undefined;
  public set validForSeconds(seconds: number) {
    this._validForSeconds = seconds;
    this.validityRefTimeStamp = new Date().getTime();
  }
  public get validForSeconds(): number {
    if (this._validForSeconds != undefined && this.validityRefTimeStamp != undefined) {
      return this._validForSeconds - (new Date().getTime() - this.validityRefTimeStamp) / 1000;
    }
    return undefined;
  }


  public get isValid(): boolean{
    let validitySeconds = this.validForSeconds;
    return validitySeconds == undefined || validitySeconds > 0;
  }

  //we want to have at least 20 seconds of validity to complete the full download
  public get isValidForNewDownload(): boolean{
    let validitySeconds = this.validForSeconds;
    return validitySeconds == undefined || validitySeconds > 20;
  }

  //the timestamp the validity was set
  private validityRefTimeStamp = undefined;

  constructor(mediaUrl?: DtoMediaUrl){
    if (mediaUrl){
      this.url = mediaUrl.url;
      this.validForSeconds = mediaUrl.validForSeconds;
      this.origin = MediaUrlOrigin.dto;
    }
  }

  public toString(){
    return `[MediaUrl (origin: ${this.origin}): ${this.url}, validForSeconds: ${this.validForSeconds ? this.validForSeconds : 'undefined'}]`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AudiofileUrlService implements OnDestroy {

  private LOGGER_CLASSNAME = 'AudiofileUrlService';

  constructor(private http: HttpClient,
              private zoneConnectionsService: ZoneConnectionsService,
              private playTokenService: PlayTokenService,
              private loggerService: LoggerService) {
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public loadAudioFileUrls(playableAudio: PlayableAudio): Observable<Array<MediaUrl>> {

    const httpUrlsSubject: Subject<MediaUrl[]> = new Subject<MediaUrl[]>();
    //const httpUrls$: Observable<string[]> = httpUrlsSubject.asObservable();


    if (this.zoneConnectionsService.activeZoneConnection != null && this.zoneConnectionsService.applicationMode === ApplicationMode.playerMode) {

      const audioFileId = playableAudio.id;
      const zoneId = this.zoneConnectionsService.activeZoneConnection.zoneId;

      //wait until we actually have a playtoken
      this.playTokenService.playToken$
      .pipe(
        filter(result => result != null),
        take(1),
        takeUntil(
          merge(
            this.destroyed$,
            this.zoneConnectionsService.activeZoneConnection$.pipe(filter(zoneConnection => zoneConnection == null || zoneConnection.zoneId != zoneId))
          )
        )
      ).subscribe(
          playToken => {

            const requestBody = new DtoMediaUrlRequest();
            requestBody.playToken = playToken;


            const url = Config.api4Url_mediaUrl(this.zoneConnectionsService.activeZoneConnection.zoneId, audioFileId);

            this.loggerService.debug( this.LOGGER_CLASSNAME, 'loadAudioFileUrls', 'about to execute loadAudioFileUrls request');
            this.http.post<DtoMediaUrl[]>(
                        url, requestBody
            ).subscribe(
              (data) => {

                if (data.length > 0){
                  //bypass the service workers for media urls -> service workers can't access the media files
                  const mediaUrls = data.map((dtoMediaUrl) => {
                    const mediaUrl = new MediaUrl(dtoMediaUrl);

                    //Bypass is now done through the config
                    //mediaUrl.url = (mediaUrl.url.includes('?') ? mediaUrl.url + '&ngsw-bypass=true' : mediaUrl.url + '?ngsw-bypass=true');

                    //test -> very fast unavailable
                    //mediaUrl.validForSeconds = 60;
                    return mediaUrl;
                  });

                  //test m4a
                  /*
                  const mediaUrl = new MediaUrl({'url':"http://rescue-cdn.tunify.com/aac/01GoodKisser.m4a", 'validForSeconds': 600})
                  const mediaUrls2 = [mediaUrl];
                  */

                  httpUrlsSubject.next(mediaUrls);
                  httpUrlsSubject.complete();
                }
              },
              error => {
                if (error instanceof HttpErrorResponse && error.status === 405 && error.error != null && error.error.message != null && error.error.message.toLowerCase().indexOf('wrong playtoken') >= 0) {
                  this.loggerService.warn( this.LOGGER_CLASSNAME, 'loadAudioFileUrls response', 'playToken is invalid -> going to invalidate');
                  this.playTokenService.invalidatePlayToken();
                }else{
                  const errMsg = (error.message) ? error.message :
                  error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                  console.error(errMsg);
                }
                httpUrlsSubject.next([]);
                timer(0).subscribe(() => {
                  httpUrlsSubject.complete();
                });
              }
            );
          }
      );
    }else {
      httpUrlsSubject.next([]);
      timer(0).subscribe(() => {
        httpUrlsSubject.complete();
      });
    }

    return httpUrlsSubject;
  }
}
