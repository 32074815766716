import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Calendar } from '@model/calendar';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { CalendarService } from '@service/calendar.service';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-edit-title-for-calendar-view',
  templateUrl: './edit-title-for-calendar-view.component.html',
  styleUrls: ['./edit-title-for-calendar-view.component.scss']
})
export class EditTitleForCalendarViewComponent implements OnDestroy, AfterViewInit {

  // === ViewChildren === //
   @ViewChild('nameInput')
   nameInput: ElementRef;

  //null = create a playlist
  private _calendar: Calendar;
  @Input()
  public set calendar(calendar: Calendar){
    this._calendar = calendar;
    if (calendar != null){
      this.currentName = this._calendar.name;
    }else{
      this.currentName = "";
    }

  }
  public get calendar(){
    return this._calendar;
  }

  private _canReceiveFocus = false;
  @Input()
  public set canReceiveFocus(value: boolean){
    this._canReceiveFocus = value;
    this.focusIfPossible();
  }
  public get canReceiveFocus(){
    return this._canReceiveFocus;
  }

  private componentDestroyed$ = new Subject<boolean>();

  @Output() back = new EventEmitter<void>();

  onBack(){
    this.back.emit();
  }

  public currentName = "";
  onNameChange(name: string){
    this.currentName = name;
  }

  constructor(
    private appV5StateService: AppV5StateService,
    private calendarService: CalendarService
  ){

  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$ = null;
  }

  private _initDone = false;
  private set initDone(value: boolean){
    this._initDone = value;
    this.focusIfPossible();

  }
  private get initDone(){
    return this._initDone;
  }
  private focusIfPossible(){
    if (this.initDone && this.canReceiveFocus){
      if (this.nameInput) {
        this.nameInput.nativeElement.focus();
      }
    }
  }

  ngAfterViewInit(): void {

    //we need to set the focus after the views animation has finished
    timer(100)
    .pipe(
      takeUntil(
        this.componentDestroyed$
      )
    )
    .subscribe(
      () => {
        this.initDone = true;
      }
    )
  }

  onCommit(){
    if (this.calendar == null){
      //create calendar
      const calendar = new Calendar()
      calendar.name = this.currentName;
      const creatingCalendarObservable = this.calendarService.createCustomCalendar(calendar)

      this.appV5StateService.showCalendarInTunifyGreen(calendar);
      this.appV5StateService.selectCreatedCalendarAfterCreation(calendar, creatingCalendarObservable);

    }else{
      this.calendar.name = this.currentName;
      this.calendarService.saveCalendar(this.calendar);
      this.onBack();
    }

  }
}
