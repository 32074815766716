<div #calendarPanel class="wrapper">

    <ng-container *ngIf="showingCalendar$ | async">

      <!-- Special calendar states indicator -->

      <div
        *ngIf="calendarState != CalendarState.normal"
        [ngSwitch]="calendarState"
        class="feedback-container"
      >
        <div
          *ngSwitchCase="CalendarState.loadError"
          class="error-feedback-container"
        >
          <p>
            {{'calendar.contentPanel.load.error' | translate }}
          </p>
          <button (click)="retryClick()" class="retryButton">
            <p>
              {{'general.tryAgain' | translate }}
            </p>
          </button>
        </div>

        <ng-container *ngSwitchCase="CalendarState.loading">
          <tun-loader-v5 class="loader"></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.clearingCurrentDay">
          <tun-loader-v5 text="clearing day .."></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.creating">
          <tun-loader-v5 text="calendar.contentPanel.create.busy"></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.creatingCopy">
          <tun-loader-v5 text="calendar.contentPanel.createCopy.busy"></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.deleting">
          <tun-loader-v5 text="calendar.contentPanel.delete.busy"></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.undoingDelete">
          <tun-loader-v5 text="calendar.contentPanel.undoDelete.busy"></tun-loader-v5>
        </ng-container>

        <ng-container *ngSwitchCase="CalendarState.copyingDay">
          <tun-loader-v5 text="calendar.contentPanel.copyDay.busy"></tun-loader-v5>
        </ng-container>

        <div
          *ngSwitchCase="CalendarState.deleted"
          class="error-feedback-container"
        >
          <p translate>calendar.contentPanel.delete.done</p>
          <button (click)="onBack()" class="retryButton">
            <p translate>playlist.status.deleted.close</p>
          </button>
        </div>
      </div>

      <!-- normal calendar content-->
      <div *ngIf="calendarState == CalendarState.normal" class="normalContent">

        <tun-timeline-view
          #timeLineView
          class="left-side"
          [currentTimeMultiplier]="currentTimeMultiplier"
          [hours]="hours"
        ></tun-timeline-view>

        <div class="animation-container" [style.height.rem]="24*CALENDAR_ITEM_BASE_HEIGTH_REM">
          <ng-container *ngFor="let day of daysOfWeek; let dayIndex = index">
            <div
              class="calendar-items-columns"
              *ngIf="dayIndex === currentIndex"
              [@carousel]="{
                value: animationDirection,
                params: {
                  duration: CAROUSEL_DURATION
                }
              }"
            >
              <div class="first-column" #column>

                <ng-container *ngIf="editMode$ | async">
                  <ng-container
                    *ngFor="let hour of hoursForDropdown; let i = index"
                  >
                    <tun-create-calendar-item
                      [time]="hour"
                      [position]="1"
                      [style.pointer-events]="(isMouseDown$ | async) ? 'none' : 'all'"
                      (createMusicChannelCalendarItem)="onCreateMusicChannelCalendarItem($event)"
                      (createPlaylistCalendarItem)="onCreatePlaylistCalendarItem($event)"
                    >

                    </tun-create-calendar-item>
                    <tun-create-calendar-item
                      [time]="hour + 0.5"
                      [position]="1"
                      [style.pointer-events]="(isMouseDown$ | async) ? 'none' : 'all'"
                      (createMusicChannelCalendarItem)="onCreateMusicChannelCalendarItem($event)"
                      (createPlaylistCalendarItem)="onCreatePlaylistCalendarItem($event)"
                    >

                    </tun-create-calendar-item>
                  </ng-container>
                </ng-container>

              <!--
                <ng-container *ngIf="inEditMode">
                  <ng-container
                    *ngFor="let hour of hoursForDropdown; let i = index"
                  >
                    <tun-calendar-dropdown
                      (dropdown)="onDropdown($event)"
                      [style.pointer-events]="isMouseDown ? 'none' : 'all'"
                      [time]="hour"
                      [top]="heightPerItem / 2 + i * heightPerItem"
                      [position]="1"
                      (calendarItemDrop)="onCalendarItemDrop($event)"
                      (dropdownitemselect)="
                        onDropdownItemSelect($event, i, 0, 1)
                      "
                    ></tun-calendar-dropdown>
                    <tun-calendar-dropdown
                      (dropdown)="onDropdown($event)"
                      [style.pointer-events]="isMouseDown ? 'none' : 'all'"
                      [time]="hour + 0.5"
                      [top]="
                        heightPerItem / 2 +
                        i * heightPerItem +
                        heightPerItem / 2
                      "
                      [position]="1"
                      (calendarItemDrop)="onCalendarItemDrop($event)"
                      (dropdownitemselect)="
                        onDropdownItemSelect($event, i, 30, 1)
                      "
                    ></tun-calendar-dropdown>
                  </ng-container>
                </ng-container>
                -->
                <ng-container
                  *ngFor="
                    let calendarItem of showingCalendar.calendarItems
                      | calendarItemsDayPositionFilter: currentIndex:1
                  "
                >
                  <tun-calendar-item-view
                    [calendarItem]="calendarItem"
                    [editMode]="editMode$ | async"
                    [isSelected]="isActive(calendarItem)"
                    (openTweakPanel)="onOpenTweakPanel($event)"
                    (selectMusicChannelForCalendarItem)="onSelectMusicChannel($event)"
                    (selectPlaylistForCalendarItem)="onSelectPlaylist($event)"
                    (deleteItem)="onDeleteItem($event)"
                  ></tun-calendar-item-view>
                </ng-container>
              </div>
              <div class="second-column">
                <!--
                <ng-container *ngIf="inEditMode">
                  <ng-container
                    *ngFor="let hour of hoursForDropdown; let i = index"
                  >
                    <tun-calendar-dropdown
                      (dropdown)="onDropdown($event)"
                      [style.pointer-events]="isMouseDown ? 'none' : 'all'"
                      [time]="hour"
                      [top]="heightPerItem / 2 + i * heightPerItem"
                      [position]="2"
                      (calendarItemDrop)="onCalendarItemDrop($event)"
                      (dropdownitemselect)="
                        onDropdownItemSelect($event, i, 0, 2)
                      "
                    ></tun-calendar-dropdown>
                    <tun-calendar-dropdown
                      (dropdown)="onDropdown($event)"
                      [style.pointer-events]="isMouseDown ? 'none' : 'all'"
                      [time]="hour + 0.5"
                      [top]="
                        heightPerItem / 2 +
                        i * heightPerItem +
                        heightPerItem / 2
                      "
                      [position]="2"
                      (calendarItemDrop)="onCalendarItemDrop($event)"
                      (dropdownitemselect)="
                        onDropdownItemSelect($event, i, 30, 2)
                      "
                    ></tun-calendar-dropdown>
                  </ng-container>
                </ng-container>
                -->

                <ng-container *ngIf="editMode$ | async">
                  <ng-container
                    *ngFor="let hour of hoursForDropdown; let i = index"
                  >
                    <tun-create-calendar-item
                      [time]="hour"
                      [position]="2"
                      [style.pointer-events]="(isMouseDown$ | async) ? 'none' : 'all'"
                      (createMusicChannelCalendarItem)="onCreateMusicChannelCalendarItem($event)"
                      (createPlaylistCalendarItem)="onCreatePlaylistCalendarItem($event)"
                    >

                    </tun-create-calendar-item>
                    <tun-create-calendar-item
                      [time]="hour + 0.5"
                      [position]="2"
                      [style.pointer-events]="(isMouseDown$ | async) ? 'none' : 'all'"
                      (createMusicChannelCalendarItem)="onCreateMusicChannelCalendarItem($event)"
                      (createPlaylistCalendarItem)="onCreatePlaylistCalendarItem($event)"
                    >

                    </tun-create-calendar-item>
                  </ng-container>
                </ng-container>


                <ng-container
                  *ngFor="
                    let calendarItem of showingCalendar.calendarItems
                      | calendarItemsDayPositionFilter: currentIndex:2
                  "
                >
                  <tun-calendar-item-view
                    [calendarItem]="calendarItem"
                    [editMode]="editMode$ | async"
                    [isSelected]="isActive(calendarItem)"
                    (openTweakPanel)="onOpenTweakPanel($event)"
                    (selectMusicChannelForCalendarItem)="onSelectMusicChannel($event)"
                    (selectPlaylistForCalendarItem)="onSelectPlaylist($event)"
                    (deleteItem)="onDeleteItem($event)"
                  ></tun-calendar-item-view>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>




  </div>
