<svg xmlns="http://www.w3.org/2000/svg" 
version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 16 24"
  enable-background="new 0 0 16 24"
  xml:space="preserve">
<g fill="currentColor">
  <path d="M8 0C12.4 0 16 3.6 16 8 16 11.5 11 19.8 8.8 23.1 8.7 23.4 8.3 23.6 8 23.6 7.7 23.6 7.3 23.4 7.2 23.1 5 19.8 0 11.5 0 8 0 3.6 3.6 0 8 0ZM8 1C4.1 1 1 4.1 1 8 1 10.7 4.6 17.3 8 22.6 9.6 20 15 11.4 15 8 15 4.1 11.9 1 8 1ZM8 4.5C9.9 4.5 11.5 6.1 11.5 8 11.5 9.9 9.9 11.5 8 11.5 6.1 11.5 4.5 9.9 4.5 8 4.5 6.1 6.1 4.5 8 4.5ZM8 5.5C6.6 5.5 5.5 6.6 5.5 8 5.5 9.4 6.6 10.5 8 10.5 9.4 10.5 10.5 9.4 10.5 8 10.5 6.6 9.4 5.5 8 5.5Z" />
</g>
</svg>