import { Component } from '@angular/core';
import { ApplicationMode, ZoneConnectionsService } from '@service/authentication/zone-connections.service';
import { SubscriptionsService } from '@service/subscriptions.service';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { ExternalLinksService } from '../../../../../../services/app/external-links.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-settings-zone-info',
  templateUrl: './settings-zone-info.component.html',
  styleUrls: ['./settings-zone-info.component.scss']
})
export class SettingsZoneInfoComponent {

  get locationName(){
    if (this.zoneConnectionsService.activeZoneConnection != null && this.zoneConnectionsService.activeZoneConnection.locationName){
      return this.zoneConnectionsService.activeZoneConnection.locationName;
    }
    return '';
  }

  get subscriptionName$(){
    return this.subscriptionsService.subscriptionInfo$
    .pipe(
      map(subscriptionInfo => {
        if (subscriptionInfo != null){
          return subscriptionInfo.name;
        }
        return "";
      })
    );
  }

  get lastValidDate$(){
    return this.subscriptionsService.subscriptionInfo$
    .pipe(
      map(subscriptionInfo => {
        if (subscriptionInfo != null){
          return subscriptionInfo.lastValidDate;
        }
        return null;
      })
    );
  }

  get autoRenewalDate$(){
    return this.subscriptionsService.subscriptionInfo$
    .pipe(
      map(subscriptionInfo => {
        if (subscriptionInfo != null){
          return subscriptionInfo.autoRenewalDate;
        }
        return null;
      })
    );
  }

  get remoteMode$(){
    return this.zoneConnectionsService.applicationMode$
    .pipe(map(applicationMode => applicationMode == ApplicationMode.remoteMode))
  }

  constructor(
    private zoneConnectionsService: ZoneConnectionsService,
    private externalLinksService: ExternalLinksService,
    private subscriptionsService: SubscriptionsService,

  ) {

  }

  onOpenZone(){
    this.externalLinksService.openZoneBuyPage();
  }

  onLogout(){
    this.zoneConnectionsService.deactivateCurrentZoneConnection(null);
  }
}
