import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { musicChannelGroups } from '@service/mock/musicChannelGroups';
import { SearchMusicChannelService } from '@service/search-music-channel.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tun-search-music-channels-view',
  templateUrl: './search-music-channels-view.component.html',
  styleUrls: ['./search-music-channels-view.component.scss']
})
export class SearchMusicChannelsViewComponent implements OnDestroy {

  @Output() startSearchText = new EventEmitter<void>();

  public get searchMusicChannelGroupsLoading$(){
    return this.searchMusicChannelService.loading$
  }

  public get searchMusicChannelGroupsError$(){
    return this.searchMusicChannelService.loadingError$
  }

  public get searchMusicChannels$(){
    return this.searchMusicChannelService.musicChannelGroups$
    .pipe(
      map(musicChannelGroups=>{
        const musicChannels = []
        if (musicChannelGroups){
          musicChannelGroups.forEach(musicChannelGroup => {
            musicChannels.push(...musicChannelGroup.musicChannels)
          });
        }

        return musicChannels
      })
    )
  }

  constructor(
    private searchMusicChannelService: SearchMusicChannelService,
    private appV5StateService: AppV5StateService
  ) {
    if (this.searchMusicChannelService.musicChannelGroups == null && !this.searchMusicChannelService.loading){
      this.searchMusicChannelService.loadMusicChannelGroups();
    }
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onRetry(){
    this.searchMusicChannelService.loadMusicChannelGroups();
  }

  public onSearchText(){
    this.appV5StateService.openTextSearch(true);
  }

}
