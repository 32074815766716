export const musicChannelGroups_limit = {
  "timestamp": 1232,
  "musicChannelGroups":[
    {
        "id": 1,
        "name": "Quick start",
        "musicChannels": [
            {
                "id": 134,
                "name": "Popular & Nostalgic",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8619,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8399,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8149,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8134,
                        "title": "Dutch",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8310,
                        "title": "Peaceful",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8005,
                        "title": "Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8147,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8127,
                        "title": "Stimulating",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8312,
                        "title": "Dance",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8204,
                        "title": "Soul & Funk",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8311,
                        "title": "R&B",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8143,
                        "title": "Disco",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8202,
                        "title": "Pop",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8133,
                        "title": "Pop Rock",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8252,
                        "title": "Oldies Pop",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8315,
                        "title": "Oldies Rock",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 146,
                "name": "Young & Alternative",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8622,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8181,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8070,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8182,
                        "title": "Indie Pop",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8200,
                        "title": "Easy",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8073,
                        "title": "Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8074,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8201,
                        "title": "Dynamic",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8218,
                        "title": "Old Skool",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8992,
                        "title": "Nu Urban",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8185,
                        "title": "Rock",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8077,
                        "title": "Metal",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8991,
                        "title": "Drum & Bass",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8219,
                        "title": "House",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8072,
                        "title": "Electro",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8184,
                        "title": "Techno",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 155,
                "name": "Stylish & Artistic",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 9000,
                        "title": "Pop Classical",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8999,
                        "title": "Pop Jazzy",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8345,
                        "title": "Violins",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8355,
                        "title": "Piano",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8227,
                        "title": "Easy Classical",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8150,
                        "title": "Easy Jazz",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8321,
                        "title": "Easy Lounge",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8993,
                        "title": "Easy Listening",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8348,
                        "title": "Cosy Classical",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8349,
                        "title": "Cosy Jazz",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8320,
                        "title": "Cosy Lounge",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8997,
                        "title": "Cosy Chanson",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8994,
                        "title": "Bright Classical",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8995,
                        "title": "Bright  Jazz",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8996,
                        "title": "Bright Lounge",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8998,
                        "title": "Bright Chanson",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8317,
                        "title": "Bossa Nova",
                        "description": "17",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": false
                    },
                    {
                        "@type": "Context",
                        "id": 8212,
                        "title": "Funky Groove",
                        "description": "17",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": false
                    }
                ]
            },
            {
                "id": 165,
                "name": "Lite & Cosy",
                "musicChannelGroupId": 1,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8620,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8220,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8224,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8233,
                        "title": "Oldies",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8225,
                        "title": "Cosy",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8226,
                        "title": "Bright",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 9004,
                        "title": "Dutch",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8221,
                        "title": "Schlagers",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8223,
                        "title": "Party",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 9005,
                        "title": "Après Ski",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8232,
                        "title": "Disco",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8231,
                        "title": "Boogie",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 9006,
                        "title": "Smartlap",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8229,
                        "title": "Levenslied",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8089,
                        "title": "Country Lite",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8228,
                        "title": "Traditional Music",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            }
        ]
    },
    {
        "id": 2,
        "name": "Create mood",
        "musicChannels": [
            {
                "id": 142,
                "name": "Hit Factory",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8619,
                        "title": "Hit Parade Pop",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Playlist",
                        "id": 8621,
                        "title": "Hit Parade Dance",
                        "description": "02",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8665,
                        "title": "2014 - 2018  Pop",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8667,
                        "title": "2014 - 2018  Dance",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Playlist",
                        "id": 8977,
                        "title": "Hitparade Urban",
                        "description": "05",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Playlist",
                        "id": 8622,
                        "title": "Hit Parade Alternative",
                        "description": "06",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8358,
                        "title": "2014 - 2018  Urban",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8675,
                        "title": "2014 - 2018  Alternative",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Playlist",
                        "id": 8620,
                        "title": "Hit Parade Lite",
                        "description": "09",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8176,
                        "title": "Best Of The 10's",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8666,
                        "title": "2014 - 2018  Lite",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8178,
                        "title": "Best Of The 00's",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8177,
                        "title": "Best Of The 90's",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8180,
                        "title": "Best Of The 70's",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8108,
                        "title": "Best Of The 80's",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8038,
                        "title": "Best Of The 60's",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 281,
                "name": "Barista Bar",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8855,
                        "title": "Hitparade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8856,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8827,
                        "title": "Acoustic",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8840,
                        "title": "Oldies",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8841,
                        "title": "Social",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8846,
                        "title": "Bright",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8849,
                        "title": "Alternative",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8853,
                        "title": "Americana",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8857,
                        "title": "Pure Soul",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8858,
                        "title": "Pure Jazz",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8851,
                        "title": "Soulful",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8829,
                        "title": "Jazzy",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8859,
                        "title": "Urban Grooves",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8838,
                        "title": "Rhythm & Blues",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8828,
                        "title": "Groovy",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8852,
                        "title": "Funky",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 124,
                "name": "Chill Out Zone",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8095,
                        "title": "2014 - 2018",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8027,
                        "title": "New Age",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8030,
                        "title": "Relax",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8780,
                        "title": "Ambient",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8020,
                        "title": "Chill Jazz",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8782,
                        "title": "Chill Lounge",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8040,
                        "title": "Easy Pop",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8779,
                        "title": "Dream Pop",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8777,
                        "title": "Piano",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8783,
                        "title": "Guitar",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8778,
                        "title": "Violins",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8097,
                        "title": "Voices",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8784,
                        "title": "Nature",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8039,
                        "title": "Electronic",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8010,
                        "title": "Trip Hop",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8781,
                        "title": "Down Beat",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 192,
                "name": "Club Lounge",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8011,
                        "title": "Trendy",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8770,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8021,
                        "title": "Down Beat",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8785,
                        "title": "Up Beat",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8025,
                        "title": "Funky & Jazzy",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8042,
                        "title": "Jazzy Bright",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8033,
                        "title": "Bossa Nova",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8968,
                        "title": "Beach House",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8018,
                        "title": "Deep Loungy",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8191,
                        "title": "Deep Soulful",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8775,
                        "title": "Deep Beats",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8022,
                        "title": "Deep Grooves",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8772,
                        "title": "Deep Electronic",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8123,
                        "title": "Deep Instrumental",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8019,
                        "title": "Minimal House",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8773,
                        "title": "Tech House",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 126,
                "name": "Club Tropical",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8008,
                        "title": "2014 - 2018",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8766,
                        "title": "Latin Dances",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8324,
                        "title": "BBQ Mix",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8080,
                        "title": "Tapas Latino",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8081,
                        "title": "Easy",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8124,
                        "title": "Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8083,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8091,
                        "title": "Dynamic",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8092,
                        "title": "Tropical Party",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8776,
                        "title": "Tropical Lite",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8128,
                        "title": "Beach Club",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8126,
                        "title": "Salsa Merengue",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8088,
                        "title": "Jamaican Feel",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8098,
                        "title": "Caribbean Sound",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8096,
                        "title": "Spanish Roots",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8767,
                        "title": "Mexican Waves",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 133,
                "name": "Easy Listening",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8171,
                        "title": "Autumn Leaves",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8170,
                        "title": "Winter Memories",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8195,
                        "title": "Spring Swing",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8067,
                        "title": "Summer Breeze",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8093,
                        "title": "Lite Easy",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8125,
                        "title": "Lite Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8172,
                        "title": "Lite Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8192,
                        "title": "Lite Classical",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8013,
                        "title": "Aperitif Piano",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8198,
                        "title": "Aperitif Violin",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8196,
                        "title": "Lounge Easy",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8028,
                        "title": "Lounge Cosy",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8194,
                        "title": "Tearoom Jazz",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8331,
                        "title": "Smooth Reception",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8193,
                        "title": "Pop Corner",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8197,
                        "title": "Groove Gallery",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 135,
                "name": "Fashion Store",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8373,
                        "title": "Trendy Pop",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8969,
                        "title": "Trendy Dance",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8970,
                        "title": "Trendy Urban",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8383,
                        "title": "2014 - 2018",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8384,
                        "title": "Jeans",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8382,
                        "title": "Sport",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8343,
                        "title": "Casual",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8972,
                        "title": "Family",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8377,
                        "title": "Chique Ladies",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8378,
                        "title": "Chique Men",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8374,
                        "title": "Business",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8971,
                        "title": "Boutique",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8385,
                        "title": "Couture",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8375,
                        "title": "Wedding",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8376,
                        "title": "Cocktail",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8379,
                        "title": "Underwear",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 137,
                "name": "Fitness Center",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8820,
                        "title": "Trendy  090-099",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8407,
                        "title": "Trendy  100-109",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8338,
                        "title": "Trendy  110-114",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8329,
                        "title": "Trendy  115-119",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8341,
                        "title": "Trendy  120-124",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8400,
                        "title": "Trendy  125-129",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8332,
                        "title": "Trendy  130-149",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8401,
                        "title": "Trendy  150-180",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8403,
                        "title": "Best Of  090-099",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8334,
                        "title": "Best Of  100-109",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8337,
                        "title": "Best Of  110-119",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8402,
                        "title": "Best Of  120-124",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8335,
                        "title": "Best Of  125-129",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8406,
                        "title": "Best Of  130-139",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8405,
                        "title": "Best Of  140-169",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8404,
                        "title": "Best Of  170-200",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 140,
                "name": "Grand Café",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8619,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8129,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8392,
                        "title": "Café Classics",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8393,
                        "title": "Oldies Mix",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8395,
                        "title": "Smooth Tea",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8396,
                        "title": "Black Coffee",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8397,
                        "title": "Café de Paris",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8398,
                        "title": "Bistro Italiano",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8327,
                        "title": "Lazy Breakfast",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8085,
                        "title": "Summer Salad",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8323,
                        "title": "Trendy Lunch",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8336,
                        "title": "Dinner Classics",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8326,
                        "title": "Lounge Bubbles",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8328,
                        "title": "Jazzy Spirits",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8325,
                        "title": "Groovy Drinks",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8330,
                        "title": "Happy Hour",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 149,
                "name": "Party Zone",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8339,
                        "title": "Dance Beats",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8415,
                        "title": "Party Rock",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8418,
                        "title": "Fiesta Tropicana",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8414,
                        "title": "Ritmo Latino",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8413,
                        "title": "Disco Inferno",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8417,
                        "title": "Ladies Night",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8425,
                        "title": "Guilty Pleasures",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8409,
                        "title": "Feest Muziek",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8419,
                        "title": "Boogie",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Playlist",
                        "id": 8423,
                        "title": "Fun Dance",
                        "description": "10",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8420,
                        "title": "Polonaise",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8421,
                        "title": "Waltz",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8412,
                        "title": "Après-Ski",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8422,
                        "title": "Carnaval BE",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8424,
                        "title": "Carnaval NL",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8416,
                        "title": "Carnaval DE",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 285,
                "name": "Quality Food",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8842,
                        "title": "Trendy",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8861,
                        "title": "Best Of",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8831,
                        "title": "Down Beat",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8832,
                        "title": "Up Beat",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8844,
                        "title": "Soft Piano",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8834,
                        "title": "Soft Lounge",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8843,
                        "title": "Soft Pop",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8847,
                        "title": "Soft Electronic",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8833,
                        "title": "Bossa Nova",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8835,
                        "title": "Smooth Jazz",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8836,
                        "title": "Jazzy Pop",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8864,
                        "title": "Jazzy Grooves",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8191,
                        "title": "Deep Soulful",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8865,
                        "title": "Deep Lounge",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8775,
                        "title": "Deep Beats",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8022,
                        "title": "Deep Grooves",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 173,
                "name": "Trend Gallery",
                "musicChannelGroupId": 2,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8523,
                        "title": "Rock & Roll",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8526,
                        "title": "Surf",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8529,
                        "title": "Ska",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8524,
                        "title": "Pop Corn",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8525,
                        "title": "Flower Power",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8527,
                        "title": "Glam Rock",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8530,
                        "title": "Disco",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8528,
                        "title": "Punk",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8531,
                        "title": "New Wave",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8533,
                        "title": "New Romantics",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8532,
                        "title": "New Beat",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8534,
                        "title": "Grunge",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8535,
                        "title": "Trip Hop",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8537,
                        "title": "Drum & Bass",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8536,
                        "title": "Jump",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8538,
                        "title": "Trap",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            }
        ]
    },
    {
        "id": 3,
        "name": "Discover more",
        "musicChannels": [
            {
                "id": 123,
                "name": "Blues Bar",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8032,
                        "title": "Live",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8060,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8002,
                        "title": "Pure Blues",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8037,
                        "title": "Blues Roots",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8043,
                        "title": "Peaceful",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8035,
                        "title": "Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8001,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8007,
                        "title": "Dynamic",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8009,
                        "title": "Pop & Blues",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8034,
                        "title": "Soul & Blues",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8036,
                        "title": "Rock & Blues",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8003,
                        "title": "Rhythm & Blues",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8015,
                        "title": "Guitar Acoustic",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8014,
                        "title": "Guitar Electric",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8017,
                        "title": "Blues Waltz",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8016,
                        "title": "Shuffle & Swing",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 129,
                "name": "Country Saloon",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8102,
                        "title": "Pure Country",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8064,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8101,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8122,
                        "title": "Country Roots",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8105,
                        "title": "Peaceful",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8114,
                        "title": "Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8107,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8113,
                        "title": "Dynamic",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8152,
                        "title": "Country Lite",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8121,
                        "title": "Country Pop",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8103,
                        "title": "Country Folk",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8104,
                        "title": "Country Rock",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8110,
                        "title": "Bluegrass",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8120,
                        "title": "Tex Mex",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8111,
                        "title": "Country Dances",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8109,
                        "title": "Country & Western",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 130,
                "name": "Dance Arena",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8729,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8065,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8136,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8115,
                        "title": "Retro",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8144,
                        "title": "Minimal",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8118,
                        "title": "House",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8145,
                        "title": "Progressive House",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8153,
                        "title": "Tech House",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8141,
                        "title": "Electro",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8135,
                        "title": "Techno",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8117,
                        "title": "Trance",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8139,
                        "title": "Hardcore",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8142,
                        "title": "Urban",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8146,
                        "title": "Dubstep",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8076,
                        "title": "Breaks",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8137,
                        "title": "Drum & Bass",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 132,
                "name": "Discothèque",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8621,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8154,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8155,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8132,
                        "title": "Oldies",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8159,
                        "title": "Peaceful",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8158,
                        "title": "Bright",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8157,
                        "title": "Party Disco",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8166,
                        "title": "Party Dance",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8165,
                        "title": "Pop",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8162,
                        "title": "Disco",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8164,
                        "title": "House",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8116,
                        "title": "Eurodance",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8066,
                        "title": "Dance Pop",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8160,
                        "title": "Dance Grooves",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8163,
                        "title": "Dance Urban",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8161,
                        "title": "Dance Latin",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 143,
                "name": "Indie Corner",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8622,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8568,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8566,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8567,
                        "title": "Belgian",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8569,
                        "title": "Easy",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8571,
                        "title": "Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8570,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8572,
                        "title": "Dynamic",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8573,
                        "title": "Rock",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8574,
                        "title": "Folk",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8579,
                        "title": "Americana",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8577,
                        "title": "Acoustic",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8576,
                        "title": "Electronic",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8575,
                        "title": "Pop",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8578,
                        "title": "Chill",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8581,
                        "title": "Remix",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 145,
                "name": "Jazz Club",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8249,
                        "title": "Pop Jazz",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8237,
                        "title": "Swing Jazz",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8239,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8240,
                        "title": "Modern Jazz",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8238,
                        "title": "Easy",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8241,
                        "title": "Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8242,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8243,
                        "title": "Exuberant",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8248,
                        "title": "Latin Jazz",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8522,
                        "title": "Gipsy Jazz",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8245,
                        "title": "Smooth Jazz",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8244,
                        "title": "Acid Jazz",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8236,
                        "title": "Crooners",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8521,
                        "title": "Soul Jazz",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8246,
                        "title": "Funk Jazz",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8520,
                        "title": "Pure Jazz",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 148,
                "name": "Metal-o-drome",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8428,
                        "title": "2014 - 2018",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8430,
                        "title": "Cosy & Bright",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8432,
                        "title": "Dynamic",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8429,
                        "title": "Exuberant",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8203,
                        "title": "Hard Rock",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8440,
                        "title": "Hard Core",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8439,
                        "title": "Power Metal",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8436,
                        "title": "Heavy Metal",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8433,
                        "title": "Black Metal",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8434,
                        "title": "Death Metal",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8438,
                        "title": "Gothic Metal",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8437,
                        "title": "Doom Metal",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8426,
                        "title": "Nu Metal",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8435,
                        "title": "Thrash Metal",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8431,
                        "title": "Progressive Metal",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8427,
                        "title": "Industrial Metal",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 150,
                "name": "Pop Store",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8550,
                        "title": "Easy",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8552,
                        "title": "Cosy",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8563,
                        "title": "Bright",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8553,
                        "title": "Dynamic",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8551,
                        "title": "Folk",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8554,
                        "title": "Country",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8549,
                        "title": "Rhythm & Blues",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8555,
                        "title": "Rock & Roll",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8556,
                        "title": "Pop",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8514,
                        "title": "Rock",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8513,
                        "title": "Soul",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8561,
                        "title": "Disco",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8558,
                        "title": "Reggae",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8560,
                        "title": "Latin",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8559,
                        "title": "Urban",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8557,
                        "title": "Dance",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 151,
                "name": "Rock Café",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8731,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8477,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8344,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8481,
                        "title": "Dutch",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8478,
                        "title": "Peaceful",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8479,
                        "title": "Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8480,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8482,
                        "title": "Dynamic",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8483,
                        "title": "Pop Rock",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8485,
                        "title": "Folk Rock",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8476,
                        "title": "Blues Rock",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8487,
                        "title": "Country Rock",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8489,
                        "title": "Mainstream",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8488,
                        "title": "Alternative",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8484,
                        "title": "Hard Rock",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8486,
                        "title": "Progressive",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 153,
                "name": "Soul Club",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8734,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8167,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8075,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8169,
                        "title": "Oldies",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8031,
                        "title": "Peaceful",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8041,
                        "title": "Cosy",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8188,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8210,
                        "title": "Soulful",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8168,
                        "title": "Soul Pure",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8211,
                        "title": "Soul Pop",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8029,
                        "title": "Soul Disco",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8206,
                        "title": "Soul R&B",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8207,
                        "title": "Groovy",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8205,
                        "title": "Funky",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8209,
                        "title": "Jazzy",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8208,
                        "title": "Bluesy",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 159,
                "name": "Theme Café",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8461,
                        "title": "Football",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8469,
                        "title": "Eurovision",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Playlist",
                        "id": 8457,
                        "title": "Birthday",
                        "description": "03",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Playlist",
                        "id": 8463,
                        "title": "Closing Time",
                        "description": "04",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8462,
                        "title": "Valentine",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8459,
                        "title": "Valentine Lite",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8283,
                        "title": "Halloween",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8467,
                        "title": "Summer",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8465,
                        "title": "Kids Corner",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8464,
                        "title": "Kids Party",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8458,
                        "title": "Saint Nicholas",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8466,
                        "title": "Teenage Life",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8460,
                        "title": "Christmas Pop",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8473,
                        "title": "Christmas Lite",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8472,
                        "title": "Christmas Stylish",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8471,
                        "title": "Christmas - Best Of",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 164,
                "name": "Urban Street",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Playlist",
                        "id": 8733,
                        "title": "Hit Parade",
                        "description": "01",
                        "lastChanged": null,
                        "audioFiles": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8493,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8490,
                        "title": "Best Of",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8148,
                        "title": "Dutch",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8501,
                        "title": "R&B",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8499,
                        "title": "Urban Pop",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8494,
                        "title": "Urban Dance",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8496,
                        "title": "Urban Latin",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8504,
                        "title": "Dancehall",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8495,
                        "title": "Urban Reggae",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8500,
                        "title": "Ragga",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8498,
                        "title": "Reggaeton",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8497,
                        "title": "Slam",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8492,
                        "title": "Rap",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8502,
                        "title": "Hip Hop",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8503,
                        "title": "Street Dance",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 166,
                "name": "Volkscafé",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8441,
                        "title": "Schlagers",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8444,
                        "title": "2014 - 2018",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8456,
                        "title": "Party Music",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8443,
                        "title": "Oldies",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8445,
                        "title": "Cosy",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8442,
                        "title": "Levenslied",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8447,
                        "title": "Bright",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8450,
                        "title": "Tearjerker",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8449,
                        "title": "Swing",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8451,
                        "title": "Disco",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8452,
                        "title": "Folk Dancing",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8448,
                        "title": "Slow",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8455,
                        "title": "Country Lite",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8454,
                        "title": "Carnaval",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8453,
                        "title": "Volksmuziek",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8446,
                        "title": "Dialect",
                        "description": "16",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            },
            {
                "id": 168,
                "name": "World Expo",
                "musicChannelGroupId": 3,
                "musicCollections": [
                    {
                        "@type": "Context",
                        "id": 8024,
                        "title": "Belgian",
                        "description": "01",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": true,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8474,
                        "title": "French",
                        "description": "02",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8371,
                        "title": "Dutch",
                        "description": "03",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8314,
                        "title": "German",
                        "description": "04",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8247,
                        "title": "Italian",
                        "description": "05",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8251,
                        "title": "Greec",
                        "description": "06",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8367,
                        "title": "Spanish",
                        "description": "07",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8541,
                        "title": "Portugese",
                        "description": "08",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8540,
                        "title": "Irish",
                        "description": "09",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8543,
                        "title": "East European",
                        "description": "10",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8544,
                        "title": "Caribbean",
                        "description": "11",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8542,
                        "title": "Mexican",
                        "description": "12",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8545,
                        "title": "African",
                        "description": "13",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8547,
                        "title": "Asian",
                        "description": "14",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    },
                    {
                        "@type": "Context",
                        "id": 8548,
                        "title": "South-American",
                        "description": "15",
                        "lastChanged": null,
                        "changeableParameter": null,
                        "defaultChangeableParameter": null,
                        "visible": true,
                        "editable": false,
                        "favourite": false,
                        "selected": false,
                        "mostPlayed": false,
                        "defaultSelected": true
                    }
                ]
            }
        ]
    }
]};
