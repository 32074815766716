import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { AppService } from '@service/app.service';
import { LoggerService } from '@service/loggers/logger.service';
import { Subject } from 'rxjs';
import { ResizedEvent } from '@components/resize/resize.directive';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'tun-player',
    templateUrl: 'player.component.html',
    styleUrls: ['./player.component.scss'],
    host: {
      'class': 'router-flex'
    },
})

export class PlayerComponent implements OnInit, OnDestroy {

  private LOGGER_CLASSNAME = 'PlayerComponent';

  public get useTunifyV4(){
    return !environment.use_redirect_location || environment.build_version == '4';
  }

  @ViewChild('playerView', {static: true}) playerView:any;

  public sizeCalculated = false;

  get heightPerItem$(){
    return this.appService.heightPerItem$;
  }


  // === Observables === //
  private _destroy$ = new Subject<void>();

    constructor(
        private appService:AppService,
        private loggerService:LoggerService,
    ) {

  }

  ngOnInit() {
    this.playerSizeCalculation();
  }


  public onResize(event: ResizedEvent){
    //this.loggerService.debug(this.LOGGER_CLASSNAME, "onResize", "triggering resize");
    this.playerSizeCalculation();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$ = null;

  }

  private playerSizeCalculation() {
    const playerViewRect = this.playerView.nativeElement.getBoundingClientRect();

    if (playerViewRect.height > 0){
      this.appService.adjustPlayerSizeToHeight(playerViewRect.height);
      this.appService.adjustPlayerSizeToWidth(playerViewRect.width);

      this.sizeCalculated = true;
    }

    this.loggerService.debug(this.LOGGER_CLASSNAME, "playerSizeCalculation", "width: " + playerViewRect.width + " - height: " + playerViewRect.height);
  }

}

