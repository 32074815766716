import { Component } from '@angular/core';
import { PlayingItemService } from '@service/app-v5/playing-item.service';

@Component({
  selector: 'tun-mini-track-info-view',
  templateUrl: './mini-track-info-view.component.html',
  styleUrls: ['./mini-track-info-view.component.scss']
})
export class MiniTrackInfoViewComponent {

  public get currentAudio$(){
    return this.playingItemServiceService.playingAudio$
  }

  constructor(
    private playingItemServiceService: PlayingItemService
    ) {

    }

}
