import { Component } from '@angular/core';

@Component({
  selector: 'tun-angle-right-icon-v5',
  templateUrl: './angle-right-icon-v5.component.html',
  styleUrls: ['./angle-right-icon-v5.component.scss']
})
export class AngleRightIconV5Component {

}
