import { Component } from '@angular/core';

@Component({
  selector: 'tun-chat20px-icon-v5',
  templateUrl: './chat20px-icon-v5.component.html',
  styleUrls: ['./chat20px-icon-v5.component.scss']
})
export class Chat20pxIconV5Component {

}
