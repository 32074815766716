import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AudioFile } from '@model/audioFile';

@Component({
  selector: 'tun-settings-banned-track',
  templateUrl: './settings-banned-track.component.html',
  styleUrls: ['./settings-banned-track.component.scss']
})
export class SettingsBannedTrackComponent {

  @Input() track: AudioFile

  @Output() reset = new EventEmitter<AudioFile>();


  public onReset(){
    this.reset.emit(this.track);
  }
}
