import { Component, Input } from '@angular/core';
import { MusicCollection } from '@model/musicCollection';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';

@Component({
  selector: 'tun-search-music-collection-button-view',
  templateUrl: './search-music-collection-button-view.component.html',
  styleUrls: ['./search-music-collection-button-view.component.scss']
})
export class SearchMusicCollectionButtonViewComponent {

  @Input() searchMusicCollection: MusicCollection;

  constructor(
    private appV5StateService:AppV5StateService
  ){

  }

  public onShowDetails(){
    this.appV5StateService.showSearchMusicCollectionInTunifyOrange(this.searchMusicCollection);
  }
}
