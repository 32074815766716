import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BrandMessage } from '../../model/brandMessage';

@Injectable({
  providedIn: 'root'
})
export class BrandMessageQueueService {

  constructor(

  ) {

  }





  private queue: BrandMessage[] = [];

  private set _nextBrandMessage(value: BrandMessage) {
    if (this._nextBrandMessage !== value) {
      this._nextBrandMessageSubject.next(value);
    }
  }
  private get _nextBrandMessage(): BrandMessage {
    return this._nextBrandMessageSubject.value;
  }
  public get nextBrandMessage(): BrandMessage {
    return this._nextBrandMessage;
  }
  private _nextBrandMessageSubject: BehaviorSubject<BrandMessage> = new BehaviorSubject<BrandMessage>(null);
  public nextBrandMessage$: Observable<BrandMessage> = this._nextBrandMessageSubject.asObservable();

  private recalcNextBrandMessage() {
    this._nextBrandMessage = this.getNextBrandMessage();
  }

  public getNextBrandMessage(): BrandMessage {
    if (this.queue.length > 0){
      return this.queue[0];
    }
    return null;
  }

  public removeBrandMessageFromQueue(message: BrandMessage){
    this.queue = this.queue.filter(m => m !== message);
    this.recalcNextBrandMessage();
  }

  public addToQueue(messages: BrandMessage[]){

    if (messages != null){

      //filter messages that are already on the queue
      messages = messages.filter(message => {
        return !this.queue.some(queueMessage => queueMessage.id == message.id)
      });

      //after we filtered -> add them to the queue
      messages.forEach(message => {
        this.queue.push(message);
      });

      this.recalcNextBrandMessage();

    }

  }
}
