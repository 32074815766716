export const musicCollection_8619 = {
    "@type": "Playlist",
    "id": 8619,
    "title": "Pop",
    "description": "01",
    "lastChanged": null,
    "audioFiles": [
        {
            "@type": "Song",
            "id": 413208,
            "duration": 202373,
            "group": "Satin Jackets ft Niya Wells",
            "location": "2-8/0004132089e027a.mp3",
            "title": "Lost In Japan",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 105,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 201261,
            "fadeBeginPosition": 198373,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 405333,
            "duration": 193762,
            "group": "Dean Lewis",
            "location": "2-33/0004053337511e1.mp3",
            "title": "Be Alright",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 63,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 881,
            "endAudioSignal": 192317,
            "fadeBeginPosition": 190650,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412699,
            "duration": 178256,
            "group": "Rita Ora",
            "location": "2-99/0004126994a3d10.mp3",
            "title": "Only Want You",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 82,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 176107,
            "fadeBeginPosition": 163107,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 411309,
            "duration": 202308,
            "group": "Clean Bandit, Marina & Luis Fonsi",
            "location": "2-9/000411309720aba.mp3",
            "title": "Baby",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 117,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 201863,
            "fadeBeginPosition": 200196,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412119,
            "duration": 180197,
            "group": "Lewis Capaldi",
            "location": "2-19/0004121195d910f.mp3",
            "title": "Someone You Loved",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 110,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 6,
            "endAudioSignal": 176085,
            "fadeBeginPosition": 172752,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 413600,
            "duration": 268280,
            "group": "Jack Savoretti",
            "location": "2-0/000413600c27f79.mp3",
            "title": "Candlelight",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 82,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 266279,
            "fadeBeginPosition": 255835,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412963,
            "duration": 214647,
            "group": "Miley Cyrus & Mark Ronson",
            "location": "2-63/000412963e135de.mp3",
            "title": "Nothing Breaks Like A Heart",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 114,
            "musicFeel": "Dance",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 3,
            "endAudioSignal": 214202,
            "fadeBeginPosition": 209535,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Dance",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 408856,
            "duration": 186869,
            "group": "Rita Ora",
            "location": "2-56/00040885690cc6b.mp3",
            "title": "Let You Love Me",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 96,
            "musicFeel": "Soul",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 185646,
            "fadeBeginPosition": 183091,
            "musicStyle": "Urban",
            "subStyle": "R & B",
            "genreDescription": "R & B",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412971,
            "duration": 223368,
            "group": "Loïc Nottet",
            "location": "2-71/000412971a6a182.mp3",
            "title": "On Fire",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 70,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 1,
            "endAudioSignal": 221923,
            "fadeBeginPosition": 213923,
            "musicStyle": "Electronic",
            "subStyle": "Basic",
            "genreDescription": "Electronic Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 410675,
            "duration": 186412,
            "group": "Zara Larsson",
            "location": "2-75/000410675e85281.mp3",
            "title": "Ruin My Life",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 125,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 7,
            "endAudioSignal": 184300,
            "fadeBeginPosition": 183967,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 413592,
            "duration": 185870,
            "group": "Avril Lavigne",
            "location": "2-92/0004135923b5f90.mp3",
            "title": "Tell Me It's Over",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 92,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Slow",
            "startAudioSignal": 11,
            "endAudioSignal": 181092,
            "fadeBeginPosition": 178425,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Slow",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 411314,
            "duration": 191957,
            "group": "Martin Garrix",
            "location": "2-14/000411314b564e3.mp3",
            "title": "Dreamer",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 105,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 189845,
            "fadeBeginPosition": 173734,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 403782,
            "duration": 147775,
            "group": "Rosalía",
            "location": "2-82/00040378271af75.mp3",
            "title": "Malamente",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 98,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 146663,
            "fadeBeginPosition": 144663,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412453,
            "duration": 164234,
            "group": "Sultan & Ned Shepard",
            "location": "2-53/000412453c6ba18.mp3",
            "title": "Where Are You?",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 124,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 163122,
            "fadeBeginPosition": 162122,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412107,
            "duration": 165212,
            "group": "Martin Jensen ft Bjørnskov",
            "location": "2-7/000412107a9089c.mp3",
            "title": "Somebody I'm Not",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 100,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 33,
            "endAudioSignal": 164397,
            "fadeBeginPosition": 161767,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 409330,
            "duration": 226959,
            "group": "Robyn",
            "location": "2-30/000409330eb0120.mp3",
            "title": "Honey",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 116,
            "musicFeel": "Dance",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 37,
            "endAudioSignal": 224403,
            "fadeBeginPosition": 216810,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Dance",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": null
        },
        {
            "@type": "Song",
            "id": 413581,
            "duration": 158417,
            "group": "Don Diablo & Nate Dogg",
            "location": "2-81/000413581c336ff.mp3",
            "title": "I Got Love",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 123,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 32,
            "endAudioSignal": 157305,
            "fadeBeginPosition": 156268,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412141,
            "duration": 194601,
            "group": "Little Mix ft Kamille",
            "location": "2-41/00041214146e380.mp3",
            "title": "More Than Words",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 67,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 77,
            "endAudioSignal": 191823,
            "fadeBeginPosition": 183156,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 413741,
            "duration": 175052,
            "group": "Netsky, Toulouse & StarGate",
            "location": "2-41/00041374194acf3.mp3",
            "title": "Voodoo Magic",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 170,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 169274,
            "fadeBeginPosition": 168940,
            "musicStyle": "Electronic",
            "subStyle": "Basic",
            "genreDescription": "Electronic Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412543,
            "duration": 174835,
            "group": "Kris Kross Amsterdam, Ally Brooke & Messiah",
            "location": "2-43/000412543872a2b.mp3",
            "title": "Vámonos",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 105,
            "musicFeel": "Reggae",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 174316,
            "fadeBeginPosition": 173316,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Reggae",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412894,
            "duration": 231558,
            "group": "Clean Bandit, KYLE & Big Boi",
            "location": "2-94/000412894230c31.mp3",
            "title": "Out At Night",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 105,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 228780,
            "fadeBeginPosition": 228076,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412226,
            "duration": 197622,
            "group": "Blanche",
            "location": "2-26/0004122269d1dbd.mp3",
            "title": "Moment",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 182,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 195510,
            "fadeBeginPosition": 193399,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 410668,
            "duration": 184111,
            "group": "Ava Max",
            "location": "2-68/000410668b9c276.mp3",
            "title": "Sweet But Psycho",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 133,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 4,
            "endAudioSignal": 182666,
            "fadeBeginPosition": 179999,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 410128,
            "duration": 211650,
            "group": "Jess Glynne",
            "location": "2-28/000410128907379.mp3",
            "title": "Thursday",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 112,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 90,
            "endAudioSignal": 210538,
            "fadeBeginPosition": 208538,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 409804,
            "duration": 198179,
            "group": "Angèle ft Roméo Elvis",
            "location": "2-4/0004098047db682.mp3",
            "title": "Tout Oublier",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 105,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 196734,
            "fadeBeginPosition": 194623,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 413120,
            "duration": 170168,
            "group": "Laura Tesoro",
            "location": "2-20/0004131203a2006.mp3",
            "title": "Mutual",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 96,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 25,
            "endAudioSignal": 169390,
            "fadeBeginPosition": 164723,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 413209,
            "duration": 181642,
            "group": "Mike Mago & Dog Collective",
            "location": "2-9/000413209fb4c1d.mp3",
            "title": "Always On My Mind",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 124,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 6,
            "endAudioSignal": 179864,
            "fadeBeginPosition": 178530,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412582,
            "duration": 195834,
            "group": "Jamie Lawson",
            "location": "2-82/000412582455f3d.mp3",
            "title": "Testify",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 108,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 194722,
            "fadeBeginPosition": 186056,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 410960,
            "duration": 179581,
            "group": "Ellie Goulding, Diplo & Swae Lee",
            "location": "2-60/00041096097af7c.mp3",
            "title": "Close To Me",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 72,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 1,
            "endAudioSignal": 177469,
            "fadeBeginPosition": 163136,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412445,
            "duration": 204357,
            "group": "The Chainsmokers",
            "location": "2-45/00041244505285d.mp3",
            "title": "Beach House",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 90,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 6,
            "endAudioSignal": 203245,
            "fadeBeginPosition": 192245,
            "musicStyle": "Electronic",
            "subStyle": "Basic",
            "genreDescription": "Electronic Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 413883,
            "duration": 194604,
            "group": "Brynn Cartelli",
            "location": "2-83/0004138831f249d.mp3",
            "title": "Last Night's Mascara",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 102,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 271,
            "endAudioSignal": 194492,
            "fadeBeginPosition": 177492,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": null
        },
        {
            "@type": "Song",
            "id": 413826,
            "duration": 275509,
            "group": "India Arie",
            "location": "2-26/00041382663d7aa.mp3",
            "title": "That Magic",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 92,
            "musicFeel": "Soul",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Social",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 268175,
            "fadeBeginPosition": 229397,
            "musicStyle": "Urban",
            "subStyle": "R & B",
            "genreDescription": "R & B",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412972,
            "duration": 187904,
            "group": "John Gibbons & Nina Nesbitt",
            "location": "2-72/000412972b69877.mp3",
            "title": "A Spaceman Came Travelling",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 100,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 12,
            "endAudioSignal": 186459,
            "fadeBeginPosition": 177126,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 413113,
            "duration": 215050,
            "group": "Cheat Codes & Kim Petras",
            "location": "2-13/0004131137d97e0.mp3",
            "title": "Feeling Of Falling",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 120,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 2,
            "endAudioSignal": 210938,
            "fadeBeginPosition": 200605,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 412888,
            "duration": 185439,
            "group": "Jax Jones & Years & Years",
            "location": "2-88/0004128882f8da6.mp3",
            "title": "Play",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 125,
            "musicFeel": "Electronic",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 184327,
            "fadeBeginPosition": 183327,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop Electronic",
            "rhythmDescription": "Free Dance",
            "tunifyTipValue": "Tunify Tip"
        },
        {
            "@type": "Song",
            "id": 413597,
            "duration": 168169,
            "group": "Regi & Milo Meskens",
            "location": "2-97/000413597476984.mp3",
            "title": "Ordinary",
            "origin": {
                "type": "musicCollection",
                "data": "8619"
            },
            "composer": null,
            "bpm": 122,
            "musicFeel": "Pop",
            "localAvailable": null,
            "version": "Original",
            "year": "2018",
            "mood": "Bright",
            "dancingStyle": "Free",
            "startAudioSignal": 0,
            "endAudioSignal": 166724,
            "fadeBeginPosition": 164576,
            "musicStyle": "Pop",
            "subStyle": "Basic",
            "genreDescription": "Pop",
            "rhythmDescription": "Free",
            "tunifyTipValue": "Tunify Tip"
        }
    ],
    "visible": true,
    "defaultSelected": true,
    "editable": false,
    "favourite": false,
    "selected": false,
    "mostPlayed": false
};