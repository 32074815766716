import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClearBanlistPopupComponent } from '@components/popups-v5/clear-banlist-popup/clear-banlist-popup.component';
import { AudioFile } from '@model/audioFile';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { MusicCollectionService } from '@service/music-collection.service';
import { PlaylistService } from '@service/playlist.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { Subject, concat } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export enum BanlistState{
  normal,
  creating,
  deleting,
  deleted,
  loading,
  loadError,
  none
}

@Component({
  selector: 'tun-settings-banlist-panel',
  templateUrl: './settings-banlist-panel.component.html',
  styleUrls: ['./settings-banlist-panel.component.scss']
})
export class SettingsBanlistPanelComponent implements OnInit, OnDestroy {

  @ViewChild('wrapper') wrapper: ElementRef;

  @Output() back = new EventEmitter<void>();

  get banlist$(){
    return this.playlistService.banlist$;
  }

  public get trackItemSize$(){
    return this.appV5StateService.pixelsFor1Rem$
    .pipe(
      map(px => {
        return 3 * px
      })
    )
  }

  constructor(
    private appV5StateService: AppV5StateService,
    private playlistService: PlaylistService,
    private musicCollectionService: MusicCollectionService,
    private dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
      if (this.playlistService.banlist && !this.playlistService.banlist.detailsLoaded && !this.playlistService.banlist.detailsLoading){
        this.musicCollectionService.loadMusicCollectionDetails(this.playlistService.banlist);
      }
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onReset(track:AudioFile){
    if (this.playlistService.banlist){
      this.playlistService.deleteAudioFileInPlaylist(this.playlistService.banlist, [track])
      .pipe(
        takeUntil(
          this.destroyed$
        )
      )
      .subscribe(
        (asyncStatys)=>{
          if (asyncStatys == AsyncStatus.COMPLETED){
            //do we need feedback?
          }
        }
      )

    }
  }

  onResetAll(){
    //if there are doubles to check -> ask user, this can lead to a cancelled state
    if (this.wrapper.nativeElement.offsetWidth > 0){
      const dialogRef = this.dialog.open(ClearBanlistPopupComponent, {
        width: ClearBanlistPopupComponent.widthForPopup,
        disableClose: true,
        data: {confirm: false}
      });

      dialogRef.afterClosed()
      .pipe(
        takeUntil(
          this.destroyed$
        )
      )
      .subscribe(result => {
        if (result.confirm && this.playlistService.banlist && this.playlistService.banlist.audioFiles && this.playlistService.banlist.audioFiles.length > 0){
          this.playlistService.deleteAudioFileInPlaylist(this.playlistService.banlist, this.playlistService.banlist.audioFiles);
        }
      });
    }
  }

  onBack(){
    this.back.emit();
  }
}
