

  <div class="contentWrapper" @fadeIn>

    <!-- Keep the groups on screen to store the scrollPosition? -->
    <tun-overlay-select-music-channel-view
      [@viewPosition]="mainViewPosition$ | async"
      (close)="onClose()"
      (selectMusicChannel)="onSelectMusicChannel($event)"
      class="fullContainer">
    </tun-overlay-select-music-channel-view>

    <tun-overlay-select-music-collection-view
      *ngIf="(showDetailsForMusicChannel$ | async) != null"
      @slideInRight
      [musicChannel]="(showDetailsForMusicChannel$ | async)"
      (back)="onBack()"
      (close)="onClose()"
      (selectMusicCollection)="onSelectMusicCollection($event)"
      class="fullContainer">
    </tun-overlay-select-music-collection-view>

  </div>

