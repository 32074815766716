import { RemoteAudioType } from '@service/vo/remote/remote-objects';
import { AudioFile } from './audioFile';

export class Song extends AudioFile{
    bpm: number;
    version: string;
    year: string;
    mood: string;
    dancingStyle: string;

    genreDescription: string;
    rhythmDescription: string;

    startAudioSignal: number;
    fadeBeginPosition: number;
    endAudioSignal: number;

    type = RemoteAudioType.song;

    constructor(song?: Song){
        super(song);
        if (song){
            this.bpm = song.bpm;
            this.version = song.version;
            this.year = song.year;
            this.mood = song.mood;
            this.dancingStyle = song.dancingStyle;
            this.genreDescription = song.genreDescription;
            this.rhythmDescription = song.rhythmDescription;
            this.startAudioSignal = song.startAudioSignal;
            this.fadeBeginPosition = song.fadeBeginPosition;
            this.endAudioSignal = song.endAudioSignal;
        }
    }
}
