import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TrackTableItem } from '@components/components-v5/track-list-view/track-list-view.component';
import { AudioFile } from '@model/audioFile';
import { AppV5StateService } from '@service/app-v5/app-v5-state.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { QueueService } from '@service/queue.service';
import { createClassObjectForAudioFile } from '@service/util/audioFileUtil';
import { ZoneConfigurationService } from '@service/zone-configuration.service';
import { Subject, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Playlist } from '@model/playlist';
import { TunifyCheckBoxColor } from '@components/components-v5/checkbox-v5/checkbox-v5.component';
import { PlaylistService } from '@service/playlist.service';
import { AsyncStatus } from '@service/vo/asyncStatus';
import { MatSnackBar } from '@angular/material/snack-bar';
import { slideUpAnimation } from '@util/animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { TrackOverlayService } from '@service/app-v5/track-overlay.service';
import { TrackInfoContext, TrackInfoContextMode, TrackInfoOverlayData } from '@components/overlays-v5/overlay-song-info/overlay-song-info.component';
import { TunifyColor } from '@model/enums/tunifyColor.enum';
import { translatedDescriptionForSearchData } from '@service/search.service';
import { SearchState } from '../../search-music-collection-result-view/search-music-collection-result-content-view/search-music-collection-result-content-view.component';
import { SearchV5Service } from '@service/app-v5/search-v5.service';
import { SearchService } from '../../../../../../services/data/search.service';

@Component({
  selector: 'tun-search-result-content-view',
  templateUrl: './search-result-content-view.component.html',
  styleUrls: ['./search-result-content-view.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      transition(':enter', [
        useAnimation(slideUpAnimation)
      ]),
      transition(':leave', [
        useAnimation(slideUpAnimation, { params: { startY: '0', endY: '100%' } })
      ])
    ])
  ]
})
export class SearchResultContentViewComponent {

  public LOGGER_NAME = SearchResultContentViewComponent.name;
  private SNACKBAR_DURATION = 5000;

  public TunifyCheckBoxColor = TunifyCheckBoxColor

  public get showingPopup(){
    return this.showChoosePlaylistOverlay;
  }
  public showChoosePlaylistOverlay = false

  public get searchResult$(){
    return this.searchV5Service.searchResult$;
  }

  public get searchTitle$(){
    return this.searchV5Service.lastSearch$
      .pipe(
        map(
          searchData => {
            return translatedDescriptionForSearchData(this.translateService, searchData);
          }
        )
      )
  }

  //make the enum available in the html template
  public SearchState = SearchState;

  get searchState(): SearchState{
      if (this.searchV5Service.searching){
        return SearchState.searching;
      }else if (this.searchV5Service.searchError){
        return SearchState.searchError;
      }else if (this.searchV5Service.lastSearch != null){
        return SearchState.normal;
      }
      return SearchState.none;
  }

  get audioFileProperty$(){
    return this.zoneConfigurationService.audioFileProperty$
  }


  constructor(
    private appV5StateService: AppV5StateService,
    private searchV5Service: SearchV5Service,
    private translateService: TranslateService,
    private zoneConfigurationService: ZoneConfigurationService,
    private musicManipulationService: MusicManipulationService,
    private queueService: QueueService,
    private loggerService: LoggerService,
    private playlistService: PlaylistService,
    private snackBar: MatSnackBar,
    private trackOverlayService: TrackOverlayService
  ) {

  }

  ngOnInit(): void {
    this.initCheckedTracks();
  }

  ngAfterViewInit(): void {

  }

  private tracksChanged$ = new Subject<void>();
  private initCheckedTracks(){
    this.searchV5Service.searchResult$
    .pipe(
      takeUntil(
        this.destroyed$
      )
    )
    .subscribe(
      ()=>{
        this.tracksChanged$.next();
        this.checkedTracks = [];
        this.updateAllComplete();
      }
    )
  }


  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.tracksChanged$.complete();

    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }r

  onBack(){
    this.appV5StateService.closeSearchResults();
  }

  onPlayAll(){
    if (this.searchV5Service.searchResult){
      if (this.searchV5Service.searchResult.length > 0){
        const tracksToQueue: AudioFile[] = [];
        this.searchV5Service.searchResult.forEach(track => tracksToQueue.push(createClassObjectForAudioFile(track)));

        const trackToPlay = tracksToQueue.shift()
        this.musicManipulationService.playAudioFile(trackToPlay);

        if (tracksToQueue.length > 0){
          this.queueService.clearQueue();
          this.queueService.addAudioFilesToQueue(tracksToQueue);
        }
      }
    }
  }

  /* Handler for selection header */
  public checkedTracks : AudioFile[] = [];

  get selectTitle(){
    if (this.checkedTracks.length == 0){
      return this.translateService.instant('search.result.select.all')
    }else if (this.checkedTracks.length == 1){
      return this.translateService.instant('search.result.select.amount.one')
    }else{
      return this.translateService.instant('search.result.select.amount.many').replace("{0}", this.checkedTracks.length);
    }
  }

  allComplete: boolean = false;
  updateAllComplete() {
    if (this.searchV5Service.searchResult){
      this.allComplete = this.searchV5Service.searchResult.every(track => this.checkedTracks.includes(track))
    }
    return true
  }

  someComplete(): boolean {
    if (this.searchV5Service.searchResult == null) {
      return false;
    }
    return !this.allComplete && this.checkedTracks.length > 0;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.allComplete && this.searchV5Service.searchResult){
      this.checkedTracks = this.searchV5Service.searchResult.map(track => track);
    }else{
      this.checkedTracks = [];
    }
  }

  onAddSelectedToQueue(){
    this.musicManipulationService.addAudioFilesToQueue(this.checkedTracks)
    .pipe(
      takeUntil(
        merge(
          this.tracksChanged$,
          this.destroyed$
        )
      )
    ).subscribe(
      (asyncStatus) => {
        if (asyncStatus == AsyncStatus.COMPLETED){
            const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
              duration: this.SNACKBAR_DURATION,
              panelClass: ['tunify-snackbar']
            });

        }
      }
    );


  }

  onAddSelectedToPlaylist(){
    this.showChoosePlaylistOverlay = true
  }

  // Handlers for track items

  onCheckedTracksChanged(checkedTracks: AudioFile[]){
    this.checkedTracks = checkedTracks
  }

  /* Select playlist popup */
  onCloseSelectPlaylist(playlist: Playlist){
    if (playlist && this.checkedTracks.length > 0){
      this.playlistService.addAudioFileToPlaylist(playlist, this.checkedTracks)
      .pipe(
        takeUntil(
          merge(
            this.tracksChanged$,
            this.destroyed$
          )
        )
      ).subscribe(
        (asyncStatus) => {
          if (asyncStatus == AsyncStatus.COMPLETED){
              const snackBarRef = this.snackBar.open(this.translateService.instant('search.message.selection.addToQueue.succes'), null, {
                duration: this.SNACKBAR_DURATION,
                panelClass: ['tunify-snackbar']
              });
          }
        }
      );
    }
    this.showChoosePlaylistOverlay = false;
  }


  onClickOutside(event: Event, item){
    if (event.target !== item)
      return;
    this.showChoosePlaylistOverlay = false;
  }



}
