import { environment } from 'src/environments/environment';
import { DTO_AccessRights } from '../../../services/api/subscriptions-api.service';

const UPGRADE_TO_BLUE = "upgrade.link.blue";
const UPGRADE_TO_ORANGE = "upgrade.link.orange";

export interface AccessRightsChecker {
    checkAccessToGreen: boolean;
    checkAccessToBlue: boolean;
    checkAccessToOrange: boolean;
  
    //check access to specific functions
    checkAccessToSearch: boolean;
    checkAccessToCustomPlaylists: boolean;
    checkAccessToAddToQueue: boolean;
    checkAccessToStartTrack: boolean;
  }

  export interface AccessRightViolation{
      message: string;
      upgradeLink?: string;
  }

  export function hasAccessViolations(accessRightsChecker: AccessRightsChecker, zoneAccessRights: DTO_AccessRights): boolean{
    return checkAccess(accessRightsChecker, zoneAccessRights).length > 0;
  }

  export function checkAccess(accessRightsChecker: AccessRightsChecker, zoneAccessRights: DTO_AccessRights): AccessRightViolation[]{
    const accessRightsViolations: AccessRightViolation[] = [];

    //1 - check access to whole colors
    //1A - green
    if (accessRightsChecker.checkAccessToGreen){
        if (!environment.enableGreen){
            accessRightsViolations.push({message: "upgrade.green.notAvailable"});
        }
      }
  
      //1B - BLUE
      if (accessRightsChecker.checkAccessToBlue){
        if (!zoneAccessRights || !zoneAccessRights.accessBlue){
            accessRightsViolations.push({message: "upgrade.blue.access", upgradeLink: UPGRADE_TO_BLUE});
        }
      }
  
      //1C - ORANGE
      if (accessRightsChecker.checkAccessToOrange){
        if (!zoneAccessRights || !zoneAccessRights.accessOrange){
            accessRightsViolations.push({message: "upgrade.orange.access", upgradeLink: UPGRADE_TO_ORANGE});
        }else if (!environment.enableOrange){
            accessRightsViolations.push({message: "upgrade.orange.notAvailable"});
        }
      }
  
      //2- check specific functions
      //2A - search
      if (accessRightsChecker.checkAccessToSearch){
        if (!zoneAccessRights || !zoneAccessRights.search){
            accessRightsViolations.push({message: "upgrade.search.access", upgradeLink: UPGRADE_TO_ORANGE});
        }else if (!environment.enableSearch){
            accessRightsViolations.push({message: "upgrade.search.notAvailable"});
        }
      }
  
      //2B - custom playlists
      if (accessRightsChecker.checkAccessToCustomPlaylists){
        if (!zoneAccessRights || !zoneAccessRights.customPlaylists){
            accessRightsViolations.push({message: "upgrade.customPlaylists.access", upgradeLink: UPGRADE_TO_ORANGE});
        }
      }

      //2B - add to queue
      if (accessRightsChecker.checkAccessToAddToQueue){
        if (!zoneAccessRights || !zoneAccessRights.addToQueue){
            accessRightsViolations.push({message: "upgrade.addToQueue.access", upgradeLink: UPGRADE_TO_ORANGE});
        }
      }

      //2B - custom playlists
      if (accessRightsChecker.checkAccessToStartTrack){
        if (!zoneAccessRights || !zoneAccessRights.startTrack){
            accessRightsViolations.push({message: "upgrade.startTrack.access", upgradeLink: UPGRADE_TO_ORANGE});
        }
      }

      return accessRightsViolations;
  }
