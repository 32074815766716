import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, Observable, timer, merge, pipe } from 'rxjs';
import { TrackForRemoteSharing, PlayModeForRemoteSharing, ProgressForRemoteSharing } from '@service/vo/remote/remote-objects';
import { AudioFile, QueueOrigin } from '../../model/audioFile';
import { TrackApiService } from '../api/track-api.service';
import { takeUntil, filter, finalize, map } from 'rxjs/operators';
import { createClassObjectForAudioFile } from '../data/util/audioFileUtil';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { min } from 'moment';
import { RemoteAudioType } from '../data/vo/remote/remote-objects';
import { LoggerService } from '../loggers/logger.service';


/**
 * This class is responsible for storing the remote playState.
 *
 * Values are put into this service by the remoteService
 */

@Injectable({
  providedIn: 'root'
})
export class RemotePlayStateService implements OnDestroy {

  private LOGGER_CLASSNAME = 'RemotePlayStateService';

  constructor(
    private trackApiService: TrackApiService,
    private zoneConnectionsService: ZoneConnectionsService,
    private loggerService: LoggerService,
    private ngZone: NgZone
  ) {
    this.zoneConnectionsService.currentPlayerApplicationInfo$
    .pipe(
      map(remoteApplicationInfos => remoteApplicationInfos != null),
      filter(v => v == false),
      takeUntil(this.destroyed$)
    )
    .subscribe(
      ()=>{
        //remove current track as soon as no player is active any longer
        this._currentTrack = null;
        this._waitingForTrackInfoAfterNext = false;
        this._playMode = PlayModeForRemoteSharing.stopped;
        this._progress = 0;
        this._totalTime = 0;
      }
    )
  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

    /**
     * Current remote track - the track that the active player is using
     * This is a 'RemoteSharing' representation
     * If we need to add the track to a playlist, we need a real Track object (see remoteTrackObject)
     */
    private get _currentTrack():TrackForRemoteSharing {
        return this._currentTrackSubject.value;
    }
    private set _currentTrack(value:TrackForRemoteSharing) {
        if (this._currentTrack != value){
          this._currentTrackSubject.next(value);
          this._remoteTrackObject = null;

          timer(2000)
            .pipe(
              takeUntil(
                merge(
                  this.destroyed$,
                  this._currentTrackSubject.pipe(filter(track => track != value))
                )
              )
            )
            .subscribe(
              () => {
                this.lookupTrackIfNeeded();
              }
            )

        }
    }
    public get currentTrack():TrackForRemoteSharing{
        return this._currentTrack;
    }

    private _currentTrackSubject:BehaviorSubject<TrackForRemoteSharing> = new BehaviorSubject<TrackForRemoteSharing>(null);
    public currentTrack$:Observable<TrackForRemoteSharing> = this._currentTrackSubject.asObservable();

    public remoteTrackReceived(track: TrackForRemoteSharing){
      if (track != null && track.type == RemoteAudioType.none){
        this._currentTrack = null;
      }else{
        this._currentTrack = track;
      }

      this._waitingForTrackInfoAfterNext = false;
    }

    /**
     * This is the real 'AudioFile' object representation of the remote track.
     * Only fetched on command
     */
    private get _remoteTrackObject():AudioFile {
      return this._remoteTrackObjectSubject.value;
    }
    private set _remoteTrackObject(value:AudioFile) {
      if (this.remoteTrackObject != value){
        this._remoteTrackObjectSubject.next(value);

      }
    }
    public get remoteTrackObject():AudioFile{
      return this._remoteTrackObject;
    }

    private _remoteTrackObjectSubject = new BehaviorSubject<AudioFile>(null);
    public remoteTrackObject$ = this._remoteTrackObjectSubject.asObservable();

    private lookingUpTrackObservable: Observable<AudioFile> = null;
    public lookupTrackIfNeeded(){
      if (this.remoteTrackObject == null && this.lookingUpTrackObservable == null && this.currentTrack != null){

        if (this.currentTrack.type == RemoteAudioType.song || this.currentTrack.type == RemoteAudioType.message){
          const lookingUpTrack = this.currentTrack.id;
          this.lookingUpTrackObservable = this.trackApiService.searchTrack(lookingUpTrack);
          if (this.lookingUpTrackObservable){
            this.lookingUpTrackObservable
            .pipe(
              takeUntil(
                merge(
                  this.currentTrack$.pipe(filter(track => track == null || track.id != lookingUpTrack)),
                  this.destroyed$
                )
              ),
              finalize(
                ()=>{
                  this.lookingUpTrackObservable = null;
                }
              )
            )
            .subscribe(
              (data) => {
                const track = createClassObjectForAudioFile(data);

                //keep the track origin
                track.origin = this.currentTrack.origin;
                track.queueOrigin = QueueOrigin.remote;

                this._remoteTrackObject = track;
              }
            )
          }
        }else if (this.currentTrack.type == RemoteAudioType.brandMessage){
          this.loggerService.debug(this.LOGGER_CLASSNAME, "lookupTrackIfNeeded", "Brandmessages do not need to be looked up");
        }else{
          this.loggerService.error(this.LOGGER_CLASSNAME, "lookupTrackIfNeeded", "Track type not recognized: " + this.currentTrack.type);
        }
      }
    }



    /**
     * indicates if we are waiting for a next track
     * It is possible that the current track is null but we are not waiting for a new track (the user is not playing any track)
     */

    private get _waitingForTrackInfoAfterNext():boolean {
        return this._waitingForTrackInfoAfterNextSubject.value;
    }
    private set _waitingForTrackInfoAfterNext(value:boolean) {
        if (this._waitingForTrackInfoAfterNext != value){
            this._waitingForTrackInfoAfterNextSubject.next(value);
        }
    }
    public get waitingForTrackInfoAfterNext():boolean{
        return this._waitingForTrackInfoAfterNext;
    }
    private _waitingForTrackInfoAfterNextSubject:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public waitingForTrackInfoAfterNext$:Observable<boolean> = this._waitingForTrackInfoAfterNextSubject.asObservable();

    //erase our local model once we skip a track
    public currentTrackIsSkipped(){
      this.remoteTrackReceived(null);
      this._waitingForTrackInfoAfterNext = true;
    }


    /**
     * player state
     */
    private get _playMode():PlayModeForRemoteSharing {
        return this._playModeSubject.value;
    }
    private set _playMode(value:PlayModeForRemoteSharing) {
        if (this._playMode != value){

            this.runTimer = value === PlayModeForRemoteSharing.playing;

            this._playModeSubject.next(value);
        }
    }
    get playMode():PlayModeForRemoteSharing{
        return this._playMode;
    }
    private _playModeSubject:BehaviorSubject<PlayModeForRemoteSharing> = new BehaviorSubject<PlayModeForRemoteSharing>(null);
    public playMode$:Observable<PlayModeForRemoteSharing> = this._playModeSubject.asObservable();

    public remotePlayModeReceived(playMode: PlayModeForRemoteSharing){
      this._playMode = playMode;
    }

    /**
     * Progress of the remote track
     */
    private get _progress():number {
        return this._progressSubject.value;
    }
    private set _progress(value:number) {
        if (this._progress != value){
            this._progressSubject.next(value);
        }
    }
    get progress():number{
        return this._progress;
    }
    private _progressSubject:BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public progress$:Observable<number> = this._progressSubject.asObservable();


    /**
     * Total time of the remote track
     */
    private get _totalTime():number {
        return this._totalTimeSubject.value;
    }
    private set _totalTime(value:number) {
        if (this._totalTime != value){
            this._totalTimeSubject.next(value);
        }
    }
    get totalTime():number{
        return this._totalTime;
    }
    private _totalTimeSubject:BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public totalTime$:Observable<number> = this._totalTimeSubject.asObservable();

    public remoteProgressReceived(progress: ProgressForRemoteSharing){
      this._progress = progress.currentTime / 1000;
      this._totalTime = progress.totalTime / 1000;
    }

    /**
     * A timer to simulate progress when we are in remote mode
     */

    private _runTimer = false;
    private set runTimer(value){
        if (this._runTimer != value){
            this._runTimer = value;
            if (value){
                this.startProgressTimer();
            }else{
                this.stopProgressTimer();
            }

        }
    }
    private get runTimer(){
        return this._runTimer;
    }

    private progressTimer;
    private progressTimerSubscription;
    private timePreviousTick;
    private startProgressTimer(){
      this.ngZone.runOutsideAngular(() => {
        if (this.progressTimer == null){
            this.progressTimer = timer(0,250);
        }
        this.timePreviousTick = new Date();
        if (this.progressTimerSubscription == null){
            this.progressTimerSubscription = this.progressTimer.subscribe(t=>this.handleTick(t));
        }
      });
    }
    private stopProgressTimer(){
        if (this.progressTimerSubscription){
            this.progressTimerSubscription.unsubscribe();
            this.progressTimerSubscription = null;
        }
    }

    private handleTick(t){
      const newTimeTick = new Date();
      const millisecondsPassed = newTimeTick.valueOf() - this.timePreviousTick.valueOf();
      this._progress = Math.min(this.progress + millisecondsPassed / 1000, this.totalTime);
      this.timePreviousTick = newTimeTick;
    }

}
