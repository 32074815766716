import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Playlist } from '@model/playlist';
import { PlaylistService } from '@service/playlist.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'tun-select-playlist-view',
  templateUrl: './select-playlist-view.component.html',
  styleUrls: ['./select-playlist-view.component.scss']
})
export class SelectPlaylistViewComponent implements OnDestroy{

  @Input() confirmButtonTranslateableText : string = null;

  @Output() closeWithPlaylist = new EventEmitter<Playlist>();

  public selectedPlaylist: Playlist;

  public get playlistsLoading$(){
    return this.playlistService.loading$
  }

  public get playlistsLoadingError$(){
    return this.playlistService.loadingError$
  }

  public get playlists$(){
    return this.playlistService.playlists$
  }

  public get favoritesLoading$(){
    return this.playlistService.favoritesLoading$
  }

  public get favoritesLoadingError$(){
    return this.playlistService.favoritesLoadingError$
  }

  public get favoriteList$(){
    return this.playlistService.favorites$
  }

  constructor(
    private playlistService: PlaylistService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onRetry(){
    this.playlistService.loadPlaylists();
  }

  public onClose(){
    this.closeWithPlaylist.emit();
  }

  onSelectPlaylist(playlist: Playlist){
    if (this.confirmButtonTranslateableText){
      //we use a confirm button -> preselect the playlist
      this.selectedPlaylist = playlist;
    }else{
      //no confirmation -> direct select
      this.closeWithPlaylist.emit(playlist);
    }

  }

  public onCommit(){
    this.closeWithPlaylist.emit(this.selectedPlaylist);
  }
}
