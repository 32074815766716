import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { PlaylistService } from '@service/playlist.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'tun-playlists-view',
  templateUrl: './playlists-view.component.html',
  styleUrls: ['./playlists-view.component.scss']
})
export class PlaylistsViewComponent implements OnDestroy {

  @Output() createPlaylist = new EventEmitter<void>();

  public get playlistsLoading$(){
    return this.playlistService.loading$
  }

  public get playlistsLoadingError$(){
    return this.playlistService.loadingError$
  }

  public get playlists$(){
    return this.playlistService.playlists$
  }

  public get favoritesLoading$(){
    return this.playlistService.favoritesLoading$
  }

  public get favoritesLoadingError$(){
    return this.playlistService.favoritesLoadingError$
  }

  public get favoriteList$(){
    return this.playlistService.favorites$
  }

  constructor(
    private playlistService: PlaylistService
  ) {

  }

  private destroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  public onRetry(){
    this.playlistService.loadPlaylists();
  }

  public onCreatePlaylist(){
    this.createPlaylist.emit();
  }
}
