import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Calendar } from '@model/calendar';
import { PlayState } from '@model/enums/playState.enum';
import { MusicChannel } from '@model/musicChannel';
import { AudioCommandsService } from '@service/app-v5/audio-commands.service';
import { PlayingItemService } from '@service/app-v5/playing-item.service';
import { MusicSelectionState, PlayingMusicSelectionService, PlayingOrigin } from '@service/app-v5/playing-music-selection.service';
import { AudioTagService } from '@service/audio.tag.service';
import { LoggerService } from '@service/loggers/logger.service';
import { MusicManipulationService } from '@service/music-manipulation.service';
import { MusicPlayerService } from '@service/music-player.service';
import { BehaviorSubject, Subject, combineLatest, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tun-play-music-selection-button',
  templateUrl: './play-music-selection-button.component.html',
  styleUrls: ['./play-music-selection-button.component.scss']
})
export class PlayMusicSelectionButtonComponent implements OnDestroy, OnChanges {

  private LOGGER_CLASSNAME = 'PlayMusicSelectionButtonComponent';

  @Input() public itemToStart: MusicChannel | Calendar;
  @Input() public disabled = false;

  @Output() itemIsStarting = new EventEmitter<void>()

  private itemIsPlayableSubject = new BehaviorSubject<boolean>(true);
  public itemIsPlayable$ = this.itemIsPlayableSubject.asObservable();

  public get itemIsPlaying$(){
    return combineLatest([this.reallyPlaying$, this.playingMusicSelectionService.playingMusicSelection$])
    .pipe(
      map(
        ([playing, playingMusicSelection]) => {
          if (this.itemIsActive(playingMusicSelection)){
            return playing
          }
          return false
        }
      )
    )
  }

  private itemIsActive(playingMusicSelection: MusicSelectionState){
    if (this.itemToStart){
      if (this.itemToStart instanceof MusicChannel){
        return playingMusicSelection.origin == PlayingOrigin.musicChannel && this.itemToStart == playingMusicSelection.musicChannel
      }else if (this.itemToStart instanceof Calendar){
        return playingMusicSelection.origin == PlayingOrigin.calendar && this.itemToStart == playingMusicSelection.calendar;
      }
      return false
    }
  }

  public get itemIsStarting$(){
    return combineLatest([this.audioCommandsService.musicChannelToStart$, this.audioCommandsService.calendarToStart$])
    .pipe(
      map(
        ([musicChannelToStart, calendarToStart]) => {
          if (this.itemToStart){
            if (this.itemToStart instanceof MusicChannel){
              return this.itemToStart == musicChannelToStart
            }else if (this.itemToStart instanceof Calendar){
              return this.itemToStart == calendarToStart
            }
            return false
          }
        }
      )
    )
  }

  public get reallyPlaying$(){
    return combineLatest([this.playingItemService.isPlaying$, this.needClickToStartAudio$])
    .pipe(
      map(([playing, needClickToStartAudio]) => {
        return !needClickToStartAudio && playing
      })
    )
  }

  public get needClickToStartAudio$() {
    return this.playingItemService.needClickToPlay$
  }

  constructor(
    private playingMusicSelectionService: PlayingMusicSelectionService,
    private musicManipulationService: MusicManipulationService,
    private playingItemService: PlayingItemService,
    private audioTagService: AudioTagService,
    private musicPlayerService: MusicPlayerService,
    private loggerService: LoggerService,
    private audioCommandsService: AudioCommandsService
    ) {

    }

  private destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
      if (simpleChanges['itemToStart']) {
        this.watchItemToStartIsStartable();
        this.adjustItemIsPlayable();
      }
  }

  private changeItemToStartWatcher$ = new Subject<void>();
  private watchItemToStartIsStartable(){
    this.changeItemToStartWatcher$.next(null);
    if (this.itemToStart && this.itemToStart instanceof MusicChannel && this.itemToStart.musicCollections){
      this.itemToStart.musicCollections.forEach(musicCollection => {
        musicCollection.selected$
        .pipe(
          takeUntil(
            merge(
              this.destroyed$,
              this.changeItemToStartWatcher$
            )
          )
        )
        .subscribe(
          ()=>{
            this.adjustItemIsPlayable();
          }
        )
      });
    }

    if (this.itemToStart && this.itemToStart instanceof Calendar){
      this.itemToStart.deleted$
        .pipe(
          takeUntil(
            merge(
              this.destroyed$,
              this.changeItemToStartWatcher$
            )
          )
        )
        .subscribe(
          ()=>{
            this.adjustItemIsPlayable();
          }
        )
    }

  }

  private adjustItemIsPlayable(){
    this.itemIsPlayableSubject.next(this.calcItemIsPlayable());
  }

  private calcItemIsPlayable():boolean{
    if (this.itemToStart){

      if (this.itemToStart instanceof MusicChannel){
        if (this.playingMusicSelectionService.playingMusicSelection.musicChannel == this.itemToStart){
          return true;
        }
        if (this.itemToStart.musicCollections){
          return this.itemToStart.musicCollections.filter(mc => mc.selected).length > 0;
        }else{
          return true;
        }
      }else if (this.itemToStart instanceof Calendar){
        if (this.itemToStart.deleted){
          return false;
        }
        return true;
      }
    }else{
      return false;
    }
  }


  public onTogglePlay(event: Event){
    event.stopPropagation();
    if (this.playingItemService.needClickToPlay) {

      this.audioTagService.enableAudioAfterClick();
      this.loggerService.debug(this.LOGGER_CLASSNAME, "onTogglePlay", "starting audio -> " + (NgZone.isInAngularZone() ? "" : "NOT ") + "in angular zone");
    }

    if (this.itemIsActive(this.playingMusicSelectionService.playingMusicSelection)){
      if (this.playingItemService.isPlaying){
        this.musicManipulationService.pause();
      }else{
        this.musicManipulationService.play();
      }
    }else{
      if (this.itemToStart){
        this.itemIsStarting.emit();
        if (this.itemToStart instanceof MusicChannel){
          this.audioCommandsService.startMusicChannel(this.itemToStart);
        }else if (this.itemToStart instanceof Calendar){
          this.audioCommandsService.startCalendar(this.itemToStart);
        }
      }else{
        this.loggerService.error(this.LOGGER_CLASSNAME, "onTogglePlay", "No item to start");
      }
    }

  }
}
